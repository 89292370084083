import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SalesService } from '../../../resources/analytics/sales/sales.service';
import { DataTableFilterService } from '../data-table-filter/data-table-filter.service';

@Component({
  selector: 'app-preview-warning',
  templateUrl: './preview-warning.component.html',
  styleUrls: ['./preview-warning.component.scss']
})
export class PreviewWarningComponent {

  mode: 'preview' | 'production' = 'production';

  @Input() onlyPreview: boolean;
  @Output() previewEmitter: EventEmitter<'preview' | 'production'> = new EventEmitter();

  constructor(
    private readonly salesService: SalesService,
    private readonly dataTableFilterService: DataTableFilterService
  ) { }

  setMode(mode: 'preview' | 'production'): void {
    this.mode = mode;
    if (mode === 'preview') {
      this.salesService.filters = {};
      this.salesService.applyFilters$.next({});
      this.dataTableFilterService.lookerFilterPreview.next(true);
    }
    this.previewEmitter.emit(this.mode);
  }
}