import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleTranslatorService {

  private apiKey = environment.googleTranslatorKey;

  constructor(
    private http: HttpClient
  ) { }

  decodeHtmlEntities(text: string): string {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(text, "text/html").body.textContent;
    return decodedString ?? '';
  }

  translateToMultipleLanguages(text: string, targetLangs: string[]): Observable<string[]> {
    const requests = targetLangs.map(targetLang => this.translate(text, targetLang));
    return forkJoin(requests);
  }

  translate(text: string, targetLang: string): Observable<string> {
    const url = `https://translation.googleapis.com/language/translate/v2?key=${this.apiKey}`;
    const body = {
      q: text,
      target: targetLang
    };

    return this.http.post(url, body).pipe(
      map((response: any) => {
        const translatedText = response.data.translations[0].translatedText;
        return this.decodeHtmlEntities(translatedText);
      })
    );
  }
}
