import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Plan } from '../plan';
import { Subscription } from 'rxjs';
import { ModalStatusService } from '../../../shared/services/modal-status.service';
import { PlansService } from '../plans.service';
import { isNullOrUndefined } from 'is-what';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { FeatureFlagsService } from './../../../shared/services/feature-flags.service';

@Component({
  selector: 'app-plan-detail',
  templateUrl: './plan-detail.component.html',
  styleUrls: ['./plan-detail.component.css']
})

export class PlanDetailComponent implements OnInit, OnDestroy {

  plan: Plan;
  slug: string;
  isCampaignStepModal = false;
  mode: string;
  isEditionEnabled: boolean;
  isEditionVisible = false;
  subs$: Subscription[] = [];
  flags = this.featureFlags.flags;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalStatusService: ModalStatusService,
    private planService: PlansService,
    private confirmationService: ConfirmationService,
    private featureFlags: FeatureFlagsService,
  ) { }

  ngOnInit() {
    this.mode = this.route.snapshot.data.mode;
    this.isEditionEnabled = false;
    this.onRouterChanges();
    const modalStatus$ = this.modalStatusService.modalStatus.subscribe(() => this.isEditionEnabled = false);
    const modalResource$ = this.modalStatusService.resourceStatus.subscribe((plan: Plan) => this.handleEmittedPlan(plan));
    this.subs$.push(modalStatus$);
    this.subs$.push(modalResource$);
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  public handleCloseModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  public getLabelClassByStatus(): string {
    switch (this.plan.status) {
      case 'draft':
        return 'label-default';
      case 'error':
        return 'label-danger';
      case 'scheduled':
        return 'label-warning';
      case 'sending':
        return 'label-info';
      case 'delivered':
        return 'label-success';
    }
  }

  public toggleEdition() {
    this.isEditionEnabled = this.isEditionEnabled ? false : true;
  }

  public handleEmittedPlan(plan: Plan) {
    this.plan = plan;
  }

  private onRouterChanges() {
    this.slug = this.route.firstChild ? this.route.firstChild.snapshot.data.slug : null;
    const router$ = this.route.params.subscribe(params => {
      this.slug = this.route.firstChild ? this.route.firstChild.snapshot.data.slug : null;
      if (params.hasOwnProperty('id') && !isNullOrUndefined(params.id)) {
        this.getPlan(params.id);
      }
    });
    this.subs$.push(router$);
  }

  private getPlan(id: string) {
    const plan$ = this.planService.getPlanById(id).subscribe(
      planData => {
        this.plan = new Plan(planData);
        this.isEditionVisible = ['draft', 'error'].includes(this.plan.status);
      },
      errorData => {

        this.confirmationService.displayErrorAlert('Error', errorData.error.error);
      }
    );
    this.subs$.push(plan$);
  }
}
