import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { ConditionService } from '../../../../services/conditions/condition.service';
import { FeatureFlagsService } from '../../../../../shared/services/feature-flags.service';
import { handleSelectedLocationTaxonomyTermIdsField, resetInputsForm } from '../../utils/common.utils';
import { LocationsService } from '../../../../../resources/data-warehouse/locations/locations.service';
import { MultiselectDataSource } from '../../../multiselect/multiselect';
import { Operator } from '../../../../../shared/models/segments/conditions/operator';
import { QuestionBase } from '../../../../models/forms/question-base';
import { QuestionControlService } from '../../../../services/question-control.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-ticket-condition',
  templateUrl: './ticket-condition.component.html',
  styleUrls: ['./ticket-condition.component.scss']
})
export class TicketConditionComponent implements OnInit, OnDestroy {

  flags = this.featureFlags.flags;
  scopeValue = 'activity';

  private destroy$ = new Subject<void>();

  @Input() item: Condition;
  @Input() inputs: QuestionBase<any>[];
  @Input() form: UntypedFormGroup;

  constructor(
    private qcs: QuestionControlService,
    private conditionService: ConditionService,
    private locationsService: LocationsService,
    private changeDetector: ChangeDetectorRef,
    private featureFlags: FeatureFlagsService
  ) {}

  ngOnInit(): void {
    this.setScopeValueChanges();
    this.setInitialScopeValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

  handleTotalSumOperatorChanges(totalSumOperator: Operator): void {
    this.handleOperatorChanges(totalSumOperator, 'total_sum_operator', 'total_sum_value', 'total_sum_value_1', 'total_sum_value_2');
  }

  handleActivityCountOperatorChanges(activityCountOperator: Operator): void {
    this.handleOperatorChanges(activityCountOperator, 'activity_count_operator', 'activity_count_value', 'activity_count_value_1', 'activity_count_value_2');
  }

  handleSelectedLocationTaxonomyTermIds(locationTaxonomyTermIdsSelected: MultiselectDataSource[]): void {
    handleSelectedLocationTaxonomyTermIdsField(
      locationTaxonomyTermIdsSelected,
      'location_ids',
      this.getInputConfig('location_ids'),
      this.form,
      this.changeDetector,
      this.locationsService
    );
  }

  private setScopeValueChanges(): void {
    const key = Object.keys(this.form.controls).find(controlKey => controlKey.indexOf('scope') >= 0);
    this.form.get(key).valueChanges.pipe(takeUntil(this.destroy$)).subscribe(
      scopeValue => {
        resetInputsForm(this.form, this.inputs, ['feature_ids', 'product_ids']);
        this.scopeValue = scopeValue; // Define scope value for handling inputs visibility
      }
    );
  }

  private setInitialScopeValue(): void {
    const formValues = this.conditionService.formValues[this.item.content.formControlId];
    if (formValues) {
      const keys = Object.keys(formValues);
      if (keys.length) {
        const foundkey = keys.find(_k => _k.indexOf('scope') >= 0);
        this.scopeValue = formValues[foundkey];
      }
    }
  }

  private handleOperatorChanges(operator: Operator, keyOperator: string, keyOperatorValue: string, keyOperatorValue1: string, keyOperatorValue2: string) {
    if (operator) {
      const op = operator.hasOwnProperty('id') ? operator.id : null;
      const keyFieldsToReset = op === 'bt' ? [keyOperatorValue] : [keyOperatorValue1, keyOperatorValue2];
      resetInputsForm(this.form, this.inputs, keyFieldsToReset);
    } else {
      resetInputsForm(this.form, this.inputs, [keyOperator, keyOperatorValue, keyOperatorValue1, keyOperatorValue2]);
    }
  }
}
