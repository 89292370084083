import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { ApiKey } from '../models/api-key';
import { ApiKeysMock } from '../../../../../shared/mocks/api-keys-mock';
import { of } from 'rxjs';

@Injectable()
export class ApiKeyService extends ResourcesService {

  constructor(http: HttpClient) {
    super(http);
  }

  getById(id: number | string) {
    return this.getData({ apiEndPoint: `api_keys/${id}` });
  }

  create(apiKey: ApiKey) {
    return this.postResource(apiKey, 'api_keys');
  }

  updateById(apiKey: ApiKey, id: number | string) {
    return this.patchResource(apiKey, `api_keys/${id}`);
  }
}
