import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-show-streaming-api',
  templateUrl: './show-streaming-api.component.html',
  styleUrls: ['./show-streaming-api.component.scss']
})

export class ShowStreamingApiComponent implements OnInit {

  id: number;
  show: boolean;
  streamingData: Object;
  streamingElement: boolean;
  subs$: Subscription[] = [];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly streamingSrv: ApiService
  ) {}

  ngOnInit(): void {
    this.getParams();
  }

  getParams(): void {
    const route$ = this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.getDataById();
      }
    });
    this.subs$.push(route$);
  }

  getDataById(): void {
    const streamings$ = this.streamingSrv.getStreamingDataById(this.id).subscribe(
      (data) => {
        this.streamingData = data;
      }
    );
    this.subs$.push(streamings$);
  }

  getFile(streaming_element, file_id): void {
    const download$ = this.streamingSrv.getFile(this.id, streaming_element.id, file_id).subscribe(
      (data) => {
        window.open(data['file']);
      }
    );
    this.subs$.push(download$);
  }

  showDetails(streamingElement): void {
    this.streamingElement = streamingElement;
  }

}
