import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { distinctUntilChanged, Subject, takeUntil } from "rxjs";
import { MultiSelectQuestion } from "../../../../shared/models/forms/question-multiselect";
import { TextboxQuestion } from "../../../../shared/models/forms/question-textbox";
import { PlanCampaign } from "../plan-campaign";
import { UntypedFormGroup } from "@angular/forms";
import { isNullOrUndefined } from "util";
import { QuestionBase } from "../../../../shared/models/forms/question-base";
import { CampaignCategoriesService } from "../../../campaigns/campaign-categories/campaign-categories.service";
import { QuestionControlService } from "../../../../shared/services/question-control.service";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { CsvExportService } from "../../../../shared/services/csv-export.service";

@Component({
  selector: 'app-1on1-campaign-plan-definition',
  templateUrl: './1on1-campaign-plan-definition.component.html',
  styleUrls: ['./1on1-campaign-plan-definition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class _1on1CampaignPlanAudienceComponent implements OnInit, OnDestroy {

  files: File[] = [];
  form: UntypedFormGroup;
  inputs: QuestionBase<any>[];
  isDragging: boolean;
  id: string;
  types: string[] = ['csv'];
  multiple = false;

  private destroy$: Subject<void> = new Subject<void>();

  @Input('planCampaign') planCampaign: PlanCampaign;
  @Output('formValidity') formValidity: EventEmitter<any> = new EventEmitter();

  constructor(
    private qcs: QuestionControlService,
    private changeDetector: ChangeDetectorRef,
    private campaignCategoriesSrv: CampaignCategoriesService,
    private translate: TranslateService,
    private http: HttpClient,
    private csvExportService: CsvExportService
  ) { }

  ngOnInit(): void {
    this.setInputs(this.planCampaign);
    this.changeDetector.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  onSelect(event) {
    this.files.push(...event.addedFiles);
    if (!this.multiple && this.files && this.files.length >= 2) {
      this.onRemove(this.files[0]);
    }
    this.setValue(this.files);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.setValue(this.files);
  }

  setValue(value: any) {
    const formValue = {};
    formValue[this.id] = value;
    this.form.patchValue(formValue);
  }

  getFilteredTypes() {
    const strTypes = this.types.map(type => '.' + type);
    return strTypes.join(',');
  }

  showFilteredTypes() {
    return this.getFilteredTypes();
  }

  isCsv(): boolean {
    return this.types.indexOf('csv') !== -1;
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = false;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = false;
  }

  downloadTemplate() {
    const csvContent = this.generateCSVContent();
    this.downloadCSV(csvContent);
  }

  downloadCSV(data) {
    const blob = this.csvExportService.getCsvBlob(data);
    const url = window.URL.createObjectURL(blob);
    const filename = `csv_template.csv`;

    if (navigator['msSaveOrOpenBlob']) {
      navigator['msSaveBlob'](blob, filename);
    } else {
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  private generateCSVContent(): string {
    const headers = ['customer_id', 'coupon_id'];
    const rows = [['123124', '7980'], ['543645', '8908'], ['79800', '77']];
    let csvContent = headers.join(',') + '\n';
    rows.forEach(row => csvContent += row.join(',') + '\n');
    return csvContent;
  }

  private setInputs(planCampaign?: PlanCampaign) {
    let campaignCategories;
    if (planCampaign) {
      campaignCategories = this.getCampaignCategoryValue(planCampaign);
    }
    const inputs = [
      new TextboxQuestion({
        key: 'name',
        label: 'resources.campaign_plans.types.custom.fields.name',
        type: 'text',
        cssClasses: 'form-control input-default',
        required: true,
        value: planCampaign ? planCampaign.name : null
      }),
      new MultiSelectQuestion({
        key: 'campaign_plan_category_id',
        label: 'resources.plans.filters.campaign_category',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.campaignCategoriesSrv,
        selectedIds: (campaignCategories && campaignCategories.length > 0 && campaignCategories[0] !== 0) ? campaignCategories : null,
        value: [{id: -1, name: this.translate.instant('resources.campaign_categories.messages.no_category')}],
        required: true
      })
    ];

    this.inputs = inputs;
    this.form = this.qcs.toFormGroup(this.inputs);
    this.formStatusChanges();
  }

  private getCampaignCategoryValue(planCampaign: PlanCampaign) {
    const campaignPlanCategoryId = planCampaign.config['campaign_plan_category_id'];
    if (isNullOrUndefined(campaignPlanCategoryId)) {return []; }
    return [campaignPlanCategoryId];
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.form.status));
    const formStatus$ = this.form.statusChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((formStatus) => {
        // This is for DEMO only, this campaign will be always invalid until implemented.
        this.formValidity.emit('INVALID');
    });
  }
}
