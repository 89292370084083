import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

enum Mode {
  CREATE = 'create',
  UPDATE_COUPON = 'update_coupon',
  UPDATE_COUPON_HISTORY = 'update_history_id',
}

@Component({
  selector: 'app-coupon-header-component',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './coupon-header-component.component.html',
})
export class CouponHeaderComponentComponent {
  title: string;
  router = inject(Router);

  @Input() showCouponPreview: boolean;
  @Input() set mode({ mode, formMode }: { mode: string, formMode: string }) {
    const modeTitles = {
      [Mode.CREATE]: 'resources.coupons.form.create_title',
      [Mode.UPDATE_COUPON]: 'resources.coupons.form.edit_title',
      [Mode.UPDATE_COUPON_HISTORY]: 'resources.coupons.form.edit_coupon_history_title',
      'default': 'resources.coupons.form.edit_coupon_history_title'
    };
    this.title = modeTitles[mode] || modeTitles[formMode] || modeTitles['default'];
  }

  @Output() closeCoupon = new EventEmitter();

  closeModal() {
    // If the route contains "(modal:show", it means that the user is adding a coupon to an Audience.
    // In this case, we want to emit an event to close the modal.
    // If not, we navigate to the parent route, which is the coupon list page.
    if (this.router.url.indexOf('(modal:show') >= 0 ) {
      this.closeCoupon.emit();
    } else {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    }
  }

}
