import { Component, Input, OnInit } from '@angular/core';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-total-amount-conditions',
  templateUrl: './total-amount-conditions.component.html',
  styleUrl: './total-amount-conditions.component.scss'
})
export class TotalAmountConditionsComponent implements OnInit {

  @Input('loyaltyScoresForm') loyaltyScoresForm: UntypedFormGroup;
  @Input('inputs') inputs: QuestionBase<unknown>[];

  constructor(
    private qcs: QuestionControlService
  ) {}

  ngOnInit() {
    this.loyaltyScoresForm.get('ticket_total_max').valueChanges.subscribe(() => {
      this.validateTotalTicketValues();
    });

    this.loyaltyScoresForm.get('ticket_total_min').valueChanges.subscribe(() => {
      this.validateTotalTicketValues();
    });
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.loyaltyScoresForm, formKey, value);
  }

  private validateTotalTicketValues() {
    const ticketTotalMax = parseInt(this.loyaltyScoresForm.get('ticket_total_max').value, 10);
    const ticketTotalMin = parseInt(this.loyaltyScoresForm.get('ticket_total_min').value, 10);

    if(ticketTotalMin > ticketTotalMax) {
      this.loyaltyScoresForm.get('ticket_total_min').setErrors({'incorrect': true})
    } else {
      this.loyaltyScoresForm.get('ticket_total_min').setErrors(null);
    }
  }

}
