import { Component, OnInit } from '@angular/core';
import { CountriesService } from '../../../../shared/services/countries.service';
import { MultiSelectQuestion } from '../../../../shared/models/forms/question-multiselect';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { Router } from '@angular/router';
import { TextareaQuestion } from '../../../../shared/models/forms/question-textarea';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';
import { DictionaryService } from '../../../../shared/services/dictionary.service';
import { RadioQuestion } from '../../../../shared/models/forms/question-radio';
import { RewardsService } from '../../rewards/rewards.service';
import { LocationsTaxonomyTermsService } from '../../../data-warehouse/locations/location-taxonomy-terms.service';

@Component({
  selector: 'app-form-referral-program',
  templateUrl: './form-referral-program.component.html',
  styleUrls: ['./form-referral-program.component.scss'],
  providers: [DictionaryService]
})

export class FormReferralProgramComponent implements OnInit {

  formReferralProgram: UntypedFormGroup;
  id: number;
  inputs: QuestionBase<any>[];
  loading: boolean;

  constructor(
    private countriesService: CountriesService,
    private dictionarySrv: DictionaryService,
    private qcs: QuestionControlService,
    private router: Router,
    private translate: TranslateService,
    private rewardsService: RewardsService,
    private locationTaxonomyTermsSrv: LocationsTaxonomyTermsService,
  ) { }

  ngOnInit(): void {
    this.inputs = this.setInputs();
    this.formReferralProgram = this.qcs.toFormGroup(this.inputs);
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.formReferralProgram, formKey, value);
  }

  returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    }
  }

  save(): void {

  }

  private setInputs(): QuestionBase<any>[] {
    return [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextareaQuestion({
        key: 'description',
        type: 'textarea',
        cssClasses: 'form-control input-default',
        placeholder: this.translate.instant('resources.scores.referral-programs.form.description_placeholder'),
      }),
      new MultiSelectQuestion({
        key: 'country',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        options: this.countriesService.getCountries(),
      }),
      new MultiSelectQuestion({
        key: 'program_type',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        options: this.dictionarySrv.getValuesByKey('referral_programs')
      }),
      new MultiSelectQuestion({
        key: 'win_type',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        options: this.dictionarySrv.getValuesByKey('win_type_referral_programs')
      }),
      new RadioQuestion({
        key: 'code_type',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.dictionarySrv.getValuesByKey('code_type_referral_programs'),
        value: 'random',
      }),
      new TextboxQuestion({
        key: 'code_text',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'reward',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.rewardsService
      }),
      new MultiSelectQuestion({
        key: 'referral_prize_option',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        options: [
          {id: 'points', name: this.translate.instant('resources.scores.referral-programs.types.prize_options.points')},
          {id: 'reward', name: this.translate.instant('resources.scores.referral-programs.types.prize_options.reward')},
        ]
      }),
      new MultiSelectQuestion({
        key: 'advocate_prize_option',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        options: [
          {id: 'points', name: this.translate.instant('resources.scores.referral-programs.types.prize_options.points')},
          {id: 'reward', name: this.translate.instant('resources.scores.referral-programs.types.prize_options.reward')},
        ]
      }),
      new TextboxQuestion({
        key: 'points_value',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'taxonomy',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.locationTaxonomyTermsSrv
      }),
      new MultiSelectQuestion({
        key: 'redeem_chances',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.getRedeemOptions(),
      }),
      new TextboxQuestion({
        key: 'redeem_times',
        type: 'number',
        cssClasses: 'form-control input-default',
        getValue: (value: number) => value
      }),
    ];
  }

  private getRedeemOptions() {
    return [
      { id: 'always', name: this.translate.instant('resources.scores.rewards.form.redeem_always') },
      { id: 'num_total', name: this.translate.instant('resources.scores.rewards.form.redeem_total_times') },
      { id: 'num_day', name: this.translate.instant('resources.scores.rewards.form.redeem_times_day') },
      { id: 'num_month', name: this.translate.instant('resources.scores.rewards.form.redeem_times_month') },
      { id: 'num_year', name: this.translate.instant('resources.scores.rewards.form.redeem_times_year') },
    ];
  }


}
