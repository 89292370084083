import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Subscription } from 'rxjs';

import { Plan } from '../../plan';
import { Alert } from '../../../alerts/alert';

import { SharedModule } from '../../../../shared/shared.module';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { PlansService } from '../../plans.service';

import { CheckboxQuestion } from '../../../../shared/models/forms/question-checkbox';

@Component({
  selector: 'app-validate-plan-confirmation',
  standalone: true,
  imports: [CommonModule, SharedModule, ReactiveFormsModule],
  templateUrl: './validate-plan-confirmation.component.html',
  styleUrls: ['./validate-plan-confirmation.component.scss'],
})
export class ValidatePlanConfirmationComponent implements OnInit, OnDestroy {
  notifyInput: CheckboxQuestion;
  validatePlanForm: UntypedFormGroup;
  id = this.route.snapshot.params.id;
  plan: Plan;
  alerts: Alert[];
  loading: boolean;

  subs$: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private qcs: QuestionControlService,
    private translate: TranslateService,
    private planService: PlansService,
    private confirmationService: ConfirmationService,
    private modalStatus: ModalStatusService,
  ) { }

  ngOnInit() {
    this.notifyInput = new CheckboxQuestion({
      key: 'notify',
      cssClasses: 'form-control input-md',
      label: this.translate.instant(
        'resources.plans.alerts.validation_modal.notify_email',
      ),
    });

    this.validatePlanForm = this.qcs.toFormGroup([this.notifyInput]);

    this.getPlanById();
  }

  ngOnDestroy() {
    this.subs$.forEach(sub => sub.unsubscribe());
  }

  closeModal(): void {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => { });
  }

  getPlanById() {
    this.subs$.push(
      this.planService.getPlanById(this.id).subscribe((response) => {
        this.plan = new Plan(response);
      })
    );
  }

  handleValidation(_event: Event) {
    this.subs$.push(
      this.planService
        .getPlanAlertsById(
          this.id,
          true,
          this.validatePlanForm.get(this.notifyInput.key).value ?? false,
        )
        .subscribe({
          next: (_response) => {
            this.router.navigate([{ outlets: { modal: `schedule_plan/${this.id}` } }]).catch(() => { });
          },
          error: (err: HttpErrorResponse) => this.handleExecutionError(err),
        }),
    );
  }

  private handleExecutionError(err: HttpErrorResponse) {
    if (
      err.error.hasOwnProperty('extra_info') &&
      err.error.extra_info.length > 0
    ) {
      this.modalStatus.resourceStatus.emit({ plan: this.plan, err: err });
    } else {
      this.confirmationService.displayErrorAlert(
        this.translate.instant('common.error'),
        err.error.error,
      );
    }
    this.closeModal();
  }
}
