import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, Output, EventEmitter, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { CountriesService } from '../../../shared/services/countries.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { FeatureFlagsService } from '../../../shared/services/feature-flags.service';
import { FeaturesService } from '../../data-warehouse/products/services/products-features/features.service';
import { FeatureTaxonomiesService } from '../../data-warehouse/products/services/products-feature-taxonomies/feature-taxonomies.service';
import { handleUserPasswordError } from './utils/form-errors.utils';
import { HttpErrorResponse } from '@angular/common/http';
import { LanguagesService } from '../../../shared/services/languages.service';
import { LocationsService } from '../../data-warehouse/locations/locations.service';
import { matchPasswordValidator } from '../../../shared/validators/match-password.validator';
import { ModalStatusService } from '../../../shared/services/modal-status.service';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { PartnersService } from '../../scores/partners/partners.service';
import { ProfileService } from '../../../profiles/profile.service';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../shared/services/question-control.service';
import { RolesService } from '../roles.service';
import { Subscription } from 'rxjs';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';
import { User } from '../../../shared/models/users/user';
import { userPassworValidator } from '../../../shared/validators/user-password.validator';
import { UserSecurity } from '../../../shared/models/company/company-configurations';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-form-users',
  templateUrl: './form-users.component.html',
  styleUrls: ['./form-users.component.scss']
})

export class FormUsersComponent implements OnInit, AfterViewChecked, OnDestroy {

  element: User;
  flags = this.featureFlags.flags;
  id: number;
  inputs: QuestionBase<unknown>[];
  showPasswordVisibility = {
    user_password: false,
    user_password_confirmation: false
  };
  subs$: Subscription[] = [];
  userForm: UntypedFormGroup;
  userRoleSlug: string;
  userSecurity = new UserSecurity(this.profileService.getProfileCompany()?.company_configurations?.user_security ?? {});

  @Output('formValidity') formValidity: EventEmitter<string> = new EventEmitter<string>() ;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly confirmationService: ConfirmationService,
    private readonly countriesService: CountriesService,
    private readonly featureFlags: FeatureFlagsService,
    private readonly featuresService: FeaturesService,
    private readonly featureTaxonomiesService: FeatureTaxonomiesService,
    private readonly languagesService: LanguagesService,
    private readonly locationsService: LocationsService,
    private readonly modalStatusService: ModalStatusService,
    private readonly profileService: ProfileService,
    private readonly qcs: QuestionControlService,
    private readonly rolesService: RolesService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly userService: UsersService,
    private readonly partnersService: PartnersService
  ) {}

  ngOnInit(): void {
    this.getParams();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.subs$.forEach(s$ => s$.unsubscribe());
    this.rolesService.setRoleSlugsToFilter(null);
    this.countriesService.setIsAllCountriesOption(false);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  handlePasswordVisibility(key: string): void {
    this.showPasswordVisibility[key] = !this.showPasswordVisibility[key];
    this.getInputConfig(key).type = this.showPasswordVisibility[key] ? 'text' : 'password';
  }

  sendData(): void {
    const userObj = this.parseUserFormValues(this.userForm.value);
    if (userObj['password'] !== userObj['password_confirmation']) {
      this.confirmationService.displayAlert(
        this.translate.instant('resources.users.fields.password'),
        this.translate.instant('resources.users.messages.password_mismatch'),
        'warning'
      ).catch(() => {});
    } else {
      this.modalStatusService.formLoaderStatus.emit('loading');
      const req$ = this.id ? this.userService.updateUser(userObj, this.id) : this.userService.createUser(userObj);
      const user$ = req$.subscribe(
        () => this.handleUserSuccessRequest(),
        (errData: HttpErrorResponse) => this.handleAPIErrors(errData)
      );
      this.subs$.push(user$);
    }
  }

  hasFormKeyWithSlug(formKey: string, value: any): boolean {
    if (this.userForm.get(formKey).value &&
        this.userForm.get(formKey).value.length &&
        this.userForm.get(formKey).value[0].hasOwnProperty('rawElement')) {
      return this.userForm.get(formKey).value[0].rawElement.slug === value;
    } else {
      return this.userForm.get(formKey).value === value;
    }
  }

  handleRoleOptsLoaded(): void {
    setTimeout(() => {
      if(this.id && this.userRoleSlug === 'admin') {
        this.getInputConfig('user_role_id').disabled = true;
      }
    })
  }

  private handleUserSuccessRequest(): void {
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    const successTitle = this.translate.instant(this.id ? 'resources.users.messages.updated_success_title' : 'resources.users.messages.created_success_title');
    const successDesc = this.translate.instant(this.id ? 'resources.users.messages.updated_success_text' : 'resources.users.messages.created_success_text');
    this.confirmationService.displaySuccessAlert(successTitle, successDesc).catch(() => {});
    this.modalStatusService.modalStatus.emit();
    this.returnToList();
  }

  private handleAPIErrors(errorData: HttpErrorResponse): void {
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    const errorValue = errorData?.error?.error ? errorData.error.error : null;
    const errorsValue = errorData?.error?.errors ? errorData?.error?.errors : null;
    if (errorsValue?.length) {
      let userPasswordError = null;
      errorsValue.forEach(error => {
        if (error['field'] === 'password') { userPasswordError = error; }
        error['field'] = 'user_' + error['field'];
      });
      this.qcs.paintErrorsInForm(this.inputs, this.userForm, errorData.error.errors);
      if (!this.id && userPasswordError) {
        handleUserPasswordError(this.userSecurity, this.userForm.get('user_password'), this.getInputConfig('user_password'), userPasswordError);
      }
      if (errorValue) { this.displayHttpErrorAlert(errorData); }
    } else if (errorValue) {
      this.displayHttpErrorAlert(errorData);
    }
  }

  private displayHttpErrorAlert(error: HttpErrorResponse): void {
    this.confirmationService.displayHttpErrorAlert(error);
  }

  private getParams(): void {
    const router$ = this.route.params.subscribe(params => {
      this.modalStatusService.formLoaderStatus.emit('loading_data');
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getUserById();
      } else {
        this.setInputsAndForm({}, null);
      }
    });
    this.subs$.push(router$);
  }

  private getUserById(): void {
    const element$ = this.userService.getUserById(this.id).subscribe(
      data => {
        this.userRoleSlug = data.hasOwnProperty('role') ? data['role']['slug'] : null;
        const roleSlugsToFilter = this.userRoleSlug && this.userRoleSlug !== 'admin' ? ['admin'] : null;
        this.setInputsAndForm(data, roleSlugsToFilter);
      },
      (errorData: HttpErrorResponse) => this.displayHttpErrorAlert(errorData)
    );
    this.subs$.push(element$);
  }

  private setInputsAndForm(data: Object, roleSlugsToFilter?: string[]) {
    const supplierTaxonomies$ = this.featureTaxonomiesService.fetchMultiselect(null, null, {kind: 'supplier'});
    const taxonomies$ = supplierTaxonomies$.subscribe(supplierTaxonomies => {
      this.inputs = this.getInputs(data, supplierTaxonomies, this.id, roleSlugsToFilter);
      this.userForm = this.qcs.toFormGroup(this.inputs);
      this.setFormValidators();
      this.formStatusChanges();
      this.modalStatusService.formLoaderStatus.emit('loading_data_finished');
    })
    this.subs$.push(taxonomies$);
  }

  private setFormValidators(): void {
    if (!this.id) {
      this.userForm.addValidators(matchPasswordValidator('user_password', 'user_password_confirmation'));
      this.userForm.updateValueAndValidity();
    }
  }

  private parseUserFormValues(userFormValues): object {
    const resultObj = {};

    if (this.id) {
      delete userFormValues['user_partner_id'];
    }

    Object.keys(userFormValues).forEach(key => {
      const newKey = key.replace('user_', '');
      const keyValue = userFormValues[key];
      if ( keyValue instanceof Array && keyValue.length === 1 && newKey !== 'location_ids' && newKey !== 'supplier_ids') {
        resultObj[newKey] = keyValue[0].id;
      } else if ((keyValue instanceof Array && keyValue.length > 0) && (newKey === 'location_ids' || newKey === 'supplier_ids')) {
        resultObj[newKey] = keyValue.map(valueObj => valueObj.id);
      } else {
        resultObj[newKey] = keyValue;
      }

      if ( newKey === 'ip_white_list' && !Array.isArray(resultObj[newKey]) ) {
        resultObj[newKey] = resultObj[newKey] ? resultObj[newKey].replace(/\s/g, '').split(',') : null;
      }

      if (newKey === 'country' && keyValue === undefined) {
        resultObj[newKey] = [];
      }

      if (newKey === 'partner_id' && !keyValue?.length) {
        resultObj[newKey] = null;
      }
    });

    return resultObj;
  }

  private returnToList(): void {
    if (this.router.url.indexOf('(modal:') >= 0) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.router.navigate(['users']).catch(() => {});
    }
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.userForm.status));
    const formStatus$ = this.userForm.statusChanges.pipe(distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
    this.subs$.push(formStatus$);
  }

  private initialLanguage(params?: any): {id: string, name: string}[] {
    if(!params?.hasOwnProperty('preferred_language')) {
      return [];
    }
    const languages = this.languagesService.getLocalizedLanguages();
    const userLang = params?.preferred_language;
    const optionLang = languages.find(item => item.id === userLang);
    return  optionLang ? [optionLang] : [];
  }

  private getInputs(params?: any, supplierTaxonomies?: object, id?: number, roleSlugsToFilter?: string[]) {
    const roleSlugsList = roleSlugsToFilter?.length ? roleSlugsToFilter : null;
    this.rolesService.setRoleSlugsToFilter(roleSlugsList);
    this.countriesService.setIsAllCountriesOption(true);
    this.featuresService.treatPkAsId = true;
    const supplierTaxonomySlug = this.featureTaxonomiesService.getTaxonomySlug(supplierTaxonomies);

    return [
      new TextboxQuestion({
        key: 'user_name',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: params?.name,
        required: true
      }),
      new TextboxQuestion({
        key: 'user_surname_1',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: params?.surname_1,
        required: true
      }),
      new TextboxQuestion({
        key: 'user_surname_2',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: params?.surname_2
      }),
      new TextboxQuestion({
        key: 'user_email',
        type: 'email',
        cssClasses: 'form-control input-default',
        value: params?.email,
        required: true
      }),
      new TextboxQuestion({
        key: 'user_password',
        type: 'password',
        cssClasses: 'form-control input-default',
        required: !id,
        customValidators: id ? [] : [userPassworValidator(this.userSecurity)]
      }),
      new TextboxQuestion({
        key: 'user_password_confirmation',
        type: 'password',
        cssClasses: 'form-control input-default',
        required: !id
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'user_role_id',
        dataSource: this.rolesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        selectedIds: params?.role ? [params.role.id] : [],
        required: true
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'user_location_ids',
        dataSource: this.locationsService,
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        selectedIds: params?.locations?.length ? params.locations.map(location => location.id) : []
      }),
      new TextboxQuestion({
        key: 'user_ip_white_list',
        type: 'text',
        cssClasses: 'form-control input-default',
        helpText: this.translate.instant('resources.users.messages.ip_white_list_help'),
        value: params?.ip_white_list ? params.ip_white_list.join(',') : null
      }),
      new MultiSelectQuestion({
        key: 'user_country',
        type: 'text',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        options: this.countriesService.getCountries(),
        value: [this.countriesService.getCountriesById(params?.hasOwnProperty('country') ? params.country : null)],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'user_preferred_language',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: false },
        options: this.languagesService.getPlatformLanguages().filter(lang => !['hr', 'ro'].includes(lang.id)),
        value: this.initialLanguage(params),
        required: true
      }),
      new MultiSelectQuestion({
        key: 'user_supplier_ids',
        cssClasses: 'form-control input-md',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.featuresService,
        filters: {taxonomy_slug: supplierTaxonomySlug ? supplierTaxonomySlug : 'none'},
        selectedIds: params?.supplier_ids?.length ? params.supplier_ids : []
      }),
      new MultiSelectQuestion({
        key: 'user_partner_id',
        cssClasses: 'form-control input-md',
        settings: { singleSelection: true, enableSearchFilter: false },
        dataSource: this.partnersService,
        selectedIds: params?._embedded?.partner?.id ? [params._embedded.partner.id] : [],
        disabled: !!this.id
      })
    ]
  }
}
