import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { ProfileService } from '../../profiles/profile.service';

@Pipe({
  name: 'timeRemaining',
})
export class TimeRemainingPipe implements PipeTransform {
  constructor(
    private profileService: ProfileService
  ) {}

  transform(availableTo: any, humanize: boolean = true): any {
    let timezone = this.profileService.getProfileCompany().timezone;
    let endDate = moment.tz(availableTo, timezone).endOf('day');
    let momentAct = moment.tz(timezone);
    let diff = endDate.diff(momentAct, 'seconds');
    let duration = moment.duration(diff, 'seconds');

    if (humanize) {
      return duration.humanize();
    } else {
      return Math.floor(duration.asDays());
    }
  }
}
