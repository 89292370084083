import { EMPTY, Observable, Subject, catchError, switchMap, takeUntil, takeWhile, timer } from "rxjs";

export function isNullOrUndefined(value: unknown): boolean {
  return value === null || value === undefined;
}

export function isEmptyObjectValues(object: object): boolean {
  return Object.values(object).every(val => val === undefined || val === null || val === '' || !val.length);
}

export const isObject = (value: any): value is object => {
  return !!value && value.constructor === Object
}

/*
* Replacement for the deprecated getCurrencySymbol from @angular/common
* Also see LgCurrencyPipe for actual currency formatting
*/
export function getCurrencySymbol(code: string, format?: Intl.NumberFormatOptions['currencyDisplay'] | 'wide' | 'narrow', locale: string = 'en'): string {
  try {
    // Map old formats to Intl.NumberFormat options, so migration is easier
    let currencyDisplay: Intl.NumberFormatOptions['currencyDisplay'] = 'code';
    if (format === 'wide') {
      currencyDisplay = 'code';
    } else if (format === 'narrow') {
      currencyDisplay = 'narrowSymbol';
    }
    // Get currency symbol from Intl.NumberFormat
    const parts = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: code,
      currencyDisplay: currencyDisplay
    }).formatToParts();
    // Extract currency symbol from parts
    const currencyPart = parts.find(part => part.type === 'currency');
    // If missing, pass through currency code
    return currencyPart ? currencyPart.value : code;
  } catch (e) {
    console.error(`Couldn't get currency symbol for ${code}. Falling back to currency code.`);
    return code;
  }
}

export function getIdValueFromArrayObject(arrayObject: any[], asSingleElementArray = false) {
  if (arrayObject?.length > 0 && arrayObject[0].id) {
    return asSingleElementArray ? [arrayObject[0].id] : arrayObject[0].id;
  }
  return null;
}

export function isIntegerValue1GreaterThanValue2(value1: string, value2: string, base: number = 10): boolean {
  return parseInt(value1, base) > parseInt(value2, base);
}

export function getRandomNumber() {
  const crypto = window.crypto;
  const array = new Uint32Array(1);
  return crypto.getRandomValues(array)[0];
}

export function getDataTableTextPropValue(prop: string): unknown {
  return (element: Object) => element?.[prop] ? element[prop] : null;
}

export function getDataTableNumberPropValue(prop: string): unknown {
  return (element: Object) => element?.[prop] ?? null;
}

export function getDataTableIconPropValue(prop: string): unknown {
  return (element: Object) => element?.[prop] ? element[prop] : 'none';
}

export function getResourceProp(apiObject: object, resource: string, prop: string, defaultValue: any = null): any {
  if (!apiObject.hasOwnProperty(resource)) { return defaultValue; }
  if (apiObject[resource] && !apiObject[resource].hasOwnProperty(prop)) { return defaultValue; }
  if (!apiObject[resource][prop]) { return defaultValue; }
  return apiObject[resource][prop];
}

/**
 * Splits an array into two groups based on a predicate function.
 *
 * This function takes an array and a predicate function. It returns a tuple of two arrays:
 * the first array contains elements for which the predicate function returns true, and
 * the second array contains elements for which the predicate function returns false.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} arr - The array to partition.
 * @param {(value: T) => boolean} isInTruthy - A predicate function that determines
 * whether an element should be placed in the truthy array. The function is called with each
 * element of the array.
 * @returns {[T[], T[]]} A tuple containing two arrays: the first array contains elements for
 * which the predicate returned true, and the second array contains elements for which the
 * predicate returned false.
 *
 * @example
 * const array = [1, 2, 3, 4, 5];
 * const isEven = x => x % 2 === 0;
 * const [even, odd] = partition(array, isEven);
 * // even will be [2, 4], and odd will be [1, 3, 5]
 */
export function partition<T>(arr: readonly T[], isInTruthy: (value: T) => boolean): [truthy: T[], falsy: T[]] {
  const truthy: T[] = [];
  const falsy: T[] = [];

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    if (isInTruthy(item)) {
      truthy.push(item);
    } else {
      falsy.push(item);
    }
  }

  return [truthy, falsy];
}

export const get = (obj: object, path: string, defaultValue = undefined) => {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res: any, key: string) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};


type TransformFunction = (key: string) => string;

/**
 * This function initiates the key transformation process.
 * It accepts a key transformation function and an object, then calls
 * the tail-recursive function `transformKeysRecursively` with appropriate
 * initial values. It also checks if the input is a valid object.
 *
 * @param transform The function to apply to each key.
 * @param obj The object whose keys will be transformed.
 * @returns A new object with transformed keys.
 */
export function transformKeys(transform: TransformFunction, obj: any): any {
  // Return the original value if it's not a plain object
  if (!isObject(obj)) { return obj; }

  const initialKeys = Object.keys(obj);
  return transformKeysRecursively(transform, obj, initialKeys, {}, 0);
}

/**
 * This function recursively transforms the keys of an object using a
 * provided transformation function. It uses tail recursion for efficiency.
 * It also recursively transforms the values if they are objects themselves.
 *
 * @param transform The function to apply to each key.
 * @param originalObj The original object.
 * @param keys The remaining keys to process.
 * @param acc The accumulator object that stores the transformed key-value pairs.
 * @param index The current index in the `keys` array.
 * @returns A new object with transformed keys.
 */
function transformKeysRecursively(
  transform: TransformFunction,
  originalObj: Record<string, any>,
  keys: string[],
  acc: Record<string, any>,
  index: number
): Record<string, any> {
  if (index >= keys.length) { return acc; }

  const currentKey = keys[index];
  const newKey = transform(currentKey);
  const currentValue = originalObj[currentKey];

  // Recursively transform values if they are also objects
  acc[newKey] = isObject(currentValue) ? transformKeys(transform, currentValue) : currentValue;

  return transformKeysRecursively(transform, originalObj, keys, acc, index + 1);
}

/**
 * Deeply merges two objects.
 *
 * This function recursively merges the properties of the `source` object into the `target` object.
 * If both `target` and `source` have an object at the same key, the function will recursively merge those nested objects.
 * Otherwise, the value from the `source` object will overwrite the corresponding value in the `target` object.
 *
 * Note: This function modifies the `target` object in place.
 *
 * @param target - The target object to merge into.
 * @param source - The source object to merge from.
 * @returns The merged `target` object.
 */
export function merge(target: any, source: any): any {
  if (typeof target !== 'object' || typeof source !== 'object') {
    return source;
  }

  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (target.hasOwnProperty(key) && typeof target[key] === 'object' && typeof source[key] === 'object') {
        // Recursively merge nested objects
        target[key] = merge(target[key], source[key]);
      } else {
        // Otherwise, just assign the source value
        target[key] = source[key];
      }
    }
  }

  return target;
}

/**
 * Guesses the type of a value based on its JavaScript type.
 * @param value
 */
export function guessType(value: any) {
  if (value instanceof Array) { return 'array'; }
  if (isObject(value)) { return 'hash' }

  const jsType = typeof value;
  switch (jsType) {
    case 'number': return guessNumberType(value);
    case 'boolean': return 'boolean';
    default: return 'string';
  }
}

/**
 * Guesses the type of a number based on its value.
 * @param value
 */
export function guessNumberType(value: any) {
  try {
    const number = Number(value);
    if (Number.isNaN(number)) { return 'integer'; }
    if (Number.isInteger(number)) { return 'integer'; }
    // If it is number, but not integer, assume float
    return 'float';
  } catch (e) {
    // Always assume integer
    if (e instanceof TypeError) { return 'integer'; }
  }
}

/**
 * Casts a value to a given type.
 * @param value
 * @param type
 */
export function castToType(value: any, type: string) {
  switch (type) {
    case 'integer':
    case 'float':
      return defaultByCastType(Number(value), type);
    default:
      return defaultByCastType(value, type);
  }
}

/**
 * Polls until a condition is met or an error occurs.
 * @param pollFun - Function to poll.
 * @param pollParams - Parameters to pass to the poll function.
 * @param pollFunSelf - Self reference to the poll function.
 * @param pollInterval - Interval in milliseconds between polls; default is 2000ms.
 * @param errorCallback - Callback function that handles errors during polling; optional.
 * @param takeWhileCallback - Callback function that determines whether to continue polling; if not provided, polling will run once.
 * @returns [Observable<any>, Subject<void>] - Returns an Observable that emits the result of the poll function and a Subject that can be used to stop the polling.
 */
export function pollUntil(
  pollFun: (...args: any[]) => Observable<any>,
  pollParams: any[],
  pollFunSelf: any,
  pollInterval: number = 2000,
  errorCallback: (error: any) => void = () => {},
  takeWhileCallback: (value: any, index: number) => boolean = () => false
): [Observable<any>, Subject<void>] {
  const pollKillSwitch$ = new Subject<void>();
  return [timer(0, pollInterval).pipe(
    takeUntil(pollKillSwitch$), // Stop polling when pollKillSwitch$ emits
    switchMap(() => {
      return pollFun.apply(pollFunSelf, pollParams).pipe(
        catchError((error, _caught) => {
          console.error("Error polling: ", error);
          errorCallback(error);
          return EMPTY;
        })
      )
    }),
    takeWhile(takeWhileCallback, true)
  ), pollKillSwitch$];
}

function defaultByCastType(value: any, type: string) {
  if (!isNullOrUndefined(value)) { return value; }

  switch (type) {
    case 'integer':
      return 0;
    case 'float':
      return 0.0;
    case 'string':
      return '';
    case 'hash':
      return {};
    case 'array':
      return [];
    default:
      return null;
  }
}
