import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, registerLocaleData } from '@angular/common';

import co from '@angular/common/locales/es-CO';
import it from '@angular/common/locales/it';
import hr from '@angular/common/locales/hr';
import ro from '@angular/common/locales/ro';
import fr from '@angular/common/locales/fr';

import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../profiles/profile.service';

import { getCurrencySymbol } from '../utils/common.utils';

@Pipe({
  name: 'lgcurrency',
  standalone: true
})
export class LgCurrencyPipe implements PipeTransform {
  public currencySymbol: string;

  constructor(
    private translateService: TranslateService,
    private profileService: ProfileService,
    private currencyPipe: CurrencyPipe
  ) {
    registerLocaleData(co);
    registerLocaleData(it);
    registerLocaleData(hr);
    registerLocaleData(ro);
    registerLocaleData(fr);
    this.currencySymbol = '€';
  }

  transform(
    value: any,
    digits?: string,
    currencyCode?: string,
    display: boolean | 'symbol' | 'code'| 'symbol-narrow' = 'symbol',
    locale?: string
  ): string {
    const currencySymbol = this.getCurrencySymbol(currencyCode);

    if (locale === undefined) {
      if (this.profileService.getProfileCompany().time_zone === 'America/Bogota') {
        locale = 'es-CO'; // In Colombia the currency symbol should be placed at the left
      } else {
        locale = this.translateService.currentLang;
      }
    }

    if (value != null && !isNaN(parseFloat(value)) && value !== '') {
      value = parseFloat(value);
      return this.currencyPipe.transform(value, currencySymbol, display, digits, locale);
    }

    return this.currencyPipe.transform(0, currencySymbol, display, digits, locale).split('0.00')[0];
  }

  public getCurrencySymbol(currencyCode: string): string {
    const companyRaw = this.profileService.getProfileCompany();
    const companyCurrency = companyRaw.currency;
    currencyCode = currencyCode && companyRaw.multicurrency ? currencyCode : companyCurrency;
    return getCurrencySymbol(currencyCode, 'narrow');
  }
}
