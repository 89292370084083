<div class="modal-backdrop fade in" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="mrg-left-15 mrg-btm-5" [translate]="'resources.customers.show.actions.subtract'"></h4>
        <button [attr.data-cy]="'button_closeSubstractScore'" class="modal-close" (click)="closeModal()">
          <i class="ti-close"></i>
        </button>
      </div>

      <app-loader *ngIf="loading"></app-loader>

      <div class="modal-body background-gray">
        <div class="row">
          <div class="col-md-12">
            <div class="container-fluid">
              <div class="row">
                <div class="mrg-top-10 col-md-12">
                  <div class="card-block no-pdd">
                    <div class="row">
                      <div class="col-md-8 ml-auto mr-auto">
                        <form [formGroup]="subtractScoreForm" *ngIf="subtractScoreForm">

                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="score" [translate]="'resources.customers.show.action_modals.score'"></label>
                                <span class="text-danger">*</span>
                                <app-question [question]="getInputConfig('score')" [form]="subtractScoreForm">
                                </app-question>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="comments"
                                       [translate]="'resources.customers.show.action_modals.comments'">
                                </label>
                                <span class="text-danger">*</span>

                                <ng-container>
                                  <!-- IF COMMENTS TABLE IS EMPTY DISPLAY TEXTBOXQUESTION -->
                                  <app-question *ngIf="!hasComments" [question]="getInputConfig('comments')" [form]="subtractScoreForm"></app-question>
                                  <!-- IF COMMENTS TABLE HAS VALUES DISPLAY MULTISELECTOR -->
                                  <app-question *ngIf="hasComments" [question]="getInputConfig('multi_comments')" [form]="subtractScoreForm"></app-question>
                                </ng-container>

                              </div>
                            </div>
                          </div>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer background-white">
        <div class="pull-right">
          <button [attr.data-cy]="'button_customerSubstractScoreConfirm'"
            class="pull-right btn btn-info"
            [disabled]="!subtractScoreForm?.valid || loading"
            (click)="sendData()">
            <span *ngIf="loading">
              <em class="fa fa-spinner fa-spin"></em>
            </span>
            {{ 'resources.customers.show.actions.subtract' | translate }}
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
