import { ActivatedRoute, Router } from '@angular/router';
import { AttributesConditionsService } from '../../../../shared/services/attributes-conditions.service';
import { CompanyService } from '../../../config/company/company.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { CurrentCompany } from '../../../../shared/models/current-user';
import { CustomEntitiesService } from '../../../customer-data-platform/custom-entities/services/custom-entities.service';
import { CustomEntity } from '../../../customer-data-platform/custom-entities/models/custom-entity';
import { DateTimeZoneService } from '../../../../shared/services/date-time-zone.service';
import { DictionaryService } from '../../../../shared/services/dictionary.service';
import { FeatureFlagsService } from './../../../../shared/services/feature-flags.service';
import { FormCustomEntityComponent } from './form-custom-entity/form-custom-entity.component';
import { getTranslationInputs } from '../../../data-warehouse/data-warehouse-coupons/form-coupons/coupon-utils/coupon-utils';
import { HttpErrorResponse } from '@angular/common/http';
import { LocationsService } from '../../../data-warehouse/locations/locations.service';
import { LoyaltyScore } from '../../../../shared/models/loyalty-scores/loyalty-scores';
import { LoyaltyScoresService } from '../loyalty-scores.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { MultiselectService } from '../../../../shared/components/multiselect/multiselect.service';
import { ProductsService } from '../../../data-warehouse/products/services/products/products.service';
import { ProfileService } from '../../../../profiles/profile.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { registerLocaleData } from '@angular/common';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-form-loyalty-scores',
  templateUrl: './form-loyalty-scores.component.html',
  styleUrls: ['./form-loyalty-scores.component.scss'],
  providers: [ LoyaltyScoresService, DictionaryService, ProductsService ]
})

export class FormLoyaltyScoresComponent implements OnInit, OnDestroy {

  companyData: CurrentCompany;
  companyId: number;
  customEntity: CustomEntity;
  customer_attributes: any[] = [];
  el: Object;
  endOfMonth: string;
  endOfQuarter: string;
  endOfSemester: string;
  endOfYear: string;
  errorData: any;
  expirationDate: any;
  filteredAvailableLanguages: string[];
  flags = this.featureFlags.flags;
  hasNullName: boolean;
  id: number;
  inputs: QuestionBase<any>[];
  isCustomEntity: boolean;
  isUsed: boolean;
  items: Object;
  lineInputDisabled: boolean;
  lineItems: Object;
  loading: boolean;
  loyaltyScoresData: LoyaltyScore;
  loyaltyScoresForm: UntypedFormGroup;
  mode: string = this.route.snapshot.data.mode;
  onUpdate: boolean;
  roleSlug = this.profileService.getStoredUserRole();
  searchBy: string;
  shouldHideValidityPointsBlock: boolean = false;
  showButton: boolean;
  subs$: Subscription[] = [];

  strategyFlags: {
    disablePoints: boolean,
    showLabelPoints: boolean,
    showLabelPointsMultiplicator: boolean,
    showLabelPriceMultiplicator: boolean,
    showPoints: boolean,
    showPointsPriceMultiplicator: boolean,
    showTransferablePoints: boolean,
    symbol: string
  };

  motivator_line_attributes: {
    condition_name?: string;
    condition: string;
    field_name?: string;
    field: string;
    id?: number;
    value: unknown;
    _destroy?: boolean;
  }[] = [];

  private destroy$: Subject<void> = new Subject<void>();
  @ViewChild(FormCustomEntityComponent) private _customEntityForm: FormCustomEntityComponent;

  get customEntityForm(): FormCustomEntityComponent {
    return this._customEntityForm;
  }

  set customEntityForm(form: FormCustomEntityComponent) {
    this._customEntityForm = form;
  }

  constructor(
    private attrConditionService: AttributesConditionsService,
    private companyService: CompanyService,
    private confirmationService: ConfirmationService,
    private customEntityService: CustomEntitiesService,
    private dateService: DateTimeZoneService,
    private featureFlags: FeatureFlagsService,
    private locationsService: LocationsService,
    private loyaltyScoreService: LoyaltyScoresService,
    private modalStatusService: ModalStatusService,
    private multiselectService: MultiselectService,
    private productService: ProductsService,
    private profileService: ProfileService,
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {
    this.companyId = this.profileService.getStoredUser().company.id;
    this.isUsed = false;
    this.lineInputDisabled = true;
    this.strategyFlags = {
      disablePoints: false,
      showLabelPoints: false,
      showLabelPointsMultiplicator: false,
      showLabelPriceMultiplicator: false,
      showPoints: false,
      showPointsPriceMultiplicator: false,
      showTransferablePoints: false,
      symbol: ''
    };
  }

  ngOnInit() {
    this.productService.displayCode = true;
    registerLocaleData(es);
    this.getParams();
  }

  ngOnDestroy() {
    if (this.subs$.length > 0) { this.subs$.forEach(s$ => s$.unsubscribe()); }
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  onHideValidityPointsBlockChange(hide: boolean) {
    this.shouldHideValidityPointsBlock = hide;
  }

  manageCustomEntityFields(trigger) {
    if(trigger?.id === 'after_create_custom_entity') {
      this.customEntity = trigger;
      this.isCustomEntity = true;
    } else {
      this.isCustomEntity = false;
      this.customEntity = null;
    }
  }

  displayExpirationDate(event) {
    if (!event) {
      this.expirationDate = null;
      return;
    }
    const expirationDate$ = this.loyaltyScoreService.getExpirationDate(event.id).subscribe(
      (res: object) => {
        if (res.hasOwnProperty('available_to')) {
          this.expirationDate = res['available_to'].split('-').reverse().join('/');
        }
      }
    );
    this.subs$.push(expirationDate$);
  }

  paintErrorsInForm(form: UntypedFormGroup, errorObject: any) {
    const errorsArray = [];
    if (errorObject.hasOwnProperty('error') && errorObject.error.hasOwnProperty('error') && errorObject.error.error) {
      errorsArray.push({
        field: 'Global',
        field_name: 'Global',
        message: errorObject.error.error
      });
    }
    this.qcs.paintErrorsInForm(this.inputs, form, errorsArray);
  }

  paintFieldErrorsInForm(form: UntypedFormGroup, errorsArray: { field: string }[]) {
    if (errorsArray) {
      errorsArray.forEach(apiError => {
        const controlKey = apiError.field;
        form.get(controlKey).setErrors({ 'notPresent': true });
        this.qcs.getInputCfgByKey(this.inputs, controlKey).hasErrors = true;
      });
    }
  }

  getErrors(): { field: string; message: string }[] {
    return this.loyaltyScoresForm.errors.map(error => {
      const field = error.field_name.indexOf('translation missing') >= 0 ? error.field : error.field_name;
      const message = error.message;
      return { field: field, message: message };
    });
  }

  removeError(error: { field: string; message: string }) {
    const errorsArray = this.loyaltyScoresForm.errors.filter(_error => _error.field !== error.field);
    this.loyaltyScoresForm.setErrors(errorsArray);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.loyaltyScoresForm, formKey, value);
  }

  hasFormKey(formKey: string): boolean {
    return this.qcs.hasFormKey(this.loyaltyScoresForm, formKey);
  }

  save() {
    const translatePath = 'resources.scores.loyalty_scores.form.warnings';
    const companyLanguage = this.profileService.getProfileCompany().locale.split('_')[0];
    const parsedLanguageSelected = this.loyaltyScoresForm.get('available_languages').value.map(lang => lang.id);
    if (this.flags.loyaltyAvailableLanguagesVisibility && !parsedLanguageSelected.includes(companyLanguage)) {
      return this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), this.translate.instant(`${translatePath}.company_lang_not_selected`));
    }

    if (this.displayErrorIfRequired(this.loyaltyScoresForm)){return;}

    if (this.displayLimitErrorIfRequired(this.loyaltyScoresForm)){return;}

    if(this.loyaltyScoresForm.value.available_from && this.loyaltyScoresForm.value.available_to &&
      this.dateService.isDate2BeforeDate1(this.loyaltyScoresForm.value.available_from, this.loyaltyScoresForm.value.available_to)){
        return this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), this.translate.instant('resources.scores.loyalty_scores.form.warnings.not_valid_dates'));
    }

    const loyaltyScoreOptions = this.loyaltyScoreService.prepareDataToDispatch(
      this.loyaltyScoresForm,
      this.motivator_line_attributes,
      this.customer_attributes
    );

    if (this.displayWarningIfInvalid(loyaltyScoreOptions)) {
      return;
    }

    if (this.flags.loyaltyAvailableLanguagesVisibility) {
      this.nameAndDescriptionPayload(loyaltyScoreOptions);
    }

    // Custom entities definition
    if(this.customEntityForm) {
      loyaltyScoreOptions['custom_entity_attributes'] = this.customEntityForm.getEntityPayload();
      loyaltyScoreOptions['custom_entity_definition_id'] = this.customEntityForm.entity.id
    } else {
      loyaltyScoreOptions['custom_entity_attributes'] = [];
    }
    // Name Validation
    if (!loyaltyScoreOptions.name) {
      return this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), this.translate.instant(`${translatePath}.name_required`));
    }

    this.loading = true;
    let save$: Subscription;

    if (this.id) {
      const updateText = `<div class="alert alert-warning" role="alert">
                           ${this.translate.instant('resources.scores.loyalty_scores.form.warnings.update_text')}
                          </div>`;
      save$ = this.loyaltyScoreService.updateData(loyaltyScoreOptions, this.id).subscribe(
        () => {
          this.loading = false;
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.scores.loyalty_scores.form.warnings.update_title'),
            updateText
          ).catch(() => {});
          this.modalStatusService.modalStatus.emit();
          this.returnToList();
        },
        (errorData: HttpErrorResponse) => {
          this.loading = false;
          if ( errorData?.error?.error ) {
            this.paintFieldErrorsInForm(this.loyaltyScoresForm, errorData.error.errors);
            this.paintErrorsInForm(this.loyaltyScoresForm, errorData);
          }
        }
      );
    } else {
      const successText = `<div class="alert alert-warning" role="alert">
                            ${this.translate.instant('resources.scores.loyalty_scores.form.warnings.success_text')}
                           </div>`;
      save$ = this.loyaltyScoreService.sendData(loyaltyScoreOptions).subscribe(
        () => {
          this.loading = false;
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.scores.loyalty_scores.form.warnings.success_title'),
            successText
          ).catch(() => {});
          this.modalStatusService.modalStatus.emit();
          this.returnToList();
        },
        (errorData: HttpErrorResponse) => {
          this.loading = false;
          if ( errorData?.error?.error ) {
            this.paintFieldErrorsInForm(this.loyaltyScoresForm, errorData.error.errors);
            this.paintErrorsInForm(this.loyaltyScoresForm, errorData);
          }
        }
      );
    }
    this.subs$.push(save$);
  }

  countrySelector() {
    const countriesIds = this.inputs.find(input => input.key === 'countries').value.map(item => item.id);
    this.locationsService.setCountries(countriesIds);
  }

  handleLocationChange(event) {
    const locationInput = this.getInputConfig('location_ids');
    const currentIds = locationInput.selectedIds;
    const newArray = currentIds.filter(id => id !== event.id);
    locationInput.selectedIds = newArray;
  }

  addMotivatorLine() {
    const lineOption = this.loyaltyScoresForm.get('lineOptions').value;
    const lineCondition = this.loyaltyScoresForm.get('lineConditions').value;
    const lineInput = this.loyaltyScoresForm.get('lineInput').value;
    const lineConditionSelectMulti = this.loyaltyScoresForm.get('lineConditionSelectMulti').value;

    if (lineOption?.length && lineCondition?.length && (lineInput || lineConditionSelectMulti)) {
      this.motivator_line_attributes.push({
        condition: lineOption?.[0]?.id,
        condition_name: lineOption?.[0]?.name,
        field: lineCondition?.[0]?.id,
        field_name: lineCondition?.[0]?.name,
        value: lineInput ? lineInput : lineConditionSelectMulti.map(el => el.id),
        id: null
      });
    }
    this.loyaltyScoresForm.get('lineOptions').reset();
    this.loyaltyScoresForm.get('lineConditions').reset();
    this.loyaltyScoresForm.get('lineInput').reset();
    this.loyaltyScoresForm.get('lineConditionSelectMulti').reset();
  }



  deleteMotivatorLine(index) {
    if (this.motivator_line_attributes[index].id) {
      this.motivator_line_attributes[index]['_destroy'] = true;
    } else {
      this.motivator_line_attributes.splice(index, 1);
    }
  }

  addCustomerAttributeLine() {

    const customerOptions = this.loyaltyScoresForm.get('customerOptions').value;
    const customerConditions = this.loyaltyScoresForm.get('customerConditions').value;
    const customerSelectMulti = this.loyaltyScoresForm.get('customerSelectMulti').value;
    const customerInput = this.loyaltyScoresForm.get('customerInput').value;

    if (customerOptions?.length) {
      this.customer_attributes.push({
        condition: customerOptions[0].id,
        field: customerConditions[0].id,
        field_name: customerConditions[0].name,
        value: customerInput ? customerInput : customerSelectMulti.map(el => el.id),
        value_content: customerInput ? customerInput : customerSelectMulti.map(el => el.name)
      });
    }

    this.loyaltyScoresForm.get('customerOptions').reset();
    this.loyaltyScoresForm.get('customerConditions').reset();
    this.loyaltyScoresForm.get('customerSelectMulti').reset();
    this.loyaltyScoresForm.get('customerInput').reset();
  }

  deleteCustomerAttribute(index) {
    if (this.customer_attributes[index].id) {
      this.customer_attributes[index]['_destroy'] = true;
    } else {
      this.customer_attributes.splice(index, 1);
    }
  }

  customerLineConditionChange() {
    this.loyaltyScoresForm.get('lineOptions').reset();
    this.loyaltyScoresForm.get('lineConditionSelectMulti').reset();
    this.loyaltyScoresForm.get('lineInput').reset();
  }

  lineOptionsChange(event:object){
    if (!event){
      this.inputs.find( input => input.key === 'lineInput').disabled = true;
      this.loyaltyScoresForm.get('lineInput').reset();
      return;
    }
    this.inputs.find( input => input.key === 'lineInput').disabled = false;
  }

  customerConditionChange(event) {

    if (!event){
      this.cleanCustomerOptionsAndSelectMulti()
      return;
    }

    this.cleanCustomerOptionsAndSelectMulti()
    const replacedId = event.id.replace('.', '_');
    this.attrConditionService.getConditionById(replacedId).subscribe(item => {
      this.items = item;
      this.inputs.find(input => input.key === 'customerOptions')['options'] = item['operators'].map(operator => ({ id: operator.name, name: operator.label }));
      this.multiselectService.updateDropdownList.next('customerOptions');
      this.multiselectService.updateDropdownList.next('customerSelectMulti');
      this.loyaltyScoresForm.value.customerOptions = [];
      this.inputs.find(input => input.key === 'customerOptions').value = [];
      this.inputs.find(input => input.key === 'customerSelectMulti')['options'] = item['values'];
    });
  }

  cleanCustomerOptionsAndSelectMulti(){
    this.loyaltyScoresForm.get('customerOptions').reset();
    this.loyaltyScoresForm.get('customerSelectMulti').reset();
    this.loyaltyScoresForm.get('customerInput').reset();
  }

  returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    }
  }

  searchByResource(event) {
    this.productService.searchBy = event.id
  }

  resetOptionsValues(formKey: string) {
    const inputKey = this.inputs.find(input => input.key === formKey);
    this.loyaltyScoresForm.get(formKey).reset();
    inputKey.selectedIds = [];
  }

  private getParams() {
    const route$ = this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getLoyaltyScoresById();
      } else {
        this.inputs = this.loyaltyScoreService.getInputs({});
        this.loyaltyScoresForm = this.qcs.toFormGroup(this.inputs);
        this.getCompanyData();
      }
    });
    this.subs$.push(route$);
  }

  private getCompanyData() {
    this.companyService.getCompanyWithRepresenterType(this.companyId, 'element').subscribe(
      (resp: CurrentCompany) => {
        this.companyData = resp;
        if (this.companyData['available_languages']) {
          const availableLanguagesOptions = this.inputs.find(input => input.key === 'available_languages').options;
          const filtered = availableLanguagesOptions.filter(item => this.companyData['available_languages'].map(_item => _item.slice(0, 2)).includes(item.id));
          this.loyaltyScoresForm.get('available_languages').patchValue(filtered);
          this.filteredAvailableLanguages = filtered;
        }
      }
    );
  }

  private getLoyaltyScoresById() {
    const lScore$ = this.loyaltyScoreService.getLoyaltyScoresById(this.id).subscribe(
      data => {
        this.loyaltyScoresData = new LoyaltyScore(data);
        if(this.loyaltyScoresData.embeddedLoyaltyScoreMoment.isCustomEntity) {
          this.isCustomEntity = true;
          this.getCustomEntityData(this.loyaltyScoresData.customEntityDefinitionId);
        }
        this.isUsed = data.used ? data.used : false;
        this.inputs = this.loyaltyScoreService.getInputs(data);
        this.loyaltyScoresForm = this.qcs.toFormGroup(this.inputs);
        this.motivator_line_attributes = this.getMotivatorLines(data);
        this.customer_attributes = this.getCustomerLines(data);
        this.evalIfCalculateDate();
        getTranslationInputs(this.loyaltyScoresData, this.qcs, this.loyaltyScoresForm, this.inputs, this.mode);
        this.filteredAvailableLanguages = data.available_languages;
      });
    this.subs$.push(lScore$);
  }

  private getCustomEntityData(customEntity) {
    this.customEntityService.getById(customEntity).pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: CustomEntity) => {
        this.customEntity = response;
        this.isCustomEntity = true;
      },
      error: (error: HttpErrorResponse) => console.warn('custom entity get error', error)
    });
  }

  private nameAndDescriptionPayload(payload) {
    const nameKey = Object.keys(this.loyaltyScoresForm.value).filter(item => /name_/.test(item)).map(element => element.split('_')[1]);
    const availableLanguages = [];
    if (nameKey && nameKey.length > 0) {
      payload.name = {};
      payload.description = {};
      nameKey.forEach(key => {
        payload['name'][`${key}`] = this.loyaltyScoresForm.value[`name_${key}`];
        payload['description'][`${key}`] = this.loyaltyScoresForm.value[`description_${key}`];
        availableLanguages.push(key);
      });
      this.hasNullName = Object.values(payload.name).some(x => x === null || x === '');
      if (this.hasNullName) {
        payload['name'] = null;
        payload['description'] = null;
      }
    }
    payload.available_languages = availableLanguages;
  }

  private evalIfCalculateDate() {
    if (this.loyaltyScoresForm.value['expiration_period_type'] &&
      this.loyaltyScoresForm.value['expiration_period_type'] === 'fixed' &&
      this.loyaltyScoresForm.value['fixed_option_selected'] &&
      this.loyaltyScoresForm.value['fixed_option_selected'].length > 0) {
      this.displayExpirationDate(this.loyaltyScoresForm.value['fixed_option_selected'][0]);
    }
  }

  private getMotivatorLines(data): { condition: string; field: string; value: any; }[] {
    return data.motivator_line_attributes.map(el => ({
      condition: el.condition,
      condition_name: el.condition_name,
      field: el.field,
      field_name: el.field_name ?? this.loyaltyScoreService.getlineConditionsFieldName(el.field),
      value: el.value,
      id: el.id
    }));
  }

  private getCustomerLines(data): { condition: string; field: string; field_name: string; value: any }[] {
    return data.customer_attributes.map(element => {
      return {
        condition: element.condition,
        field: element.field,
        field_name: element.field_name,
        value: element.value,
        value_content: element.value_content,
        id: element.id
      };
    });
  }

  private displayWarningIfInvalid(payload: object): boolean {
    const arePointsNegative = payload['points'] && payload['points'] < 0;
    const isMultiplicatorNegative = payload['points_price_multiplicator'] && payload['points_price_multiplicator'] < 0;

    if (arePointsNegative) {
      this.confirmationService.displayAlert(
        '',
        this.translate.instant('resources.scores.loyalty_scores.form.warnings.negative_points'),
        'warning'
      ).catch(() => {});
      return true;
    } else if (isMultiplicatorNegative) {
      this.confirmationService.displayAlert(
        '',
        this.translate.instant('resources.scores.loyalty_scores.form.warnings.negative_points_multiplicator'),
        'warning'
      ).catch(() => {});
      return true;
    }
  }

  private displayLimitErrorIfRequired(form: UntypedFormGroup): boolean{

    if (form.value.once.length > 0 && form.value.once[0].id !== 'allways' && !form.value.once_limit ){
      const errorsArray = [{
        field: 'Global',
        field_name: 'Global',
        message: this.translate.instant('resources.scores.loyalty_scores.fields.limits_fields_error')
      }]
      this.qcs.paintErrorsInForm(this.inputs, form, errorsArray);
      return true;
    }
    return false;
  }

  private displayErrorIfRequired(form: UntypedFormGroup): boolean {
    if (form.get('strategy_type')?.value?.[0]?.id === 'chunk' && (!form.get('points_price_multiplicator').value || !form.get('points').value)){
      const errorsArray = [{
        field: 'Global',
        field_name: 'Global',
        message: this.translate.instant('resources.scores.loyalty_scores.fields.chunks_fields_error')
      }]
      this.qcs.paintErrorsInForm(this.inputs, form, errorsArray);
      return true;
    }
    return false;
  }

}
