<div class="container-fluid clearfix">
  <ng-container *ngIf="element">
    <div class="row">
      <div class="col">
        <div class="card no-mrg-btm">
          <div class="card-block">
            <div class="table-overflow">
              <table class="table table-hover" aria-hidden="true">
                <tbody>
                  <tr *ngIf="itemType">
                    <td class="no-border-top"><b [translate]="'resources.versions.fields.item_type'"></b></td>
                    <td class="no-border-top">{{itemType}}</td>
                  </tr>
                  <tr *ngIf="element.item_id">
                    <td><b [translate]="'resources.versions.fields.item_id'"></b></td>
                    <td>{{element.item_id}}</td>
                  </tr>
                  <tr *ngIf="element.event">
                    <td><b [translate]="'resources.versions.fields.event'"></b></td>
                    <td>{{element.event}}</td>
                  </tr>
                  <tr *ngIf="element.whodunnit_info.email">
                    <td><b [translate]="'resources.versions.fields.email'"></b></td>
                    <td>{{element.whodunnit_info.email}}</td>
                  </tr>
                  <tr *ngIf="element.created_at">
                    <td><b [translate]="'resources.versions.fields.created_at'"></b></td>
                    <td>{{element.created_at | dateTimeZone: 'DD/MM/YYYY HH:mm'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mrg-top-15">
      <div class="col-md-12">
        <div class="tabs-container">
          <ul class="nav nav-tabs border-radius-4 nav-border" role="tablist">
            <li class="nav-item" (click)="changeTab('params')">
              <a class="nav-link pointer"
                 [ngClass]="{'active': selectedTab === 'params'}">
                {{paramsLabel()}}
              </a>
            </li>
            <li class="nav-item" (click)="changeTab('object_changes')">
              <a *ngIf="element.event == 'update'"
                 class="nav-link pointer"
                 [ngClass]="{'active': selectedTab === 'object_changes'}">
                {{'resources.versions.event.changes' | translate}}
              </a>
            </li>
          </ul>

          <div *ngIf="selectedTab === 'params'">
            <ace #editor class="height-400" [value]="element.params" [mode]="'json'" theme="monokai" [disabled]="true"></ace>
          </div>
          <div *ngIf="selectedTab === 'object_changes'">
            <ace #editor class="height-400" [value]="element.object_changes" [mode]="'json'" theme="monokai" [disabled]="true"></ace>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
