
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MODULE_HELP_CENTER_LINKS } from '../../shared/constants/module-helpcenter-links.constants';
import { MODULE_ICONS } from '../../shared/constants/module-icons.constants';
import { Modules } from '../../shared/enums/modules.enums';
import { LaunchDarklyService } from '../../shared/services/launch-darkly/launchdarkly.service';
import { SideNavService } from '../side-nav/side-nav.service';
import { ProfileService } from './../../profiles/profile.service';
import { FeatureFlagsService } from './../../shared/services/feature-flags.service';
import { HomeItem, homeModules, homeRevampModules } from './home-modules.constants';
import { SideNavRevampService } from '../side-nav-revamp/side-nav-revamp.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  modules: HomeItem[] = [];
  storedUser = this.profileService.getStoredUser();
  flags = this.featureFlags.flags;
  helpCenterLinks = MODULE_HELP_CENTER_LINKS;
  icons = MODULE_ICONS;
  isNewHome = false;

  constructor(
    private readonly router: Router,
    private readonly featureFlags: FeatureFlagsService,
    private readonly profileService: ProfileService,
    private readonly sideNavService: SideNavService,
    private readonly sideNavRevampService: SideNavRevampService,
    private readonly launchDarklyService: LaunchDarklyService
  ) { }

  ngOnInit(): void {
    this.setModules().catch(() => {});
  }

  private async setModules(): Promise<void> {
    const defaultHomeModules = [...homeModules];
    const newHomeRevampModules = [...homeRevampModules];
    return this.launchDarklyService.client.waitUntilReady().then(() => {
      if (this.flags.showNewMenuHome) {
        this.isNewHome = true;
        const modulesConfiguration = this.sideNavRevampService.setModulePermissions([...newHomeRevampModules]);
        if (this.storedUser?.partner?.id) {
         const basicLoyaltyAnalyticsModule = modulesConfiguration.find(module => module.id === Modules.BasicLoyaltyAnalytics);
         basicLoyaltyAnalyticsModule.route = 'campaign-performance/earn-rules';
        }
        this.modules = modulesConfiguration;
      } else {
        if (!this.flags.loyaltyAnalyticsProgramPerformanceVisibility) {
          const loyaltyAnalyticsModule = defaultHomeModules.find(module => module.id === Modules.LoyaltyAnalytics);
          loyaltyAnalyticsModule.route = 'points-rewards';
        }
        this.modules = this.sideNavService.setModulePermissions([...defaultHomeModules]);
      }
    });
  }

  navigate(module: HomeItem): void {
    const url = module.route ? `/${module.id}/${module.route}` : `/${module.id}`;
    this.router.navigateByUrl(url).catch(() => {});
  }

  isNotAuth(module: HomeItem): boolean {
    return this.sideNavService.isNotAuth(module);
  }

  showNotAvailable(): void {
    this.sideNavService.showNotAvailable();
  }

  openHelpCenter(moduleId: string, event: Event): void {
    event.stopPropagation();
    window.open(this.helpCenterLinks[moduleId], '_blank');
  }

}
