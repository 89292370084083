import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import es from '@angular/common/locales/es';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-show-loyalty-score-moment',
  templateUrl: './show-loyalty-score-moment.component.html',
  styleUrls: ['./show-loyalty-score-moment.component.css']
})
export class ShowLoyaltyScoreMomentComponent implements OnInit {

  apiEndPoint: string;
  element: any;
  id: number;
  isModal: boolean;

  constructor(
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private resourcesService: ResourcesService,
    private router: Router
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.getParams();
    this.getData();
  }

  getParams() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'loyalty_rule_moments';
      }
    });
  }

  getData() {
    this.resourcesService.getData({ apiEndPoint: this.apiEndPoint + '/' + this.id }).subscribe({
      next: (res: any) => {
        this.element = res;
      },
      error: (error: HttpErrorResponse) => {
        this.confirmationService.displayHttpErrorAlert(error);
      }
    });
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  goToEdit() {
    this.router.navigate([{ outlets: { modal: `update/loyalty_rule_moments/${this.id}`}}]).catch(() => {});
  }

}
