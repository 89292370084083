<div class="modal-backdrop fade in" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="plan">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="mrg-left-15 mrg-btm-5 first-letter-upper">{{'resources.plans.messages.modal_schedule_title' | translate}}:&nbsp;<span>{{plan.name}}</span></h4>
        <button class="modal-close" (click)="closeModal()">
          <i class="ti-close"></i>
        </button>
      </div>

      <div class="modal-body background-gray">
        <div class="row">
          <div class="col-md-12">

            <div class="container-fluid">
              <div class="row">
                <div class="mrg-top-10 col-md-12">
                  <div class="alert alert-danger d-flex align-items-center space-between pdd-left-20 pdd-right-0 pdd-vertical-5" *ngIf="isPlanAlertStatus([PlanAlertsStatus.Failed, PlanAlertsStatus.Error])">
                    {{'resources.plans.alerts.error' | translate}}
                    <a class="btn btn-icon btn-default btn-sm pdd-vertical-10 no-mrg-vertical pdd-vertical-10"
                      [routerLink]="['', { outlets: { modal: ['show', 'plans', id, 'alerts'] } }]">
                      <i class="fa-duotone fa-solid fa-warning font-size-18 text-danger"></i>
                      {{ 'common.buttons.check_report' | translate }}
                    </a>
                  </div>

                  <div class="card-block no-pdd">
                    <div class="row">
                      <div class="col-md-8 ml-auto mr-auto">
                        <label class="text-bold">{{'resources.plans.messages.schedule_title' | translate}}</label>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-8 ml-auto mr-auto">
                        <form [formGroup]="schedulePlanForm">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group no-mrg-btm">
                                <app-question [question]="getInputConfig('schedule')"
                                              [form]="schedulePlanForm"
                                              (blur)="handleSchedule($event)">
                                </app-question>
                              </div>
                            </div>
                          </div>

                          <div class="row" *ngIf="hasFormKeyWithValue('schedule', 'schedule')">
                            <div class="col-md-12">
                              <div class="form-group no-mrg-btm">
                                <label for="schedule_date" class="text-bold">{{'resources.plans.fields.date' | translate}}<span class="text-bold text-danger">&nbsp;*</span></label>
                                <app-question [question]="getInputConfig('schedule_date')"
                                              [form]="schedulePlanForm">
                                </app-question>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer background-white">
        <div class="pull-right d-flex align-items-center">
          <button class="btn btn-icon pull-right"
            *ngIf="flags.showPlansAlertsConfiguration"
            (click)="openValidationModal()"
            [ngClass]="{
              'btn-success': isPlanAlertStatus(PlanAlertsStatus.Success),
              'btn-info': isPlanAlertStatus(PlanAlertsStatus.Pending),
              'btn-secondary': isPlanAlertStatus(PlanAlertsStatus.Generating),
              'btn-warning': !isPlanAlertStatus(PlanAlertsStatus.Success, PlanAlertsStatus.Generating, PlanAlertsStatus.Pending)
            }"
            [disabled]="this.plan?.alerts_status === PlanAlertsStatus.Generating">
              <i class="fa fa-spinner fa-spin" *ngIf="isPlanAlertStatus(PlanAlertsStatus.Generating)"></i>
              <i class="fa fa-check" *ngIf="isPlanAlertStatus(PlanAlertsStatus.Success)"></i>
              <i class="fa fa-exclamation-triangle" *ngIf="isPlanAlertStatus([PlanAlertsStatus.Error, PlanAlertsStatus.Failed])"></i>
              <span [translate]="'resources.plans.buttons.validate_alerts'"></span>
          </button>
          <button class="btn pull-right" id="scheduleConfirmButton"
            (click)="handleFormSelection()"
            [ngClass]="disableConfirmButton() ? 'btn-secondary' : 'btn-info'"
            [disabled]="disableConfirmButton()">{{'common.buttons.confirm' | translate}}
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
