import { ActivatedRoute, Router } from "@angular/router";
import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { ConfirmationService } from "../../../../shared/services/confirmation.service";
import { DictionaryService } from "../../../../shared/services/dictionary.service";
import { FeaturesService } from "../../../data-warehouse/products/services/products-features/features.service";
import { FeatureTaxonomiesService } from "../../../data-warehouse/products/services/products-feature-taxonomies/feature-taxonomies.service";
import { FormGroup } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { ImageQuestion } from "../../../../shared/models/forms/question-image";
import { ModalStatusService } from "../../../../shared/services/modal-status.service";
import { MultiSelectQuestion } from "../../../../shared/models/forms/question-multiselect";
import { Option } from "../../../../shared/models/common/option";
import { Partner } from "../partner";
import { PartnersService } from "../partners.service";
import { QuestionBase } from "../../../../shared/models/forms/question-base";
import { QuestionControlService } from "../../../../shared/services/question-control.service";
import { RadioQuestion } from "../../../../shared/models/forms/question-radio";
import { Subject, Subscription, distinctUntilChanged, takeUntil } from "rxjs";
import { TextboxQuestion } from "../../../../shared/models/forms/question-textbox";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-form-partners',
  templateUrl: './partner-form.component.html',
  styleUrls: ['./partner-form.component.scss']
})

export class PartnerFormComponent implements OnInit, OnDestroy {
  id: number | string;
  inputs: QuestionBase<unknown>[];
  missingImg = false;
  partner: Partner;
  partnerForm: FormGroup;

  private subs$: Subscription[] = [];
  private imageData: { filename: any; filesize: any; filetype: any; base64: string; };
  private destroy$: Subject<void> = new Subject<void>();

  @Output('formValidity') formValidity: EventEmitter<string> = new EventEmitter();

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly dictionarySrv: DictionaryService,
    private readonly featuresService: FeaturesService,
    private readonly featureTaxonomiesService: FeatureTaxonomiesService,
    private readonly modalStatusService: ModalStatusService,
    private readonly partnersService: PartnersService,
    private readonly qcs: QuestionControlService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translate: TranslateService
  ){}

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.featuresService.treatPkAsId = false;
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: boolean): boolean {
    return this.qcs.hasFormKeyWithValue(this.partnerForm, formKey, value);
  }

  onTypeSelectorChange(): void {
    this.getInputConfig('supplier_id').value = null;
    this.partnerForm.get('supplier_id').patchValue(null);
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.imageData = {
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
          base64: reader.result.toString().split(',')[1]
        };
      };
    }
  }

  sendData() {
    const payload = this.getPayload();
    this.modalStatusService.formLoaderStatus.emit('loading');
    const req$ = this.id ? this.partnersService.updateById(payload, this.id) : this.partnersService.create(payload);
    req$.subscribe({
      next: () => this.handleSuccessRequest(),
      error: (err: HttpErrorResponse) => {
        this.modalStatusService.formLoaderStatus.emit('loading_finished');
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), err.error.error)
      }
    });
  }

  private getPayload(): object {
    const formValue = this.partnerForm.getRawValue();
    const payload = {
      ...formValue,
      type: formValue?.type?.[0]?.id ?? null,
      supplier_id: formValue?.supplier_id?.[0]?.id ?? null
    };
    payload['image'] = this.imageData ? this.imageData : null;
    return payload;
  }

  private handleSuccessRequest(): void {
    const successTitle = this.translate.instant(this.id ? 'resources.scores.partners.warnings.update_title' : 'resources.scores.partners.warnings.success_title');
    const successText = this.translate.instant(this.id ? 'resources.scores.partners.warnings.update_text' : 'resources.scores.partners.warnings.success_text');
    this.confirmationService.displaySuccessAlert(successTitle, successText).catch(() => {});
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    this.modalStatusService.modalStatus.emit();
    this.closeModal();
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.modalStatusService.formLoaderStatus.emit('loading_data');
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getPartnerById();
      } else {
        this.setInputsAndForm();
      }
    });
  }

  private getPartnerById(): void {
    this.partnersService.getById(this.id).pipe(takeUntil(this.destroy$)).subscribe(
      (data: Partner) => this.setInputsAndForm(data),
      (errorData: HttpErrorResponse) => this.handleErrorResponse(errorData)
    );
  }

  private handleErrorResponse(err: HttpErrorResponse) {
    this.confirmationService.displayErrorAlert(
      this.translate.instant('common.error'),
      err.error.error
    )
    this.closeModal();
  }

  private setInputsAndForm(partner?: Partner):void {
    this.partner = partner;
    this.missingImg = partner?.thumbnail_medium.indexOf('images/medium/missing') >= 0;
    const supplierTaxonomies$ = this.featureTaxonomiesService.fetchMultiselect(null, null, {kind: 'supplier'});
    const taxonomies$ = supplierTaxonomies$.subscribe(supplierTaxonomies => {
      this.inputs = this.getInputs(partner, supplierTaxonomies);
      this.partnerForm = this.qcs.toFormGroup(this.inputs);
      this.formStatusChanges();
      this.modalStatusService.formLoaderStatus.emit('loading_data_finished');
    });
    this.subs$.push(taxonomies$);
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.partnerForm.status));
    const formStatus$ = this.partnerForm.statusChanges.pipe(distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
    this.subs$.push(formStatus$);
  }

  private getInputs(partner?: Partner, supplierTaxonomies?: object): QuestionBase<unknown>[] {
    this.featuresService.treatPkAsId = true;
    const supplierTaxonomySlug = this.featureTaxonomiesService.getTaxonomySlug(supplierTaxonomies);
    const inputs = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: partner?.name ?? null,
        required: true
      }),
      new TextboxQuestion({
        key: 'description',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: partner?.description ?? null
      }),
      new ImageQuestion({
        key: 'image',
        type: 'file',
        cssClasses: 'input-default',
      }),
      new MultiSelectQuestion({
        key: 'type',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: false },
        options: this.typeOptions(),
        value: this.id ? this.typeOptions().filter(option => option.id === partner?.type) : [this.typeOptions()[1]]
      }),
      new MultiSelectQuestion({
        key: 'supplier_id',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: true },
        dataSource: this.featuresService,
        filters: { taxonomy_slug: supplierTaxonomySlug ? supplierTaxonomySlug : 'none' },
        selectedIds: partner?.supplier_id ? [partner.supplier_id] : []
      }),
      new RadioQuestion({
        key: 'active',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.statusOptions(),
        value: partner && partner.active ? this.statusOptions()[0].id : this.statusOptions()[1].id
      })
    ];

    return inputs;
  }

  private statusOptions() {
    return this.dictionarySrv.getValuesByKey('partners_status');
  }

  private typeOptions(): Option[] {
    return this.dictionarySrv.getValuesByKey('partners_type');
  }

  private closeModal(): void {
    this.router.navigate([{ outlets: { modal: null }}]).catch(() => {});
  }
}