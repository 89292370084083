import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import { QuestionBase } from '../../../../../../shared/models/forms/question-base';
import { ConfirmationService } from '../../../../../../shared/services/confirmation.service';
import { ModalStatusService } from '../../../../../../shared/services/modal-status.service';
import { QuestionControlService } from '../../../../../../shared/services/question-control.service';
import { S3Service } from '../../../../../../shared/services/s3.service';
import { FeaturesService } from '../../../services/products-features/features.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-import-product-features',
  templateUrl: './import-product-features.component.html',
  styleUrls: ['./import-product-features.component.scss'],
  providers: [FeaturesService]
})
export class ImportProductFeaturesComponent implements OnInit, OnDestroy {

  importProductFeaturesForm: UntypedFormGroup;
  inputs: QuestionBase<unknown>[];

  private readonly destroy$ = new Subject<void>();

  @Output() formValidity = new EventEmitter<string>() ;

  constructor(
    private readonly qcs: QuestionControlService,
    private readonly confirmationService: ConfirmationService,
    private readonly modalStatusService: ModalStatusService,
    private readonly translate: TranslateService,
    private readonly featuresService: FeaturesService,
    private readonly s3Service: S3Service,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  sendData(): void {
    const files = this.importProductFeaturesForm.value.csv;
    if (files?.length) {
      const file = files[0];
      this.s3Service.uploadFile(file).then(filePath => {
        this.featuresService.importCsv(filePath).pipe(takeUntil(this.destroy$), finalize(() => this.closeModal())).subscribe({
          next: () => {
            const import_title = this.translate.instant(`resources.product_features.form.warnings.import_title`);
            const import_text = this.translate.instant(`resources.product_features.form.warnings.import_text`);
            this.confirmationService.displaySuccessAlert(import_title, import_text).catch(() => {});
            this.modalStatusService.modalStatus.emit();
          },
          error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
        });
      });
    }
  }

  private getParams(): void {
    this.inputs = this.featuresService.getImportInputs();
    this.importProductFeaturesForm = this.qcs.toFormGroup(this.inputs);
    this.formStatusChanges();
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.importProductFeaturesForm.status));
    this.importProductFeaturesForm.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
  }

  private closeModal(): void {
    this.router.navigate([{ outlets: { modal: null }}]).catch(() => {});
  }
}
