import { AbstractControl, UntypedFormGroup, ValidationErrors, Validators } from "@angular/forms";
import { isNullOrUndefined } from "../../../../../utils/common.utils";
import { checkValue1GreaterThanValue2 } from "../../../../validations.service";
import { isCtrlMinNumber, setMultipleValidationCustomErrorsMark } from "../../../validations/common.validations";

const REQUIRED_ERROR_MESSAGE = 'valueRequired';
const MIN_VALUE_REQUIRED_ERROR_MESSAGE = 'minValueRequired';
const MIN_VALUE_REQUIRED_VALIDATION_MESSAGE = 'validMinNumberRequired';

export const MIN_OPERATOR_VALUE = 0;
export const MIN_OPERATOR_VALUE_2 = 0.1;

export function getFormGroupValidations(control: UntypedFormGroup): ValidationErrors | null {
  const totalSumOp = control.get('total_sum_operator');
  const totalSumValue = control.get('total_sum_value');
  const totalSumValue1 = control.get('total_sum_value_1');
  const totalSumValue2 = control.get('total_sum_value_2');
  const shippingCostOp = control.get('shipping_cost_operator');
  const shippingCostValue = control.get('shipping_cost_value');
  const shippingCostValue1 = control.get('shipping_cost_value_1');
  const shippingCostValue2 = control.get('shipping_cost_value_2');
  const paymentCostOp = control.get('payment_cost_operator');
  const paymentCostValue = control.get('payment_cost_value');
  const paymentCostValue1 = control.get('payment_cost_value_1');
  const paymentCostValue2 = control.get('payment_cost_value_2');

  const controls = [totalSumValue, totalSumValue1, totalSumValue2, shippingCostValue, shippingCostValue1, shippingCostValue2, paymentCostValue, paymentCostValue1, paymentCostValue2];
  controls.forEach(_ctrl => _ctrl.setValidators([]));

  const totalSumValidator = setOperatorsValidators(totalSumOp, totalSumValue, totalSumValue1, totalSumValue2, 'totalSumOpRequired', 'totalSumValueRequired');
  const shippingCostValidator = setOperatorsValidators(shippingCostOp, shippingCostValue, shippingCostValue1, shippingCostValue2, 'shippingCostOpRequired', 'shippingCostValueRequired', true);
  const paymentCostValidator = setOperatorsValidators(paymentCostOp, paymentCostValue, paymentCostValue1, paymentCostValue2, 'paymentCostOpRequired', 'paymentCostValueRequired', true);

  let validators = {};
  [totalSumValidator, shippingCostValidator, paymentCostValidator].forEach(validator => {
    if (validator) {
      validators = {...validators, validator};
    }
  });

  if (Object.keys(validators).length) {
    return validators;
  }

  return null;
}

function setOperatorsValidators(
  operatorCtrl: AbstractControl,
  operatorValueCtrl: AbstractControl,
  operatorValue1Ctrl: AbstractControl,
  operatorValue2Ctrl: AbstractControl,
  operatorRequiredValidator: string,
  operatorValueRequiredValidator: string,
  minValidation: boolean = false
): ValidationErrors | null {
  const value = operatorValueCtrl?.value ?? null;
  const value1 = operatorValue1Ctrl.value ?? null;
  const value2 = operatorValue2Ctrl.value ?? null;

  if (operatorCtrl?.value?.length) {
    if (operatorCtrl.value[0].id === 'bt') {
      return checkBtOperatorValuesValidation(operatorValue1Ctrl, operatorValue2Ctrl, value1, value2, minValidation);
    } else {
      return checkValueValidation(operatorValueCtrl, value, operatorValueRequiredValidator, minValidation);
    }
  } else {
    return checkOperatorValidation(operatorCtrl, value, operatorRequiredValidator);
  }
}

function checkValueValidation(
  operatorValueCtrl: AbstractControl,
  value: number | string,
  operatorValueRequiredValidator: string,
  minValidation: boolean
): ValidationErrors | null {
  if (isNullOrUndefined(value) || isNaN(parseFloat(value as string))) {
    const valueValidators = minValidation ? [Validators.required, Validators.min(MIN_OPERATOR_VALUE)] : [Validators.required];
    const ctrlsConfig = [{ ctrl: operatorValueCtrl, validators: valueValidators, errorKeyMessage: REQUIRED_ERROR_MESSAGE }];
    return setMultipleValidationCustomErrorsMark(ctrlsConfig, operatorValueRequiredValidator);
  } else {
    const isCtrlValueMinNumber = isCtrlMinNumber(operatorValueCtrl, MIN_OPERATOR_VALUE);
    if (minValidation && !isCtrlValueMinNumber) {
      const ctrlsConfig = [{ ctrl: operatorValueCtrl, validators: [Validators.required, Validators.min(MIN_OPERATOR_VALUE)], errorKeyMessage: MIN_VALUE_REQUIRED_ERROR_MESSAGE }];
      return setMultipleValidationCustomErrorsMark(ctrlsConfig, MIN_VALUE_REQUIRED_VALIDATION_MESSAGE);
    }
    return null;
  }
}

function checkOperatorValidation(operatorCtrl: AbstractControl, value: number | string, operatorRequiredValidator: string): ValidationErrors | null {
  operatorCtrl.setErrors(null);
  if (!isNullOrUndefined(value) && !isNaN(parseFloat(value as string))) {
    if (!operatorCtrl.value || (operatorCtrl.value && operatorCtrl.value.length === 0)) {
      operatorCtrl.setErrors({[REQUIRED_ERROR_MESSAGE]: true});
      return {[operatorRequiredValidator]: true};
    }
    return null;
  }
  return null;
}

function checkBtOperatorValuesValidation(operatorValue1Ctrl: AbstractControl, operatorValue2Ctrl: AbstractControl, value1: string | number, value2: string | number, minValidation: boolean): ValidationErrors | null {
  const isCtrlValue1MinNumber = isCtrlMinNumber(operatorValue1Ctrl, MIN_OPERATOR_VALUE);
  const isCtrlValue2MinNumber = isCtrlMinNumber(operatorValue2Ctrl, MIN_OPERATOR_VALUE_2);
  const value1Validators = minValidation ? [Validators.required, Validators.min(MIN_OPERATOR_VALUE)] : [Validators.required];
  const value2Validators = minValidation ? [Validators.required, Validators.min(MIN_OPERATOR_VALUE_2)]: [Validators.required];
  const isValidValue1 = minValidation ? isCtrlValue1MinNumber : !isNullOrUndefined(value1);
  const isValidValue2 = minValidation ? isCtrlValue2MinNumber : !isNullOrUndefined(value2);

  if ((isNullOrUndefined(value1) || (!value1 && value1 !== 0))  && (isNullOrUndefined(value2) || (!value2 && value2 !== 0))) {
    const ctrlsConfig = [
      { ctrl: operatorValue1Ctrl, validators: value1Validators, errorKeyMessage: REQUIRED_ERROR_MESSAGE },
      { ctrl: operatorValue2Ctrl, validators: value2Validators, errorKeyMessage: REQUIRED_ERROR_MESSAGE }
    ];
    return setMultipleValidationCustomErrorsMark(ctrlsConfig, REQUIRED_ERROR_MESSAGE);
  } else if ((isNullOrUndefined(value1) || (!value1 && value1 !== 0)) && isValidValue2) {
    const ctrlsConfig = [{ ctrl: operatorValue1Ctrl, validators: value1Validators, errorKeyMessage: REQUIRED_ERROR_MESSAGE }];
    return setMultipleValidationCustomErrorsMark(ctrlsConfig, REQUIRED_ERROR_MESSAGE);
  } else if (isValidValue1 && (isNullOrUndefined(value2) || (!value2 && value2 !== 0))) {
    const ctrlsConfig = [{ ctrl: operatorValue2Ctrl, validators: value2Validators, errorKeyMessage: REQUIRED_ERROR_MESSAGE }];
    return setMultipleValidationCustomErrorsMark(ctrlsConfig, REQUIRED_ERROR_MESSAGE);
  } else {
    return checkRangeMinBtOperatorValues(operatorValue1Ctrl, operatorValue2Ctrl, isCtrlValue1MinNumber, isCtrlValue2MinNumber, minValidation);
  }
}

function checkRangeMinBtOperatorValues(operatorValue1Ctrl: AbstractControl, operatorValue2Ctrl: AbstractControl, isCtrlValue1MinNumber: boolean, isCtrlValue2MinNumber: boolean, minValidation: boolean): ValidationErrors | null {
  operatorValue1Ctrl.setErrors(null);
  operatorValue2Ctrl.setErrors(null);
  const rangeValidity = checkValue1GreaterThanValue2(operatorValue1Ctrl, operatorValue2Ctrl);
  if (rangeValidity) {
    return rangeValidity;
  } else if (minValidation) {
    if (!isCtrlValue1MinNumber && !isCtrlValue2MinNumber) {
      const ctrlsConfig = [
        { ctrl: operatorValue1Ctrl, validators: [Validators.required, Validators.min(MIN_OPERATOR_VALUE)] , errorKeyMessage: MIN_VALUE_REQUIRED_ERROR_MESSAGE },
        { ctrl: operatorValue2Ctrl, validators: [Validators.required, Validators.min(MIN_OPERATOR_VALUE_2)], errorKeyMessage: MIN_VALUE_REQUIRED_ERROR_MESSAGE }
      ];
      return setMultipleValidationCustomErrorsMark(ctrlsConfig, MIN_VALUE_REQUIRED_VALIDATION_MESSAGE);
    } else if (!isCtrlValue1MinNumber && isCtrlValue2MinNumber) {
      const ctrlsConfig = [{ ctrl: operatorValue1Ctrl, validators: [Validators.required, Validators.min(MIN_OPERATOR_VALUE)], errorKeyMessage: MIN_VALUE_REQUIRED_ERROR_MESSAGE }];
      return setMultipleValidationCustomErrorsMark(ctrlsConfig, MIN_VALUE_REQUIRED_VALIDATION_MESSAGE);
    } else if (isCtrlValue1MinNumber && !isCtrlValue2MinNumber) {
      const ctrlsConfig = [{ ctrl: operatorValue2Ctrl, validators: [Validators.required, Validators.min(MIN_OPERATOR_VALUE_2)], errorKeyMessage: MIN_VALUE_REQUIRED_ERROR_MESSAGE }];
      return setMultipleValidationCustomErrorsMark(ctrlsConfig, MIN_VALUE_REQUIRED_VALIDATION_MESSAGE);
    }
    return null;
  }
  return null;
}
