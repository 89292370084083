import { Modules } from "../../shared/enums/modules.enums";

export const USER_PARTNER_MODULES_NOT_AUTHORIZED = [
  Modules.Tiers,
  Modules.LoyaltyPayment,
  Modules.Partners,
  Modules.PromotionsEngine,
  Modules.ReferralPrograms,
  Modules.PersonalizationCampaigns,
  Modules.CustomerDataPlatform,
  Modules.LoyalGPT,
  Modules.Configuration
];

export const USER_PARTNER_SECTIONS_NOT_AUTHORIZED = [
  `${Modules.BasicLoyaltyAnalytics}.program-performance`,
  `${Modules.BasicLoyaltyAnalytics}.loyalty-playbook`,
  `${Modules.BasicLoyaltyAnalytics}.campaign-performance.overview`,
  `${Modules.BasicLoyaltyAnalytics}.campaign-performance.promotions`,
  `${Modules.BasicLoyaltyAnalytics}.campaign-performance.budget-roi`,
  `${Modules.BasicLoyaltyAnalytics}.partners.endemic`,
  `${Modules.BasicLoyaltyAnalytics}.ledgers`,
  `${Modules.BasicLoyaltyAnalytics}.retail-analytics`,
  `${Modules.LoyaltyEngine}.triggers`,
  `${Modules.LoyaltyEngine}.massive-actions`,
  `${Modules.LoyaltyEngine}.configuration`,
  `${Modules.LoyaltyEngine}.api-requests`
];

export const USER_PARTNER_MODULES_SECTIONS_NOT_AUTHORIZED = [ ...USER_PARTNER_MODULES_NOT_AUTHORIZED, ...USER_PARTNER_SECTIONS_NOT_AUTHORIZED ];
