import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../../../models/forms/question-base';
import { FileQuestion } from '../../../../models/forms/question-file';
import { QuestionControlService } from '../../../../services/question-control.service';
import { S3Service } from '../../../../services/s3.service';
import { ConfirmationService } from '../../../../services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-campaign-csv-upload',
  templateUrl: './campaign-csv-upload.component.html',
  styleUrls: ['./campaign-csv-upload.component.scss']
})

export class CampaignCsvUploadComponent implements OnInit {

  form: UntypedFormGroup;
  inputs: QuestionBase<any>[];
  loading = false;

  @Output() closeEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() savedUrlEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private qcs: QuestionControlService,
    private s3Service: S3Service,
    private confirmationService: ConfirmationService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.inputs = this.getInputs();
    this.form = this.qcs.toFormGroup(this.inputs);
  }

  closeModal(): void {
    this.closeEmitter.emit();
  }

  downloadTemplate(): void {
    const newWindow = window.open('https://loyal-guru-default.s3.us-east-1.amazonaws.com/csv_external_list_example.csv');
    if (newWindow) {
      newWindow.opener = null;
    }
  }

  save() {
    const files = this.form.value.csv;
    if (files && files.length > 0) {
      const file = files[0];
      this.s3Service.uploadFile(file)
        .then(filename => {
          this.savedUrlEmitter.emit(filename);
        })
        .catch(error => {
          this.confirmationService.displayErrorAlert(
            this.translate.instant('common.error'),
            error.message || this.translate.instant('common.messages.error_page_sub1'))
      });
    }
  }

  private getInputs() {
    return [
      new FileQuestion({
      key: 'csv',
      types: ['csv'],
      multiple: false,
      cssClasses: 'form-control input-default',
      required: true
    })]
  }
}
