export enum CustomEntityKey {
  DefinitionId = 'definition_id',
  Attribute = 'entity_attribute',
  AttributeType = 'entity_attribute_type',
  AttributesConfiguration = 'entity_attributes_configuration',
  StringOperator = 'string_operator',
  IntegerOperator = 'integer_operator',
  FloatOperator = 'float_operator',
  BooleanOperator = 'boolean_operator',
  DateOperator = 'date_operator',
  StringValue = 'string_value',
  StringValueMultiselect = 'string_value_multiselect',
  IntegerValue = 'integer_value',
  IntegerValue2 = 'integer_value2',
  FloatValue = 'float_value',
  FloatValue2 = 'float_value2',
  BooleanValue = 'boolean_value',
  DateValue = 'date_value',
  DateValue2 = 'date_value2',
  DateFrom = 'date_from',
  DateTo = 'date_to',
  QuantityOperator = 'quantity_operator',
  QuantityValue = 'quantity_value',
  QuantityValue2 = 'quantity_value2',
  Values = 'values'
}