import { CustomEntityKey } from "../../../../../services/conditions/services/custom-entity-condition/enums/custom-entity-key.enums";

export const ATTRIBUTES_CONFIGURATION_OPERATORS_VALUES_FIELDS: string[] = [
  CustomEntityKey.StringOperator, CustomEntityKey.IntegerOperator, CustomEntityKey.FloatOperator, CustomEntityKey.BooleanOperator,
  CustomEntityKey.DateOperator, CustomEntityKey.StringValue, CustomEntityKey.StringValueMultiselect, CustomEntityKey.IntegerValue,
  CustomEntityKey.IntegerValue2, CustomEntityKey.FloatValue, CustomEntityKey.FloatValue2, CustomEntityKey.BooleanValue,
  CustomEntityKey.DateValue, CustomEntityKey.DateValue2
];

export const ATTRIBUTES_CONFIGURATION_FIELDS: string[] = [
  CustomEntityKey.Attribute, CustomEntityKey.AttributeType, ...ATTRIBUTES_CONFIGURATION_OPERATORS_VALUES_FIELDS
];

export const CONDITION_DATES_FIELDS: string[] = [CustomEntityKey.DateFrom, CustomEntityKey.DateTo];

export const WIDE_LAYOUT_CLASS = 'col-md-12 col-lg-6 col-xl-4';
export const NARROW_LAYOUT_CLASS = 'col-md-12 col-lg-4 col-xl-4';
