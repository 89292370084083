<div class="row h-100">
  <div class="col-md-12">
    <form [formGroup]="form" *ngIf="form">
      <div class="card d-flex justify-content-center">
        <div class="card-heading border bottom">
          <h4 class="card-title" [translate]="'resources.plans.alerts.report.title'"></h4>
        </div>
        <div class="card-body px-5">
          <p-table [value]="alertInputs" [tableStyle]="{width: '100%'}">
            <ng-template pTemplate="header">
              <tr>
                <th>{{'resources.plans.columns.message' | translate}}</th>
                <th>{{'resources.plans.columns.actions' | translate}}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
              <tr>
                <td>{{data.message}}</td>
                <td>
                  <app-question [question]="data.input" [form]="form" (click)="acknowledge($event)"></app-question>
                </td>
              </tr>
            </ng-template>
          </p-table>
          <div class="d-flex justify-content-end mt-3">
            <div class="mr-3 mt-2" *ngIf="isPlanAlertStatus(PlanAlertsStatus.Generating)">
              <i class="fa fa-spinner fa-spin"></i>
            </div>
            <button
              class="btn btn-info mr-3"
              type="button"
              (click)="acknowledge($event)"
              [disabled]="isPlanAlertStatus(PlanAlertsStatus.Generating)"
              translate
            >
              resources.plans.buttons.acknowledge_all_alerts
            </button>
            <button
              class="btn btn-info btn-inverse no-mrg-right"
              type="button"
              (click)="recalculate($event)"
              [disabled]="isPlanAlertStatus(PlanAlertsStatus.Generating)"
              translate
            >
              resources.plans.buttons.recalculate_all_alerts
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
