<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="row">
      <div class="col-md-12">
        <div id="accordion-1" class="accordion panel-group" role="tablist" aria-multiselectable="true">
          <div class="panel panel-default border-radius-4" [ngClass]="{'pdd-top-15': advancedFilters.length <= 0}">
            <div class="panel-heading" role="tab" id="headingOne">
              <div class="row justify-content-end">
                <h4 class="panel-title col-md-1">
                  <a class="no-mrg no-pdd mrg-right-15 mrg-top-10 font-size-14 collapsed"
                     data-toggle="collapse"
                     data-parent="#accordion-1"
                     href="#{{slug}}"
                     *ngIf="advancedFilters.length > 0">
                    <em title="{{'components.data-table-filter.buttons.more_filters' | translate}}"
                       class="icon ti-arrow-circle-down"></em>
                  </a>
                </h4>
              </div>
            </div>

            <div class="panel-body no-pdd-vertical mrg-horizon-20">
              <div *ngIf="preStandardFilters && preStandardFilters.length > 0" class="row align-items-end">
                <ng-container *ngFor="let filter of preStandardFilters">
                  <div class="col-md-2 form-group" *ngIf="!filter.hidden" [ngClass]="cfg.customInputsWidthClass ? cfg.customInputsWidthClass : ''">
                    <app-question [question]= "filter" [form]="form" [useToken]="filter.useToken" [filters]="filter.filters"></app-question>
                  </div>
                </ng-container>
              </div>

              <div class="row">
                <ng-container *ngFor="let filter of standardFilters">
                  <div class="col-md-2 form-group" *ngIf="!filter.hidden" [ngClass]="cfg.customInputsWidthClass ? cfg.customInputsWidthClass : ''">
                    <app-question [question]= "filter" [form]="form" (multiselectChanged)="handleFilterChanges($event, filter)" [useToken]="filter.useToken" [filters]="filter.filters"></app-question>
                  </div>
                </ng-container>
              </div>
            </div>

            <div id="{{slug}}" class="collapse">
              <div class="panel-body no-pdd-vertical mrg-horizon-20">
                <div class="row">
                  <ng-container *ngFor="let filter of advancedFilters" >
                    <div class="col-md-2 form-group" *ngIf="!filter.hidden" [ngClass]="cfg.customInputsWidthClass ? cfg.customInputsWidthClass : ''">
                      <app-question [question]="filter" [form]="form" [useToken]="filter.useToken"></app-question>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

            <div *ngIf="checkboxRowFilters && checkboxRowFilters.length > 0" class="panel-body no-pdd-vertical mrg-horizon-20">
              <div class="row">
                <ng-container *ngFor="let filter of checkboxRowFilters">
                  <div class="col-xl-4 col-lg-6 col-md-6 form-group flex align-items-center" *ngIf="!filter.hidden">
                    <app-question [question]= "filter" [form]="form" [filters]="filter.filters"></app-question>
                  </div>
                </ng-container>
              </div>
            </div>

            <ng-container *ngIf="slug === 'customers_list_filter' || (slug === 'data_subject_requests_list_filter' && flags?.useCustomersV3Endpoint)">
              <div class="panel-body mrg-horizon-20 mrg-top-10 mrg-btm-10 inline-block">
                <div class="alert alert-info d-flex align-items-center">
                  <em class="fa-regular fa-exclamation-circle mrg-right-10 font-size-16" aria-hidden="true"></em>
                  <span class="font-size-14">{{ (flags?.useCustomersV3Endpoint ? 'components.data-table-filter.messages.customers_results' : 'components.data-table-filter.messages.apply_filter_view_customers_results') | translate }}</span>
                </div>
              </div>
            </ng-container>

            <div class="panel-body mrg-horizon-20 mrg-btm-10">
              <button id="searchBtn" [attr.data-cy]="'button_search'" class="btn btn-info btn-icon btn-sm" type="submit" [disabled]="disableBtns || cfg.disableSubmitBtn">
                <i class="ei-search-alt font-size-14"></i>
                <span [translate]="'components.data-table-filter.buttons.search'"></span>
              </button>
              <button id="clearBtn" [attr.data-cy]="'button_clear'" class="btn btn-default btn-icon btn-sm" type="button" (click)="clearAllFilters()">
                <i class="ei-eraser-alt font-size-14"></i>
                <span [translate]="'components.data-table-filter.buttons.clean'"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
