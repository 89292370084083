export const TAG_DATE_REGEX = /^\d+ \((\d{2}-\d{2}-\d{4})/;

export const LOYALTY_SLUGS_TABS_WITH_COUNTRY_FF: string[] = ['loyalty_scores_won', 'loyalty_points_accrued', 'loyalty_scores_lost', 'loyalty_points_redeemed', 'loyalty_scores_reward', 'loyalty_rewards', 'loyalty_multicurrency'];
export const LOYALTY_SLUGS_TABS_NO_LOOKER: string[] = ['loyalty_scores_won', 'loyalty_scores_lost', 'loyalty_scores_reward', 'loyalty_multicurrency'];

export const SLUGS_TABS_TO_SET_COUNTRY: string[] = [
  'summary',
  'sales_evolution',
  'metrics',
  'geomarketing',
  'loyalty_summary',
  'loyalty_partners',
  'basic_loyalty_promotions_accrued',
  'basic_loyalty_promotions_redeemed',
  ...LOYALTY_SLUGS_TABS_WITH_COUNTRY_FF
];
