import { Injectable } from '@angular/core';
import { QuestionBase } from '../../../../models/forms/question-base';
import { TranslateService } from '@ngx-translate/core';
import { MultiSelectQuestion } from '../../../../models/forms/question-multiselect';
import { FeatureTaxonomiesService } from '../../../../../resources/data-warehouse/products/services/products-feature-taxonomies/feature-taxonomies.service';
import { SegmentConditionProvider } from '../../condition.service';
import { FloatQuestion } from '../../../../models/forms/question-float';
import { CheckboxQuestion } from '../../../../models/forms/question-checkbox';
import { DynamicDateQuestion } from '../../../../models/forms/question-dynamic-date';
import { LocationsService } from '../../../../../resources/data-warehouse/locations/locations.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { multiselectPresenceValidator } from '../../../validations.service';
import { DateService } from '../../../date.service';
import { LocationsTaxonomyTermsService } from '../../../../../resources/data-warehouse/locations/location-taxonomy-terms.service';
import { TaxonomiesCountCondition } from '../../../../models/segments/conditions/taxonomies-count-condition';
import { MultiselectDataSource } from '../../../../components/multiselect/multiselect';
import { defaultParseFormValuesToAPI, getParseBooleanValue, getParseDynamicDateValue, getParseIntValue, getParseMultiselectIdValues, getParseObjectKeysIdValue, getParseOperatorValue, getParseSelectedIdsValue, getParseSingleSelectOperatorIdValue, getParseValue, setLocationsFiltering } from '../../utils/common.utils';
import { FeatureFlagsService } from '../../../feature-flags.service';
import { INTEGER_REGEX } from '../../constants/condition.constants';
import { OperatorsService } from '../../common/operators.service';
import { getFormGroupValidations } from './validations/form.validations';

@Injectable()
export class TaxonomiesCountConditionService implements SegmentConditionProvider {

  flags = this.featureFlags.flags;
  inputs: QuestionBase<unknown>[];
  minValue = 1;
  customValidators = [(control: UntypedFormGroup) => getFormGroupValidations(control, this.minValue)];

  private operatorOpts = this.operatorsService.getNumericOperators();

  constructor(
    private translate: TranslateService,
    private dateService: DateService,
    private locationsTaxonomyTermsService: LocationsTaxonomyTermsService,
    private locationsService: LocationsService,
    private featureTaxonomies: FeatureTaxonomiesService,
    private featureFlags: FeatureFlagsService,
    private operatorsService: OperatorsService
  ) {}

  getInputs(params: TaxonomiesCountCondition): QuestionBase<unknown>[] {
    if (this.flags.showLocationTaxonomyTermIds) {
      setLocationsFiltering(params, this.locationsService);
    }

    const inputs = [
      new MultiSelectQuestion({
        key: 'taxonomy_slug',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.feature_taxonomy'),
        settings: { singleSelection: true, enableSearchFilter: true, showCheckbox: false},
        required: true,
        dataSource: this.featureTaxonomies,
        getValue: (value) => {
          if (value) {
            return { selectedIds: value.length && value[0] instanceof MultiselectDataSource ? [value[0].id] : [value] }
          }
        },
        parseValue: (value) => (value?.length ? value[0].id : []),
        customValidators: [multiselectPresenceValidator]
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'operator',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorOpts,
        required: true,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        getValue: getParseValue(),
        parseValue: getParseSingleSelectOperatorIdValue(this.operatorOpts),
        customValidators: [multiselectPresenceValidator]
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        key: `include_zero`,
        label: this.translate.instant('resources.segment_conditions.fields.include_zero'),
        getValue: getParseBooleanValue(),
        parseValue: getParseBooleanValue()
      }),
      new FloatQuestion({
        key: 'feature_count',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        type: 'number',
        min: 1,
        step: 1,
        required: true,
        getValue: getParseIntValue(),
        parseValue: getParseIntValue(),
        customValidators: [Validators.min(this.minValue), Validators.pattern(INTEGER_REGEX)]
      }),
      new FloatQuestion({
        key: 'feature_count_bt',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.max_value'),
        type: 'number',
        min: 1,
        step: 1,
        required: true,
        getValue: getParseIntValue(),
        parseValue: getParseIntValue()
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_from',
        label: this.translate.instant('resources.segment_conditions.fields.date_from'),
        getValue: getParseDynamicDateValue(this.dateService),
        parseValue: getParseObjectKeysIdValue()
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_to',
        label: this.translate.instant('resources.segment_conditions.fields.date_to'),
        getValue: getParseDynamicDateValue(this.dateService),
        parseValue: getParseObjectKeysIdValue()
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'location_taxonomy_term_ids',
        label: this.translate.instant('resources.segment_conditions.fields.location_taxonomy_term_ids'),
        dataSource: this.locationsTaxonomyTermsService,
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        getValue: getParseSelectedIdsValue(),
        parseValue: getParseMultiselectIdValues()
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'location_ids',
        label: this.translate.instant('resources.segment_conditions.fields.location_ids'),
        dataSource: this.locationsService,
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        getValue: getParseSelectedIdsValue(),
        parseValue: getParseMultiselectIdValues()
      })
    ];

    if (params) {
      Object.keys(params).forEach(key => {
        const input = inputs.find(_input => _input.key === key);
        const value = input.getValue(params[key]);
        if (value?.hasOwnProperty('selectedIds') && value.selectedIds) {
          input.selectedIds = value.selectedIds;
        } else {
          input.value = value;
        }
      });
    }

    this.inputs = inputs;
    return this.inputs;
  }

  prepareFormValuesToAPI(params: TaxonomiesCountCondition): TaxonomiesCountCondition {
    return defaultParseFormValuesToAPI(params, this.inputs);
  }

  prepareFormValuesFromAPI(params: TaxonomiesCountCondition): TaxonomiesCountCondition {
    const parseValues = {
      taxonomy_slug: getParseValue(),
      operator: getParseOperatorValue(this.operatorOpts),
      feature_count: getParseValue(false),
      feature_count_bt: getParseValue(false),
      include_zero: getParseBooleanValue(),
      date_from: getParseValue(),
      date_to: getParseValue(),
      location_taxonomy_term_ids: getParseValue(),
      location_ids: getParseValue()
    };

    const parsedValuesObj = {};
    Object.keys(params).forEach(key => {
      if (parseValues[key]) { parsedValuesObj[key] = parseValues[key](params[key]); }
    });
    return parsedValuesObj;
  }
}

