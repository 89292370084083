<div class="modal-backdrop fade in" [id]="'test-csv-external'" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-lg" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="mrg-left-15 mrg-btm-5 first-letter-upper">
          {{'resources.campaigns.fields.csv_external_data_url' | translate}}
        </h1>
				<button class="modal-close" (click)="closeModal()">
					<i class="ti-close"></i>
				</button>
			</div>
			<div class="modal-body background-gray">
				<div class="row">
					<div class="col-md-12">
            <div class="container-fluid clearfix">
              <div class="row">
                <div class="col">
                  <div class="card height-100">
                    <div class="card-block">
                      <div class="row">
                        <div class="col-md-12">
                          <!-- CSV Info message -->
                          <div class="alert alert-info alert-dismissible fade show">
                            {{'resources.campaigns.warnings.csv_external_list_info' | translate}}
                            <button type="button" class="close no-pdd-top pdd-top-10" data-dismiss="alert" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <!-- END - CSV Info message -->
                          <div class="form-group" *ngIf="form">
                            <label for="csv" class="font-size-14 text-bold" [translate]="'resources.coupons.form.csv'"></label>
                            <app-question [question]="inputs[0]" [form]="form"></app-question>
                          </div>
                          <p>
                            {{ 'resources.coupons.modal.import.download_template'| translate }}
                            <a (click)="downloadTemplate()" target="_blank">
                              <u class="text-info cursor-pointer">
                                {{'resources.coupons.modal.import.here_link'| translate}}
                              </u>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
			<div class="modal-footer background-white align-content-center">
				<div class="pull-right d-flex align-items-center">
					<span>
						<i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          </span>
					<button class="pull-right btn btn-info"
									(click)="save()"
									[translate]="'resources.products.buttons.send'"
									[disabled]="loading === true || !form?.valid">
          </button>
				</div>
			</div>
    </div>
  </div>
</div>
