import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { FeatureFlagsService } from '../../../../../shared/services/feature-flags.service';
import { handleSelectedLocationTaxonomyTermIdsField, resetInputsForm } from '../../utils/common.utils';
import { LocationsService } from '../../../../../resources/data-warehouse/locations/locations.service';
import { MultiselectDataSource } from '../../../multiselect/multiselect';
import { Operator } from '../../../../../shared/models/segments/conditions/operator';
import { QuestionBase } from '../../../../models/forms/question-base';
import { QuestionControlService } from '../../../../services/question-control.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-average-activity-condition',
  templateUrl: './average-activity-condition.component.html',
  styleUrls: ['./average-activity-condition.component.scss']
})
export class AverageActivityConditionComponent {

  flags = this.featureFlags.flags;
  narrowLayout = 'col-md-3 col-lg-3 col-xl-2' as const;
  wideBtLayout = 'col-md-6 col-lg-6 col-xl-2' as const;
  wideLayout = 'col-md-6 col-lg-6 col-xl-3' as const;

  @Input('item') item: Condition;
  @Input('inputs') inputs: QuestionBase<any>[];
  @Input('form') form: UntypedFormGroup;

  constructor(
    private qcs: QuestionControlService,
    private locationsService: LocationsService,
    private changeDetector: ChangeDetectorRef,
    private featureFlags: FeatureFlagsService
  ) {}

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

  handleSelectedLocationTaxonomyTermIds(locationTaxonomyTermIdsSelected: MultiselectDataSource[]): void {
    handleSelectedLocationTaxonomyTermIdsField(
      locationTaxonomyTermIdsSelected,
      'location_ids',
      this.getInputConfig('location_ids'),
      this.form,
      this.changeDetector,
      this.locationsService
    );
  }

  handleOperatorChanges(operator: Operator): void {
    const defaultFieldsToReset = ['value', 'value_bt'];
    const fieldsToReset = operator ? defaultFieldsToReset : [...defaultFieldsToReset, 'operator'];
    resetInputsForm(this.form, this.inputs, fieldsToReset);
  }
}
