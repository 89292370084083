import { Component, OnInit, OnDestroy, Output, EventEmitter } from "@angular/core";
import { AbstractControl, UntypedFormGroup, ValidatorFn } from "@angular/forms";
import { Subject, takeUntil, finalize, distinctUntilChanged, Observable } from "rxjs";
import { QuestionBase } from "../../../../shared/models/forms/question-base";
import { QuestionControlService } from "../../../../shared/services/question-control.service";
import { QuestionFactory } from "../../../../shared/services/question-factory";
import { ContactPoliciesService } from "../../../../shared/services/contact-policies.service";
import { ConfirmationService } from "../../../../shared/services/confirmation.service";
import { ContactPolicy, ContactPolicyPayload } from "../contact-policy.model";
import { ActivatedRoute, Router } from "@angular/router";
import { Option } from "../../../../shared/models/common/option";
import { TranslateService } from "@ngx-translate/core";
import { ModalStatusService } from "../../../../shared/services/modal-status.service";
import { SegmentsService } from "../../../segments/segments.service";
import { FeatureFlagsService } from "../../../../shared/services/feature-flags.service";

@Component({
  selector: 'app-form-contact-policies',
  templateUrl: './form-contact-policies.component.html',
  styleUrls: ['./form-contact-policies.component.scss'],
  providers: [ContactPoliciesService]
})
export class FormContactPoliciesComponent implements OnInit, OnDestroy {

  loading = false;
  form: UntypedFormGroup;
  inputs: QuestionBase<any>[];
  currentContactPolicy: ContactPolicy;
  id: string;
  flags = this.featureFlags.flags;

  @Output('formValidity') formValidity: EventEmitter<string> = new EventEmitter();

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private qcs: QuestionControlService,
    private confirmationService: ConfirmationService,
    private contactPoliciesService: ContactPoliciesService,
    private translate: TranslateService,
    private modalStatusService: ModalStatusService,
    private router: Router,
    private segmentsService: SegmentsService,
    private featureFlags: FeatureFlagsService
  ) { }

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  sendData(): void {
    const successMsg = this.id ?
      this.translate.instant('resources.contact_policies.messages.updated_success') :
      this.translate.instant('resources.contact_policies.messages.created_success');
    this.loading = true;
    this.modalStatusService.formLoaderStatus.emit('loading');
    this.getCrudRequest().pipe(takeUntil(this.destroy$), finalize(() => this.loading = false)).subscribe({
        next: () => {
          this.handleSuccessfullRequest('', successMsg);
        },
        error: (err) => {
          this.modalStatusService.formLoaderStatus.emit('loading_finished');
          this.confirmationService.displayHttpErrorAlert(err);
        }
      }
    );
  }

  openSegment() {
    if (this.form.get('segment_id')?.value?.[0]?.id) {
      const segmentId = this.form.get('segment_id').value[0].id;
      window.open(`#/segments/segments/${segmentId}`, '_blank');
    }
  }

  private handleSuccessfullRequest(title: string, desc: string): void {
    this.confirmationService.displaySuccessAlert(title, desc).catch(() => {});
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    this.modalStatusService.modalStatus.emit();
    this.returnToList();
  }

  private getCrudRequest(): Observable<any> {
    return this.id ?
      this.contactPoliciesService.updateContactPolicy(this.getPayload(), this.id) :
      this.contactPoliciesService.createContactPolicy(this.getPayload());
  }

  private getPayload(): ContactPolicyPayload {
    const channels = Object.keys(this.form.value)
      .filter(key => key.includes('cp_') && this.form.value[key] === true)
      .map(key => key.replace('cp_', ''));
    return {
      name: this.form.value.name,
      channels: channels,
      timeframe: this.form.value.timeframe,
      max_contacts: this.form.value.max_contacts,
      segment_id: this.form.value.segment_id?.[0]?.id ? this.form.value.segment_id[0].id : null
    }
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getContactPolicy();
      } else {
        this.setForm();
      }
    });
  }

  private getContactPolicy(): void {
    this.modalStatusService.formLoaderStatus.emit('loading_data');
    this.contactPoliciesService.fetchSelectedById(this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (contactPolicy: ContactPolicy) => {
        this.currentContactPolicy = contactPolicy;
        this.setForm();
        this.modalStatusService.formLoaderStatus.emit('loading_data_finished');
      },
      error: err => this.confirmationService.displayHttpErrorAlert(err)
    });
  }

  private setForm(): void {
    const questionFactory = new QuestionFactory();
    this.inputs = [
      questionFactory.createTextboxQuestion('name', 'text', true, this.currentContactPolicy?.name),
      questionFactory.createCheckboxQuestion('cp_email', this.translate.instant('resources.contact_policies.channels.email'), this.currentContactPolicy?.channels.includes('email')),
      questionFactory.createCheckboxQuestion('cp_push', this.translate.instant('resources.contact_policies.channels.push'), this.currentContactPolicy?.channels.includes('push')),
      questionFactory.createCheckboxQuestion('cp_sms', this.translate.instant('resources.contact_policies.channels.sms'), this.currentContactPolicy?.channels.includes('sms')),
      questionFactory.createRadioQuestion('timeframe', this.getTimeframeOpts(), this.currentContactPolicy?.timeframe, true),
      questionFactory.createTextboxQuestion('max_contacts', 'number', true, this.currentContactPolicy?.max_contacts),
      questionFactory.createMultiselectQuestion(
        'segment_id',
        { singleSelection: true, enableSearchFilter: true},
        this.segmentsService,
        false,
        this.currentContactPolicy?.segment_id ? [this.currentContactPolicy.segment_id] : []
      )
    ];
    this.form = this.qcs.toFormGroup(this.inputs);
    this.form.setValidators(this.getValidations());
    this.formStatusChanges();
  }

  private getTimeframeOpts(): Option[] {
    return [
      { id: 'daily', name: this.translate.instant('resources.contact_policies.timeframes.daily') },
      { id: 'weekly', name: this.translate.instant('resources.contact_policies.timeframes.weekly') },
      { id: 'monthly', name: this.translate.instant('resources.contact_policies.timeframes.monthly') }
    ];
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.form.status));
    const formStatus$ = this.form.statusChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
  }

  private getValidations(): ValidatorFn[] {
    const channelPresenceCheck = ((_ctrl: AbstractControl) => {
      if (!this.form.value.cp_email && !this.form.value.cp_push && !this.form.value.cp_sms) {
        return {atLeastOneChannelRequired: true};
      }
      return null;
    });
    const maxContactsCheck = ((_ctrl: AbstractControl) => {
      if (this.form.value.max_contacts < 1) {
        return {minValue: true};
      }
      return null;
    });
    return [channelPresenceCheck, maxContactsCheck];
  }

  private returnToList(): void {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }
}

