import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { INTEGER_REGEX, POSITIVE_INTEGER_REGEX } from "../constants/condition.constants";
import { isNullOrUndefined } from "../../../utils/common.utils";

export function isCtrlPositiveInteger(ctrl: AbstractControl): boolean {
  const ctrlValue = ctrl?.value ?? null;
  return !!(!isNullOrUndefined(ctrlValue) && ctrlValue.toString().match(POSITIVE_INTEGER_REGEX));
}

export function setValidationPositiveIntegerErrorsMark(ctrl: AbstractControl): ValidationErrors {
  ctrl.setErrors({'invalidPositiveInteger': true});
  ctrl.markAsDirty();
  return {['requiredPositiveIntegerValue']: true};
}

export function isCtrlMinNumber(ctrl: AbstractControl, minValue: number): boolean {
  const ctrlValue = ctrl?.value ?? null;
  return !!(!isNullOrUndefined(ctrlValue) && ctrlValue >= minValue);
}

export function isCtrlMinInteger(ctrl: AbstractControl, minValue: number): boolean {
  const ctrlValue = ctrl?.value ?? null;
  return !!(!isNullOrUndefined(ctrlValue) && ctrlValue >= minValue && ctrlValue.toString().match(INTEGER_REGEX));
}

export function setValidationMinIntegerErrorsMark(ctrl: AbstractControl): ValidationErrors {
  ctrl.setErrors({'invalidMinimumInteger': true });
  ctrl.markAsDirty();
  return {['requiredMinimumIntegerValue']: true};
}

export function setMultipleValidationCustomErrorsMark(ctrlsConfig: { ctrl: AbstractControl, validators: ValidatorFn[], errorKeyMessage: string }[], validationMessage: string): ValidationErrors {
  ctrlsConfig.forEach(config => {
    const ctrlItem = config.ctrl;
    ctrlItem.setValidators(config.validators);
    ctrlItem.setErrors({[config.errorKeyMessage]: true});
    ctrlItem.markAsDirty();
  });
  return {[validationMessage]: true};
}
