import { Routes } from '@angular/router';
import { AuthGuard } from './auth/auth-guard.service';
import { SigninComponent } from './auth/signin/signin.component';
import { CommonLayoutComponent } from './common/common-layout.component';
import { HomeComponent } from './common/home/home.component';
import { ModulesGuard } from './guards/modules-guard.service';
import { ErrorPageComponent } from './shared/components/error-page/error-page.component';
import { Modules } from './shared/enums/modules.enums';

export const AppRoutes: Routes = [
  {
    path: 'users/activate/:activation_token',
    component: SigninComponent
  },
  {
    path: 'customers/edit_password/:reset_token',
    component: SigninComponent
  },
  {
    path: 'expired-password/:reset_password_token',
    component: SigninComponent
  },
  {
    path: 'recover-password',
    component: SigninComponent
  },
  {
    path: 'signin',
    component: SigninComponent
  },
  {
    path: 'sso-signin',
    component: SigninComponent
  },
  {
    path: 'sso-error-signin',
    component: SigninComponent
  },
  {
    path: 'loading-home',
    component: SigninComponent
  },
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./initial.module').then(m => m.InitialModule),
  },
  {
    path: '',
    component: CommonLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      // Each module represents a given section in the new side menu structure
      {
        path: 'loyalty-analytics',
        loadChildren: () => import('./resources/loyalty-analytics/loyalty-analytics.module').then(m => m.LoyaltyAnalyticsModule),
        // canLoad: [ModulesGuard],
        // canActivate: [ModulesGuard],
        data: {
          featureFlag: 'show.loyalty_analytics'
        }
      },
      {
        path: 'retail-group-analytics',
        loadChildren: () => import('./resources/retail-group-analytics/retail-group-analytics.module').then(m => m.RetailGroupAnalyticsModule),
        canLoad: [ModulesGuard],
        canActivate: [ModulesGuard],
        data: {
          featureFlag: 'show.group_analytics'
        }
      },
      {
        path: 'basic-analytics',
        loadChildren: () => import('./resources/basic-analytics/basic-analytics.module').then(m => m.BasicAnalyticsModule),
        canLoad: [ModulesGuard],
        canActivate: [ModulesGuard],
        data: {
          featureFlag: 'show.basic_analytics'
        }
      },
      {
        path: 'retail-analytics',
        loadChildren: () => import('./resources/retail-analytics/retail-analytics.module').then(m => m.RetailAnalyticsModule),
        canLoad: [ModulesGuard],
        canActivate: [ModulesGuard],
        data: {
          featureFlag: 'show.retail_analytics'
        }
      },
      {
        path: 'supplier-analytics',
        loadChildren: () => import('./resources/supplier-analytics/supplier-analytics.module').then(m => m.SupplierAnalyticsModule),
        canLoad: [ModulesGuard],
        canActivate: [ModulesGuard],
        data: {
          featureFlag: 'show.supplier_analytics'
        }
      },
      {
        path: 'offers-personalization',
        loadChildren: () => import('./resources/offers/offers.module').then(m => m.OffersModule),
        canLoad: [ModulesGuard],
        canActivate: [ModulesGuard]
      },
      {
        path: 'loyalty',
        loadChildren: () => import('./resources/scores/scores.module').then(m => m.ScoresModule),
        canLoad: [ModulesGuard],
        canActivate: [ModulesGuard]
      },
      {
        path: 'omnichannel-manager',
        loadChildren: () => import('./resources/omnichannel-manager/omnichannel-manager.module').then(m => m.OmnichannelManagerModule),
        canLoad: [ModulesGuard],
        canActivate: [ModulesGuard]
      },
      {
        path: 'audiences',
        loadChildren: () => import('./resources/audiences/audiences.module').then(m => m.AudiencesModule),
        canLoad: [ModulesGuard],
        canActivate: [ModulesGuard]
      },
      {
        path: 'customer-data-platform',
        loadChildren: () => import('./resources/customer-data-platform/customer-data-platform.module').then(m => m.CustomerDataPlatformModule),
        canLoad: [ModulesGuard],
        canActivate: [ModulesGuard]
      },
      {
        path: 'gen-ai-analytics',
        loadChildren: () => import('./resources/loyal-gpt/loyalguru-gpt.module').then(m => m.LoyalguruChatModule),
      },
      // Configuration
      {
        path: 'config',
        loadChildren: () => import('./resources/config/config.module').then(m => m.ConfigModule),
        canLoad: [ModulesGuard],
        canActivate: [ModulesGuard]
      },
      // REVAMP MODULES
      {
        path: Modules.BasicLoyaltyAnalytics,
        loadChildren: () => import('./resources/revamp-modules/basic-loyalty-analytics-revamp/basic-loyalty-analytics.module').then(m => m.BasicLoyaltyAnalyticsModule),
      },
      {
        path: Modules.LoyaltyEngine,
        loadChildren: () => import('./resources/revamp-modules/loyalty-engine-revamp/loyalty-engine.module').then(m => m.LoyaltyEngineModule),
      },
      {
        path: Modules.Tiers,
        loadChildren: () => import('./resources/revamp-modules/tiers-revamp/tiers.module').then(m => m.TiersModule)
      },
      {
        path: Modules.LoyaltyPayment,
        loadChildren: () => import('./resources/revamp-modules/loyalty-payment-revamp/loyalty-payment.module').then(m => m.LoyaltyPaymentModule)
      },
      {
        path: Modules.Rewards,
        loadChildren: () => import('./resources/revamp-modules/rewards-revamp/rewards.module').then(m => m.RewardsModule)
      },
      {
        path: Modules.Partners,
        loadChildren: () => import('./resources/revamp-modules/partners-revamp/partners.module').then(m => m.PartnersModule),
      },
      {
        path: Modules.PromotionsEngine,
        loadChildren: () => import('./resources/revamp-modules/promotions-engine-revamp/promotions-engine.module').then(m => m.PromotionsEngineModule),
      },
      {
        path: Modules.GamificationChallenges,
        loadChildren: () => import('./resources/revamp-modules/gamification-challenges-revamp/gamification-challenges.module').then(m => m.GamificationChallengesModule),
      },
      {
        path: Modules.ReferralPrograms,
        loadChildren: () => import('./resources/revamp-modules/referral-programs-revamp/referral-programs.module').then(m => m.ReferralProgramsModule),
      },
      {
        path: Modules.PersonalizationCampaigns,
        loadChildren: () => import('./resources/revamp-modules/personalization-campaigns-revamp/personalization-campaigns.module').then(m => m.PersonalizationCampaignsModule),
      },
      // END REVAMP MODULES
      // Utility modules
      {
        path: 'notifications',
        loadChildren: () => import('./resources/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      // Modules to deprecate, review or delete
      {
        path: 'analytics',
        loadChildren: () => import('./resources/analytics/analytics.module').then(m => m.AnalyticsModule)
      },
      {
        path: 'segments',
        loadChildren: () => import('./resources/segments/segments.module').then(m => m.SegmentsModule)
      },
      {
        path: 'campaigns',
        loadChildren: () => import('./resources/campaigns/campaigns.module').then(m => m.CampaignsModule),
      },
      {
        path: 'content-designer',
        loadChildren: () => import('./resources/content-designer/content-designer.module').then(m => m.ContentDesignerModule)
      },
      {
        path: 'coupons',
        loadChildren: () => import('./resources/coupons/coupons.module').then(m => m.CouponsModule),
      },
      {
        path: 'vouchers',
        loadChildren: () => import('./resources/coupons/coupons.module').then(m => m.CouponsModule),
      },
      {
        path: 'customer-journeys',
        loadChildren: () => import('./resources/customer-journeys/customer-journeys.module').then(m => m.CustomerJourneysModule),
      },
      {
        path: 'recipes',
        loadChildren: () => import('./resources/recipes/recipes.module').then(m => m.RecipesModule),
      },
      {
        path: 'data-warehouse',
        loadChildren: () => import('./resources/data-warehouse/data-warehouse.module').then(m => m.DataWarehouseModule)
      },
      // Home
      {
        path: 'home',
        component: HomeComponent
      },
      //Error
      {
        path: 'error',
        component: ErrorPageComponent
      }
    ]
  },
  { path: '**', redirectTo: 'error' }
];
