import { MultiselectDataSourceable } from "../components/multiselect/multiselect";
import { Option } from "../models/common/option";
import { CheckboxQuestion } from "../models/forms/question-checkbox";
import { MultiSelectQuestion } from "../models/forms/question-multiselect";
import { RadioQuestion } from "../models/forms/question-radio";
import { TextboxQuestion } from "../models/forms/question-textbox";

export class QuestionFactory {
  constructor() {}

  createCheckboxQuestion(key: string, label: string, value: any, required?: boolean): CheckboxQuestion {
    return new CheckboxQuestion({
      key: key,
      type: 'checkbox',
      cssClasses: 'form-control input-default',
      label: label,
      value: value,
      required: required ?? false
    });
  }

  createRadioQuestion(key: string, options: any[], value: any, required?: boolean): RadioQuestion {
    return new RadioQuestion({
      cssClasses: 'radio-inline radio-info',
      key: key,
      type: 'radio',
      options: options,
      value: value,
      required: required ?? false
    });
  }

  createTextboxQuestion(key: string, type: string, required: boolean, value: any, min?: number, disabled?: boolean, label?: string): TextboxQuestion {
    return new TextboxQuestion({
      cssClasses: 'form-control input-md',
      key: key,
      type: type,
      required: required ?? false,
      value: value,
      min: min,
      disabled: disabled,
      label: label ?? null
    });
  }

  createMultiselectQuestion(
    key: string,
    settings: object,
    dataSource: MultiselectDataSourceable,
    required: boolean,
    selectedIds: any[],
    label?: string
  ): MultiSelectQuestion {
    return new MultiSelectQuestion({
      key: key,
      cssClasses: 'form-control input-default',
      settings: settings,
      dataSource: dataSource,
      required: required,
      selectedIds: selectedIds,
      label: label ?? null
    });
  }

  createStaticMultiselectQuestion(
    key: string,
    settings: object,
    options: Option[],
    required: boolean,
    value: Option | [],
    label?: string
  ): MultiSelectQuestion {
    return new MultiSelectQuestion({
      key: key,
      cssClasses: 'form-control input-default',
      settings: settings,
      options: options,
      required: required,
      value: value,
      label: label ?? null
    });
  }
}