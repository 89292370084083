<div class="card">
  <div class="card-heading border bottom">
    <h4 class="text-bold card-title" [translate]="'resources.scores.rewards.titles.stock_management'"></h4>
  </div>
  <div class="card-block">
    <div class="alert alert-info" role="alert">
      <span class="alert-text">
        {{'resources.scores.rewards.subtitles.stock_management' | translate}}
      </span>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="text-bold" for="low_stock_level" [translate]="'resources.scores.rewards.form.low_stock_level'"></label>
          <app-question [question]="getInputConfig('low_stock_level')" [form]="rewardsForm"></app-question>
        </div>
      </div>
    </div>
  </div>
</div>
