import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { QuestionBase } from '../../models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../services/question-control.service';
import { SegmentsService } from '../../../resources/segments/segments.service';
import { Condition } from '../../models/segments/condition';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from '../../services/confirmation.service';
import { ConditionService } from '../../services/conditions/condition.service';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from '../../utils/common.utils';
import { SegmentConditionComponent } from './segment-condition/segment-condition.component';
import { CampaignHistoriesService } from '../../../resources/campaigns/campaign-histories.service';
import { Location, registerLocaleData } from '@angular/common';
import { ProfileService } from '../../../profiles/profile.service';
import { CurrentCompany } from '../../models/current-user';
import es from '@angular/common/locales/es';
import { FeatureFlagsService } from '../../services/feature-flags.service';
import { LaunchDarklyService } from '../../services/launch-darkly/launchdarkly.service';
import { UserIdleSessionService } from '../../../services/user-idle-session/user-idle-session.service';

@Component({
  selector: 'app-create-update-segments-v2',
  templateUrl: './create-update-segments-v2.component.html',
  styleUrls: ['./create-update-segments-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TranslateService],
  encapsulation: ViewEncapsulation.None
})

/**
 * Note that data is handled with JSON.stringify / JSON.parse
 * Only set simple data or disable as methods will be lost
 */
export class CreateUpdateSegmentsV2Component implements OnInit, OnDestroy {
  static DEFAULT_OPERATOR = 'and';
  clientsCategories = [
    { id: null, name: this.translate.instant('resources.segment_conditions.types.postal_code'), key: 'postal_code', effectAllowed: 'copy' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.collective'), key: 'collective', effectAllowed: 'copy' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.birthday'), key: 'birthday', effectAllowed: 'copy' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.email'), key: 'email', effectAllowed: 'copy' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.phone_prefix'), key: 'phone_prefix', effectAllowed: 'copy' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.customer_channel_status'), key: 'customer_channel_status', effectAllowed: 'copy' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.signup_date'), key: 'signup_date', effectAllowed: 'copy' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.customer_id'), key: 'customer_id', effectAllowed: 'copy' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.score_available'), key: 'score_available', effectAllowed: 'copy' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.usual_location'), key: 'usual_location', effectAllowed: 'copy' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.countries'), key: 'country', effectAllowed: 'copy' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.registration_location'), key: 'registration_location', effectAllowed: 'copy' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.buyer_type_segment'), key: 'buyer_type_segment', effectAllowed: 'copy' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.name'), key: 'name', effectAllowed: 'copy' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.city'), key: 'city', effectAllowed: 'copy' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.state'), key: 'state', effectAllowed: 'copy' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.gender'), key: 'gender', effectAllowed: 'copy' }
  ];
  clientsCategoriesList = this.clientsCategories.sort((a, b) => a.name.localeCompare(b.name));
  purchasesCategories = [
    { id: null, name: this.translate.instant('resources.segment_conditions.types.last_purchase'), key: 'last_purchase', effectAllowed: 'copy', cssClass: 'btn-info' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.family'), key: 'taxonomies_count', effectAllowed: 'copy', cssClass: 'btn-info' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.tickets'), key: 'sales', effectAllowed: 'copy', cssClass: 'btn-info' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.average_activity'), key: 'average_activity', effectAllowed: 'copy', cssClass: 'btn-info' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.returns'), key: 'returns', effectAllowed: 'copy', cssClass: 'btn-info' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.promotion'), key: 'promotion', effectAllowed: 'copy', cssClass: 'btn-info' },
    { id: null, name: this.translate.instant('resources.segment_conditions.types.ecommerce'), key: 'ecommerce', effectAllowed: 'copy', cssClass: 'btn-info' }
  ];
  purchasesCategoriesList = this.purchasesCategories.sort((a, b) => a.name.localeCompare(b.name));

  eventsConditionList = {
    id: null,
    name: this.translate.instant('resources.segment_conditions.categories.events'),
    icon: 'ei-calendar text-warning',
    children: this.sortAryObjByProp([
      {
        id: null,
        name: this.translate.instant('resources.segment_conditions.types.custom_entity'),
        key: 'custom_entity',
        effectAllowed: 'copy'
      }
    ], 'name'),
  };

  conditionLists = [
    {
      id: null,
      name: this.translate.instant('resources.segment_conditions.categories.groups'),
      icon: 'ei-collaboration text-info',
      children: this.sortAryObjByProp([{
        id: null,
        name: this.translate.instant('resources.segment_conditions.types.group'),
        key: 'group',
        cssClass: 'btn-info',
        effectAllowed: 'copy'
      }], 'name'),
    },
    {
      id: null,
      name: this.translate.instant('resources.segment_conditions.categories.clients'),
      icon: 'ei-users text-primary',
      children: this.clientsCategoriesList
    },
    {
      id: null,
      name: this.translate.instant('resources.segment_conditions.categories.clients_custom'),
      icon: 'ei-users text-primary',
      children: this.sortAryObjByProp([
        {
          id: null,
          name: this.translate.instant('resources.segment_conditions.types.customer_attribute'),
          key: 'customer_attribute',
          effectAllowed: 'copy'
        },
        {
          id: null,
          name: this.translate.instant('resources.segment_conditions.types.custom'),
          key: 'custom',
          effectAllowed: 'copy'
        }
      ], 'name'),
    },
    {
      id: null,
      name: this.translate.instant('resources.segment_conditions.categories.segments'),
      icon: 'ei-pie-chart-1 text-success',
      children: this.sortAryObjByProp([
        {
          id: null,
          name: this.translate.instant('resources.segment_conditions.types.nested_segment'),
          key: 'nested',
          effectAllowed: 'copy',
          cssClass: 'btn-info'
        },
        {
          id: null,
          name: this.translate.instant('resources.segment_conditions.types.max_sales'),
          key: 'max_sales',
          effectAllowed: 'copy'
        },
        {
          id: null,
          name: this.translate.instant('resources.segment_conditions.types.client_expense_segment'),
          key: 'client_expense_segment',
          effectAllowed: 'copy'
        },
        {
          id: null,
          name: this.translate.instant('resources.segment_conditions.types.activity_segment'),
          key: 'activity_segment',
          effectAllowed: 'copy'
        },
        {
          id: null,
          name: this.translate.instant('resources.segment_conditions.types.recency_segment'),
          key: 'recency_segment',
          effectAllowed: 'copy'
        },
        {
          id: null,
          name: this.translate.instant('resources.segment_conditions.types.customer_category'),
          key: 'customer_category',
          effectAllowed: 'copy'
        },
      ], 'name'),
    },
    {
      name: this.translate.instant('resources.segment_conditions.categories.tickets'),
      icon: 'ei-ticket text-danger',
      children: this.purchasesCategoriesList
    },
    {
      id: null,
      name: this.translate.instant('resources.segment_conditions.categories.coupons'),
      icon: 'ei-percentage text-warning',
      children: this.sortAryObjByProp([
        { id: null, name: this.translate.instant('resources.segment_conditions.types.coupons'), key: 'coupon', effectAllowed: 'copy' },
        { id: null, name: this.translate.instant('resources.segment_conditions.types.voucher_count'), key: 'voucher_count', effectAllowed: 'copy' }
      ], 'name')
    },
    {
      id: null,
      name: this.translate.instant('resources.segment_conditions.categories.campaigns'),
      icon: 'ei-departures text-danger',
      children: this.sortAryObjByProp([
        { id: null, name: this.translate.instant('resources.segment_conditions.types.email_actions'), key: 'email_actions', effectAllowed: 'copy' },
        { id: null, name: this.translate.instant('resources.segment_conditions.types.campaigns'), key: 'campaign', effectAllowed: 'copy' }
      ], 'name')
    },
    {
      id: null,
      name: this.translate.instant('resources.segment_conditions.categories.sql'),
      icon: 'ei-file-code text-success',
      children: [
        { id: null, name: this.translate.instant('resources.segment_conditions.types.sql'), key: 'sql', effectAllowed: 'copy' },
      ]
    }
  ];

  ableToSave = true;
  company: CurrentCompany = new CurrentCompany(this.profileService.getStoredUser().company);
  conditionsToDestroy: Condition[] = [];
  flags = this.featureFlags.flags;
  form: UntypedFormGroup;
  historyData: {
    name: string,
    deliveryDate: string,
    customers: number
  };
  inputs: QuestionBase<unknown>[];
  savingData = false;
  loadingData = false;
  mode: string;
  nestableList: Condition[] = [];
  resource: string;

  get hasCompanyGDPRCommunications(): boolean {
    return !!this.company?.gdprFields?.communications;
  }

  private get isCompanyStrictProfiling(): boolean {
    return !!this.company?.strictProfiling;
  }

  private segmentId: number;
  private campaignId: number;
  private campaignHistoryId: number;
  private subs$: Subscription[] = [];
  private currentDraggableEvent: DragEvent;
  private currentDragEffectMsg: string;

  @ViewChildren(SegmentConditionComponent) conditions: QueryList<SegmentConditionComponent>;

  constructor(
    private router: Router,
    private qcs: QuestionControlService,
    private segmentService: SegmentsService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private conditionService: ConditionService,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private historiesService: CampaignHistoriesService,
    private profileService: ProfileService,
    private location: Location,
    private featureFlags: FeatureFlagsService,
    private launchDarklyService: LaunchDarklyService,
    private userIdleSessionService: UserIdleSessionService
  ) {}

  ngOnInit() {
    registerLocaleData(es);
    this.setConditionsConfiguration();
    this.mode = this.route.snapshot.data.mode;
    this.resource = this.route.snapshot.data.resource;
    this.conditionService.formValues = {};
    this.userIdleSessionService.setupUserIdleSession();
    this.getParams();
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach( s$ => s$.unsubscribe()); }
    this.userIdleSessionService.destroyUserIdleSession();
  }

  private getParams() {
    const router$ = this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        if(this.resource === 'campaign_history') {
          this.campaignId = params.id;
          this.campaignHistoryId = params.history_id;
          this.getCampaignHistory();
        } else {
          this.segmentId = params.id;
          this.getSegment();
        }
      } else {
        this.inputs = this.segmentService.getInputs({});
        this.getInputConfig('profiled_customers').value = this.isCompanyStrictProfiling;
        this.setSegmentCategoryIdInput();
        this.form = this.qcs.toFormGroup(this.inputs);
        this.changeDetector.markForCheck();
      }
    });
    this.subs$.push(router$);
  }

  getSegment() {
    this.loadingData = true;
    const segment$ = this.segmentService.getSegmentById(this.segmentId).subscribe(
      segmentData => {
        this.loadingData = false;
        segment$.unsubscribe();
        if (!segmentData['operator']) {
          this.showSegmentsV1Warning();
        } else {
          this.parseNestableListFromData(segmentData);
        }
        this.changeDetector.markForCheck();
      },
      errorData => {
        segment$.unsubscribe();
        this.confirmationService.displayErrorAlert('Error', JSON.stringify(errorData.error));
      }
    );
  }

  getCampaignHistory() {
    this.loadingData = true;
    const history$ = this.historiesService.getCampaignHistoryById(this.campaignId, this.campaignHistoryId).subscribe(
      historyData => {
        this.loadingData = false;
        const segmentData = historyData['_embedded']['campaign_info']['segment'];
        this.parseNestableListFromData(segmentData);
        this.historyData = {
          name: historyData['_embedded']['campaign_info']['name'],
          deliveryDate: historyData['sent_at'],
          customers: historyData['customers']
        };
        this.changeDetector.markForCheck();
      },
      errorData => {
        this.confirmationService.displayErrorAlert('Error', JSON.stringify(errorData.error));
      }
    );
    this.subs$.push(history$);
  }

  closeModal() {
    this.location.back();
  }

  onDragStart(event: DragEvent, item: Condition, effect: DropEffect) {
    this.currentDragEffectMsg = '';
    this.currentDraggableEvent = event;
    /* Disable all nested subgroups to avoid dragging inside itself */
    if ( item.content.key === 'group' ) {
      this.disableSelfAndNestedGroups(item, true);
    }
  }

  public handleFormValidity(formValidity: 'VALID' | 'INVALID') {
    const invalidFormsCount = this.conditions.filter(el => !el.form.valid).length;
    if (this.conditions.length === 0) { this.ableToSave = true; }
    this.ableToSave = (formValidity === 'VALID') && (invalidFormsCount === 0);
    this.changeDetector.markForCheck();
  }

  private parseNestableListFromData(segmentData: Object) {
    this.inputs = this.segmentService.getInputs(segmentData);
    this.setSegmentCategoryIdInput();
    this.form = this.qcs.toFormGroup( this.inputs );
    this.nestableList = this.conditionService.getConditionsAsNestableList(segmentData);
    const rootConditions = this.nestableList.filter(element => isNullOrUndefined(element.group_id));
    rootConditions.forEach(element => element.operator = segmentData['operator']);
  }

  private setSegmentCategoryIdInput(): void {
    const segmentCategoryIdInput = this.getInputConfig('segment_category_id');
    if (!segmentCategoryIdInput?.selectedIds?.length) {
      segmentCategoryIdInput.value = [{id: -1, name: this.translate.instant('resources.segments.messages.no_category')}];
    }
  }

  private showSegmentsV1Warning() {
    this.confirmationService.displayAlert(
      this.translate.instant('resources.segments.messages.edit_segmentsV1_error_title'),
      this.translate.instant('resources.segments.messages.edit_segmentsV1_error_desc'),
      'warning'
    ).then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        this.closeModal();
      }
    }).catch(() => {});
  }

  private disableSelfAndNestedGroups(item: Condition, disableValue: boolean) {
    item.disable = disableValue;
    item.children.filter( child => child.content.key === 'group').forEach( child => {
      this.disableSelfAndNestedGroups(child, disableValue);
    });
  }

  onDragged( item: any, list: any[], effect: DropEffect ) {
    this.currentDragEffectMsg = effect; // `Drag ended with effect "
    if ( effect === 'move' ) {
      const index = list.indexOf( item );
      list.splice( index, 1 );
    }
  }

  onDragEnd(event: DragEvent, item?: Condition) {
    if ( item.content.key === 'group' ) {
      this.disableSelfAndNestedGroups(item, false);
    }
  }

  onDrop( event: DndDropEvent, containerItem: Condition ) {
    const children = event.data.key === 'group' ? [] : null;
    const list = containerItem.children;
    if ( list && ( event.dropEffect === 'copy' )) {
      this.handleCopiedItemOnDrop(event, list, children);
    } else if ( list && ( event.dropEffect === 'move' )) {
      this.handleMovedItemOnDrop(event, list, containerItem);
    }
  }

  onDropInBaseDropZone( event: DndDropEvent ) {
    const children = event.data.key === 'group' ? [] : null;
    const list = this.nestableList;
    if ( event.dropEffect === 'copy' ) {
      this.handleCopiedItemOnDrop(event, list, children);
    } else {
      this.handleMovedItemOnDrop(event, list);
    }
  }

  private handleMovedItemOnDrop(event: DndDropEvent, list: Condition[], container?: Condition) {
    const index = event.index ? event.index : list.length ;
    const content = event.data.content;

    if ( content.id ) {
      const toDelete = this.buildCondition(event, content, content.id);
      this.markToDestoy(toDelete);
      if ( this.containerHasItem(container, content) ) {
        list = list.splice( index, 0, toDelete );
      }
    }

    const movedItem = this.buildCondition(event, content);

    if ( container && container.content.id ) {
      movedItem.group_id = container.content.id;
      movedItem.operator = container.operator;
    }

    if ( !this.containerHasItem(container, content) ) {
      list = list.splice( index, 0, movedItem );
    }
  }

  private buildCondition(event: DndDropEvent, content, id?: number): Condition {
    const condition = {
      content: {
        id: id ? id : null,
        name: content.name,
        key: content.key,
        formControlId: content.formControlId,
        formValues: content.formValues,
      },
      children: event.data.children,
      operator: event.data.operator, // Modify regarding inner operator in DropZone.
      disable: false,
      handle: true
    };

    if ( condition.children && !id ) {
      condition.children = this.cleanseIds( condition.children );
    }

    return condition;
  }

  private containerHasItem(container: Condition, item: any): boolean {
    return container &&
           item.formControlId &&
           container.content.formControlId === item.formControlId;
  }

  private handleCopiedItemOnDrop(event: DndDropEvent, list: Condition[], children: Condition[]) {
    const newItem: Condition = {
      content: {
        id: event.data.id,
        name: event.data.name,
        key: event.data.key
      },
      children: children,
      handle: true
    };
    let index = event.index;
    if ( typeof index === 'undefined' ) {
      index = list.length;
      newItem.operator = list[index - 1] ? list[index - 1].operator : CreateUpdateSegmentsV2Component.DEFAULT_OPERATOR;
    } else {
      newItem.operator = list[index - 1].operator;
    }
    const formControlId = this.conditionService.setUniqueFormControlId(newItem);
    newItem.content.formControlId = formControlId;
    this.form.addControl(formControlId, new UntypedFormGroup({}));
    list.splice( index, 0, newItem );
  }

  remove(item: Condition, list: Condition[]) {
    if ( item.content.key === 'group' && item.children.length ) {
      this.confirmationService.displayConfirmationAlert(
        this.translate.instant('resources.segment_conditions.messages.delete_subgroup_title'),
        this.translate.instant('resources.segment_conditions.messages.delete_subgroup_message'),
        'question',
      ).then(data => {
        if (data.hasOwnProperty('value') && data.value) {
          this.removeItem(item, list);
        }
      }).catch(() => {});
    } else if ( item.content.key !== 'group' && this.conditionService.hasFormValuesByKey(item.content.formControlId)) {
      this.confirmationService.displayConfirmationAlert(
        this.translate.instant('resources.segment_conditions.messages.delete_condition_title'),
        this.translate.instant('resources.segment_conditions.messages.delete_condition_message'),
        'question',
      ).then(data => {
        if (data.hasOwnProperty('value') && data.value) {
          this.removeItem(item, list);
        }
      }).catch(() => {});
    }else {
      this.removeItem(item, list);
    }
  }

  private removeItem(item: Condition, list: Condition[]) {
    const index = list.indexOf(item, 0);
    this.form.removeControl(item.content.formControlId);
    if ( item.content.id ) { this.markToDestoy(item); }
    if (index > -1) { list.splice(index, 1); }

    setTimeout(() => this.handleFormValidity('VALID'));
  }

  private markToDestoy(item: Condition) {
    this.conditionsToDestroy.push( item );
    if ( item.children && item.children.length ) {
      item.children.forEach( childCondition => this.markToDestoy( childCondition ));
    }
  }

  private cleanseIds( conditions: Condition[] ): Condition[] {
    return conditions.map( (condition: Condition) => {
      const _tmpCondition = JSON.parse( JSON.stringify( condition ) );
      _tmpCondition.content.id = null;
      if ( _tmpCondition.children && _tmpCondition.children.length ) {
        _tmpCondition.children = this.cleanseIds( _tmpCondition.children );
      }
      return _tmpCondition;
    });
  }

  save(): void {
    this.savingData = true;
    const segment_category_id = this.form.get('segment_category_id').value;
    const firstChild = this.nestableList[0];
    const conditions = this.conditionService.getFormValuesByList( this.nestableList, this.conditionsToDestroy );

    // New quick filters by channel
    const activeCustomers = this.form.get('active_customers').value ?? false;
    const excludeFraudulentCustomers = this.form.get('exclude_fraudulent_customers').value ?? false;
    const profiledCustomers = this.form.get('profiled_customers').value ?? false;
    const applyCommunicationsConsent = this.form.get('apply_communications_consent').value ?? false;
    const sendEmail = this.form.get('send_email').value ?? false;
    const sendPush = this.form.get('send_push').value ?? false;
    const sendSms = this.form.get('send_sms').value ?? false;
    const includePhoneDuplicated = this.form.get('include_phone_duplicated').value ?? false;

    const payload = {
      name: this.form.get('name').value,
      description: this.form.get('description').value,
      segment_category_id: segment_category_id && segment_category_id.length > 0 ? segment_category_id[0].id : null,
      operator: firstChild ? firstChild.operator : CreateUpdateSegmentsV2Component.DEFAULT_OPERATOR,
      conditions: conditions,

      /* New channel filters (still missing API contract. This is a work in progress and back dependant */
      active_customers: activeCustomers,
      exclude_fraudulent_customers: excludeFraudulentCustomers,
      profiled_customers: profiledCustomers,
      apply_communications_consent: applyCommunicationsConsent,
      send_email: sendEmail,
      send_sms: sendSms,
      send_push: sendPush,
      include_phone_duplicated: includePhoneDuplicated
    };

    this.segmentId ? this.updateSegment(payload) : this.createSegment(payload);
  }

  changeOperator(list: Condition[], operator: string) {
    list.map( condition => condition.operator = operator );
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

  smsChanged(event) {
    const smsValue = this.form.get('send_sms').value;
    if ( smsValue === false ) {
      this.form.patchValue( { include_phone_duplicated: false}, { emitEvent: false });
    }
  }

  private createSegment(payload: object) {
    const save$ = this.segmentService.saveSegment(payload).subscribe(
      successData => {
        save$.unsubscribe();
        this.savingData = false;
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.segments.messages.create_success_title'),
          this.translate.instant('resources.segments.messages.create_success_desc')
        ).catch(() => {});
        this.router.navigateByUrl(`/segments/segments/${successData['id']}`).catch(() => {});
      },
      errorData => {
        save$.unsubscribe();
        this.savingData = false;
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error );
      }
    );
  }

  private updateSegment(payload: object) {
    const save$ = this.segmentService.patchSegmentById(this.segmentId, payload).subscribe(
      successData => {
        save$.unsubscribe();
        this.savingData = false;
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.segments.messages.update_success_title'),
          this.translate.instant('resources.segments.messages.update_success_desc')
        ).catch(() => {});
        this.router.navigateByUrl(`/segments/segments/${successData['id']}`).catch(() => {});
      },
      errorData => {
        save$.unsubscribe();
        this.savingData = false;
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error );
      }
    );
  }

  private sortAryObjByProp(ary: any[], prop: string): any[] {
    return ary.sort((actionA, actionB) => {
      if (actionA[prop] > actionB[prop]) {return 1; }
      if (actionA[prop] < actionB[prop]) {return -1; }
      return 0;
    });
  }

  private async setConditionsConfiguration(): Promise<void> {
    return this.launchDarklyService.client.waitUntilReady().then(() => {
      if (this.flags.showMarginSegmentCondition) {
        this.purchasesCategoriesList.push( { id: null, name: this.translate.instant('resources.segment_conditions.types.margin'), key: 'margin', effectAllowed: 'copy', cssClass: 'btn-info' });
        this.purchasesCategoriesList.sort((a, b) => a.name.localeCompare(b.name));
      }

      if (this.flags.customEntitiesVisibility) {
        this.conditionLists.splice(4, 0, this.eventsConditionList);
      }
    });
  }
}
