import { FeatureInternal } from '../../../../../shared/models/products/feature-internal';
import { FeaturesService } from '../products-features/features.service';
import { FeatureTaxonomiesService } from '../products-feature-taxonomies/feature-taxonomies.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../../../../shared/components/multiselect/multiselect';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { Observable } from 'rxjs';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import { TextboxQuestion } from '../../../../../shared/models/forms/question-textbox';

@Injectable()
export class FeatureInternalsService extends ResourcesService implements MultiselectDataSourceable {
  inputs: (TextboxQuestion | MultiSelectQuestion)[];

  constructor(http: HttpClient,
              private readonly featureService: FeaturesService,
              private readonly featureTaxonomies: FeatureTaxonomiesService) {
    super(http);
  }

  fetchMultiselect(searchValues?: string, page?: number): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'feature_internals',
      numberPerPage: 10,
      filtering: {},
      pageNumber: 1,
      sorting: {}
    };

    requestOptions.pageNumber = page ?? requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues } };
      requestOptions = { ...requestOptions, ...filterObj };
    }

    return this.getData(requestOptions);
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `feature_internals?id=${id}` });
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.name}`, element);
  }

  getFeatureInternalsById(id: number): Observable<unknown> {
    return this.getData({ apiEndPoint: `feature_internals/${id}` });
  }

  getInputs(formValues: FeatureInternal, isEditMode: boolean): QuestionBase<unknown>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: formValues?.name ?? null,
        order: 1
      }),
      new TextboxQuestion({
        key: 'slug',
        type: 'text',
        cssClasses: 'form-control input-default',
        required: true,
        disabled: isEditMode,
        value: formValues?.slug ?? null,
        order: 2
      }),
      new MultiSelectQuestion({
        key: 'taxonomy_slug',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.featureTaxonomies,
        required: true,
        disabled: isEditMode,
        selectedIds: formValues?.taxonomy_slug ? [formValues.taxonomy_slug] : [],
        order: 3
      }),
      new MultiSelectQuestion({
        key: 'features',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.featureService,
        selectedIds: formValues?.feature_objects ? formValues.feature_objects.map(item => item.id) : [],
        order: 4
      })
    ];

    this.inputs = inputsArray;
    return this.inputs;
  }

  sendData(data: unknown) {
    return this.postResource(data, 'feature_internals');
  }

  updateData(data: unknown, _id: number) {
    return this.patchResource(data, `feature_internals/${_id}`);
  }

}
