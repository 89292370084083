import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';

@Component({
  selector: 'app-customer-segmentation-block',
  templateUrl: './customer-segmentation-block.component.html'
})

export class CustomerSegmentationBlockComponent {

  @Input('loyaltyScoresForm') loyaltyScoresForm: UntypedFormGroup;
  @Input('inputs') inputs;
  @Input('flags') flags;

  constructor(
    private qcs: QuestionControlService,
  ) { }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

}
