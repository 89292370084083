import { Component, Input } from '@angular/core';
import { ReportCouponsAppAnalysisBaseHeaders } from '../utils/table-header.utils';

@Component({
  selector: 'app-analysis-table-cell',
  templateUrl: './analysis-table-cell.component.html',
  styleUrls: ['./analysis-table-cell.component.scss'],
})
export class AnalysisTableCellComponent {

  @Input() item: any;
  @Input() header: any;
  @Input() currencySymbol: string;

  baseHeaders = ReportCouponsAppAnalysisBaseHeaders.filter((header) => header.display);

  isString(value: any): boolean {
    return typeof value === 'string';
  }

  isType(key: string, type: string): boolean {
    return this.baseHeaders.find(h => h.key === key)?.type === type;
  }

  containsPlansPrefix(itemValue: string): boolean {
    return itemValue?.indexOf('Plans::') >= 0;
  }

  goToCoupon(couponId: number) {
    window.open(`#/offers-personalization/dashboard/reports(modal:show/coupons/${couponId})`);
  }

  goToCampaign(campaignId: number) {
    window.open(`#/offers-personalization/dashboard/reports(modal:show/campaigns/${campaignId})`);
  }

  goToCampaignHistory(campaignId: number, historyId: number) {
    window.open(`#/offers-personalization/dashboard/reports(modal:show/campaigns/${campaignId}/histories/${historyId}/details)`);
  }

  goToPlan(planId: number) {
    window.open(`#/offers-personalization/dashboard/reports(modal:show/plans/${planId}/roi)`);
  }
}