<div [formGroup]="form" [ngClass]="{'display-inline': displayInline}">

  <small *ngIf="question.label && question.controlType !== 'checkbox' && question.controlType !== 'toggle-checkbox'">
    <label [hidden]="question.type === 'hidden'"
           *ngIf="!question.hidden"
           [attr.data-cy]="'label_' + (question.key | camelCase)"
           [attr.for]="question.key">
      <span class="text-semibold" [innerHTML]="question.label | translate"></span>
      <span *ngIf="question.required" class="text-danger">*</span>
    </label>
    <i *ngIf="question.showTooltip && question.tooltipKey"
       [attr.data-cy]="'icon_' + (question.key | camelCase) + 'Tooltip'"
       class="ti-info-alt font-size-10"
       tooltipPosition="right"
       pTooltip="{{ question.tooltipKey | translate }}">
    </i>
  </small>

  <div class="form-group field"
       [hidden]="question.type === 'hidden'"
       [ngSwitch]="question.controlType"
       [ngClass]="{ 'no-mrg-btm': question.helpText || question.errorText }">

    <input *ngSwitchCase="'hidden'"
           [formControlName]="question.key"
           [id]="question.key"
           [attr.data-cy]="'input_' + (question.key | camelCase)"
           [type]="question.type"
           [value]="question.value"
           [attr.disabled]="question.disabled? '' : null ">

    <div *ngSwitchCase="'textbox'">
      <input [formControlName]="question.key"
              (blur)="emitBlur($event)"
              (click)="emitClick($event)"
              (keydown)="emitInput($event)"
              (keypress)="emitInput($event)"
              (keyup)="emitInput($event)"
              (onchange)="emitInput($event)"
              [attr.data-cy]="'input_' + (question.key | camelCase)"
              [attr.disabled]="question.disabled? '' : null "
              [class]="question.cssClasses"
              [hidden]="question.hidden"
              [id]="question.key"
              [max]="question.type === 'date' && !question.maxDate ? '2999-12-31' : question.maxDate"
              [maxlength]="question.max"
              [min]="question.minDate"
              [ngClass]="{ 'invalid-field': hasError() }"
              [placeholder]="question.placeholder"
              [required]="question.required"
              [step]="question.step || 1"
              [type]="question.type"
              [value]="question.value">
            </div>

    <div *ngSwitchCase="'checkbox'" class="checkbox" [ngClass]="{'flex align-items-center' : question.tooltipKey}">
      <input type="checkbox"
              [id]="question.key"
              [attr.data-cy]="'input_' + (question.key | camelCase)"
              [name]="question.key"
              [formControlName]="question.key"
              [required]="question.required"
              [hidden]="question.hidden"
              [attr.disabled]="question.disabled? '' : null"
              [attr.checked]="question.value === true ? '' : null"
              (change)="emitBlur($event)">
      <label [ngClass]="{'nowrap': !question.tooltipKey}" [ngClass]="{'disabled': question.disabled}" [attr.data-cy]="'label_' + (question.key | camelCase)" [attr.for]="question.key">
        {{question.label | translate}}
        <i *ngIf="question.tooltipKey" [attr.data-cy]="'icon_'+ (question.key | camelCase) + 'Tooltip'" [ngClass]="{'disabled': question.disabled}" class="ti-info-alt mrg-left-5 vertical-align-middle" tooltipPosition="right" pTooltip="{{ question.tooltipKey | translate }}" [disableTooltip]="question.disabled"></i>
      </label>
    </div>

    <div *ngSwitchCase="'toggle-checkbox'" class="toggle-checkbox toggle-checkbox-wrapper toggle-sm">
      <input type="checkbox"
              [id]="question.key"
              [attr.data-cy]="'input_' + (question.key | camelCase)"
              [name]="question.key"
              [formControlName]="question.key"
              [required]="question.required"
              [hidden]="question.hidden"
              [attr.disabled]="question.disabled? '' : null"
              [attr.checked]="question.value === true ? '' : null"
              (change)="emitBlur($event)">
      <label [ngClass]="{'disabled': question.disabled}" [attr.data-cy]="'label_' + (question.key | camelCase)" [attr.for]="question.key"></label>
      <span [attr.data-cy]="'span_' + (question.key | camelCase)" class="toggle-checkbox-label">{{question.label | translate}}</span>
    </div>

    <input  *ngSwitchCase="'float'"
            [formControlName]="question.key"
            [id]="question.key"
            [attr.data-cy]="'input_' + (question.key | camelCase)"
            [type]="question.type"
            [hidden]="question.hidden"
            [value]="question.value"
            [class]="question.cssClasses"
            [max]="question.max"
            [min]="question.min"
            [step]="question.step"
            [required]="question.required"
            (blur)="emitBlur($event)"
            [attr.disabled]="question.disabled || null">

    <div *ngSwitchCase="'image'">
      <input *ngIf="!question.disabled"
           [formControlName]="question.key"
           [id]="question.key"
           [attr.data-cy]="'input_' + (question.key | camelCase)"
           [type]="'file'"
           [hidden]="question.hidden"
           [class]="question.cssClasses"
           (change)="onFileChange($event)"
           (blur)="emitBlur($event)"
           [attr.disabled]="question.disabled? '' : null ">
      <img  *ngIf="question.value"
            [src]="question.value"
            width="100"
            [hidden]="question.hidden"
            [attr.data-cy]="'img_' + (question.key | camelCase)"
            [attr.disabled]="question.disabled? '' : null "
            alt="image">
    </div>

    <select *ngSwitchCase="'dropdown'"
            [formControlName]="question.key"
            [id]="question.key"
            [attr.data-cy]="'select_' + (question.key | camelCase)"
            [class]="question.cssClasses"
            [hidden]="question.hidden"
            [value]="question.value? question.value : null"
            [attr.disabled]="question.disabled? '' : null "
            (blur)="emitBlur($event)">
      <option *ngFor="let opt of question.options"
              [value]="question.value === opt.id ? question.value : opt.id"
              [attr.disabled]="question.disabled? '' : null ">
              <div *ngIf="!question.translatable" [innerText]="opt.name"></div>
              <div *ngIf="question.translatable" [translate]="opt.name"></div>
      </option>
    </select>

    <app-multiselect *ngSwitchCase="'multiselect'"
      [question]="question"
      [settings]="question.settings"
      [hidden]="question.hidden"
      [form]="form"
      [formControlName]="question.key"
      [translatable]="question.translatable"
      [dataSource]="question.dataSource"
      [selectedIds]="question.selectedIds"
      [hasErrors]="question.hasErrors"
      (ngModelChange)="question.hasErrors = false"
      (onSelect)="onMultiselectSelection($event)"
      (onDeSelect)="onMultiselectDeselect($event);"
      (onSelectAll)="onMultiselectChange($event); question.hasErrors = false"
      (onDeSelectAll)="onMultiselectChange($event); question.hasErrors = false"
      (selectedIdsLoaderEmitter)="onSelectedIdsLoaded()"
      (optionsLoadedEmitter)="onOptionsLoaded($event)"
      (selectedItemLoadedEmitter)="onSelectedItemLoadedEmitter($event)"
      [doDisable]="question.disabled"
      [allowBlank]="question.allowBlank"
      [useToken]="useToken"
      [options]="multiselectOptions"
      [filters]="filters"
      [value]="question.value? question.value : []">
    </app-multiselect>

    <div *ngSwitchCase="'radiobox'">
      <div *ngFor="let opt of question.options; let i = index" class="radio" [ngClass]="question.cssClasses">
          <input type="radio"
               [value]="opt.id"
               [name]="question.key"
               [id]="question.key + i"
               [attr.data-cy]="'input_' + (question.key | camelCase) + i"
               [formControlName]="question.key"
               [attr.disabled]="question.disabled || null"
               (change)="emitBlur($event)">
        <label [ngClass]="{'disabled': question.disabled}" [attr.data-cy]="'label_' + (question.key | camelCase) + i" [attr.for]="question.key + i">{{opt.name}}</label>
      </div>
    </div>

    <textarea *ngSwitchCase="'textarea'"
              #inputElement
              [formControlName]="question.key"
              [id]="question.key"
              [attr.data-cy]="'textarea_' + (question.key | camelCase)"
              [hidden]="question.hidden"
              [value]="question.value? question.value : null"
              [class]="question.cssClasses"
              [required]="question.required"
              [attr.disabled]="question.disabled? '' : null "
              [placeholder]="question.placeholder"
              (blur)="emitBlur($event)"
              (keyup)="emitInput($event)"
              (onchange)="emitInput($event)"
              (change)="emitInput($event)"
              [rows]="question.rows"></textarea>

    <app-dynamic-date-input *ngSwitchCase="'dynamic-date'"
                            [form]="form"
                            [formControlName]="question.key"
                            [id]="question.key"
                            [hidden]="question.hidden"
                            [value]="question.value? question.value : null"
                            [class]="question.cssClasses"
                            [required]="question.required"
                            [doDisable]="question.disabled ?? false"
                            (blur)="emitBlur($event)"
                            [placeholder]="question.placeholder"
                            [hideNoDateOpt]="question.hideNoDateOpt"
                            [hideOverlappingOpts]="question.hideOverlappingOpts"
                            [mode]="question.mode"></app-dynamic-date-input>

    <app-file-upload *ngSwitchCase="'file'"
                     [form]="form"
                     [formControlName]="question.key"
                     [id]="question.key"
                     [hidden]="question.hidden"
                     [class]="question.cssClasses"
                     [required]="question.required"
                     [multiple]="question.multiple"
                     [types]="question.types"
                     [maxFileSize]="question.maxFileSize"></app-file-upload>

  </div>

  <div class="row">
    <div *ngIf="question.errorText" class="col mrg-btm-15 text-left text-danger form-question-error">
      <small [attr.data-cy]="'text_' + (question.key | camelCase) + 'Error'" [innerHTML]="question.errorText"></small>
    </div>

    <div *ngIf="question.helpText" class="col mrg-btm-15 text-default">
      <small [attr.data-cy]="'text_' + (question.key | camelCase) + 'Info'" [innerHTML]="question.helpText"></small>
    </div>
  </div>

  <div *ngIf="question.error" class="alert alert-warning">
    <strong [attr.data-cy]="'text_' + (question.key | camelCase) + 'Warning'" [translate]="question.error.field_name"></strong> {{question.error.message}}
  </div>
</div>
