import { AbstractControl, UntypedFormGroup, ValidationErrors, Validators } from "@angular/forms";
import { checkValue1GreaterThanValue2 } from "../../../../validations.service";
import { isNullOrUndefined } from "../../../../../utils/common.utils";
import { setMultipleValidationCustomErrorsMark } from "../../../validations/common.validations";

const MAX_2_DECIMALS_REGEX = /^-?\d*(?:[.,]\d{1,2})?$/;
const REQUIRED_PERCENTAGE_VALIDATORS = [Validators.required, Validators.pattern(MAX_2_DECIMALS_REGEX)];
const REQUIRED_ERROR_MESSAGE = 'valueRequired';
const PERCENTAGE_FORMAT_ERROR_MESSAGE = 'invalidPercentageNumberFormat';
const PERCENTAGE_FORMAT_VALIDATION_MESSAGE = 'validPercentageNumberRequired';

export function getFormGroupValidations(control: UntypedFormGroup): ValidationErrors | null {
  const scopeKey = Object.keys(control.controls).find((_key => _key.indexOf('scope') >= 0));
  const scope = control.get(scopeKey);
  const features = control.get('feature_ids');
  const marginAmountOp = control.get('margin_amount_operator');
  const marginAmountValue = control.get('margin_amount_value');
  const marginAmountValue1 = control.get('margin_amount_value_1');
  const marginAmountValue2 = control.get('margin_amount_value_2');

  [features, marginAmountValue, marginAmountValue1, marginAmountValue2]
    .forEach(_ctrl => _ctrl.setValidators([]));

  const featureValidator = setScopeValidators(scope, 'feature', features, 'featuresRequired');
  const marginAmountValidator = setOperatorsValidators(marginAmountOp, marginAmountValue, marginAmountValue1, marginAmountValue2, 'marginAmountValueRequired');

  let validators = {};
  [featureValidator, marginAmountValidator].forEach(validator => {
    if (validator) {
      validators = {...validators, validator};
    }
  });

  if (Object.keys(validators).length) {
    return validators;
  }

  return null;
}

function setScopeValidators(scope: AbstractControl, scopeKeyValue: string, control: AbstractControl, requiredValidator: string): ValidationErrors | null {
  if (scope.value === scopeKeyValue) {
    const controlValues = control?.value?.length ? control.value[0].id : null;
    if (isNullOrUndefined(controlValues)) {
      const ctrlsConfig = [{ ctrl: control, validators: [Validators.required], errorKeyMessage: 'required' }];
      return setMultipleValidationCustomErrorsMark(ctrlsConfig, requiredValidator);
    }
  }
  return null;
}

function setOperatorsValidators(
  operatorCtrl: AbstractControl,
  operatorValueCtrl: AbstractControl,
  operatorValue1Ctrl: AbstractControl,
  operatorValue2Ctrl: AbstractControl,
  operatorValueRequiredValidator: string
): ValidationErrors | null {
  const value = operatorValueCtrl.value ?? null;
  const value1 = operatorValue1Ctrl.value ?? null;
  const value2 = operatorValue2Ctrl.value ?? null;

  if (operatorCtrl?.value?.length && operatorCtrl.value[0]?.id === 'bt') {
    return checkBtOperatorValuesValidation(operatorValue1Ctrl, operatorValue2Ctrl, value1, value2);
  } else {
    return checkValuePercentageNumberValidation(operatorValueCtrl, value, operatorValueRequiredValidator);
  }
}

function checkValuePercentageNumberValidation(
  operatorValueCtrl: AbstractControl,
  value: number | string,
  operatorValueRequiredValidator: string,
): ValidationErrors | null {
  if (isNullOrUndefined(value) || isNaN(parseFloat(value as string))) {
    const ctrlsConfig = [{ ctrl: operatorValueCtrl, validators: REQUIRED_PERCENTAGE_VALIDATORS, errorKeyMessage: REQUIRED_ERROR_MESSAGE }];
    return setMultipleValidationCustomErrorsMark(ctrlsConfig, operatorValueRequiredValidator);
  } else {
    const isValueMax2Decimals = !!((value || value === 0) && MAX_2_DECIMALS_REGEX.exec(value.toString()));
    if (!isValueMax2Decimals) {
      const ctrlsConfig = [{ ctrl: operatorValueCtrl, validators: REQUIRED_PERCENTAGE_VALIDATORS, errorKeyMessage: PERCENTAGE_FORMAT_ERROR_MESSAGE }];
      return setMultipleValidationCustomErrorsMark(ctrlsConfig, PERCENTAGE_FORMAT_VALIDATION_MESSAGE);
    }
    return null;
  }
}

function checkBtOperatorValuesValidation(operatorValue1Ctrl: AbstractControl, operatorValue2Ctrl: AbstractControl, value1: string | number, value2: string | number): ValidationErrors | null {
  const isValue1Max2Decimals = !!((value1 || value1 === 0) && MAX_2_DECIMALS_REGEX.exec(value1.toString()));
  const isValue2Max2Decimals = !!((value2 || value2 === 0) && MAX_2_DECIMALS_REGEX.exec(value2.toString()));
  if ((isNullOrUndefined(value1) || (!value1 && value1 !== 0))  && (isNullOrUndefined(value2) || (!value2 && value2 !== 0))) {
    const validationMessage = 'marginAmountValuesRequired';
    const ctrlsConfig = [
      { ctrl: operatorValue1Ctrl, validators: REQUIRED_PERCENTAGE_VALIDATORS, errorKeyMessage: REQUIRED_ERROR_MESSAGE },
      { ctrl: operatorValue2Ctrl, validators: REQUIRED_PERCENTAGE_VALIDATORS, errorKeyMessage: REQUIRED_ERROR_MESSAGE }
    ];
    return setMultipleValidationCustomErrorsMark(ctrlsConfig, validationMessage);
  } else if ((isNullOrUndefined(value1) || (!value1 && value1 !== 0)) && isValue2Max2Decimals) {
    const ctrlsConfig = [{ ctrl: operatorValue1Ctrl, validators: REQUIRED_PERCENTAGE_VALIDATORS, errorKeyMessage: PERCENTAGE_FORMAT_ERROR_MESSAGE }];
    return setMultipleValidationCustomErrorsMark(ctrlsConfig, PERCENTAGE_FORMAT_VALIDATION_MESSAGE);
  } else if (isValue1Max2Decimals && (isNullOrUndefined(value2) || (!value2 && value2 !== 0))) {
    const ctrlsConfig = [{ ctrl: operatorValue2Ctrl, validators: REQUIRED_PERCENTAGE_VALIDATORS, errorKeyMessage: PERCENTAGE_FORMAT_ERROR_MESSAGE }];
    return setMultipleValidationCustomErrorsMark(ctrlsConfig, PERCENTAGE_FORMAT_VALIDATION_MESSAGE);
  } else {
    return checkRangePercentageBtOperatorValues(operatorValue1Ctrl, operatorValue2Ctrl, isValue1Max2Decimals, isValue2Max2Decimals);
  }
}

function checkRangePercentageBtOperatorValues(operatorValue1Ctrl: AbstractControl, operatorValue2Ctrl: AbstractControl, isValue1Max2Decimals: boolean, isValue2Max2Decimals: boolean): ValidationErrors | null {
  operatorValue1Ctrl.setErrors(null);
  operatorValue2Ctrl.setErrors(null);
  const rangeValidity = checkValue1GreaterThanValue2(operatorValue1Ctrl, operatorValue2Ctrl);
  if (rangeValidity) {
    return rangeValidity;
  } else if (!isValue1Max2Decimals && !isValue2Max2Decimals) {
    const ctrlsConfig = [
      { ctrl: operatorValue1Ctrl, validators: REQUIRED_PERCENTAGE_VALIDATORS, errorKeyMessage: PERCENTAGE_FORMAT_ERROR_MESSAGE },
      { ctrl: operatorValue2Ctrl, validators: REQUIRED_PERCENTAGE_VALIDATORS, errorKeyMessage: PERCENTAGE_FORMAT_ERROR_MESSAGE }
    ];
    return setMultipleValidationCustomErrorsMark(ctrlsConfig, PERCENTAGE_FORMAT_VALIDATION_MESSAGE);
  } else if (!isValue1Max2Decimals && isValue2Max2Decimals) {
    const ctrlsConfig = [{ ctrl: operatorValue1Ctrl, validators: REQUIRED_PERCENTAGE_VALIDATORS, errorKeyMessage: PERCENTAGE_FORMAT_ERROR_MESSAGE }];
    return setMultipleValidationCustomErrorsMark(ctrlsConfig, PERCENTAGE_FORMAT_VALIDATION_MESSAGE);
  } else if (isValue1Max2Decimals && !isValue2Max2Decimals) {
    const ctrlsConfig = [{ ctrl: operatorValue2Ctrl, validators: REQUIRED_PERCENTAGE_VALIDATORS, errorKeyMessage: PERCENTAGE_FORMAT_ERROR_MESSAGE }];
    return setMultipleValidationCustomErrorsMark(ctrlsConfig, PERCENTAGE_FORMAT_VALIDATION_MESSAGE);
  }
  return null;
}
