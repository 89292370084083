import { Component, OnInit, ViewChildren, OnDestroy } from '@angular/core';
import { VersionsService } from '../versions.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import 'brace';
import 'brace/mode/json';
import 'brace/theme/monokai';
import { getItemType } from '../utils/common.utils';

@Component({
  selector: 'app-show-versions',
  templateUrl: './show-versions.component.html',
  styleUrls: ['./show-versions.component.scss'],
  providers: [ VersionsService ]
})
export class ShowVersionsComponent implements OnInit, OnDestroy {

  id: number;
  apiEndPoint: string;
  element: unknown;
  selectedTab = 'params';

  get itemType(): string {
    return getItemType(this.element);
  }

  private readonly destroy$ = new Subject<void>();

  @ViewChildren('editor') editor;

  constructor(
    private readonly translate: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly confirmationService: ConfirmationService,
    private readonly versionsService: VersionsService
  ) {}

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  paramsLabel(): string {
    let label;
    switch (this.element['event']) {
      case 'create':
        label = this.translate.instant('resources.versions.event.create');
        break;
      case 'update':
        label = this.translate.instant('resources.versions.event.update');
        break;
      case 'destroy':
        label = this.translate.instant('resources.versions.event.destroy');
        break;
      default:
        label = this.translate.instant('resources.versions.event.default');
    }
    return label;
  }

  changeTab(tab: string): void {
    this.selectedTab = tab;
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'versions';
        this.getVersionsById();
      }
    });
  }

  private getVersionsById(): void {
    this.versionsService.getVersionById(this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (resp) => {
        this.element = resp;
        this.element['params'] = JSON.stringify(resp.params, null, '\t');
        this.element['object_changes'] = JSON.stringify(resp.object_changes, null, '\t');
      },
      error: (errorData) => this.confirmationService.displayHttpErrorAlert(errorData)
    });
  }
}
