import { TranslateService } from "@ngx-translate/core";
import { CouponsAppAnalysisGroupedResults, ReportCouponsAppAnalysisCalculatedData } from "../model/report-coupons-in-app-analysis.model";

const SUMMABLE_FIELDS = [
  'activating_customers_with_app',
  'activating_customers_without_app',
  'coupon_printing_customers_with_app',
  'coupon_printing_customers_without_app',
  'coupons_activated_with_app',
  'coupons_issued_with_app',
  'coupons_issued_without_app',
  'customers_impacted_with_app',
  'customers_impacted_without_app',
  'redeemer_customers_with_app',
  'redeemer_customers_with_app_after_selection',
  'redeemer_customers_without_app',
  'sum_discount_with_app',
  'sum_discount_without_app',
  'total_redeemer_customers',
  'total_ticket_redemption_with_app',
  'total_ticket_redemption_without_app',
  'total_voucher_redemptions_with_app',
  'total_voucher_redemptions_without_app'
];

const formulaeFields = {
  coupons_redemptions_per_customer_with_app: (totals: ReportCouponsAppAnalysisCalculatedData) =>
    totals.redeemer_customers_with_app !== 0 ? totals.total_voucher_redemptions_with_app / totals.redeemer_customers_with_app : 0,
  coupons_redemptions_per_customer_without_app: (totals: ReportCouponsAppAnalysisCalculatedData) =>
    totals.redeemer_customers_without_app !== 0 ? totals.total_voucher_redemptions_without_app / totals.redeemer_customers_without_app : 0,
  average_discount_per_customer_with_app: (totals: ReportCouponsAppAnalysisCalculatedData) =>
    totals.redeemer_customers_with_app !== 0 ? totals.sum_discount_with_app / totals.redeemer_customers_with_app : 0,
  average_discount_per_customer_without_app: (totals: ReportCouponsAppAnalysisCalculatedData) =>
    totals.redeemer_customers_without_app !== 0 ? totals.sum_discount_without_app / totals.redeemer_customers_without_app : 0,
  percentage_customers_engaged_with_app_ratio: (totals: ReportCouponsAppAnalysisCalculatedData) =>
    totals.customers_impacted_with_app !== 0 ? totals.activating_customers_with_app / totals.customers_impacted_with_app : 0,
  percentage_redemption_with_app: (totals: ReportCouponsAppAnalysisCalculatedData) =>
    totals.customers_impacted_with_app !== 0 ? totals.total_voucher_redemptions_with_app / totals.customers_impacted_with_app : 0,
  percentage_redemption_without_app: (totals: ReportCouponsAppAnalysisCalculatedData) =>
    totals.customers_impacted_without_app !== 0 ? totals.total_voucher_redemptions_without_app / totals.customers_impacted_without_app : 0,
  percentage_printing_customers_with_app_ratio: (totals: ReportCouponsAppAnalysisCalculatedData) =>
    totals.customers_impacted_with_app !== 0 ? totals.coupon_printing_customers_with_app / totals.customers_impacted_with_app : 0,
  percentage_printing_customers_without_app_ratio: (totals: ReportCouponsAppAnalysisCalculatedData) =>
    totals.customers_impacted_without_app !== 0 ? totals.coupon_printing_customers_without_app / totals.customers_impacted_without_app : 0,
  percentage_redeemer_customers_with_app_ratio: (totals: ReportCouponsAppAnalysisCalculatedData) =>
    totals.customers_impacted_with_app !== 0 ? totals.redeemer_customers_with_app / totals.customers_impacted_with_app : 0,
  percentage_redeemer_customers_without_app_ratio: (totals: ReportCouponsAppAnalysisCalculatedData) =>
    totals.customers_impacted_without_app !== 0 ? totals.redeemer_customers_without_app / totals.customers_impacted_without_app : 0,
  percentage_redeemer_customers_with_app_after_selection_ratio: (totals: ReportCouponsAppAnalysisCalculatedData) =>
    totals.customers_impacted_with_app !== 0 ? totals.redeemer_customers_with_app_after_selection / totals.customers_impacted_with_app : 0,
  sum_discount: (totals: ReportCouponsAppAnalysisCalculatedData) =>
    totals.sum_discount_with_app + totals.sum_discount_without_app,
  sum_total_ticket_redemption: (totals: ReportCouponsAppAnalysisCalculatedData) =>
    totals.total_ticket_redemption_with_app + totals.total_ticket_redemption_without_app,
  sum_total_voucher_redemptions: (totals: ReportCouponsAppAnalysisCalculatedData) =>
    totals.total_voucher_redemptions_with_app + totals.total_voucher_redemptions_without_app,
};

export function groupRawResultsByCampaignId(rawResults: ReportCouponsAppAnalysisCalculatedData[], translate: TranslateService): CouponsAppAnalysisGroupedResults[] {
  // Extract unique campaign_ids using Set
  const uniqueCampaignIds = [...new Set(rawResults.map(result => result.campaign_id))];

  // Group results by campaign_id
  const groupedResults = uniqueCampaignIds.map(campaignId => {
    const filteredCampaigns = rawResults.filter(result => result.campaign_id === campaignId);
    return {
      campaign_id: campaignId,
      results: filteredCampaigns,
      total_result: calculateTotalResult(filteredCampaigns, translate)
    };
  });

  return groupedResults;
}

function calculateTotalResult(groupedResults: ReportCouponsAppAnalysisCalculatedData[], translate: TranslateService): ReportCouponsAppAnalysisCalculatedData {
  // Assign first result as the base for all static values (campaign data, coupon, and others).
  const totals = Object.assign({}, groupedResults[0]);

  // Summable fields that will be summed across all results
  SUMMABLE_FIELDS.forEach(field => {
    totals[field] = groupedResults.reduce((acc, result) => acc + result[field], 0);
  });

  // Formulas that will be calculated across all results
  for (const key in formulaeFields) {
    totals[key] = formulaeFields[key](totals);
  }

  // Campaign name is displayed as "Total: <campaign_name>" to highlight that this is the total result
  totals.campaign_name = translate.instant('common.total') + `: ${totals.campaign_name}`;

  // Calculate the start and end date of the campaign
  const minDate = groupedResults.reduce((min, result) => result.date_from < min ? result.date_from : min, groupedResults[0].date_from);
  const maxDate = groupedResults.reduce((max, result) => result.date_to > max ? result.date_to : max, groupedResults[0].date_to);
  totals['start_end_date'] = `${minDate} - ${maxDate}`;

  return totals;
}