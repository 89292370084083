import { Component, EventEmitter, Output } from '@angular/core';
import { FeatureFlagsService } from '../../shared/services/feature-flags.service';
import { NavItem } from './nav-item-revamp';
import { SideNavRevampService } from './side-nav-revamp.service';
import { SIDENAVREVAMP } from './nav-items-revamp.constants';

@Component({
  selector: 'app-new-side-nav-revamp',
  templateUrl: './side-nav-revamp.component.html',
  styleUrls: ['./side-nav-revamp.component.scss']
})

export class SideNavRevampComponent {

  featureFlags = this.featureFlagsService.flags;
  roleSlug = this.sideNavRevampService.role.slug;
  flags = this.sideNavRevampService.flags;
  navItems:  NavItem[] = this.sideNavRevampService.setModulePermissions([...SIDENAVREVAMP]);

  @Output() onEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private sideNavRevampService: SideNavRevampService,
    private featureFlagsService: FeatureFlagsService
  ) { }

  isNotAuth(item: NavItem): boolean {
    return this.sideNavRevampService.isNotAuth(item);
  }

  triggerEvent(event): void {
    this.onEvent.emit(event);
  }

  showNotAvailable(): void {
    this.sideNavRevampService.showNotAvailable();
  }
}