import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';

import { LgCurrencyPipe } from '../../../../shared/pipes/lg-currency.pipe';

@Component({
  selector: 'app-budget-info',
  standalone: true,
  imports: [CommonModule, TranslateModule, LgCurrencyPipe, TooltipModule],
  templateUrl: './budget-info.component.html',
  styleUrl: './budget-info.component.scss'
})
export class BudgetInfoComponent implements OnChanges {
  @Input() currentBudgetAmount: number;
  @Input() maxBudgetAmount: number;
  @Input() titleKey: string;
  icon: string = 'ti ti-check';
  separator: string = '<';
  colorClass: string = 'text-success';
  @Input() budgetAlertEnabled: boolean;

  ngOnChanges(_changes: SimpleChanges) {
    if (this.budgetAlertEnabled && this.maxBudgetAmount > 0) {
      const budgetDifference = this.maxBudgetAmount - this.currentBudgetAmount;
      if (budgetDifference < 0) {
        this.icon = 'ti ti-alert';
        this.separator = '>';
        this.colorClass = 'text-danger';
      } else if (budgetDifference === 0) {
        this.separator = '=';
        this.colorClass = 'text-warning';
      }
    } else {
      this.colorClass = 'text-muted';
    }
  }
}
