<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card-block no-pdd">
        <div class="mrg-top-10">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form [formGroup]="form" *ngIf="form">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="channels">
                        {{'resources.contact_policies.fields.channels' | translate}}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="row no-mrg-left">
                        <span class="mrg-right-15">
                          <app-question [question]="getInputConfig('cp_email')" [form]="form"></app-question>
                        </span>
                        <span class="mrg-right-15">
                          <app-question [question]="getInputConfig('cp_push')" [form]="form"></app-question>
                        </span>
                        <span class="mrg-right-15">
                          <app-question [question]="getInputConfig('cp_sms')" [form]="form"></app-question>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name">
                        {{'resources.contact_policies.fields.name' | translate}}
                        <span class="text-danger">*</span>
                      </label>
                      <app-question [question]="getInputConfig('name')" [form]="form"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name">
                        {{'resources.contact_policies.fields.timeframe' | translate}}
                        <span class="text-danger">*</span>
                      </label>
                      <app-question [question]="getInputConfig('timeframe')" [form]="form"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name">
                        {{'resources.contact_policies.fields.max_contacts' | translate}}
                        <span class="text-danger">*</span>
                      </label>
                      <app-question [question]="getInputConfig('max_contacts')" [form]="form"></app-question>
                    </div>
                  </div>
                </div>
                <div class="alert alert-info" role="alert">
                  <span class="info-text">
                    {{'resources.contact_policies.messages.segment_info' | translate}}
                  </span>
                </div>
                <div class="row" *ngIf="flags.showContactPoliciesSegmentInput">
                  <div class="col-md-12 pdd-right-5">
                    <div class="form-group">
                      <label for="name">
                        {{'resources.contact_policies.fields.segment' | translate}}
                      </label>
                      <div class="segment-container">
                        <app-question
                          class="flex-grow-1"
                          [question]="getInputConfig('segment_id')"
                          [form]="form"
                          [filters]="{only_tagged: true}"></app-question>
                        <button type="button"
                          class="btn btn-default btn-icon"
                          (click)="openSegment()"
                          [disabled]="!form.value.segment_id?.length"
                          pTooltip="{{'resources.campaigns.buttons.see_segment' | translate}}">
                          <i class="ei-show"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
