import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FeaturesService } from '../../../../resources/data-warehouse/products/services/products-features/features.service';
import { QuestionBase } from '../../../models/forms/question-base';
import { ProductTaxonomy } from '../product-taxonomy';

@Component({
  selector: 'app-single-input',
  templateUrl: './single-input.component.html',
  styleUrls: ['./single-input.component.css'],
  providers: [FeaturesService]
})
export class SingleInputComponent implements OnInit {

  @Input('filter') filter: QuestionBase<any>;
  @Input('nielsenTaxonomy') nielsenTaxonomy: ProductTaxonomy;
  @Input('form') form: UntypedFormGroup;

  @Output() public onFilterEmitter: EventEmitter<any>;

  constructor(
    private featuresService: FeaturesService
  ) {
    this.onFilterEmitter = new EventEmitter<any>();
  }

  ngOnInit() {
    if(this.filter.dataSource instanceof FeaturesService) this.filter.dataSource = this.featuresService;
  }

  public onFilterChange() {
    this.onFilterEmitter.emit(this.filter);
  }

  public refreshOptions() {
    this.filter.dataSource.optsSource.next({});
  }
}
