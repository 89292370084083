<div class="row" *ngIf="hasFormKeyWithValue('via', 'email')">
  <div class="col-md-6">
    <div class="form-group">
      <label>{{'resources.campaigns.fields.email_subject' | translate}}
        <span class="required-text-bold" class="text-danger">*</span>
      </label>
      <app-question [question]="getInputConfig('email_subject')" [form]="campaignForm"></app-question>
    </div>
    <div class="form-group">
      <label>{{'resources.campaigns.fields.chose_email_template' | translate}}
        <span class="required-text-bold text-danger">*</span>
      </label>
      <div class="selector-container">
        <app-question
          (multiselectChanged)="handleTemplateChanges($event, 'email')"
          [question]="getInputConfig('email_template')"
          [form]="campaignForm">
          </app-question>
        <div class="btn-group">
          <button type="button"
                  class="btn btn-default btn-icon"
                  (click)="viewOrEditTemplate('emailView')"
                  [disabled]="isDisabled('email_template')">
            <i class="ei-show"></i>
          </button>
          <button type="button"
                  class="btn btn-default btn-icon"
                  (click)="viewOrEditTemplate('emailEdit')"
                  [disabled]="isDisabled('email_template')">
            <i class="ei-pencil"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>{{'resources.campaigns.messages.make_delivery_test' | translate}}</label>
          <app-question [question]="getInputConfig('email_test_delivery')" [form]="campaignForm"></app-question>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>&nbsp;</label>
          <div>
            <button type="button" class="btn btn-default btn-icon" (click)="submitTest('email_test_delivery')">
              {{'resources.campaigns.buttons.send_destination_test' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
      {{'resources.campaigns.warnings.variables_in_test' | translate}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
      {{'resources.campaigns.warnings.destinations_test' | translate}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>
<div class="row" *ngIf="hasFormKeyWithValue('via', 'sms')">
  <div class="col-md-6">
    <div class="form-group">
      <label>{{'resources.campaigns.fields.chose_sms_template' | translate}}
        <span class="required-text-bold" class="text-danger">*</span>
      </label>
      <div class="selector-container">
        <app-question
          (multiselectChanged)="handleTemplateChanges($event, 'sms')"
          [question]="getInputConfig('sms_template')"
          [form]="campaignForm">
        </app-question>
        <div class="btn-group">
          <button type="button"
                  class="btn btn-default btn-icon"
                  (click)="viewOrEditTemplate('smsView')"
                  [disabled]="isDisabled('sms_template')">
            <i class="ei-show"></i>
          </button>
          <button type="button"
                  class="btn btn-default btn-icon"
                  (click)="viewOrEditTemplate('smsEdit')"
                  [disabled]="isDisabled('sms_template')">
            <i class="ei-pencil"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>{{'resources.campaigns.messages.make_delivery_test' | translate}}</label>
          <app-question [question]="getInputConfig('sms_test_delivery')" [form]="campaignForm"></app-question>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>&nbsp;</label>
          <div>
            <button type="button"
              class="btn btn-default btn-icon"
              (click)="submitTest('sms_test_delivery')"
              [disabled]="!campaignForm.value.sms_test_delivery">
              {{'resources.campaigns.buttons.send_destination_test' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
      {{'resources.campaigns.warnings.variables_in_test' | translate}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
      {{'resources.campaigns.warnings.destinations_test' | translate}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
      {{'resources.campaigns.warnings.prefix_test' | translate}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>
<div class="row" *ngIf="hasFormKeyWithValue('via', 'push')">
  <div class="col-md-6">
    <div class="form-group">
      <label>{{'resources.campaigns.fields.chose_push_template' | translate}}
        <span class="required-text-bold" class="text-danger">*</span>
      </label>
      <div class="selector-container">
        <app-question (multiselectChanged)="handleTemplateChanges($event, 'push')"
          [question]="getInputConfig('push_template')"
          [form]="campaignForm"></app-question>
        <div class="btn-group">
          <button type="button"
            class="btn btn-default btn-icon"
            (click)="viewOrEditTemplate('pushView')"
            [disabled]="isDisabled('push_template')">
            <i class="ei-show"></i>
          </button>
          <button type="button"
            class="btn btn-default btn-icon"
            (click)="viewOrEditTemplate('pushEdit')"
            [disabled]="isDisabled('push_template')">
            <i class="ei-pencil"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-4 form-group">
        <label>{{'resources.campaigns.messages.make_delivery_test' | translate}}</label>
        <app-question [question]="getInputConfig('push_test_delivery_prefix')" [form]="campaignForm"></app-question>
      </div>
      <div class="col-md-5 form-group">
        <label>&nbsp;</label>
        <app-question [question]="getInputConfig('push_test_delivery')" [form]="campaignForm"></app-question>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>&nbsp;</label>
          <div>
            <button type="button" class="btn btn-default btn-icon pull-right" (click)="submitTest('push_test_delivery')">
              {{'resources.campaigns.buttons.send_destination_test' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
      {{'resources.campaigns.warnings.destinations_in_push' | translate}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
      {{'resources.campaigns.warnings.prefix_test' | translate}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>
