import { Injectable } from '@angular/core';
import { SegmentConditionProvider } from './condition.service';
import { QuestionBase } from '../../models/forms/question-base';
import { MultiSelectQuestion } from '../../models/forms/question-multiselect';
import { DynamicDateQuestion } from '../../models/forms/question-dynamic-date';
import { TranslateService } from '@ngx-translate/core';
import { LocationsService } from '../../../resources/data-warehouse/locations/locations.service';
import { DateService } from '../date.service';
import { FloatQuestion } from '../../models/forms/question-float';
import { CheckboxQuestion } from '../../models/forms/question-checkbox';
import { UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { multiselectPresenceValidator, checkValue1GreaterThanValue2 } from '../validations.service';
import { getParseBooleanValue, getParseOperatorValue, getParseValue, setLocationsFiltering } from './utils/common.utils';
import { LocationsTaxonomyTermsService } from '../../../resources/data-warehouse/locations/location-taxonomy-terms.service';
import { AverageActivityCondition } from '../../models/segments/conditions/average-activity-condition';
import { FeatureFlagsService } from '../feature-flags.service';
import { OperatorsService } from './common/operators.service';

@Injectable()
export class AverageActivityConditionService implements SegmentConditionProvider {

  flags = this.featureFlags.flags;
  inputs: QuestionBase<any>[];
  customValidators = [(control: UntypedFormGroup) => this.getFormGroupValidations(control)];

  private readonly operatorOpts = this.operatorsService.getAllNumericOperators();

  constructor(
    private translate: TranslateService,
    private dateService: DateService,
    private locationsTaxonomyTermsService: LocationsTaxonomyTermsService,
    private locationsService: LocationsService,
    private featureFlags: FeatureFlagsService,
    private operatorsService: OperatorsService
  ) {}

  getInputs(params: AverageActivityCondition): QuestionBase<any>[] {
    if (this.flags.showLocationTaxonomyTermIds) {
      setLocationsFiltering(params, this.locationsService);
    }

    const milliseconds = new Date().getMilliseconds();

    const inputs = [
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_from',
        label: this.translate.instant('resources.segment_conditions.fields.date_from'),
        getValue: (value) => {
          value = (value?.hasOwnProperty('id')) ? value.id : value;
          if (!value) { return {id: null, name: null}; }
          return this.dateService.dynamicDateToDateOption(value);
        },
        parseValue: (value) =>  {
          return (value && Object.keys(value).length) ? value.id : null;
        }
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_to',
        label: this.translate.instant('resources.segment_conditions.fields.date_to'),
        getValue: (value) => {
          value = (value?.hasOwnProperty('id')) ? value.id : value;
          if (!value) { return {id: null, name: null}; }
          return this.dateService.dynamicDateToDateOption(value);
        },
        parseValue: (value) =>  {
          return (value && Object.keys(value).length) ? value.id : null;
        }
      }),
      new MultiSelectQuestion({
        key: 'location_taxonomy_term_ids',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.location_taxonomy_term_ids'),
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locationsTaxonomyTermsService,
        getValue: (value) => ({ selectedIds: value ? value : [] }),
        parseValue: (values) => (values?.length ? values.map(value => value.id) : [])
      }),
      new MultiSelectQuestion({
        key: 'location_ids',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.location_ids'),
        settings: { singleSelection: false, enableCheckAll: true, enableSearchFilter: true, showCheckbox: false},
        dataSource: this.locationsService,
        getValue: (value) => ({ selectedIds: value ? value : [] }),
        parseValue: (values) => ((values?.length) ? values.map(value => value.id) : [])
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'operator',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorOpts,
        required: true,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        getValue: getParseValue(),
        parseValue: (value) => {
          return (value?.length) ? this.operatorOpts.find(operator => operator.id === value[0].id).id : null;
        },
        customValidators: [multiselectPresenceValidator]
      }),
      new FloatQuestion({
        key: 'value',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        type: 'number',
        min: 0.0,
        step: 0.1,
        required: true,
        getValue: (value) => value,
        parseValue: (value) => parseFloat(value)
      }),
      new FloatQuestion({
        key: 'value_bt',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.max_value'),
        type: 'number',
        min: 0.1,
        step: 0.1,
        required: true,
        getValue: (value) => value,
        parseValue: (value) => parseFloat(value)
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        key: `include_non_positive_average_${milliseconds}`,
        getValue: (value) => !!value,
        parseValue: (value) => !!value
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        key: `net_amount_${milliseconds}`,
        getValue: getParseBooleanValue(),
        parseValue: getParseBooleanValue()
      })
    ];

    if (params) { this.prepareInputValuesFromParams(inputs, params); }

    this.inputs = inputs;
    return this.inputs;
  }

  prepareFormValuesToAPI(params: AverageActivityCondition): AverageActivityCondition {
    const parsedValuesObj = {};
    Object.keys(params).forEach(_key => {
      const input = this.getInputByKey(this.inputs, _key);
      const parsedValue = input.parseValue(params[_key]);
      const key = this.getKeyWithoutDateId(_key);
      parsedValuesObj[key] = parsedValue;
    });
    return parsedValuesObj;
  }

  prepareFormValuesFromAPI(params: AverageActivityCondition): AverageActivityCondition {
    const parseValues = {
      date_from: getParseValue(),
      date_to: getParseValue(),
      location_taxonomy_term_ids: getParseValue(),
      location_ids: getParseValue(),
      operator : getParseOperatorValue(this.operatorOpts),
      value: (value) => value,
      value_bt: (value) => value,
      include_non_positive_average: (value) => !!value,
      net_amount: getParseBooleanValue()
    };

    const parsedValuesObj = {};
    Object.keys(params).forEach( _key => {
      const key: string = this.getKeyWithoutDateId(_key);
      if (parseValues[key]) { parsedValuesObj[key] = parseValues[key](params[key]); }
    });
    return parsedValuesObj;
  }

  private getInputByKey(_inputs: QuestionBase<any>[], _key: string ): QuestionBase<any> {
    let input: QuestionBase<any>;
    if (_key.indexOf('include_non_positive_average') >= 0) {
      input = _inputs[7];
    } else if (_key.indexOf('net_amount') >= 0) {
      input = _inputs[8];
    } else {
      input = _inputs.find(_input => _input.key === _key);
    }
    return input;
  }

  private getKeyWithoutDateId(_key: string): string {
    let key: string;
    if (_key.indexOf('include_non_positive_average') >= 0) {
      key = 'include_non_positive_average';
    } else if (_key.indexOf('net_amount') >= 0) {
      key = 'net_amount';
    } else {
      key = _key;
    }
    return key;
  }

  private prepareInputValuesFromParams(inputs: QuestionBase<any>[], params: AverageActivityCondition): void {
    Object.keys(params).forEach( _key => {
      const input = this.getInputByKey(inputs, _key);
      const value = input.getValue(params[_key]);
      if (value && value.hasOwnProperty('selectedIds')) {
        input.selectedIds = value.selectedIds;
      } else {
        input.value = value;
      }
    });
  }

  private getFormGroupValidations(control: UntypedFormGroup): ValidationErrors | null {
    const operator = control.get('operator');
    const valueCtrl = control.get('value');
    const valueBtCtrl = control.get('value_bt');

    valueBtCtrl.setValidators([]);

    if (operator?.value?.length && operator.value[0].id === 'bt') {
      valueBtCtrl.setValidators([Validators.required]);
      return checkValue1GreaterThanValue2(valueCtrl, valueBtCtrl);
    } else if (operator?.value?.length && operator.value[0].id !== 'bt') {
      valueBtCtrl.setValidators([]);
      return null;
    }
  }
}
