import { Modules } from "../../shared/enums/modules.enums";
import { NavItem } from "./nav-item-revamp";

/*********************************************/
/*               SIDENAV ITEMS               */
/*********************************************/

//#region <NavItems>
const BASIC_LOYALTY_ANALYTICS = new NavItem({
  id: Modules.BasicLoyaltyAnalytics,
  children: [
    {
      id: 'program-performance',
      children: [
        { id: 'overview' },
        { id: 'customers' },
        { id: 'audiences' },
        { id: 'contactability' }
      ]
    },
    {
      id: 'loyalty-playbook',
      children: [
        { id: 'insights' }
      ]
    },
    {
      id: 'campaign-performance',
      children: [
        { id: 'overview' },
        { id: 'earn-rules' },
        { id: 'promotions' },
        { id: 'challenges' },
        { id: 'rewards' },
        { id: 'budget-roi' }
      ]
    },
    {
      id: 'partners',
      children: [
        { id: 'overview' },
        {
          id: 'endemic',
          children: [
            { id: 'sales' },
            { id: 'locations' },
            { id: 'categories' },
            { id: 'products' },
            { id: 'customers' }
          ]
        }
      ]
    },
    {
      id: 'ledgers'
    },
    {
      id: 'retail-analytics',
      children: [
        { id: 'sales' },
        { id: 'locations' },
        { id: 'categories' },
        { id: 'system-categories' },
        { id: 'products' },
        { id: 'custom' }
      ]
    }
  ]
});

const LOYALTY_ENGINE = new NavItem({
  id: Modules.LoyaltyEngine,
  children: [
    { id: 'rules-engine' },
    { id: 'triggers' },
    { id: 'massive-actions' },
    { id: 'configuration' },
    { id: 'api-requests', isMarked: true, markedIcon: 'fa-duotone fa-link-simple' }
  ]
});

const TIERS = new NavItem({
  id: Modules.Tiers
});

const LOYALTY_PAYMENT = new NavItem({
  id: Modules.LoyaltyPayment,
  children: [
    { id: 'cashback-exchange' },
    { id: 'configuration' },
    { id: 'api-requests', isMarked: true, markedIcon: 'fa-duotone fa-link-simple' }
  ]
});

const REWARDS = new NavItem({
  id: Modules.Rewards,
  children: [
    { id: 'list', title: 'catalog' },
    { id: 'redemptions' }
  ]
});

const PARTNERS = new NavItem({
  id: Modules.Partners
});

const PROMOTIONS_ENGINE = new NavItem({
  id: Modules.PromotionsEngine,
  children: [
    { id: 'promotions' },
    { id: 'coupons' },
    { id: 'redemptions' },
    { id: 'api-requests', isMarked: true, markedIcon: 'fa-duotone fa-link-simple' }
  ]
});

const GAMIFICATION_CHALLENGES = new NavItem({
  id: Modules.GamificationChallenges,
  children: [
    { id: 'challenges' }
  ]
});

const REFERRAL_PROGRAMS = new NavItem({
  id: Modules.ReferralPrograms
});

const PERSONALIZATION_CAMPAIGNS = new NavItem({
  id: Modules.PersonalizationCampaigns,
  children: [
    { id: 'audiences' },
    { id: 'campaigns', title: 'targeted_campaigns' },
    { id: 'plans' },
    {
      id: 'templates',
      children: [
        { id: 'email', title: 'mail_templates' },
        { id: 'sms', title: 'sms_templates' },
        { id: 'push', title: 'push_templates' },
        { id: 'ticket-messages', title: 'campaign_messages' },
        { id: 'landings', },
        { id: 'cms' }
      ]
    },
    { id: 'special-actions' },
    { id: 'customer-journeys' }
  ]
});

const CUSTOMER_DATA_PLATFORM = new NavItem({
    id: Modules.CustomerDataPlatform,
    children: [
      {
        id: 'dashboards',
        children: [
          { id: 'data-quality' }
        ]
      },
      {
        id: 'warehouse',
        title: 'warehouse',
        children: [
          { id: 'customers' },
          { id: 'purchases' },
          { id: 'products' },
          { id: 'locations' }
        ]
      },
      {
        id: 'collect',
        title: 'collect',
        children: [
          { id: 'api-management' },
          { id: 'files-management' }
        ]
      },
      { id: 'custom-entities' },
      { id: 'customer-attributes' },
      { id: 'connectors' }
    ]
});

const GEN_AI_ANALYTICS = new NavItem({
  id: Modules.LoyalGPT,
  children: [
    { id: 'chatbot' },
    { id: 'prompts' },
    // { id: 'guide' }
  ]
});

const CONFIGURATION = new NavItem({
    id: Modules.Configuration,
    groupSeparator: true,
    children: [
      { id: 'offers' },
      { id: 'omnichannel' },
      { id: 'scheduler' },
      { id: 'consent-management' },
      { id: 'users' },
      { id: 'company' },
      { id: 'system' },
      { id: 'payments' }
    ]
});


export const SIDENAVREVAMP: NavItem[] = [
    BASIC_LOYALTY_ANALYTICS,
    LOYALTY_ENGINE,
    TIERS,
    LOYALTY_PAYMENT,
    REWARDS,
    PARTNERS,
    PROMOTIONS_ENGINE,
    GAMIFICATION_CHALLENGES,
    REFERRAL_PROGRAMS,
    PERSONALIZATION_CAMPAIGNS,
    CUSTOMER_DATA_PLATFORM,
    GEN_AI_ANALYTICS,
    CONFIGURATION
];
