<form [formGroup]="form">
  <div class="row">
    <div class="col-md-6">
      <app-question [question]="getInputConfig('name')" [form]="form"></app-question>
    </div>
    <div class="col-md-6">
      <app-question [question]="getInputConfig('campaign_plan_category_id')" [form]="form"></app-question>
    </div>
  </div>
  <hr>
  <div>
    <div class="file-upload">
      <ngx-dropzone [multiple]="multiple"
                    [accept]="getFilteredTypes()"
                    (change)="onSelect($event)"
                    (dragover)="onDragOver($event)"
                    (dragleave)="onDragLeave($event)"
                    (drop)="onDrop($event)"
                    [ngClass]="{ 'drag-active': isDragging }">
        <ngx-dropzone-label>
          <i class="ei ei-upload-1"></i>
          <br/>{{ 'components.upload.drag_here' | translate }}
          <br/>{{ 'components.upload.or' | translate }}<br/>
          <p class="file-upload-link">{{ 'components.upload.browse_files' | translate }}</p>
        </ngx-dropzone-label>
        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
          <ngx-dropzone-label><span class="file-name">{{ f.name }}</span> ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>
      <p class="accepted-types mrg-top-10" *ngIf="showFilteredTypes() != ''">{{ 'components.upload.accepted_types' | translate:{ types: showFilteredTypes() } }}
        <span *ngIf="isCsv()">{{ 'components.upload.utf8' | translate }}</span>
      </p>
    </div>
    <p>
      {{ 'resources.coupons.modal.import.download_template'| translate }}
      <a (click)="downloadTemplate()" target="_blank"><span class="text-info pointer">{{ 'resources.coupons.modal.import.here_link'| translate }}</span></a>
    </p>
  </div>
</form>
