<div class="modal-backdrop fade in" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="mrg-left-15 mrg-btm-5 first-letter-upper">{{'resources.plans.alerts.validation_modal.title' | translate}}</h4>
        <button class="modal-close" (click)="closeModal()">
          <i class="ti-close"></i>
        </button>
      </div>

      <div class="modal-body background-gray">
        <div class="row">
          <div class="col-md-12">

            <div class="container-fluid">
              <div class="row">
                <div class="mrg-top-10 col-md-12">

                  <div class="card-block no-pdd">

                    <div class="row">
                      <div class="col-md-8 ml-auto mr-auto">
                        <i class="fa fa-exclamation-triangle text-warning"></i> <label class="text-bold">{{'resources.plans.alerts.validation_modal.subtitle' | translate}}</label>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-8 ml-auto mr-auto" [innerHTML]="'resources.plans.alerts.validation_modal.body' | translate"></div>
                    </div>

                    <div class="row mt-3">
                      <div class="col-md-8 ml-auto mr-auto">
                        <form [formGroup]="validatePlanForm">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group no-mrg-btm d-flex">
                                <i class="fa fa-envelope mt-2 mr-2"></i>
                                <app-question [question]="notifyInput"
                                              [form]="validatePlanForm">
                                </app-question>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer background-white">
        <div class="pull-right d-flex align-items-center">
          <button class="btn btn-info pull-right" (click)="handleValidation($event)">
            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
            {{'common.buttons.validate' | translate}}
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
