import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfirmationService } from '../../services/confirmation.service';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FileUploadComponent),
    }
  ]
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {

  @Input() types: string[] = [];
  @Input() multiple: boolean;

  @Input() form: UntypedFormGroup;
  @Input() formControlName: string;
  @Input() id: string;
  @Input() hidden: boolean;
  @Input() class: string;
  @Input() required: boolean;
  @Input() value: any;
  @Input() maxFileSize: number;

  public files: File[] = [];
  isDragging: boolean;

  constructor(
    private confirmationService: ConfirmationService,
    private truncatePipe: TruncatePipe,
    private translate: TranslateService
  ) { }

  ngOnInit() {
  }

  /**
   * Tells Angular how to write value from model into view
   * Using setValue method to patch form values
   * Has to be implemented when using ControlValueAccessor
   */
  writeValue() {}

  /**
   * Registers a handler function that is called when the view changes
   * Has to be implemented when using ControlValueAccessor
   */
  registerOnChange() {}

  /**
   * Registers a handler to be called when the component receives a touch event,
   * useful for knowing if the component has been focused
   * Has to be implemented when using ControlValueAccessor
   */
  registerOnTouched() {}

  onSelect(event: object) {

    if (event['rejectedFiles']?.[0]?.reason === 'size') {
      const name = this.truncatePipe.transform(event['rejectedFiles']?.[0]?.name, 20);
      this.confirmationService.displayAlert(name, this.translate.instant('components.upload.max_size'), 'error');
      return;
    }

    this.files.push(...event['addedFiles']);
    if (!this.multiple && this.files && this.files.length >= 2) {
      this.onRemove(this.files[0]);
    }
    this.setValue(this.files);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.setValue(this.files);
  }

  setValue(value: any) {
    const formValue = {};
    formValue[this.id] = value;
    this.form.patchValue(formValue);
  }

  getFilteredTypes() {
    const strTypes = this.types.map(type => '.' + type);
    return strTypes.join(',');
  }

  showFilteredTypes() {
    return this.getFilteredTypes();
  }

  isCsv(): boolean {
    return this.types.indexOf('csv') !== -1;
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = false;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = false;
  }
}
