import { FeatureFlagsService } from './../../../../../../shared/services/feature-flags.service';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-show-history-info',
  templateUrl: './show-history-info.component.html',
  styleUrls: ['./show-history-info.component.scss']
})

export class ShowHistoryInfoComponent{
  showEstimations: boolean;
  showPaymentMethods: boolean;
  flags = this.featureFlags.flags;

  @Input() couponDetail;

  constructor(private featureFlags: FeatureFlagsService) {
    this.showEstimations = false;
  }

  seeEstimations() {
    this.showEstimations = !this.showEstimations;
  }

  seePaymentMethods() {
    this.showPaymentMethods = !this.showPaymentMethods;
  }
}
