import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { CheckboxQuestion } from '../../../../shared/models/forms/question-checkbox';
import { ImageQuestion } from '../../../../shared/models/forms/question-image';
import { MultiSelectQuestion } from '../../../../shared/models/forms/question-multiselect';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { CouponsService } from '../../../coupons/coupons.service';
import { FeatureTaxonomiesService } from '../../../data-warehouse/products/services/products-feature-taxonomies/feature-taxonomies.service';
import { FeaturesService } from '../../../data-warehouse/products/services/products-features/features.service';
import { ProductsService } from '../../../data-warehouse/products/services/products/products.service';
import { SegmentsService } from '../../../segments/segments.service';
import { LoyaltyScoresService } from '../../loyalty_scores/loyalty-scores.service';

@Component({
  selector: 'app-form-milestones',
  templateUrl: './form-milestones.component.html',
  styleUrls: ['./form-milestones.component.css']
})

export class FormMilestonesComponent implements OnInit {

  public inputs: QuestionBase<any>[];
  public milestonesForm: UntypedFormGroup;
  public mode: string;

  constructor(
    private router: Router,
    private qcs: QuestionControlService,
    private featureTaxonomies: FeatureTaxonomiesService,
    private featureSrv: FeaturesService,
    private productsSrv: ProductsService,
    private loyaltyEngineSrv: LoyaltyScoresService,
    private couponSrv: CouponsService,
    private segmentSrv: SegmentsService,
    private route: ActivatedRoute
  ) {
    this.mode = this.route.snapshot.data.mode;
  }

  ngOnInit(): void {
    this.setInputs();
    this.milestonesForm = this.qcs.toFormGroup(this.inputs);
    this.populateFormData();
  }

  returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.router.navigate(['/loyalty/milestones']).catch(() => {});
    }
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.milestonesForm, formKey, value);
  }

  populateFormData() {
    if (this.mode === 'edit') {
      this.milestonesForm.get('name').patchValue('Once you reach 4000AED in your LC Waikiki purchases you get a 10% discount voucher for your next purchase');
      this.milestonesForm.get('description').patchValue('Purchasing 4000AED in LC Waikiki stores gets your 10% discount');
      this.milestonesForm.get('mechanic_options').patchValue([{id: 'goal', name: 'Goal'}]);
      this.milestonesForm.get('period').patchValue([{id: 'quarterly', name: 'Quarterly'}]);
      this.milestonesForm.get('goal').patchValue(4000);

      this.milestonesForm.get('taxonomy_slug').patchValue([{id: '99', name: 'Brand'}]);
      this.milestonesForm.get('feature_ids').patchValue([{id: '6623', name: 'LC Waikiki'}]);
      // Rewards
      this.milestonesForm.get('loyalty_engine').patchValue([{id: '255', name: 'LC Waikiki Goals'}]);
      this.milestonesForm.get('coupon_ids').patchValue([{id: '42', name: '10% off next purchase LC Waikiki'}]);
      this.milestonesForm.get('badge').patchValue('LC Waikiki lover');
      // TARGET
      this.milestonesForm.get('target').patchValue(false);
      this.milestonesForm.get('segment_ids').patchValue([{id: '10', name: '(10) GOLD'}]);
    }
  }

  private mechanicOptions() {
    return [
      { id: 'stamps', name: 'Stamps' },
      { id: 'goal', name: 'Goal' },
    ];
  }

  private periodsOptions() {
    return [
      { id: 'monthly', name: 'Monthly' },
      { id: 'quarterly', name: 'Quarterly' },
      { id: 'semester', name: 'Semester' },
      { id: 'yearly', name: 'Yearly' }
    ];
  }

  private stampsOptions() {
    return [
      { id: '1', name: '1' },
      { id: '2', name: '2' },
      { id: '3', name: '3' },
      { id: '4', name: '4' },
      { id: '5', name: '5' },
      { id: '6', name: '6' },
      { id: '7', name: '7' },
      { id: '8', name: '8' },
      { id: '9', name: '9' },
      { id: '10', name: '10' }
    ];
  }

  private setInputs() {
    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'description',
        type: 'text',
        cssClasses: 'form-control input-default',
        required: true,
        getValue: (value) => value
      }),
      new ImageQuestion({
        key: 'image',
        type: 'file',
        cssClasses: 'input-default',
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'mechanic_options',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: false, enableCheckAll: false},
        options: this.mechanicOptions()
      }),
      new MultiSelectQuestion({
        key: 'period',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: false},
        options: this.periodsOptions()
      }),
      new MultiSelectQuestion({
        key: 'stamps',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: false},
        options: this.stampsOptions()
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'taxonomy_slug',
        dataSource: this.featureTaxonomies,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'feature_ids',
        dataSource: this.featureSrv,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'product_ids',
        dataSource: this.productsSrv,
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
      }),
      new TextboxQuestion({
        key: 'goal',
        type: 'number',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'loyalty_engine',
        dataSource: this.loyaltyEngineSrv,
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'coupon_ids',
        dataSource: this.couponSrv,
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
      }),
      new TextboxQuestion({
        key: 'badge',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        label: 'General',
        key: 'target',
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'segment_ids',
        dataSource: this.segmentSrv,
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
      }),
    ];

    this.inputs = inputsArray;
    return this.inputs;
  }

}
