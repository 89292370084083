<ng-container *ngIf="flags.showDemoWallmart">
  <div class="card">
    <div class="card-heading border bottom">
      <h4 class="text-bold card-title" [translate]="'resources.scores.loyalty_scores.form.customer_segmentation_title'"></h4>
    </div>
    <div class="card-block">
      <div class="alert alert-info" role="alert">
        <span class="alert-text">{{'resources.scores.loyalty_scores.descriptions.customer_segmentation' | translate}}</span>
      </div>
      <!-- SEGMENT ID -->
      <div class="row">
        <label class="text-bold mrg-left-15"
          [translate]="'resources.scores.loyalty_scores.fields.segment_id'"></label>
      </div>
      <div class="row flex-end">
        <div class="col-md-6">
          <div class="form-group">
            <app-question [question]="getInputConfig('segment_id')"
                          [form]="loyaltyScoresForm">
            </app-question>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-container>
