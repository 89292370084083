import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'is-what';

import { ConfigurationEntryResponse, ConfigurationService } from '../../../../shared/services/configuration/configuration.service';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { DateService } from '../../../../shared/services/date.service';
import { FeatureFlagsService } from './../../../../shared/services/feature-flags.service';
import { ForecastContentComponent } from './forecast-content/forecast-content.component';
import { getCurrencySymbol } from '../../../../shared/utils/common.utils';
import { Plan } from '../../plan';
import { PlanCampaign } from '../plan-campaign';
import { PlanCampaignAction } from '../custom-campaign-plan-audience/plan-campaign-action';
import { ProfileService } from '../../../../profiles/profile.service';
import { RefreshCacheService } from '../../../../shared/services/refresh-cache.service';
import { CompanyData } from '../../../../shared/models/company/company-data';

@Component({
  selector: 'app-custom-campaign-plan-forecast',
  templateUrl: './custom-campaign-plan-forecast.component.html',
  styleUrls: ['./custom-campaign-plan-forecast.component.css']
})

export class CustomCampaignPlanForecastComponent implements OnInit {
  currencySymbol: string;
  companyInfo: CompanyData;
  flags = this.featureFlags.flags;
  forecastData: PlanCampaignAction[];
  impactedCustomerCount = 0;
  includeEurecatPrediction = false;
  loading = false;
  subs$: Subscription[] = [];
  tableHeaders: {id: number, name: string}[];
  totalsLoaded = false;
  budgetAlertEnabled = false;
  showEurecatCheckbox = false;
  showCreditsCards = false;
  totals: {
    customers: number,
    delivered: number,
    deliveryCost: number,
    discountCost: number,
    estimatedDelivery: number,
    estimatedRedemption: number,
    estimatedRedemptionPerc: number,
    incremental: number,
    incrementalOverSales: number
    incrementalRatio: number,
    lgCost: number,
    maxRedemptionsPerCoupon: number,
    totalRedemptions: number,
    uniqueRedemptions: number,
  };

  @Input() plan: Plan;
  @Input() planCampaign: PlanCampaign;
  @Output('calculatedDataStatus') calculatedDataStatus: EventEmitter<string> = new EventEmitter();
  @Output('statusEmitter') statusEmitter: EventEmitter<string> = new EventEmitter<string>();
  @ViewChildren(ForecastContentComponent) forecastRows: QueryList<ForecastContentComponent>;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private dateService: DateService,
    private featureFlags: FeatureFlagsService,
    private profileService: ProfileService,
    private refreshCacheService: RefreshCacheService,
    private translate: TranslateService,
    private configurationService: ConfigurationService,
  ) {
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'narrow');
  }

  ngOnInit() {
    this.getTableHeaders();
    this.getForecastData();
    this.profileService.getRawUserProfile().subscribe(profileData => {
      this.companyInfo = new CompanyData(profileData['company']);
      this.showCreditsCards = this.flags.showPlanDetailCreditBalance && !!this.companyInfo;
    });
    if (this.flags.showPlansAlertsConfiguration) {
      this.configurationService.getConfiguration('alerts', 'total_budget').subscribe((config: ConfigurationEntryResponse) => this.budgetAlertEnabled = config.active);
    }
  }

  handleEmittedAction() {
    const unsavedActions = this.forecastData.filter((forecastRow) => !forecastRow.calculatedDataLoaded);
    const errorActions = this.forecastData.filter((forecastRow) => forecastRow.errors.length > 0);
    this.calculatedDataStatus.emit('loading');
    if (unsavedActions.length === 0 && errorActions.length === 0) {
      this.calculatedDataStatus.emit('finished_loading');
      this.calculateTotalValues();
    } else if (errorActions.length > 0) {
      this.calculatedDataStatus.emit('actions_with_errors');
    }
  }

  handleEurecatPredictionChange(value: boolean) {
    if (value) {
      this.forecastRows.forEach(el => el.getEurecatEstimatedRedemption());
    } else {
      this.forecastRows.forEach(el => el.hideEurecatEstimatedRedemption());
    }
  }

  private getTableHeaders() {
    const lgCostColId = 16;
    const deliveryCostColdId = 6;
    let headers = [
      { id: 1, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.segment')},
      { id: 2, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.channel')},
      { id: 3, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.impacted_customers')},
      { id: 4, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.max_coupon_redemptions')},
      { id: 5, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.coupons_delivered')},
      { id: 6, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.delivery_cost', {currencySymbol: this.currencySymbol})},
      { id: 7, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.estimated_delivery')},
      { id: 8, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.total_redemptions')},
      { id: 9, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.estimated_redemption')},
      { id: 10, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.redemption_per_customer')},
      { id: 11, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.unique_redemptions')},
      { id: 12, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.discount')},
      { id: 13, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.min_purchase', {currencySymbol: this.currencySymbol})},
      { id: 14, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.percent_discount')},
      { id: 15, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.discount_cost', {currencySymbol: this.currencySymbol})},
      { id: 16, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.lg_cost')},
      { id: 17, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.incremental_sales', {currencySymbol: this.currencySymbol})},
      { id: 18, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.incremental_ratio')},
      { id: 19, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.increase_total_sales')}
    ]

    if (!this.flags.showPlanDetailCreditBalance) {
      headers = headers.filter(item => item.id !== deliveryCostColdId);
    }

    if (this.planCampaign.type !== 'Plans::SupplierCampaign') {
      const filteredHeaders = headers.filter(item => item.id !== lgCostColId);
      this.tableHeaders = filteredHeaders.map(element => this.translate.instant(element.name));
    } else {
      this.tableHeaders = headers.map(element => this.translate.instant(element.name));
    }
  }

  private getForecastData() {
    this.loading = true;
    this.enqueuePollingRequest();
  }

  private enqueuePollingRequest() {
    const params = {
      apiEndPoint: `campaign_plans/${this.planCampaign.id}/actions`,
      numberPerPage: 300
    };
    const forecastData$ = this.refreshCacheService.getExpensiveData(params).subscribe({
      next: response => {
        this.loading = false;
        const actions: PlanCampaignAction[] = response['list'].map((actionApiObj: Record<any, any>) => new PlanCampaignAction(actionApiObj));
        const actionsWithCoupon = actions.filter(action => !isNullOrUndefined(action.coupon_id));
        if (actionsWithCoupon.length === 0) {this.statusEmitter.emit('empty_selected_audiences'); }
        this.forecastData = actionsWithCoupon;
        this.changeDetector.markForCheck();
        this.showEurecatCheckbox = this.flags.eurecatCheckboxPredictionVisibility && !!this.forecastData && this.forecastData.length > 0;
      },
      error: errorData => {
        this.loading = false;
        this.forecastData = [];
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error);
        this.changeDetector.markForCheck();
      }
    });
    this.subs$.push(forecastData$);
  }

  private calculateTotalValues() {
    const channelCost = 0;
    const customers = this.forecastData.reduce((acc, obj) => acc + (obj.forecast.customers || 0), 0);
    const maxRedemptionsPerCoupon = this.forecastData.reduce((acc, obj) => acc + (obj.forecast.maxRedemptionsPerCoupon || 0), 0);
    const totalDeliveredCoupons = this.forecastData.reduce((acc, obj) => acc + (obj.forecast.delivered || 0), 0);
    const estimatedRedemption = this.forecastData.reduce((acc, obj) => acc + (obj.forecast.estimatedRedemption || 0), 0);
    const totalRedemptions = this.forecastData.reduce((acc, obj) => acc + (obj.forecast.totalRedemptions || 0), 0);
    const totalUniqueRedemp = this.forecastData.reduce((acc, obj) => acc + (Math.round(obj.forecast.uniqueRedemptions) || 0), 0);
    const totalDiscCost = this.forecastData.reduce((acc, obj) => acc + (obj.forecast.budgetExpense || 0), 0);
    const totalLgCost = this.forecastData.reduce((acc, obj) => acc + (obj.forecast.lgCost || 0), 0);
    const totalIncSales = this.forecastData.reduce((acc, obj) => acc + (obj.forecast.incremental || 0), 0);
    const totalAffectationSales = this.forecastData.reduce((acc, obj) => acc + (obj.forecast.affectationSales || 0), 0);
    const totalDeliveryCost = this.forecastData.reduce((acc, obj) => acc + (obj.forecast.deliveryCost || 0), 0);

    const estimatedDelivery = customers !== 0 ? (totalDeliveredCoupons / customers) * 100 : 0;
    const incrementalRatio = totalDiscCost !== 0 ? (totalIncSales / (totalDiscCost + channelCost)) : 0;
    const estimatedRedemptionPerc = totalDeliveredCoupons !== 0 ? ((totalRedemptions / totalDeliveredCoupons) * 100) : 0;

    const totalAffectationSalesByPlanDaysCount = totalAffectationSales * this.getPlanDaysCount();
    const incrementalOverSales = totalAffectationSalesByPlanDaysCount !== 0 ? (totalIncSales / totalAffectationSalesByPlanDaysCount) * 100 : 0;

    this.totals = {
      customers: customers,
      maxRedemptionsPerCoupon: maxRedemptionsPerCoupon,
      delivered: totalDeliveredCoupons,
      deliveryCost: totalDeliveryCost,
      estimatedDelivery: estimatedDelivery,
      totalRedemptions: totalRedemptions,
      estimatedRedemption: estimatedRedemption,
      estimatedRedemptionPerc: estimatedRedemptionPerc,
      uniqueRedemptions: totalUniqueRedemp,
      discountCost: totalDiscCost,
      lgCost: totalLgCost,
      incremental: totalIncSales,
      incrementalRatio: incrementalRatio,
      incrementalOverSales: incrementalOverSales
    };

    setTimeout(() => {
      this.totalsLoaded = true;
      this.changeDetector.markForCheck();
    });
  }

  private getPlanDaysCount(): number {
    return this.dateService.getDatesDiffByTimeUnit(new Date(this.plan.available_from), new Date(this.plan.available_to), 'days', true);
  }
}
