import { ButtonModule } from 'primeng/button';
import { cardPortletRefresh, cardPortletDelete } from './shared/directives/cards.directive';
import { CommonLayoutComponent } from './common/common-layout.component';
import { CommonModule } from '@angular/common';
import { ContentFooterComponent } from './common/content-footer/content-footer.component';
import { ContentHeaderComponent } from './common/content-header/content-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './common/home/home.component';
import { MaintenanceModeComponent } from './shared/components/maintenance-mode/maintenance-mode.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { Sidebar_Directives } from './shared/directives/side-nav.directive';
import { SideNavComponent } from './common/side-nav/side-nav.component';
import { SidePanelComponent } from './common/side-panel/side-panel.component';
import { UserSettingsComponent } from './shared/components/user-settings/user-settings.component';
import { SideNavRevampComponent } from './common/side-nav-revamp/side-nav-revamp.component';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    cardPortletDelete,
    cardPortletRefresh,
    CommonLayoutComponent,
    ContentFooterComponent,
    ContentHeaderComponent,
    HomeComponent,
    MaintenanceModeComponent,
    Sidebar_Directives,
    SideNavComponent,
    SideNavRevampComponent,
    SidePanelComponent,
    UserSettingsComponent
  ],
  exports: [
    cardPortletDelete,
    cardPortletRefresh,
    CommonLayoutComponent,
    ContentFooterComponent,
    ContentHeaderComponent,
    HomeComponent,
    MaintenanceModeComponent,
    Sidebar_Directives,
    SideNavComponent,
    SideNavRevampComponent,
    SidePanelComponent,
    UserSettingsComponent
  ]
})

export class InitialModule { }
