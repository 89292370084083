import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { MultiselectDataSource } from '../../../shared/components/multiselect/multiselect';
import { Observable } from 'rxjs';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { ImageQuestion } from '../../../shared/models/forms/question-image';
import { TextareaQuestion } from '../../../shared/models/forms/question-textarea';
import { NewsTaxonomyTermsService } from './news-taxonomy-terms-service';
import { DateService } from '../../../shared/services/date.service';

@Injectable()
export class CmsService extends ResourcesService {

  inputs: QuestionBase<unknown>[];

  constructor(
    http: HttpClient,
    private dateService: DateService,
    private newsTaxonomyTerms: NewsTaxonomyTermsService) {
    super(http);
  }

  fetchMultiselect( searchValues?: string, page?: number ) {
    const requestOptions = {
      apiEndPoint: 'news',
      pageNumber: 1,
      numberPerPage: 300,
      filtering: {
        order_by: 'created_at', order_sense: 'desc'}
      };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getById(id);
  }

  getTaxonomies() {

    const requestOptions = {
      apiEndPoint: 'news_taxonomy_terms',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 10
    };

    return this.getData(requestOptions);
  }

  getNews(options?: object) {

    let requestOptions = {
      apiEndPoint: 'news',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };

    if (options) {
      requestOptions = Object.assign(requestOptions, options);
    }
    return this.getData(requestOptions);
  }

  deleteData(id: number) {
    return this.deleteResource({}, `news/${id}`);
  }

  sendData(data: any) {
    return this.postResource(data, 'news');
  }

  updateData(data: any, id: number) {
    return this.patchResource(data, `news/${id}`);
  }

  getById(id: number) {
    return this.getData({apiEndPoint: `/news/${id}`});
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.title}`);
  }

  setInputs(formValues: any) {
    const inputsArray = [
      new ImageQuestion({
        key: 'image',
        type: 'file',
        cssClasses: 'input-default',
      }),
      new TextareaQuestion({
        cssClasses: 'form-control input-md',
        key: 'title',
        type: 'text',
        getValue: (value) => value
      }),
      new TextareaQuestion({
        cssClasses: 'form-control input-md',
        key: 'body',
        type: 'text',
        getValue: (value) => value
      }),
      new TextboxQuestion({

        key: 'publish_at',
        type: 'datetime-local',
        step: 60,
        cssClasses: 'form-control input-default',
        getValue: (value) => value ? this.dateService.dateISOStringToDateTime(value) : null
      }),
      new TextboxQuestion({
        key: 'available_to',
        type: 'datetime-local',
        step: 60,
        cssClasses: 'form-control input-default',

        getValue: (value) => value ? this.dateService.dateISOStringToDateTime(value) : null
      }),
      new MultiSelectQuestion({
        key: 'news_taxonomy_terms',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.newsTaxonomyTerms,
        getValue: () => {
          if (formValues && formValues.news_taxonomy_terms && formValues.news_taxonomy_terms.length > 0){
            return {
              selectedIds: formValues.news_taxonomy_terms.map(el => el.id)
            };
          } else {
            return {
              selectedIds: []
            };
          }
        }
      }),
    ];

    this.inputs = inputsArray;
    this.assignFormInputValues(formValues);
    return this.inputs;
  }

  private assignFormInputValues(formValues) {
    Object.keys(formValues).map(
      key => {
        const tmpInput = this.inputs.find(input => input.key === key);

        if (tmpInput && tmpInput.getValue) {
          const value = tmpInput.getValue(formValues[key]);
          if (value && value.hasOwnProperty('selectedIds')) {
            tmpInput.selectedIds = value.selectedIds;
          } else {
            tmpInput.value = value;
          }
        }
      });
  }

}
