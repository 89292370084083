<div class="row">
  <label class=" text-bold mrg-left-15"
         [translate]="'resources.scores.loyalty_scores.fields.customer_purchase'">
  </label>
  <em class="ti-info-alt pull-right mrg-top-3 mrg-left-5"
      tooltipPosition="top"
      pTooltip="{{'resources.scores.loyalty_scores.form.tooltip.customer_purchase_tooltip' | translate}}">
  </em>
</div>

<div class="row">
  <div class="col-md-4">
    <div class="form-group">
      <app-question [question]="getInputConfig('customer_purchase_options')"
                    [form]="loyaltyScoresForm">
      </app-question>
    </div>
  </div>
</div>
