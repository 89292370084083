import { Component, OnInit, OnDestroy } from '@angular/core';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportSegmentEvoService } from './report-segment.service';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Modules } from '../../../../../shared/enums/modules.enums';

@Component({
  selector: 'app-edit-segment-evo',
  templateUrl: './edit-segment-evo.component.html',
  styleUrls: ['./edit-segment-evo.component.css'],
  providers: [ReportSegmentEvoService]
})
export class EditSegmentEvoComponent implements OnInit, OnDestroy {

  inputs: QuestionBase<any>[];
  reportSegmentForm: UntypedFormGroup;
  id: number;
  historyId: number;
  loading: boolean;
  fetchReportDetail$: Subscription;
  updateData$: Subscription;
  sendData$: Subscription;
  isBasicLoyaltyAnalyticsModule = this.route.snapshot?.['_routerState']?.url?.split('/')?.[1] === Modules.BasicLoyaltyAnalytics;

  constructor(private qcs: QuestionControlService,
              private route: ActivatedRoute,
              private confirmationService: ConfirmationService,
              private translate: TranslateService,
              private router: Router,
              private reportSegmentService: ReportSegmentEvoService) { }

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy() {
    if ( this.fetchReportDetail$ ) { this.fetchReportDetail$.unsubscribe(); }
    if ( this.updateData$ ) { this.updateData$.unsubscribe(); }
    if ( this.sendData$ ) { this.sendData$.unsubscribe(); }
  }

  public closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  public getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id') && params.hasOwnProperty('history_id')) {
        this.id = params.id;
        this.historyId = params.history_id;
        this.getReportDetailById();
      } else {
        this.id = params.id;
        this.inputs = this.reportSegmentService.setInputs({});
        this.reportSegmentForm = this.qcs.toFormGroup(this.inputs);
      }
    });
  }

  private getReportDetailById() {
    this.fetchReportDetail$ = this.reportSegmentService.fetchReportDetailById(this.id, this.historyId).subscribe(
      data => {
        this.inputs = this.reportSegmentService.setInputs(data);
        this.reportSegmentForm = this.qcs.toFormGroup(this.inputs);
      }
    );
  }

  paintErrorsInForm(form: UntypedFormGroup, errorsArray: {field: string}[]) {
    this.qcs.paintErrorsInForm(this.inputs, form, errorsArray);
  }

  public sendData() {

    const segmentEvoOptions = {
      name: this.reportSegmentForm.value.name,
      report_id: this.id,
      type: 'ReportSegment',
      configuration: {
        date_from: this.reportSegmentForm.value.date_from ? this.reportSegmentForm.value.date_from.id : [],
        date_to: this.reportSegmentForm.value.date_to ? this.reportSegmentForm.value.date_to.id : [],
        segment_ids: this.reportSegmentForm.value.segment_ids ? this.reportSegmentForm.value.segment_ids.map(item => item.id) : [],
      }
    };

    if (!segmentEvoOptions.name || segmentEvoOptions.name === '') {
      delete segmentEvoOptions['name'];
    }

    if (!segmentEvoOptions.configuration.segment_ids || segmentEvoOptions.configuration.segment_ids.length === 0) {
      delete segmentEvoOptions.configuration.segment_ids;
    }

    if (this.historyId) {
      this.updateData$ = this.reportSegmentService.updateData(segmentEvoOptions, this.id, this.historyId).subscribe(
          response => {
            this.confirmationService.displaySuccessAlert(
              this.translate.instant('resources.reports.form.warnings.report_update_title'),
              this.translate.instant('resources.reports.form.warnings.report_update_text')
            ).catch(() => {});
            this.returnToList();
          },
          errorData => {
            if (errorData.error?.errors?.length > 0) {
              this.confirmationService.displayErrorAlert('Error', errorData.error.error );
              this.paintErrorsInForm( this.reportSegmentForm, errorData.error.errors );
            }
          }
        );
    } else {
      this.loading = true;
      this.sendData$ = this.reportSegmentService.sendData(segmentEvoOptions, this.id).subscribe(
        response => {
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.reports.form.warnings.report_success_title'),
            this.translate.instant('resources.reports.form.warnings.report_success_text')
          ).catch(() => {});
          this.returnToList();
          this.loading = false;
        },
        errorData => {
          if (errorData.error?.errors?.length > 0) {
            this.confirmationService.displayErrorAlert('Error', errorData.error.error);
            this.paintErrorsInForm(this.reportSegmentForm, errorData.error.errors);
          }
          this.loading = false;
        }
      );
    }
  }

  returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0 ) {
      this.router.navigate([{ outlets: { modal: `update/reports/${this.id}` } }]).catch(() => {});
    } else if (this.isBasicLoyaltyAnalyticsModule) {
      this.router.navigate([`/${Modules.BasicLoyaltyAnalytics}/campaign-performance/budget-roi/reports`]).catch(() => {});
    } else {
      this.router.navigate(['/offers-personalization/dashboard/reports']).catch(() => {});
    }
  }
}
