<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card-block no-pdd">
        <div class="mrg-top-10">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form [formGroup]="userForm" *ngIf="userForm">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="user_name">{{ 'resources.users.fields.name' | translate }}</label>
                      <span *ngIf="getInputConfig('user_name').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('user_name')" [form]="userForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="user_surname_1">{{ 'resources.users.fields.surname_1' | translate }}</label>
                      <span *ngIf="getInputConfig('user_surname_1').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('user_surname_1')" [form]="userForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="user_surname_2">{{ 'resources.users.fields.surname_2' | translate }}</label>
                      <span *ngIf="getInputConfig('user_surname_2').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('user_surname_2')" [form]="userForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="user_email">{{ 'resources.users.fields.email' | translate }}</label>
                      <span *ngIf="getInputConfig('user_email').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('user_email')" [form]="userForm"></app-question>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="!id">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="user_password">{{ 'resources.users.fields.password' | translate }}</label>
                        <span *ngIf="getInputConfig('user_password').required" class="text-danger">*</span>
                        <app-question class="question-hint" [question]="getInputConfig('user_password')" [form]="userForm"></app-question>
                        <ng-container *ngTemplateOutlet="passwordVisibilityTemplate; context: { key: 'user_password'}"></ng-container>
                        <ng-container *ngTemplateOutlet="passwordErrorTemplate; context: { key: 'user_password'}"></ng-container>
                        <ng-container *ngIf="userSecurity">
                          <app-user-password-validation [passwordCtrl]="userForm?.get('user_password')" [userSecurity]="userSecurity"></app-user-password-validation>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="user_password_confirmation">{{ 'resources.users.fields.password_confirmation' | translate }}</label>
                        <span *ngIf="getInputConfig('user_password_confirmation').required" class="text-danger">*</span>
                        <app-question class="question-hint" [question]="getInputConfig('user_password_confirmation')" [form]="userForm"></app-question>
                        <ng-container *ngTemplateOutlet="passwordVisibilityTemplate; context: { key: 'user_password_confirmation'}"></ng-container>
                        <ng-container *ngTemplateOutlet="passwordErrorTemplate; context: { key: 'user_password_confirmation'}"></ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="user_country">{{ 'resources.users.fields.country' | translate }}</label>
                      <span *ngIf="getInputConfig('user_country').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('user_country')" [form]="userForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="user_role_id">{{ 'resources.users.fields.role_id' | translate }}</label>
                      <span *ngIf="getInputConfig('user_role_id').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('user_role_id')" [form]="userForm" (optionsLoaded)="handleRoleOptsLoaded()"></app-question>
                    </div>
                  </div>
                </div>
                <div *ngIf="flags.showWalmartPOC" class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="user_partner_id">{{ 'resources.users.fields.partner_id' | translate }}</label>
                      <span *ngIf="getInputConfig('user_partner_id').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('user_partner_id')" [form]="userForm"></app-question>
                    </div>
                  </div>
                </div>
                <div *ngIf="!flags.showWalmartPOC" class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="user_supplier_ids">{{ 'resources.users.fields.supplier_ids' | translate }}</label>
                      <span *ngIf="getInputConfig('user_supplier_ids').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('user_supplier_ids')" [form]="userForm" [filters]="getInputConfig('user_supplier_ids').filters"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="hasFormKeyWithSlug('user_role_id', 'franchise') || hasFormKeyWithSlug('user_role_id', 'manager')">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="user_location_ids">{{'resources.users.fields.location_ids' | translate }}</label>
                      <span *ngIf="getInputConfig('user_location_ids').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('user_location_ids')" [form]="userForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="user_ip_white_list">{{ 'resources.users.fields.ip_white_list' | translate }}</label>
                      <span *ngIf="getInputConfig('user_ip_white_list').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('user_ip_white_list')" [form]="userForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="user_preferred_language">{{ 'resources.users.fields.preferred_language' | translate }}</label>
                      <span *ngIf="getInputConfig('user_preferred_language').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('user_preferred_language')" [form]="userForm"></app-question>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #passwordVisibilityTemplate let-key="key">
  <div class="password-hint-wrapper">
    <a (click)="handlePasswordVisibility(key)" class="password-hint">
      <span>{{ 'resources.users.hints.' + (showPasswordVisibility[key] ? 'hide_password' : 'show_password') | translate }}</span>
      <em class="fa-regular mrg-left-5" [ngClass]="showPasswordVisibility[key] ? 'fa-eye-slash' : 'fa-eye'"></em>
    </a>
  </div>
</ng-template>

<ng-template #passwordErrorTemplate let-key="key">
  <div *ngIf="getInputConfig(key)?.errorText" class="text-left text-danger">
    <small [attr.data-cy]="'text_' + (key | camelCase) + 'Error'" [innerHTML]="getInputConfig(key).errorText"></small>
  </div>
</ng-template>
