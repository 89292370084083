<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card-block no-pdd">
        <div class="mrg-top-10">
          <div class="row">
            <div [ngClass]="{'mr-auto': !flags.showRewardsPreview}" class="col-md-8 ml-auto">
              <form [formGroup]="rewardsForm" *ngIf="rewardsForm">

                <!-- DETAILS -->
                <div class="card">
                  <div class="card-heading border bottom">
                    <!-- ############################################################################################################### -->
                    <!-- ###### DEMO ####### -->
                    <div class="row" *ngIf="flags.showDemoWallmart">
                      <div class="col-md-12">
                        <button class="btn btn-default pull-right" (click)="navigateToTranslations()">
                          <span class="text-bold">{{'resources.scores.rewards.titles.configure_translations' |
                            translate}}</span>
                        </button>
                      </div>
                    </div>
                    <!-- END DEMO -->
                    <!-- ################################################################################################################ -->
                    <div class="row">
                      <div class="col-md-6">
                        <h4 class="text-bold card-title" [translate]="'resources.scores.rewards.titles.details'"></h4>
                      </div>
                    </div>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert">
                      <span class="alert-text">
                        {{'resources.scores.rewards.subtitles.details' | translate}}
                      </span>
                    </div>
                    <div class="row" *ngIf="flags.rewardsAvailableLanguagesVisibility">
                      <div class="col-md-12">
                        <div class="form-group">
                          <app-language-accordions [inputs]="inputs" [form]="rewardsForm" [mode]="mode"
                            [filteredAvailableLanguages]="filteredAvailableLanguages">
                          </app-language-accordions>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="!flags.rewardsAvailableLanguagesVisibility">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="name" class="text-bold"
                            [translate]="'resources.scores.rewards.form.name'"></label>
                          <span class="text-danger">*</span>
                          <app-question [question]="getInputConfig('name')" [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="!flags.rewardsAvailableLanguagesVisibility">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="text-bold" for="description"
                            [translate]="'resources.scores.rewards.form.description'"></label>
                          <app-question [question]="getInputConfig('description')" [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="row" *ngIf="!flags.rewardsAvailableLanguagesVisibility && flags.showDemoWallmart">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="text-bold" for="terms_and_conditions"
                            [translate]="'resources.scores.rewards.form.terms_and_conditions'"></label>
                          <app-question [question]="getInputConfig('terms_and_conditions')" [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div> -->
                    <img *ngIf="id && !imgMissing" class="border-rad-50 mrg-btm-15"
                      src="{{rewardsData._links.thumbnail.href}}" alt="thumbnail">
                    <div class="form-group" [style.border]="'none'">

                      <label class="text-bold">
                        <span>{{'resources.scores.rewards.form.image' | translate}}</span>
                        <em class="ti-info-alt pull-right mrg-top-3 mrg-left-5" tooltipPosition="right"
                          pTooltip="{{'resources.scores.common_messages.image_tooltip' | translate}}">
                        </em>
                      </label>

                      <div class="row" *ngIf="selectedFile?.size > 0">
                        <div class="col-md-3">
                          <div class="alert alert-info fade show">
                            <span class="text-bold">{{'resources.scores.rewards.form.file_name' | translate}}:</span>
                            {{selectedFile?.size / 1024 | number:'1.0-2'}}KB<br>
                            <span class="text-bold">{{'resources.scores.rewards.form.file_size' | translate}}:</span>
                            {{selectedFile?.name }}
                          </div>
                        </div>
                      </div>

                      <p-fileUpload #fileUpload
                        mode="basic"
                        [chooseLabel]="'common.select_file' | translate"
                        invalidFileSizeMessageSummary="{{'common.invalid_file_size' | translate}}. {{'common.maximum_size' | translate}}: 500KB"
                        invalidFileSizeMessageDetail=""
                        accept="image/*"
                        [maxFileSize]="MAX_FILE_SIZE_LIMIT"
                        (onSelect)="validateFileSize($event, fileUpload)">
                      </p-fileUpload>

                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="text-bold" for="category_id"
                            [translate]="'resources.scores.rewards.form.category_id'"></label>
                          <app-question [question]="getInputConfig('reward_taxonomy_terms')"
                            [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="text-bold" for="redemption_type"
                            [translate]="'resources.scores.rewards.form.redemption_type'"></label>
                          <span class="text-danger">*</span>
                          <div class="alert alert-info" role="alert">
                            <span class="alert-text">
                              {{'resources.scores.rewards.subtitles.redemption_type' | translate}}
                            </span>
                          </div>
                          <app-question [question]="getInputConfig('redemption_type')" [form]="rewardsForm"
                            (blur)="resetRelatedFieldsValue($event)">
                          </app-question>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="text-bold" for="status">
                            <span>{{'resources.scores.rewards.form.status' | translate}}</span>
                            <em class="ti-info-alt pull-right mrg-top-3 mrg-left-5" tooltipPosition="left"
                              pTooltip="{{'resources.scores.common_messages.status_tooltip' | translate}}">
                            </em>
                            <span class="text-danger">*</span>
                          </label>
                          <app-question [question]="getInputConfig('status')" [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END DETAILS -->

                <!-- TYPES -->
                <div class="card">
                  <div class="card-heading border bottom">
                    <h4 class="text-bold card-title" [translate]="'resources.scores.rewards.titles.types'"></h4>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert" *ngIf="!rewardsData?.id">
                      <span class="alert-text">
                        {{'resources.scores.rewards.subtitles.types' | translate}}
                      </span>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="type" class="text-bold"
                            [translate]="'resources.scores.rewards.form.type'"></label>
                          <span *ngIf="getInputConfig('type').required" class="text-danger">*</span>
                          <app-question [question]="getInputConfig('type')" [form]="rewardsForm"
                            (multiselectChanged)="typesSelector($event)"></app-question>
                        </div>
                      </div>
                    </div>
                    <!-- CHECKBOX -->
                    <div class="row" *ngIf="flags?.walletsSectionVisibility && hasFormKeyWithValue('type', 'coupon')">
                      <div class="col-md-12">
                        <div class="form-group">
                          <app-question [question]="getInputConfig('is_coupon')" [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="rewardsForm?.get('is_coupon').value && flags.walletsSectionVisibility">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="coupon_id" [translate]="'resources.scores.rewards.form.coupon_id'"></label>
                          <app-question [question]="getInputConfig('coupon_id')" [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="hasFormKeyWithValue('type', 'code_delivery')">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="upload_csv_external_codes"
                            [translate]="'resources.scores.rewards.form.upload_csv_external_codes'"></label>
                          <app-question [question]="getInputConfig('upload_csv_external_codes')"
                            [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END TYPES -->

                <!-- REDEMPTION VALUE -->
                <div class="card" *ngIf="rewardsForm?.value?.redemption_type === 'manual'">
                  <div class="card-heading border bottom">
                    <h4 class="text-bold card-title" [translate]="'resources.scores.rewards.titles.points'"></h4>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert">
                      <span class="alert-text">
                        {{'resources.scores.rewards.subtitles.points' | translate}}
                      </span>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="text-bold" for="points"
                            [translate]="'resources.scores.rewards.form.points'"></label>
                          <span *ngIf="getInputConfig('points').required" class="text-danger">*</span>
                          <app-question [question]="getInputConfig('points')" [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="text-bold" for="extra_money"
                            [translate]="'resources.scores.rewards.form.extra_money'"></label>
                          <app-question [question]="getInputConfig('extra_money')" [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="text-bold" for="currency"
                            [translate]="'resources.scores.rewards.form.currency'"></label>
                          <span *ngIf="fieldHasValue(rewardsForm, 'extra_money')" class="text-danger">*</span>
                          <app-question [question]="getInputConfig('currency')" [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <app-question [question]="getInputConfig('flexible_score_allowed')"
                            [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END POINTS -->

                <!-- AVAILABILITY -->
                <div class="card" *ngIf="rewardsForm?.value?.redemption_type === 'manual'">
                  <div class="card-heading border bottom">
                    <h4 class="text-bold card-title" [translate]="'resources.scores.rewards.titles.availability'"></h4>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert">
                      <span class="alert-text">
                        {{'resources.scores.rewards.subtitles.availability' | translate}}
                      </span>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="text-bold" for="tiers"
                            [translate]="'resources.scores.rewards.form.tiers'"></label>
                          <app-question [question]="getInputConfig('achievement_definition_ids')"
                            [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="text-bold" for="collectives"
                            [translate]="'resources.scores.rewards.form.collectives'"></label>
                          <app-question [question]="getInputConfig('collectives')" [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div>
                    <!-- ############## -->
                    <!--   DEMO -->
                    <!-- ############## -->
                    <div class="row" *ngIf="flags?.showDemoWallmart">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="text-bold" for="feature_location_ids"
                            [translate]="'resources.scores.rewards.form.location_taxonomies'"></label>
                          <app-question [question]="getInputConfig('feature_location_ids')"
                            [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div>
                    <!-- ############## -->
                    <!-- ############## -->
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="text-bold" for="locations"
                            [translate]="'resources.scores.rewards.form.locations'"></label>
                          <app-question [question]="getInputConfig('locations')" [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="text-bold" for="countries"
                            [translate]="'resources.scores.loyalty_scores.fields.countries'"></label>
                          <span *ngIf="getInputConfig('countries').required" class="text-danger">*</span>
                          <app-question [question]="getInputConfig('countries')" [form]="rewardsForm"
                            (multiselectChanged)="countrySelector()"></app-question>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="text-bold" for="available_from"
                            [translate]="'resources.scores.rewards.form.available_from'"></label>
                          <app-question [question]="getInputConfig('available_from')"
                            [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="text-bold" for="available_to"
                            [translate]="'resources.scores.rewards.form.available_to'"></label>
                          <app-question [question]="getInputConfig('available_to')" [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END AVAILABILITY -->

                <!-- Validity of redeemed reward -->
                <div class="card">
                  <div class="card-heading border bottom">
                    <h4 class="text-bold card-title" [translate]="'resources.scores.rewards.titles.reward_validity'">
                    </h4>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert">
                      <span class="alert-text">
                        {{'resources.scores.rewards.subtitles.validity' | translate}}
                      </span>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <app-question [question]="getInputConfig('activate_reward_validity')" [form]="rewardsForm">
                          </app-question>
                        </div>
                      </div>
                    </div>

                    <div class="row" *ngIf="rewardsForm.value.activate_reward_validity">
                      <div class="col">
                        <div class="form-group">
                          <label class="text-bold" for="validity_duration_type"
                            [translate]="'resources.scores.rewards.form.validity_conditions'"></label>
                          <app-question [question]="getInputConfig('validity_duration_type')" [form]="rewardsForm"
                            (blur)="resetValidityDurationFields()">
                          </app-question>
                        </div>
                      </div>
                    </div>

                    <!-- IF RELATIVE PERIOD SELECTED -->
                    <ng-container
                      *ngIf="(rewardsForm.value.activate_reward_validity && rewardsForm.value.validity_duration_type === 'relative')">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-bold" for="validity_duration_number">
                              {{'resources.scores.rewards.form.availability_duration' | translate}}
                              <span class="text-danger">*</span>
                            </label>
                            <app-question [question]="getInputConfig('validity_duration_number')"
                              [form]="rewardsForm"></app-question>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-bold" for="validity_duration_unit_option">
                              {{'resources.scores.rewards.form.validity_duration_unit_option' | translate}}
                              <span class="text-danger">*</span>
                            </label>
                            <app-question [question]="getInputConfig('validity_duration_unit_option')"
                              [form]="rewardsForm"></app-question>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <!--  -->

                    <!-- IF FIXED PERIOD SELECTED -->
                    <ng-container
                      *ngIf="(rewardsForm.value.activate_reward_validity && rewardsForm.value.validity_duration_type === 'fixed')">
                      <div class="row">
                        <div class="col-md-4 mrg-top-10">
                          <div class="form-group">
                            <label class="text-bold" for="validity_duration_date"
                              [translate]="'resources.scores.rewards.form.validity_duration_date'">
                            </label>
                            <span class="text-danger">*</span>
                            <app-question [question]="getInputConfig('validity_duration_date')"
                              [form]="rewardsForm"></app-question>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <!--  -->
                <!-- LIMITS -->
                <div class="card" *ngIf="rewardsForm.value.redemption_type === 'manual'">
                  <div class="card-heading border bottom">
                    <h4 class="text-bold card-title" [translate]="'resources.scores.rewards.titles.limits'"></h4>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert">
                      <span class="alert-text">
                        {{'resources.scores.rewards.subtitles.limits' | translate}}
                      </span>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="text-bold" for="redeem_chances"
                            [translate]="'resources.scores.rewards.form.redeem_chances'"></label>
                          <app-question [question]="getInputConfig('redeem_chances')"
                            [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="text-bold" for="redeem_times">&nbsp;</label>
                          <app-question [question]="getInputConfig('redeem_times')" [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END LIMITS -->
                <!-- PARTNERS -->
                <div class="card">
                  <div class="card-heading border bottom">
                    <h4 class="text-bold card-title" [translate]="'resources.scores.rewards.titles.partners'"></h4>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert">
                      <span class="alert-text">
                        {{'resources.scores.rewards.subtitles.partners' | translate}}
                      </span>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="text-bold" for="partner_id"
                            [translate]="'resources.scores.rewards.form.partner_id'"></label>
                          <app-question [question]="getInputConfig('partner_id')" [form]="rewardsForm"></app-question>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END PARTNERS -->

                <!-- ####################### -->
                <!-- ###### DEMO ########### -->
                <ng-container *ngIf="flags.showDemoWallmart">
                  <!-- CUSTOM FIELDS -->
                  <div class="card">
                    <div class="card-heading border bottom">
                      <h4 class="text-bold card-title" [translate]="'resources.scores.rewards.titles.custom_fields'">
                      </h4>
                    </div>
                    <div class="card-block">
                      <div class="alert alert-info" role="alert">
                        <span class="alert-text">
                          {{'resources.scores.rewards.subtitles.custom_fields' | translate}}
                        </span>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label class="text-bold" for="approval"
                              [translate]="'resources.scores.rewards.form.approval'"></label>
                            <app-question [question]="getInputConfig('approval')" [form]="rewardsForm"></app-question>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label class="text-bold" for="cta_type"
                              [translate]="'resources.scores.rewards.form.cta_type'"></label>
                            <app-question [question]="getInputConfig('cta_type')" [form]="rewardsForm"></app-question>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label class="text-bold" for="adobe_campaign"
                              [translate]="'resources.scores.rewards.form.adobe_campaign'"></label>
                            <app-question [question]="getInputConfig('adobe_campaign')"
                              [form]="rewardsForm"></app-question>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label class="text-bold" for="cause_id"
                              [translate]="'resources.scores.rewards.form.cause_id'"></label>
                            <app-question [question]="getInputConfig('cause_id')" [form]="rewardsForm"></app-question>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label class="text-bold" for="custom_field_5"
                              [translate]="'resources.scores.rewards.form.custom_field_5'"></label>
                            <span *ngIf="fieldHasValue(rewardsForm, 'extra_money')" class="text-danger">*</span>
                            <app-question [question]="getInputConfig('custom_field_5')"
                              [form]="rewardsForm"></app-question>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label class="text-bold" for="custom_field_6"
                              [translate]="'resources.scores.rewards.form.custom_field_6'"></label>
                            <span *ngIf="fieldHasValue(rewardsForm, 'extra_money')" class="text-danger">*</span>
                            <app-question [question]="getInputConfig('custom_field_6')"
                              [form]="rewardsForm"></app-question>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ###### EXTRA INFO ##### -->
                  <div class="card">
                    <div class="card-heading border bottom">
                      <h4 class="text-bold card-title" [translate]="'resources.scores.rewards.titles.extra_info'"></h4>
                    </div>
                    <div class="card-block">
                      <div class="alert alert-info" role="alert">
                        <span class="alert-text">
                          {{'resources.scores.rewards.subtitles.extra_info' | translate}}
                        </span>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="text-bold" for="priority"
                              [translate]="'resources.scores.rewards.form.priority'"></label>
                            <app-question [question]="getInputConfig('priority')" [form]="rewardsForm"></app-question>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label class="text-bold" for="publish_from"
                              [translate]="'resources.scores.rewards.form.publish_from'"></label>
                            <app-question [question]="getInputConfig('publish_from')"
                              [form]="rewardsForm"></app-question>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label class="text-bold" for="publish_to"
                              [translate]="'resources.scores.rewards.form.publish_to'"></label>
                            <app-question [question]="getInputConfig('publish_to')" [form]="rewardsForm"></app-question>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label class="text-bold" for="terms_and_conditions"
                              [translate]="'resources.scores.rewards.form.terms_and_conditions'"></label>
                            <span *ngIf="fieldHasValue(rewardsForm, 'extra_money')" class="text-danger">*</span>
                            <app-question [question]="getInputConfig('terms_and_conditions')"
                              [form]="rewardsForm"></app-question>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- ###### DEMO END ####### -->
                <!-- ############################################################################################### -->
                <app-stock-management [inputs]="inputs" [rewardsForm]="rewardsForm"></app-stock-management>
              </form>
            </div>

            <div *ngIf="flags.showRewardsPreview" class="col-md-2 mr-auto">
              <div class="card sticky-div">
                <div class="card-heading border bottom">
                  <h4 class="text-bold card-title">
                    {{'common.preview' | translate}}
                  </h4>
                </div>
                <app-resource-preview
                  *ngIf="rewardsForm"
                  [formValues]="rewardsForm.value"
                  [imageFile]="imageFile"
                  [thumbnail]="rewardsData?._links?.thumbnail_medium?.href"
                  [type]="'reward'"
                  [availabilityType]="'range'"></app-resource-preview>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
