<div class="modal fade modal-fs show" id="modal-fs" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header background-white">
        <div class="row flex-grow-1">
          <div class="col-md-8 ml-auto mr-auto">
            <h1 *ngIf="!id" class="mrg-left-15">{{ 'resources.cms.form.fields.new_content' | translate }}</h1>
            <h1 *ngIf="id" class="mrg-left-15">{{ 'resources.cms.form.fields.edit_content' | translate }}</h1>
          </div>
        </div>

        <div class="pdd-vertical-20-but-bottom">
          <button class="modal-close" (click)="closeModal()">
            <i class="ti-close"></i>
          </button>
        </div>
      </div>

      <div class="modal-body height-100 background-gray">
        <div class="container-fluid clearfix">

          <div class="row">
            <div class="col-md-8 offset-md-2">
              <div class="card">
                <div class="card-block">
                  <app-loader noWrapper *ngIf="loading" />
                  <form class="form-horizontal" *ngIf="cmsForm">

                    <div class="row">
                      <div class="col-md-12">
                        <h4 class="card-title" [translate]="'resources.cms.form.fields.details'"></h4>
                        <hr>
                        <img *ngIf="id && !isMissingImg" class="width-49" src="{{cmsData?.thumbnail}}" alt="image">
                        <img *ngIf="id && !cmsData?.thumbnail || isMissingImg" class="mrg-top-30" src="assets/images/avatars/no_image.png" alt="image">
                        <div class="row flex-column">
                          <div class="col-md-6">
                            <label class="mrg-top-20" for="image" [translate]="'resources.cms.form.fields.image'"></label>
                            <app-question [question]="getInputConfig('image')" (change)="onFileChange($event)" [form]="cmsForm"></app-question>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <label for="publish_at" [translate]="'resources.cms.form.fields.publish_at'"></label>
                        <app-question [question]="getInputConfig('publish_at')" [form]="cmsForm" format-date></app-question>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <label for="available_to" [translate]="'resources.cms.form.fields.available_to'"></label>
                        <app-question [question]="getInputConfig('available_to')" [form]="cmsForm"></app-question>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <label for="news_taxonomy_terms" [translate]="'resources.cms.form.fields.news_taxonomy_terms'"></label>
                        <app-question [question]="getInputConfig('news_taxonomy_terms')" [form]="cmsForm"></app-question>
                      </div>
                    </div>

                  </form>
                </div>
              </div>

              <div class="row" id="newsTitle">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-block">
                      <div class="row">
                        <div class="col-md-12">
                          <span class="font-size-16 card-title mrg-btm-10" [translate]="'resources.cms.form.fields.title'"></span>
                          <p-editor [(ngModel)]="cmsData.title" [style]="{ height: '320px' }"></p-editor>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" id="newsBody">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-block">
                      <div class="row mrg-top-20">
                        <div class="col-md-12">
                          <span class="font-size-16 card-title" [translate]="'resources.cms.form.fields.body'"></span>
                          <p-editor [(ngModel)]="cmsData.body" [style]="{ height: '320px' }"></p-editor>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="row modal-footer background-white">
        <div class="col-md-8 ml-auto mr-auto text-right">
          <span>
            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          </span>
					<button class="btn btn-info mrg-right-15" (click)="sendData()" [translate]="'resources.cms.buttons.save'"></button>
        </div>
      </div>

    </div>
  </div>
</div>
