<div class="card">
  <div class="card-heading border bottom">
    <h4 class="text-bold card-title" [translate]="'resources.scores.loyalty_scores.fields.limits'"></h4>
  </div>
  <div class="card-block">
    <div class="alert alert-info" role="alert">
      <span class="alert-text">{{'resources.scores.loyalty_scores.descriptions.limits' | translate}}</span>
    </div>
    <!-- CHANCES TO WIN -->
    <div class="row">
      <label for="chancesToWin" class="text-bold mrg-left-15"
        [translate]="'resources.scores.loyalty_scores.fields.chances_to_win'"></label>
    </div>
    <div class="row flex-end">
      <div class="col-md-4">
        <div class="form-group">
          <app-question [question]="getInputConfig('once')" [form]="loyaltyScoresForm"
                        (multiselectChanged)="chancesToWinSelectorChange($event)"
                        [disabled]="isUsed"/>
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <app-question [question]="getInputConfig('once_limit')" [form]="loyaltyScoresForm"
                        [disabled]="isUsed"/>
        </div>
      </div>
    </div>


    <!-- ################################################################## -->
    <!-- #################### DEMO WALLMART - MAX POINTS ########### -->
    <!-- ################################################################## -->
     <ng-container *ngIf="flags.showDemoWallmart">
      <!-- PER POLICY -->
      <div class="row">
        <label for="max_points_per_loyalty_rule" class="text-bold mrg-left-15"
          [translate]="'resources.scores.loyalty_scores.fields.max_points'">
        </label>
        <em class="ti-info-alt pull-right mrg-top-3 mrg-left-5"
            tooltipPosition="top"
            pTooltip="{{'resources.scores.loyalty_scores.form.tooltip.max_points_per_loyalty_rule' | translate}}">
        </em>
      </div>

      <div class="row flex-end">
        <div class="col-md-4">
          <div class="form-group">
            <app-question [question]="getInputConfig('budget_period')"
                          [form]="loyaltyScoresForm"/>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <app-question [question]="getInputConfig('budget_value')" [form]="loyaltyScoresForm"/>
          </div>
        </div>
      </div>

      <!-- PER TRANSACTION -->
      <div class="row">
        <label for="maximum_points_transaction" class="text-bold mrg-left-15"
               [translate]="'resources.scores.loyalty_scores.fields.max_points_per_transaction'">
        </label>
        <em class="ti-info-alt pull-right mrg-top-3 mrg-left-5"
            tooltipPosition="top"
            pTooltip="{{'resources.scores.loyalty_scores.form.tooltip.max_points_per_transaction' | translate}}">
        </em>
      </div>

      <div class="row flex-end">
        <div class="col-md-4">
          <div class="form-group">
            <app-question [question]="getInputConfig('maximum_points_transaction')" [form]="loyaltyScoresForm"/>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- ################################################################## -->

  </div>
</div>
