import { AbstractControl, ValidationErrors, Validators } from "@angular/forms";
import { CustomEntityAttributeType } from "../../enums/custom-entity-attribute-type.enums";
import { INTEGER_REGEX } from "../../../../constants/condition.constants";
import { checkValue2GreaterValue1Validation, setRequiredControlsValidators, setValidatorsErrorsMark } from "../utils/common.validations.utils";
import { isNullOrUndefined } from '../../../../../../utils/common.utils';
import { checkIntegerValuesBtOperatorValidation, getIsValueInteger } from "../utils/integer-validations.utils";
import { DATE_RANGE_REQUIRED, INTEGER_VALUE_REQUIRED, OPERATOR_REQUIRED, VALUE_REQUIRED } from "../constants/controls-validators.constants";

export function handleAttributeControlsValidators(
  operatorCtrl: AbstractControl,
  valueCtrl: AbstractControl,
  value2Ctrl?: AbstractControl,
  attributeTypeCtrl?: AbstractControl
): ValidationErrors | null {
  valueCtrl?.setValidators([]);
  value2Ctrl?.setValidators([]);
  const attributeTypeValue = attributeTypeCtrl ? attributeTypeCtrl.value : null;
  const value = valueCtrl?.value ?? null;
  const value2 = value2Ctrl?.value ?? null;

  if (operatorCtrl?.value?.length) {
    if (operatorCtrl.value[0].id === 'bt') {
      if (attributeTypeValue && (attributeTypeValue === CustomEntityAttributeType.Datetime || attributeTypeValue === CustomEntityAttributeType.Date)) {
        checkAttributeDateBtOperatorValidation(valueCtrl, value2Ctrl, value, value2);
      } else if (attributeTypeValue && attributeTypeValue === CustomEntityAttributeType.Integer) {
        checkAttributeIntegerBtOperatorValidation(valueCtrl, value2Ctrl, value, value2);
      } else {
        valueCtrl.setErrors(null);
        value2Ctrl.setErrors(null);
        checkValue2GreaterValue1Validation(valueCtrl, value2Ctrl, value, value2);
      }
    } else if (['eq', 'in'].includes(operatorCtrl.value[0].id)) {
      if (isNoValue(value) && isNoValue(value2)) {
        return {[VALUE_REQUIRED]: true};
      }
      return null;
    } else if (isNoValue(value)) {
      setRequiredControlsValidators(valueCtrl);
      return {[VALUE_REQUIRED]: true};
    } else {
      valueCtrl.setErrors(null);
      checkValueIntegerValidation(attributeTypeValue, valueCtrl, value);
    }
  }

  if (isNoValue(value)) {
    setRequiredControlsValidators(valueCtrl);
    return {[VALUE_REQUIRED]: true};
  }

  checkValueIntegerValidation(attributeTypeValue, valueCtrl, value);
  checkOperatorValidation(operatorCtrl);
  return null;
}

function isNoValue(value: unknown): boolean {
  return !value || (value instanceof Array && !value?.length) || (value instanceof Object && !Object.keys(value)?.length);
}

function checkValueIntegerValidation(attributeTypeValue: string, valueCtrl: AbstractControl, value: string | number): ValidationErrors | null {
  if (attributeTypeValue && attributeTypeValue === CustomEntityAttributeType.Integer) {
    const isValueInteger = !!((value || value === 0) && INTEGER_REGEX.exec(value.toString()));
    if (!isValueInteger) {
      setValidatorsErrorsMark(valueCtrl, [Validators.required, Validators.pattern(INTEGER_REGEX)], INTEGER_VALUE_REQUIRED);
      return {[INTEGER_VALUE_REQUIRED]: true};
    }
  }
}

function checkOperatorValidation(operatorCtrl: AbstractControl): ValidationErrors | null {
  if (!operatorCtrl.value || (operatorCtrl?.value instanceof Array && !operatorCtrl?.value?.length)) {
    setRequiredControlsValidators(operatorCtrl);
    return {[OPERATOR_REQUIRED]: true};
  }
}

function checkAttributeDateBtOperatorValidation(valueCtrl: AbstractControl, value2Ctrl: AbstractControl, value: unknown, value2: unknown): ValidationErrors | null {
  const isNoValue = isNoValueBtOperator(value);
  const isNoValue2 = isNoValueBtOperator(value2);
  if (isNoValue && isNoValue2) {
    setRequiredControlsValidators(valueCtrl);
    setRequiredControlsValidators(value2Ctrl);
    return {[DATE_RANGE_REQUIRED]: true};
  } else if (isNoValue && !isNoValue2) {
    setRequiredControlsValidators(valueCtrl);
    return {[VALUE_REQUIRED]: true};
  } else if (!isNoValue && isNoValue2) {
    setRequiredControlsValidators(value2Ctrl);
    return {[VALUE_REQUIRED]: true};
  }
}

function isNoValueBtOperator(value: unknown): boolean {
  return !!(!value || (value instanceof Object && !Object.keys(value)?.length));
}

function checkAttributeIntegerBtOperatorValidation(valueCtrl: AbstractControl, value2Ctrl: AbstractControl, value: string | number, value2: string | number): ValidationErrors | null {
  const isValueInteger = getIsValueInteger(value);
  const isValue2Integer = getIsValueInteger(value2);
  if ((isNullOrUndefined(value) || (!value && value !== 0))  && (isNullOrUndefined(value2) || (!value2 && value2 !== 0))) {
    setValidatorsErrorsMark(valueCtrl, [Validators.required, Validators.pattern(INTEGER_REGEX)], INTEGER_VALUE_REQUIRED);
    setValidatorsErrorsMark(value2Ctrl, [Validators.required, Validators.pattern(INTEGER_REGEX)], INTEGER_VALUE_REQUIRED);
    return {[INTEGER_VALUE_REQUIRED]: true};
  } else if ((isNullOrUndefined(value) || (!value && value !== 0)) && value2 && isValue2Integer) {
    setValidatorsErrorsMark(valueCtrl, [Validators.required, Validators.pattern(INTEGER_REGEX)], INTEGER_VALUE_REQUIRED);
    return {[INTEGER_VALUE_REQUIRED]: true};
  } else if (value && isValueInteger && (isNullOrUndefined(value2) || (!value2 && value2 !== 0))) {
    setValidatorsErrorsMark(value2Ctrl, [Validators.required, Validators.pattern(INTEGER_REGEX)], INTEGER_VALUE_REQUIRED);
    return {[INTEGER_VALUE_REQUIRED]: true};
  } else {
    valueCtrl.setErrors(null);
    value2Ctrl.setErrors(null);
    checkIntegerValuesBtOperatorValidation(valueCtrl, value2Ctrl, value, value2, INTEGER_REGEX, INTEGER_VALUE_REQUIRED);
    checkValue2GreaterValue1Validation(valueCtrl, value2Ctrl, value, value2);
  }
}