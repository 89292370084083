import { environment } from '../../../../environments/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Injectable } from '@angular/core';
import { ProfileService } from '../../../profiles/profile.service';

export enum GOOGLE_ANALYTICS_TAG_MANAGER_EVENT {
  EnableGoogleTagManager,
  Login,
  Logout,
  UserData
}

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsTagManagerService {

  constructor(
    private readonly gtmService: GoogleTagManagerService,
    private readonly profileService: ProfileService
  ) {}

  setGoogleAnalyticsTagManager(event: GOOGLE_ANALYTICS_TAG_MANAGER_EVENT, userData?: unknown): void {
    if (environment?.googleTagManagerID && environment?.production) {
      switch (event) {
        case GOOGLE_ANALYTICS_TAG_MANAGER_EVENT.EnableGoogleTagManager:
          this.gtmService.addGtmToDom();
          break;
        case GOOGLE_ANALYTICS_TAG_MANAGER_EVENT.Login:
          this.loginGATM(userData);
          break;
        case GOOGLE_ANALYTICS_TAG_MANAGER_EVENT.Logout:
          this.logoutGATM();
          break;
        case GOOGLE_ANALYTICS_TAG_MANAGER_EVENT.UserData:
          this.userDataGATM();
          break;
        default:
          break;
      }
    }
  }

  private loginGATM(userData: unknown): void {
    const loginGTMData = {
      event: 'login',
      userId: userData?.['id'] ?? null,
      companyId: userData?.['company']?.id ?? null
    }
    this.gtmService?.pushTag(loginGTMData).catch(error => console.error(error));
  }

  private logoutGATM(): void {
    const logoutGTMData = {
      event: 'logout',
      userId: null,
      companyId: null
    }
    this.gtmService?.pushTag(logoutGTMData).catch(error => console.error(error));
  }

  private userDataGATM(): void {
    const userData = this.profileService.getStoredUser();
    const userDataGTMData = {
      event: 'user_data',
      userId: userData?.id ?? null,
      companyId: userData?.company?.id ?? null
    }
    this.gtmService?.pushTag(userDataGTMData).catch(error => console.error(error));
  }
}