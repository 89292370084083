import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../../shared/services/question-control.service';
import { ProductConditionsAttributes } from '../../product-conditions-model/product-conditions-attributes.interface';

@Component({
  selector: 'app-product-condition-attributes',
  templateUrl: './product-condition-attributes.component.html',
  styleUrls: ['./product-condition-attributes.component.scss']
})
export class ProductConditionAttributesComponent {

  @Input('loyaltyScoresForm') loyaltyScoresForm: UntypedFormGroup;
  @Input('inputs') inputs;
  @Input('flags') flags;

  productConditionsAttributes: ProductConditionsAttributes[] = [];

  constructor(
    private qcs: QuestionControlService
  ) { }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  addProductConditionLine() {
    const productName = this.loyaltyScoresForm.get('product_ids').value;
    const productCondition = this.loyaltyScoresForm.get('product_condition').value;
    const productTotalOption = this.loyaltyScoresForm.get('product_total_options').value;
    const productTotalValue = this.loyaltyScoresForm.get('product_total_value').value;
    const productQuantityOption = this.loyaltyScoresForm.get('product_quantity_options').value;
    const productQuantityValue = this.loyaltyScoresForm.get('product_quantity_value').value;

    if (productCondition && productTotalOption && productTotalValue && productQuantityOption && productQuantityValue ) {
      this.productConditionsAttributes.push({
        product_name: productName[0].name,
        product_condition: productCondition[0].id.charAt(0).toUpperCase() + productCondition[0].id.slice(1),
        product_total_option: productTotalOption[0].name,
        product_total_value: productTotalValue,
        product_quantity_option: productQuantityOption[0].name,
        product_quantity_value: productQuantityValue
      });
    }

    this.loyaltyScoresForm.get('product_ids').reset();
    this.loyaltyScoresForm.get('product_condition').reset();
    this.loyaltyScoresForm.get('product_total_options').reset();
    this.loyaltyScoresForm.get('product_total_value').reset();
    this.loyaltyScoresForm.get('product_quantity_options').reset();
    this.loyaltyScoresForm.get('product_quantity_value').reset();
  }

  deleteProductContidionLine(index) {
    if (this.productConditionsAttributes[index]['id']) {
      this.productConditionsAttributes[index]['_destroy'] = true;
    } else {
      this.productConditionsAttributes.splice(index, 1);
    }
  }

}
