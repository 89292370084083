import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn } from '@angular/forms';
import { QuestionBase } from '../models/forms/question-base';
import { TextboxQuestion } from '../models/forms/question-textbox';

@Injectable()
export class QuestionControlService {
  constructor() { }

  toFormGroup(questions: QuestionBase<any>[] ): UntypedFormGroup {
    const group: any = {};
    if (questions) {
      questions.forEach(question => {
        const validators = this.getValidators(question);
        group[question.key] = new UntypedFormControl(question.value, validators);
      });
    }
    return new UntypedFormGroup(group);
  }

  addToFormGroup(form: UntypedFormGroup, question: QuestionBase<any>): UntypedFormGroup {
    const newFormControl = question.required ? new UntypedFormControl(question.value || '', Validators.required)
                                             : new UntypedFormControl(question.value || '');
    form.addControl(question.key,  newFormControl);
    return form;
  }

  removeFromFormGroup(form: UntypedFormGroup, questionKey: string): UntypedFormGroup {
    form.removeControl(questionKey);
    return form;
  }

  getInputCfgByKey(inputs: QuestionBase<any>[], inputKey: string): QuestionBase<any> {
    return inputs.find( input => input.key === inputKey );
  }

  hasFormKeyWithValue(form: UntypedFormGroup, formKey: string, value: any): boolean {
    if (form.get(formKey).value &&
        form.get(formKey).value.length &&
        form.get(formKey).value[0].hasOwnProperty('id')) {
      return form.get(formKey).value[0].id === value;
    } else {
      return form.get(formKey).value === value;
    }
  }

  hasFormKey(form: UntypedFormGroup, formKey: string): boolean {
    return !!(form.get(formKey).value && form.get(formKey).value.length);
  }


  paintErrorsInForm(inputs: QuestionBase<any>[], form: UntypedFormGroup, errorsArray: {field: string}[]) {
    const errorsWithoutField = [];
    errorsArray.forEach((apiError) => {
      const controlKey = apiError.field;
      if (form.get(controlKey)) {
        form.get(controlKey).markAsDirty();
        form.get(controlKey).setErrors({'notPresent': true});
        const input = this.getInputCfgByKey(inputs, controlKey);
        input.hasErrors = true;
        if ( apiError['message'] ) { input.errorText = apiError['message']; }
      } else {
        console.warn(`Unable to set error in form. Field: ${apiError.field}`);
        errorsWithoutField.push(apiError);
      }
    });
    if ( errorsWithoutField.length ) { form.setErrors(errorsWithoutField); }
  }

  public getArrayWithNormalizedKeys(rawArray, strings?: string[]) {

    strings = ['profiles.', 'profiles[0][', ']'];
    rawArray.forEach( el => {
      strings.forEach( string => {
        if (el.field.indexOf(string) >= 0) {
          el.field = el.field.replace(string, '');
        }
      });
    });

    return rawArray;
  }

  private getValidators(question: QuestionBase<any>) {
    const validators = [];
    /* Add required validation if input is required in order to disable given submit button on form.invalid */
    if ( question.required ) { validators.push(Validators.required); }
    /* For numeric inputs, add min validation in case it is declared */
    if ( question.min ) { validators.push(Validators.min(question.min)); }
    /* For numeric inputs, add max value validation in case it is declared */
    if ( question.max ) { validators.push(Validators.max(question.max)); }
    /* For numeric inputs, ensure integer in case step is declared as 1 (only positive integers) */
    if ( question.type === 'number' && question.step === 1 && question instanceof TextboxQuestion ) {
      validators.push( Validators.pattern(/^\d+$/) );
    }
    /* For form controls with custom validators */
    if ( question.customValidators && question.customValidators.length > 0) {
      question.customValidators.forEach((validator: ValidatorFn) => validators.push(validator));
    }
    return validators;
  }
}
