import { BehaviorSubject, Observable, of } from 'rxjs';
import { FeaturesService } from '../products-features/features.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MultiselectDataSource, MultiselectDataSourceable } from '../../../../../shared/components/multiselect/multiselect';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import { TextboxQuestion } from '../../../../../shared/models/forms/question-textbox';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ProductsService extends ResourcesService implements MultiselectDataSourceable {

  displayCode = false;
  featurePks: string;
  fetchByBatch: boolean;
  inputs: QuestionBase<unknown>[];
  optsSource = new BehaviorSubject<string>(null);
  optsSource$ = this.optsSource.asObservable();
  preventUnfilteredRequests: boolean;
  searchBy: string;
  selectedOptsSource = new BehaviorSubject<{selectedIds: string[], key: string}>(null);
  selectedOptsSource$ = this.selectedOptsSource.asObservable();

  constructor(
    http: HttpClient,
    private readonly featuresService: FeaturesService,
    private readonly translate: TranslateService
  ) {
    super(http);
    this.preventUnfilteredRequests = false;
    this.fetchByBatch = false;
  }

  setFeatures(featurePks) {
    this.featurePks = featurePks;
    this.optsSource.next(featurePks);
  }

  getStatusList(): Object[] {
    return [
      {id: null, name: this.translate.instant('common.all')},
      {id: 'active', name: this.translate.instant('resources.products.states.active')},
      {id: 'inactive', name: this.translate.instant('resources.products.states.inactive')}
    ];
  }

  getAll() {
    const requestOptions = {
      apiEndPoint: '/products',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

  fetchSelectedById(id: number) {
    return this.getProductsById(id);
  }

  fetchSelectedByBatch(filters?: object): Observable<any> {
    let requestOptions = {
      apiEndPoint: 'products',
      useToken: true,
      filtering: {},
      sorting: {},
      numberPerPage: 300
    };

    filters['ids'] = filters['ids'].join(',');
    if (this.featurePks) { filters['feature_ids'] = this.featurePks; }
    const filterObj = {filtering: filters};
    requestOptions = {...requestOptions, ...filterObj};

    return this.getData( requestOptions );
  }

  getProductsById(id: number) {
    return this.getData({ apiEndPoint: `products/${id}` });
  }

  fetchMultiselect( searchValues?: string, page?: number, filters?: object ) {

    if ( this.preventUnfilteredRequests && !this.featurePks ) { return of({}); }

    let requestOptions = {
      apiEndPoint: 'products',
      useToken: true,
      nextToken: 0,
      filtering: {},
      sorting: {},
    };

    requestOptions.nextToken = page;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterKey = this.searchBy || 'name';
      const filterObj = this.searchBy
                        ? { filtering: { [filterKey]: searchValues, filter_logic: 'or' } }
                        : { filtering: { name: searchValues, id: searchValues, filter_logic: 'or' } };
      requestOptions = { ...requestOptions, ...filterObj };
    }

    if ( this.featurePks ) {
      requestOptions.filtering['feature_ids'] = this.featurePks;
    }

    return this.getData( requestOptions );
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    const displayName = this.displayCode
                        ? `(${element.id} - ${element.code}) ${element.name}`
                        : `(${element.id}) ${element.name}`;
    return new MultiselectDataSource(element.id, displayName);
  }

  statusList() {
    return [
      { id: 'active', name: this.translate.instant('resources.products.fields.active')},
      { id: 'inactive', name: this.translate.instant('resources.products.fields.inactive')}
    ];
  }

  getInputs(formValues: any): QuestionBase<any>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'id',
        type: 'number',
        cssClasses: 'form-control input-default',
        order: 1
      }),
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        order: 2
      }),
      new TextboxQuestion({
        key: 'code',
        type: 'text',
        cssClasses: 'form-control input-default',
        order: 3
      }),
      new MultiSelectQuestion({
        key: 'status',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false},
        options: this.statusList(),
        order: 4,
        required: true
      }),
      new TextboxQuestion({
        key: 'description',
        type: 'text',
        cssClasses: 'form-control input-default',
        order: 5
      }),
      new TextboxQuestion({
        key: 'price',
        type: 'number',
        cssClasses: 'form-control input-default',
        order: 6
      }),
      new MultiSelectQuestion({
        key: 'features',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.featuresService,
        order: 7
      })
    ];

    Object.keys(formValues).forEach(
      key => {
        if (key === 'id') {
          inputsArray.find( input => input.key === 'id' ).value = formValues[key];
        } else if (key === 'name') {
          inputsArray.find( input => input.key === 'name' ).value = formValues[key];
        } else if (key === 'code') {
          inputsArray.find( input => input.key === 'code' ).value = formValues[key];
        } else if (key === 'status') {
          inputsArray.find( input => input.key === 'status' ).value = [
            this.statusList().find( item => item.id === formValues[key])
          ];
        } else if (key === 'description') {
          inputsArray.find( input => input.key === 'description' ).value = formValues[key];
        } else if (key === 'price') {
          inputsArray.find( input => input.key === 'price' ).value = formValues[key];
        } else if (key === 'features') {
          inputsArray.find( input => input.key === 'features' ).selectedIds = formValues[key].map(item => item.id) ;
        }
      }
    );

    this.inputs = inputsArray;
    return this.inputs;
  }

  sendData(payload: object) {
    return this.postResource(payload, 'products');
  }

  updateData(payload: object, id: number) {
    return this.patchResource(payload, `products/${id}`);
  }

  refreshSelectedOptsSource(selectedIds: string[], key: string) {
    this.selectedOptsSource.next({selectedIds, key});
  }
}
