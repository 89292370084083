<div class="modal fade modal-fs show" id="modal-fs" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="inputs">
      <div class="row">
        <div class="col-md-12">
          <div class="modal-header">
            <div class="col-md-12">
              <h1 class="mrg-btm-10 no-pdd" [translate]="modeTitle"></h1>
            </div>
            <div class="pdd-vertical-20-but-bottom">
              <button class="modal-close mrg-right-25" (click)="closeModal()">
                <i class="ti-close"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row mrg-top-10 pdd-horizon-35">
        <div class="col-md-4">
          <app-question [question]="getInputConfig('name')" [form]="form"></app-question>
        </div>
        <div class="col-md-5">
          <app-question [question]="getInputConfig('description')" [form]="form"></app-question>
        </div>
        <div class="col-md-3">
          <app-question [question]="getInputConfig('segment_category_id')" [form]="form" [filters]="{with_privileges: true}"></app-question>
        </div>
      </div>

      <div class="modal-body back-color">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="layout-padding">
                <app-question *ngIf="!hideCSVField" [question]="getInputConfig('create_from_csv_content')" [form]="form"></app-question>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="col ml-auto mr-auto text-right">
          <span *ngIf="loadingData">
            <em class="fa fa-spinner fa-spin"></em>
          </span>
          <button type="button"
            class="btn btn-info text-left"
            (click)="save()"
            [disabled]="!ableToSaveSegment() || savingData || loadingData">
            <span *ngIf="savingData">
              <em class="fa fa-spinner fa-spin"></em>
            </span>
            {{ 'resources.segments.buttons.save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
