import { ActivatedRoute } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { getTranslationInputs } from '../../../../data-warehouse/data-warehouse-coupons/form-coupons/coupon-utils/coupon-utils';
import { GoogleTranslatorService } from '../../../../../shared/services/google_translator.service';
import { LanguageAccordionsComponent } from '../../../../../shared/components/language-accordions/language-accordions.component';
import { LanguagesService } from '../../../../../shared/services/languages.service';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { SharedModule } from "../../../../../shared/shared.module";
import { UntypedFormGroup } from '@angular/forms';
import { UploadCsvDemoComponent } from '../modal-upload-csv/upload-csv-demo/upload-csv-demo.component';

@Component({
    selector: 'app-configure-translations',
    templateUrl: './configure-translations.component.html',
    styleUrls: ['./configure-translations.component.scss'],
    standalone: true,
    imports: [ SharedModule, UploadCsvDemoComponent ]
})
export class ConfigureTranslationsComponent implements OnInit {

  myobject: any;
  data: any;
  demo: boolean

  mode = 'update'
  inputs: QuestionBase<any>[];
  form: UntypedFormGroup;
  filteredAvailableLanguages: any;

  @ViewChild('languageAccordionsComponent') languageAccordionsComponent: LanguageAccordionsComponent;
  @ViewChild('uploadModalComponent') uploadModalComponent: UploadCsvDemoComponent;
  @ViewChild('uploadModal') uploadModal: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private qcs: QuestionControlService,
    private languagesService: LanguagesService,
    private googleTranslatorService: GoogleTranslatorService
  ) { }

  ngOnInit() {
    this.getInputs();
    this.form = this.qcs.toFormGroup(this.inputs);

    this.route.queryParams.subscribe(params => {
      const name = params['name'];
      const description = params['description'];
      const terms = params['termsAndConditions'];
      this.myobject = { name, description, terms };
    });

    this.data = {
      available_languages: ['es', 'en', 'it', 'ca', 'de', 'fr', 'pt', 'ro', 'hr', 'hu', 'el'],
    }
    this.inputs.find(input => input.key === 'available_languages').value = this.languagesService.getLocalizedLanguages();
    this.form.get('available_languages').patchValue(this.languagesService.getLocalizedLanguages());
    getTranslationInputs(this.data, this.qcs, this.form, this.inputs, 'update');
    this.filteredAvailableLanguages = this.data.available_languages;
  }

  openModal() {
    this.uploadModalComponent.open();
  }

  getTranslations() {

    const originalName = this.myobject?.name;
    const originalDescription = this.myobject?.description;
    const originalTerms = this.myobject?.terms;
    const targetLanguages = ['es', 'en', 'it', 'ca', 'de', 'fr', 'pt', 'ro', 'hr', 'hu', 'el'];

    forkJoin([
        this.googleTranslatorService.translateToMultipleLanguages(originalName, targetLanguages),
        this.googleTranslatorService.translateToMultipleLanguages(originalDescription, targetLanguages),
        this.googleTranslatorService.translateToMultipleLanguages(originalTerms, targetLanguages),
    ]).subscribe(([nameTranslations, descriptionTranslations, termsTranslations]) => {
        const formattedNameTranslations = {};
        const formattedDescriptionTranslations = {};
        const formattedTermsTranslations = {};

        nameTranslations.forEach((translation, index) => {
            const langCode = targetLanguages[index];
            const nameKey = `name_${langCode}`;
            formattedNameTranslations[nameKey] = translation;
        });

        descriptionTranslations.forEach((translation, index) => {
            const langCode = targetLanguages[index];
            const descriptionKey = `description_${langCode}`;
            formattedDescriptionTranslations[descriptionKey] = translation;
        });

        termsTranslations.forEach((translation, index) => {
            const langCode = targetLanguages[index];
            const termsKey = `terms_and_conditions_${langCode}`;
            formattedTermsTranslations[termsKey] = translation;
        });

        const translatedData = {
            ...formattedNameTranslations,
            ...formattedDescriptionTranslations,
            ...formattedTermsTranslations
        };

        this.form.patchValue(translatedData);
        getTranslationInputs(translatedData, this.qcs, this.form, this.inputs, 'update');
    });
  }

  private getInputs() {
    this.inputs = [
      new MultiSelectQuestion({
        key: 'available_languages',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: false },
        options: this.languagesService.getLocalizedLanguages(),
      })
    ]
    return this.inputs;
  }

}
