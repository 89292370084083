import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()

export class ReservedIdsService extends ResourcesService {

  constructor(http: HttpClient) {
    super(http);
  }

  loadCSV(payload: object): Observable<object> {
    return this.postResource(payload, 'reserved_ids');
  }
}
