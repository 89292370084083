import { MODULE_ICONS } from "../../shared/constants/module-icons.constants";

export class NavItem {
    id: string;
    /**
     * The key to be translated in the template. Used if translation key is diferent from id
     *
     * If the item is a child, the key is prefixed with the parent's title and '.sections'.
     * If the item is first level or has children, the key is suffixed with '.title'.
     * It replaces '-' with '_'.
     *
     * @example
     * // 1. Used if translation key is diferent from id.
     * id: 'retail-group-analytics',
     * title: 'analytics.retail_group',
     *
     * // 2. Used with / preffixed to skip parent title.
     * { id: 'audiences', title: '/offers_personalization.sections.audiences' },
     */
    title?: string;
    /**
     * The route to navigate to.
     *
     * If the item is a child, the route is prefixed with the parent's route.
     * It replaces '.' with '/'.
     *
     * 1. Used if route is different from given id.
     * 2. Used with / preffixed to skip parent route.
     * @example
     * // 1. CONFIG
     * { id: 'company' } // route: '/company'
     * { id: 'company', route: 'company/details' }
     *
     * // 2. OMNICHANNEL > TEMPLATES
     * { id: 'credits' } // route: '/omnichannel-manager/templates/credits'
     * { id: 'credits', route: '/omnichannel-manager/credits' }
     */
    route?: string;
    /**
     * Displays a \<hr> separator between groups of items.
     */
    groupSeparator?: boolean;
    isExternal?: boolean;
    isMarked?: boolean;
    icon?: string;
    markedIcon?: string;
    children?: NavItem[];
    // Permissions
    isPurchased?: boolean;
    isAuthorized?: boolean;
    isNotAuthorized?: boolean;
    isUserPartnerNotAuthorized?: boolean;

    constructor(item: NavItem, parent?: NavItem) {
        const id = item.id;
        const title = (item.title ?? id).replace(/-/g, '_');
        const route = item.route ?? id;

        if (parent) {
            this.id = `${parent.id}.${id}`;
            this.title = title.startsWith('/') ? title.slice(1) : `${parent.title}.sections.${title}`;
            this.route = route?.startsWith('/') ? route : `/${parent.route}/${route}`.replace(/\./g, '/');
        }
        else {
            this.id = id;
            this.title = title;
            this.route = `/${route}`;
        }

        this.isExternal = item.isExternal || false;
        this.isMarked = item.isMarked || false;
        this.icon = item.icon ?? MODULE_ICONS[this.id];
        this.markedIcon = item.markedIcon || null;
        this.groupSeparator = item.groupSeparator || false;
        this.isPurchased = item.isPurchased;

        if (item.children?.length) {
            this.children = item.children.map(child => new NavItem(child, this));
        }
        if (!parent || this.children) {
            this.title += '.title';
        }
    }
}
