<div class="modal-backdrop fade in modal-fs"
     id='modal-fs'
     tabindex="-1"
     role="dialog"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body height-100 background-gray">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-heading border bottom" *ngIf="mode === 'create'">
                <h1 class="card-title">
                  {{'resources.plans.buttons.new_plan' | translate}}
                </h1>
              </div>
              <div class="card-block no-pdd no-mrg pdd-horizon-15 pdd-top-15">
                <ng-container *ngIf="mode !== 'create' && plan">
                  <div>
                    <h2 class="card-title mrg-btm-10 inline">
                      <b>{{plan.name}}</b>
                    </h2>
                    <span class="label font-size-14 mrg-left-15" [ngClass]="getLabelClassByStatus()">
                      {{'resources.plans.status.' +  plan.status | translate}}
                    </span>
                    <span class="text-semibold font-size-14 mrg-left-15">
                      <i class="far fa-calendar"></i>
                      {{'resources.plans.fields.valid_from' | translate}}:
                      {{plan.available_from | date: 'dd/MM/yyyy'}}
                    </span>
                    <span class="text-semibold font-size-14 mrg-left-15">
                      {{'resources.plans.fields.valid_until' | translate}}:
                      {{plan.available_to | date: 'dd/MM/yyyy'}}
                    </span>
                    <ng-container *ngIf="isEditionVisible">
                      <i class="text-info mrg-left-15 fad fa-pencil-alt"></i>
                      <button type="button"
                              class="mrg-top-10"
                              (click)="toggleEdition()"
                              *ngIf="isEditionEnabled">
                              <span class="text-bold">{{'resources.plans.buttons.disable_edit' | translate}}</span>
                      </button>
                      <button type="button"
                              class="mrg-top-10"
                              (click)="toggleEdition()"
                              *ngIf="!isEditionEnabled">
                              <span class="text-bold">{{'resources.plans.buttons.enable_edit' | translate}}</span>
                      </button>

                    </ng-container>
                    </div>

                  <div class="row mrg-top-15 mrg-btm-10" *ngIf="flags.printFieldsVisibility && plan.print_from && plan.print_to">
                    <span class="text-semibold font-size-14 mrg-left-15">
                      <i class="fal fa-print"></i>
                      {{'resources.plans.fields.print_from' | translate}}:
                      {{plan.print_from | date: 'dd/MM/yyyy'}}
                    </span>
                    <span class="text-semibold font-size-14 mrg-left-15">
                      {{'resources.plans.fields.print_to' | translate}}:
                      {{plan.print_to | date: 'dd/MM/yyyy'}}
                    </span>
                  </div>
                </ng-container>
              </div>

              <ng-container *ngIf="mode === 'create' || isEditionEnabled">
                <app-plan-form (planEmitter)="handleEmittedPlan($event)" [plan]="plan"></app-plan-form>
              </ng-container>

              <ul class="nav nav-tabs" role="tablist" *ngIf="mode !== 'create'">
                <li class="nav-item">
                  <a [routerLink]="['campaigns']"
                     href="#tab-plan-1" class="nav-link {{slug === 'campaigns' ? 'active' : ''}}"
                     role="tab"
                     data-toggle="tab"
                     [translate]="'resources.plans.tabs.campaigns'"></a>
                </li>
                <li class="nav-item">
                  <a [routerLink]="['forecast']"
                     href="#tab-plan-2" class="nav-link {{slug === 'forecast' ? 'active' : ''}}"
                     role="tab"
                     data-toggle="tab"
                     [translate]="'resources.plans.tabs.forecast'"></a>
                </li>
                <li class="nav-item">
                  <a [routerLink]="['roi']"
                     href="#tab-plan-3" class="nav-link {{slug === 'roi' ? 'active' : ''}}"
                     role="tab"
                     data-toggle="tab"
                     [translate]="'resources.plans.tabs.roi'"></a>
                </li>
                <li class="nav-item" *ngIf="flags.showPlansPrePostAnalysis">
                  <a [routerLink]="['analysis']"
                     href="#tab-plan-4" class="nav-link {{slug === 'analysis' ? 'active' : ''}}"
                     role="tab"
                     data-toggle="tab"
                     [translate]="'resources.plans.tabs.analysis'"></a>
                </li>
                <li class="nav-item" *ngIf="flags.showPlansAlertsConfiguration">
                  <a [routerLink]="['alerts']"
                      href="#plans-tab-5" class="nav-link {{slug === 'alerts'? 'active' : ''}}"
                      role="tab"
                      data-toggle="tab"
                      [translate]="'resources.plans.tabs.alerts'">
                  </a>
                </li>
              </ul>
            </div>

            <!-- Tabs -->
            <div class="tab-info">
              <div class="tab-content">
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </div>
        <!-- Close fullscreen modal btn -->
        <a class="modal-close margin-10 mrg-top-15" data-dismiss="modal" (click)="handleCloseModal()">
          <i class="ti-close"></i>
        </a>
      </div>
    </div>
  </div>
</div>
