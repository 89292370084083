import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MultiselectDataSource, MultiselectDataSourceable } from '../../../shared/components/multiselect/multiselect';

@Injectable()
export class PartnersService extends ResourcesService implements MultiselectDataSourceable {

  constructor(http: HttpClient) {
    super(http);
  }

  getAll() {
    return this.getData({ apiEndPoint: `partners` });
  }

  getById(id: number | string) {
    return this.getData({ apiEndPoint: `partners/${id}` });
  }

  deleteById(id: number) {
    return this.deleteResource({}, `partners/${id}`);
  }

  create(payload: object) {
    return this.postResource(payload, 'partners');
  }

  updateById(payload: object, id: number | string) {
    return this.patchResource(payload, `partners/${id}`);
  }

  fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'partners',
      numberPerPage: 10,
      pageNumber: 1
    };
    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;
    if (searchValues) {
      const filterObj = { filtering: { search_text: searchValues, active: true}};
      requestOptions = {...requestOptions, ...filterObj};
    }
    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getById(id);
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.name}`, element);
  }
}
