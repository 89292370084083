import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourcesService } from '../../../../../../shared/services/resources.service';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-show-products-internal-features',
  templateUrl: './show-products-internal-features.component.html',
  styleUrls: ['./show-products-internal-features.component.scss']
})
export class ShowProductsInternalFeaturesComponent implements OnInit {

 element: any;
 apiEndPoint: string;
 id: number;
 isModal: boolean;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly resourcesService: ResourcesService,
    private readonly router: Router
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.isModal = this.router.url.indexOf('(modal:show/feature_internals/') >= 0;
    this.getParams();
    this.resourcesService.getData({ apiEndPoint: this.apiEndPoint + '/' + this.id })
        .subscribe(res => {
          this.element = res;
        },
        error => {
          console.log('error:', error);
        });
  }

  getParams() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'feature_internals';
      }
    });
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  goToCategory(id) {
    if (this.isModal) {
      window.open(`#/data-warehouse/products/features/${id}`);
    } else {
      this.router.navigate([{ outlets: { modal: `show/features/${id}`}}]).catch(() => {});
    }
  }

  goToTaxonomy() {
    if ( this.isModal ) {
      window.open(`#/data-warehouse/products/feature_taxonomies/${this.element.taxonomy.id}`);
    } else {
      this.router.navigate([{ outlets: { modal: `show/feature_taxonomies/${this.element.taxonomy.id}`}}]).catch(() => {});
    }
  }

}
