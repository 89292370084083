import { Injectable } from '@angular/core';
import { SegmentsService } from '../../../../../resources/segments/segments.service';
import { AnalyticsService } from '../../../../../shared/services/analytics/analytics.service';
import { DateService } from '../../../../../shared/services/date.service';
import { FeaturesService } from '../../../../../resources/data-warehouse/products/services/products-features/features.service';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { TextboxQuestion } from '../../../../../shared/models/forms/question-textbox';
import { ToggleCheckboxQuestion } from '../../../../../shared/models/forms/question-toggle-checkbox';
import { CheckboxQuestion } from '../../../../../shared/models/forms/question-checkbox';
import { CfgFilters } from '../../models/cfg-filters.model';
import { SegmentCategoriesService } from '../../../../../resources/segments/segment-categories.service';
import { CountriesService } from '../../../../services/countries.service';

@Injectable()
export class LookerCfgGlobalFiltersService {

  constructor(
    private dateService: DateService, 
    private analyticsService: AnalyticsService,
    private countriesService: CountriesService,
    private segmentCategoriesService: SegmentCategoriesService,
    private segmentsService: SegmentsService
  ) {}

  getCfgGlobalFilters(dateFrom: any, dateTo: any, currencySymbol: string, brandFeaturesService: FeaturesService): CfgFilters[] {
    this.segmentCategoriesService.onlyCategoriesWithSegments = true;
    const dateFromCompared = this.dateService.calculateDate('substract', 1, 'year', dateFrom);
    const dateToCompared = this.dateService.calculateDate('substract', 1, 'year', dateTo);

    return [
      {
        cfgKey: 'measureTypeFilter',
        cfgFilters: new MultiSelectQuestion({
          key: 'param_measure_type',
          label: 'components.looker-dashboard-filter.filters.measure_type',
          cssClasses: 'form-control input-default',
          settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
          options: this.analyticsService.getMeasureTypes(),
          required: true
        })
      },
      {
        cfgKey: 'dashboardPerUnitMeasure',
        cfgFilters: new MultiSelectQuestion({
          key: 'unit_measure',
          label: 'components.looker-dashboard-filter.filters.unit_measure',
          cssClasses: 'form-control input-default',
          settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
          options: this.analyticsService.getUnitMeasures(currencySymbol),
          value: [this.analyticsService.getUnitMeasures(currencySymbol)[0]],
          required: true
        })
      },
      {
        cfgKey: 'dashboardPerUnitMeasureQuantity',
        cfgFilters: new MultiSelectQuestion({
          key: 'unit_measure_quantity',
          label: 'components.looker-dashboard-filter.filters.unit_measure',
          cssClasses: 'form-control input-default',
          settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
          options: this.analyticsService.getUnitMeasuresQuantity(),
          value: [this.analyticsService.getUnitMeasuresQuantity()[0]],
          required: true
        })
      },
      {
        cfgKey: 'ticketsFilter',
        cfgFilters: new MultiSelectQuestion({
          key: 'tickets',
          label: 'components.looker-dashboard-filter.filters.tickets',
          cssClasses: 'form-control input-default',
          settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
          options: this.analyticsService.getTicketsOptions(),
          value: [this.analyticsService.getTicketsOptions()[0]]
        })
      },
      {
        cfgKey: 'supplierBrandFilter',
        cfgFilters: new MultiSelectQuestion({
          key: 'supplier_brand',
          label: 'components.looker-dashboard-filter.filters.supplier_brand',
          cssClasses: 'form-control input-default',
          settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
          options: this.analyticsService.getSupplierBrandOptions(),
          value: [this.analyticsService.getSupplierBrandOptions()[0]]
        })
      },
      {
        cfgKey: 'viewAndTopBrandSupplierFilters',
        cfgFilters: [
          new MultiSelectQuestion({
            key: 'view_brand_supplier',
            label: 'components.looker-dashboard-filter.filters.view_by_brand_supplier',
            cssClasses: 'form-control input-default',
            settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
            options: this.analyticsService.getBrandSupplierEntities(),
            value: [this.analyticsService.getBrandSupplierEntities()[0]]
          }),
          new MultiSelectQuestion({
            key: 'top_brand_supplier',
            label: 'components.looker-dashboard-filter.filters.top_brand_supplier',
            cssClasses: 'form-control input-default',
            settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
            options: this.analyticsService.getTopAllBrandSupplierEntities(),
            value: [this.analyticsService.getTopAllBrandSupplierEntities()[0]]
          })
        ]
      },
      {
        cfgKey: 'comparableDatesFilters',
        cfgFilters: [
          new ToggleCheckboxQuestion({
            cssClasses: 'form-control input-default',
            key: 'compare_with',
            label: 'dashboards.sales.filters.compare_with',
            type: 'toggle-checkbox'
          }),
          new TextboxQuestion({
            cssClasses: 'form-control input-md',
            key: 'date_from_compared',
            label: 'dashboards.sales.filters.available_from',
            type: 'date',
            value: dateFromCompared,
            required: true,
            hidden: true
          }),
          new TextboxQuestion({
            cssClasses: 'form-control input-md',
            key: 'date_to_compared',
            label: 'dashboards.sales.filters.available_to',
            type: 'date',
            value: dateToCompared,
            required: true,
            hidden: true
          })
        ]
      },
      {
        cfgKey: 'chartMetricFilter',
        cfgFilters: new MultiSelectQuestion({
          key: 'chart_metric',
          label: 'components.looker-dashboard-filter.filters.chart_metric',
          cssClasses: 'form-control input-default',
          settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
          options: this.analyticsService.getChartMetricOptions(),
          value: [this.analyticsService.getChartMetricOptions()[0]],
          required: true
        })
      },
      {
        cfgKey: 'chartMetricSupplierBrandFilter',
        cfgFilters: new MultiSelectQuestion({
          key: 'chart_metric_supplier_brand',
          label: 'components.looker-dashboard-filter.filters.chart_metric',
          cssClasses: 'form-control input-default',
          settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
          options: this.analyticsService.getChartMetricSupplierBrandOptions(),
          value: [this.analyticsService.getChartMetricSupplierBrandOptions()[0]],
          required: true
        })
      },
      {
        cfgKey: 'chartGranularityFilter',
        cfgFilters: new MultiSelectQuestion({
          key: 'chart_granularity',
          label: 'components.looker-dashboard-filter.filters.chart_granularity',
          cssClasses: 'form-control input-default',
          settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
          options: this.analyticsService.getChartGranularityOptions(),
          value: [this.analyticsService.getChartGranularityOptions()[3]],
          required: true
        })
      },
      {
        cfgKey: 'dayOfWeekFilter',
        cfgFilters: new MultiSelectQuestion({
          key: 'day_of_week',
          label: 'components.looker-dashboard-filter.filters.day_of_week',
          cssClasses: 'form-control input-default',
          settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
          options: this.analyticsService.getWeekdaysOptions(),
          value: [...this.analyticsService.getWeekdaysOptions()],
          required: true
        })
      },
      {
        cfgKey: 'countryFilter',
        cfgFilters: new MultiSelectQuestion({
          key: 'country',
          label: 'dashboards.sales.filters.country',
          cssClasses: 'form-control input-default',
          settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
          options: this.countriesService.getCountries(),
          value: [...this.countriesService.getCountries()],
          required: true
        })
      },
      {
        cfgKey: 'onlyComparableStoresFilter',
        cfgFilters: new CheckboxQuestion({
          key: 'only_comparable_stores',
          label: 'components.looker-dashboard-filter.filters.only_comparable_stores',
          type: 'checkbox',
          cssClasses: 'form-control input-default',
          value: false,
          tooltipKey: 'components.looker-dashboard-filter.tooltips.only_comparable_stores'
        })
      },
      {
        cfgKey: 'chartMetricasFilter',
        cfgFilters: new MultiSelectQuestion({
          key: 'chart_metricas',
          label: 'components.looker-dashboard-filter.filters.chart_metric',
          cssClasses: 'form-control input-default',
          settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
          options: this.analyticsService.getChartMetricasOptions(),
          value: [this.analyticsService.getChartMetricasOptions()[0]],
          required: true
        })
      },
      {
        cfgKey: 'chartMetricsWithOwnBrandFilter',
        cfgFilters: new MultiSelectQuestion({
          key: 'chart_metrics_with_own_brand',
          label: 'components.looker-dashboard-filter.filters.chart_metric',
          cssClasses: 'form-control input-default',
          settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
          options: this.analyticsService.getChartMetricsWithOwnBrandOptions(),
          value: [this.analyticsService.getChartMetricsWithOwnBrandOptions()[0]],
          required: true
        })
      },
      {
        cfgKey: 'segmentCategoryIdFilter',
        cfgFilters: new MultiSelectQuestion({
          key: 'segment_category_id',
          label: 'components.looker-dashboard-filter.filters.segment_category',
          cssClasses: 'form-control input-default',
          settings: { singleSelection: true, enableSearchFilter: true},
          dataSource: this.segmentCategoriesService,
          showTooltip: true,
          tooltipKey: 'components.looker-dashboard-filter.tooltips.segment_category'
        })
      },
      {
        cfgKey: 'segmentIdFilter',
        cfgFilters: new MultiSelectQuestion({
          key: 'segment_id',
          label: 'dashboards.sales.filters.segments',
          cssClasses: 'form-control input-default',
          settings: { singleSelection: true, enableSearchFilter: true},
          dataSource: this.segmentsService
        })
      },
      {
        cfgKey: 'brandPkFilter',
        cfgFilters: new MultiSelectQuestion({
          key: 'brand_pk',
          label: 'components.looker-dashboard-filter.filters.brand',
          cssClasses: 'form-control input-default',
          settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
          dataSource: brandFeaturesService
        })
      },
      {
        cfgKey: 'idEanProductFilter',
        cfgFilters: new MultiSelectQuestion({
          key: 'id_ean_product',
          label: 'components.looker-dashboard-filter.filters.id_ean_product',
          cssClasses: 'form-control input-default',
          settings: { singleSelection: true, enableSearchFilter: false },
          options: this.analyticsService.getIdEanProductOptions(),
          value: [this.analyticsService.getIdEanProductOptions()[0]]
        })
      }
    ]
  }
}
