import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ConfirmationService } from '../../../../../../shared/services/confirmation.service';
import { getCurrencySymbol } from '../../../../../../shared/utils/common.utils';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfileService } from '../../../../../../profiles/profile.service';
import { PromotionsService } from '../../../services/promotions/promotions.service';
import { registerLocaleData } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-show-promotions',
  templateUrl: './show-promotions.component.html',
  styleUrls: ['./show-promotions.component.scss'],
  providers: [PromotionsService]
})

export class ShowPromotionsComponent implements OnInit, OnDestroy {

  apiEndPoint: string;
  currencySymbol: string;
  element: unknown;
  id: number;

  private readonly destroy$ = new Subject<void>();

  @Output('updateEmitter') updateEmitter: EventEmitter<unknown>  = new EventEmitter();

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly profileService: ProfileService,
    private readonly promotionService: PromotionsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'narrow');
    registerLocaleData(es);
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeModal(): void {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  viewAll(): void {
    this.router.navigate([{ outlets: { modal: null } }]).then(() => {
      this.router.navigate([`customer-data-platform/warehouse/promotion/${this.id}/edit`]);
    });
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'promotions';
        this.getPromotionById();
      }
    });
  }

  private getPromotionById(): void {
    this.promotionService.getPromotionsById(this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (res) => {
        this.element = res;
        this.updateEmitter.emit([`/customer-data-platform/warehouse/promotion/${this.element['id']}/edit`]);
      },
      error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
    });
  }
}
