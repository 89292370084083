<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card-block no-pdd">
        <div class="mrg-top-40">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form  [formGroup]="featureTaxonomiesForm" *ngIf="featureTaxonomiesForm">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name">{{ 'resources.product_taxonomies.form.name' | translate }}</label>
                      <app-question [question]="getInputConfig('name')" [form]="featureTaxonomiesForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="slug">{{ 'resources.product_taxonomies.form.slug' | translate }}</label>
                      <span *ngIf="getInputConfig('slug').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('slug')" [form]="featureTaxonomiesForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="origin">{{ 'resources.product_taxonomies.form.origin' | translate }}</label>
                      <span *ngIf="getInputConfig('origin').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('origin')" [form]="featureTaxonomiesForm"></app-question>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="isKindLevelVisible">
                  <div class="row" >
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="kind">{{ 'resources.product_taxonomies.form.kind' | translate }}</label>
                        <app-question [question]="getInputConfig('kind')" [form]="featureTaxonomiesForm"></app-question>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="level">{{ 'resources.product_taxonomies.form.level' | translate }}</label>
                        <span *ngIf="getInputConfig('level').required" class="text-danger">*</span>
                        <app-question [question]="getInputConfig('level')" [form]="featureTaxonomiesForm"></app-question>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
