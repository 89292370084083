import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DictionaryService } from '../../services/dictionary.service';
import { FormCampaignsCategoriesComponent } from '../../../resources/campaigns/campaign-categories/form-campaigns-categories/form-campaigns-categories.component';
import { FormCompanyPromotionsComponent } from '../../../resources/data-warehouse/promotions/components/tab-promotional-discounts/form-promotional-discounts/form-promotional-discounts.component';
import { FormContactPoliciesComponent } from '../../../resources/config/contact-policies/form-contact-policies/form-contact-policies.component';
import { FormCreditTransactionsComponent } from '../../../resources/config/credit-transactions/form-credit-transactions/form-credit-transactions.component';
import { FormLocationTaxonomiesTermsComponent } from '../../../resources/data-warehouse/locations/form-location-taxonomies-terms/form-location-taxonomies-terms.component';
import { FormLoyaltyScoreMomentsComponent } from '../../../resources/scores/loyalty_scores/loyalty_scores_moments/form-loyalty-score-moments/form-loyalty-score-moments.component';
import { FormMessageCategoriesComponent } from '../../../resources/content-designer/campaign-messages/form-message-categories/form-message-categories.component';
import { FormNewsTaxonomyTermsComponent } from '../../../resources/content-designer/campaign-news-list/tabs/news-taxonomy-terms/form/form-news-taxonomy-terms.component';
import { FormProductsComponent } from '../../../resources/data-warehouse/products/components/products/form-products/form-products.component';
import { FormProductsFeaturesComponent } from '../../../resources/data-warehouse/products/components/products-features/form-products-features/form-products-features.component';
import { FormProductsFeatureTaxonomiesComponent } from '../../../resources/data-warehouse/products/components/products-feature-taxonomies/form-products-feature-taxonomies/form-products-feature-taxonomies.component';
import { FormRewardCategoriesComponent } from '../../../resources/scores/rewards/reward-categories/form-reward-categories/form-reward-categories.component';
import { FormSegmentCategoriesComponent } from '../../../resources/segments/form-segment-categories/form-segment-categories.component';
import { FormWalletsComponent } from '../../../resources/scores/wallets/form-wallets/form-wallets.component';
import { ModalStatusService } from '../../services/modal-status.service';
import { ProfileService } from '../../../profiles/profile.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-large-modal-container',
  templateUrl: './large-modal-container.component.html',
  styleUrls: ['./large-modal-container.component.css'],
  providers: [DictionaryService]
})

export class LargeModalContainerComponent implements OnInit, OnDestroy {

  apiEndPoint: string;
  campaignStatus: string;
  closeModalOnRedirect = false;
  formLoaderStatus$: Subscription;
  id: number;
  isValid: any = 'VALID';
  loading = false;
  loadingData = true;
  mode: string;
  roleSlug: any;
  title: string;
  updateRedirect;

  get isExecuteButton(): boolean {
    return this.apiEndPoint === 'execute_date';
  }

  @ViewChild('form') form: FormLoyaltyScoreMomentsComponent |
                           FormProductsFeaturesComponent |
                           FormProductsFeatureTaxonomiesComponent |
                           FormLocationTaxonomiesTermsComponent |
                           FormProductsComponent |
                           FormCreditTransactionsComponent |
                           FormSegmentCategoriesComponent |
                           FormWalletsComponent |
                           FormRewardCategoriesComponent |
                           FormCampaignsCategoriesComponent |
                           FormNewsTaxonomyTermsComponent |
                           FormCompanyPromotionsComponent |
                           FormMessageCategoriesComponent |
                           FormContactPoliciesComponent;

  constructor(
    private dictionarySrv: DictionaryService,
    private modalStatusService: ModalStatusService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.roleSlug = this.profileService.getStoredUserRole();
    this.getId();
    this.getMode();
    this.getApiEndPoint();
    this.getTitle();
    setTimeout(() => { this.initLoaderStatus(); }, 100);
  }

  ngOnDestroy() {
    if (this.formLoaderStatus$) { this.formLoaderStatus$.unsubscribe(); }
  }

  initLoaderStatus() {
    this.loadingData = false;
    this.formLoaderStatus$ = this.modalStatusService.formLoaderStatus.subscribe(
      (loaderStatus: string) => {
        switch (loaderStatus) {
          case 'loading':
            this.loading = true;
            break;
          case 'loading_finished':
            this.loading = false;
            break;
          case 'loading_data':
            this.loadingData = true;
            break;
          case 'loading_data_finished':
            this.loadingData = false;
            break;
        }
      }
    );
  }

  getId() {
    this.id = this.route.snapshot.params.id;
  }

  getApiEndPoint() {
    this.apiEndPoint = this.route.snapshot.data.title;
  }

  getTitle() {
    const title = this.dictionarySrv.dictionaries.modal_titles.find( _title => _title.id === this.apiEndPoint );
    this.title = title[`${this.mode}_title`];
  }

  getMode() {
    this.mode = this.route.snapshot.data.mode;
    if (!this.mode) { this.mode = 'create'; }
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  sendData() {
    if (this.form) { this.form.sendData(); }
  }

  handleDelivery(emitterUpdateRoute, _closeModalOnRedirect = false) {
    this.closeModalOnRedirect = _closeModalOnRedirect;
    this.updateRedirect = emitterUpdateRoute;
  }

  handleFormStatus(formStatus) {
    this.isValid = formStatus === 'VALID';
  }

  handleCampaignStatus(event) {
    this.campaignStatus = event;
  }

  handleUpdateRedirect() {
    if(this.closeModalOnRedirect) {
      this.router.navigate([{ outlets: { modal: null } }]).then(() => {
        this.router.navigate(this.updateRedirect);
      })
    } else {
      this.router.navigate(this.updateRedirect);
    }
  }
}
