import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { ReservedIdsService } from '../services/reserved-ids.service';
import { Router } from '@angular/router';
import { SharedModule } from '../../../../shared/shared.module';
import { Subject, finalize } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormReservedIdsInputsService } from './services/form-reserved-ids-inputs.service';

@Component({
  selector: 'app-form-reserved-ids',
  templateUrl: './form-reserved-ids.component.html',
  styleUrls: ['./form-reserved-ids.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, ReactiveFormsModule, FormsModule],
  providers: [ReservedIdsService, FormReservedIdsInputsService]
})
export class FormReservedIdsComponent implements OnInit {

  loading = false;
  reservedIdsForm: UntypedFormGroup;
  reservedIdsInputs: QuestionBase<unknown>[];

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly modalStatusService: ModalStatusService,
    private readonly qcs: QuestionControlService,
    private readonly reservedIdsService: ReservedIdsService,
    private readonly formReservedIdsInputsService: FormReservedIdsInputsService,
    private readonly router: Router,
    private readonly translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.setInputsAndForm();
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.reservedIdsInputs, inputKey);
  }

  save(): void {
    const formValue = this.reservedIdsForm.getRawValue();
    const payload = {
      codes_type: formValue?.codes_type?.[0]?.id ?? null,
      codes: formValue?.codes ? formValue.codes : null
    };
    this.loading = true;
    this.reservedIdsService.loadCSV(payload).pipe(finalize(() => this.loading = false)).subscribe({
      next: () => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.reserved_ids.warnings.success_title'),
          this.translate.instant('resources.reserved_ids.warnings.success_text')
        ).catch(() => {});
        this.modalStatusService.modalStatus.emit();
        this.closeModal();
      },
      error: (errorData: HttpErrorResponse) => this.handleErrors(errorData)
    });
  }

  closeModal(): void {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private setInputsAndForm(): void {
    this.reservedIdsInputs = this.formReservedIdsInputsService.getInputs();
    this.reservedIdsForm = this.qcs.toFormGroup(this.reservedIdsInputs);
  }

  private handleErrors(errorData: HttpErrorResponse): void {
    const errorValue = errorData?.error?.error ? errorData.error.error : null;
    if (errorData?.error?.errors?.length) {
      this.qcs.paintErrorsInForm(this.reservedIdsInputs, this.reservedIdsForm, errorData.error.errors);
      if (errorValue) { this.displayErrorAlert(errorValue); }
    } else if (errorValue) {
      this.displayErrorAlert(errorValue);
    }
  }

  private displayErrorAlert(error: string): void {
    this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), error);
  }
}
