import { Injectable } from '@angular/core';
import { currencies } from './currencies.mock';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ResourcesService } from './resources.service';
import { MultiselectDataSource } from '../components/multiselect/multiselect';
import { QuestionBase } from '../models/forms/question-base';
import { MultiSelectQuestion } from '../models/forms/question-multiselect';
import { CheckboxQuestion } from '../models/forms/question-checkbox';
import { Observable } from 'rxjs';

@Injectable()
export class CurrenciesService extends ResourcesService {

  inputs: QuestionBase<unknown>[];
  activeCompanyCurrencies: {};

  constructor(
    http: HttpClient,
    private translate: TranslateService) {
    super(http);
  }

  fetchMultiselect( searchValues?: string, page?: number, filters?: object ): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'currencies',
      numberPerPage: 300,
      filtering: {
        active: true
      },
      pageNumber: 1,
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues, active: true }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    if (filters || this.activeCompanyCurrencies ) {
      filters = {...filters, ...this.activeCompanyCurrencies ?? {}};
      const filtering = Object.assign({}, requestOptions.filtering, filters);
      const filterObj = { filtering: filtering};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.iso_code, element.name);
  }

  fetchSelectedById(isoCode: string) {
    return this.getCurrenciesByIsoCode(isoCode);
  }

  getCurrenciesByIsoCode(isoCode: string): Observable<any> {
    return this.getData({apiEndPoint: `currencies/${isoCode}`});
  }

  getCurrencies() {
    return currencies;
  }

  getCurrenciesById(id: string) {
    return currencies.find(currency => currency.id === id);
  }

  getInputs(formValues: any): QuestionBase<any>[] {
    this.inputs = [
      new MultiSelectQuestion({
        key: 'default_currency',
        cssClasses: 'form-control input-default',
        label: this.translate.instant('resources.currencies.form.default_currency'),
        settings: {singleSelection: true, showCheckbox: false, enableSearchFilter: true},
        dataSource: this,
        order: 1,
        selectedIds: formValues.hasOwnProperty('default_currency') ? [formValues['default_currency']] : []
      }),
      new CheckboxQuestion({
        key: 'multicurrency',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.currencies.form.multicurrency'),
        order: 2,
        value: formValues.hasOwnProperty('multicurrency') ? !!formValues['multicurrency'] : false
      }),
    ];

    return this.inputs;
  }

  setActiveSlugFilter(): boolean {
    this.activeCompanyCurrencies = { active: true };
    return true;
  }

}
