import { Injectable } from '@angular/core';
import { SegmentConditionProvider } from './condition.service';
import { QuestionBase } from '../../models/forms/question-base';
import { MultiSelectQuestion } from '../../models/forms/question-multiselect';
import { TranslateService } from '@ngx-translate/core';
import { MultiselectDataSource } from '../../components/multiselect/multiselect';
import { multiselectPresenceValidator, formGroupEmptyValidator } from '../validations.service';
import { DynamicDateQuestion } from '../../models/forms/question-dynamic-date';
import { DateService } from '../date.service';
import { FeaturesService } from '../../../resources/data-warehouse/products/services/products-features/features.service';
import { ProductsService } from '../../../resources/data-warehouse/products/services/products/products.service';
import { V1SegmentsService } from '../../../resources/segments/v1-segments.service';

@Injectable()
export class ClientCategoriesConditionService implements SegmentConditionProvider {

  public inputs: QuestionBase<any>[];
  public customValidators = formGroupEmptyValidator();

  constructor(private translate: TranslateService,
              private featuresService: FeaturesService,
              private productsService: ProductsService,
              private dateService: DateService,
              private segmentsService: V1SegmentsService) { }

  public getInputs(params?: any): QuestionBase<any>[] {

    this.featuresService.treatPkAsId = true;
    const paramsAreDefined = ( params && Object.keys(params).length > 0 );

    const inputs = [
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'category',
        label: this.translate.instant('resources.segment_conditions.fields.customer'),
        options: this.getCustomerCategories(),
        required: true,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        value: paramsAreDefined ? this.getCategoryFromParams(params) : [],
        parseValue: (value) => {
          return (value && value.length) ? this.getCustomerCategories().find( operator => operator.id === value[0].id ).id : null;
        },
        customValidators: [multiselectPresenceValidator]
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_from',
        label: this.translate.instant('resources.segment_conditions.fields.date_from'),
        value: paramsAreDefined ? this.getDateFromFromParams(params) : null,
        parseValue: (value) =>  {
          return (value && Object.keys(value).length > 0) ? value.id : null;
        }
      }),
      new DynamicDateQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_to',
        label: this.translate.instant('resources.segment_conditions.fields.date_to'),
        value: paramsAreDefined ? this.getDateToFromParams(params) : null,
        parseValue: (value) =>  {
          return (value && Object.keys(value).length > 0) ? value.id : null;
        }
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'feature_ids',
        label: this.translate.instant('resources.segment_conditions.fields.feature_ids'),
        dataSource: this.featuresService,
        settings: { singleSelection: false, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
        selectedIds: paramsAreDefined ? this.getFeaturesFromParams(params) : [],
        parseValue: (values) => ((values && values.length) ? values.map( value => value.rawElement.pk ) : [])
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'product_ids',
        label: this.translate.instant('resources.segment_conditions.fields.product_ids'),
        dataSource: this.productsService,
        useToken: true,
        settings: { singleSelection: false, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
        selectedIds: paramsAreDefined ? this.getProductsFromParams(params) : [],
        parseValue: (values) => ((values && values.length) ? values.map( value => value.id ) : [])
      }),
      new MultiSelectQuestion({
        label: this.translate.instant('resources.segment_conditions.fields.customers_group'),
        cssClasses: 'form-control input-md',
        key: 'segment_id',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        parseValue: (values) => ((values && values.length > 0) ? values[0].id : []),
        dataSource: this.segmentsService,
        selectedIds: paramsAreDefined ? this.getSegmentFromParams(params) : []
      }),
    ];

    this.inputs = inputs;
    return this.inputs;
  }

  public prepareFormValuesToAPI(params: any): any {
    const parsedValuesObj = {};
    Object.keys(params).forEach( key => {
      const input = this.inputs.find( _input => _input.key === key );
      const parsedValue = input.parseValue( params[key], parsedValuesObj );
      parsedValuesObj[key] = parsedValue;
    });
    return parsedValuesObj;
  }

  public prepareFormValuesFromAPI(params: any): any {
    const parseValues = {
      category: (value) => (value) ? value : null,
      date_from: (value) => (value) ? value : null,
      date_to: (value) => (value) ? value : null,
      feature_ids: (value) => (value) ? value : null,
      product_ids: (value) => (value) ? value : null,
      segment_id: (value) => (value) ? value : null
    };
    const parsedValuesObj = {};
    Object.keys(params).forEach( key => { parsedValuesObj[key] = parseValues[key](params[key]); });
    return parsedValuesObj;
  }

  private getCategoryFromParams(params: Object): MultiselectDataSource[] {
    const category = params['category'];
    if (category && category.length > 0 && category[0].hasOwnProperty('id')) {
      return category;
    } else if (category && category.length > 0 && !category[0].hasOwnProperty('id')) {
      return [this.getCustomerCategories().find( op => op.id === category )];
    }
    return [];
  }

  private getDateFromFromParams(params: Object): any {
    const dateFrom = params['date_from'];
    const value = (dateFrom && dateFrom.hasOwnProperty('id')) ? dateFrom.id : dateFrom;
    if (!value) { return { id: null, name: null}; }
    return this.dateService.dynamicDateToDateOption(value);
  }

  private getDateToFromParams(params: Object): any {
    const dateTo = params['date_to'];
    const value = (dateTo && dateTo.hasOwnProperty('id')) ? dateTo.id : dateTo;
    if (!value) { return { id: null, name: null}; }
    return this.dateService.dynamicDateToDateOption(value);
  }

  private getFeaturesFromParams(params: Object): any {
    let features = params['feature_ids'];
    if (!features) { return []; }
    if (features && features.length && features[0] instanceof MultiselectDataSource) {
      features = features.map(val => val.rawElement.pk);
    }
    return features ? features : [];
  }

  private getProductsFromParams(params: Object): any {
    let productIds = params['product_ids'];
    if (!productIds) { return []; }
    if (productIds && productIds.length && productIds[0] instanceof MultiselectDataSource) {
      productIds = productIds.map(val => val.id);
    }
    return productIds ? productIds : [];
  }

  private getSegmentFromParams(params?: Object){
    const segment = params['segment_id'];
    if( segment && typeof(segment) === 'number' ){
      return [{ id: segment, name: segment }];
    } else if ( segment && segment.length > 0 && typeof(segment) === 'object' ){
      return segment;
    } else {
      return [];
    }
  }

  private getCustomerCategories(): MultiselectDataSource[] {
    return [
      {id: 'gold', name: this.translate.instant('resources.segment_conditions.fields.gold')},
      {id: 'silver', name: this.translate.instant('resources.segment_conditions.fields.silver')},
      {id: 'bronze', name: this.translate.instant('resources.segment_conditions.fields.bronze')}
    ];
  }
}
