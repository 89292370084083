import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { HttpClient } from '@angular/common/http';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../../../shared/components/multiselect/multiselect';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PaymentMethodsService extends ResourcesService implements MultiselectDataSourceable {

  public inputs: any[];

  constructor(http: HttpClient) {
    super(http);
  }

  public getAll() {

    const requestOptions = {
      apiEndPoint: 'payment_methods',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };

    return this.getData(requestOptions);
  }

  public fetchMultiselect( searchValues?: string, page?: number ) {
    let requestOptions = {
      apiEndPoint: 'payment_methods',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {}
    };

    requestOptions.pageNumber = page ?? requestOptions.pageNumber;

    /* Handling user search input: Merge object with user search term string */
    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  public fetchSelectedById(id: number): Observable<object> {
    return this.getData( { apiEndPoint: `payment_methods?per_page=300` } ).pipe(
            map( itemArray => itemArray['list'] ),
            map( element => {
              return element.filter( x => `${x.id}` === `${id}` )[0];
            }));
  }

  public getPaymentById(id: number): Observable<any> {
    return this.getData({apiEndPoint: `payment_methods/${id}`});
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, element.slug, element);
  }

  public getInputs(): QuestionBase<any>[] {
    const inputsArray = [
      new TextboxQuestion({
        key: 'slug',
        type: 'text',
        cssClasses: 'form-control input-default',
      })
    ];

    this.inputs = inputsArray;
    return this.inputs;
  }

  public sendData(data) {
    return this.postResource(data, 'payment_methods');
  }

}
