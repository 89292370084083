import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DictionaryService {
  dictionaries = {
    countries: [
      {name: this.translate.instant('common.countries.afghanistan'), id: 'AF', dial_code: '93'},
      {name: this.translate.instant('common.countries.aland_island'), id: 'AX', dial_code: '358'},
      {name: this.translate.instant('common.countries.albania'), id: 'AL', dial_code: '355'},
      {name: this.translate.instant('common.countries.algeria'), id: 'DZ', dial_code: '213'},
      {name: this.translate.instant('common.countries.andorra'), id: 'AD', dial_code: '376'},
      {name: this.translate.instant('common.countries.angola'), id: 'AO', dial_code: '244'},
      {name: this.translate.instant('common.countries.anguila'), id: 'AI', dial_code: '264'},
      {name: this.translate.instant('common.countries.antarctica'), id: 'AQ', dial_code: '672'},
      {name: this.translate.instant('common.countries.antigua_barbuda'), id: 'AG', dial_code: '268'},
      {name: this.translate.instant('common.countries.argentina'), id: 'AR', dial_code: '54'},
      {name: this.translate.instant('common.countries.armenia'), id: 'AM', dial_code: '374'},
      {name: this.translate.instant('common.countries.aruba'), id: 'AW', dial_code: '297'},
      {name: this.translate.instant('common.countries.australia'), id: 'AU', dial_code: '61'},
      {name: this.translate.instant('common.countries.austria'), id: 'AT', dial_code: '43'},
      {name: this.translate.instant('common.countries.azerbaijan'), id: 'AZ', dial_code: '994'},
      {name: this.translate.instant('common.countries.belgium'), id: 'BE', dial_code: '32'},
      {name: this.translate.instant('common.countries.bahamas'), id: 'BS', dial_code: '242'},
      {name: this.translate.instant('common.countries.bahrain'), id: 'BH', dial_code: '973'},
      {name: this.translate.instant('common.countries.bangladesh'), id: 'BD', dial_code: '880'},
      {name: this.translate.instant('common.countries.barbados'), id: 'BB', dial_code: '246'},
      {name: this.translate.instant('common.countries.belize'), id: 'BZ', dial_code: '501'},
      {name: this.translate.instant('common.countries.benin'), id: 'BJ', dial_code: '229'},
      {name: this.translate.instant('common.countries.bhutan'), id: 'BT', dial_code: '975'},
      {name: this.translate.instant('common.countries.belarus'), id: 'BY', dial_code: '375'},
      {name: this.translate.instant('common.countries.bermuda_island'), id: 'BM', dial_code: '441'},
      {name: this.translate.instant('common.countries.bolivia'), id: 'BO', dial_code: '591'},
      {name: this.translate.instant('common.countries.bonaire_eustatius_saba'), id: 'BQ', dial_code: '535'},
      {name: this.translate.instant('common.countries.bosnia_herzegovina'), id: 'BA', dial_code: '387'},
      {name: this.translate.instant('common.countries.botswana'), id: 'BW', dial_code: '267'},
      {name: this.translate.instant('common.countries.brazil'), id: 'BR', dial_code: '55'},
      {name: this.translate.instant('common.countries.brunei'), id: 'BN', dial_code: '673'},
      {name: this.translate.instant('common.countries.bulgary'), id: 'BG', dial_code: '359'},
      {name: this.translate.instant('common.countries.burkina_faso'), id: 'BF', dial_code: '226'},
      {name: this.translate.instant('common.countries.burundi'), id: 'BI', dial_code: '257'},
      {name: this.translate.instant('common.countries.cape_verde'), id: 'CV', dial_code: '238'},
      {name: this.translate.instant('common.countries.cambodia'), id: 'KH', dial_code: '855'},
      {name: this.translate.instant('common.countries.cameroon'), id: 'CM', dial_code: '237'},
      {name: this.translate.instant('common.countries.canada'), id: 'CA', dial_code: '1'},
      {name: this.translate.instant('common.countries.cayman_island'), id: 'KY', dial_code: '345'},
      {name: this.translate.instant('common.countries.chad'), id: 'TD', dial_code: '235'},
      {name: this.translate.instant('common.countries.chile'), id: 'CL', dial_code: '56'},
      {name: this.translate.instant('common.countries.central_african_republic'), id: 'CF', dial_code: '236'},
      {name: this.translate.instant('common.countries.china'), id: 'CN', dial_code: '86'},
      {name: this.translate.instant('common.countries.cyprus'), id: 'CY', dial_code: '357'},
      {name: this.translate.instant('common.countries.colombia'), id: 'CO', dial_code: '57'},
      {name: this.translate.instant('common.countries.comoros'), id: 'KM', dial_code: '269'},
      {name: this.translate.instant('common.countries.congo'), id: 'CG', dial_code: '242'},
      {name: this.translate.instant('common.countries.congo'), id: 'CD', dial_code: '243'},
      {name: this.translate.instant('common.countries.costa_rica'), id: 'CR', dial_code: '506'},
      {name: this.translate.instant('common.countries.cook_island'), id: 'CK', dial_code: '682'},
      {name: this.translate.instant('common.countries.croatia'), id: 'HR', dial_code: '385'},
      {name: this.translate.instant('common.countries.cuba'), id: 'CU', dial_code: '53'},
      {name: this.translate.instant('common.countries.czech_republic'), id: 'CZ', dial_code: '420'},
      {name: this.translate.instant('common.countries.denmark'), id: 'DK', dial_code: '45'},
      {name: this.translate.instant('common.countries.dominica'), id: 'DM', dial_code: '767'},
      {name: this.translate.instant('common.countries.dominican_republic'), id: 'DO', dial_code: '809'},
      {name: this.translate.instant('common.countries.ecuador'), id: 'EC', dial_code: '593'},
      {name: this.translate.instant('common.countries.egypt'), id: 'EG', dial_code: '20'},
      {name: this.translate.instant('common.countries.el_salvador'), id: 'SV', dial_code: '503'},
      {name: this.translate.instant('common.countries.eritrea'), id: 'ER', dial_code: '291'},
      {name: this.translate.instant('common.countries.estonia'), id: 'EE', dial_code: '372'},
      {name: this.translate.instant('common.countries.ethiopia'), id: 'ET', dial_code: '251'},
      {name: this.translate.instant('common.countries.faroe_island'), id: 'FO', dial_code: '298'},
      {name: this.translate.instant('common.countries.finland'), id: 'FI', dial_code: '358'},
      {name: this.translate.instant('common.countries.fiji'), id: 'FJ', dial_code: '679'},
      {name: this.translate.instant('common.countries.france'), id: 'FR', dial_code: '33'},
      {name: this.translate.instant('common.countries.french_guiana'), id: 'GF', dial_code: '594'},
      {name: this.translate.instant('common.countries.french_polynesia'), id: 'PF', dial_code: '689'},
      {name: this.translate.instant('common.countries.gabon'), id: 'GA', dial_code: '241'},
      {name: this.translate.instant('common.countries.gambia'), id: 'GM', dial_code: '220'},
      {name: this.translate.instant('common.countries.georgia'), id: 'GE', dial_code: '995'},
      {name: this.translate.instant('common.countries.germany'), id: 'DE', dial_code: '49'},
      {name: this.translate.instant('common.countries.ghana'), id: 'GH', dial_code: '233'},
      {name: this.translate.instant('common.countries.gibraltar'), id: 'GI', dial_code: '350'},
      {name: this.translate.instant('common.countries.grenada'), id: 'GD', dial_code: '473'},
      {name: this.translate.instant('common.countries.greece'), id: 'GR', dial_code: '30'},
      {name: this.translate.instant('common.countries.greenland'), id: 'GL', dial_code: '299'},
      {name: this.translate.instant('common.countries.guadeloupe'), id: 'GP', dial_code: '590'},
      {name: this.translate.instant('common.countries.guam'), id: 'GU', dial_code: '671'},
      {name: this.translate.instant('common.countries.guatemala'), id: 'GT', dial_code: '502'},
      {name: this.translate.instant('common.countries.guernsey'), id: 'GG', dial_code: '44'},
      {name: this.translate.instant('common.countries.guinea'), id: 'GN', dial_code: '224'},
      {name: this.translate.instant('common.countries.equatorial_guinea'), id: 'GQ', dial_code: '240'},
      {name: this.translate.instant('common.countries.guinea_bissau'), id: 'GW', dial_code: '245'},
      {name: this.translate.instant('common.countries.guyana'), id: 'GY', dial_code: '592'},
      {name: this.translate.instant('common.countries.haiti'), id: 'HT', dial_code: '509'},
      {name: this.translate.instant('common.countries.honduras'), id: 'HN', dial_code: '504'},
      {name: this.translate.instant('common.countries.hong_kong'), id: 'HK', dial_code: '852'},
      {name: this.translate.instant('common.countries.hungary'), id: 'HU', dial_code: '36'},
      {name: this.translate.instant('common.countries.iceland'), id: 'IS', dial_code: '354'},
      {name: this.translate.instant('common.countries.india'), id: 'IN', dial_code: '91'},
      {name: this.translate.instant('common.countries.indonesia'), id: 'ID', dial_code: '62'},
      {name: this.translate.instant('common.countries.iran'), id: 'IR', dial_code: '98'},
      {name: this.translate.instant('common.countries.irak'), id: 'IQ', dial_code: '964'},
      {name: this.translate.instant('common.countries.ireland'), id: 'IE', dial_code: '353'},
      {name: this.translate.instant('common.countries.israel'), id: 'IL', dial_code: '972'},
      {name: this.translate.instant('common.countries.italy'), id: 'IT', dial_code: '39'},
      {name: this.translate.instant('common.countries.ivory_coast'), id: 'CI', dial_code: '225'},
      {name: this.translate.instant('common.countries.jamaica'), id: 'JM', dial_code: '876'},
      {name: this.translate.instant('common.countries.japan'), id: 'JP', dial_code: '81'},
      {name: this.translate.instant('common.countries.jersey'), id: 'JE', dial_code: '44'},
      {name: this.translate.instant('common.countries.jordan'), id: 'JO', dial_code: '962'},
      {name: this.translate.instant('common.countries.kazakhstan'), id: 'KZ', dial_code: '7'},
      {name: this.translate.instant('common.countries.kenya'), id: 'KE', dial_code: '254'},
      {name: this.translate.instant('common.countries.kyrgyzstan'), id: 'KG', dial_code: '996'},
      {name: this.translate.instant('common.countries.kiribati'), id: 'KI', dial_code: '686'},
      {name: this.translate.instant('common.countries.kosovo'), id: 'XK', dial_code: '383'},
      {name: this.translate.instant('common.countries.kuwait'), id: 'KW', dial_code: '965'},
      {name: this.translate.instant('common.countries.lebanon'), id: 'LB', dial_code: '961'},
      {name: this.translate.instant('common.countries.laos'), id: 'LA', dial_code: '856'},
      {name: this.translate.instant('common.countries.lesotho'), id: 'LS', dial_code: '266'},
      {name: this.translate.instant('common.countries.latvia'), id: 'LV', dial_code: '371'},
      {name: this.translate.instant('common.countries.liberia'), id: 'LR', dial_code: '231'},
      {name: this.translate.instant('common.countries.libya'), id: 'LY', dial_code: '218'},
      {name: this.translate.instant('common.countries.liechtenstein'), id: 'LI', dial_code: '423'},
      {name: this.translate.instant('common.countries.lithuania'), id: 'LT', dial_code: '370'},
      {name: this.translate.instant('common.countries.luxembourg'), id: 'LU', dial_code: '352'},
      {name: this.translate.instant('common.countries.man_island'), id: 'IM', dial_code: '44'},
      {name: this.translate.instant('common.countries.maldivas_island'), id: 'MV', dial_code: '960'},
      {name: this.translate.instant('common.countries.malvinas_island'), id: 'FK', dial_code: '500'},
      {name: this.translate.instant('common.countries.mariana_island'), id: 'MP', dial_code: '670'},
      {name: this.translate.instant('common.countries.marshall_island'), id: 'MH', dial_code: '692'},
      {name: this.translate.instant('common.countries.mexico'), id: 'MX', dial_code: '52'},
      {name: this.translate.instant('common.countries.monaco'), id: 'MC', dial_code: '377'},
      {name: this.translate.instant('common.countries.macao'), id: 'MO', dial_code: '853'},
      {name: this.translate.instant('common.countries.macedonia'), id: 'MK', dial_code: '389'},
      {name: this.translate.instant('common.countries.madagascar'), id: 'MG', dial_code: '261'},
      {name: this.translate.instant('common.countries.malaysia'), id: 'MY', dial_code: '60'},
      {name: this.translate.instant('common.countries.malawi'), id: 'MW', dial_code: '265'},
      {name: this.translate.instant('common.countries.mali'), id: 'ML', dial_code: '223'},
      {name: this.translate.instant('common.countries.malta'), id: 'MT', dial_code: '356'},
      {name: this.translate.instant('common.countries.morocco'), id: 'MA', dial_code: '212'},
      {name: this.translate.instant('common.countries.martinique'), id: 'MQ', dial_code: '596'},
      {name: this.translate.instant('common.countries.mauritius'), id: 'MU', dial_code: '230'},
      {name: this.translate.instant('common.countries.mauritania'), id: 'MR', dial_code: '222'},
      {name: this.translate.instant('common.countries.mayotte'), id: 'YT', dial_code: '262'},
      {name: this.translate.instant('common.countries.moldova'), id: 'MD', dial_code: '373'},
      {name: this.translate.instant('common.countries.mongolia'), id: 'MN', dial_code: '976'},
      {name: this.translate.instant('common.countries.montenegro'), id: 'ME', dial_code: '382'},
      {name: this.translate.instant('common.countries.montserrat'), id: 'MS', dial_code: '664'},
      {name: this.translate.instant('common.countries.mozambique'), id: 'MZ', dial_code: '258'},
      {name: this.translate.instant('common.countries.navidad_island'), id: 'CX', dial_code: '61'},
      {name: this.translate.instant('common.countries.namibia'), id: 'NA', dial_code: '264'},
      {name: this.translate.instant('common.countries.nauru'), id: 'NR', dial_code: '674'},
      {name: this.translate.instant('common.countries.nepal'), id: 'NP', dial_code: '977'},
      {name: this.translate.instant('common.countries.netherlands_antilles'), id: 'AN', dial_code: '599'},
      {name: this.translate.instant('common.countries.new_caledonia'), id: 'NC', dial_code: '687'},
      {name: this.translate.instant('common.countries.netherlands'), id: 'NL', dial_code: '31'},
      {name: this.translate.instant('common.countries.new_zelanda'), id: 'NZ', dial_code: '64'},
      {name: this.translate.instant('common.countries.nicaragua'), id: 'NI', dial_code: '505'},
      {name: this.translate.instant('common.countries.niger'), id: 'NE', dial_code: '227'},
      {name: this.translate.instant('common.countries.nigeria'), id: 'NG', dial_code: '234'},
      {name: this.translate.instant('common.countries.norfolk_island'), id: 'NF', dial_code: '672'},
      {name: this.translate.instant('common.countries.north_korea'), id: 'KP', dial_code: '850'},
      {name: this.translate.instant('common.countries.norway'), id: 'NO', dial_code: '47'},
      {name: this.translate.instant('common.countries.oman'), id: 'OM', dial_code: '968'},
      {name: this.translate.instant('common.countries.pakistan'), id: 'PK', dial_code: '92'},
      {name: this.translate.instant('common.countries.palau'), id: 'PW', dial_code: '680'},
      {name: this.translate.instant('common.countries.palestine'), id: 'PS', dial_code: '970'},
      {name: this.translate.instant('common.countries.panama'), id: 'PA', dial_code: '507'},
      {name: this.translate.instant('common.countries.papua_new_guinea'), id: 'PG', dial_code: '675'},
      {name: this.translate.instant('common.countries.paraguay'), id: 'PY', dial_code: '595'},
      {name: this.translate.instant('common.countries.peru'), id: 'PE', dial_code: '51'},
      {name: this.translate.instant('common.countries.philippines'), id: 'PH', dial_code: '63'},
      {name: this.translate.instant('common.countries.poland'), id: 'PL', dial_code: '48'},
      {name: this.translate.instant('common.countries.portugal'), id: 'PT', dial_code: '351'},
      {name: this.translate.instant('common.countries.puerto_rico'), id: 'PR', dial_code: '1'},
      {name: this.translate.instant('common.countries.qatar'), id: 'QA', dial_code: '974'},
      {name: this.translate.instant('common.countries.reunion'), id: 'RE', dial_code: '262'},
      {name: this.translate.instant('common.countries.rwanda'), id: 'RW', dial_code: '250'},
      {name: this.translate.instant('common.countries.romania'), id: 'RO', dial_code: '40'},
      {name: this.translate.instant('common.countries.russia'), id: 'RU', dial_code: '7'},
      {name: this.translate.instant('common.countries.salomon_island'), id: 'SB', dial_code: '677'},
      {name: this.translate.instant('common.countries.samoa'), id: 'WS', dial_code: '685'},
      {name: this.translate.instant('common.countries.american_samoa'), id: 'AS', dial_code: '684'},
      {name: this.translate.instant('common.countries.saint_bartolome'), id: 'BL', dial_code: '590'},
      {name: this.translate.instant('common.countries.saint_cristobal'), id: 'KN', dial_code: '869'},
      {name: this.translate.instant('common.countries.saint_marino'), id: 'SM', dial_code: '378'},
      {name: this.translate.instant('common.countries.saint_martin'), id: 'MF', dial_code: '599'},
      {name: this.translate.instant('common.countries.saint_pedro'), id: 'PM', dial_code: '508'},
      {name: this.translate.instant('common.countries.saint_vicente'), id: 'VC', dial_code: '784'},
      {name: this.translate.instant('common.countries.saint_lucia'), id: 'LC', dial_code: '758'},
      {name: this.translate.instant('common.countries.saint_tome'), id: 'ST', dial_code: '239'},
      {name: this.translate.instant('common.countries.saudi_arabia'), id: 'SA', dial_code: '966'},
      {name: this.translate.instant('common.countries.senegal'), id: 'SN', dial_code: '221'},
      {name: this.translate.instant('common.countries.serbia'), id: 'RS', dial_code: '381'},
      {name: this.translate.instant('common.countries.seychelles'), id: 'SC', dial_code: '248'},
      {name: this.translate.instant('common.countries.sierra_leone'), id: 'SL', dial_code: '232'},
      {name: this.translate.instant('common.countries.singapore'), id: 'SG', dial_code: '65'},
      {name: this.translate.instant('common.countries.sint_maarten'), id: 'SX', dial_code: '534'},
      {name: this.translate.instant('common.countries.slovakia'), id: 'SK', dial_code: '421'},
      {name: this.translate.instant('common.countries.slovenia'), id: 'SI', dial_code: '386'},
      {name: this.translate.instant('common.countries.syria'), id: 'SY', dial_code: '963'},
      {name: this.translate.instant('common.countries.somalia'), id: 'SO', dial_code: '252'},
      {name: this.translate.instant('common.countries.sri_lanka'), id: 'LK', dial_code: '94'},
      {name: this.translate.instant('common.countries.south_africa'), id: 'ZA', dial_code: '27'},
      {name: this.translate.instant('common.countries.south_korea'), id: 'KR', dial_code: '82'},
      {name: this.translate.instant('common.countries.spain'), id: 'ES', dial_code: '34'},
      {name: this.translate.instant('common.countries.sudan'), id: 'SD', dial_code: '249'},
      {name: this.translate.instant('common.countries.sweden'), id: 'SE', dial_code: '46'},
      {name: this.translate.instant('common.countries.switzerland'), id: 'CH', dial_code: '41'},
      {name: this.translate.instant('common.countries.suriname'), id: 'SR', dial_code: '597'},
      {name: this.translate.instant('common.countries.swaziland'), id: 'SZ', dial_code: '268'},
      {name: this.translate.instant('common.countries.tajikistan'), id: 'TJ', dial_code: '992'},
      {name: this.translate.instant('common.countries.thailand'), id: 'TH', dial_code: '66'},
      {name: this.translate.instant('common.countries.taiwan'), id: 'TW', dial_code: '886'},
      {name: this.translate.instant('common.countries.tanzania'), id: 'TZ', dial_code: '255'},
      {name: this.translate.instant('common.countries.timor'), id: 'TL', dial_code: '670'},
      {name: this.translate.instant('common.countries.togo'), id: 'TG', dial_code: '228'},
      {name: this.translate.instant('common.countries.tokelau'), id: 'TK', dial_code: '690'},
      {name: this.translate.instant('common.countries.tonga'), id: 'TO', dial_code: '676'},
      {name: this.translate.instant('common.countries.trinidad_tobago'), id: 'TT', dial_code: '868'},
      {name: this.translate.instant('common.countries.tunissia'), id: 'TN', dial_code: '216'},
      {name: this.translate.instant('common.countries.turkmenistan'), id: 'TM', dial_code: '993'},
      {name: this.translate.instant('common.countries.turkey'), id: 'TR', dial_code: '90'},
      {name: this.translate.instant('common.countries.turks_island'), id: 'TC', dial_code: '649'},
      {name: this.translate.instant('common.countries.tuvalu'), id: 'TV', dial_code: '688'},
      {name: this.translate.instant('common.countries.united_kingdom'), id: 'GB', dial_code: '44'},
      {name: this.translate.instant('common.countries.ukraine'), id: 'UA', dial_code: '380'},
      {name: this.translate.instant('common.countries.uk_virgin_islands'), id: 'VG', dial_code: '284'},
      {name: this.translate.instant('common.countries.united_arab_emirates'), id: 'AE', dial_code: '971'},
      {name: this.translate.instant('common.countries.united_states'), id: 'US', dial_code: '1'},
      {name: this.translate.instant('common.countries.united_states_minor_outlying_islands'), id: 'UM', dial_code: '581'},
      {name: this.translate.instant('common.countries.united_states_virgin_islands'), id: 'VI', dial_code: '340'},
      {name: this.translate.instant('common.countries.uganda'), id: 'UG', dial_code: '256'},
      {name: this.translate.instant('common.countries.uruguay'), id: 'UY', dial_code: '598'},
      {name: this.translate.instant('common.countries.uzbekistan'), id: 'UZ', dial_code: '998'},
      {name: this.translate.instant('common.countries.vanuatu'), id: 'VU', dial_code: '678'},
      {name: this.translate.instant('common.countries.vatican_city_state'), id: 'VA', dial_code: '39'},
      {name: this.translate.instant('common.countries.venezuela'), id: 'VE', dial_code: '58'},
      {name: this.translate.instant('common.countries.vietnam'), id: 'VN', dial_code: '84'},
      {name: this.translate.instant('common.countries.wallis'), id: 'WF', dial_code: '681'},
      {name: this.translate.instant('common.countries.western_sahara'), id: 'EH', dial_code: '212'},
      {name: this.translate.instant('common.countries.yemen'), id: 'YE', dial_code: '967'},
      {name: this.translate.instant('common.countries.zambia'), id: 'ZM', dial_code: '260'},
      {name: this.translate.instant('common.countries.zimbawe'), id: 'ZW', dial_code: '263'}
    ],
    campaign_plan_status: [
      {id: 'draft', name: this.translate.instant('resources.campaign_plans.status.draft'), css_class: 'label label-default font-size-12'},
      {id: 'error', name: this.translate.instant('resources.campaign_plans.status.error'), css_class: 'label label-danger font-size-12'},
      {id: 'completed', name: this.translate.instant('resources.campaign_plans.status.completed'), css_class: 'label label-success font-size-12'},
      {id: 'sending', name: this.translate.instant('resources.campaign_plans.status.sending'), css_class: 'label label-info font-size-12'},
      {id: 'delivered', name: this.translate.instant('resources.campaign_plans.status.delivered'), css_class: 'label label-success font-size-12'},
      {id: 'rejected', name: this.translate.instant('resources.campaign_plans.status.rejected'), css_class: 'label label-danger font-size-12'}
    ],
    plan_status: [
      {id: 'draft', name: this.translate.instant('resources.plans.status.draft'), css_class: 'label label-default font-size-12'},
      {id: 'error', name: this.translate.instant('resources.plans.status.error'), css_class: 'label label-danger font-size-12'},
      {id: 'scheduled', name: this.translate.instant('resources.plans.status.scheduled'), css_class: 'label label-warning font-size-12'},
      {id: 'sending', name: this.translate.instant('resources.plans.status.sending'), css_class: 'label label-info font-size-12'},
      {id: 'delivered', name: this.translate.instant('resources.plans.status.delivered'), css_class: 'label label-success font-size-12'}
    ],
    campaigns_vias: [
      {
        id: undefined,
        name: this.translate.instant('resources.campaigns.channels.all')},
      {
        id: 'email',
        name: this.translate.instant('resources.campaigns.channels.email'),
        icon: 'ei-email'
      },
      {
        id: 'sms',
        name: this.translate.instant('resources.campaigns.channels.sms'),
        icon: 'ei-speech-box-text'
      },
      {
        id: 'none',
        name: this.translate.instant('resources.campaigns.channels.ticket_coupon'),
        icon: 'ei-percentage'
      },
      {
        id: 'push',
        name: this.translate.instant('resources.campaigns.channels.push'),
        icon: 'ei-smartphone-vibrate'
      },
      {
        id: 'ticket_message',
        name: this.translate.instant('resources.campaigns.channels.ticket_message'),
        icon: 'ei-discount'
      }
    ],
    notification_types: [
      {id: 'info', name: this.translate.instant('resources.notifications.types.info'), css_class: 'label label-info font-size-12'},
      {id: 'success', name: this.translate.instant('resources.notifications.types.success'), css_class: 'label label-success font-size-12'},
      {id: 'warning', name: this.translate.instant('resources.notifications.types.warning'), css_class: 'label label-warning font-size-12'},
      {id: 'error', name: this.translate.instant('resources.notifications.types.error'), css_class: 'label label-danger font-size-12'}
    ],
    campaign_status: [
      {id: 'delivered', name: this.translate.instant('resources.campaigns.status.delivered'), css_class: 'label label-success font-size-12'},
      {id: 'sending', name: this.translate.instant('resources.campaigns.status.sending'), css_class: 'label label-primary font-size-12'},
      {id: 'enqueued', name: this.translate.instant('resources.campaigns.status.enqueued'), css_class: 'label label-info font-size-12'},
      {id: 'error', name: this.translate.instant('resources.campaigns.status.error'), css_class: 'label label-danger font-size-12'},
      {id: 'draft', name: this.translate.instant('resources.campaigns.status.draft'), css_class: 'label label-info font-size-12'},
      {id: 'scheduled', name: this.translate.instant('resources.campaigns.status.scheduled'), css_class: 'label label-primary font-size-12'},
      {id: 'checking', name: this.translate.instant('resources.campaigns.status.checking'), css_class: 'label label-warning font-size-12'}
    ],
    campaign_pending: [
      {id: 'true', value: 'true', name: null, css_class: 'fa fa-check font-size-24 text-success'},
      {id: 'false', value: 'false', name: null, css_class: 'fa fa-times font-size-24 text-danger'},
    ],
    campaign_motive: [
      {id: undefined, name: this.translate.instant('resources.campaigns.via.all')},
      {id: 'informative', name: this.translate.instant('resources.campaigns.motive.informative'), css_class: 'font-size-12 label label-success'},
      {id: 'coupon', name: this.translate.instant('resources.campaigns.motive.ticket_coupon'), css_class: 'font-size-12 label label-info'},
      {id: 'message', name: this.translate.instant('resources.campaigns.motive.ticket_message'), css_class: 'font-size-12 label label-warning'}
    ],
    campaign_histories_motives: [
      {id: undefined, name: '-'},
      {id: null, name: '-'},
      {id: 'informative', name: this.translate.instant('resources.campaigns.motive.informative'), css_class: 'font-size-12 label label-info'},
      {id: 'coupon', name: this.translate.instant('resources.campaigns.channels.ticket_coupon'), css_class: 'font-size-12 label label-primary'},
      {id: 'message', name: this.translate.instant('resources.campaigns.channels.ticket_message'), css_class: 'font-size-12 label label-warning'}
    ],
    campaign_templates_custom_variables: [
      { name: this.translate.instant('resources.campaigns.content_variables.name'), value: '{name}' },
      { name: this.translate.instant('resources.campaigns.content_variables.surname_1'), value: '{surname_1}' },
      { name: this.translate.instant('resources.campaigns.content_variables.surname_2'), value: '{surname_2}' },
      { name: this.translate.instant('resources.campaigns.content_variables.mobile_phone'), value: '{phone}' },
      { name: this.translate.instant('resources.campaigns.content_variables.email'), value: '{email}' },
      { name: this.translate.instant('resources.campaigns.content_variables.address'), value: '{address}' },
      { name: this.translate.instant('resources.campaigns.content_variables.city'), value: '{city}' },
      { name: this.translate.instant('resources.campaigns.content_variables.postal_code'), value: '{postal_code}' },
      { name: this.translate.instant('resources.campaigns.content_variables.client_code'), value: '{code}' },
      { name: this.translate.instant('resources.campaigns.content_variables.points'), value: '{score}' },
      { name: this.translate.instant('resources.campaigns.content_variables.usual_store'), value: '{shop}' },
      { name: this.translate.instant('resources.campaigns.content_variables.registration_store_name'), value: '{RegistrationLocationName}' },
      { name: this.translate.instant('resources.campaigns.content_variables.coupon_name'), value: '{CouponName}' },
      { name: this.translate.instant('resources.campaigns.content_variables.coupon_code'), value: '{couponCode}' },
      { name: this.translate.instant('resources.campaigns.content_variables.coupon_available_to'), value: '{CouponAvailableTo}' },
      { name: this.translate.instant('resources.campaigns.content_variables.coupon_available_from'), value: '{CouponAvailableFrom}' },
      { name: this.translate.instant('resources.campaigns.content_variables.coupon_discount_type'), value: '{CouponDiscountType}'},
      { name: this.translate.instant('resources.campaigns.content_variables.coupon_discount'), value: '{CouponDiscount}'},
      { name: this.translate.instant('resources.campaigns.content_variables.coupon_minimum_money'), value: '{CouponMinimumMoney}'},
      { name: this.translate.instant('resources.campaigns.content_variables.coupon_maximum_money'), value: '{CouponMaximumMoney}'},
      { name: this.translate.instant('resources.campaigns.content_variables.coupon_minimum_units'), value: '{CouponMinimumUnits}'},
      { name: this.translate.instant('resources.campaigns.content_variables.coupon_maximum_units'), value: '{CouponMaximumUnits}'},
      { name: this.translate.instant('resources.campaigns.content_variables.coupon_maximum_redemptions'), value: '{CouponMaximumRedemptions}'},
      { name: this.translate.instant('resources.campaigns.content_variables.custom_1'), value: '{custom1}'}
    ],
    dynamic_dates: [
      { id: null, name: this.translate.instant('components.dynamic-date-input.dynamic-dates.no_date'), separatorAfter: true, timeFrame: null },
      { id: '%3_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.days_3'), timeFrame: 'past' },
      { id: '%5_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.days_5'), timeFrame: 'past' },
      { id: '%7_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.days_7'), timeFrame: 'past' },
      { id: '%14_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.days_14'), timeFrame: 'past' },
      { id: '%15_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.days_15'), timeFrame: 'past' },
      { id: '%21_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.days_21'), timeFrame: 'past' },
      { id: '%28_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.days_28'), timeFrame: 'past' },
      { id: '%30_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_1'), timeFrame: 'past' },
      { id: '%31_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.31_days_ago'), timeFrame: 'past' },
      { id: '%60_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_2'), timeFrame: 'past' },
      { id: '%90_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_3'), timeFrame: 'past' },
      { id: '%91_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.91_days_ago'), timeFrame: 'past' },
      { id: '%120_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_4'), timeFrame: 'past' },
      { id: '%150_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_5'), timeFrame: 'past' },
      { id: '%180_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_6'), timeFrame: 'past' },
      { id: '%181_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.181_days_ago'), timeFrame: 'past' },
      { id: '%365_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_12'), timeFrame: 'past' },
      { id: '%366_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.366_days_ago'), separatorAfter: true, timeFrame: 'past' },
      { id: '%today%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.today'), timeFrame: 'present' },
      { id: '%yesterday%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.yesterday'), timeFrame: 'past' },
      { id: '%in_7_days%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.in_7_days'), timeFrame: 'future' },
      { id: '%in_15_days%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.in_15_days'), timeFrame: 'future' },
      { id: '%next_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.next_month'), separatorAfter: true, timeFrame: 'future' },
      { id: '%current_period_at_beginning_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_actual_period'), timeFrame: 'past' },
      { id: '%current_period_at_end_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_actual_period'), separatorAfter: true, timeFrame: 'future' },
      { id: '%1_periods_ago_at_beginning_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_1_period_ago'), timeFrame: 'past' },
      { id: '%1_periods_ago_at_end_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_1_period_ago'), separatorAfter: true , timeFrame: 'past' },
      { id: '%2_periods_ago_at_beginning_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_2_period_ago'), timeFrame: 'past' },
      { id: '%2_periods_ago_at_end_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_2_period_ago'), separatorAfter: true , timeFrame: 'past' },
      { id: '%3_periods_ago_at_beginning_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_3_period_ago'), timeFrame: 'past' },
      { id: '%3_periods_ago_at_end_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_3_period_ago'), separatorAfter: true , timeFrame: 'past' },
      { id: '%4_periods_ago_at_beginning_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_4_period_ago'), timeFrame: 'past' },
      { id: '%4_periods_ago_at_end_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_4_period_ago'), separatorAfter: true , timeFrame: 'past' },
      { id: '%5_periods_ago_at_beginning_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_5_period_ago'), timeFrame: 'past' },
      { id: '%5_periods_ago_at_end_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_5_period_ago'), separatorAfter: true , timeFrame: 'past' },
      { id: '%6_periods_ago_at_beginning_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_6_period_ago'), timeFrame: 'past' },
      { id: '%6_periods_ago_at_end_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_6_period_ago'), separatorAfter: true , timeFrame: 'past' },
      { id: '%7_periods_ago_at_beginning_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_7_period_ago'), timeFrame: 'past' },
      { id: '%7_periods_ago_at_end_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_7_period_ago'), separatorAfter: true , timeFrame: 'past' },
      { id: '%8_periods_ago_at_beginning_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_8_period_ago'), timeFrame: 'past' },
      { id: '%8_periods_ago_at_end_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_8_period_ago'), separatorAfter: true , timeFrame: 'past' },
      { id: '%9_periods_ago_at_beginning_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_9_period_ago'), timeFrame: 'past' },
      { id: '%9_periods_ago_at_end_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_9_period_ago'), separatorAfter: true , timeFrame: 'past' },
      { id: '%10_periods_ago_at_beginning_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_10_period_ago'), timeFrame: 'past' },
      { id: '%10_periods_ago_at_end_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_10_period_ago'), separatorAfter: true , timeFrame: 'past' },
      { id: '%11_periods_ago_at_beginning_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_11_period_ago'), timeFrame: 'past' },
      { id: '%11_periods_ago_at_end_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_11_period_ago'), separatorAfter: true , timeFrame: 'past' },
      { id: '%12_periods_ago_at_beginning_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_12_period_ago'), timeFrame: 'past' },
      { id: '%12_periods_ago_at_end_of_the_period%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_12_period_ago'), separatorAfter: true, timeFrame: 'past' },
      { id: '%1_months_ago_at_beginning_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_1_month_ago'), timeFrame: 'past' },
      { id: '%2_months_ago_at_beginning_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_2_month_ago'), timeFrame: 'past' },
      { id: '%3_months_ago_at_beginning_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_3_month_ago'), timeFrame: 'past' },
      { id: '%4_months_ago_at_beginning_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_4_month_ago'), timeFrame: 'past' },
      { id: '%5_months_ago_at_beginning_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_5_month_ago'), timeFrame: 'past' },
      { id: '%6_months_ago_at_beginning_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_6_month_ago'), timeFrame: 'past' },
      { id: '%7_months_ago_at_beginning_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_7_month_ago'), timeFrame: 'past' },
      { id: '%8_months_ago_at_beginning_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_8_month_ago'), timeFrame: 'past' },
      { id: '%9_months_ago_at_beginning_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_9_month_ago'), timeFrame: 'past' },
      { id: '%10_months_ago_at_beginning_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_10_month_ago'), timeFrame: 'past' },
      { id: '%11_months_ago_at_beginning_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_11_month_ago'), timeFrame: 'past' },
      { id: '%12_months_ago_at_beginning_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_12_month_ago'), timeFrame: 'past' },
      { id: '%1_months_ago_at_end_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_1_month_ago'), timeFrame: 'past' },
      { id: '%2_months_ago_at_end_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_2_month_ago'), timeFrame: 'past' },
      { id: '%3_months_ago_at_end_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_3_month_ago'), timeFrame: 'past' },
      { id: '%4_months_ago_at_end_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_4_month_ago'), timeFrame: 'past' },
      { id: '%5_months_ago_at_end_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_5_month_ago'), timeFrame: 'past' },
      { id: '%6_months_ago_at_end_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_6_month_ago'), timeFrame: 'past' },
      { id: '%7_months_ago_at_end_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_7_month_ago'), timeFrame: 'past' },
      { id: '%8_months_ago_at_end_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_8_month_ago'), timeFrame: 'past' },
      { id: '%9_months_ago_at_end_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_9_month_ago'), timeFrame: 'past' },
      { id: '%10_months_ago_at_end_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_10_month_ago'), timeFrame: 'past' },
      { id: '%11_months_ago_at_end_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_11_month_ago'), timeFrame: 'past' },
      { id: '%12_months_ago_at_end_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_12_month_ago'), separatorAfter: true, timeFrame: 'past' },
      { id: '%current_month_at_beginning_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_actual_month'), timeFrame: 'past' },
      { id: '%current_month_at_end_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_actual_month'), timeFrame: 'future' },
      { id: '%next_month_at_beginning_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.first_day_next_month'), timeFrame: 'future' },
      { id: '%next_month_at_end_of_the_month%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.last_day_next_month'), timeFrame: 'future' }
    ],
    dynamic_dates_minutes: [
      { id: '%10_minutes_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.minutes_10'), timeFrame: 'past' },
      { id: '%30_minutes_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.minutes_30'), timeFrame: 'past' },
      { id: '%1_hours_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.hours_1'), timeFrame: 'past' },
      { id: '%2_hours_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.hours_2'), timeFrame: 'past' },
      { id: '%3_hours_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.hours_3'), timeFrame: 'past' },
      { id: '%4_hours_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.hours_4'), timeFrame: 'past', separatorAfter: true },
    ],
    journeys_type: [
      {id: undefined, name: this.translate.instant('resources.journeys.type.all')},
      {id: 'CampaignBlock::Custom', name: this.translate.instant('resources.journeys.type.custom')},
      {id: 'CampaignBlock::Potencial', name: this.translate.instant('resources.journeys.type.potential')},
      {id: 'CampaignBlock::Recuperation', name: this.translate.instant('resources.journeys.type.recuperation')},
      {id: 'CampaignBlock::MultiCoupon', name: this.translate.instant('resources.journeys.type.multicoupon')}
    ],
    journey_history_status: [
      {id: 'cancelled', name: this.translate.instant('resources.journey_histories.status.cancelled'), css_class: 'font-size-12 label label-danger'},
      {id: 'done', name: this.translate.instant('resources.journey_histories.status.done'), css_class: 'font-size-12 label label-success'},
      {id: 'error', name: this.translate.instant('resources.journey_histories.status.error'), css_class: 'font-size-12 label label-danger'},
      {id: 'paused', name: this.translate.instant('resources.journey_histories.status.paused'), css_class: 'font-size-12 label label-warning'},
      {id: 'running_step', name: this.translate.instant('resources.journey_histories.status.running_step'), css_class: 'font-size-12 label label-info'},
      {id: 'waiting_step', name: this.translate.instant('resources.journey_histories.status.waiting_step'), css_class: 'font-size-12 label label-primary'}
    ],
    recipes_models: [
      {id: 'after_create_activity', name: this.translate.instant('resources.marketing_automation.when.after_create_activity')},
      {id: 'after_first_login', name: this.translate.instant('resources.marketing_automation.when.after_first_login')},
      {id: 'after_create_voucher', name: this.translate.instant('resources.marketing_automation.when.after_create_voucher')},
      {id: 'after_create_customer', name: this.translate.instant('resources.marketing_automation.when.after_create_customer')}
    ],
    recipes_actions: [
      {id: 'push', name: this.translate.instant('resources.marketing_automation.actions.push')},
      {id: 'coupon', name: this.translate.instant('resources.marketing_automation.actions.coupon')}
    ],
    journeys_status: [
      {id: 'paused', name: this.translate.instant('resources.journeys.status.paused'), css_class: 'font-size-12 label label-warning'},
      {id: 'error', name: this.translate.instant('resources.journeys.status.error'), css_class: 'font-size-12 label label-danger'},
      {id: 'done', name: this.translate.instant('resources.journeys.status.done'), css_class: 'font-size-12 label label-success'},
      {id: 'waiting_step', name: this.translate.instant('resources.journeys.status.waiting_step'), css_class: 'font-size-12 label label-info'},
    ],
    vouchers_status: [
      {id: 'deleted', name: this.translate.instant('resources.vouchers.states.deleted'), css_class: 'font-size-12 label label-danger'},
      {id: 'expired', name: this.translate.instant('resources.vouchers.states.expired'), css_class: 'font-size-12 label label-warning'},
      {id: 'pending', name: this.translate.instant('resources.vouchers.states.pending'), css_class: 'font-size-12 label label-info'},
      {id: 'freezed', name: this.translate.instant('resources.vouchers.states.freezed'), css_class: 'font-size-12 label label-info'},
      {id: 'unfreezed', name: this.translate.instant('resources.vouchers.states.unfreezed'), css_class: 'font-size-12 label label-info'},
      {id: 'printed', name: this.translate.instant('resources.vouchers.states.printed'), css_class: 'font-size-12 label label-warning'},
      {id: 'redeemed', name: this.translate.instant('resources.vouchers.states.redeemed'), css_class: 'font-size-12 label label-success'},
      {id: 'soldout', name: this.translate.instant('resources.vouchers.states.soldout'), css_class: 'font-size-12 label label-success'},
      {id: 'selected', name: this.translate.instant('resources.vouchers.states.selected'), css_class: 'font-size-12 label label-primary'},
      {id: 'viewed', name: this.translate.instant('resources.vouchers.states.viewed'), css_class: 'font-size-12 label label-primary'},
      {id: 'cancelled', name: this.translate.instant('resources.vouchers.states.cancelled'), css_class: 'font-size-12 label label-danger'},
    ],
    location_status: [
      {id: 'active', name: this.translate.instant('resources.locations.status.active'), css_class: 'font-size-12 label label-success'},
      {id: 'inactive', name: this.translate.instant('resources.locations.status.inactive'), css_class: 'font-size-12 label label-danger'}
    ],
    products_status: [
      {id: 'active', name: this.translate.instant('resources.products.status.active'), css_class: 'font-size-12 label label-success'},
      {id: 'inactive', name: this.translate.instant('resources.products.status.inactive'), css_class: 'font-size-12 label label-danger'}
    ],
    products_origin_features: [
      {id: 'internal', name: this.translate.instant('resources.product_taxonomies.origin.internal')},
      {id: 'external', name: this.translate.instant('resources.product_taxonomies.origin.external')},
    ],
    products_taxonomies: [
      {id: 'true', name: this.translate.instant('resources.product_taxonomies.columns.main'), icon: 'ei ei-checked text-success'},
      {id: 'none', name: '', icon: ''}
    ],
    promotions_status: [
      {id: 'true', name: this.translate.instant('resources.promotions.status.active'), css_class: 'font-size-12 label label-success'},
      {id: 'false', name: this.translate.instant('resources.promotions.status.inactive'), css_class: 'font-size-12 label label-danger'}
    ],
    synchros_status: [
      {id: 'completed', name: this.translate.instant('resources.synchros.status.completed')},
    ],
    credits_actions: [
      {id: 'in', name: this.translate.instant('resources.credits.actions.in'), css_class: 'font-size-12 label label-success'},
      {id: 'out', name: this.translate.instant('resources.credits.actions.out'), css_class: 'font-size-12 label label-danger'},
      {id: 'hold', name: this.translate.instant('resources.credits.actions.hold'), css_class: 'font-size-12 label label-warning'},
      {id: 'release', name: this.translate.instant('resources.credits.actions.release'), css_class: 'font-size-12 label label-primary'}
    ],
    credits_types: [
      {id: 'sms', name: this.translate.instant('resources.credits.types.sms')},
      {id: 'email', name: this.translate.instant('resources.credits.types.email')},
    ],
    jobs_status: [
      {id: 'pending', name: this.translate.instant('resources.jobs.status.pending'), css_class: 'font-size-12 label label-info'},
      {id: 'success', name: this.translate.instant('resources.jobs.status.success'), css_class: 'font-size-12 label label-success'},
      {id: 'error', name: this.translate.instant('resources.jobs.status.error'), css_class: 'font-size-12 label label-danger'},
      {id: 'processing', name: this.translate.instant('resources.jobs.status.processing'), css_class: 'font-size-12 label label-warning'},
    ],
    execution_jobs: [
      {id: 'biannually', name: this.translate.instant('resources.jobs.execution.biannually')},
      {id: 'daily', name: this.translate.instant('resources.jobs.execution.daily')},
      {id: 'fortnightly', name: this.translate.instant('resources.jobs.execution.fortnightly')},
      {id: 'hourly', name: this.translate.instant('resources.jobs.execution.hourly')},
      {id: 'manual', name: this.translate.instant('resources.jobs.execution.manual')},
      {id: 'monthly', name: this.translate.instant('resources.jobs.execution.monthly')},
      {id: 'quarterly', name: this.translate.instant('resources.jobs.execution.quarterly')},
      {id: 'weekly', name: this.translate.instant('resources.jobs.execution.weekly')}
    ],
    periods_status: [
      {id: 'done', name: this.translate.instant('resources.periods.status.done'), css_class: 'font-size-12 label label-success'},
      {id: 'pending', name: this.translate.instant('resources.periods.status.pending'), css_class: 'font-size-12 label label-warning'},
      {id: 'validation', name: this.translate.instant('resources.periods.status.validation'), css_class: 'font-size-12 label label-info'},
      {id: 'validated', name: this.translate.instant('resources.periods.status.validated'), css_class: 'font-size-12 label label-primary'},
      {id: 'manual_validated', name: this.translate.instant('resources.periods.status.manual_validated'), css_class: 'font-size-12 label label-primary'},
      {id: 'calculations', name: this.translate.instant('resources.periods.status.calculations'), css_class: 'font-size-12 label label-info'},
      {id: 'tag_categories', name: this.translate.instant('resources.periods.status.tag_categories'), css_class: 'font-size-12 label label-info'},
      {id: 'checking', name: this.translate.instant('resources.periods.status.checking'), css_class: 'font-size-12 label label-info'},
      {id: 'error', name: this.translate.instant('resources.periods.status.error'), css_class: 'font-size-12 label label-danger'},
      {id: 'warning', name: this.translate.instant('resources.periods.status.warning'), css_class: 'font-size-12 label label-warning'},
      {id: 'rollback', name: this.translate.instant('resources.periods.status.rollback'), css_class: 'font-size-12 label label-default'}
    ],
    versions_actions: [
      {id: 'update', name: this.translate.instant('resources.versions.actions.update')},
      {id: 'create', name: this.translate.instant('resources.versions.actions.create')},
      {id: 'login', name: this.translate.instant('resources.versions.actions.login')},
      {id: 'logout', name: this.translate.instant('resources.versions.actions.logout')},
      {id: 'destroy', name: this.translate.instant('resources.versions.actions.destroy')}
    ],
    free_reserved_ids: [
      {id: 'true', name: this.translate.instant('resources.reserved_ids.row_column.is_free'), icon: 'fa fa-check text-success'},
      {id: 'none', name: this.translate.instant('resources.reserved_ids.row_column.not_free'), icon: 'fa fa-times text-danger'}
    ],
    months: [
      { id: 1, name: this.translate.instant('common.months.january'), short_name: this.translate.instant('common.months.january_short'), key: 'january' },
      { id: 2, name: this.translate.instant('common.months.february'), short_name: this.translate.instant('common.months.february_short'), key: 'february' },
      { id: 3, name: this.translate.instant('common.months.march'), short_name: this.translate.instant('common.months.march_short'), key: 'march' },
      { id: 4, name: this.translate.instant('common.months.april'), short_name: this.translate.instant('common.months.april_short'), key: 'april' },
      { id: 5, name: this.translate.instant('common.months.may'), short_name: this.translate.instant('common.months.may_short'), key: 'may' },
      { id: 6, name: this.translate.instant('common.months.june'), short_name: this.translate.instant('common.months.june_short'), key: 'june' },
      { id: 7, name: this.translate.instant('common.months.july'), short_name: this.translate.instant('common.months.july_short'), key: 'july' },
      { id: 8, name: this.translate.instant('common.months.august'), short_name: this.translate.instant('common.months.august_short'), key: 'august' },
      { id: 9, name: this.translate.instant('common.months.september'), short_name: this.translate.instant('common.months.september_short'), key: 'september' },
      { id: 10, name: this.translate.instant('common.months.october'), short_name: this.translate.instant('common.months.october_short'), key: 'october' },
      { id: 11, name: this.translate.instant('common.months.november'), short_name: this.translate.instant('common.months.november_short'), key: 'november' },
      { id: 12, name: this.translate.instant('common.months.december'), short_name: this.translate.instant('common.months.december_short'), key: 'december' }
    ],
    loyalty_scores_status_filter: [
      {id: '', name: this.translate.instant('common.all')},
      {id: 'true', name: this.translate.instant('common.enabled'), css_class: 'font-size-12 label label-success'},
      {id: 'false', name: this.translate.instant('common.disabled'), css_class: 'font-size-12 label label-danger'},
    ],
    loyalty_scores_status: [
      {id: 'true', name: this.translate.instant('common.enabled'), css_class: 'font-size-12 label label-success'},
      {id: 'false', name: this.translate.instant('common.disabled'), css_class: 'font-size-12 label label-danger'},
    ],
    scores_status: [
      {id: 'active', name: this.translate.instant('resources.scores.scores.status.active'), css_class: 'font-size-12 label label-success'},
      {id: 'inactive', name: this.translate.instant('resources.scores.scores.status.inactive'), css_class: 'font-size-12 label label-danger'}
    ],
    loyalty_score_moments_protected: [
      {id: 'true', name: this.translate.instant('resources.scores.loyalty_scores.status.active'), icon: 'fa fa-check text-success'},
      {id: 'none', name: this.translate.instant('resources.scores.loyalty_scores.status.inactive'), icon: 'fa fa-times text-danger'},
    ],
    score_expenses_status: [
      {id: 'redemption', name: this.translate.instant('resources.scores.score_expenses.status.redemption'), css_class: 'font-size-12 label label-success'},
      {id: 'transfer', name: this.translate.instant('resources.scores.score_expenses.status.transfer'), css_class: 'font-size-12 label label-info'},
      {id: 'return', name: this.translate.instant('resources.scores.score_expenses.status.return'), css_class: 'font-size-12 label label-warning'},
      {id: 'cashback', name: this.translate.instant('resources.scores.score_expenses.status.cashback'), css_class: 'font-size-12 label label-primary'},
      {id: 'expiration', name: this.translate.instant('resources.scores.score_expenses.status.expiration'), css_class: 'font-size-12 label label-danger'}
    ],
    rewards_status: [
      {id: 'active', name: this.translate.instant('common.enabled'), css_class: 'font-size-12 label label-success'},
      {id: 'inactive', name: this.translate.instant('common.disabled'), css_class: 'font-size-12 label label-danger'}
    ],
    partners_status: [
      {id: 'true', name: this.translate.instant('resources.scores.partners.status.active'), 'css_class': 'font-size-12 label label-success'},
      {id: 'false', name: this.translate.instant('resources.scores.partners.status.inactive'), 'css_class': 'font-size-12 label label-danger'},
    ],
    partners_type: [
      {id: 'endemic', name: this.translate.instant('resources.scores.partners.type.endemic')},
      {id: 'non_endemic', name: this.translate.instant('resources.scores.partners.type.non_endemic')}
    ],
    reward_code_batches_status: [
      {id: 'uploading', name: this.translate.instant('resources.scores.reward_code_batches.status.uploading'), css_class: 'font-size-12 label label-info'},
      {id: 'uploaded', name: this.translate.instant('resources.scores.reward_code_batches.status.uploaded'), css_class: 'font-size-12 label label-success'},
      {id: 'error', name: this.translate.instant('resources.scores.reward_code_batches.status.error'), css_class: 'font-size-12 label label-danger'}
    ],
    reward_code_batches_availability: [
      {id: 'true', name: this.translate.instant('resources.scores.reward_code_batches.status.available'), icon: 'fa fa-check text-success'},
      {id: 'false', name: this.translate.instant('resources.scores.reward_code_batches.status.unavailable'), icon: 'fa fa-check text-danger'},
    ],
    reward_taxonomies_origin: [
      {id: 'internal', name: this.translate.instant('resources.scores.reward_taxonomies.origin.internal')},
      {id: 'external', name: this.translate.instant('resources.scores.reward_taxonomies.origin.external')},
    ],
    system_status: [
      {id: 'online', name: this.translate.instant('resources.status.status.online'), icon: 'fa fa-check text-success'},
      {id: 'offline', name: this.translate.instant('resources.status.status.offline'), css_class: 'font-size-12 label label-danger'}
    ],
    logs_system_status: [
      { id: 'AmazonS3', name: this.translate.instant('resources.status.status.amazons3'), icon: 'ei-archive' },
      { id: 'AmazonSQS', name: this.translate.instant('resources.status.status.amazonsqs'), icon: 'ei-archive' },
      { id: 'Storage', name: this.translate.instant('resources.status.status.storage'), icon: 'ei-archive' },
      { id: 'Bigquery', name: this.translate.instant('resources.status.status.biguery'), icon: 'ei-archive' },
      { id: 'RedisCache', name: this.translate.instant('resources.status.status.redis'), icon: 'ei-archive' },
      { id: 'Sendgrid', name: this.translate.instant('resources.status.status.sendgrid'), icon: 'ei-archive' },
    ],
    coupons_status: [
      {id: 'true', name: this.translate.instant('resources.coupons.status.active'), css_class: 'font-size-12 label label-success'},
      {id: 'false', name: this.translate.instant('resources.coupons.status.inactive'), css_class: 'font-size-12 label label-danger'}
    ],
    vouchers_profile_type: [
      {id: 'Coupon', name: this.translate.instant('resources.vouchers.type.coupon')},
      {id: 'Message', name: this.translate.instant('resources.vouchers.type.message')}
    ],
    vouchers_table_type: [
      {id: 'coupon', name: this.translate.instant('resources.vouchers.type.coupon')},
      {id: 'message', name: this.translate.instant('resources.vouchers.type.message')}
    ],
    report_status: [
      {id: 'pending', name: this.translate.instant('resources.reports.status.pending'), css_class: 'font-size-12 label label-warning'},
      {id: 'refreshing', name: this.translate.instant('resources.reports.status.refreshing'), css_class: 'font-size-12 label label-primary'},
      {id: 'outdated', name: this.translate.instant('resources.reports.status.outdated'), css_class: 'font-size-12 label label-danger'},
      {id: 'error', name: this.translate.instant('resources.reports.status.error'), css_class: 'font-size-12 label label-danger'},
      {id: 'done', name: this.translate.instant('resources.reports.status.done'), css_class: 'font-size-12 label label-info'}
    ],
    profiles_status: [
      {id: 'active', name: this.translate.instant('common.status.active'), css_class: 'font-size-12 label label-success'},
      {id: 'inactive', name: this.translate.instant('common.status.inactive'), css_class: 'font-size-12 label label-danger'},
      {id: 'merged', name: this.translate.instant('common.status.merged'), css_class: 'font-size-12 label label-info'},
      {id: 'deleted', name: this.translate.instant('common.status.deleted'), css_class: 'font-size-12 label label-danger'}
    ],
    scores_transactions_type: [
      {id: 'activation', name: this.translate.instant('resources.scores.scores.types.activation'), css_class: 'font-size-12 label label-success'},
      {id: 'activity', name: this.translate.instant('resources.scores.scores.types.activity'), css_class: 'font-size-12 label label-success'},
      {id: 'challenge', name: this.translate.instant('resources.scores.scores.types.challenge'), css_class: 'font-size-12 label label-success'},
      {id: 'custom_entity', name: this.translate.instant('resources.scores.scores.types.custom_entity'), css_class: 'font-size-12 label label-success'},
      {id: 'expiration', name: this.translate.instant('resources.scores.scores.types.expiration'), css_class: 'font-size-12 label label-danger'},
      {id: 'reward', name: this.translate.instant('resources.scores.scores.types.reward'), css_class: 'font-size-12 label label-warning'},
      {id: 'score_payment', name: this.translate.instant('resources.scores.scores.types.score_payment'), css_class: 'font-size-12 label label-warning'},
      {id: 'subtract', name: this.translate.instant('resources.scores.scores.types.subtract'), css_class: 'font-size-12 label label-warning'},
      {id: 'transfer', name: this.translate.instant('resources.scores.scores.types.transfer'), css_class: 'font-size-12 label label-info'},
      {id: 'trigger', name: this.translate.instant('resources.scores.scores.types.trigger'), css_class: 'font-size-12 label label-success'}
    ],
    scores_generated_at: [
      {id: '', name: this.translate.instant('resources.scores.scores.historical.generated_lg'), css_class: 'font-size-12 label label-success'},
      {id: 'only_historical', name: this.translate.instant('resources.scores.scores.historical.generated_other'), css_class: 'font-size-12 label label-success'},
    ],
    synchros_histories: [
      {id: 'started', name: this.translate.instant('resources.synchros.status.started'), css_class: 'font-size-12 label label-info'},
      {id: 'pending', name: this.translate.instant('resources.synchros.status.pending'), css_class: 'font-size-12 label label-warning'},
      {id: 'error', name: this.translate.instant('resources.synchros.status.error'), css_class: 'font-size-12 label label-danger'},
      {id: 'completed', name: this.translate.instant('resources.synchros.status.completed'), css_class: 'font-size-12 label label-success'},
      {id: 'completed_with_errors', name: this.translate.instant('resources.synchros.status.completed_with_errors'), css_class: 'font-size-12 label label-warning'}
    ],
    forecast_custom_campaign: [
      { id: 1, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.segment')},
      { id: 2, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.impacted_customers')},
      { id: 3, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.max_coupon_redemptions')},
      { id: 4, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.coupons_delivered')},
      { id: 5, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.estimated_delivery')},
      { id: 6, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.total_redemptions')},
      { id: 7, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.estimated_redemption')},
      { id: 8, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.redemption_per_customer')},
      { id: 9, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.unique_redemptions')},
      { id: 10, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.discount')},
      { id: 11, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.min_purchase')},
      { id: 12, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.percent_discount')},
      { id: 13, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.discount_cost')},
      { id: 14, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.lg_cost')},
      { id: 15, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.incremental_sales')},
      { id: 16, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.incremental_ratio')},
      { id: 17, name: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.increase_total_sales')}
    ],
    historical_process_status: [
      {id: 'error', name: this.translate.instant('common.status.error'), css_class: 'font-size-12 label label-danger'},
      {id: 'running', name: this.translate.instant('common.status.executing'), css_class: 'font-size-12 label label-warning'},
      {id: 'started', name: this.translate.instant('common.status.executing'), css_class: 'font-size-12 label label-warning'},
      {id: 'getting_data', name: this.translate.instant('common.status.executing'), css_class: 'font-size-12 label label-warning'},
      {id: 'redeeming', name: this.translate.instant('common.status.executing'), css_class: 'font-size-12 label label-warning'},
      {id: 'pumping_data_done', name: this.translate.instant('common.status.executing'), css_class: 'font-size-12 label label-warning'},
      {id: 'creating_campaigns_done', name: this.translate.instant('common.status.executing'), css_class: 'font-size-12 label label-success'},
      {id: 'sending_campaigns_done', name: this.translate.instant('common.status.executing'), css_class: 'font-size-12 label label-success'},
      {id: 'success', name: this.translate.instant('common.status.finished'), css_class: 'font-size-12 label label-success'},
    ],
    export_scheduled: [
      {id: 'true', name: this.translate.instant('resources.scores.loyalty_scores.status.active'), icon: 'fa fa-check text-success'},
      {id: 'none', name: this.translate.instant('resources.scores.loyalty_scores.status.inactive'), icon: 'fa fa-times text-danger'},
    ],
    associates_main: [
      {id: 'true', name: this.translate.instant('resources.profiles.show.associates.main'), icon: 'fa fa-check text-success'},
      {id: 'none', name: this.translate.instant('resources.profiles.show.associates.main'), icon: ''}
    ],
    association_status: [
      {id: 'true', name: this.translate.instant('common.status.active'), css_class: 'font-size-12 label label-success'},
      {id: 'false', name: this.translate.instant('common.status.inactive'), css_class: 'font-size-12 label label-danger'}
    ],
    users_status: [
      {id: 'active', name: this.translate.instant('resources.users.status.active'), css_class: 'font-size-12 label label-success'},
      {id: 'inactive', name: this.translate.instant('resources.users.status.inactive'), css_class: 'font-size-12 label label-danger'},
      {id: 'blocked', name: this.translate.instant('resources.users.status.blocked'), css_class: 'font-size-12 label label-warning'},
    ],
    reward_types: [
      { id: 'physical', name: this.translate.instant('resources.scores.rewards.types.physical'), css_class: 'font-size-14 text-bold'},
      { id: 'digital', name: this.translate.instant('resources.scores.rewards.types.digital'), css_class: 'font-size-14 text-bold'},
      { id: 'coupon', name: this.translate.instant('resources.scores.rewards.types.coupon'), css_class: 'font-size-14 text-bold'},
      { id: 'code', name: this.translate.instant('resources.scores.rewards.types.code'), css_class: 'font-size-14 text-bold'},
      { id: 'download', name: this.translate.instant('resources.scores.rewards.types.download'), css_class: 'font-size-14 text-bold'},
      { id: 'donation', name: this.translate.instant('resources.scores.rewards.types.donation'), css_class: 'font-size-14 text-bold'},
      { id: 'free_delivery', name: this.translate.instant('resources.scores.rewards.types.free_delivery'), css_class: 'font-size-14 text-bold'},
      { id: 'voucher', name: this.translate.instant('resources.scores.rewards.types.voucher'), css_class: 'font-size-14 text-bold'},
      { id: 'wallet', name: this.translate.instant('resources.scores.rewards.types.wallet'), css_class: 'font-size-14 text-bold'},
    ],
    customer_attribute_origin: [
      {id: 'true', name: this.translate.instant('resources.customer_attributes.fields.system'), css_class: 'font-size-12 label label-default'},
      {id: 'false', name: this.translate.instant('resources.customer_attributes.fields.custom_origin'), css_class: 'font-size-12 label label-info'}
    ],
    customer_attribute_status: [
      {id: 'succeeded', name: this.translate.instant('resources.customer_attributes.status.succeeded'), css_class: 'font-size-12 label label-success'},
      {id: 'failed', name: this.translate.instant('resources.customer_attributes.status.failed'), css_class: 'font-size-12 label label-danger'},
      {id: 'cancelled', name: this.translate.instant('resources.customer_attributes.status.cancelled'), css_class: 'font-size-12 label label-cancel'},
      {id: 'new', name: this.translate.instant('resources.customer_attributes.status.new'), css_class: 'font-size-12 label label-info'}
    ],
    customer_attribute_results_available_in_real_time: [
      {id: 'true', name: this.translate.instant('resources.customer_attributes.rows.available_via_api'), icon: 'fa-light fa-check text-neo-navy'},
      {id: 'none', name: this.translate.instant('resources.customer_attributes.rows.unavailable_via_api'), icon: 'fa-light fa-times text-neo-navy'}
    ],
    connectors_active: [
      { id: 'true', name: this.translate.instant('resources.connectors.active.on'), css_class: 'font-size-12 label label-success' },
      { id: 'false', name: this.translate.instant('resources.connectors.active.off'), css_class: 'font-size-12 label label-danger' }
    ],
    tiers_status: [
      {id: 'true', name: this.translate.instant('resources.scores.tiers-management.status.active'), icon: 'fa fa-check text-success'},
      {id: 'none', name: this.translate.instant('resources.scores.tiers-management.status.inactive'), icon: 'fa fa-times text-danger'},
    ],
    api_keys_status: [
      {id: 'true', name: this.translate.instant('resources.api_keys.status.enabled'), css_class: 'label label-success font-size-12'},
      {id: 'false', name: this.translate.instant('resources.api_keys.status.disabled'), css_class: 'label label-danger font-size-12'}
    ],
    api_keys_system: [
      {id: 'true', name: this.translate.instant('resources.api_keys.system.active'), icon: 'fa fa-check text-success'},
      {id: 'none', name: this.translate.instant('resources.api_keys.system.inactive'), icon: 'fa fa-times text-danger'},
    ],
    reward_validity_period: [
      {id: 'relative', name: this.translate.instant('resources.scores.loyalty_scores.form.options.relative')},
      {id: 'fixed', name: this.translate.instant('resources.scores.loyalty_scores.form.options.fixed')}
    ],
    reward_duration_unit_option: [
      { id: 'days', name: this.translate.instant('resources.scores.rewards.form.options.days')},
      { id: 'months', name: this.translate.instant('resources.scores.rewards.form.options.months')},
      { id: 'years', name: this.translate.instant('resources.scores.rewards.form.options.years')}
    ],
    reward_redemption_type: [
      {id: 'manual', name: this.translate.instant('resources.scores.rewards.form.options.manual')},
      {id: 'loyalty_rule', name: this.translate.instant('resources.scores.rewards.form.options.loyalty_rule')}
    ],
    search_by_products_options: [
      { id: 'id', name: this.translate.instant('common.id')},
      { id: 'code', name: this.translate.instant('common.code')},
      { id: 'name', name: this.translate.instant('common.name')}
    ],
    award_type_values: [
      { id: 'score', name: this.translate.instant('resources.scores.loyalty_scores.fields.award_type_values.score')},
      { id: 'reward', name: this.translate.instant('resources.scores.loyalty_scores.fields.award_type_values.reward')}
    ],
    loyalty_massive_actions: [
      {id: 'approved', name: this.translate.instant('resources.scores.massive_actions.status.approved'), css_class: 'font-size-12 label label-success'},
      {id: 'data_pending', name: this.translate.instant('resources.scores.massive_actions.status.data_pending'), css_class: 'font-size-12 label label-info'},
      {id: 'failed', name: this.translate.instant('resources.scores.massive_actions.status.failed'), css_class: 'font-size-12 label label-danger'},
      {id: 'fixed', name: this.translate.instant('resources.scores.massive_actions.status.fixed'), css_class: 'font-size-12 label label-success'},
      {id: 'not_valid', name: this.translate.instant('resources.scores.massive_actions.status.not_valid'), css_class: 'font-size-12 label label-danger'},
      {id: 'processed', name: this.translate.instant('resources.scores.massive_actions.status.processed'), css_class: 'font-size-12 label label-success'},
      {id: 'rejected', name: this.translate.instant('resources.scores.massive_actions.status.rejected'), css_class: 'font-size-12 label label-danger'},
      {id: 'running', name: this.translate.instant('resources.scores.massive_actions.status.running'), css_class: 'font-size-12 label label-info'},
      {id: 'success_with_errors', name: this.translate.instant('resources.scores.massive_actions.status.success_with_errors'), css_class: 'font-size-12 label label-warning'},
      {id: 'success', name: this.translate.instant('resources.scores.massive_actions.status.success'), css_class: 'font-size-12 label label-success'},
      {id: 'support', name: this.translate.instant('resources.scores.massive_actions.status.support'), css_class: 'font-size-12 label label-warning'},
      {id: 'validated', name: this.translate.instant('resources.scores.massive_actions.status.validated'), css_class: 'font-size-12 label label-info'},
    ],
    referral_programs: [
      {id: 'refer', name: this.translate.instant('resources.scores.referral-programs.types.refer'), css_class: 'label label-success font-size-12'},
      {id: 'influencer', name: this.translate.instant('resources.scores.referral-programs.types.influencer'), css_class: 'label label-warning font-size-12'}
    ],
    win_type_referral_programs: [
      {id: 'signup', name: this.translate.instant('resources.scores.referral-programs.types.signup')},
      {id: 'first_purchase', name: this.translate.instant('resources.scores.referral-programs.types.first_purchase')},
      {id: 'all_purchases', name: this.translate.instant('resources.scores.referral-programs.types.all_purchases')}
    ],
    code_type_referral_programs: [
      {id: 'random', name: this.translate.instant('resources.scores.referral-programs.types.random')},
      {id: 'pattern', name: this.translate.instant('resources.scores.referral-programs.types.pattern')},
      {id: 'fixed', name: this.translate.instant('resources.scores.referral-programs.types.fixed')}
    ],
    cashback_configuration: [
      {id: 'true', name: this.translate.instant('resources.promotions.status.active'), icon: 'fa fa-check text-success'},
      {id: 'false', name: this.translate.instant('resources.promotions.status.inactive'), icon: 'fa fa-times text-danger'}
    ],
    modal_titles: [
      {
        id: 'bigquery_jobs',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.bigquery_jobs')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.bigquery_jobs')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.bigquery_jobs')}),
      },
      {
        id: 'execute_date',
        create_title: this.translate.instant('components.large-modal-container.titles.action_modal',
          { model: this.translate.instant('components.large-modal-container.models.execute_date')}),
      },
      {
        id: 'plan',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.plans')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.plans')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.plans')}),
      },
      {
        id: 'products',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.products')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.products')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.products')}),
      },
      {
        id: 'features',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.products_features')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.products_features')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.products_features')}),
      },
      {
        id: 'feature_internals',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.feature_internals')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.feature_internals')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.feature_internals')}),
      },
      {
        id: 'feature_taxonomies',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.feature_taxonomies')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.feature_taxonomies')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.feature_taxonomies')}),
      },
      {
        id: 'locations',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.locations')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.locations')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.locations')}),
      },
      {
        id: 'location_taxonomy_terms',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.location_taxonomy_terms')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.location_taxonomy_terms')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.location_taxonomy_terms')}),
      },
      {
        id: 'location_taxonomies',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.location_taxonomies')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.location_taxonomies')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.location_taxonomies')}),
      },
      {
        id: 'promotions',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.promotions')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.promotions')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.promotions')}),
      },
      {
        id: 'promotional_discounts',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.promotional_discounts')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.promotional_discounts')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.promotional_discounts')}),
      },
      {
        id: 'credit_transactions',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.credit_transactions')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.credit_transactions')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.credit_transactions')}),
      },
      {
        id: 'segment_categories',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.segment_categories')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.segment_categories')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.segment_categories')}),
      },
      {
        id: 'loyalty_rule_moments',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.loyalty_score_moments')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.loyalty_score_moments')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.loyalty_score_moments')}),
      },
      {
        id: 'scores',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.scores')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.scores')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.scores')}),
      },
      {
        id: 'rewards',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.rewards')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.rewards')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.rewards')}),
      },
      {
        id: 'reward_categories',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.reward_categories')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.reward_categories')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.reward_categories')}),
      },
      {
        id: 'reward_taxonomies',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.reward_taxonomies')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.reward_taxonomies')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.reward_taxonomies')}),
      },
      {
        id: 'wallets',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.wallets')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.wallets')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.wallets')}),
      },
      {
        id: 'redemptions',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.redemptions')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.redemptions')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.redemptions')}),
      },
      {
        id: 'score_exchanges',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.score_exchanges')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.score_exchanges')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.score_exchanges')}),
      },
      {
        id: 'campaigns',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.campaigns')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.campaigns')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.campaigns')}),
      },
      {
        id: 'campaign_categories',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.campaign_categories')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.campaign_categories')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.campaign_categories')}),
      },
      {
        id: 'messages',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.messages')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.messages')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.messages')}),
      },
      // TODO: RENAME
      {
        id: 'message_histories',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.messages')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.messages')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.messages')}),
      },
      {
        id: 'message_categories',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.message_categories')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.message_categories')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.message_categories')}),
      },
      {
        id: 'currency_conversions',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.currency_conversions')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.currency_conversions')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.currency_conversions')}),
      },
      {
        id: 'coupons',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.coupons')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.coupons')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.coupons')}),
      },
      {
        id: 'recipes',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.recipes')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.recipes')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.recipes')}),
      },
      {
        id: 'news',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.news')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.news')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.news')}),
      },
      {
        id: 'news_taxonomy_terms',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.news_taxonomy_terms')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.news_taxonomy_terms')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.news_taxonomy_terms')}),
      },
      {
        id: 'news_taxonomies',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.news_taxonomies')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.news_taxonomies')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.news_taxonomies')}),
      },
      {
        id: 'segments',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.segments')})
      },
      {
        id: 'audience_selector',
        show_title: this.translate.instant('components.large-modal-container.titles.action_modal',
          { model: this.translate.instant('components.large-modal-container.models.audience_selector')})
      },
      {
        id: 'reports',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.reports')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.reports')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.reports')}),
      },
      {
        id: 'report_incremental',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.report_incremental')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.report_incremental')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.report_incremental')}),
      },
      {
        id: 'report_segment',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.report_segment')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.report_segment')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.report_segment')}),
      },
      {
        id: 'user',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.users')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.users')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.users')}),
      },
      {
        id: 'vouchers',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.vouchers')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.vouchers')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.vouchers')}),
      },
      {
        id: 'campaigns_histories',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.campaigns_histories_content')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.campaigns_histories_content')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.campaigns_histories_content')}),
      },
      {
        id: 'campaigns_content',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.campaigns_histories_content')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.campaigns_histories_content')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.campaigns_histories_content')}),
      },
      {
        id: 'campaigns_histories_content',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.campaigns_histories_content')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.campaigns_histories_content')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.campaigns_histories_content')}),
      },
      {
        id: 'campaigns_histories_content',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.campaigns_histories')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.campaigns_histories')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.campaigns_histories')}),
      },
      {
        id: 'payment_methods',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.payment_methods')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.payment_methods')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.payment_methods')}),
      },
      {
        id: 'customer-attribute',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.customer_attributes')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.customer_attributes')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.customer_attributes')}),
      },
      {
        id: 'synchros',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.synchros')})
      },
      {
        id: 'periods',
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.periods')}),
      },
      {
        id: 'grant_points',
        action_modal_title: this.translate.instant('components.large-modal-container.titles.action_modal',
          { model: this.translate.instant('components.large-modal-container.models.grant_points')}),
      },
      {
        id: 'transfer_score',
        action_modal_title: this.translate.instant('components.large-modal-container.titles.action_modal',
          { model: this.translate.instant('components.large-modal-container.models.transfer_score')})
      },
      {
        id: 'new_card',
        action_modal_title: this.translate.instant('components.large-modal-container.titles.action_modal',
          { model: this.translate.instant('components.large-modal-container.models.new_card')})
      },
      {
        id: 'customer_fusion',
        action_modal_title: this.translate.instant('components.large-modal-container.titles.action_modal',
          { model: this.translate.instant('components.large-modal-container.models.customer_fusion')})
      },
      {
        id: 'versions',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.versions')})
      },
      {
        id: 'logs',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.logs')})
      },
      {
        id: 'import_coupons',
        import_title: this.translate.instant('components.large-modal-container.titles.action_modal',
          { model: this.translate.instant('resources.coupons.table_actions.import_coupons')})
      },
      {
        id: 'upload_images',
        import_title: this.translate.instant('components.large-modal-container.titles.action_modal',
         { model: this.translate.instant('resources.coupons.table_actions.upload_images')})
      },
      {
        id: 'gdpr',
        update_title: this.translate.instant('components.large-modal-container.titles.action_modal',
          { model: this.translate.instant('resources.company.gdpr.form.title')})
      },
      {
        id: 'streaming_api',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.streaming_api')})
      },
      {
        id: 'import_product_features',
        import_title: this.translate.instant('components.large-modal-container.titles.action_modal',
          { model: this.translate.instant('resources.product_features.table_actions.import_csv')})
      },
      {
        id: 'import_location_taxonomy_terms',
        import_title: this.translate.instant('components.large-modal-container.titles.action_modal',
          { model: this.translate.instant('components.table_actions.import_csv')})
      },
      {
        id: 'custom_entities',
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.custom_entities')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
        { model: this.translate.instant('components.large-modal-container.models.custom_entities')}),
        show_title: ''
      },
      {
        id: 'partner',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.partner')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.partner')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.partner')}),
      },
      {
        id: 'api_key',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.api_key')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.api_key')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.api_key')}),
      },
      {
        id: 'contact_policies',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.contact_policy')}),
        update_title: this.translate.instant('components.large-modal-container.titles.update',
          { model: this.translate.instant('components.large-modal-container.models.contact_policy')}),
        create_title: this.translate.instant('components.large-modal-container.titles.create',
          { model: this.translate.instant('components.large-modal-container.models.contact_policy')}),
      },
      {
        id: 'massive_actions',
        show_title: this.translate.instant('components.large-modal-container.titles.show',
          { model: this.translate.instant('components.large-modal-container.models.massive_actions')})
      }
    ]
  };

  constructor(public translate: TranslateService) {}

  public getValuesByKey(key: string) {
    return this.dictionaries[key];
  }

  public getNameByKeyAndId(key: string, id: string) {

    if (!this.dictionaries.hasOwnProperty(key)) {
      console.error('DictionaryError: Sent key is not in dictionary');
      return;
    }

    const objToReturn = this.searchObject(this.dictionaries[key], id);
    return objToReturn['name'];
  }

  public getIconByKeyAndId(key: string, id: string) {

    if (!this.dictionaries.hasOwnProperty(key)) {
      console.error('DictionaryError: Sent key is not in dictionary');
      return;
    }

    const objToReturn = this.searchObject(this.dictionaries[key], id);
    return objToReturn['icon'];
  }

  public getObjectByKeyAndId(key: string, id: string) {
    if (!this.dictionaries.hasOwnProperty(key)) {
      console.error('DictionaryError - getObjetByKeyAndId: Sent key is not in dictionary');
      return;
    }
    return this.searchObject(this.dictionaries[key], id);
  }


  public searchObject(arrayOfValues, id) {

    let objToReturn = {};

    if (!arrayOfValues || !id) {
      return objToReturn;
    }

    arrayOfValues.forEach(element => {
      if ( element.id === id ) {
        objToReturn = element;
      }
    });

    return objToReturn;
  }
}
