<div class="modal fade modal-fs show" id="modal-fs" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12">
          <div class="modal-header background-white">
            <h1 class="first-letter-upper">{{'resources.scores.rewards.titles.configure_translations' | translate}}</h1>
          </div>
        </div>
      </div>

      <div class="modal-body height-100 background-gray">
        <div class="row">
          <div class="col">
            <div class="alert alert-info d-flex align-items-center">
              <em class=" text-bold fa-light fa-exclamation-circle mrg-right-10 font-size-16 exclamation_circle_position"></em>
              <span class="font-size-14">
                {{'demo.configure_translations.header_title' | translate}}<br>
                <span class="text-bold">{{'demo.configure_translations.instructions' | translate}}:</span>
                <ul>
                  <li>{{'demo.configure_translations.text1' | translate}}</li>
                  <li>{{'demo.configure_translations.text2' | translate}}</li>
                </ul>
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-block">
                <div class="row">
                  <div class="col">
                    <button class="btn btn-info btn-sm pull-right no-mrg-right" (click)="openModal()">
                      <em class="fa-solid fa-upload"></em>&nbsp;<span>{{'demo.configure_translations.upload_csv' | translate}}</span>
                    </button>
                    <button class="btn btn-info btn-sm pull-right" (click)="getTranslations()">
                      <em class="fa-solid fa-language"></em>&nbsp;{{'demo.configure_translations.populate' | translate}}
                    </button>

                  </div>
                </div>
                <app-language-accordions #languageAccordionsComponent [inputs]="inputs"
                                         [form]="form"
                                         [mode]="mode"
                                         [filteredAvailableLanguages]="filteredAvailableLanguages"
                                         [demo]=true>
                </app-language-accordions>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer background-white align-content-center">
        <div class="pull-right">
          <button class="btn btn-info mrg-right-5" >{{'common.buttons.save' | translate}}</button>
        </div>
			</div>

    </div>
  </div>
</div>

<app-upload-csv-demo #uploadModalComponent></app-upload-csv-demo>
