import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MultiselectDataSource } from '../../../../../shared/components/multiselect/multiselect';
import { Observable } from 'rxjs';
import { ResourcesService } from '../../../../../shared/services/resources.service';

@Injectable()
export class PromotionsService extends ResourcesService {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

   getAll() {
    const requestOptions = {
      apiEndPoint: '/promotions',
      sorting: {
        byProp: '',
        direction: ''
      },
      filtering: {},
      numberPerPage: 300
    };
    return this.getData(requestOptions);
  }

   getPromotionsById(id: number) {
    return this.getData({ apiEndPoint: `promotions/${id}` });
  }

   fetchMultiselect(searchValues?: string, page?: number) {
    let requestOptions = {
      apiEndPoint: 'promotions',
      numberPerPage: 10,
      pageNumber: 1,
      filtering: {},
      sorting: {}
    };

    requestOptions.pageNumber = page ?? requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { search_text: searchValues } };
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData(requestOptions);
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getPromotionsById(id);
  }

   getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.name}`);
  }

  sendData(promotionData) {
    return this.postResource(promotionData, 'promotions');
  }

   updateData(promotionData: any, _id: number) {
    return this.patchResource(promotionData, `promotions/${_id}`);
  }
}
