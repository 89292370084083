<div class="home-container container-fluid">

  <aside class="banner">
    <div class="banner__img"></div>
    <div class="banner__content text-center">
      <div class="banner__title text-dark text-bold font-size-35" translate="menu.home.banner.title"></div>
      <div class="banner__text text-dark" translate="menu.home.banner.text"></div>
      <a class="banner__btn btn btn-blue-lg btn-lg" href="https://loyal-guru.atlassian.net/servicedesk/customer/portals" target="_blank" rel="noopener">
        {{ 'menu.home.banner.action_button' | translate }}
      </a>
    </div>
  </aside>

  <main class="grid">
    <ng-container *ngFor="let module of modules">

      <div class="card"
        [class.card--disabled]="!module.isPurchased"
        [class.card--revamp]="isNewHome"
        [attr.data-cy]="'card_' + (module.id | camelCase)"
        (click)="module.isPurchased ? navigate(module) : showNotAvailable()"
        [hidden]="isNotAuth(module) || module.isUserPartnerNotAuthorized"
      >
        <div class="card__icon">
          <em class="module-icon" [class]="icons[module.id]"></em>
        </div>
        <div class="card-block padding-20">
          <!-- Title -->
          <h4 class="card__title text-bold" [class.card__title--revamp]="isNewHome" [style.max-width]="storedUser.preferred_language === 'it' ? '12ch' : '11ch'">
            {{ 'menu_dia.' + module.text + '.title' | translate }}
          </h4>
          <!-- Description -->
          <p class="card__text font-size-14">{{ 'menu_dia.' + module.text + '.description' | translate }}</p>
          <!-- More info button -->
          <div *ngIf="!isNewHome" class="card__action-btn">
            <icon-info-button (click)="openHelpCenter(module.id, $event)" />
          </div>
        </div>
        <div class="card__bottom-decor"></div>
      </div>

    </ng-container>
  </main>

</div>