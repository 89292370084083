import { CheckboxQuestion } from '../../../shared/models/forms/question-checkbox';
import { CollectivesService } from '../../data-warehouse/profiles/collectives.service';
import { CountriesService } from '../../../shared/services/countries.service';
import { CouponsService } from '../../coupons/coupons.service';
import { CurrenciesService } from '../../../shared/services/currencies.service';
import { DictionaryService } from '../../../shared/services/dictionary.service';
import { FeatureFlagsService } from '../../../shared/services/feature-flags.service';
import { FloatQuestion } from '../../../shared/models/forms/question-float';
import { HttpClient } from '@angular/common/http';
import { ImageQuestion } from '../../../shared/models/forms/question-image';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '../../../shared/utils/common.utils';
import { LanguagesService } from '../../../shared/services/languages.service';
import { LocationsService } from '../../data-warehouse/locations/locations.service';
import { LocationsTaxonomyTermsService } from '../../data-warehouse/locations/location-taxonomy-terms.service';
import { MultiselectDataSource } from '../../../shared/components/multiselect/multiselect';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { Observable } from 'rxjs';
import { PartnersService } from '../partners/partners.service';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { RadioQuestion } from '../../../shared/models/forms/question-radio';
import { ResourcesService } from '../../../shared/services/resources.service';
import { RewardCategoriesService } from './reward-categories/reward-categories.service';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { TiersService } from './tiers.service';
import { TranslateService } from '@ngx-translate/core';
import { TextareaQuestion } from '../../../shared/models/forms/question-textarea';

@Injectable()
export class RewardsService extends ResourcesService {

  flags = this.featureFlags.flags;
  inputs: any;
  parsedCountriesList: any;
  filterByLoyaltyRule: boolean;

  constructor(
    http: HttpClient,
    private collectivesService: CollectivesService,
    private countriesService: CountriesService,
    private couponsService: CouponsService,
    private currencies: CurrenciesService,
    private dictionarySrv: DictionaryService,
    private featureFlags: FeatureFlagsService,
    private languagesService: LanguagesService,
    private locationsService: LocationsService,
    private locationTaxonomyTermsService: LocationsTaxonomyTermsService,
    private partnersService: PartnersService,
    private rewardsCategories: RewardCategoriesService,
    private tiersService: TiersService,
    private translate: TranslateService
  ) {
    super(http);
  }

  fetchMultiselect(searchValues?: string, page?: number, filters?: object): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'rewards',
      numberPerPage: 10,
      filtering: {},
      pageNumber: 1,
      sorting: {}
    };

    requestOptions.pageNumber = page || requestOptions.pageNumber;

    if (this.filterByLoyaltyRule) {
      const loyaltyFilter = { redemption_type: 'loyalty_rule' };
      const filtering = { ...requestOptions.filtering, ...loyaltyFilter };
      requestOptions = { ...requestOptions, filtering };
    }

    if (searchValues) {
      const nameFilter = { name: searchValues };
      requestOptions.filtering = { ...requestOptions.filtering, ...nameFilter };
    }

    if (filters) {
      requestOptions.filtering = { ...requestOptions.filtering, ...filters };
    }

    return this.getData(requestOptions);
  }


  fetchSelectedById(id: number) {
    return this.getRewardsById(id);
  }

  getRewardsById(id: number): Observable<any> {
    return this.getData({apiEndPoint: `rewards/${id}`});
  }

  getCountries() {
    const list = this.dictionarySrv.getValuesByKey('countries');
    this.parsedCountriesList = list.map(element => {
      const translatedName = this.translate.instant(element.name);
      return {
        id: element.id,
        name: '(' + '+' + element.id + ') ' + translatedName,
        rawElement: {...element, name: translatedName}
      };
    });
    return this.parsedCountriesList.sort((a, b) => a.rawElement.name.localeCompare(b.rawElement.name));
  }

  createReward(data): Observable<any> {
    return this.postResource(data, 'rewards');
  }

  updateReward(data: any, _id: number): Observable<any> {
    return this.patchResource(data, `rewards/${_id}`);
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, element.name);
  }

  importCodesBatch(rewardId: number, file_url: string) {
    return this.postResource({ file_url }, `rewards/${rewardId}/code_batches`);
  }

  getRewardBatch(rewardId: number, batchId: number) {
    return this.getData({ apiEndPoint: `rewards/${rewardId}/code_batches/${batchId}` });
  }

  addMoreStock(value, id: number) {
    return this.postResource(value, `rewards/${id}/stock`);
  }

  deleteRewardCodeBatch(rewardId: number, batchId: number) {
    return this.deleteResource({}, `rewards/${rewardId}/code_batches/${batchId}/delete`);
  }

  redeemReward(data: object, rewardId: number): Observable<any> {
    return this.postResource(data, `rewards/${rewardId}/redeem`);
  }

  getDemoInputs(): QuestionBase<unknown>[] {
    const demoInputsArray = [
      new MultiSelectQuestion({
        key: 'feature_location_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locationTaxonomyTermsService,
      }),
      new TextboxQuestion({
        key: 'publish_from',
        type: 'date',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'publish_to',
        type: 'date',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'priority',
        type: 'number',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextareaQuestion({
        key: 'terms_and_conditions',
        type: 'textarea',
        rows: 3,
        cssClasses: 'form-control input-default',
        placeholder: this.translate.instant('resources.scores.rewards.form.terms_and_conditions_placeholder'),
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'approval',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: [
          {id: 'yes', name: this.translate.instant('resources.scores.rewards.form.approval_options.yes')},
          {id: 'no', name: this.translate.instant('resources.scores.rewards.form.approval_options.no')},
        ],
        getValue: (value: string) => value ? [this.getRedeemOptions().find(item => item.id === value)] : []
      }),
      new TextboxQuestion({
        key: 'cta_type',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'adobe_campaign',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'cause_id',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'custom_field_5',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'custom_field_6',
        type: 'text',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      })
    ]
    return demoInputsArray;
  }

  getInputs(formValues: any): QuestionBase<unknown>[] {
    const inputsArray = [
      // DETAILS
      new MultiSelectQuestion({
        key: 'available_languages',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: false, showCheckbox: true, enableSearchFilter: false },
        options: this.languagesService.getLocalizedLanguages(),
      }),
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
      }),
      new TextboxQuestion({
        key: 'description',
        type: 'text',
        cssClasses: 'form-control input-default',
      }),
      new ImageQuestion({
        key: 'image',
        type: 'file',
        cssClasses: 'input-default',
      }),
      new MultiSelectQuestion({
        key: 'reward_taxonomy_terms',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.rewardsCategories,
        selectedIds: this.getEmbeddedCategories(formValues)
      }),
      // TYPES
      new MultiSelectQuestion({
        key: 'type',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        options: this.dictionarySrv.getValuesByKey('reward_types'),
        disabled: !isNullOrUndefined(formValues?.id),
        required: true,
        value: formValues?.['type'] ? this.dictionarySrv.getValuesByKey('reward_types').filter(type => type.id === formValues['type']) : [{id: 'physical', name: this.translate.instant('resources.scores.rewards.types.physical')}]
      }),
      new CheckboxQuestion({
        label: this.translate.instant('resources.scores.rewards.form.is_coupon'),
        key: 'is_coupon',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        value: formValues?.['is_coupon']
      }),
      new MultiSelectQuestion({
        key: 'coupon_id',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.couponsService,
      }),
      new ImageQuestion({
        key: 'upload_csv_external_codes',
        type: 'file',
        cssClasses: 'input-default'
      }),
      // REDEMPTION VALUE
      new TextboxQuestion({
        key: 'points',
        type: 'number',
        cssClasses: 'form-control input-default',
        required: true,
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'extra_money',
        type: 'number',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'currency',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.currencies,
        selectedIds: formValues?.currency ? [formValues.currency] : [],
      }),
      new CheckboxQuestion({
        label: this.translate.instant('resources.scores.rewards.form.flexible_score_allowed'),
        key: 'flexible_score_allowed',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      // AVAILABILITY
      new MultiSelectQuestion({
        key: 'achievement_definition_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: false },
        dataSource: this.tiersService
      }),
      new MultiSelectQuestion({
        key: 'collectives',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.collectivesService
      }),
      new MultiSelectQuestion({
        key: 'locations',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.locationsService,
      }),
      new MultiSelectQuestion({
        key: 'countries',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        options: this.countriesService.getCountries('default'),
        required: true
      }),
      new TextboxQuestion({
        key: 'available_from',
        type: 'date',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'available_to',
        type: 'date',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'status',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        required: true,
        value: [{ id: 'active', name: this.translate.instant('common.enabled') }],
        options: this.dictionarySrv.getValuesByKey('rewards_status')
      }),
      // VALIDITY OF REDEEMED
      new CheckboxQuestion({
        label: this.translate.instant('resources.scores.rewards.form.activate_reward_validity'),
        key: 'activate_reward_validity',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        value: formValues?.validity_duration_type ? true : false
      }),
      new RadioQuestion({
        key: 'validity_duration_type',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.dictionarySrv.getValuesByKey('reward_validity_period'),
        getValue: (value) => value
      }),
      new RadioQuestion({
        key: 'redemption_type',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.dictionarySrv.getValuesByKey('reward_redemption_type'),
        value: formValues?.redemption_type ? formValues?.redemption_type : 'manual',
        required: true,
        disabled: formValues?.id,
        getValue: (value) => value
      }),
      new TextboxQuestion({
        key: 'validity_duration_number',
        type: 'number',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      new MultiSelectQuestion({
        key: 'validity_duration_unit_option',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.dictionarySrv.getValuesByKey('reward_duration_unit_option'),
        getValue: (value: string) => value ? [this.dictionarySrv.getValuesByKey('reward_duration_unit_option').find(item => item.id === value)] : []
      }),
      new TextboxQuestion({
        key: 'validity_duration_date',
        type: 'date',
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
      // LIMITS
      new MultiSelectQuestion({
        key: 'redeem_chances',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        options: this.getRedeemOptions(),
        getValue: (value: string) => value ? [this.getRedeemOptions().find(item => item.id === value)] : []
      }),
      new TextboxQuestion({
        key: 'redeem_times',
        type: 'number',
        cssClasses: 'form-control input-default',
        disabled: !formValues?.redeem_chances || formValues?.redeem_chances === 'always',
        required: formValues?.redeem_chances && formValues?.redeem_chances !== 'always',
        getValue: (value: number) => value
      }),
      // PARTNERS
      new MultiSelectQuestion({
        key: 'partner_id',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        dataSource: this.partnersService,
        selectedIds: formValues?.partner_id ? [formValues.partner_id] : null
      }),
      new FloatQuestion({
        key: 'low_stock_level',
        type: 'number',
        min: 0,
        cssClasses: 'form-control input-default',
        getValue: (value: number) => value
      }),
    ];

    Object.keys(formValues).forEach(key => {
      const input = inputsArray.find(input => input.key === key);
      switch (key) {
        case 'image':
        case 'name':
        case 'description':
        case 'is_coupon':
        case 'locations':
        case 'collectives':
          input.value = formValues[key];
          break;
        case 'coupon':
          inputsArray.find(input => input.key === 'coupon_id').selectedIds = [formValues[key].id];
          break;
        case 'countries':
          const countries = formValues[key].map(countryId => this.countriesService.getCountriesById(countryId));
          input.value = countries ?? [];
          break;
        case 'status':
          input.value = [this.dictionarySrv.getValuesByKey('rewards_status').find(item => item.id === formValues[key])];
          break;
        case 'available_languages':
          const langValue = this.languagesService.getLocalizedLanguages().filter(item => formValues[key].includes(item.id));
          input.value = langValue;
          break;
      }
    });

    if (formValues.achievement_definitions?.length) {
      inputsArray.find(input => input.key === 'achievement_definition_ids').selectedIds = this.getAchievementValues(formValues.achievement_definitions);
    }

    this.inputs = this.flags.showDemoWallmart
                  ? (inputsArray as QuestionBase<unknown>[]).concat(this.getDemoInputs())
                  : inputsArray;
    this.assignFormInputValues(formValues);

    return this.inputs;
  }

  private assignFormInputValues(formValues) {
    Object.keys(formValues).forEach(
      key => {
        const tmpInput = this.inputs.find(input => input.key === key);
        if (tmpInput?.getValue) {
          const value = tmpInput.getValue(formValues[key]);
          if (value?.hasOwnProperty('selectedIds')) {
            tmpInput.selectedIds = value.selectedIds;
          } else {
            tmpInput.value = value;
          }
        }
      }
    );
  }

  private getRedeemOptions() {
    return [
      { id: 'always', name: this.translate.instant('resources.scores.rewards.form.redeem_always') },
      { id: 'num_total', name: this.translate.instant('resources.scores.rewards.form.redeem_total_times') },
      { id: 'num_day', name: this.translate.instant('resources.scores.rewards.form.redeem_times_day') },
      { id: 'num_month', name: this.translate.instant('resources.scores.rewards.form.redeem_times_month') },
      { id: 'num_year', name: this.translate.instant('resources.scores.rewards.form.redeem_times_year') },
    ];
  }

  private getAchievementValues(rawAchievementValues) {
    return rawAchievementValues.map(el => el.id);
  }

  private getEmbeddedCategories(formValues) {
    return formValues['reward_taxonomy_terms']?.length ? formValues['reward_taxonomy_terms'].map(el => el.id) : [];
  }

}
