<div class="modal fade modal-fs show" id="modal-fs" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header background-white">
        <div class="row flex-grow-1">
          <div class="col-md-8 ml-auto mr-auto">
            <h1 *ngIf="!id" [translate]="'resources.scores.referral-programs.form.title'"></h1>
            <h1 *ngIf="id" [translate]="'resources.scores.referral-programs.form.edit_title'"></h1>
          </div>
        </div>

        <div class="pdd-vertical-20-but-bottom">
          <button class="modal-close" (click)="returnToList()">
            <i class="ti-close"></i>
          </button>
        </div>
      </div>

      <div class="modal-body back-color">
        <div class="card-block no-pdd">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form [formGroup]="formReferralProgram" *ngIf="formReferralProgram">

                <!-- GENERAL INFORMATION -->
                <div class="card">
                  <div class="card-heading border bottom">
                    <h4 class="text-bold card-title" [translate]="'resources.scores.referral-programs.form.general_info'"></h4>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert">
                      <span class="alert-text">{{'resources.scores.referral-programs.messages.general_info_alert' | translate}}</span>
                    </div>
                    <div class="row mrg-top-15">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="text-bold" for="name"
                            [translate]="'common.name'"></label>
                          <app-question [question]="getInputConfig('name')" [form]="formReferralProgram"></app-question>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="text-bold" for="description"
                            [translate]="'common.description'"></label>
                          <app-question [question]="getInputConfig('description')" [form]="formReferralProgram"></app-question>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label class="text-bold" for="country">
                            <span>{{'resources.scores.referral-programs.form.country' | translate}}</span>
                          </label>
                          <app-question [question]="getInputConfig('country')" [form]="formReferralProgram"></app-question>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label class="text-bold" for="taxonomy">
                            <span>{{'resources.scores.referral-programs.form.taxonomy' | translate}}</span>
                          </label>
                          <app-question [question]="getInputConfig('taxonomy')" [form]="formReferralProgram"></app-question>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label class="text-bold" for="program_type">
                            <span>{{'resources.scores.referral-programs.form.program_type' | translate}}</span>
                          </label>
                          <app-question [question]="getInputConfig('program_type')" [form]="formReferralProgram"></app-question>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label class="text-bold" for="win_type">
                            <span>{{'resources.scores.referral-programs.form.win_type' | translate}}</span>
                          </label>
                          <app-question [question]="getInputConfig('win_type')" [form]="formReferralProgram"></app-question>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <!--  -->

                <!-- Code Generator Section -->
                <div class="card">
                  <div class="card-heading border bottom">
                    <h4 class="text-bold card-title" [translate]="'resources.scores.referral-programs.code_generation_title'"></h4>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert">
                      <span class="alert-text">{{'resources.scores.referral-programs.messages.code_generation_info' | translate}}</span><br>
                      <span class="alert-text">{{'resources.scores.referral-programs.messages.code_generation_info_random' | translate}}</span><br>
                      <span class="alert-text">{{'resources.scores.referral-programs.messages.code_generation_info_pattern' | translate}}</span><br>
                      <span class="alert-text">{{'resources.scores.referral-programs.messages.code_generation_info_fixed' | translate}}</span>
                    </div>
                    <div class="row mrg-top-30">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="text-bold" for="code_type"
                            [translate]="'resources.scores.referral-programs.form.code_type'"></label>
                          <app-question [question]="getInputConfig('code_type')" [form]="formReferralProgram"></app-question>
                        </div>
                      </div>
                      <div class="col-md-6" *ngIf="hasFormKeyWithValue('code_type', 'fixed') || hasFormKeyWithValue('code_type', 'pattern')">
                        <div class="form-group">
                          <label class="text-bold" for="code_text" [translate]="'resources.scores.referral-programs.form.code_text'"></label>
                          <app-question [question]="getInputConfig('code_text')" [form]="formReferralProgram"></app-question>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--  -->
                <!-- Referral Section -->
                <div class="card">
                  <div class="card-heading border bottom">
                    <h4 class="text-bold card-title" [translate]="'resources.scores.referral-programs.referral_title'"></h4>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert">
                      <span class="alert-text">{{'resources.scores.referral-programs.messages.referral_info' | translate}}</span>
                    </div>
                    <div class="row mrg-top-15">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="text-bold" for="prize_option"
                            [translate]="'resources.scores.referral-programs.form.prize_option'"></label>
                          <app-question [question]="getInputConfig('referral_prize_option')" [form]="formReferralProgram"></app-question>
                        </div>
                      </div>
                      <div class="col-md-6" *ngIf="hasFormKeyWithValue('referral_prize_option', 'reward')">
                        <div class="form-group">
                          <label class="text-bold" for="reward" [translate]="'resources.scores.referral-programs.form.reward'"></label>
                          <app-question [question]="getInputConfig('reward')" [form]="formReferralProgram"></app-question>
                        </div>
                      </div>
                      <div class="col-md-6" *ngIf="hasFormKeyWithValue('referral_prize_option', 'points')">
                        <div class="form-group">
                          <label class="text-bold" for="points_value" [translate]="'resources.scores.referral-programs.form.points_value'"></label>
                          <app-question [question]="getInputConfig('points_value')" [form]="formReferralProgram"></app-question>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--  -->
                <!-- Advocate Section -->
                <div class="card">
                  <div class="card-heading border bottom">
                    <h4 class="text-bold card-title" [translate]="'resources.scores.referral-programs.advocate_title'"></h4>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert">
                      <span class="alert-text">{{'resources.scores.referral-programs.messages.advocate_title_info' | translate}}</span>
                    </div>
                    <div class="row mrg-top-15">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label class="text-bold" for="prize_option"
                            [translate]="'resources.scores.referral-programs.form.prize_option'"></label>
                          <app-question [question]="getInputConfig('advocate_prize_option')" [form]="formReferralProgram"></app-question>
                        </div>
                      </div>
                      <div class="col-md-3" *ngIf="hasFormKeyWithValue('advocate_prize_option', 'reward')">
                        <div class="form-group">
                          <label class="text-bold" for="reward" [translate]="'resources.scores.referral-programs.form.reward'"></label>
                          <app-question [question]="getInputConfig('reward')" [form]="formReferralProgram"></app-question>
                        </div>
                      </div>
                      <div class="col-md-3" *ngIf="hasFormKeyWithValue('advocate_prize_option', 'points')">
                        <div class="form-group">
                          <label class="text-bold" for="points_value" [translate]="'resources.scores.referral-programs.form.points_value'"></label>
                          <app-question [question]="getInputConfig('points_value')" [form]="formReferralProgram"></app-question>
                        </div>
                      </div>
                      <div class="col-md-3" *ngIf="hasFormKeyWithValue('advocate_prize_option', 'reward') || hasFormKeyWithValue('advocate_prize_option', 'points')">
                        <div class="form-group">
                          <label class="text-bold" for="redeem_chances" [translate]="'resources.scores.rewards.form.redeem_chances'"></label>
                          <app-question [question]="getInputConfig('redeem_chances')" [form]="formReferralProgram"></app-question>
                        </div>
                      </div>
                      <div class="col-md-3" *ngIf="hasFormKeyWithValue('advocate_prize_option', 'reward') || hasFormKeyWithValue('advocate_prize_option', 'points')">
                        <div class="form-group">
                          <label for="redeem_times">&nbsp;</label>
                          <app-question [question]="getInputConfig('redeem_times')" [form]="formReferralProgram"></app-question>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--  -->
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- FOOTER -->
      <div class="row modal-footer background-white">
        <div class="col-md-8 ml-auto mr-auto text-right">
          <span>
            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          </span>
          <button class="btn btn-info no-mrg-right"
                  (click)="save()"
                  [translate]="'common.save'"
                  [disabled]="loading">
          </button>
        </div>
      </div>
      <!--  -->

    </div>
  </div>
</div>
