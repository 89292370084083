import { Injectable } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../shared/components/multiselect/multiselect';
import { Observable } from 'rxjs';

@Injectable()
export class SmsTemplatesService extends ResourcesService implements MultiselectDataSourceable {

  constructor(http: HttpClient) {
    super(http);
  }

  public getTemplateById(id) {
    return this.getData({ apiEndPoint: `campaign_templates/${id}` });
  }

  public fetchMultiselect(searchValues?: string, page?: number): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'campaign_templates',
      numberPerPage: 300,
      filtering: { via: 'sms' },
      pageNumber: 1,
      sorting: {}
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;

    /* Handling user search input */
    if (searchValues) {
      requestOptions.filtering['name'] = searchValues;
      if (Number.isInteger(Number(searchValues))) {
        requestOptions.filtering['id'] = searchValues;
        requestOptions.filtering['filter_logic'] = 'or';
      }
    }

    return this.getData(requestOptions);
  }

  public getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.name}`);
  }

  public fetchSelectedById(id: number): Observable<object> {
    return this.getTemplateById(id);
  }

  public parseSmsForm(formValues: any): any {
    const subject = formValues.hasOwnProperty('subject') ? formValues.subject : '';
    const message = formValues.hasOwnProperty('message') ? formValues.message : '';
    return { subject: subject, message: message };
  }

  public parseApiTemplate(apiTemplate: any, campaignId: number): any {

    const parsedApiTemplate = { ...apiTemplate };

    parsedApiTemplate['id'] = campaignId;
    parsedApiTemplate['subject'] = parsedApiTemplate['name'];
    parsedApiTemplate['message'] = parsedApiTemplate['html'];

    delete parsedApiTemplate['name'];
    delete parsedApiTemplate['html'];

    return parsedApiTemplate;
  }
}
