import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { TranslateService } from '@ngx-translate/core';
import { TextboxQuestion } from '../../../../../../shared/models/forms/question-textbox';
import { FeaturesService } from '../../../services/products-features/features.service';
import { DataTableComponent } from '../../../../../../shared/components/data-table/data-table.component';
import { DataTableConfiguration } from '../../../../../../shared/components/data-table/data-table-cfg';
import { MultiSelectQuestion } from '../../../../../../shared/models/forms/question-multiselect';
import { ConfirmationService } from '../../../../../../shared/services/confirmation.service';
import { ModalStatusService } from '../../../../../../shared/services/modal-status.service';
import { Subscription } from 'rxjs';
import { QuestionBase } from '../../../../../../shared/models/forms/question-base';
import { getDataTableTextPropValue } from '../../../../../../shared/utils/common.utils';

@Component({
  selector: 'app-show-products-features',
  templateUrl: './show-products-features.component.html',
  styleUrls: ['./show-products-features.component.scss']
})
export class ShowProductsFeaturesComponent implements OnInit, OnDestroy {

  element: any;
  apiEndPoint: string;
  id: number;
  isModal: boolean;
  productsTableConfig: DataTableConfiguration;
  filters: QuestionBase<any>[];
  selectedItems: any[];
  csv_text: string;
  subs$: Subscription[];

  @ViewChild(DataTableComponent) dataTable: DataTableComponent;
  @ViewChild('categorizeProductsBtn') categorizeProductsBtn: ElementRef;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly featureService: FeaturesService,
    private readonly confirmationService: ConfirmationService,
    private readonly modalStatusService: ModalStatusService,
    private readonly router: Router
  ){
    this.subs$ = [];
  }

  ngOnInit() {
    registerLocaleData(es);
    this.isModal = this.router.url.indexOf('(modal:show/features/') >= 0;
    this.getParams();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  categorizeProducts() {
    const categorize$ = this.featureService.categorizeProductsByCsv(this.csv_text, this.id).subscribe(
      response => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.messages.warnings.csv_load_title'),
          this.translate.instant('resources.messages.warnings.csv_load_text')
        ).catch(() => {});
        this.modalStatusService.modalStatus.emit();
        this.closeModal();
      },
      error => {
        this.confirmationService.displayErrorAlert('Error', error.error.error);
      }
    );
    this.subs$.push(categorize$);
  }

  onFilterHandler(filterFormValue) {
    filterFormValue['feature_ids'] = this.element.pk;
    this.dataTable.onFilter(filterFormValue);
  }
  
  private getParams() {
    const route$ = this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.getFeature();
        this.setFilters();
      }
    });
    this.subs$.push(route$);
  }

  private getFeature() {
    const feature$ = this.featureService.getFeatureById(this.id).subscribe(
      response => {
        this.element = response;
        this.setTableCfg();
      },
      errorData => {
        this.confirmationService.displayErrorAlert('Error', errorData.error.error);
      }
    );
    this.subs$.push(feature$);
  }

  private setTableCfg() {
    this.productsTableConfig = {
      tokenPagination: true,
      requiresCacheService: true,
      isActive: true,
      tableActions: [
        { name: this.translate.instant( 'components.table_actions.export_csv' ),
          icon: 'ei-export',
          id: 'exportCSV'
        },
        {
          name: this.translate.instant( 'components.table_actions.categorize_products' ),
          icon: 'ei-file-add',
          id: 'categorizeProducts',
          onClick: () => {
            this.openCategorizeProductsModal();
          }
        }
      ],
      rowActions: [
        { name: this.translate.instant('resources.segments.row_actions.details'),
          icon: 'eye',
          id: 'details',
          customRoute: (element: { id: number }) => {
            return ['', {outlets: {modal: ['show', 'products', element.id ]}}];
          }
        },
        { name: this.translate.instant('resources.segments.row_actions.edit'),
          icon: 'pencil',
          id: 'edit',
          customRoute: (element: { id: number }) => {
            return ['', {outlets: {modal: ['update', 'products', element.id ]}}];
          }
        }
      ],
      columns: [
        { name: this.translate.instant('resources.products.columns.id'), prop: 'id' },
        { name: this.translate.instant('resources.products.columns.code'), prop: 'code' },
        { name: this.translate.instant('resources.products.columns.name'), prop: 'name' },
        { name: this.translate.instant('resources.products.columns.status'), prop: 'status' }
      ],
      requestData: {
        apiEndPoint: 'products',
        filtering: {
          feature_ids: this.element.pk
        }
      },
      rows: [],
      tableMapping: [
        { prop: 'id', type: 'number', apiProp: 'id' },
        { prop: 'code', type: 'text', apiProp: 'code', getValue: getDataTableTextPropValue('code') },
        { prop: 'name', type: 'text', apiProp: 'name', getValue: getDataTableTextPropValue('name') },
        { prop: 'status', type: 'badge', apiProp: 'status', badge: {dictionaryKey: 'products_status'}}
      ]
    };
  }

  private getStatus() {
    return [
      { id: 'inactive', name: this.translate.instant('resources.products.status.inactive') },
      { id: 'active', name: this.translate.instant('resources.products.status.active') }
    ];
  }

  private setFilters() {
    this.filters = [
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'id',
        label: 'resources.products.filters.id',
        type: 'number',
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'code',
        label: 'resources.products.filters.code',
        type: 'text',
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'name',
        label: 'resources.products.filters.name',
        type: 'text',
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'status',
        label: 'resources.messages.filters.status',
        options: this.getStatus(),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
      }),
    ];
  }

  private openCategorizeProductsModal() {
    this.categorizeProductsBtn.nativeElement.click();
  }

  private closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }
}
