import { AuthService } from './auth.service';
import { GOOGLE_ANALYTICS_TAG_MANAGER_EVENT, GoogleAnalyticsTagManagerService } from '../shared/services/google-analytics-tag-manager/google-analytics-tag-manager.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthGuard  {

  constructor(
    private readonly authService: AuthService,
    private readonly gatmService: GoogleAnalyticsTagManagerService,
    private readonly router: Router
  ) {}

  canActivate(): boolean {
    const isAuthenticated = this.authService.isAuthenticated();
    if (!isAuthenticated) {
      this.router.navigate(['/signin']).catch(() => {});
    } else {
      this.gatmService.setGoogleAnalyticsTagManager(GOOGLE_ANALYTICS_TAG_MANAGER_EVENT.UserData);
    }
    return isAuthenticated;
  }
}
