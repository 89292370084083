import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { CustomEntityKey } from '../../../../../../services/conditions/services/custom-entity-condition/enums/custom-entity-key.enums';
import { CustomEntityAttributeType } from '../../../../../../services/conditions/services/custom-entity-condition/enums/custom-entity-attribute-type.enums';
import { AttributesConfigColumnsList } from '../../enums/attributes-configuration-list.enums';
import { QuestionControlService } from '../../../../../../services/question-control.service';
import { DecimalPipe } from '@angular/common';
import { parseFloatAttributeValue } from '../../parsers/attributes.parser';
import { CustomEntity } from '../../../../../../../resources/customer-data-platform/custom-entities/models/custom-entity';
import { MultiselectDataSourceNumeric, Option } from '../../../../../../models/common/option';

@Component({
  selector: 'app-attributes-list-custom-entity-condition',
  templateUrl: './attributes-list-custom-entity-condition.component.html',
  styleUrls: ['./attributes-list-custom-entity-condition.component.scss']
})
export class AttributesListCustomEntityConditionComponent implements OnChanges {

  attributesConfigColumnList = AttributesConfigColumnsList;
  customEntityKey = CustomEntityKey;
  customEntityAttributeType = CustomEntityAttributeType;
  selectedEntity: MultiselectDataSourceNumeric;

  @Input() definitionIdValue: CustomEntity;
  @Input() attributesConfigArray: UntypedFormArray;

  constructor(
    private qcs: QuestionControlService,
    private decimalPipe: DecimalPipe
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.definitionIdValue) {
      this.handleDefinitionIdValueChange(changes.definitionIdValue.currentValue);
    }
  }

  attributeConfigItemList(attributeConfigCtrl: UntypedFormGroup, columnKey: string): string | number {
    let attributeType = attributeConfigCtrl.get(this.customEntityKey.AttributeType)?.value;
    const isFloatAttribute = this.isFloatAttribute(attributeConfigCtrl);
    if (isFloatAttribute) { attributeType = this.customEntityAttributeType.Float };
    if (this.isDateOrDatetimeAttribute(attributeConfigCtrl)) { attributeType = this.customEntityAttributeType.Date };

    const operatorKey = `${attributeType}_operator`;
    const attribute = attributeConfigCtrl.get(this.customEntityKey.Attribute)?.value?.[0];
    switch (columnKey) {
      case this.attributesConfigColumnList.AttributeName:
        return attribute.name;
      case this.attributesConfigColumnList.Operator:
        return attributeConfigCtrl.get(operatorKey)?.value?.[0]?.name;
      case this.attributesConfigColumnList.Value:
        return this.handleValueCase(attributeConfigCtrl, attribute, attributeType, operatorKey, isFloatAttribute);
      case this.attributesConfigColumnList.Value2:
        return this.getItemListValue(operatorKey, attributeConfigCtrl, attributeType, isFloatAttribute, true);
      default:
        return null;
    }
  }

  deleteAttribute(index: number): void {
    this.attributesConfigArray.removeAt(index);
  }

  /**
   * Handles the value case for an attribute based on its type and other conditions.
   *
   * @param attributeConfigCtrl - The form control containing the attribute configuration.
   * @param attribute - The attribute object containing its details.
   * @param attributeType - The type of the attribute (e.g., 'string', 'float').
   * @param operatorKey - The key representing the operator used for the attribute.
   * @param isFloatAttribute - A boolean indicating whether the attribute is a float.
   * @returns The processed attribute value as a string or number.
   *
   * This method checks if the attribute type is 'string' and if the selected entity exists without a multiselect value.
   * If these conditions are met, it filters and maps the attribute values to their names and joins them into a single string.
   * If the conditions are not met, it calls the `getItemListValue` method to process the attribute value.
   */
  private handleValueCase(attributeConfigCtrl: any, attribute: any, attributeType: string, operatorKey: string, isFloatAttribute: boolean): string | number {
    const hasStringMultiselectValue = !!attributeConfigCtrl.get(CustomEntityKey.StringValueMultiselect).value;
    const stringValue = attributeConfigCtrl.get(CustomEntityKey.StringValue).value;
    const attrValues = this.selectedEntity?.rawElement?.['entity_attributes']?.[attribute.id]?.values;

    if (attributeType === 'string' && this.selectedEntity && !hasStringMultiselectValue && attrValues?.length) {
      return attrValues.filter((attrVal: Option) => attrVal.id === stringValue).map(attrVal => attrVal.name).join(', ');
    }

    return this.getItemListValue(operatorKey, attributeConfigCtrl, attributeType, isFloatAttribute, false);
  }

  private getItemListValue(
    operatorKey: string,
    attributeConfigCtrl: UntypedFormGroup,
    attributeType: string,
    isFloatAttribute: boolean,
    isValue2?: boolean
  ): string | number {

    const valueKey = `${attributeType}_value`;
    const valueCtrlValue = attributeConfigCtrl.get(valueKey)?.value;
    const valueMultiKey = `${attributeType}_value_multiselect`;
    const valueMultiselect = attributeConfigCtrl.get(valueMultiKey)?.value;

    if(this.isOperator('eq', operatorKey, attributeConfigCtrl) && !isValue2 && valueMultiselect?.length){
      return valueMultiselect?.length ? valueMultiselect.map((value) => value.name).join(', ') : '';
    } else if (this.isOperator('bt', operatorKey, attributeConfigCtrl)) {
      const value2Key = `${attributeType}_value2`;
      const value2CtrlValue = attributeConfigCtrl.get(value2Key)?.value;
      return this.parseValuesBtOperator(isValue2 ? value2CtrlValue : valueCtrlValue, isFloatAttribute);
    } else if(this.isOperator('in', operatorKey, attributeConfigCtrl) && !isValue2){
      return valueMultiselect?.length ? valueMultiselect.map((value) => value.name).join(', ') : '';
    } else {
      return isValue2 ? null : this.getValueSimpleOperator(attributeConfigCtrl, valueCtrlValue);
    }
  }

  private getValueSimpleOperator(attributeConfigCtrl: UntypedFormGroup, valueCtrlValue: unknown): string | number {
    let value = null;
    if (valueCtrlValue && valueCtrlValue instanceof Array) {
      value =  valueCtrlValue.length ? valueCtrlValue[0]['name'] : null;
    } else if (valueCtrlValue && valueCtrlValue instanceof Object) {
      value = valueCtrlValue?.['name'] ?? null;
    } else {
      value = (valueCtrlValue ?? null) as string | number;
      if (this.isFloatAttribute(attributeConfigCtrl)) {
        value = parseFloatAttributeValue(value as number, this.decimalPipe);
      }
    }
    return value;
  }

  private parseValuesBtOperator(valueCtrlValue: unknown, isFloatAttribute: boolean): string | number {
    let value = valueCtrlValue && valueCtrlValue instanceof Object ? valueCtrlValue?.['name'] ?? null : valueCtrlValue ?? null;
    if (isFloatAttribute) {
      value = parseFloatAttributeValue(value as number, this.decimalPipe);
    }
    return value;
  }

  private isOperator(operator: string, operatorKey: string, attributeConfig: UntypedFormGroup): boolean {
    return this.qcs.hasFormKeyWithValue(attributeConfig, operatorKey, operator);
  }

  private isDateOrDatetimeAttribute(attributeConfig: UntypedFormGroup): boolean {
    return !!(this.isSelectedAttributeType(this.customEntityAttributeType.Datetime, attributeConfig) || this.isSelectedAttributeType(this.customEntityAttributeType.Date, attributeConfig));
  }

  private isFloatAttribute(attributeConfig: UntypedFormGroup): boolean {
    return !!(this.isSelectedAttributeType(this.customEntityAttributeType.Float, attributeConfig) || this.isSelectedAttributeType(this.customEntityAttributeType.Number, attributeConfig));
  }

  private isSelectedAttributeType(attributeType: string, attributeConfig: UntypedFormGroup): boolean {
    return attributeConfig?.get(this.customEntityKey.AttributeType)?.value === attributeType;
  }

  private handleDefinitionIdValueChange(newDefitionValue: MultiselectDataSourceNumeric[]): void {
    this.selectedEntity = Array.isArray(newDefitionValue) && newDefitionValue.length ? newDefitionValue[0] : null;
  }
}
