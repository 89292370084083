import { QuestionBase } from './question-base';

export class FileQuestion extends QuestionBase<string> {
  controlType = 'file';
  types: string[];
  multiple: boolean;
  maxFileSize?: number;

  constructor(options: {} = {}) {
    super(options);
    this.types = options['types'] || [];
    this.multiple = !!options['multiple'];
    this.maxFileSize = options['maxFileSize'];
  }
}
