import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, filter, takeUntil } from 'rxjs';

import { getCurrencySymbol } from '../../../shared/utils/common.utils';
import { BudgetInfoComponent } from '../alerts/budget-info/budget-info.component';
import { PlanCampaignsMock, PlanCampaign } from '../campaigns/plan-campaign';
import { DataTableConfiguration } from '../../../shared/components/data-table/data-table-cfg';
import { PlansService } from '../plans.service';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { PlanCampaignService } from '../campaigns/plan-campaign.service';
import { ModalStatusService } from '../../../shared/services/modal-status.service';
import { DictionaryService } from '../../../shared/services/dictionary.service';
import { DataTableComponent } from '../../../shared/components/data-table/data-table.component';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { MultiselectDataSource } from '../../../shared/components/multiselect/multiselect';
import { Plan } from '../plan';
import { ProfileService } from '../../../profiles/profile.service';
import { CompanyData } from '../../../shared/models/company/company-data';
import { FeatureFlagsService } from '../../../shared/services/feature-flags.service';
import { SharedModule } from '../../../shared/shared.module';
import { ConfigurationEntryResponse, ConfigurationService } from '../../../shared/services/configuration/configuration.service';

@Component({
  selector: 'app-plan-campaign-list',
  templateUrl: './plan-campaign-list.component.html',
  standalone: true,
  imports: [BudgetInfoComponent, CommonModule, RouterOutlet, SharedModule],
  providers: [DictionaryService, ConfigurationService]
})
export class PlanCampaignListComponent implements OnInit, OnDestroy {
  dataTableConfig: DataTableConfiguration;
  destroy$ = new Subject<void>();
  planId: string;
  plan: Plan;
  filters: QuestionBase<any>[];
  currencySymbol: string;
  companyInfo: CompanyData;
  flags = this.featureFlags.flags;
  budgetAlertEnabled = false;

  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  constructor(
    private readonly translate: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly plansService: PlansService,
    private readonly planCampaignService: PlanCampaignService,
    private readonly confirmationService: ConfirmationService,
    private readonly modalStatusSrv: ModalStatusService,
    private readonly dictionarySrv: DictionaryService,
    private readonly profileService: ProfileService,
    private readonly decimalPipe: DecimalPipe,
    private readonly featureFlags: FeatureFlagsService,
    private readonly configurationService: ConfigurationService,
  ) {
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency);
  }

  ngOnInit() {
    this.setFilters();

    // If navigating to this page again after opening a modal, recalculate the data
    this.router.events.pipe(filter(event => event instanceof NavigationEnd), takeUntil(this.destroy$)).subscribe(() => this.getParams());

    this.profileService.getRawUserProfile().subscribe(profileData => this.companyInfo = new CompanyData(profileData['company']));
    this.getParams();
    this.configurationService.getConfiguration('alerts', 'total_budget')
      .subscribe((config: ConfigurationEntryResponse) => this.budgetAlertEnabled = config.active);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onFilterHandler(filterFormValue) {
    this.dataTable.onFilter(filterFormValue);
  }

  handleRestoreStatus(campaign) {
    const title = this.translate.instant('resources.campaign_plans.messages.title_edit_completed');
    const text = this.translate.instant('resources.campaign_plans.messages.text_edit_completed');
    this.confirmationService.displayConfirmationAlert(title, text).then(data => {
      if ( data.hasOwnProperty('value') && data.value) {
        this.planCampaignService.restoreStatus(campaign.id).pipe(takeUntil(this.destroy$)).subscribe({
          next: () => {
            this.router.navigate([{ outlets: { modal: `show/plans/${this.planId}/campaigns/${campaign.id}/step_2`}}]).catch(() => {});
            this.modalStatusSrv.modalStatus.emit();
          },
          error: errorData => {
            this.confirmationService.displayErrorAlert( 'Error', errorData.error.error );
          }
        });
      }
    }).catch(() => {});
  }

  private getParams() {
    this.route.parent.params.pipe(takeUntil(this.destroy$)).subscribe(parentParams => {
      this.planId = parentParams.id;
      this.planCampaignService.setPlan(parseInt(parentParams.id, 10));
      this.getPlan(this.planId);
      this.dataTableConfig = this.getTableCfg(this.planId);
      // Ensure data table is initialized on config change
      if (this.dataTable) { this.dataTable.getData(); }
    });
  }

  private getPlan(planId: string) {
    this.plansService.getPlanById(planId).pipe(takeUntil(this.destroy$)).subscribe({
      next: (planData: HttpResponse<Object>) => {
        this.plan = new Plan(planData);
        this.disableCreateButton();
      },
      error: (errorData: HttpErrorResponse) => this.confirmationService.displayErrorAlert(
        this.translate.instant('common.error'),
        errorData.error.error
      )
    });
  }

  private confirmDelete(id: number) {
    const deleteCampaign = this.translate.instant('resources.campaign_plans.messages.title_edit_completed');
    const deleteText = this.translate.instant('resources.campaign_plans.messages.confirm_delete');
    this.confirmationService.displayConfirmationAlert(deleteCampaign, deleteText, 'question')
    .then(
      data => {
        if (data.hasOwnProperty('value') && data.value) {
          this.planCampaignService.deleteCampaignPlan(id).subscribe({
            next: (successDelete) => {
              this.confirmationService.displaySuccessAlert('', this.translate.instant('resources.campaign_plans.messages.delete_success')).catch(() => {});
              this.modalStatusSrv.modalStatus.emit();
            },
            error: (errorData) => {
              this.confirmationService.displayErrorAlert('Error', errorData.error.error);
            }
          });
        }
      }
    ).catch(() => {});
  }

  private disableCampaignActions() {
    if (this.plan) {
      return ['refreshing'].includes(this.plan.forecast_status) ||
            ['refreshing'].includes(this.plan.roi_status) ||
            ['sending'].includes(this.plan.status) ||
            this.plan.created_by_csv;
    }
  }

  private disableCreateButton() {
    if (this.plan?.created_by_csv) {
      this.dataTable.ableToCreate = false;
    }
  }

  private getTableCfg(planId?: string): DataTableConfiguration {
    // showPlanDetailCreditBalance
    let columns = [
      {name: this.translate.instant('resources.campaign_plans.columns.name'), prop: 'name'},
      {name: this.translate.instant('resources.campaign_plans.columns.type'), prop: 'type'},
      {name: this.translate.instant('resources.campaign_plans.columns.status'), prop: 'status'},
      {
        name: this.translate.instant('resources.campaign_plans.columns.delivery_cost', {currencySymbol: this.currencySymbol}),
        prop: 'delivery_cost'
      },
    ]

    if(!this.flags.showPlanDetailCreditBalance) {
      columns = columns.filter(column => column.prop !== 'delivery_cost');
    }

    return {
      isMock: false,
      mockConstant: PlanCampaignsMock,
      renderRowCheckbox: false,
      createButton: {
        label: this.translate.instant('resources.plans.buttons.add_campaign'),
        redirectTo: [{outlets: { modal: `show/plans/${this.planId}/campaigns/new/step_1` }}]
      },
      noElementsFoundCustomMessage: this.translate.instant('resources.campaign_plans.messages.no_campaigns'),
      isActive: true,
      tableActions: [],
      rowActions: [
        {
          name: this.translate.instant('common.buttons.show'),
          icon: 'eye',
          id: 'show',
          disabled: () => this.disableCampaignActions(),
          show: (campaign: PlanCampaign) => ['delivered'].includes(campaign.status),
          customRoute: (element: {id: string}) => ['', { outlets: { modal: `show/plans/${this.planId}/campaigns/${element.id}/step_2`}}]
        },
        {
          name: this.translate.instant('common.buttons.edit'),
          icon: 'pencil',
          id: 'edit',
          disabled: () => this.disableCampaignActions(),
          show: (campaign: PlanCampaign) => ['draft', 'error'].includes(campaign.status),
          customRoute: (element: {id: string}) => ['', { outlets: { modal: `show/plans/${this.planId}/campaigns/${element.id}/step_2`}}]
        },
        {
          name: this.translate.instant('common.buttons.edit'),
          icon: 'pencil',
          id: 'edit',
          disabled: () => this.disableCampaignActions(),
          show: (campaign: PlanCampaign) => ['completed', 'rejected'].includes(campaign.status),
          callback: (campaign: PlanCampaign) => this.handleRestoreStatus(campaign)
        },
        {
          name: this.translate.instant('common.buttons.clone'),
          icon: 'clone',
          id: 'clone',
          disabled: () => this.plan?.created_by_csv,
          show: (campaign: PlanCampaign) => ['draft'].includes(this.plan?.status) && ['draft', 'rejected', 'completed'].includes(campaign.status),
          customRoute: (element: {id: string}) => ['', { outlets: { modal: `show/plans/${this.planId}/campaigns/${element.id}/clone`}}]
        },
        {
          name: this.translate.instant('common.buttons.delete'),
          icon: 'trash',
          id: 'delete',
          disabled: () => this.disableCampaignActions(),
          show: (campaign: PlanCampaign) => ['draft', 'rejected'].includes(campaign.status),
          callback: (element) => this.confirmDelete(element.id)
        }
      ],
      rows: [],
      columns: columns,
      requestData: {
        apiEndPoint: `plans/${this.planId}/campaign_plans`,
        pageNumber: 0,
        sorting: {},
        filtering: {},
        numberPerPage: 10
      },
      tableMapping: [
        {
          prop: 'name',
          type: 'text',
          apiProp: 'name'
        },{
          prop: 'type',
          type: 'text',
          apiProp: 'type',
          getValue: (element: PlanCampaign) => this.getCampaignTypeTranslation(element)
        },
        {
          prop: 'status',
          type: 'badge',
          apiProp: 'status',
          badge: { dictionaryKey: 'campaign_plan_status' }},
        {
          prop: 'delivery_cost',
          type: 'text',
          apiProp: 'delivery_cost',
          getValue: (element: PlanCampaign) => this.getCampaignTotalCost(new PlanCampaign(element))
        }
      ]
    };
  }

  private getStatusOptions() {
    return this.dictionarySrv.getValuesByKey('campaign_plan_status');
  }

  private getPlanCampaignCategoryOptions(): MultiselectDataSource[] {
    return this.plansService.getStaticTypes().slice(0,
      4).map(el => new MultiselectDataSource(el.type, el.name));
  }

  private setFilters() {
    this.filters = [
      new MultiSelectQuestion({
        key: 'campaign_plan_ids',
        label: this.translate.instant('resources.plans.filters.campaign'),
        cssClasses: 'form-control input-default',
        settings: {singleSelection: false, enableSearchFilter: true, enableCheckAll: true, showCheckbox: true},
        dataSource: this.planCampaignService
      }),
      new MultiSelectQuestion({
        key: 'campaign_plan_type',
        label: this.translate.instant('resources.plans.filters.campaign_type'),
        cssClasses: 'form-control input-default',
        settings: {singleSelection: false, enableSearchFilter: false, enableCheckAll: true, showCheckbox: true},
        options: this.getPlanCampaignCategoryOptions()
      }),
      new MultiSelectQuestion({
        key: 'status',
        label: 'resources.plans.filters.status',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableSearchFilter: false},
        options: this.getStatusOptions()
      })
    ];
  }

  private getCampaignTypeTranslation(planCampaign: PlanCampaign): string {
    return this.translate.instant('resources.campaign_plans.types.dictionary.' + planCampaign.type);
  }

  private getCampaignTotalCost(planCampaign: PlanCampaign): string {
    const cost = planCampaign.requiredEmailCredits + planCampaign.requiredSmsCredits;
    return this.decimalPipe.transform(cost, '1.2-2') + ' ' + this.currencySymbol;
  }

}
