<div class="card">
  <div class="card-heading border bottom d-flex justify-content-between align-items-center">
    <h4 class="card-title">{{ 'resources.tickets.modal.cards.details.title' | translate }}</h4>
  </div>
  <div class="card-block">
    <ng-container *ngFor="let item of details; let i = index;">
      <h5 class="text-left" [ngClass]="i > 0 ? 'mrg-top-30' : ''">
        {{item.blockTitleKey | translate}}
      </h5>
      <ng-container *ngFor="let child of item.children; let j = index;">
        <ng-container *ngIf="child.visibility ?? true">
          <div class="personal-data-item">
            <span class="personal-data-item-key">
              {{child.titleKey | translate}}
              <em *ngIf="child.tooltip"
                class="ti-info-alt pull-right mrg-left-5 mrg-top-5 cursor-pointer"
                tooltipPosition="right"
                pTooltip="{{ child.tooltip | translate }}">
              </em>
            </span>
            <span class="personal-data-item-value break-personal-data-text" *ngIf="!child.link">
              {{child.value}}
            </span>
            <span class="personal-data-item-value break-personal-data-text" *ngIf="child.link">
              <a class="cursor-pointer" (click)="openUrlInNewTab(child.link)">
                {{child.value}}
              </a>
            </span>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="card-footer text-center">
    <button class="btn btn-info btn-sm"
      type="button"
      data-toggle="collapse"
      data-target=".multi-collapse"
      aria-expanded="false"
      aria-controls="multiCollapseExample1"
      [translate]="'resources.tickets.buttons.view_in_json'"
      (click)="scroll('json')">
    </button>
  </div>
</div>
