<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="false"  *ngIf="element">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="element.image">
                  <td class="no-border-top"><strong [translate]="'resources.promotions.show.fields.image'"></strong></td>
                  <td class="no-border-top"><img id="image" [src]="element.image" class="img-fluid" alt="promotion"></td>
                </tr>
                <tr *ngIf="element.id">
                  <td><strong [translate]="'resources.promotions.show.fields.id'"></strong></td>
                  <td>{{element.id}}</td>
                </tr>
                <tr *ngIf="element.name">
                  <td><strong [translate]="'resources.promotions.show.fields.name'"></strong></td>
                  <td>{{element.name}}</td>
                </tr>
                <tr *ngIf="element.description">
                  <td><strong [translate]="'resources.promotions.show.fields.description'"></strong></td>
                  <td>{{element.description}}</td>
                </tr>
                <tr *ngIf="element.discount_type">
                  <td><strong [translate]="'resources.promotions.show.fields.discount_type'"></strong></td>
                  <td *ngIf="element.discount_type === 'cash'" [translate]="'resources.promotions.show.fields.cash'"></td>
                  <td *ngIf="element.discount_type === 'percent'" [translate]="'resources.promotions.show.fields.percent'"></td>
                </tr>
                <tr *ngIf="element.cumulative">
                  <td><strong [translate]="'resources.promotions.show.fields.cumulative'"></strong></td>
                  <td *ngIf="element.cumulative"><span><em class="fa fa-check text-success"></em></span></td>
                  <td *ngIf="!element.cumulative"><span><em class="fa fa-times text-danger"></em></span></td>
                </tr>
                <tr *ngIf="element.discount">
                  <td><strong [translate]="'resources.promotions.show.fields.discount'"></strong></td>
                  <td *ngIf="element.discount_type === 'percent'">{{ element.discount | number: '1.2-2': 'es' }}%</td>
                  <td *ngIf="element.discount_type === 'cash'">{{ element.discount | number: '1.2-2': 'es' }}&nbsp;{{currencySymbol}}</td>
                </tr>
                <tr *ngIf="element.min_money">
                  <td><strong [translate]="'resources.promotions.show.fields.min_money'"></strong></td>
                  <td>{{ element.min_money | number: '1.2-2': 'es'}}</td>
                </tr>
                <tr *ngIf="element.max_money">
                  <td><strong [translate]="'resources.promotions.show.fields.max_money'"></strong></td>
                  <td>{{ element.max_money | number: '1.2-2': 'es' }}</td>
                </tr>
                <tr *ngIf="element.min_units">
                  <td><strong [translate]="'resources.promotions.show.fields.min_units'"></strong></td>
                  <td>{{element.min_units}}</td>
                </tr>
                <tr *ngIf="element.max_units">
                  <td><strong [translate]="'resources.promotions.show.fields.max_units'"></strong></td>
                  <td>{{element.max_units}}</td>
                </tr>
                <tr *ngIf="element.available_from">
                  <td><strong [translate]="'resources.promotions.show.fields.available_from'"></strong></td>
                  <td>{{element.available_from | date: 'dd/MM/yyyy'}}</td>
                </tr>
                <tr *ngIf="element.available_to">
                  <td><strong [translate]="'resources.promotions.show.fields.available_to'"></strong></td>
                  <td>{{element.available_to | date: 'dd/MM/yyyy'}}</td>
                </tr>
                <tr *ngIf="element.affectation">
                  <td><strong [translate]="'resources.promotions.show.fields.affectation'"></strong></td>
                  <td *ngIf="element.affectation === 'activity'" [translate]="'resources.coupons.modal.show.details.fields.activity'"></td>
                  <td *ngIf="element.affectation === 'attribute'" [translate]="'resources.coupons.modal.show.details.fields.attribute'"></td>
                  <td *ngIf="element.affectation === 'product'" [translate]="'resources.coupons.modal.show.details.fields.product'"></td>
                </tr>

                <tr *ngIf="element?.feature_pks !== '[]' && element?._embedded?.features_list?.length">
                  <td><strong [translate]="'resources.promotions.show.fields.product_category'"></strong><strong>&nbsp;({{element._embedded.features_list.length}})</strong></td>
                  <td>
                    <ul class="list arrow bullet-success">
                      <ng-container>
                        <li *ngFor="let item of element._embedded.features_list | orderBy:['name'] | slice: 0:5">
                          <span>{{ item.name | emptyData }}</span>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="element._embedded.features_list.length > 5">
                        <li>
                          <a (click)="viewAll()" class="text-blue pointer">
                            <u>{{'common.view_all' | translate}}</u>
                          </a>
                        </li>
                      </ng-container>
                    </ul>
                  </td>
                </tr>

                <tr *ngIf="element.product_ids?.length">
                  <td><strong [translate]="'resources.promotions.show.fields.products'"></strong><strong>&nbsp;({{element._embedded.products.length}})</strong></td>
                  <td>
                    <ul class="list arrow bullet-success">
                      <ng-container>
                        <li *ngFor="let item of element._embedded.products | orderBy:['name'] | slice: 0:5">
                          <span>{{ item.name | emptyData }}</span>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="element._embedded.products.length > 5">
                        <li>
                          <a (click)="viewAll()" class="text-blue pointer">
                            <u>{{'common.view_all' | translate}}</u>
                          </a>
                        </li>
                      </ng-container>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
