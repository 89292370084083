<div class="card">
  <div class="card-heading border bottom">
    <h4 class="text-bold card-title" [translate]="'resources.scores.loyalty_scores.fields.once'"></h4>
  </div>
  <div class="card-block">
    <div class="alert alert-info" role="alert">
      <span class="alert-text">{{'resources.scores.loyalty_scores.descriptions.validity_points_awarded' | translate}}</span>
    </div>
    <h5 class="text-bold mrg-top-10" [translate]="'resources.scores.loyalty_scores.fields.activation'"></h5>
    <div class="row mrg-top-10">
      <div class="col-md-4">
        <div class="form-group">
          <label class="text-bold" for="activation_delay">
            {{'resources.scores.loyalty_scores.fields.activation_delay' | translate}}
            <span class="text-danger">*</span>
          </label>
          <app-question [question]="getInputConfig('activation_delay')"
                        [form]="loyaltyScoresForm"
                        [disabled]="isUsed">
          </app-question>
        </div>
      </div>

      <div class="col-md-4 mrg-top-10">
        <div class="form-group">
          <label class="text-bold" for="activation_delay_unit"></label>
          <app-question [question]="getInputConfig('activation_delay_unit')" [form]="loyaltyScoresForm"
                        [disabled]="isUsed">
          </app-question>
        </div>
      </div>

      <div class="col-md-4 mrg-top-35">
        <div class="form-group" *ngIf="hasFormKeyWithValue('activation_delay_unit', 'months')"
          style="display: inline-flex">
          <app-question [question]="getInputConfig('activation_delay_beginning_month')"
                        [form]="loyaltyScoresForm"
                        [disabled]="isUsed">
          </app-question>
          <span class="mrg-top-5" [translate]="'resources.scores.loyalty_scores.fields.activation_delay_beginning_month'"></span>
        </div>
      </div>
    </div>

    <h5 class="text-bold" [translate]="'resources.scores.loyalty_scores.fields.expiration'"></h5>
    <div class="row height-50">
      <div class="col-md-12">
        <div class="form-group">
          <app-question [question]="getInputConfig('expiration_period_type')"
                        [form]="loyaltyScoresForm">
          </app-question>
        </div>
      </div>
    </div>

    <!-- IF RELATIVE PERIOD SELECTED -->
    <div class="row" *ngIf="hasFormKeyWithValue('expiration_period_type', 'relative')">
      <div class="col-md-4">
        <div class="form-group">
          <label class="text-bold" for="availability_duration">
            {{'resources.scores.loyalty_scores.fields.availability_duration' | translate}}
            <span class="text-danger">*</span>
          </label>
          <app-question [question]="getInputConfig('availability_duration')"
                        [form]="loyaltyScoresForm"
                        [disabled]="isUsed">
          </app-question>
        </div>
      </div>

      <div class="col-md-4 mrg-top-10">
        <div class="form-group">
          <label class="text-bold" for="availability_duration_unit"></label>
          <app-question [question]="getInputConfig('availability_duration_unit')"
                        [form]="loyaltyScoresForm"
                        [disabled]="isUsed">
          </app-question>
        </div>
      </div>

      <div class="col mrg-top-35">
        <div class="form-group" *ngIf="hasFormKeyWithValue('availability_duration_unit', 'months')"
          style="display: inline-flex">
          <app-question [question]="getInputConfig('availability_duration_natural')"
                        [form]="loyaltyScoresForm"
                        [disabled]="isUsed">
          </app-question>
          <span class="mrg-top-5" [translate]="'resources.scores.loyalty_scores.fields.availability_duration_natural'"></span>
        </div>
      </div>
      <!-- DEMO -->
       <ng-container *ngIf="flags.showDemoWallmart">
         <div class="col mrg-top-35">
           <div class="form-group" *ngIf="hasFormKeyWithValue('availability_duration_unit', 'months')"
             style="display: inline-flex">
             <app-question [question]="getInputConfig('customer_registration_date')"
                           [form]="loyaltyScoresForm"
                           [disabled]="isUsed">
             </app-question>
             <span class="mrg-top-5" [translate]="'resources.scores.loyalty_scores.fields.customer_registration_date'"></span>
           </div>
         </div>
       </ng-container>
       <!--  -->
    </div>
    <!--  -->

    <!-- DISPLAY IF FIXED PERIOD SELECTED -->
    <div class="row" *ngIf="hasFormKeyWithValue('expiration_period_type', 'fixed')">
      <div class="col-md-4 mrg-top-10">
        <div class="form-group">
          <label class="text-bold" for="fixed_option_selected"
            [translate]="'resources.scores.loyalty_scores.fields.expiration_occurs'"></label>
          <app-question [question]="getInputConfig('fixed_option_selected')"
                        [form]="loyaltyScoresForm"
                        [disabled]="isUsed"
                        (multiselectChanged)="displayExpirationDate($event)">
          </app-question>
          <ng-container *ngIf="expirationDate">
            <i class="ti-info-alt mrg-top-5"></i>&nbsp;
            <span [innerHTML]="'resources.scores.loyalty_scores.fields.expires' | translate: {expirationDate: expirationDate}"></span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
