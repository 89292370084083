import { ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LocationsService } from '../../../../resources/data-warehouse/locations/locations.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { MultiselectDataSource } from '../../multiselect/multiselect';

export function handleSelectedLocationTaxonomyTermIdsField(
  locationTaxonomyTermIdsSelected: MultiselectDataSource[], 
  key: string, 
  keyInput: QuestionBase<any>, 
  form: UntypedFormGroup, 
  changeDetector: ChangeDetectorRef, 
  locationsService: LocationsService
): void {
  if (locationTaxonomyTermIdsSelected?.length) {
    keyInput.selectedIds = [];
    keyInput.value = [];
    form.get(key).patchValue([]);
    changeDetector.detectChanges();
    const locationTaxonomyTermIdsValues = locationTaxonomyTermIdsSelected.map(location => location.id).join(',');
    locationsService.setLocationTaxonomyTermIds(locationTaxonomyTermIdsValues);
  } else {
    locationsService.setLocationTaxonomyTermIds(null);
  }
}

export function resetInputsForm(form: UntypedFormGroup, inputs: QuestionBase<unknown>[], keyInputs: string[], clearKeyInputsOptions?: string[]): void {
  const formObj = {};
  keyInputs.forEach(key => {
    const keyInput = inputs.find(input => input.key === key);
    if (keyInput) {
      keyInput.value = keyInput.dataSource ? [] : null;
      keyInput.selectedIds = [];
      if (clearKeyInputsOptions?.length && clearKeyInputsOptions.includes(key)) { keyInput.options = []; }
      formObj[key] = null;
    }
  });
  form.patchValue(formObj, {emitEvent: true});
}