<app-data-table-filter *ngIf="filters"
                       [slug]="filterSlug"
                       [questions]="filters" 
                       (onSubmitEmitter)="onFilterHandler($event)"
                       [cfg]="dataTableFilterConfig"
                       [flags]="flags">
</app-data-table-filter>

<div class="card" [class.no-pager]="isFranchiseRole">
  <div class="pdd-horizon-15 pdd-vertical-15">
    <app-data-table *ngIf="dataTableConfig" [config]="dataTableConfig" (dataEmitter)="dataTableEmitted($event)"></app-data-table>
  </div>
</div>
