export const BUY_5_GET_1 = {
  coupon_id: 'B5G1FREE001',
  name: 'Buy 5, Get 1 Free',
  description:
    'Buy 5 units of the specified product and get 1 additional unit for free.',
  type: 'multi_buy',
  mechanics: {
    buy_quantity: 5,
    get_quantity: 1,
    discount_type: 'free',
    applicable_products: [
      {
        product_id: '12345',
        product_name: 'Brand X Cereal 500g',
      },
    ],
  },
  validity: {
    start_date: '2024-07-01',
    end_date: '2024-07-31',
  },
  usage_limits: {
    max_redemptions: 3,
  },
  conditions: {
    min_purchase_amount: 0,
    max_purchase_amount: null,
  },
};

export const PERCENTAGE_BASED = {
  coupon_id: 'PERC10OFF',
  name: '10% Off Total Purchase',
  description: 'Get 10% off your total purchase.',
  type: 'percentage',
  mechanics: {
    discount_type: 'percentage',
    discount_value: 10,
    applicable_scope: {
      scope_type: 'total_ticket',
    },
  },
  validity: {
    start_date: '2024-07-01',
    end_date: '2024-07-31',
  },
  conditions: {
    min_purchase_amount: 20,
  },
  usage_limits: {
    max_redemptions_per_user: 2,
    total_redemptions: 500,
  },
  status: 'active',
};

export const MULTI_BUY = {
  coupon_id: 'B3G150OFF001',
  name: 'Buy 3, Get 50% Off the 4th Item',
  description:
    'Buy 3 units of the selected products and get 50% off the 4th unit.',
  type: 'multi_buy',
  mechanics: {
    buy_quantity: 3,
    get_quantity: 1,
    discount_type: 'percentage',
    discount_value: 50,
    applicable_scope: {
      scope_type: 'specific_products',
      products: [
        {
          product_id: 'A12345',
          product_name: 'Brand A Shampoo 250ml',
        },
        {
          product_id: 'B67890',
          product_name: 'Brand B Conditioner 250ml',
        },
      ],
    },
  },
  validity: {
    start_date: '2024-07-01',
    end_date: '2024-07-31',
  },
  conditions: {
    min_purchase_amount: 0,
    max_discount_amount: 10,
  },
  usage_limits: {
    max_redemptions_per_user: 5,
    total_redemptions: 1000,
  },
  status: 'active',
};
