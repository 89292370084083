import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../profiles/profile.service';
import { Role, RolesService } from '../../profiles/roles.service';
import { PERMISSIONS } from '../../shared/constants/module-permissions.constants';
import { Modules } from '../../shared/enums/modules.enums';
import { ConfirmationService } from '../../shared/services/confirmation.service';
import { FeatureFlagsService } from '../../shared/services/feature-flags.service';
import { HomeItem } from '../home/home-modules.constants';
import { NavItem } from './nav-item';

@Injectable({
  providedIn: 'root'
})
export class SideNavService {

  purchasedModules: string[] = this.profileService.getStoredUser().company?.purchased_modules || [];
  role: Role = this.profileService.getStoredUser().role;
  flags = this.featureFlags.flags;

  constructor(
    private profileService: ProfileService,
    private rolesService: RolesService,
    private translateService: TranslateService,
    private confirmService: ConfirmationService,
    private featureFlags: FeatureFlagsService,
  ) { }

  setModulePermissions<T extends NavItem | HomeItem>(navItems: T[]): T[] {
    navItems.forEach((item) => {
      this.setPermissions(item);
      if ('children' in item && item.children?.length) {
        this.setModulePermissions(item.children);
      }
    });
    return navItems;
  }

  isNotAuth(item: NavItem | HomeItem): boolean {
    const { isAuthorized, isNotAuthorized } = item;
    const hasFF = this.getModuleFF(item.id);

    return isNotAuthorized || !isAuthorized || hasFF || false;
  }

  showNotAvailable(): void {
    const text = this.translateService.instant('menu_dia.messages.unavailable');
    this.confirmService.displayAlert('', text, 'warning').catch(() => {});
  }

  private setPermissions(item: NavItem | HomeItem): void {
    const module = PERMISSIONS[item.id];

    item.isPurchased = item.isPurchased ?? (module?.slug ? this._isPurchased(module.slug) : true);
    item.isAuthorized = module?.authRoles?.length ? this._isAuthorized(module.authRoles) : true;
    item.isNotAuthorized = module?.notAuthRoles?.length ? this._isNotAuthorized(module.notAuthRoles) : false;
  }

  private _isPurchased(moduleSlug: string): boolean {
    return this.purchasedModules?.length ? this.purchasedModules.includes(moduleSlug) : true;
  }
  private _isAuthorized(roleSlugs: string[]): boolean {
    return this.rolesService.isAuthorized(roleSlugs, this.role);
  }
  private _isNotAuthorized(roleSlugs: string[]): boolean {
    return this.rolesService.isNotAuthorized(roleSlugs, this.role);
  }

  private getModuleFF(id: string): boolean {
    const moduleFlags: { [key: string]: boolean } = {
      [Modules.RetailGroupAnalytics]: !this.flags.retailGroupAnalyticsModuleVisibility,
      [Modules.BasicAnalytics]: !this.flags.basicAnalyticsModuleVisibility,
      [Modules.LoyaltyAnalytics]: !this.flags.loyaltyAnalyticsModuleVisibility,
      [Modules.RetailAnalytics]: !this.flags.retailAnalyticsModuleVisibility,
      [Modules.SupplierAnalytics]: !this.flags.supplierAnalyticsModuleVisibility,
      [Modules.Omnichannel]: this.flags.omnichannelModuleVisibility,
      [Modules.Audiences]: this.flags.audiencesModuleVisibility,
      [Modules.SystemStatus]: !this.flags.systemStatusModuleVisibility,
      [Modules.LoyalGPT]: !this.flags.loyalGPTVisibility,
    };

    const submoduleFlags: { [key: string]: boolean } = {
      [`${Modules.LoyaltyAnalytics}.program-performance`]: !this.flags.loyaltyAnalyticsProgramPerformanceVisibility,
      [`${Modules.LoyaltyAnalytics}.points-rewards`]: !this.flags.loyaltyAnalyticsPointsRewardsVisibility,
      [`${Modules.LoyaltyAnalytics}.program-ledgers`]: !this.flags.loyaltyAnalyticsProgramLedgersVisibility,
      [`${Modules.RetailAnalytics}.summary`]: !this.flags.retailAnalyticsSummaryVisibility,
      [`${Modules.RetailAnalytics}.products`]: !this.flags.retailAnalyticsProductsVisibility,
      [`${Modules.RetailAnalytics}.custom`]: !this.flags.retailAnalyticsCustomVisibility,
      [`${Modules.BasicAnalytics}.sales`]: !this.flags.basicAnalyticsSalesSectionVisibility,
      [`${Modules.BasicAnalytics}.customers`]: !this.flags.basicAnalyticsCustomersSectionVisibility,
      [`${Modules.SupplierAnalytics}.locations`]: !this.flags.supplierAnalyticsLocationsVisibility,
      [`${Modules.SupplierAnalytics}.categories`]: !this.flags.supplierAnalyticsCategoriesVisibility,
      [`${Modules.Loyalty}.milestones`]: !this.flags.milestoneSectionVisibility,
      [`${Modules.Loyalty}.challenges`]: !this.flags.challengesSectionVisibility,
      [`${Modules.Loyalty}.tiers-management.tiers-management`]: !this.flags.tiersManagementVisibility,
      [`${Modules.Loyalty}.audiences`]: !this.flags.loyaltyAudiencesVisibility,
      [`${Modules.Loyalty}.plan-type-configuration`]: !this.flags.planTypeVisibility,
      [`${Modules.Loyalty}.recipes`]: !this.flags.planTypeVisibility,
      [`${Modules.Loyalty}.referral-programs`]: !this.flags.showDemoWallmart,
      [`${Modules.Loyalty}.api-requests`]: !this.flags.simulateSectionVisibility,
      [`${Modules.OffersPersonalization}.audiences`]: !this.flags.offersPersonalizationAudiencesVisibility,
      [`${Modules.OffersPersonalization}.customer-journeys`]: !this.flags.showCustomerJourneysSection,
      [`${Modules.OffersPersonalization}.api-calls`]: !this.flags.showApiCallsSection,
      [`${Modules.Audiences}.consent`]: !this.flags.audiencesConsentVisibility,
      [`${Modules.CustomerDataPlatform}.dashboards`]: !this.flags.dashboardSectionVisibility,
      [`${Modules.CustomerDataPlatform}.custom-entities`]: !this.flags.customEntitiesVisibility,
      [`${Modules.CustomerDataPlatform}.connectors`]: !this.flags.connectorsVisibility,
      [`${Modules.CustomerDataPlatform}.gdpr.consent-purpose`]: !this.flags.consentPurposeVisibility,
      [`${Modules.Configuration}.plans`]: !this.flags.plansConfigVisiblity
    };
    const moduleFF: { [key: string]: boolean } = { ...moduleFlags, ...submoduleFlags };

    return moduleFF[id];
  }
}
