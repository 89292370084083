import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { LocationsService } from '../locations.service';
import { CountriesService } from '../../../../shared/services/countries.service';
import { ScoreExchangesService } from '../../../scores/score_exchanges/score-exchanges.service';
import { LocationsTaxonomyTermsService } from '../location-taxonomy-terms.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LocationForm } from '../../../../shared/models/locations/location-form';
import { getLocationsFormPayload } from './utils/form-payload.utils';
import { getLocationsFormInputs } from './utils/inputs.utils';
import { CODE_KEY_ERROR_MESSAGE, DUPLICATE_KEY_ERROR_MESSAGE, ID_KEY_ERROR_MESSAGE } from './constants/errors.constants';
import { LOCATIONS_FORM_FIELDS_TEMPLATE } from './constants/locations-fields-template.constants';

@Component({
  selector: 'app-form-locations',
  templateUrl: './form-locations.component.html',
  styleUrls: ['./form-locations.component.scss'],
  providers: [ScoreExchangesService, LocationsTaxonomyTermsService]
})
export class FormLocationsComponent implements OnInit, OnDestroy {

  locationsForm: UntypedFormGroup;
  inputs: QuestionBase<any>[];
  id: number | string;
  locationsFormFieldsTemplate: {key: string, hasLabel?: boolean, translateKey?: string}[] = LOCATIONS_FORM_FIELDS_TEMPLATE;

  private destroy$: Subject<void> = new Subject<void>();

  private get isEditMode(): boolean {
    return !!this.id;
  }

  @Output() formValidity: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private locationsService: LocationsService,
    private countriesService: CountriesService,
    private scoreExchangesService: ScoreExchangesService,
    private locationTaxonomyTermsService: LocationsTaxonomyTermsService,
    private confirmationService: ConfirmationService,
    private modalStatusService: ModalStatusService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  sendData(): void {
    const payload = getLocationsFormPayload(this.locationsForm, this.isEditMode);
    this.modalStatusService.formLoaderStatus.emit('loading');
    const request$ = this.isEditMode ? this.locationsService.updateData(payload, this.id) : this.locationsService.sendData(payload);
    request$.pipe(takeUntil(this.destroy$)).subscribe(
      () => this.handleSuccessRequest(),
      (errorData: HttpErrorResponse) => this.handleErrors(errorData)
    );
  }

  getInvalidFieldValue(key: string): { message: string} {
    return this.locationsForm.get(key)?.errors?.invalidFieldValue;
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.modalStatusService.formLoaderStatus.emit('loading_data');
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getLocationsById();
      } else {
        this.setInputsAndForm({});
      }
    });
  }

  private getLocationsById(): void {
    this.locationsService.getLocationsById(this.id).pipe(takeUntil(this.destroy$)).subscribe(
      (data: LocationForm) => this.setInputsAndForm(data),
      (errorData: HttpErrorResponse) => this.displayErrorAlert(errorData.error.error)
    );
  }

  private setInputsAndForm(requestData: LocationForm): void {
    this.inputs = this.getInputs(requestData);
    this.locationsForm = this.qcs.toFormGroup(this.inputs);
    this.formStatusChanges();
    if (!this.isEditMode) {
      this.setupFormSubscriptions();
    }
    this.modalStatusService.formLoaderStatus.emit('loading_data_finished');
  }

  private getInputs(formValues: LocationForm): QuestionBase<unknown>[] {
    this.inputs = getLocationsFormInputs(formValues, this.isEditMode, this.translate, this.countriesService, this.locationsService,
      this.locationTaxonomyTermsService, this.scoreExchangesService
    );
    return this.inputs;
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.locationsForm.status));
    this.locationsForm.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
  }

  private setupFormSubscriptions(): void {
    this.locationsForm.get('id').valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(
      idValue => this.getInputConfig('code').disabled = !!idValue
    );

    this.locationsForm.get('code').valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(
      codeValue => this.getInputConfig('id').disabled = !!codeValue
    );
  }

  private handleSuccessRequest(): void {
    const successTitle = this.translate.instant(this.id ? 'resources.locations.form.warnings.update_title' : 'resources.locations.form.warnings.success_title');
    const successText = this.translate.instant(this.id ? 'resources.locations.form.warnings.update_text' : 'resources.locations.form.warnings.success_text');
    this.confirmationService.displaySuccessAlert(successTitle, successText).catch(() => {});
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    this.modalStatusService.modalStatus.emit();
    this.returnToList();
  }

  private handleErrors(errorData: HttpErrorResponse): void {
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    const errorValue = errorData?.error?.error ?? null;
    if (errorData?.error?.errors?.length) {
      this.qcs.paintErrorsInForm(this.inputs, this.locationsForm, errorData.error.errors);
      if (errorValue) { this.displayErrorAlert(errorValue); }
    } else if (errorValue) {
      this.handleErrorValue(errorValue);
    }
  }

  private handleErrorValue(error: string): void {
    if (error.includes(DUPLICATE_KEY_ERROR_MESSAGE)) {
      if (error.includes(ID_KEY_ERROR_MESSAGE)) {
        this.setErrorsAndAlert('id', 'common.error_warnings.id_already_exists', 'common.error_warnings.duplicate_id_key');
      } else if (error.includes(CODE_KEY_ERROR_MESSAGE)) {
        this.setErrorsAndAlert('code', 'common.error_warnings.code_already_exists', 'common.error_warnings.duplicate_code_key');
      }
    } else {
      this.displayErrorAlert(error);
    }
  }

  private setErrorsAndAlert(fieldKey: string, fieldError: string, alertError: string): void {
    this.locationsForm.get(fieldKey).setErrors({'invalidFieldValue': { message: this.translate.instant(fieldError) }});
    this.locationsForm.get(fieldKey).markAsDirty();
    this.displayErrorAlert(this.translate.instant(alertError), 'common.error_warnings.review_data_entered');
  }

  private displayErrorAlert(error: string, titleError?: string): void {
    this.confirmationService.displayErrorAlert(this.translate.instant(titleError ? titleError : 'common.error'), error);
  }

  private returnToList(): void {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }
}
