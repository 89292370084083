import { Component, Input } from '@angular/core';
import { CustomEntityKey } from '../../../../../../services/conditions/services/custom-entity-condition/enums/custom-entity-key.enums';
import { CustomEntityAttributeType } from '../../../../../../services/conditions/services/custom-entity-condition/enums/custom-entity-attribute-type.enums';
import { QuestionBase } from '../../../../../../models/forms/question-base';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../../../services/question-control.service';
import { Operator } from '../../../../../../models/segments/conditions/operator';
import { getAttributesConfigurationFormGroup, resetAttributeConfigurationCtrl } from '../../utils/attributes-form.utils';
import { MultiselectDataSource } from '../../../../../multiselect/multiselect';
import { ATTRIBUTES_CONFIGURATION_OPERATORS_VALUES_FIELDS, NARROW_LAYOUT_CLASS, WIDE_LAYOUT_CLASS } from '../../constants/custom-entity-condition.constants';
import { MultiselectService } from '../../../../../multiselect/multiselect.service';

@Component({
  selector: 'app-attributes-custom-entity-condition',
  templateUrl: './attributes-custom-entity-condition.component.html',
  styleUrls: ['./attributes-custom-entity-condition.component.scss']
})
export class AttributesCustomEntityConditionComponent {

  wideLayout = WIDE_LAYOUT_CLASS;
  narrowLayout = NARROW_LAYOUT_CLASS;
  customEntityKey = CustomEntityKey;
  customEntityAttributeType = CustomEntityAttributeType;

  @Input() inputs: QuestionBase<unknown>[];
  @Input() form: UntypedFormGroup;
  @Input() attributesConfigArray: UntypedFormArray;
  @Input() newAttributeForm: UntypedFormGroup;

  constructor(
    private readonly qcs: QuestionControlService,
    private readonly multiselectService: MultiselectService
  ) {}

  ngOnInit(): void {
    this.handleInitialNewAttributeForm();
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  isSelectedAttributeType(attributeType: string): boolean {
    return this.newAttributeForm?.get(this.customEntityKey.AttributeType)?.value === attributeType;
  }

  isFloatAttribute(): boolean {
    return !!(this.isSelectedAttributeType(this.customEntityAttributeType.Float) || this.isSelectedAttributeType(this.customEntityAttributeType.Number));
  }

  isDateOrDatetimeAttribute(): boolean {
    return !!(this.isSelectedAttributeType(this.customEntityAttributeType.Datetime) || this.isSelectedAttributeType(this.customEntityAttributeType.Date));
  }

  isBtOperator(operatorKey: string): boolean {
    return this.qcs.hasFormKeyWithValue(this.newAttributeForm, operatorKey, 'bt');
  }

  classByOperator(operatorKey: string): string {
    return this.isBtOperator(operatorKey) ? this.narrowLayout : this.wideLayout;
  }

  handleSelectedAttribute(selectedAttribute: MultiselectDataSource): void {
    if (selectedAttribute) {
      this.handleAttributeSelectedOperatorAndValueFields(selectedAttribute);
    } else {
      this.newAttributeForm.reset();
    }
  }

  handleOperatorChanges(operator: Operator, keyValues: string[]) {
    const op = operator?.id || null;
    const selectedAttributeValues = this.getSelectedAttributeValues();
    this.populateStringValueMultiselect(selectedAttributeValues, true);

    if (this.isEqualOperatorWithArrayValues(op, selectedAttributeValues)) {
      this.populateStringValueMultiselect(selectedAttributeValues, false);
      resetAttributeConfigurationCtrl(this.newAttributeForm, keyValues);
    }

    if (!op || op !== 'bt') {
      resetAttributeConfigurationCtrl(this.newAttributeForm, keyValues);
    }
  }

  addNewAttribute(): void {
    if (this.newAttributeForm.valid) {
      this.attributesConfigArray.push(getAttributesConfigurationFormGroup(this.newAttributeForm.getRawValue()));
      this.newAttributeForm.reset();
    }
  }

  shouldShowMultiselect(): boolean {
    const operator = this.newAttributeForm.get(this.customEntityKey.StringOperator)?.value?.[0]?.id;
    const selectedAttributeValues = this.getSelectedAttributeValues();
    return this.isEqualOperatorWithArrayValues(operator, selectedAttributeValues);
  }

  private handleInitialNewAttributeForm(): void {
    const operator = this.form?.get(this.customEntityKey.StringOperator)?.value?.[0]?.id;
    const entityAttributeValue = this.form?.get(this.customEntityKey.Attribute)?.value?.[0];
    const entityAttributeRawElementValues = entityAttributeValue?.rawElement?.[this.customEntityKey.Values] ?? [];
    if (entityAttributeValue && this.isEqualOperatorWithArrayValues(operator, entityAttributeRawElementValues)) {
      this.populateStringValueMultiselect(entityAttributeRawElementValues, false);
    }
  }

  private getSelectedAttributeValues() {
    const attribute = this.newAttributeForm.get(this.customEntityKey.Attribute).value?.[0].id;
    return attribute ? this.form.value[this.customEntityKey.DefinitionId][0].rawElement.entity_attributes[attribute][this.customEntityKey.Values] : [];
  }

  private populateStringValueMultiselect(options: unknown[], clearOptions: boolean): void {
    const inputConfig = this.getInputConfig(this.customEntityKey.StringValueMultiselect);
    inputConfig.options = clearOptions ? [] : options;
    this.multiselectService.updateDropdownList.next(this.customEntityKey.StringValueMultiselect);
    inputConfig.value = null;
  }

  private isEqualOperatorWithArrayValues(operator: string, values: any[]): boolean {
    return operator === 'eq' && Array.isArray(values) && values.length > 0;
  }

  private handleAttributeSelectedOperatorAndValueFields(attribute: MultiselectDataSource): void {
    const attributeTypeData = attribute['rawElement']?.type ? attribute['rawElement'].type : null;
    this.newAttributeForm.get(this.customEntityKey.AttributeType).patchValue(attributeTypeData);
    resetAttributeConfigurationCtrl(this.newAttributeForm, ATTRIBUTES_CONFIGURATION_OPERATORS_VALUES_FIELDS);
    this.newAttributeForm.markAsUntouched();
  }
}
