import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { QuestionControlService } from '../../../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { FeaturesService } from '../../../services/products-features/features.service';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { ModalStatusService } from '../../../../../../shared/services/modal-status.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Feature } from '../../../../../../shared/models/products/feature';
import { isNullOrUndefined } from '../../../../../../shared/utils/common.utils';

@Component({
  selector: 'app-form-products-features',
  templateUrl: './form-products-features.component.html',
  styleUrls: ['./form-products-features.component.scss']
})

export class FormProductsFeaturesComponent implements OnInit, OnDestroy {

  id: number;
  inputs: QuestionBase<unknown>[];
  productsFeaturesForm: UntypedFormGroup;

  private readonly destroy$ = new Subject<void>();

  private get isEditMode(): boolean {
    return !!this.id;
  }

  @Output() formValidity = new EventEmitter<string>();

  constructor(
    private readonly qcs: QuestionControlService,
    private readonly productsFeaturesService: FeaturesService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly confirmationService: ConfirmationService,
    private readonly translate: TranslateService,
    private readonly modalStatusService: ModalStatusService
  ) {}

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  sendData(): void {
    const payload = this.getPayload();
    this.modalStatusService.formLoaderStatus.emit('loading');
    const request$ = this.isEditMode ? this.productsFeaturesService.updateById(payload, this.id) : this.productsFeaturesService.create(payload);
    request$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => this.handleSuccessRequest(),
      error: (errorData: HttpErrorResponse) => this.handleErrors(errorData)
    });
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.modalStatusService.formLoaderStatus.emit('loading_data');
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getFeatureById();
      } else {
        this.setInputsAndForm({});
      }
    });
  }

  private getFeatureById(): void {
    this.productsFeaturesService.getFeatureById(this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (data: Feature) => this.setInputsAndForm(data),
      error: (errorData: HttpErrorResponse) => this.displayHttpErrorAlert(errorData)
    });
  }

  private setInputsAndForm(requestData: Feature): void {
    this.inputs = this.productsFeaturesService.getInputs(requestData, this.isEditMode);
    this.productsFeaturesForm = this.qcs.toFormGroup(this.inputs);
    this.handleFormStatusChanges();
    this.modalStatusService.formLoaderStatus.emit('loading_data_finished');
  }

  private handleFormStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.productsFeaturesForm.status));
    this.productsFeaturesForm.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
  }

  private getPayload(): Feature {
    const formValue = this.productsFeaturesForm.getRawValue();
    const payload = {
      ...formValue,
      external_id: !isNullOrUndefined(formValue.external_id) ? parseInt(this.productsFeaturesForm.value.external_id, 10) : null,
      taxonomy_slug: formValue.taxonomy_slug?.length ? formValue.taxonomy_slug[0].id : null
    }
    return payload;
  }

  private handleSuccessRequest(): void {
    const successTitle = this.translate.instant(this.isEditMode ? 'resources.product_features.form.warnings.update_title' : 'resources.product_features.form.warnings.success_title');
    const successText = this.translate.instant(this.isEditMode ? 'resources.product_features.form.warnings.update_text' : 'resources.product_features.form.warnings.success_text');
    this.confirmationService.displaySuccessAlert(successTitle, successText).catch(() => {});
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    this.modalStatusService.modalStatus.emit();
    this.returnToList();
  }

  private handleErrors(errorData: HttpErrorResponse): void {
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    const errorValue = errorData?.error?.error ? errorData.error.error : null;
    if (errorData?.error?.errors?.length) {
      this.qcs.paintErrorsInForm(this.inputs, this.productsFeaturesForm, errorData.error.errors);
      if (errorValue) { this.displayHttpErrorAlert(errorData); }
    } else if (errorValue) {
      this.displayHttpErrorAlert(errorData);
    }
  }

  private displayHttpErrorAlert(error: HttpErrorResponse): void {
    this.confirmationService.displayHttpErrorAlert(error);
  }

  private returnToList(): void {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }
}
