import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MultiselectDataSource } from '../../components/multiselect/multiselect';
import { ResourcesService } from '../resources.service';
import { Option } from '../../models/common/option';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ConnectorActionsFieldsService extends ResourcesService {

  optsSource = new BehaviorSubject<string[] | string>(null);
  optsSource$ = this.optsSource.asObservable();

  constructor(
    http: HttpClient,
    private translate: TranslateService
  ) {
    super(http);
  }

  getConnectorActionsFields(): Observable<object> {
    const requestOptions = {
      apiEndPoint: '/connector_actions/fields',
      numberPerPage: 300,
      filtering: {},
      sorting: {
        byProp: '',
        direction: ''
      }
    };
    return this.getData(requestOptions);
  }

  fetchMultiselect( searchValues?: string, page?: number): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'connector_actions/fields',
      numberPerPage: 300,
      pageNumber: 1,
      filtering: {},
      sorting: {}
    };

    requestOptions.pageNumber = page ?? requestOptions.pageNumber;

    if (searchValues) {
      const filterObj = { filtering: { name: searchValues }};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `connector_actions/fields/${id}` });
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    element = element.hasOwnProperty('list') ? element.list[0] : element;
    return new MultiselectDataSource(element.alias, element.display_name, element);
  }

  getPeriodicityOptions(): Option[] {
    return [
      { id: 60, name: this.translate.instant('resources.connectors.periodicity_options.1_minute') },
      { id: 300, name: this.translate.instant('resources.connectors.periodicity_options.5_minutes') },
      { id: 600, name: this.translate.instant('resources.connectors.periodicity_options.10_minutes') },
      { id: 1800, name: this.translate.instant('resources.connectors.periodicity_options.30_minutes') },
      { id: 3600, name: this.translate.instant('resources.connectors.periodicity_options.1_hour') },
      { id: 7200, name: this.translate.instant('resources.connectors.periodicity_options.2_hours') },
      { id: 14400, name: this.translate.instant('resources.connectors.periodicity_options.4_hours') },
      { id: 21600, name: this.translate.instant('resources.connectors.periodicity_options.6_hours') },
      { id: 28800, name: this.translate.instant('resources.connectors.periodicity_options.8_hours') },
      { id: 36000, name: this.translate.instant('resources.connectors.periodicity_options.10_hours') },
      { id: 43200, name: this.translate.instant('resources.connectors.periodicity_options.12_hours') },
      { id: 86400, name: this.translate.instant('resources.connectors.periodicity_options.once_a_day') },
      { id: 604800, name: this.translate.instant('resources.connectors.periodicity_options.once_a_week') },
      { id: 2592000, name: this.translate.instant('resources.connectors.periodicity_options.once_a_month') },
    ];
  }
}
