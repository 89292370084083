import 'moment/locale/en-gb';
import 'moment/locale/es';
import { DictionaryService } from './dictionary.service';
import { Injectable } from '@angular/core';
import { MultiselectDataSource } from '../components/multiselect/multiselect';
import { ProfileService } from '../../profiles/profile.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { TitleCasePipe } from '@angular/common';
import { FeatureFlagsService } from './feature-flags.service';

@Injectable()
export class DateService {

  flags = this.featureFlags.flags;
  private locale: string;

  constructor(
    private translate: TranslateService,
    private dictionary: DictionaryService,
    private titleCasePipe: TitleCasePipe,
    private currentUserProfile: ProfileService,
    private readonly featureFlags: FeatureFlagsService
  ) {
    this.loadLocalization();
  }

  parseTodayWithFormat(format: string): string {
    return moment(new Date()).format(format);
  }

  parseDateWithFormat(dateStringValue: string, format: string) {
    return moment(new Date(dateStringValue)).format(format);
  }

  parseUTCDateWithFormat(dateStringValue: string, format: string) {
    return moment(new Date(dateStringValue)).utc().format(format);
  }

  parseNumericDateWithFormatFromNow(dateStringValue: number) {
    return moment(dateStringValue).fromNow();
  }

  // Get Date without changing by time zone
  dateStringToDateTimezoneOffset(dateString: string): Date {
    const date = new Date(dateString);
    return new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));
  }

  /**
   * Get string format date without changing by time zone from timestamp
   * Optional capitalize first letter of the format as moment doesn't capitalize in other languages than English
   */
  dateTimestampToStringDateTimezoneOffset(timestamp: number, format: string, capitalizeFirstLetter = false): string {
    const date = new Date(timestamp);
    const dateTimestampTimezoneOff = new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));
    const formatDateTimezoneOff = moment(new Date(dateTimestampTimezoneOff)).format(format);
    return capitalizeFirstLetter ? this.titleCasePipe.transform(formatDateTimezoneOff) : formatDateTimezoneOff;
  }

  dateISOStringToDateTime(dateIsoString: string): string {
    const date = new Date(dateIsoString);
    const timeOffset = date.getTimezoneOffset() * 60000;
    return (new Date(+date - timeOffset)).toISOString().slice(0, -1);
  }

  /**
   * dateStringValue: Must be a string of the type: dd/mm/yyyy
   */
  isFutureDate(dateStringValue: string): boolean {
    const today = moment();
    const splittedDate = dateStringValue.split('/');
    const momentDate = moment(new Date(`${splittedDate[1]}-${splittedDate[0]}-${splittedDate[2]}`));
    return momentDate.isValid() && momentDate.isAfter(today);
  }

  momentStrictFormat(dateStringValue: string, dateStringFormat: string, newDateStringFormat: string): string {
    return moment(dateStringValue, dateStringFormat).format(newDateStringFormat);
  }

  momentFormat(dateStringValue: any, format: string) {
    return moment(dateStringValue).format(format);
  }

  momentStartOfTypeAndFormat(startOf: any, format: string): any {
    return moment().startOf(startOf).format(format);
  }

  momentStartOfPreviousPeriodTypeAndFormat(time: number, period: any, startOf: any, format: string): any {
    return moment().subtract(time, period).startOf(startOf).format(format);
  }

  momentStartOfFromDateTypeAndFormat(date: string, startOf: any, format: string, inMilliseconds?: boolean): any {
    const parsedDate = moment(date).startOf(startOf);
    return inMilliseconds ? parsedDate.valueOf() : parsedDate.format(format);
  }

  momentEndOfTypeAndFormat(endOf: any, format: string): any {
    return moment().endOf(endOf).format(format);
  }

  momentEndOfPreviousPeriodTypeAndFormat(time: number, period: any, endOf: any, format: string): any {
    return moment().subtract(time, period).endOf(endOf).format(format);
  }

  hasChangedTimezone(initDate, endDate) {
    return (initDate.getTimezoneOffset() !== endDate.getTimezoneOffset());
  }

  applyTimezoneChange(initDate, endDate) {
    const minuteOffset = (initDate.getTimezoneOffset() - endDate.getTimezoneOffset());
    endDate.setTime(endDate.getTime() - minuteOffset * 60 * 1000);
  }

  nPeriodsAgo(date, n, period): any {
    const result = moment(date);
    if (['year', 'semester', 'quarter', 'month'].indexOf( period ) >= 0) {
      result.add(-n, 'years');
    } else {
      result.add(-n * 364, 'days');
    }
    return result.format('YYYY-MM-DD');
  }

  getYearOptions(threshold: number): MultiselectDataSource[] {
    const years: MultiselectDataSource[] = [];
    const currentYear = moment().year();
    for (let index = 0; index <= threshold; index++) {
      const year = `${currentYear - index}`;
      years.push( new MultiselectDataSource(year, year) );
    }
    return years;
  }

  getComparedYearOptions(threshold: number): {id: number, name: string}[] {
    const currentYear = moment().year();
    const yearOptions = [];
    const vs = this.translate.instant('common.versus').toLowerCase();

    for (let index = 0; index < threshold; index++) {
      const id = currentYear - index;
      yearOptions.push({id: id, name: `${id} ${vs} ${id - 1}`});
    }

    return yearOptions;
  }

  assignHourAndMinuteToDefaultDate(inputTimeValue: string): Date {
    const date = new Date();
    date.setHours(parseInt(inputTimeValue.split(':')[0], 10));
    date.setMinutes(parseInt(inputTimeValue.split(':')[1], 10));
    return date;
  }

  samePeriodLastYear(dateString: string, format: string = 'YYYY-MM-DD'): string {
    return moment(dateString).subtract(1, 'years').format(format);
  }

  dynamicDateToDateOption(value) {
    if (!(value && value.length)) { return {id: null, name: null}; }
    if ( value.indexOf('%') >= 0 ) {
      let dynamicDateValues = this.dictionary.getValuesByKey('dynamic_dates');
      if (this.flags.showMinutesInDynamicDates) {
        dynamicDateValues = [...this.dictionary.getValuesByKey('dynamic_dates_minutes'), ...dynamicDateValues];
      }
      const item = dynamicDateValues.find( element => element.id === value);
      return { id: item.id, name: this.translate.instant(item.name) };
    } else {
      const date = moment.utc(value);
      return {
        id: date.format('YYYY-MM-DD'),
        name: date.format('DD/MM/YYYY')
      };
    }
  }

  isDate2BeforeDate1(date1: string, date2: string): boolean {
    const _date1 = moment(date1);
    const _date2 = moment(date2);
    return _date2.isBefore(_date1);
  }

  isValidDate(date: string): boolean {
    return moment(date).isValid();
  }

  isSameOrBefore(momentDate): boolean {
    return moment(moment()).isSameOrBefore(momentDate, 'd');
  }

  getDatesDiffByTimeUnit(date1: Date, date2: Date, timeUnit: moment.unitOfTime.DurationConstructor, includeBothDates?: boolean): number {
    const mDate1 = moment(date1);
    const mDate2 = moment(date2);
    const result = mDate2.diff(mDate1, timeUnit);
    return includeBothDates ? result + 1 : result;
  }

  calculateDate(operation: string, quantity: number, timeUnit: moment.unitOfTime.DurationConstructor, _fromDate?: Date, _format?: string) {
    const fromDate = _fromDate ? moment(_fromDate) : moment();
    const format = _format ? _format : 'YYYY-MM-DD';
    if(operation === 'substract') {
      return fromDate.subtract(quantity, timeUnit).format(format);
    } else if(operation === 'add') {
      return fromDate.add(quantity, timeUnit).format(format);
    }
  }

  calculateUTCDate(operation: string, quantity: number, timeUnit: moment.unitOfTime.DurationConstructor, _fromDate?: Date, _format?: string) {
    const fromDate = _fromDate ? moment(_fromDate).utc() : moment().utc();
    const format = _format ? _format : 'YYYY-MM-DD';
    if(operation === 'substract') {
      return fromDate.subtract(quantity, timeUnit).format(format);
    } else if(operation === 'add') {
      return fromDate.add(quantity, timeUnit).format(format);
    }
  }

  getTzGuessMomentYearMonthDate(selectedYear: string, selectedMonth: string): moment.Moment {
    return moment.tz(`${selectedYear}-${selectedMonth}`, moment.tz.guess());
  }

  private loadLocalization() {
    const user = this.currentUserProfile.getStoredUser();
    this.locale = user.preferred_language ? user.preferred_language : user.company['locale'].replace('_', '-');
    moment.locale(this.locale);
  }

}
