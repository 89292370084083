import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from '../resources.service';

@Injectable()
export class AlertsService extends ResourcesService {
  constructor(http: HttpClient) {
    super(http);
  }

  getAlertsByEntity(baseEndPoint: string, entityId: string | number) {
    if (!baseEndPoint || !entityId) { throw new Error('baseEndPoint and entityId are required'); }
    return this.getData({ apiEndPoint: `${baseEndPoint}/${entityId}/alerts` });
  }

  acknowledgeAlert(baseEndPoint: string, entityId: string | number, alert_id: string | number = undefined) {
    if (!baseEndPoint) { throw new Error('baseEndPoint is required'); }
    return this.postResource({alert_id}, `${baseEndPoint}/${entityId}/alerts/acknowledge`);
  }

  unacknowledgeAlert(baseEndPoint: string, entityId: string | number, alert_id: string | number = undefined) {
    if (!baseEndPoint) { throw new Error('baseEndPoint is required'); }
    return this.postResource({alert_id}, `${baseEndPoint}/${entityId}/alerts/unacknowledge`);
  }
}
