import { item, row, section } from '../coupon-utils/coupon-form-section-utils';
import { IFormSection } from '../models/coupon-form.model';

/************************************/
/* COUPON SECTIONS                  */
/************************************/

const BASIC_INFO_1: IFormSection = section('basic_info_1', [
  row([item('6', 'id'), item('6', 'static_code', { toolTip: true })]),
]);
const BASIC_INFO_2: IFormSection = section('basic_info_2', [
  row([item('12', 'name')]),
  row([item('12', 'description')]),
]);
const BASIC_INFO_3: IFormSection = section('basic_info_3', [
  row([item('12', 'image')]),
  row([item('12', 'auto_select')]),
]);

/* ------------------------------- */

const DISCOUNT_1: IFormSection = section('discount_1', [
  row([
    item('6', 'coupon_type', { selectChange: true, toolTip: true }),
    item('6', 'discount_type', { selectChange: true, toolTip: true }),
  ]),
  row([item('12', 'affectation', { selectChange: true })]),
]);
const DISCOUNT_2: IFormSection = section('discount_2', [
  row([
    item('12', 'taxonomy', { selectChange: true }),
    item('12', 'feature_pks'),
  ]),
]);
const DISCOUNT_3: IFormSection = section('discount_3', [
  row([item('12', 'applies_to')]),
  row([
    item('3', 'feature_pks', { altLabel: 'purchased_feature_pks' }),
    item('3', 'purchased_feature_pks', { altLabel: 'purchased_affectation' }),
  ]),
]);
const DISCOUNT_4: IFormSection = section('discount_4', [
  row([item('11', 'product_ids', { useToken: true })]),
]);
const DISCOUNT_5: IFormSection = section('discount_5', [
  row([item('12', 'discount', { toolTip: true })]),
]);
const DISCOUNT_6: IFormSection = section('discount_6', [
  row([
    item('6', 'excluded_feature_pks'),
    item('5', 'excluded_product_ids', { useToken: true }),
  ]),
]);
const DISCOUNT_7: IFormSection = section('discount_7', [
  row([
    item('3', 'add_payment_method', { noLabel: true }),
    item('3', 'payment_method_ids', {}),
    item('3', 'payment_method_discount_type', {}),
    item('3', 'payment_method_discount', {}),
  ]),
]);

/* ------------------------------- */

const POINTS: IFormSection = section('points', [
  row([
    item('2', 'reward_points', { noLabel: true }),
    item('5', 'points'),
    item('5', 'points_money'),
  ]),
]);

const AVAILABILITY: IFormSection = section('availability', [
  row([
    item('3', 'available_days'),
    item('3', 'available_from'),
    item('3', 'available_to'),
    item('3', 'priority'),
  ]),
  row([item('6', 'feature_location_ids'), item('6', 'location_ids')]),
]);

const PRINT_CFG: IFormSection = section('print_cfg', [
  row([
    item('2', 'printable', { noLabel: true }),
    item('5', 'print_from'),
    item('5', 'print_to'),
  ]),
]);

const REDEMPTION: IFormSection = section('redemption', [
  row([
    item('3', 'min_money', { currency: true, toolTip: true }),
    item('3', 'max_money', { currency: true, toolTip: true }),
    item('3', 'min_units'),
    item('3', 'max_units'),
  ]),
  row([
    item('3', 'max_redemptions', { toolTip: true }),
    item('3', 'cumulative', { noLabel: true }),
    item('3', 'cumulative_with_other', { noLabel: true, toolTip: true }),
    item('3', 'discount_priority'),
  ]),
]);

const PREDICTION: IFormSection = section('prediction', [
  row([
    item('4', 'estimated_customer_redemption', { toolTip: true }),
    item('4', 'estimated_redemption', { toolTip: true }),
    item('4', 'estimated_delivery', { toolTip: true }),
  ]),
]);

const THIRD_PARTY: IFormSection = section('third_party', [
  row([
    item('6', 'provider_id', { toolTip: true }),
    item('6', 'funded', { noLabel: true }),
  ]),
  row([item('6', 'partner_id')]),
]);

const INTEGRATION: IFormSection = section('integration', [
  row([item('6', 'external_id'), item('6', 'key', { toolTip: true })]),
]);

export const COUPON_SECTIONS = [
  BASIC_INFO_1,
  BASIC_INFO_2,
  BASIC_INFO_3,
  DISCOUNT_1,
  DISCOUNT_2,
  DISCOUNT_3,
  DISCOUNT_4,
  DISCOUNT_5,
  DISCOUNT_6,
  DISCOUNT_7,
  POINTS,
  AVAILABILITY,
  PRINT_CFG,
  REDEMPTION,
  PREDICTION,
  THIRD_PARTY,
  INTEGRATION,
];
