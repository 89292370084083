import { BehaviorSubject } from 'rxjs';
import { Campaign } from '../../models/campaign/campaign';
import { CampaignCategoriesService } from '../../../resources/campaigns/campaign-categories/campaign-categories.service';
import { CampaignPayload } from '../../models/campaign/campaign-payload';
import { CampaignsSenderService } from '../../services/campaigns-sender.service';
import { CampaignsService } from '../../../resources/campaigns/campaigns.service';
import { CheckboxQuestion } from '../../models/forms/question-checkbox';
import { CouponHistoriesService } from '../../../resources/coupons/coupon-histories.service';
import { CouponsService } from '../../../resources/coupons/coupons.service';
import { DateTimeZoneService } from '../../services/date-time-zone.service';
import { EmailTemplatesService } from '../../services/email-templates.service';
import { FeatureFlagsService } from '../../services/feature-flags.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessagesService } from '../../../resources/content-designer/campaign-messages/messages.service';
import { MultiSelectQuestion } from '../../models/forms/question-multiselect';
import { PushTemplatesService } from '../../services/push-templates.service';
import { QuestionBase } from '../../models/forms/question-base';
import { QuestionControlService } from '../../services/question-control.service';
import { RadioQuestion } from '../../models/forms/question-radio';
import { SegmentsService } from '../../../resources/segments/segments.service';
import { SmsTemplatesService } from '../../services/sms-templates.service';
import { Subscription } from 'rxjs';
import { TextboxQuestion } from '../../models/forms/question-textbox';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';
import { CollectivesService } from '../../../resources/data-warehouse/profiles/collectives.service';
import { isNullOrUndefined } from '../../utils/common.utils';
import { LoyaltyScoresService } from '../../../resources/scores/loyalty_scores/loyalty-scores.service';
import { ChallengesService } from '../../../resources/scores/challenges.service';

@Injectable()
export class CampaignsFormService {

  eventsSource = new BehaviorSubject<string>('init');
  eventItem$ = this.eventsSource.asObservable();

  scheduledAtMsg = new BehaviorSubject<string>('');
  scheduledAtMsg$ = this.scheduledAtMsg.asObservable();

  globalEventsSource = new BehaviorSubject<string>('');
  globalEventSource$ = this.globalEventsSource.asObservable();

  inputs: QuestionBase<any>[];
  flags = this.featureFlags.flags;
  subscriptions: Subscription[] = [];

  constructor(
    private qcs: QuestionControlService,
    private campaignCategoriesService: CampaignCategoriesService,
    private campaignsService: CampaignsService,
    private couponsService: CouponsService,
    private couponHistoriesService: CouponHistoriesService,
    private messagesService: MessagesService,
    private segmentsService: SegmentsService,
    private emailTemplateService: EmailTemplatesService,
    private smsTemplatesService: SmsTemplatesService,
    private pushTemplatesService: PushTemplatesService,
    private dateService: DateTimeZoneService,
    private translate: TranslateService,
    private pushService: PushTemplatesService,
    private smsService: SmsTemplatesService,
    private featureFlags: FeatureFlagsService,
    private campaignsSendersService: CampaignsSenderService,
    private collectivesService: CollectivesService,
    private loyaltyScoreService: LoyaltyScoresService,
    private challengeService: ChallengesService
  ) {}

  addFormFieldsSubscriptions(form: UntypedFormGroup, campaign: Campaign, mode?: string) {
    /* Detect changes only in required fields, not the whole form due to Stack error when changing values */
    this.subscriptions.push(form.controls['via'].valueChanges.subscribe(
      () => {
        this.globalEventsSource.next('inform_change');
        this.handleViaValueChanged(form, campaign, mode);
      }
    ));
    this.subscriptions.push(form.controls['scheduled_at'].valueChanges.subscribe(
      () => {
        this.globalEventsSource.next('inform_change');
        this.handleScheduledAtValueChanged(form);
      }
    ));
    this.subscriptions.push(form.controls['periodic'].valueChanges.subscribe(
      () => {
        this.globalEventsSource.next('inform_change');
        this.handlePeriodicValueChanged(form);
      }
    ));
    this.subscriptions.push(form.controls['execution'].valueChanges.subscribe(
      () => {
        this.globalEventsSource.next('inform_change');
        this.handleExecutionValueChanged(form);
      }
    ));
    this.subscriptions.push(form.controls['motive'].valueChanges.subscribe(
      () => {
        this.globalEventsSource.next('inform_change');
        this.handleMotiveValueChanged(form);
      }
    ));

    // Show periodic campaign execution message when already existing campaign is loaded
    this.handleExecutionValueChanged(form);
  }

  removeFormFieldsSubscriptions() {
    this.subscriptions.forEach(s$ => s$.unsubscribe());
  }

  getCampaignInputs(campaign?: Campaign, selectedCoupon?: unknown): QuestionBase<any>[] {

    if (campaign?.reminder) this.couponHistoriesService.setCoupon(campaign.coupon_ids[0]);

    this.inputs = [
      new TextboxQuestion({
        cssClasses: 'form-control input-lg font-size-18',
        key: 'name',
        order: 1,
        type: 'text',
        placeholder: this.translate.instant('resources.campaigns.fields.name_placeholder'),
        value: campaign?.name,
        required: true,
        disabled: campaign && !campaign.editable
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'description',
        order: 2,
        type: 'text',
        value: campaign?.description,
        disabled: campaign && !campaign.editable
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'campaign_category',
        dataSource: this.campaignCategoriesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        order: 3,
        value: campaign?.campaign_category ? [campaign.campaign_category] : [{ id: 0, name: this.translate.instant('resources.campaign_categories.messages.no_category') }],
        disabled: campaign && !campaign.editable
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'via',
        order: 4,
        type: 'multiselect',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        options: this.campaignsService.getCampaignCreationChannels(),
        required: true,
        value: campaign?.via ? [this.campaignsService.getCampaignCreationChannels().find(item => item.id === campaign.via)] : [],
        disabled: campaign && !campaign.editable
      }),
      new RadioQuestion({
        cssClasses: 'radio-inline radio-info',
        key: 'motive',
        order: 5,
        type: 'radio',
        options: this.campaignsService.getCampaignMotives(),
        value: this.getMotiveValue(campaign),
        disabled: !campaign?.editable || campaign?.via === 'ticket_message'
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'scheduled_at',
        order: 6,
        type: 'datetime-local',
        step: 60,
        value: campaign?.scheduled_at ? this.dateService.dateISOStringToDateTime(campaign.scheduled_at) : null,
        helpText: this.translate.instant('resources.campaigns.fields.scheduled_at_help_block'),
        disabled: campaign && !campaign.editable
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'periodic',
        label: this.translate.instant('resources.campaigns.fields.periodic_deliveries'),
        order: 7,
        disabled: !campaign?.editable || !campaign?.scheduled_at,
        value: campaign?.periodic
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'execution',
        options: this.campaignsService.getExecutionPeriods(),
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 8,
        disabled: !campaign?.editable || !campaign?.periodic,
        value: campaign?.execution ? [this.campaignsService.getExecutionPeriods().find(item => item.id === campaign.execution)] : []
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'execution_until',
        order: 8,
        type: 'date',
        disabled: campaign && !campaign.editable || !campaign?.execution,
        value: campaign?.execution_until
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'coupon_ids',
        dataSource: this.couponsService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        order: 9,
        disabled: campaign && !campaign.editable,
        selectedIds: this.getCouponSelectedIds(campaign, selectedCoupon)
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'reward',
        label: this.translate.instant('resources.campaigns.fields.reward'),
        order: 10,
        disabled: campaign && !campaign.editable,
        value: campaign?.reward
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'campaign_history_coupon_id',
        dataSource: this.couponHistoriesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        order: 11,
        disabled: campaign && !campaign.editable,
        selectedIds: campaign?.campaign_history_coupon_id ? [campaign.campaign_history_coupon_id] : []
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'message_ids',
        dataSource: this.messagesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        order: 12,
        disabled: campaign && !campaign.editable,
        selectedIds: campaign?.message_ids?.length > 0 ? campaign?.message_ids : []
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'segment',
        dataSource: this.segmentsService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        order: 13,
        disabled: !campaign?.editable || campaign?.csv_external_data_url,
        selectedIds: campaign?.segment_id ? [campaign.segment_id] : []
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'control_group_flag',
        label: this.translate.instant('resources.campaigns.fields.control_group_flag'),
        disabled: campaign && !campaign.editable,
        value: campaign?.control_group_flag
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'email_subject',
        order: 14,
        type: 'text',
        disabled: campaign && !campaign.editable,
        value: campaign?.isEmail ? campaign?.template?.subject : null
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'email_template',
        dataSource: this.emailTemplateService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        order: 15,
        disabled: campaign && !campaign.editable,
        selectedIds: campaign && campaign.isEmail && campaign.campaign_template_id ? [campaign.campaign_template_id] : []
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'email_test_delivery',
        order: 16,
        type: 'text'
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'sms_template',
        dataSource: this.smsTemplatesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        order: 17,
        disabled: campaign && !campaign.editable,
        selectedIds: campaign && campaign.isSms && campaign.campaign_template_id ? [campaign.campaign_template_id] : []
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'sms_test_delivery',
        order: 18,
        type: 'text'
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'push_template',
        dataSource: this.pushTemplatesService,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        order: 19,
        disabled: campaign && !campaign.editable,
        selectedIds: campaign && campaign.isPush && campaign.campaign_template_id ? [campaign.campaign_template_id] : []
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'push_test_delivery_prefix',
        order: 21,
        type: 'text',
        placeholder: this.translate.instant('resources.campaigns.fields.prefix')
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'push_test_delivery',
        order: 22,
        type: 'text',
        placeholder: this.translate.instant('resources.campaigns.fields.phone')
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'skip_amount_error',
        label: this.translate.instant('resources.campaigns.fields.skip_amount_error'),
        order: 23,
        disabled: campaign && !campaign.editable,
        value: campaign?.skip_amount_error
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'sender_id',
        order: 24,
        type: 'multiselect',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        dataSource: this.campaignsSendersService,
        required: false,
        selectedIds: campaign?.sender_id ? [campaign.sender_id] : [],
        disabled: campaign && !campaign.editable
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'include_phone_duplicated',
        label: this.translate.instant('resources.campaigns.fields.include_phone_duplicated'),
        order: 25,
        disabled: campaign && !campaign.editable
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'collective_id',
        order: 26,
        type: 'multiselect',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.collectivesService,
        required: false,
        selectedIds: campaign && campaign.collective_id ? [campaign.collective_id] : [],
        disabled: campaign && !campaign.editable
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'contact_policy_flag',
        label: this.translate.instant('resources.campaigns.fields.contact_policy_flag'),
        disabled: campaign && !campaign.editable,
        value: isNullOrUndefined(campaign?.contact_policy_flag) ? this.flags.showContactPolicies : campaign?.contact_policy_flag
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'loyalty_score_id',
        type: 'multiselect',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.loyaltyScoreService,
        required: false,
        disabled: campaign && !campaign.editable
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'challenge_id',
        type: 'multiselect',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: true, enableSearchFilter: false },
        dataSource: this.challengeService,
        required: false,
        disabled: campaign && !campaign.editable
      }),
    ];

    return this.inputs;
  }

  paintErrorsInForm(form: UntypedFormGroup, errorsArray: { field: string, field_name?: string, message: string }[]) {
    const errorsWithoutField = [];
    errorsArray.forEach((apiError) => {
      let controlKey = apiError.field;

      if (apiError.field === 'template.subject') controlKey = 'email_subject';
      else if (apiError.field === 'template') controlKey = 'email_template';

      if (form.get(controlKey)) {
        form.get(controlKey).setErrors({ 'notPresent': true });
        this.inputByKey(controlKey).hasErrors = true;
      } else {
        console.warn(`Unable to set error in form. Field: ${apiError.field}. Message: ${apiError.message}`);
        errorsWithoutField.push(apiError);
      }
    });
    if (errorsWithoutField.length) { form.setErrors(errorsWithoutField); }
  }

  prepareCampaignWithForm(form: UntypedFormGroup, campaign: Campaign): CampaignPayload {
    return campaign.getCampaignPayload(form, campaign);
  }

  handleEmailTemplateValueChanged(form: UntypedFormGroup, campaign: Campaign) {
    const callback = (templateData, templateId) => {
      const subject = form.get('email_subject').value;
      campaign.copyEmailTemplate(subject, templateId, templateData);
      this.eventsSource.next('loaded_email_template');
    }
    this.handleTemplateValueChanged(form, 'email_template', this.emailTemplateService, callback);
  }

  handleSmsTemplateValueChanged(form: UntypedFormGroup, campaign: Campaign) {
    const callback = (templateData, templateId) => {
      const parsedApiTemplate = this.smsService.parseApiTemplate(templateData, campaign?.template?.id);
      campaign.copySmsTemplate(templateId, { id: campaign?.template?.id, template: parsedApiTemplate });
      this.eventsSource.next('loaded_sms_template');
    }
    this.handleTemplateValueChanged(form, 'sms_template', this.smsTemplatesService, callback);
  }

  handlePushTemplateValueChanged(form: UntypedFormGroup, campaign: Campaign) {
    const callback = (templateData, templateId) => {
      this.setCampaignCopyPushTemplate(templateId, templateData, campaign);
      this.eventsSource.next('loaded_push_template');
    }
    if (form.get('push_template').value && form.get('push_template').value.length) {
      this.handleTemplateValueChanged(form, 'push_template', this.pushTemplatesService, callback);
    } else {
      this.setCampaignCopyPushTemplate(null, null, campaign);
    }
  }

  handleCouponValueChanged(form: UntypedFormGroup) {
    this.inputByKey('campaign_history_coupon_id').selectedIds = [];
    form.patchValue({ 'campaign_history_coupon_id': null }, { emitEvent: false });

    setTimeout(() => {
      if (form.get('coupon_ids').value && form.get('coupon_ids').value.length === 0) {
        this.couponHistoriesService.setCoupon(null);
        return;
      }
      if (form.get('coupon_ids').value?.length > 0 && form.get('motive').value === 'reminder') {
        this.couponHistoriesService.setCoupon(form.get('coupon_ids').value[0].id);
      }
    });
  }

  private getMotiveValue(campaign: Campaign): string {
    if (campaign?.motive === 'message') return 'informative';
    return campaign?.motive || 'informative';
  }

  private getCouponSelectedIds(campaign?: Campaign, selectedCoupon?: unknown) {
    if (selectedCoupon) {
      return [selectedCoupon['id']];
    } else {
      return campaign?.coupon_ids?.length > 0 ? campaign?.coupon_ids : []
    }
  }

  private handleTemplateValueChanged(form: UntypedFormGroup,
    field: string,
    service: EmailTemplatesService | SmsTemplatesService | PushTemplatesService,
    callback: Function) {
    if (form.get(field).value && form.get(field).value.length) {
      this.eventsSource.next('loading_template');
      const templateId = form.get(field).value[0].id;
      service.getTemplateById(templateId).subscribe(
        (templateData) => callback(templateData, templateId),
        (templateErrorData: HttpErrorResponse) => {
          console.error(`TemplateErrorData: ${templateErrorData}`);
          this.eventsSource.next(`loaded_${field}_error`);
        }
      )
    }
  }

  private setCampaignCopyPushTemplate(templateId: any, templateData: any, campaign: Campaign) {
    const parsedApiTemplate = this.pushService.parseApiTemplate(templateData, campaign.template['id']);
    campaign.copyPushTemplate(templateId, { id: campaign.template['id'], template: parsedApiTemplate });
  }

  /*
    This shows a specific date formatted message when a given execution is selected.
    Also cleans the message if no execution is selected.
  */
  private handleExecutionValueChanged(form: UntypedFormGroup) {
    const executionValue = form.get('execution').value;
    if (executionValue?.length) {
      if (executionValue[0].id === 'daily') {
        this.scheduledAtMsg.next(this.translate.instant('resources.campaigns.messages.every_day') + this.dateService.momentFormat(form.get('scheduled_at').value, 'LT'));
      }
      if (executionValue[0].id === 'weekly') {
        const msg = this.translate.instant('resources.campaigns.messages.every_week') +
          this.dateService.momentFormat(form.get('scheduled_at').value, 'dddd') + ' ' +
          this.translate.instant('resources.campaigns.messages.hour') + ' ' +
          this.dateService.momentFormat(form.get('scheduled_at').value, 'LT');
        this.scheduledAtMsg.next(msg);
      }
      if (executionValue[0].id === 'monthly') {
        const msg = this.translate.instant('resources.campaigns.messages.every_month') +
          this.dateService.momentFormat(form.get('scheduled_at').value, 'D') + ' ' +
          this.translate.instant('resources.campaigns.messages.hour') + ' ' +
          this.dateService.momentFormat(form.get('scheduled_at').value, 'LT');
        this.scheduledAtMsg.next(msg);
      }
      form.get('execution_until').enable();
    } else {
      form.patchValue({ 'execution_until': null }, { emitEvent: false });
      form.get('execution_until').disable();
      this.scheduledAtMsg.next(null);
    }
  }

  private handleMotiveValueChanged(form: UntypedFormGroup) {
    if (form.get('motive').value === 'reminder') {
      this.inputByKey('motive').value = 'reminder';
      this.inputByKey('campaign_history_coupon_id').disabled = false;
    } else if (form.get('motive').value === 'informative') {
      this.inputByKey('periodic').disabled = false;
      this.inputByKey('coupon_ids').selectedIds = [];
      this.inputByKey('campaign_history_coupon_id').selectedIds = [];
      form.patchValue({ 'coupon_ids': [] }, { emitEvent: false });
      form.patchValue({ 'campaign_history_coupon_id': [] }, { emitEvent: false });
    } else if (form.get('motive').value === 'membership') {
      const excludedFields = ['name', 'description', 'campaign_category', 'motive', 'via'];
      Object.keys(form.controls).forEach(key => {
        if (excludedFields.findIndex(q => q === key) === -1) {
          form.get(key).reset();
        }
      });
    } else {
      form.get('periodic').enable();
      this.inputByKey('periodic').disabled = false;
    }
  }

  private handlePeriodicValueChanged(form: UntypedFormGroup) {
    if (this.qcs.hasFormKeyWithValue(form, 'periodic', true)) {
      form.get('execution').enable();
      this.inputByKey('execution').disabled = false;
    } else {
      form.get('execution').reset();
      form.get('execution').disable();
      this.inputByKey('execution').disabled = true;
    }
  }

  private handleViaValueChanged(form: UntypedFormGroup, campaign: Campaign, mode?: string) {
    const motiveInput = this.inputByKey('motive');
    campaign.resetTemplate();

    if (!campaign.reminder_plan && mode !== 'reminder_plan' && mode !== 'received_reminder' && mode !== 'plan_manual_campaign') {
      if (this.qcs.hasFormKeyWithValue(form, 'via', 'none')) {
        motiveInput.options = this.campaignsService.getCampaignMotives().filter(motive => ['membership', 'coupon'].includes(motive.id));
        form.patchValue({ 'motive': 'coupon' }, { emitEvent: false });
        campaign.hasContent = false;
        motiveInput.value = 'coupon';
        form.get('motive').enable();
        this.inputByKey('motive').disabled = false;
      } else if (this.qcs.hasFormKeyWithValue(form, 'via', 'ticket_message')) {
        motiveInput.options = this.campaignsService.getCampaignMotives().filter(motive => motive.id === 'informative');
        form.patchValue({ 'motive': 'informative' }, { emitEvent: false });
        form.get('motive').enable();
        motiveInput.value = 'informative';
        this.inputByKey('motive').disabled = false;
        campaign.hasContent = false;
      } else if (this.qcs.hasFormKeyWithValue(form, 'via', 'push')) {
        motiveInput.options = this.campaignsService.getCampaignMotives().filter(motive => !['membership', 'coupon'].includes(motive.id));
        form.patchValue({ 'motive': 'informative' }, { emitEvent: false });
        motiveInput.value = 'informative';
        form.get('motive').enable();
        form.get('periodic').enable();
        this.inputByKey('motive').disabled = false;
        campaign.hasContent = true;
      } else if (this.qcs.hasFormKeyWithValue(form, 'via', 'email') || this.qcs.hasFormKeyWithValue(form, 'via', 'sms')) {
        motiveInput.options = this.campaignsService.getCampaignMotives().filter(motive => !['membership'].includes(motive.id));
        form.patchValue({ 'motive': 'informative' }, { emitEvent: false });
        motiveInput.value = 'informative';
        form.get('motive').enable();
        form.get('periodic').enable();
        this.inputByKey('motive').disabled = false;
        campaign.hasContent = true;
      }
    }

    // Set value in campaign
    if (form.get('via')?.value && form.get('via')?.value[0] && form.get('via')?.value[0].id !== 'none' && form.get('via')?.value[0].id !== 'ticket_message') {
      campaign.via = form.get('via').value;
      campaign.hasContent = true;
    } else {
      campaign.hasContent = false;
    }
  }

  private handleScheduledAtValueChanged(form: UntypedFormGroup) {
    if (form.get('scheduled_at').value && form.get('motive').value !== 'membership') {
      form.get('periodic').enable();
      this.eventsSource.next('defined_scheduled_at');
      this.inputByKey('periodic').disabled = false;
    } else {
      form.get('periodic').reset();
      form.get('periodic').disable();
      this.eventsSource.next('undefined_scheduled_at');
      this.inputByKey('periodic').disabled = true;
    }
  }

  private inputByKey(key: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, key);
  }
}
