import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from '../../../shared/services/resources.service';
import { ConfigEntry } from './configuration.utils';

export type ConfigurationEntryResponse = { entity: string, slug: string, active: boolean, description: string, value: ConfigEntry }

@Injectable()
export class ConfigurationService extends ResourcesService {
  static readonly baseEndpoint = 'configurations';

  constructor(http: HttpClient) {
    super(http);
  }

  getConfigurations() {
    return this.getData({ apiEndPoint: ConfigurationService.baseEndpoint });
  }

  getConfigurationsByEntity(entity: string) {
    return this.getData({ apiEndPoint: `${ConfigurationService.baseEndpoint}/${entity}` })
  }

  getConfiguration(entity: string, slug: string) {
    return this.getData({ apiEndPoint: `${ConfigurationService.baseEndpoint}/${entity}/${slug}` });
  }

  patchConfiguration(entity: string, slug: string, value: object, description: string = undefined) {
    const params = { value: value };
    // Skip if missing; if null, we send it so it is deleted
    if (description !== undefined) { params['description'] = description }
    return this.patchResource(params, `${ConfigurationService.baseEndpoint}/${entity}/${slug}`)
  }

  patchAllConfigurations(entity: string, payload: object) {
    return this.patchResource(payload, `${ConfigurationService.baseEndpoint}/${entity}`);
  }
}
