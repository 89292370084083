<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true" *ngIf="element">
              <tbody>
                <tr *ngIf="element.id">
                  <td class="no-border-top"><strong>{{ 'resources.company_promotions.show.id' | translate }}</strong></td>
                  <td class="no-border-top">{{element.id}}</td>
                </tr>
                <tr *ngIf="element.name">
                  <td><strong>{{ 'resources.company_promotions.show.name' | translate }}</strong></td>
                  <td>{{element.name}}</td>
                </tr>
                <tr *ngIf="element.discount">
                  <td><strong>{{ 'resources.company_promotions.show.discount' | translate }}</strong></td>
                  <td>{{element.discount}}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
