import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { CurrentCompany } from '../../../../shared/models/current-user';
import { FeatureFlagsService } from './../../../../shared/services/feature-flags.service';
import { getTranslationInputs } from '../../../data-warehouse/data-warehouse-coupons/form-coupons/coupon-utils/coupon-utils';
import { HttpErrorResponse } from '@angular/common/http';
import { LocationsService } from '../../../data-warehouse/locations/locations.service';
import { LoyaltyScoresService } from '../../loyalty_scores/loyalty-scores.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { MultiselectDataSource } from '../../../../shared/components/multiselect/multiselect';
import { Observable, Subject, Subscription, distinctUntilChanged, take, takeUntil } from 'rxjs';
import { ProfileService } from '../../../../profiles/profile.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { Reward } from '../../../../shared/models/reward/reward';
import { RewardsService } from '../rewards.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Modules } from '../../../../shared/enums/modules.enums';

@Component({
  selector: 'app-form-rewards',
  templateUrl: './form-rewards.component.html',
  styleUrls: ['./form-rewards.component.scss'],
  providers: [ RewardsService, LoyaltyScoresService ]
})

export class FormRewardsComponent implements OnInit, OnDestroy {

  @Output() fileChanged = new EventEmitter<any>();
  @Output() formValidity = new EventEmitter<string>();

  companyData: CurrentCompany;
  companyId: number = this.profileService.getStoredUser().company.id;
  filteredAvailableLanguages: string[];
  flags = this.featureFlags.flags;
  hasNullName: boolean;
  id: number;
  imageData: { filename: any; filesize: any; filetype: any; base64: string; };
  imageFile: string | ArrayBuffer;
  imgMissing: boolean;
  inputs: QuestionBase<any>[];
  isRewardsModule = this.route.snapshot?.['_routerState']?.url?.split('/')?.[1] === Modules.Rewards;
  loading: boolean;
  MAX_FILE_SIZE_LIMIT = 500 * 1024;
  mode: string = this.route.snapshot.data.mode;
  rewardsData: Reward;
  rewardsForm: UntypedFormGroup;
  selectedFile: {
    name: string;
    size: number;
    type: string;
  }
  showButton: boolean;
  subs$: Subscription[] = [];

  private company = new CurrentCompany(this.profileService.getProfileCompany());
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private confirmationService: ConfirmationService,
    private featureFlags: FeatureFlagsService,
    private locationsService: LocationsService,
    private modalStatusService: ModalStatusService,
    private profileService: ProfileService,
    private qcs: QuestionControlService,
    private rewardsService: RewardsService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.getParams();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.subs$.forEach(sub$ => sub$.unsubscribe());
  }

  navigateToTranslations() {
    const name = this.rewardsForm.value['name'];
    const description = this.rewardsForm.value['description'];
    const termsAndConditions = this.rewardsForm.value['terms_and_conditions'];
    const rootPath = this.isRewardsModule ? Modules.Rewards : 'loyalty';
    const url = `#/${rootPath}/configure-translations?name=${encodeURIComponent(name)}&description=${encodeURIComponent(description)}&termsAndConditions=${encodeURIComponent(termsAndConditions)}`;
    window.open(url, '_blank');
  }

  validateFileSize(event, fileUpload) {
    const file = event.files[0];
    this.selectedFile = file;
    const imageInput= this.rewardsForm.get('image');

    if (file.size > this.MAX_FILE_SIZE_LIMIT) {
      this.imageData = null;
      fileUpload.clear();
      return imageInput.setErrors({ 'invalidFileSize': true });
    } else {
      fileUpload.clear();
      imageInput.setErrors(null);
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      this.imageData = {
        filename: file.name,
        filesize: file.size,
        filetype: file.type,
        base64: reader.result.toString().split(',')[1]
      };
      this.imageFile = e.target?.result; // Base64 URL
    };
  }

  fieldHasValue(form: UntypedFormGroup, formKey: string): boolean {
    return !!(form.get(formKey).value);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  resetRelatedFieldsValue(event) {
    const fieldsToReset = ['points', 'extra_money', 'currency', 'flexible_score_allowed', 'achievement_definition_ids', 'collectives', 'locations', 'countries', 'available_from', 'available_to', 'redeem_chances', 'redeem_times'];
    if(event.target.id === 'redemption_type1'){
      fieldsToReset.forEach(field => this.rewardsForm.get(field).reset());
    }
  }

  sendData() {
    const payload = this.setPayload();
    const translatePath = 'resources.scores.rewards.form.warnings';
    const companyLanguage = this.profileService.getProfileCompany().locale.split('_')[0];

    if (this.flags.rewardsAvailableLanguagesVisibility && !payload['available_languages'].includes(companyLanguage)) {
      return this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), this.translate.instant(`${translatePath}.company_lang_not_selected`));
    }
    if (!payload.name) {
      return this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), this.translate.instant(`${translatePath}.name_required`));
    }
    if (payload.is_coupon && !payload.coupon_id) {
      return this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), this.translate.instant(`${translatePath}.coupon_needed`));
    }
    if (payload.extra_money && !payload.currency) {
      return this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), this.translate.instant(`${translatePath}.currency`));
    }

    this.modalStatusService.formLoaderStatus.emit('loading');
    const req$ = this.getRequest(payload);
    let successTitle = this.translate.instant(`${translatePath}.success_title`);
    let successDesc = this.translate.instant(`${translatePath}.success_text`);
    if (this.id) {
      successTitle = this.translate.instant(`${translatePath}.update_title`);
      successDesc = this.translate.instant(`${translatePath}.update_text`);
    }
    this.executeRequest(req$, successTitle, successDesc);
  }

  returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0 ) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else if (this.isRewardsModule) {
      this.router.navigate([`${Modules.Rewards}/list/active`]).catch(() => {});
    } else {
      this.router.navigate(['/scores/rewards']).catch(() => {});
    }
  }

  countrySelector() {
    const countriesIds = this.inputs
      .find(input => input.key === 'countries')
      .value.map(item => item.id);
    this.locationsService.setCountries(countriesIds);
  }

  typesSelector($event) {
    if (!$event || ($event?.id !== 'coupon' )) {
      this.rewardsForm.patchValue({is_coupon: false}, {emitEvent: true});
    }
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.rewardsForm, formKey, value);
  }

  resetValidityDurationFields() {
    const validityDurationType = this.rewardsForm.get('validity_duration_type').value;
    const fieldsToReset = ['validity_duration_type', 'validity_duration_number', 'validity_duration_unit_option', 'validity_duration_date'];

    if (validityDurationType === 'relative') {
      fieldsToReset.forEach(field => this.rewardsForm.get(field).reset());
      this.rewardsForm.get('validity_duration_type').patchValue('relative');
    } else if (validityDurationType === 'fixed') {
      fieldsToReset.forEach(field => this.rewardsForm.get(field).reset());
      this.rewardsForm.get('validity_duration_type').patchValue('fixed');
    }
  }

  private initialValidators() {
    const countriesControl = this.rewardsForm.get('countries');
    if(this.rewardsForm.getRawValue().redemption_type === 'loyalty_rule') {
      countriesControl.disable();
    }
    countriesControl.updateValueAndValidity();
  }

  private executeRequest(req: Observable<any>, successTitle: string, successDesc: string) {
    req.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.modalStatusService.modalStatus.emit('loading_finished');
        this.confirmationService.displaySuccessAlert(successTitle, successDesc).catch(() => {});
        this.loading = true;
        this.returnToList();
      },
      error: (errorData: HttpErrorResponse) => this.handleError(errorData)
    });
  }

  private getRequest(payload: object): Observable<any> {
    return this.id ? this.rewardsService.updateReward(payload, this.id) : this.rewardsService.createReward(payload);
  }

  private handleError(errorData: HttpErrorResponse) {
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error);
  }

  private getParams() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getRewardsById(this.id);
      } else {
        this.inputs = this.rewardsService.getInputs({});
        this.rewardsForm = this.qcs.toFormGroup(this.inputs);
        this.handleIsCouponField();
        this.getCompanyData();
        this.handleExtraMoneyField();
        this.handleRedeemChancesField();
        this.handleValidityDurationFields();
        this.handleRedemptionTypeFieldChanges();
        this.formStatusChanges();
        this.initialValidators();
      }
    });
  }

  private nameAndDescriptionPayload(payload) {
    const nameKey = Object.keys(this.rewardsForm.value).filter(item => /name_/.test(item)).map(element => element.split('_')[1]);
    const availableLanguages = [];
    if (nameKey && nameKey.length > 0) {
      payload.name = {};
      payload.description = {};
      nameKey.forEach(key => {
        payload['name'][`${key}`] = this.rewardsForm.value[`name_${key}`];
        payload['description'][`${key}`] = this.rewardsForm.value[`description_${key}`];
        // Activate when terms and conditions become available
        // payload['terms_and_conditions'][`${key}`] = this.rewardsForm.value[`terms_and_conditions${key}`];
        availableLanguages.push(key);
      });
      this.hasNullName = Object.values(payload.name).some(x => x === null || x === '');
      if (this.hasNullName) {
        payload['name'] = null;
        payload['description'] = null;
        // Activate when terms and conditions become available
        // payload['terms_and_conditions'] = null;
      }
    }
    payload.available_languages = availableLanguages;
  }

  private setPayload() {
    const rawValue = this.rewardsForm?.getRawValue();

    const achievement_definition_ids = this.getMultiselectFieldValue('achievement_definition_ids');
    const available_from = rawValue?.available_from || null;
    const available_to = rawValue?.available_to || null;
    const collective_ids = this.getMultiselectFieldValue('collectives');
    const countries = rawValue?.countries?.length > 0 ? rawValue.countries.map(item => item.id) : [];
    const coupon_id = rawValue?.coupon_id?.length > 0 ? rawValue.coupon_id[0].id : null;
    const currency = rawValue?.currency?.[0] ? rawValue.currency[0].id : null;
    const description = rawValue?.description || null;
    const duration_number = rawValue?.duration_number;
    const duration_unit_option = rawValue?.duration_unit_option?.[0] ? rawValue.duration_unit_option[0].id : null;
    const duration_date = rawValue?.duration_date;
    const extra_money = parseFloat(rawValue?.extra_money);
    const flexible_score_allowed = rawValue?.flexible_score_allowed || false;
    const location_ids = rawValue?.locations?.length > 0 ? rawValue.locations.map(item => item.id) : null;
    const low_stock_level = Number(rawValue?.low_stock_level) || null;
    const name = rawValue?.name || null;
    const partner_id = rawValue?.partner_id?.length > 0 ? rawValue.partner_id[0].id : null;
    const points = Number(rawValue?.points);
    const redeem_chances = rawValue?.redeem_chances?.[0] ? rawValue.redeem_chances[0].id : null;
    const redeem_times = Number(rawValue?.redeem_times);
    const redeemed_validity = rawValue?.redeemed_validity;
    const redeemed_validity_option = rawValue?.redeemed_validity_option?.[0] ? rawValue.redeemed_validity_option[0].id : null;
    const redemption_type = rawValue?.redemption_type;
    const reward_taxonomy_terms = this.getRewardCategories(rawValue?.reward_taxonomy_terms);
    const status = rawValue?.status?.length > 0 ? rawValue.status[0].id : [];
    const type = rawValue?.type?.[0]?.id;
    const validity_conditions = rawValue?.validity_conditions;
    const validity_duration_date = rawValue.validity_duration_date !== null ? rawValue.validity_duration_date : null;
    const validity_duration_number = rawValue.validity_duration_number !== null ? rawValue.validity_duration_number : null;
    const validity_duration_unit_option = rawValue.validity_duration_unit_option?.[0]?.id || null;
    const validity_duration_type = rawValue?.validity_duration_type || null;

    const rewardsOptions = { achievement_definition_ids, available_from, available_to, collective_ids, countries, coupon_id, currency,
                             description, duration_date, duration_number, duration_unit_option, extra_money, flexible_score_allowed,
                             is_coupon: type === 'coupon', location_ids, low_stock_level, name, partner_id, points, redeem_chances, redeem_times, redeemed_validity_option,
                             redemption_type, redeemed_validity, reward_taxonomy_terms, status, type, validity_conditions, validity_duration_date,
                             validity_duration_number, validity_duration_type, validity_duration_unit_option
                           };
    if (this.imageData) { rewardsOptions['image'] = this.imageData; }
    if (!rewardsOptions.is_coupon) { rewardsOptions['coupon_id'] = null; }
    if (this.flags.rewardsAvailableLanguagesVisibility) {
      this.nameAndDescriptionPayload(rewardsOptions);
    }
    return rewardsOptions;
  }

  private getRewardCategories(categories: MultiselectDataSource[]): {external_id: number, taxonomy_slug: string}[] {
    return categories.map(el => ({
      external_id: el.rawElement.external_id,
      taxonomy_slug: el.rawElement.taxonomy_slug
    }));
  }

  private getMultiselectFieldValue(field: string) {
    let fieldValue = null;
    if (this.rewardsForm.get(field).value && this.rewardsForm.get(field).value.length > 0) {
      const fieldList = [];
      this.rewardsForm.get(field).value.forEach(f => fieldList.push(f.id));
      fieldValue = fieldList;
    }
    return fieldValue;
  }

  private getRewardsById(id: number) {
    this.rewardsService.getRewardsById(id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        data.image = null;
        this.rewardsData = data;
        this.imgMissing = this.rewardsData._links.thumbnail_medium?.href.indexOf('images/medium/missing.png') >= 0;
        this.inputs = this.rewardsService.getInputs(data);
        this.rewardsForm = this.qcs.toFormGroup(this.inputs);
        this.handleIsCouponField();
        getTranslationInputs(this.rewardsData, this.qcs, this.rewardsForm, this.inputs, 'form');
        this.filteredAvailableLanguages = data.available_languages;
        this.formStatusChanges();
        this.handleExtraMoneyField();
        this.handleRedeemChancesField();
        this.handleValidityDurationFields();
        this.handleRedemptionTypeFieldChanges();
        this.initialValidators();
      },
      error: (error) => this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), error.error.error)
    });
  }

  private handleExtraMoneyField() {
    const currencyInput = this.inputs.find(input => input.key === 'currency');

    if(this.rewardsForm.getRawValue().extra_money) {
      this.inputs.find(input => input.key === 'currency').required = true;
      this.rewardsForm.get('currency').setValidators(Validators.required);
    }

    const extraMoney$ = this.rewardsForm.get('extra_money').valueChanges.subscribe(
      data => {
        const isExtraMoneySelected = !!data;
        if (isExtraMoneySelected) {
          currencyInput.required = true;
          this.rewardsForm.get('currency').setValidators(Validators.required);
        } else {
          currencyInput.required = false;
          this.rewardsForm.get('currency').clearValidators();
        }
        this.rewardsForm.get('currency').updateValueAndValidity({ onlySelf: true });
      }
    );
    this.subs$.push(extraMoney$);
  }

  private handleIsCouponField() {
    const flexibleScoreAllowed = this.inputs.find((question) => question.key === 'flexible_score_allowed');
    const isCouponSubscription = this.rewardsForm.get('is_coupon').valueChanges.subscribe(
      value => {
        if (value) {
          this.rewardsForm.get('coupon_id').enable();
          this.rewardsForm.get('flexible_score_allowed').setValue(false);
          this.rewardsForm.get('flexible_score_allowed').disable();
          flexibleScoreAllowed.disabled = true;
        } else {
          this.rewardsForm.get('flexible_score_allowed').enable();
          flexibleScoreAllowed.disabled = false;
          this.rewardsForm.get('coupon_id').disable();
        }
      });
    this.subs$.push(isCouponSubscription);
  }

  /**
   * Handles changes to the 'redeem_chances' field.
   * Disables the 'redeem_times' field if the value is either empty or set to 'Always'.
   * Enables the 'redeem_times' field and sets it as required otherwise.
   */
  private handleRedeemChancesField(): void {
    const redeemChances$ = this.rewardsForm.get('redeem_chances').valueChanges.subscribe(
      value => {
        const redeemTimesField = this.rewardsForm.get('redeem_times');
        const shouldDisable = !value || value[0].id === 'always';

        if (shouldDisable) {
          redeemTimesField.disable();
          redeemTimesField.setValue(null);
          redeemTimesField.clearValidators();
        } else {
          redeemTimesField.enable();
          redeemTimesField.setValidators(Validators.required);
        }
        redeemTimesField.updateValueAndValidity();
      }
    );
    this.subs$.push(redeemChances$);
  }

  private handleValidityDurationFields() {

    const validityDurationNumberField = this.rewardsForm.get('validity_duration_number');
    const validityDurationOptionField = this.rewardsForm.get('validity_duration_unit_option');
    const validityDurationDateField = this.rewardsForm.get('validity_duration_date');

    const activateRewardValidity$ = this.rewardsForm.get('activate_reward_validity').valueChanges.subscribe(
      checked => {
        if(checked) {
          const fieldsToReset = ['validity_duration_type', 'validity_duration_number', 'validity_duration_unit_option', 'validity_duration_date'];
          fieldsToReset.forEach(field => this.rewardsForm.get(field).reset());
          this.rewardsForm.get('validity_duration_type').patchValue('relative');
        } else {
          this.rewardsForm.get('validity_duration_type').patchValue(null);
          validityDurationNumberField.clearValidators();
          validityDurationOptionField.clearValidators();
          validityDurationDateField.clearValidators();

          validityDurationNumberField.reset();
          validityDurationOptionField.reset();
          validityDurationDateField.reset();

          validityDurationNumberField.updateValueAndValidity();
          validityDurationOptionField.updateValueAndValidity();
          validityDurationDateField.updateValueAndValidity();
        }
      }
    );

    const validityDuration$ = this.rewardsForm.get('validity_duration_type').valueChanges.subscribe(
      value => {
        if (value === 'relative') {
          validityDurationNumberField.setValidators(Validators.required);
          validityDurationOptionField.setValidators(Validators.required);
          validityDurationDateField.clearValidators();
        } else if (value === 'fixed') {
          validityDurationDateField.setValidators(Validators.required);
          validityDurationNumberField.clearValidators();
          validityDurationOptionField.clearValidators();
        }
        validityDurationNumberField.updateValueAndValidity();
        validityDurationOptionField.updateValueAndValidity();
        validityDurationDateField.updateValueAndValidity();
      }
    );

    this.subs$.push(activateRewardValidity$);
    this.subs$.push(validityDuration$);
  }

  private handleRedemptionTypeFieldChanges() {
    const pointsControl = this.rewardsForm.get('points');
    const pointsInput = this.inputs.find(input => input.key === 'points');
    const countriesControl = this.rewardsForm.get('countries');
    const countriesInput = this.inputs.find(input => input.key === 'countries');
    const redemptionType$ = this.rewardsForm.get('redemption_type').valueChanges.subscribe(
      (value) => {
        if (value === 'loyalty_rule') {
          pointsControl.clearValidators();
          countriesControl.clearValidators();
          pointsInput.required = false;
          countriesInput.required = false;
          countriesControl.disable();
        } else {
          pointsControl.setValidators([Validators.required]);
          countriesControl.setValidators([Validators.required]);
          pointsInput.required = true;
          countriesInput.required = true;
          countriesControl.enable();
        }
        pointsControl.updateValueAndValidity();
        countriesControl.updateValueAndValidity();
      }
    );
    this.subs$.push(redemptionType$);
  }

  private getCompanyData() {
    if (this.company.availableLanguages) {
      const availableLanguagesOptions = this.inputs.find(input => input.key === 'available_languages').options;
      const filtered = availableLanguagesOptions.filter(item => this.company.availableLanguages.map(_item => _item.slice(0, 2)).includes(item.id));
      this.rewardsForm.get('available_languages').patchValue(filtered);
      this.filteredAvailableLanguages = filtered;
    }
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.rewardsForm.status));
    const formStatus$ = this.rewardsForm.statusChanges.pipe(distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
    this.subs$.push(formStatus$);
  }

}
