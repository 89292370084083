import { isNullOrUndefined } from '../../../../../shared/utils/common.utils';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { TextareaQuestion } from '../../../../../shared/models/forms/question-textarea';
import { TextboxQuestion } from '../../../../../shared/models/forms/question-textbox';
import { UntypedFormGroup } from '@angular/forms';

export function getTranslationInputs(
  data: unknown,
  qcs: QuestionControlService,
  form: UntypedFormGroup,
  inputs: QuestionBase<any>[],
  mode: string
) {
  if (data && data['available_languages']) {
    data['available_languages'].forEach(lang => {
      getNameLangInputs(lang, data, qcs, form, inputs, mode);
    });
  }
}

export function nameAndDescriptionPayload(payload, form) {
  const nameKey = Object.keys(form.value).filter(item => /name_/.test(item)).map(element => element.split('_')[1]);
  const availableLanguages = [];
  if (nameKey && nameKey.length > 0) {
    payload.name = {};
    payload.description = {};
    nameKey.forEach(key => {
      payload['name'][`${key}`] = form.value[`name_${key}`];
      payload['description'][`${key}`] = form.value[`description_${key}`];
      availableLanguages.push(key);
    });
    const hasNullName = Object.values(payload?.name).some(x => isNullOrUndefined(x) || x === '');
    if (hasNullName) {
      payload['name'] = null;
      payload['description'] = null;
    }
  }
  payload.available_languages = availableLanguages;
}

export function getNameLangInputs(
  lang: string,
  data: unknown,
  qcs: QuestionControlService,
  form: UntypedFormGroup,
  inputs: QuestionBase<any>[],
  mode: string
) {
  const langInputs: any = [
    new TextboxQuestion({
      key: `name_${lang}`,
      type: 'text',
      cssClasses: 'form-control input-default',
      value: data ? data[`name_${lang}`] : null,
      disabled: mode === 'show'
    }),
    new TextboxQuestion({
      key: `description_${lang}`,
      type: 'text',
      cssClasses: 'form-control input-default',
      value: data ? data[`description_${lang}`] : null,
      disabled: mode === 'show'
    }),
    new TextareaQuestion({
      key: `terms_and_conditions_${lang}`,
      type: 'text',
      rows: 3,
      cssClasses: 'form-control input-default',
      value: data ? data[`terms_and_conditions_${lang}`] : null,
      disabled: mode === 'show'
    }),
  ];

  langInputs.forEach(input => {
    qcs.addToFormGroup(form, input);
    inputs.push(input);
  });
}
