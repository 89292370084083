import { ActivatedRoute, Router } from "@angular/router";
import { ApiKey } from "../../models/api-key";
import { ApiKeyService } from "../../services/api-key-service";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ConfirmationService } from "../../../../../../shared/services/confirmation.service";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'app-show-api-key',
  templateUrl: './api-keys-detail.component.html',
  styleUrls: ['./api-keys-detail.component.scss']
})

export class ApiKeyDetailComponent implements OnInit {
  apiKeyId = this.route.snapshot.params.id;
  apiKey: ApiKey;

  @Output('updateEmitter') updateEmitter: EventEmitter<any>  = new EventEmitter();

  constructor(
    private readonly apiKeyService: ApiKeyService,
    private readonly confirmationService: ConfirmationService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ){}

  ngOnInit(): void {
    this.getApiKeyById();
  }

  closeModal(): void {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private getApiKeyById(): void {
    this.apiKeyService.getById(this.apiKeyId).subscribe({
      next: (partner: ApiKey) => this.handleSuccessfulResponse(partner),
      error: (err: HttpErrorResponse) => this.handleErrorResponse(err)
    });
  }

  private handleSuccessfulResponse(apiKey: ApiKey): void {
    this.apiKey = apiKey;
    setTimeout(() => {
      if(this.apiKey.system) {
        this.updateEmitter.emit(['', { outlets: { modal: null }}]);
      } else {
        this.updateEmitter.emit(['', { outlets: { modal: ['update', 'api-key', this.apiKey.id]}}]);
      }
    });
  }

  private handleErrorResponse(err: HttpErrorResponse): void {
    this.confirmationService.displayHttpErrorAlert(err);
    this.closeModal();
  }
}