import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { CurrencyConversionsService } from '../currency-conversions.service';
import { Subscription } from 'rxjs';
import { MultiSelectQuestion } from '../../../../shared/models/forms/question-multiselect';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { CurrenciesService } from '../../../../shared/services/currencies.service';
import { Modules } from '../../../../shared/enums/modules.enums';

@Component({
  selector: 'app-form-currency-conversions',
  templateUrl: './form-currency-conversions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FormCurrencyConversionsComponent implements OnInit, OnDestroy {

  conversionsInputs: QuestionBase<any>[];
  conversionsForm: UntypedFormGroup;
  id: number;
  showButton: boolean;
  errorData: any;
  loading: boolean;
  conversionRateTemplateA: string;
  conversionRateTextA: string;
  conversionRateTemplateB: string;
  conversionRateTextB: string;
  subs$: Subscription[];
  isLoyaltyPaymentModule = this.route.snapshot?.['_routerState']?.url?.split('/')?.[1] === Modules.LoyaltyPayment;

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private currencyConversionsService: CurrencyConversionsService,
    private currenciesService: CurrenciesService,
    private confirmationService: ConfirmationService,
    private modalStatusService: ModalStatusService,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.subs$ = [];
  }

  ngOnInit() {
    this.getParams();
    this.showBackButton();
    this.conversionRateTemplateA = this.translate.instant('resources.currencies.conversions.form.conversion_rate_a');
    this.conversionRateTextA = this.conversionRateTemplateA.replace('[A]', 'A');
    this.conversionRateTemplateB = this.translate.instant('resources.currencies.conversions.form.conversion_rate_b');
    this.conversionRateTextB = this.conversionRateTemplateB.replace('[B]', 'B');
    this.changeDetector.markForCheck();
  }

  ngOnDestroy() {
    this.subs$.forEach(sub$ => sub$.unsubscribe());
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.conversionsInputs, inputKey);
  }

  sendData() {
    if ( this.id ) {
      this.modalStatusService.formLoaderStatus.emit('loading');
      this.currencyConversionsService.updateData(this.getPayload(), this.id).subscribe(
        () => {
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.currencies.conversions.form.warnings.update_title'),
            this.translate.instant('resources.currencies.conversions.form.warnings.update_text')
          ).catch(() => {});
          this.modalStatusService.formLoaderStatus.emit('loading_finished');
          this.modalStatusService.modalStatus.emit();
          this.returnToList();
        },
        errorData => {
          this.modalStatusService.formLoaderStatus.emit('loading_finished');
          if (errorData.error && errorData.error.errors && errorData.error.errors.length > 0) {
            this.paintErrorsInForm(this.conversionsForm, errorData);
          } else if (typeof errorData.error.error === 'string') {
            this.confirmationService.displayErrorAlert(
              this.translate.instant('resources.currencies.conversions.form.warnings.error_title'),
              errorData.error.error
            );
          }
        }
      );
    } else {
      this.modalStatusService.formLoaderStatus.emit('loading');
      this.currencyConversionsService.sendData(this.getPayload()).subscribe(
        () => {
          this.confirmationService.displaySuccessAlert(
            this.translate.instant('resources.currencies.conversions.form.warnings.success_title'),
            this.translate.instant('resources.currencies.conversions.form.warnings.success_text')
          ).catch(() => {});
          this.modalStatusService.formLoaderStatus.emit('loading_finished');
          this.modalStatusService.modalStatus.emit();
          this.returnToList();
        },
        errorData => {
          this.modalStatusService.formLoaderStatus.emit('loading_finished');
          if (errorData.error.error && (typeof errorData.error.error === 'object') && errorData.error.error.length > 0) {
            this.errorData = errorData;
            this.paintErrorsInForm(this.conversionsForm, errorData.error.errors);
          } else if (typeof errorData.error.error === 'string') {
            this.confirmationService.displayErrorAlert(
              this.translate.instant('resources.currencies.conversions.form.warnings.error_title'),
              errorData.error.error
            );
          }
        }
      );
    }
  }

  private getPayload() {
    const currency_a = this.conversionsForm.value.currency_a ? this.conversionsForm.value.currency_a[0].id : [];
    const currency_b = this.conversionsForm.value.currency_b ? this.conversionsForm.value.currency_b[0].id : [];

    if (typeof currency_a === 'string' && typeof currency_b === 'string' && currency_a === currency_b) {
      this.confirmationService.displayErrorAlert(
        this.translate.instant('resources.currencies.conversions.form.warnings.error_title'),
        this.translate.instant('resources.currencies.conversions.form.warnings.error_same_currency')
      );
      return;
    }

    const conversionsOptions = {
      currency_a: currency_a,
      currency_b: currency_b,
      conversion_rate_a: this.conversionsForm.value.conversion_rate_a,
      conversion_rate_b: this.conversionsForm.value.conversion_rate_b
    };
    return conversionsOptions;
  }

  private returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0 ) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else if (this.isLoyaltyPaymentModule) {
      this.router.navigate([`/${Modules.LoyaltyPayment}/configuration/conversions`]).catch(() => {});
    } else {
      this.router.navigate(['/config/loyalty/conversions']).catch(() => {});
    }
  }

  private paintErrorsInForm(form: UntypedFormGroup, errorsArray: {field: string}[]) {
    this.qcs.paintErrorsInForm(this.conversionsInputs, form, errorsArray);
  }

  private showBackButton() {
    this.showButton = !(this.router.url.indexOf('(modal:') >= 0);
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getCurrencyConversionsById(this.id);
      } else {
        this.conversionsInputs = this.getInputs({});
        this.conversionsForm = this.qcs.toFormGroup(this.conversionsInputs);
        this.addFormInputSubscriptions();
        this.changeDetector.markForCheck();
      }
    });
  }

  private getCurrencyConversionsById(id: number) {
    this.currencyConversionsService.getConversionsById(id).subscribe(
      data => {
        this.conversionsInputs = this.getInputs(data);
        this.conversionsForm = this.qcs.toFormGroup(this.conversionsInputs);
        this.addFormInputSubscriptions();
        this.changeDetector.markForCheck();
      }
    );
  }

  private addFormInputSubscriptions() {
    const currencyA$ = this.conversionsForm.get('currency_a').valueChanges.subscribe(
    () => {
      const currencyA = this.conversionsForm.get('currency_a').value;
      if (currencyA !== undefined && currencyA[0] !== undefined) {
        const isoCode = currencyA[0].id;
        this.conversionRateTextA = this.conversionRateTemplateA.replace('[A]', isoCode);
      } else {
        this.conversionRateTextA = this.conversionRateTemplateA.replace('[A]', 'A');
      }
    });

    const currencyB$ = this.conversionsForm.get('currency_b').valueChanges.subscribe(
    () => {
      const currencyB = this.conversionsForm.get('currency_b').value;
      if (currencyB !== undefined && currencyB[0] !== undefined) {
        const isoCode = currencyB[0].id;
        this.conversionRateTextB = this.conversionRateTemplateB.replace('[B]', isoCode);
      } else {
        this.conversionRateTextB = this.conversionRateTemplateB.replace('[B]', 'B');
      }
    });

    this.subs$.push(currencyA$);
    this.subs$.push(currencyB$);
  }

  private getInputs(formValues: any): QuestionBase<any>[] {
    const inputsArray = [
      new MultiSelectQuestion({
        key: 'currency_a',
        cssClasses: 'form-control input-default',
        settings: {singleSelection: true, showCheckbox: false, enableSearchFilter: false},
        dataSource: this.currenciesService
      }),
      new MultiSelectQuestion({
        key: 'currency_b',
        cssClasses: 'form-control input-default',
        settings: {singleSelection: true, showCheckbox: false, enableSearchFilter: false},
        dataSource: this.currenciesService
      }),
      new TextboxQuestion({
        key: 'conversion_rate_a',
        type: 'number',
        cssClasses: 'form-control input-default',
        order: 1
      }),
      new TextboxQuestion({
        key: 'conversion_rate_b',
        type: 'number',
        cssClasses: 'form-control input-default',
        order: 2
      })
    ];

    Object.keys(formValues).map(
      key => {
        if (key === 'currency_a') {
          inputsArray.find( input => input.key === 'currency_a' ).selectedIds = [formValues[key]];
        } else if (key === 'currency_b') {
          inputsArray.find( input => input.key === 'currency_b' ).selectedIds = [formValues[key]];
        } else if (key === 'conversion_rate_a') {
          inputsArray.find( input => input.key === 'conversion_rate_a' ).value = formValues[key];
        } else if (key === 'conversion_rate_b') {
          inputsArray.find( input => input.key === 'conversion_rate_b' ).value = formValues[key];
        }
      }
    );
    return inputsArray;
  }

}
