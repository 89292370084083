// Checking with an enum instead of defining one function per status is similarly enforced by the IDE's completion mechanisms,
// but greatly reduces code duplication.
// What's more: this way we do not need to implement any function, we can just do `plan.alerts_status === AlertsStatus.Pending`
// and still enjoy autocompletion and type safety.
//
// Caveat: this is usually filled from an HTTP request, which is not being type-validated right now; this only protects us
// at compile time
export enum PlanAlertsStatus {
  Pending = 'pending',
  Generating = 'generating',
  Success = 'success',
  Failed = 'failed',
  FailedSending = 'failed_sending',
  Error = 'error',
}

export class Plan {

  id: number;
  name: string;
  alerts_status: PlanAlertsStatus;
  available_from: string;
  available_to: string;
  allowed_budget: number;
  print_from: string;
  print_to: string;
  print_disabled: string;
  status: string;
  planForecastDate: any;
  forecast_status: string;
  roi_status: string;
  total_budget: number;
  created_by_csv: boolean;
  csv_path: string;
  pre_post_status: string;
  pre_post_updated_at: string;

  constructor(cfg: Record<string, any>) {
    this.id = cfg.id;
    this.name = cfg.name;
    this.alerts_status = cfg.alerts_status; // TODO: This should ideally be enforced as type AlertsStatus
    this.available_from = cfg.available_from;
    this.available_to = cfg.available_to;
    this.print_from = cfg.print_from;
    this.print_to = cfg.print_to;
    this.print_disabled = cfg.print_disabled;
    this.status = cfg.status;
    this.planForecastDate = cfg.forecast_updated_at;
    this.forecast_status = cfg.forecast_status;
    this.roi_status = cfg.roi_status;
    this.created_by_csv = cfg.created_by_csv;
    this.allowed_budget = cfg.allowed_budget;
    this.total_budget = cfg.total_budget;
    this.csv_path = cfg.csv_path;
    this.pre_post_status = cfg.pre_post_status;
    this.pre_post_updated_at = cfg.pre_post_updated_at;
  }

  isForecastInProgress(): boolean {
    return this.forecast_status === 'refreshing';
  }

  isForecastWithErrors(): boolean {
    return this.forecast_status === 'error';
  }

  isRoiInProgress(): boolean {
    return this.roi_status === 'refreshing';
  }

  isRoiWithErrors(): boolean {
    return this.roi_status === 'error';
  }

  isDelivered(): boolean {
    return this.status === 'delivered';
  }

  isAnalysisInProgress(): boolean {
    return this.pre_post_status === 'running';
  }

  isAnalysisDone(): boolean {
    return this.pre_post_status === 'done';
  }
}

export const PlansMock = [
  new Plan({
    id: 1,
    name: 'Plan mock test 1',
    available_from: '2019-12-12',
    available_to: '2021-01-02',
    print_from: '2019-06-12',
    print_to: '2021-01-02',
    status: 'draft',
    forecast_updated_at: '2020-09-08'

  }),
  new Plan({
    id: 2,
    name: 'Plan mock test 2',
    available_from: '2019-12-12',
    available_to: '2021-01-02',
    print_from: '2019-06-12',
    print_to: '2021-01-02',
    status: 'scheduled',
    forecast_updated_at: '2020-09-08'

  }),
  new Plan({
    id: 3,
    name: 'Plan mock test 3',
    available_from: '2019-12-12',
    available_to: '2021-01-02',
    print_from: '2019-06-12',
    print_to: '2021-01-02',
    status: 'delivered',
    forecast_updated_at: '2020-09-08'

  }),
  new Plan({
    id: 4,
    name: 'Plan mock test 4',
    available_from: '2019-12-12',
    available_to: '2021-01-02',
    print_from: '2019-06-12',
    print_to: '2021-01-02',
    status: 'error',
    forecast_updated_at: '2020-09-08'

  }),
  new Plan({
    id: 5,
    name: 'Plan mock test 5',
    available_from: '2019-12-12',
    available_to: '2021-01-02',
    print_from: '2019-06-12',
    print_to: '2021-01-02',
    status: 'sending',
    forecast_updated_at: '2020-09-08'

  })
];
