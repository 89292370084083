import { ActivatedRoute } from "@angular/router";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { CustomEntitiesService } from "../../../../customer-data-platform/custom-entities/services/custom-entities.service";
import { CustomEntity } from "../../../../customer-data-platform/custom-entities/models/custom-entity";
import { FloatQuestion } from "../../../../../shared/models/forms/question-float";
import { HttpErrorResponse } from "@angular/common/http";
import { MultiselectDataSource } from "../../../../../shared/components/multiselect/multiselect";
import { MultiSelectQuestion } from "../../../../../shared/models/forms/question-multiselect";
import { MultiselectService } from "../../../../../shared/components/multiselect/multiselect.service";
import { QuestionBase } from "../../../../../shared/models/forms/question-base";
import { QuestionControlService } from "../../../../../shared/services/question-control.service";
import { Subject, takeUntil } from "rxjs";
import { TextboxQuestion } from "../../../../../shared/models/forms/question-textbox";
import { TranslateService } from "@ngx-translate/core";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: 'app-form-loyalty-scores-custom-entity',
  templateUrl: './form-custom-entity.component.html',
  styleUrls: ['./form-custom-entity.component.scss']
})

export class FormCustomEntityComponent implements OnInit, OnDestroy {

	@Input() entity: CustomEntity;
	@Input() definedEntityAttrs: {value: string, condition: string, field: string, custom_entity_id: string, _destroy?: boolean}[];

	currentSelectedType = 'number';
	entityAttrsForm: UntypedFormGroup;
	inputs: QuestionBase<unknown>[];

  private destroy$: Subject<void> = new Subject<void>();

	constructor(
		private qcs: QuestionControlService,
		private translate: TranslateService,
    private customEntityService: CustomEntitiesService,
    private multiselectService: MultiselectService,
    private route: ActivatedRoute
	) {}

	ngOnInit(): void {
    this.getParams();
	}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

	getEntityPayload(): {field: string, condition: string, value: string, custom_entity_id: string, _destroy?: boolean}[] {
		this.definedEntityAttrs = this.definedEntityAttrs.map(attr => {
			delete attr['motivator_id'];
			return attr;
		})
		return this.definedEntityAttrs;
	}

	deleteLine(index: number) {
		this.definedEntityAttrs[index]._destroy = true;
	}

	entityAttributeChange(selectedValue?: MultiselectDataSource) {
		if(selectedValue) this.currentSelectedType = selectedValue.rawElement.type;
	}

	getInputConfig(inputKey: string): QuestionBase<any> {
		return this.qcs.getInputCfgByKey(this.inputs, inputKey);
	}

	addEntityAttribute() {
		const attrValue = this.entityAttrsForm.value['attribute'];
		const operatorValue = this.entityAttrsForm.value[`${this.currentSelectedType}_operator`];
		const field = attrValue && attrValue[0]?.id;
		const value = this.entityAttrsForm.value[`${this.currentSelectedType}_value`];
		const condition = operatorValue && operatorValue[0]?.id;
		const definedAttr = {value: value, condition: condition, field: field, custom_entity_id: this.entity.id};
		if(this.definedAttributeValid(definedAttr)) {
				this.definedEntityAttrs.push(definedAttr);
				this.entityAttrsForm.reset({
						string_operator: this.entityAttrsForm.get('string_operator').value,
            custom_entity_definition_id: this.entityAttrsForm.get('custom_entity_definition_id').value
				});
		} else {
				console.warn('Attribute invalid... alert message');
		}
	}

	decorateAttrName(rawName: string) {
		rawName = rawName.replace(/\-/g, ' ');
		rawName = rawName.replace(/\_/g, ' ');
		rawName = rawName.charAt(0).toUpperCase() + rawName.slice(1);
		return rawName;
	}

	decorateAttrOperator(operator: string): string {
		return this.getOperators().find(op => op.id === operator).name;
	}

  handleEntitySelected(entity) {
    this.definedEntityAttrs.forEach(attr => {
      attr._destroy = true;
    });
    this.getCustomEntityData(entity.rawElement.id);
    this.multiselectService.updateDropdownList.next('attribute');
  }

  private getCustomEntityData(customEntity) {
    this.customEntityService.getById(customEntity).pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: CustomEntity) => {
          this.entity = response;
          this.setAttributeOptions();
      },
      error: (error: HttpErrorResponse) => console.warn('custom entity get error', error)
    });
  }

  private setAttributeOptions() {
    const attributeInput = this.inputs.find(input => input.key === 'attribute');
    attributeInput.options = this.getEntityAttributesAsOptions(this.entity);
    this.multiselectService.updateDropdownList.next('attribute');
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.inputs = this.getInputs(this.entity);
		    this.entityAttrsForm = this.qcs.toFormGroup(this.inputs);
        this.setAttributeOptions();
      } else {
        this.inputs = this.getInputs({});
		    this.entityAttrsForm = this.qcs.toFormGroup(this.inputs);
      }
    });
  }

	private definedAttributeValid(definedAttr: {value: string, condition: string, field: string}): boolean {
		return !!definedAttr.value && !!definedAttr.condition && !!definedAttr.field;
	}

  private getEntityAttributesAsOptions(entity): MultiselectDataSource[] {
    if(this.entity) {
      const keys = Object.keys(entity.entity_attributes);
      return keys.map(key => (new MultiselectDataSource(key, this.decorateAttrName(key), entity.entity_attributes[key])));
    }
	}

	private getInputs(formValues): QuestionBase<any>[] {
		const inputs = [
			new MultiSelectQuestion({
				cssClasses: 'form-control input-md',
				key: `custom_entity_definition_id`,
				dataSource: this.customEntityService,
				settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        required: true,
        selectedIds: formValues?.id ? [formValues?.id] : null
			}),
			new MultiSelectQuestion({
				cssClasses: 'form-control input-md',
				key: `attribute`,
				settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false }
			}),
			new MultiSelectQuestion({
				cssClasses: 'form-control input-md',
				key: `number_operator`,
				options: this.getOperators(),
				settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false }
			}),
			new FloatQuestion({
				cssClasses: 'form-control input-default',
				key: `number_value`,
				type: 'number'
			}),
			new MultiSelectQuestion({
				cssClasses: 'form-control input-md',
				key: `string_operator`,
				options: [this.getOperators()[0]],
				settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
				value: [this.getOperators()[0]],
				disabled: true
			}),
			new TextboxQuestion({
				cssClasses: 'form-control input-default',
				key: `string_value`,
				type: 'text'
			})
		];
		return inputs;
	}

	private getOperators(): {id: string, name: string}[] {
		return [
			{id: 'eq', name: this.translate.instant('resources.segment_conditions.operators.eq') },
			{id: 'lteq', name: this.translate.instant('resources.segment_conditions.operators.lteq') },
			{id: 'lt', name: this.translate.instant('resources.segment_conditions.operators.lt') },
			{id: 'gteq', name: this.translate.instant('resources.segment_conditions.operators.gteq') },
			{id: 'gt', name: this.translate.instant('resources.segment_conditions.operators.gt') }
		];
	}
}
