export function getDefaultNavigatorLanguage(): string {
  const defaultLang = 'en';
  const spanishLang = 'es';
  const navigatorLanguage = navigator?.language ?? defaultLang;
  const [lang] = navigatorLanguage.split('-');
  const supportedNavigatorLangs = {
    en: 'en',
    es: spanishLang,
    it: 'it',
    fr: 'fr',
    hr: 'hr',
    ro: 'ro',
    ca: spanishLang,
    gl: spanishLang,
    eu: spanishLang
  };
  return supportedNavigatorLangs[lang] ?? defaultLang;
}
