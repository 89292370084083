import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TextareaQuestion } from '../../../../../../shared/models/forms/question-textarea';
import { QuestionBase } from '../../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../../shared/services/question-control.service';
import { UntypedFormGroup } from '@angular/forms';
import { SharedModule } from "../../../../../../shared/shared.module";
import { FileQuestion } from '../../../../../../shared/models/forms/question-file';
import { HttpClient } from '@angular/common/http';
import { CsvExportService } from '../../../../../../shared/services/csv-export.service';

@Component({
    selector: 'app-upload-csv-demo',
    templateUrl: './upload-csv-demo.component.html',
    styleUrls: ['./upload-csv-demo.component.css'],
    standalone: true,
    imports: [SharedModule]
})
export class UploadCsvDemoComponent implements OnInit {

  uploadCsvForm: UntypedFormGroup;
  modalInputs: QuestionBase<any>[];

  @ViewChild('uploadCSV') uploadCSV: ElementRef;

  constructor(
    private qcs: QuestionControlService,
    private http: HttpClient,
    private csvExportService: CsvExportService
  ) { }

  ngOnInit() {
    this.modalInputs = this.setInput();
    this.uploadCsvForm = this.qcs.toFormGroup(this.modalInputs);
  }

  open() {
    this.uploadCSV.nativeElement.click();
  }

  downloadTemplate() {
    this.http.get('/coupons/csv_template', { responseType: 'text' as 'json'}).subscribe(data => {
      this.downloadCSV(data);
    });
  }

  downloadCSV(data) {
    const blob = this.csvExportService.getCsvBlob(data);
    const url = window.URL.createObjectURL(blob);
    const filename = `csv_template.csv`;

    if (navigator['msSaveOrOpenBlob']) {
      navigator['msSaveBlob'](blob, filename);
    } else {
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.modalInputs, inputKey);
  }

  setInput(): QuestionBase<any>[] {
    return [
      new TextareaQuestion({
        key: 'csv',
        label: 'csv',
        cssClasses: 'form-control input-md',
        settings: { rows: 5 }
      }),
      new FileQuestion({
        key: 'csv_file',
        types: ['csv'],
        multiple: false,
        cssClasses: 'form-control input-default',
        getValue: (value) => value
      }),
    ];
  }

}
