import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { LocationTaxonomiesService } from '../location-taxonomies.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-form-locations-taxonomies',
  templateUrl: './form-locations-taxonomies.component.html',
  styleUrls: ['./form-locations-taxonomies.component.css']
})
export class FormLocationsTaxonomiesComponent implements OnInit, OnDestroy {

  locationTaxonomiesForm: UntypedFormGroup;
  inputs: QuestionBase<any>[];
  id: number;

  private destroy$: Subject<void> = new Subject<void>();
  
  @Output() formValidity: EventEmitter<string> = new EventEmitter<string>();
  
  constructor(
    private qcs: QuestionControlService,
    private locationsTaxonomiesService: LocationTaxonomiesService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private modalStatusService: ModalStatusService
  ) {}

  ngOnInit(): void {
    this.getParams();
  }
  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }
  
  sendData(): void {
    const payload = {
      name: this.locationTaxonomiesForm.value.name,
      slug: this.locationTaxonomiesForm.value.slug,
      origin: this.locationTaxonomiesForm.value.origin?.length ? this.locationTaxonomiesForm.value.origin[0].id : null
    };

    this.modalStatusService.formLoaderStatus.emit('loading');
    const request$ = this.id ? this.locationsTaxonomiesService.updateData(payload, this.id) : this.locationsTaxonomiesService.sendData(payload);
    request$.pipe(takeUntil(this.destroy$)).subscribe(
      () => this.handleSuccessRequest(),
      (errorData: HttpErrorResponse) => this.handleErrors(errorData)
    );
  }
  
  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.modalStatusService.formLoaderStatus.emit('loading_data');
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getLocationTaxonomyById();
      } else {
        this.setInputsAndForm({});
      }
    });
  }

  private getLocationTaxonomyById(): void {
    this.locationsTaxonomiesService.getLocationsTaxonomiesById(this.id).pipe(takeUntil(this.destroy$)).subscribe(
      (data: HttpResponse<object>) => this.setInputsAndForm(data),
      (errorData: HttpErrorResponse) => this.displayErrorAlert(errorData.error.error)
    );
  }

  private setInputsAndForm(requestData: object | HttpResponse<object>): void {
    this.inputs = this.locationsTaxonomiesService.getInputs(requestData);
    this.locationTaxonomiesForm = this.qcs.toFormGroup(this.inputs);
    this.formStatusChanges();
    this.modalStatusService.formLoaderStatus.emit('loading_data_finished');
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.locationTaxonomiesForm.status));
    this.locationTaxonomiesForm.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
  }
  
  private handleSuccessRequest(): void {
    const successTitle = this.translate.instant(this.id ? 'resources.location_taxonomies.warnings.update_title' : 'resources.location_taxonomies.warnings.success_title');
    const successText = this.translate.instant(this.id ? 'resources.location_taxonomies.warnings.update_text' : 'resources.location_taxonomies.warnings.success_text');
    this.confirmationService.displaySuccessAlert(successTitle, successText).catch(() => {});
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    this.modalStatusService.modalStatus.emit();
    this.returnToList();
  }

  private handleErrors(errorData: HttpErrorResponse): void {
    this.modalStatusService.formLoaderStatus.emit('loading_finished');
    const errorValue = errorData?.error?.error ? errorData.error.error : null;
    if (errorData?.error?.errors?.length) {
      this.qcs.paintErrorsInForm(this.inputs, this.locationTaxonomiesForm, errorData.error.errors);
      if (errorValue) { this.displayErrorAlert(errorValue); }
    } else if (errorValue) {
      this.displayErrorAlert(errorValue);
    }
  }

  private displayErrorAlert(error: string): void {
    this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), error);
  }

  private returnToList(): void {
    if (this.router.url.indexOf('(modal:') >= 0 ) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.router.navigate(['/customer-data-platform/warehouse/locations/location-taxonomies']).catch(() => {});
    }
  }
}
