<div class="row">
  <div class="col-md-12">
    <label class="text-bold"
            [translate]="'resources.scores.loyalty_scores.fields.product_features'">
    </label>
  </div>
</div>
<!-- FALTA TRADUCIR DEMAS IDIOMAS -->
<table class="table table-bordered table-striped" *ngIf="featureConditionsAttributes.length > 0">
  <thead>
    <tr>
      <th class="text-center">{{'resources.scores.loyalty_scores.form.options.product_feature_conditions.table.columns.name' | translate}}</th>
      <th class="text-center">{{'resources.scores.loyalty_scores.form.options.product_feature_conditions.table.columns.total_option' | translate}}</th>
      <th class="text-center">{{'resources.scores.loyalty_scores.form.options.product_feature_conditions.table.columns.total_value' | translate}}</th>
      <th class="text-center">{{'resources.scores.loyalty_scores.form.options.product_feature_conditions.table.columns.condition' | translate}}</th>
      <th class="text-center">{{'resources.scores.loyalty_scores.form.options.product_feature_conditions.table.columns.quantity_option' | translate}}</th>
      <th class="text-center">{{'resources.scores.loyalty_scores.form.options.product_feature_conditions.table.columns.quantity_value' | translate}}</th>
      <th class="text-center">{{'resources.scores.loyalty_scores.form.options.product_feature_conditions.table.columns.actions' | translate}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of featureConditionsAttributes; let i = index" [hidden]="item._destroy">
      <td class="text-center">{{ item.feature_name }}</td>
      <td class="text-center">{{ item.feature_total_option }}</td>
      <td class="text-center">{{ item.feature_total_value }}</td>
      <td class="text-center">{{ item.feature_condition }}</td>
      <td class="text-center">{{ item.feature_quantity_option }}</td>
      <td class="text-center">{{ item.feature_quantity_value }}</td>
      <td class="text-center">
        <em class="fa fa-times pointer" (click)="deleteProductFeatureContidionLine(i)"></em>
      </td>
    </tr>
  </tbody>
</table>

<div class="row">
  <div class="col-md-4">
    <div class="form-group">
      <app-question
                [question]="getInputConfig('feature_condition')"
                [form]="loyaltyScoresForm">
      </app-question>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-4">
    <div class="form-group">
      <app-question [question]="getInputConfig('feature_ids')"
                    [form]="loyaltyScoresForm">
      </app-question>
    </div>
  </div>

  <div class="col">
    <div class="form-group">
      <app-question [question]="getInputConfig('feature_total_options')" [form]="loyaltyScoresForm"></app-question>
    </div>
  </div>

  <div class="col-md-1">
    <div class="form-group align-values">
      <label class="text-bold">Total</label>
      <app-question [question]="getInputConfig('feature_total_value')" [form]="loyaltyScoresForm"></app-question>
    </div>
  </div>

  <!-- QUANTITY -->
  <div class="col">
    <div class="form-group">
      <app-question [question]="getInputConfig('feature_quantity_options')" [form]="loyaltyScoresForm"></app-question>
    </div>
  </div>

  <div class="col-md-1">
    <div class="form-group align-values">
      <label class="text-bold">Quantity</label>
      <app-question [question]="getInputConfig('feature_quantity_value')" [form]="loyaltyScoresForm"></app-question>
    </div>
  </div>

  <div class="col-md-1">
    <button class="btn btn-success btn-icon no-margin-right pull-right"
      (click)="addProductFeatureConditionLine()">
      <i class="fa fa-plus"></i>
    </button>
  </div>

</div>
