<div class="modal-backdrop fade in modal-fs" id='modal-fs' tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body height-100 background-gray">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-block no-pdd no-mrg pdd-horizon-15 pdd-top-15" *ngIf="plan">
                <h4 class="card-title no-mrg-btm" *ngIf="plan && currentStep === 1">
									{{'resources.plans.title_single' | translate}} /
									{{plan.name}} /
									{{'resources.campaign_plans.steps.' + slug | translate}}
								</h4>
								<h4 class="card-title no-mrg-btm" *ngIf="plan && selectedType && currentStep > 1">
									{{'resources.plans.title_single' | translate}} /
									{{plan.name}} /
									{{selectedType.name}} /
									{{'resources.campaign_plans.steps.' + slug | translate}}
									<ng-container *ngIf="planCampaign">
										/ {{planCampaign.name}}
									</ng-container>
								</h4>
								<h4 class="card-title no-mrg-btm" *ngIf="!plan || !selectedType">&nbsp;</h4>
								<app-steps-bubbles [plan]="plan" [planCampaign]="planCampaign"></app-steps-bubbles>
              </div>
						</div>
            <div class="tab-info">
              <div class="tab-content">
								<app-campaign-selection (typeEmitter)="saveType($event)" [slug]="slug"></app-campaign-selection>
								<div [ngClass]="{'card': currentStep > 1}">
									<ng-container *ngIf="slug === 'step_2' && selectedType && selectedType.type === 'Plans::CustomCampaign'">
										<app-loader *ngIf="planCampaignLoader" />
										<div class="card-body" *ngIf="!planCampaignLoader">
											<app-custom-campaign-plan-definition #definition
																													(formValidity)="handleDefinitionFormStatus($event)"
																													[planCampaign]="planCampaign">
											</app-custom-campaign-plan-definition>
										</div>
									</ng-container>
									<ng-container *ngIf="slug === 'step_2' && selectedType && selectedType.type === 'Plans::SupplierCampaign'">
										<app-loader *ngIf="planCampaignLoader" />
										<div class="card-body" *ngIf="!planCampaignLoader">
											<app-supplier-campaign-plan-definition #supplierDefinition
																													(formValidity)="handleDefinitionFormStatus($event)"
																													[planCampaign]="planCampaign">
											</app-supplier-campaign-plan-definition>
										</div>
									</ng-container>
									<ng-container *ngIf="slug === 'step_2' && selectedType && selectedType.type === 'Plans::UpCrossCampaign'">
										<app-loader *ngIf="planCampaignLoader" />
										<div class="card-body" *ngIf="!planCampaignLoader">
											<app-up-cross-campaign-definition #upCrossDefinition
																												(formValidity)="handleDefinitionFormStatus($event)"
																												[planCampaign]="planCampaign">
											</app-up-cross-campaign-definition>
										</div>
									</ng-container>
									<ng-container *ngIf="slug === 'step_2' && selectedType && selectedType.type === 'Plans::RecoveryCampaign'">
										<app-loader *ngIf="planCampaignLoader" />
										<div class="card-body" *ngIf="!planCampaignLoader">
											<app-recovery-campaign-plan-definition #recoveryDefinition
																												(formValidity)="handleDefinitionFormStatus($event)"
																												[planCampaign]="planCampaign">
											</app-recovery-campaign-plan-definition>
										</div>
									</ng-container>
                  <ng-container *ngIf="slug === 'step_2' && selectedType && selectedType.type === 'Plans::1on1Campaign'">
										<app-loader *ngIf="planCampaignLoader" />
										<div class="card-body" *ngIf="!planCampaignLoader">
											<app-1on1-campaign-plan-definition #1on1Definition
																												(formValidity)="handleDefinitionFormStatus($event)"
																												[planCampaign]="planCampaign">
											</app-1on1-campaign-plan-definition>
										</div>
									</ng-container>
									<ng-container *ngIf="slug === 'step_3' && selectedType && (selectedType.type === 'Plans::CustomCampaign' ||
																																							selectedType.type === 'Plans::SupplierCampaign' ||
																																							selectedType.type === 'Plans::UpCrossCampaign' ||
																																							selectedType.type === 'Plans::RecoveryCampaign')">
										<app-loader *ngIf="planCampaignLoader" />
										<div class="card-body" *ngIf="!planCampaignLoader && plan">
											<app-custom-campaign-plan-audience #audience
																												 (formValidity)="handleDefinitionFormStatus($event)"
                                                         (requestsStatus)="handleRequestStatus($event)"
                                                         (calculatedDataStatus)="handleCalculatedData($event)"
                                                         [plan]="plan"
																												 [planCampaign]="planCampaign">
											</app-custom-campaign-plan-audience>
										</div>
									</ng-container>
									<ng-container *ngIf="slug === 'step_4' && selectedType && (selectedType.type === 'Plans::CustomCampaign' ||
																																							selectedType.type === 'Plans::SupplierCampaign' ||
																																							selectedType.type === 'Plans::UpCrossCampaign' ||
																																							selectedType.type === 'Plans::RecoveryCampaign')">
										<app-loader *ngIf="planCampaignLoader" />
										<div class="card-body" *ngIf="!planCampaignLoader && plan">
											<app-custom-campaign-plan-forecast [plan]="plan"
																												 [planCampaign]="planCampaign"
                                                         (statusEmitter)="handleForecastStatus($event)"
                                                         (calculatedDataStatus)="handleCalculatedData($event)">
                      </app-custom-campaign-plan-forecast>
										</div>
									</ng-container>
								</div>
              </div>
            </div>
          </div>
        </div>
        <a class="modal-close margin-10 mrg-top-15" data-dismiss="modal" (click)="confirmAndCloseModal()">
          <i class="ti-close"></i>
        </a>
      </div>
			<div class="modal-footer background-white" *ngIf="!isPlanDelivered()">
				<div *ngIf="currentStep > 1">
					<app-steps-nav #nav *ngIf="plan" [plan]="plan" (navEvent)="handleNavEvent($event)"></app-steps-nav>
				</div>
			</div>
    </div>
  </div>
</div>
