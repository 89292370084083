<div class="modal-backdrop fade in" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h1 class="mrg-left-15 mrg-btm-5" [translate]="'resources.segments.retro_tag.title'"></h1>
        <button class="modal-close" (click)="closeModal()">
          <em class="ti-close"></em>
        </button>
      </div>

      <div class="modal-body background-gray">
        <div class="row">
          <div class="col-md-12">
            <div class="container-fluid">
              <div class="card-block no-pdd">
                <div class="mrg-top-40">
                  <div class="row">
                    <div class="col-md-8 ml-auto mr-auto">
                      <form [formGroup]="retroTagForm" *ngIf="retroTagForm">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="iterations" [translate]="'resources.segments.retro_tag.iterations'"></label>
                              <span *ngIf="getInputConfig('iterations').required" class="text-danger">*</span>
                              <app-question [question]="getInputConfig('iterations')" [form]="retroTagForm">
                              </app-question>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="tag_date" [translate]="'resources.segments.retro_tag.tag_date'"></label>
                              <span *ngIf="getInputConfig('tag_date').required" class="text-danger">*</span>
                              <app-question [question]="getInputConfig('tag_date')" [form]="retroTagForm" (change)="changeDate()">
                              </app-question>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <div class="alert alert-info" *ngIf="retroTagForm.value.tag_date">
                              <ng-container *ngIf="isSegmenRetroTagMode">
                                {{'resources.segments.retro_tag.first_tag_will_have_date' | translate}} {{periodStart | emptyData}}
                              </ng-container>
                              <ng-container *ngIf="isPeriodsRetroTagMode">
                                {{'resources.segments.retro_tag.first_period' | translate}} {{periodStart | emptyData}}
                                {{'resources.segments.retro_tag.first_period_to' | translate}} {{periodEnd | emptyData}}
                              </ng-container>
                            </div>
                          </div>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer background-white align-content-center">
        <div class="pull-right d-flex align-items-center">
          <button class="pull-right btn btn-info"
            (click)="sendData()"
            [disabled]="loading || !retroTagForm?.valid">
            <span *ngIf="loading">
              <em class="fa fa-spinner fa-spin"></em>
            </span>
            {{ 'resources.products.buttons.send' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>