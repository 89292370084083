import { ActivatedRoute, Router } from "@angular/router";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ConfirmationService } from "../../../../shared/services/confirmation.service";
import { DictionaryService } from "../../../../shared/services/dictionary.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Partner } from "../partner";
import { PartnersService } from "../partners.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-show-partners',
  templateUrl: './partner-detail.component.html',
  styleUrls: ['./partner-detail.component.scss']
})

export class PartnerDetailComponent implements OnInit {
  partnerId = this.route.snapshot.params.id;
  partner: Partner;
  missingImg: boolean = false;
  typeName: string;
  supplierName: string;

  @Output('updateEmitter') updateEmitter: EventEmitter<any>  = new EventEmitter();

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly dictionaryService: DictionaryService,
    private readonly partnersService: PartnersService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translate: TranslateService
  ){}

  ngOnInit(): void {
    this.getPartnerById();
  }

  closeModal(): void {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private getPartnerById(): void {
    this.partnersService.getById(this.partnerId).subscribe(
      (partner: Partner) => this.handleSuccessfulResponse(partner),
      (err: HttpErrorResponse) => this.handleErrorResponse(err)
    )
  }

  private handleSuccessfulResponse(partner: Partner): void {
    this.typeName = partner?.type ? this.getTypeName(partner.type) : null;
    this.supplierName = partner?._embedded?.supplier?.name ? partner._embedded.supplier?.name : null;
    this.missingImg = partner.thumbnail.indexOf('thumbnail/missing') >= 0;
    this.partner = partner;
    this.updateEmitter.emit(['', { outlets: { modal: ['update', 'partners', this.partner.id]}}]);
  }

  private handleErrorResponse(err: HttpErrorResponse): void {
    this.confirmationService.displayErrorAlert(
      this.translate.instant('common.error'),
      err.error.error
    )
    this.closeModal();
  }

  private getTypeName(type: string): string {
    let parseTypeName = null;
    const typeOptionValue = this.dictionaryService.getValuesByKey('partners_type').filter(option => option.id === type);
    return typeOptionValue?.[0]?.name ? this.translate.instant(typeOptionValue[0].name) : parseTypeName;
  }
}