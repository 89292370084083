<div class="row">
  <div class="col-md-6">
    <div class="form-group">
      <label>{{'resources.campaigns.fields.description' | translate}}</label>
      <app-question [question]="getInputConfig('description')" [form]="campaignForm"></app-question>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group">
      <label>{{'resources.campaigns.fields.category' | translate}}</label>
      <app-question [question]="getInputConfig('campaign_category')" [form]="campaignForm"></app-question>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <div class="form-group">
      <label>
        {{'resources.campaigns.fields.via' | translate}}
        <span class="text-danger">*</span>
      </label>
      <app-question [question]="getInputConfig('via')" [form]="campaignForm"
        (multiselectChanged)="handleViaInputChanged($event)">
      </app-question>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group">
      <label>{{'resources.campaigns.fields.type' | translate}}</label>
      <app-question [question]="getInputConfig('motive')" [form]="campaignForm"></app-question>
    </div>
  </div>
</div>

<div class="row"
  *ngIf="flags.displayCampaignSender && refreshSender && (hasFormKeyWithValue('via', 'email') || hasFormKeyWithValue('via', 'sms'))">
  <div class="col-md-6">
    <div class="form-group">
      <label>
        {{'resources.campaigns.fields.sender' | translate}}
        <span class="text-danger">*</span>
      </label>
      <app-question [question]="getInputConfig('sender_id')" [filters]="{ via: campaign.via}" [form]="campaignForm"
        (optionsLoaded)="handleSenderOptsLoaded($event)">
      </app-question>
    </div>
  </div>
</div>
<div class="row" *ngIf="hasFormKeyWithValue('motive', 'coupon') || hasFormKeyWithValue('motive', 'membership')">
  <div class="col-md-6">
    <div class="form-group">
      <label>{{'resources.campaigns.fields.coupon' | translate}}</label>
      <app-question [question]="getInputConfig('coupon_ids')" (multiselectChanged)="handleCouponValueChanged($event)"
        [form]="campaignForm"></app-question>
    </div>
  </div>
  <div class="col-md-6" *ngIf="!membershipCampaign && campaignForm.value.motive !== 'membership' && mode !== 'plan_manual_campaign'">
    <label>&nbsp;</label>
    <app-question [question]="getInputConfig('reward')" [form]="campaignForm"></app-question>
  </div>
</div>
<div class="row" *ngIf="hasFormKeyWithValue('motive', 'reminder')">
  <div class="col-md-6">
    <div class="form-group">
      <label>{{'resources.campaigns.fields.coupon' | translate}}</label>
      <app-question [question]="getInputConfig('coupon_ids')" (multiselectChanged)="handleCouponValueChanged($event)"
        [form]="campaignForm"></app-question>
    </div>
  </div>
  <div class="col-md-6" [hidden]="mode === 'reminder_plan' || mode === 'received_reminder' || campaign.reminder_plan">
    <div class="form-group">
      <label>{{'resources.campaigns.fields.coupon_history' | translate}}</label>
      <app-question [question]="getInputConfig('campaign_history_coupon_id')" [form]="campaignForm"></app-question>
    </div>
  </div>
</div>
<div class="row" *ngIf="hasFormKeyWithValue('motive', 'membership')">
  <div class="col-md-6">
    <div class="form-group">
      <label>{{'resources.campaigns.fields.collective' | translate}}</label>
      <app-question [question]="getInputConfig('collective_id')" [form]="campaignForm"></app-question>
    </div>
  </div>
</div>
<div class="row" *ngIf="hasFormKeyWithValue('motive', 'grant_points')">
  <div class="col-md-12">
    <div class="form-group">
      <label>{{'resources.campaigns.fields.loyalty_score_id' | translate}}</label>
      <app-question [question]="getInputConfig('loyalty_score_id')" [form]="campaignForm"></app-question>
    </div>
  </div>
</div>
<div class="row" *ngIf="hasFormKeyWithValue('motive', 'challenge')">
  <div class="col-md-12">
    <div class="form-group">
      <label>{{'resources.campaigns.motive.challenge' | translate}}</label>
      <app-question [question]="getInputConfig('challenge_id')" [form]="campaignForm"></app-question>
    </div>
  </div>
</div>
<div class="row" *ngIf="hasFormKeyWithValue('via', 'ticket_message')">
  <div class="col-md-12">
    <div class="form-group">
      <label>{{'resources.campaigns.fields.message' | translate}}</label>
      <app-question [question]="getInputConfig('message_ids')" [form]="campaignForm"></app-question>
    </div>
  </div>
</div>
<div class="row" *ngIf="mode !== 'plan_manual_campaign' && !hasFormKeyWithValue('motive', 'membership')">
  <div class="col-md-3">
    <div class="form-group">
      <label *ngIf="!hasFormKeyWithValue('periodic', true)">{{'resources.campaigns.fields.scheduled_at'
        | translate}}</label>
      <label *ngIf="hasFormKeyWithValue('periodic', true)">{{'resources.campaigns.fields.first_delivery'
        | translate}}</label>
      <app-question [question]="getInputConfig('scheduled_at')" [form]="campaignForm" (input)="inputHandler($event)"
        (blur)="inputHandler($event)" (click)="inputHandler($event)"></app-question>
    </div>
  </div>
  <div class="col-md-3">
    <label>&nbsp;</label>
    <app-question [question]="getInputConfig('periodic')" [form]="campaignForm"></app-question>
  </div>
  <div class="col-md-3">
    <label [translate]="'resources.campaigns.fields.executions'"></label>
    <app-question [question]="getInputConfig('execution')" [form]="campaignForm"></app-question>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label>{{'resources.campaigns.fields.execution_until' | translate}}</label>
      <app-question [question]="getInputConfig('execution_until')" [form]="campaignForm"></app-question>
    </div>
  </div>
</div>
<h5 class="card-title border bottom" *ngIf="scheduledDateMsg">
  {{'resources.campaigns.messages.deliveries_will_be_on' | translate}}: <b>{{scheduledDateMsg}}</b>
</h5>
