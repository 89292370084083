<ng-container *ngIf="inputs">
  <div class="row">
    <div class="col-md-12 col-lg-12 col-xl-4 pdd-top-25">
      <!-- Radio input: Scopes (ticket or product or features) -->
      <app-question [question]="inputs[0]" [form]="form"></app-question>
    </div>

    <div class="col-md-6 col-lg-6 col-xl-3">
      <app-question [question]="getInputConfig('operator')" [form]="form"></app-question>
    </div>

    <div class="col-md-6 col-lg-6 col-xl-5" *ngIf="scopeValue === 'activity'">
    </div>

    <div class="col-md-6 col-lg-6 col-xl-5" *ngIf="scopeValue === 'feature'">
      <app-question [question]="getInputConfig('feature_ids')" [form]="form"></app-question>
    </div>

    <div class="col-md-6 col-lg-6 col-xl-5" *ngIf="scopeValue === 'product'">
      <app-question [question]="getInputConfig('product_ids')" [form]="form" [useToken]="true"></app-question>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 col-lg-6 col-xl-3">
      <app-question [question]="getInputConfig('date_from')" [form]="form"></app-question>
    </div>

    <div class="col-md-6 col-lg-6 col-xl-3">
      <app-question [question]="getInputConfig('date_to')" [form]="form"></app-question>
    </div>

    <div *ngIf="flags.showLocationTaxonomyTermIds" class="col-md-6 col-lg-6 col-xl-3">
      <app-question [question]="getInputConfig('location_taxonomy_term_ids')"
                    [form]="form"
                    (multiselectChanged)="handleSelectedLocationTaxonomyTermIds($event)">
      </app-question>
    </div>

    <div class="col-md-6 col-lg-6 col-xl-3">
      <app-question [question]="getInputConfig('location_ids')" [form]="form"></app-question>
    </div>
  </div>

  <ng-container *ngIf="flags.showTicketsSegmentNetAmount; else includeReturns">
    <div class="row mrg-btm-10">
      <div class="col-md-6 col-lg-6 col-xl-3">
        <app-question [question]="getInputConfig('total_sum_operator')"
                      [form]="form"
                      (multiselectChanged)="handleTotalSumOperatorChanges($event)"></app-question>
      </div>

      <div class="col-md-6 col-lg-3 col-xl-2" *ngIf="!hasFormKeyWithValue('total_sum_operator', 'bt')">
        <app-question [question]="getInputConfig('total_sum_value')" [form]="form"></app-question>
      </div>

      <ng-container *ngIf="hasFormKeyWithValue('total_sum_operator', 'bt')">
        <div class="col-md-3 col-lg-3 col-xl-2">
          <app-question [question]="getInputConfig('total_sum_value_1')" [form]="form"></app-question>
        </div>
        <div class="col-md-3 col-lg-3 col-xl-2">
          <app-question [question]="getInputConfig('total_sum_value_2')" [form]="form"></app-question>
        </div>
      </ng-container>

      <div class="col d-flex align-items-center">
        <div class="checkbox-wrapper-center" [class.between-operator]="hasFormKeyWithValue('total_sum_operator', 'bt')">
          <app-question [question]="inputs[18]" [form]="form"></app-question>
          <span>{{ 'resources.segment_conditions.fields.net_amount' | translate }}</span>
        </div>
      </div>
    </div>

    <div class="row mrg-btm-10">
      <div class="col-md-6 col-lg-6 col-xl-3">
        <app-question [question]="getInputConfig('activity_count_operator')"
                      [form]="form"
                      (multiselectChanged)="handleActivityCountOperatorChanges($event)"></app-question>
      </div>

      <div class="col-md-6 col-lg-3 col-xl-2" *ngIf="!hasFormKeyWithValue('activity_count_operator', 'bt')">
        <app-question [question]="getInputConfig('activity_count_value')" [form]="form"></app-question>
      </div>

      <ng-container *ngIf="hasFormKeyWithValue('activity_count_operator', 'bt')">
        <div class="col-md-3 col-lg-3 col-xl-2">
          <app-question [question]="getInputConfig('activity_count_value_1')" [form]="form"></app-question>
        </div>
        <div class="col-md-3 col-lg-3 col-xl-2">
          <app-question [question]="getInputConfig('activity_count_value_2')" [form]="form"></app-question>
        </div>
      </ng-container>

      <ng-container *ngIf="hasFormKeyWithValue('activity_count_operator', 'lt') || hasFormKeyWithValue('activity_count_operator', 'lteq')">
        <div class="col d-flex align-items-center">
          <div class="checkbox-wrapper-center">
            <app-question [question]="inputs[2]" [form]="form"></app-question>
            <span>{{ 'resources.segment_conditions.fields.activity_count_open_to_anyone' | translate }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #includeReturns>
    <div class="row mrg-btm-10">
      <div class="col-md-6 col-lg-6 col-xl-3">
        <app-question [question]="getInputConfig('total_sum_operator')"
                      [form]="form"
                      (multiselectChanged)="handleTotalSumOperatorChanges($event)"></app-question>
        <!-- Include purchases return checkbox -->
        <ng-container *ngIf="form.value.total_sum_value || form.value.total_sum_value_1">
          <div class="checkbox-wrapper">
            <app-question [question]="inputs[1]" [form]="form"></app-question>
            <span>{{'resources.segment_conditions.fields.total_sum_open_to_nonpositive_tickets' | translate }}</span>
          </div>
        </ng-container>
      </div>

      <div class="col-md-6 col-lg-6 col-xl-2" *ngIf="!hasFormKeyWithValue('total_sum_operator', 'bt')">
        <app-question [question]="getInputConfig('total_sum_value')" [form]="form"></app-question>
      </div>

      <ng-container *ngIf="hasFormKeyWithValue('total_sum_operator', 'bt')">
        <div class="col-md-3 col-lg-3 col-xl-1">
          <app-question [question]="getInputConfig('total_sum_value_1')" [form]="form"></app-question>
        </div>
        <div class="col-md-3 col-lg-3 col-xl-1">
          <app-question [question]="getInputConfig('total_sum_value_2')" [form]="form"></app-question>
        </div>
      </ng-container>

      <div class="col-md-6 col-lg-6 col-xl-3">
        <app-question [question]="getInputConfig('activity_count_operator')"
                      [form]="form"
                      (multiselectChanged)="handleActivityCountOperatorChanges($event)"></app-question>
        <!-- Include customers without tickets -->
        <ng-container *ngIf="hasFormKeyWithValue('activity_count_operator', 'lt') || hasFormKeyWithValue('activity_count_operator', 'lteq')">
          <div class="checkbox-wrapper">
            <app-question [question]="inputs[2]" [form]="form"></app-question>
            <span>{{'resources.segment_conditions.fields.activity_count_open_to_anyone' | translate }}</span>
          </div>
        </ng-container>
      </div>

      <div class="col-md-6 col-lg-6 col-xl-2" *ngIf="!hasFormKeyWithValue('activity_count_operator', 'bt')">
        <app-question [question]="getInputConfig('activity_count_value')" [form]="form"></app-question>
      </div>

      <ng-container *ngIf="hasFormKeyWithValue('activity_count_operator', 'bt')">
        <div class="col-md-3 col-lg-3 col-xl-1">
          <app-question [question]="getInputConfig('activity_count_value_1')" [form]="form"></app-question>
        </div>
        <div class="col-md-3 col-lg-3 col-xl-1">
          <app-question [question]="getInputConfig('activity_count_value_2')" [form]="form"></app-question>
        </div>
      </ng-container>
    </div>
  </ng-template>
</ng-container>
