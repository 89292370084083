import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FeatureConditionsAttributes } from '../../feature-conditions-model/feature-conditions-attributes.interface';
import { QuestionBase } from '../../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../../shared/services/question-control.service';

@Component({
  selector: 'app-feature-condition-attributes',
  templateUrl: './feature-condition-attributes.component.html',
  styleUrls: ['./feature-condition-attributes.component.scss']
})
export class FeatureConditionAttributesComponent {

  @Input('loyaltyScoresForm') loyaltyScoresForm: UntypedFormGroup;
  @Input('inputs') inputs;
  @Input('flags') flags;

  featureConditionsAttributes: FeatureConditionsAttributes[] = [];

  constructor(
    private qcs: QuestionControlService
  ) { }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  addProductFeatureConditionLine() {
    const featureName = this.loyaltyScoresForm.get('feature_ids').value;
    const featureCondition = this.loyaltyScoresForm.get('feature_condition').value;
    const featureTotalOption = this.loyaltyScoresForm.get('feature_total_options').value;
    const featureTotalValue = this.loyaltyScoresForm.get('feature_total_value').value;
    const featureQuantityOption = this.loyaltyScoresForm.get('feature_quantity_options').value;
    const featureQuantityValue = this.loyaltyScoresForm.get('feature_quantity_value').value;

    if (featureCondition && featureTotalOption && featureTotalValue && featureQuantityOption && featureQuantityValue ) {
      this.featureConditionsAttributes.push({
        feature_name: featureName[0].name,
        feature_condition: featureCondition[0].id.charAt(0).toUpperCase() + featureCondition[0].id.slice(1),
        feature_total_option: featureTotalOption[0].name,
        feature_total_value: featureTotalValue,
        feature_quantity_option: featureQuantityOption[0].name,
        feature_quantity_value: featureQuantityValue
      });
    }

    this.loyaltyScoresForm.get('feature_ids').reset();
    this.loyaltyScoresForm.get('feature_condition').reset();
    this.loyaltyScoresForm.get('feature_total_options').reset();
    this.loyaltyScoresForm.get('feature_total_value').reset();
    this.loyaltyScoresForm.get('feature_quantity_options').reset();
    this.loyaltyScoresForm.get('feature_quantity_value').reset();
  }

  deleteProductFeatureContidionLine(index) {
    if (this.featureConditionsAttributes[index]['id']) {
      this.featureConditionsAttributes[index]['_destroy'] = true;
    } else {
      this.featureConditionsAttributes.splice(index, 1);
    }
  }

}
