import { Component, DoCheck, Input, KeyValueDiffer, KeyValueDiffers, OnInit } from '@angular/core';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-limits-block',
  templateUrl: './limits-block.component.html'
})

export class LimitsBlockComponent implements OnInit, DoCheck {

  @Input('loyaltyScoresForm') loyaltyScoresForm: UntypedFormGroup;
  @Input('inputs') inputs;
  @Input('isUsed') isUsed;
  @Input('flags') flags;

  private differ: KeyValueDiffer<any, any>;

  constructor(
    private differs: KeyValueDiffers,
    private qcs: QuestionControlService
  ) { }

  ngOnInit(): void {
    this.budgetValueChanges();
    this.differ = this.differs.find(this.loyaltyScoresForm.value).create();
  }

  ngDoCheck(): void {
    const changes = this.differ.diff(this.loyaltyScoresForm.value);
    if (changes) {
      this.budgetValueChanges();
    }
  }

  budgetValueChanges(): void {
    const budgetPeriodControl = this.loyaltyScoresForm.get('budget_period');
    const budgetValueControl = this.loyaltyScoresForm.get('budget_value');

    if (budgetPeriodControl && budgetValueControl) {
      budgetPeriodControl.valueChanges.subscribe(value => {
        budgetValueControl.reset();
        if (value?.[0]?.id === 'unlimited' || !value) {
          budgetValueControl.disable();
        } else {
          budgetValueControl.enable();
        }
      });
    }
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  chancesToWinSelectorChange(event:Object) {
    const disableLimit = !event || (event && event['id'] === 'allways');
    this.inputs.find( input => input.key === 'once_limit').disabled = disableLimit;
    this.loyaltyScoresForm.get('once_limit').reset();
  }

  hasFormKey(formKey: string): boolean {
    return this.qcs.hasFormKey(this.loyaltyScoresForm, formKey);
  }

}
