import { Component, OnInit, ViewChild, OnDestroy, EventEmitter, Output, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DataTableComponent } from '../../../shared/components/data-table/data-table.component';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../../shared/models/forms/question-textbox';
import { TranslateService } from '@ngx-translate/core';
import { RefreshCacheService } from '../../../shared/services/refresh-cache.service';
import { SegmentsService } from '../segments.service';
import { ResourcesService } from '../../../shared/services/resources.service';
import { Subscription } from 'rxjs';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { QuestionControlService } from '../../../shared/services/question-control.service';
import { UntypedFormGroup } from '@angular/forms';
import { RadioQuestion } from '../../../shared/models/forms/question-radio';
import { FeaturesService } from '../../data-warehouse/products/services/products-features/features.service';
import { Location, registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { DataTableConfiguration } from '../../../shared/components/data-table/data-table-cfg';
import { FeatureFlagsService } from './../../../shared/services/feature-flags.service';
import { ProfileService } from '../../../profiles/profile.service';
import { USERS_PERSONAL_DATA_AUTH } from '../../../shared/constants/navigation-roles-not-authorized.constants';
import { getDataTableNumberPropValue, getDataTableTextPropValue } from '../../../shared/utils/common.utils';

@Component({
  selector: 'app-show-segments',
  templateUrl: './show-segments.component.html',
  styleUrls: ['./show-segments.component.scss']
})

export class ShowSegmentsComponent implements OnInit, OnDestroy {

  applyList = [{id: 'apply', apiFilter: 'apply'}];

  @ViewChild(DataTableComponent) dataTable: DataTableComponent;
  @ViewChild('customersDataTable') customersDataTable: DataTableComponent;
  @ViewChild('historyDataTable') historyDataTable: DataTableComponent;
  @ViewChild('exportSalesModalBtn') exportSalesModalBtn: ElementRef;

  @Output() segmentEmitter: EventEmitter<any> = new EventEmitter();

  element: any;
  subs$: Subscription[] = [];
  loadingReport = false;
  reportData: any;
  apiEndPoint: string;
  id: number;
  badgeFilter = {};
  historyDataTableConfig: DataTableConfiguration;
  customersTableConfig: DataTableConfiguration;
  slug = 'details';
  title = this.translate.instant('resources.customers.title');
  filters: QuestionBase<any>[];
  customer: object[];
  selectedTab: string;
  type: any;
  isSegmentCSV = false;
  isInModal = false;
  actualStatus: boolean;
  exportSalesInputs: any[];
  exportSalesForm: UntypedFormGroup;
  loading: boolean;
  currentFilters: any;
  currentRole: string;
  flags = this.featureFlags.flags;

  activeTab = {
    name: 'details',
    title: this.translate.instant('resources.segments.title')
  };

  apiEndPoints = {
    details: 'segments',
    customers: 'customers'
  };

  constructor(
    private route: ActivatedRoute,
    private refreshCacheService: RefreshCacheService,
    private location: Location,
    private translate: TranslateService,
    private router: Router,
    private qcs: QuestionControlService,
    private resourcesService: ResourcesService,
    private segmentService: SegmentsService,
    private features: FeaturesService,
    private confirmationService: ConfirmationService,
    private profileService: ProfileService,
    private featureFlags: FeatureFlagsService
  ) {
  }

  ngOnInit() {
    registerLocaleData(es);
    this.isInModal = this.router.url.indexOf('modal:show/segments/') >= 0;
    this.getParams();
    const routerEvent$ = this.router.events.subscribe(
      val => {
        if (val instanceof NavigationEnd) {
          this.setRouterURL();
          this.selectTab(this.slug);
          this.changeTitle(this.slug);
        }
      }
    );
    this.setExportSalesInputs();
    this.exportSalesForm = this.qcs.toFormGroup(this.exportSalesInputs);
    this.subs$.push(routerEvent$);
    this.currentRole = this.profileService.getStoredUserRole();
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach( s$ => s$.unsubscribe()); }
  }

  refreshUrl(tabId) {

    if ( this.selectedTab && this.selectedTab === tabId ) {
      return;
    }

    this.selectedTab = tabId;
    this.changeLocationUrl(tabId);

    if (tabId === 'details') {
      this.getReportData();
      this.badgeFilter = {};
      this.dataTable = this.historyDataTable;
      this.setHistoryTableConfig();
    } else if (tabId === 'customers') {
      this.dataTable = this.customersDataTable;
      this.setTableCfg();
      this.setFilters();
    }
  }

  selectTab(slug) {
    this.changeTitle(slug);
    this.slug = slug;
  }

  onFilterHandler(filterFormValue) {
    this.currentFilters = filterFormValue;
    this.dataTable.onFilter(filterFormValue);
  }

  onFilterClearHandler() {
    this.currentFilters = {};
  }

  refreshCache() {
    const clearCache$ = this.resourcesService.postResource([], 'segments' + '/' + this.id + '/clear_cache').subscribe(
      () => {
        this.reportData = null;
        this.getReportData();
        clearCache$.unsubscribe();
      }
    );
    this.subs$.push( clearCache$ );
  }

  removeLastTag() {
    const lastApplyDeleteTitle = this.translate.instant('resources.segments.warnings.remove_last_tag_title');
    const lastApplyDeleteText = this.translate.instant('resources.segments.warnings.remove_last_tag_text');
    this.confirmationService.displayConfirmationAlert(lastApplyDeleteTitle, lastApplyDeleteText).then(data => {
      if (data.hasOwnProperty('value') && data.value) {
        const removeLastTag$ = this.resourcesService.deleteResource([], 'segments' + '/' + this.id + '/last_apply').subscribe(
          () => {
            this.getReportData();
            removeLastTag$.unsubscribe();
            const remove_last_tag_success_title = this.translate.instant('resources.segments.warnings.remove_last_tag_success_title');
            const remove_last_tag_success = this.translate.instant('resources.segments.warnings.remove_last_tag_success');
            this.confirmationService.displaySuccessAlert(remove_last_tag_success_title, remove_last_tag_success).catch(() => {});
          },
          error => {
            this.confirmationService.displayErrorAlert('Error', JSON.stringify(error.error.error));
          }
        );
        this.subs$.push( removeLastTag$ );
      }
    }).catch(() => {});
  }

  getStatus() {
    if (this.element.applies > 0) {
      this.actualStatus = true;

      const params = {
        apiEndPoint: `segments/${this.id}/report?current=true`,
      };

      this.loadingReport = true;
      const getStatus$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
        if (!this.refreshCacheService.isRequestPending(reqResponse)) {
          this.loadingReport = false;
          this.reportData = reqResponse;
          getStatus$.unsubscribe();
        }
      });
      this.subs$.push(getStatus$);
    }
  }

  getLastView() {
    if (this.element.applies > 0) {
      this.actualStatus = false;
      this.resourcesService.getData({ apiEndPoint: 'segments' + '/' + this.id + '/report?current=false' }).subscribe( response => {
        this.reportData = response;
      });
    }
  }

  handleCustomRedirect() {
    window.open(`/#/update/segmentsV2/${this.element.id}`, '_self');
  }

  exportSales() {
    let filtering = this.currentFilters;

    const dataToExport = {
      affectation: this.exportSalesForm.value.affectation,
      features: this.exportSalesForm.value.features ? this.exportSalesForm.value.features.map(el => el.rawElement.pk) : null,
      session_from: this.exportSalesForm.value.session_from,
      session_to: this.exportSalesForm.value.session_to
    };

    filtering = this.currentFilters ? {...filtering, ...dataToExport} : dataToExport;

    this.segmentService.exportSalesToCsv(this.id,  filtering).subscribe(
      resp => {
        const exportTitle = this.translate.instant('resources.segments.modal.export_sales.warnings.export_title');
        const exportText = this.translate.instant('resources.segments.modal.export_sales.warnings.export_text');
        this.confirmationService.displaySuccessAlert(exportTitle, exportText).catch(() => {});
      },
      error => {
        this.confirmationService.displayErrorAlert('Error', error.error.error);
      }
    );
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.exportSalesForm, formKey, value);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.exportSalesInputs, inputKey);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  handleCustomCSVRedirect() {
    window.open(`/#/update/segmentsCSV/${this.element.id}`, '_self');
  }

  private getParams() {
    const routerParam$ = this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'segments';
        this.getSelectedTab();
        this.getSegmentData();
      }
    });
    this.subs$.push(routerParam$);
  }

  private getReportData() {
    this.loadingReport = true;
    const params = {
      apiEndPoint: 'segments/' + this.id + '/report?current=true',
      filtering: {}
    };

    if (this.element.applies > 0) {
      params.apiEndPoint = 'segments/' + this.id + '/report?current=false';
    }

    const report$ = this.refreshCacheService.getExpensiveData(params).subscribe(
      reqResponse => {
        if (!this.refreshCacheService.isRequestPending(reqResponse)) {
          this.loadingReport = false;
          this.reportData = reqResponse;
          report$.unsubscribe();
          if ( this.reportData.hasOwnProperty('count') && this.reportData.count === null ) {
            this.confirmationService.displayAlert(
              this.translate.instant('resources.segments.messages.report_count_null_title'),
              this.translate.instant('resources.segments.messages.report_count_null_desc'),
              'warning'
            ).catch(() => {});
          }
        }
      },
      errorData => {
        report$.unsubscribe();
        this.loadingReport = false;
        this.reportData = null;
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), JSON.stringify(errorData.error.error));
        this.closeModal();
      }
    );
    this.subs$.push(report$);
  }

  private onApplySelected(id: number) {
    this.badgeFilter['apply'] = id;
    this.refreshUrl('customers');
  }

  private getSegmentData() {
    this.segmentService.getSegmentById(this.id).subscribe(
      reqResponse => {
        this.element = reqResponse;
        this.isSegmentCSV = this.element.created_from_csv;

        if ( this.isSegmentCSV ) {
          this.segmentEmitter.emit({ customEditRedirect: [`/#/update/segmentsCSV/${this.element.id}`]});
        } else {
          this.segmentEmitter.emit({ customEditRedirect: [`/#/update/segmentsV2/${this.element.id}`]});
        }

        if (this.selectedTab === 'details') {
          this.getReportData();
          this.setHistoryTableConfig();
        } else if (this.selectedTab === 'customers') {
          this.setTableCfg();
          this.setFilters();
        }
      }
    );
  }

  private changeLocationUrl(tabId: string) {
    this.isInModal = this.router.url.indexOf('modal:show/segments/') >= 0;
    let baseUrl: string;
    let suffixUrl: string;

    if (this.isInModal) {
      baseUrl = this.router.url.substr(0, this.router.url.indexOf('(modal'));
      suffixUrl = `show/segments/${this.element.id}/${tabId}`;
      this.location.go(`${baseUrl}(modal:${suffixUrl})`);
    } else {
      suffixUrl = `/segments/segments/${this.element.id}/${tabId}`;
      this.location.go(`${suffixUrl}`);
    }
  }

  private changeTitle(slug) {
    this.activeTab.name = slug;
    this.activeTab.title = this.translate.instant(`resources.segments.tabs.${slug}`);
  }

  private getSelectedTab() {
    if (this.router.url.indexOf(`/segments/${this.id}/customers`) >= 0) {
      this.selectedTab = 'customers';
    } else if ( this.router.url.indexOf(`/segments/${this.id}/details`) >= 0 ||
                this.router.url.indexOf(`/segments/${this.id}`) >= 0 ) {
      this.selectedTab = 'details';
      this.badgeFilter = {};
    }
  }

  private exportToExcel() {
    let filtering = this.currentFilters;
    const data = this.element.id;

    if (this.currentFilters) { filtering = {...filtering, ...data }; }

    const toExcel$ = this.segmentService.customersToExcel(this.element.id, filtering).subscribe(
      exportData => {
        toExcel$.unsubscribe();
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.segments.messages.export_enqueued_title'),
          this.translate.instant('resources.segments.messages.export_enqueued_desc'),
        ).catch(() => {});
      },
      errorData => {
        this.confirmationService.displayErrorAlert( 'Error', errorData.error.error );
      }
    );
  }

  private affectationOptions() {
    return [
      { id: 'activity', name: this.translate.instant('resources.segments.modal.export_sales.fields.activity')},
      { id: 'feature', name: this.translate.instant('resources.segments.modal.export_sales.fields.feature')}
    ];
  }

  private setRouterURL() {
    this.apiEndPoint = this.apiEndPoints[this.slug];
  }

  private openExportSales() {
    this.exportSalesModalBtn.nativeElement.click();
  }

  private setTableCfg() {
    let filtering = { id: this.element.id };
    if ( this.badgeFilter ) { filtering = Object.assign(this.badgeFilter, filtering); }

    this.customersTableConfig = {
      requiresCacheService: true,
      isActive: true,
      tableActions: [
        {
          name: this.translate.instant( 'components.table_actions.export_selected' ),
          icon: 'ei-export',
          id: 'exportCSV',
          disabled: () => !this.showPersonalData()
        },
        {
          name: this.translate.instant( 'components.table_actions.export_customers' ),
          icon: 'ei-users-1',
          id: 'export_customers',
          onClick: () => this.exportToExcel(),
          disabled: () => !this.showPersonalData()
        },
        {
          name: this.translate.instant('resources.segments.table_actions.export_sales'),
          icon: 'ei-export',
          id: 'exportSales',
          onClick: () => this.openExportSales(),
          disabled: () => !this.showPersonalData()
        }
      ],
      rowActions: [
        { name: this.translate.instant('resources.segments.row_actions.details'),
          icon: 'eye',
          id: 'details',
          callback: (element: {id: number}) => {
            window.open(`#/data-warehouse/profiles/${element.id}/summary`);
          }
        }
      ],
      columns: [
        { name: this.translate.instant('resources.customers.columns.id'), prop: 'id', sortByField: 'id' },
        { name: this.translate.instant('resources.customers.columns.name'), prop: 'name', sortByField: 'name' },
        { name: this.translate.instant('resources.customers.columns.email'), prop: 'email', sortByField: 'email' },
        { name: this.translate.instant('resources.customers.columns.phone'), prop: 'phone', sortByField: 'phone' },
        { name: this.translate.instant('resources.customers.columns.dni'), prop: 'dni', sortByField: 'dni' },
        { name: this.translate.instant('resources.customers.columns.score'), prop: 'score', sortByField: 'score' },
        { name: this.translate.instant('resources.customers.columns.created_at'), prop: 'created_at', sortByField: 'created_at' },
      ],
      requestData: {
        translationResource: 'resources.profiles.columns.',
        apiEndPoint: `segments/${this.element.id}/customers`,
        pageNumber: 0,
        sorting: {},
        filtering: filtering,
        numberPerPage: 10
      },
      rows: [],
      tableMapping: [
        { prop: 'id', type: 'text', apiProp: 'id' },
        { prop: 'name', type: 'text', apiProp: 'name', getValue: getDataTableTextPropValue('name') },
        { prop: 'email', type: 'text', apiProp: 'email', getValue: getDataTableTextPropValue('email') },
        { prop: 'phone', type: 'number', apiProp: 'phone', getValue: getDataTableNumberPropValue('phone') },
        { prop: 'dni', type: 'text', apiProp: 'dni', getValue: getDataTableTextPropValue('dni') },
        { prop: 'score', type: 'number', apiProp: 'score_available', getValue: getDataTableNumberPropValue('score_available') },
        { prop: 'created_at', type: 'date', apiProp: 'created_at' },
      ]
    };
  }

  private setHistoryTableConfig() {
    let filtering = { id: this.element.id };

    if (this.badgeFilter) {
      filtering = Object.assign(this.badgeFilter, filtering);
    }

    this.historyDataTableConfig = {
      isMock: false,
      isActive: true,
      tableActions: [
        { name: this.translate.instant('components.table_actions.export_csv'), icon: 'ei-export', id: 'exportCSV' }
      ],
      rowActions: [
        {
          name: this.translate.instant('resources.segments.row_actions.details'),
          icon: 'eye',
          id: 'customers',
          callback: (element: { apply: number }) => this.onApplySelected(element.apply)
        }
      ],
      columns: [
        { name: '' },
        { name: this.translate.instant('resources.segments.show.columns.apply'), prop: 'apply'},
        { name: this.translate.instant('resources.segments.show.columns.created_at'), prop: 'created_at' },
        { name: this.translate.instant('resources.segments.show.columns.customers'), prop: 'customers' },
        { name: this.translate.instant('resources.segments.show.columns.difference'), prop: 'difference' }
      ],
      requestData: {
        apiEndPoint: `tag_histories/?segment_id=${this.element.id}&segment_id=${this.element.id}`,
        pageNumber: 0,
        sorting: {},
        filtering: filtering,
        numberPerPage: 10
      },
      rows: [],
      tableMapping: [
        { prop: 'apply', type: 'number', apiProp: 'apply' },
        { prop: 'created_at', type: 'date', apiProp: 'created_at' },
        { prop: 'customers', type: 'text', apiProp: 'customers' },
        { prop: 'difference', type: 'text', apiProp: 'difference' }
      ]
    };
  }

  private setExportSalesInputs() {
    this.exportSalesInputs = [
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'session_from',
        label: 'resources.segments.modal.export_sales.fields.session_from',
        type: 'date'
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'session_to',
        label: 'resources.segments.modal.export_sales.fields.session_to',
        type: 'date'
      }),
      new RadioQuestion({
        key: 'affectation',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.affectationOptions(),
        value: 'activity',
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'features',
        dataSource: this.features,
        settings: { singleSelection: false, enableCheckAll: false, showCheckbox: true, enableSearchFilter: true },
      })
    ];
  }

  private setFilters() {
    this.filters = [
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'customer_id',
        label: 'resources.customers.filters.id',
        type: 'number',
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'name',
        label: 'resources.customers.filters.name',
        type: 'text',
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'surname_1',
        label: 'resources.customers.filters.surname_1',
        type: 'text',
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'phone',
        label: 'resources.customers.filters.phone',
        type: 'text',
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'email',
        label: 'resources.customers.filters.email',
        type: 'text',
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'dni',
        label: 'resources.customers.filters.dni',
        type: 'text',
      })
    ];
  }

  private showPersonalData() {
    return this.flags.showCustomerPersonalData || USERS_PERSONAL_DATA_AUTH.includes(this.currentRole);
  }
}
