<div class="modal-backdrop fade in" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="mrg-left-15 mrg-btm-5" [translate]="'resources.customers.show.action_modals.new_card'"></h4>
        <button [attr.data-cy]="'button_closeNewCard'" class="modal-close" (click)="closeModal()">
          <i class="ti-close"></i>
        </button>
      </div>

      <div class="modal-body background-gray">
        <div class="row">
          <div class="col-md-12">

            <div class="container-fluid">
              <div class="row">
                <div class="mrg-top-10 col-md-12">

                  <div class="card-block no-pdd">

                    <div class="row">
                      <div class="col-md-8 ml-auto mr-auto">
                        <form [formGroup]="newCardForm" *ngIf="newCardForm">

                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="new_customer_id_code"
                                  [translate]="newCustomerInputLabelKey()"></label>
                                <app-question [question]="newCustomerInputConfig" [form]="newCardForm">
                                </app-question>
                              </div>
                            </div>
                          </div>

                        </form>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

      <div class="modal-footer background-white">
        <div class="pull-right">
          <button [attr.data-cy]="'button_customerNewCardConfirm'"
            class="pull-right btn btn-info"
            (click)="sendData()"
            [disabled]="!newCardForm.value.new_customer_id_code"
          >
            <span *ngIf="loading">
              <em class="fa fa-spinner fa-spin"></em>
            </span>
            {{ 'resources.customers.show.actions.new_card' | translate }}
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
