import { Modules } from "../../shared/enums/modules.enums";
import { NavItem } from "./nav-item";

/*********************************************/
/*               SIDENAV ITEMS               */
/*********************************************/

//#region <NavItems>
const LOYALTY_ANALYTICS = new NavItem({
  id: Modules.LoyaltyAnalytics,
  title: 'analytics.' + Modules.LoyaltyAnalytics,
  children: [
    {
      id: 'program-performance',
      children: [
        { id: 'club', title: 'club_indicators' },
        { id: 'customers' },
        { id: 'channels' },
        { id: 'contact-totals', title: 'contact_numbers' }
      ]
    },
    { id: 'points-rewards', title: 'points-rewards.title' },
    // { id: 'program-ledgers' } Work in progress
  ]
});
const RETAIL_ANALYTICS = new NavItem({
    id: Modules.RetailAnalytics,
    title: 'analytics.' + Modules.RetailAnalytics,
    children: [
      { id: 'summary' },
      { id: 'sales' },
      { id: 'locations' },
      { id: 'categories' },
      { id: 'system-categories' },
      { id: 'products' },
      { id: 'custom' }
    ]
});
const SUPPLIER_ANALYTICS = new NavItem({
  id: Modules.SupplierAnalytics,
  title: 'analytics.' + Modules.SupplierAnalytics,
  children: [
      { id: 'sales' },
      { id: 'locations' },
      { id: 'categories' },
      { id: 'products' },
      { id: 'customers' }
  ]
});
const RETAIL_GROUP_ANALYTICS = new NavItem({
  id: Modules.RetailGroupAnalytics,
  title: 'analytics.retail_group',
  children: [
    { id: 'dashboards' }
  ]
});
const BASIC_ANALYTICS = new NavItem({
  id: Modules.BasicAnalytics,
  title: 'analytics.' + Modules.BasicAnalytics,
  children: [
    { id: 'sales' },
    { id: 'customers' }
  ]
});
const LOYALTY = new NavItem({
  id: Modules.Loyalty,
  title: 'loyalty_management',
  children: [
      { id: 'plan-type-configuration' },
      { id: 'recipes' },
      { id: 'rules-engine' },
      { id: 'triggers' },
      { id: 'tiers-management' },
      {
        id: 'rewards',
        children: [
          { id: 'list' },
          { id: 'redemptions' }
        ]
      },
      { id: 'partners' },
      { id: 'cashback-exchange' },
      { id: 'audiences'},
      { id: 'challenges'},
      { id: 'massive-actions'},
      { id: 'referral-programs'},
      { id: 'api-requests'},
  ]
});
const OFFERS_PERSONALIZATION = new NavItem({
    id: Modules.OffersPersonalization,
    children: [
      { id: 'dashboard'},
      { id: 'campaigns'},
      { id: 'plans' },
      { id: 'coupons' },
      { id: 'redemptions' },
      { id: 'audiences'},
      { id: 'special-actions' },
      { id: 'customer-journeys' },
      { id: 'api-calls' }
    ]
});
const OMNICHANNEL = new NavItem({
    id: Modules.Omnichannel,
    children: [
      { id: 'campaigns' },
      {
        id: 'templates',
        children: [
          { id: 'email', title: 'mail_templates' },
          { id: 'sms', title: 'sms_templates' },
          { id: 'push', title: 'push_templates' },
        ]
      },
      { id: 'ticket-messages', title: 'campaign_messages' },
      { id: 'landings', },
      { id: 'cms', route: '/omnichannel-manager/cms' }
    ],
});
const AUDIENCES = new NavItem({
    id: Modules.Audiences,
    children: [
      { id: 'segments', title: 'builder' },
      { id: 'scheduled-tasks', title: 'scheduler' },
      { id: 'customers' }
    ]
});
const CUSTOMER_DATA_PLATFORM = new NavItem({
    id: Modules.CustomerDataPlatform,
    children: [
      {
        id: 'dashboards',
        children: [
          { id: 'data-quality' }
        ]
      },
      {
        id: 'warehouse',
        title: 'warehouse',
        children: [
          { id: 'customers' },
          { id: 'purchases' },
          { id: 'products' },
          { id: 'locations' },
          { id: 'coupons' },
          { id: 'promotions' }
        ]
      },
      {
        id: 'collect',
        title: 'collect',
        children: [
          { id: 'api-management' },
          { id: 'files-management' }
        ]
      },
      { id: 'custom-entities' },
      { id: 'customer-attributes' },
      { id: 'connectors' }
    ]
});

const GEN_AI_ANALYTICS = new NavItem({
  id: Modules.LoyalGPT,
  children: [
    { id: 'chatbot' },
    { id: 'prompts' },
    // { id: 'guide' }
  ]
});

const CONFIGURATION = new NavItem({
    id: Modules.Configuration,
    groupSeparator: true,
    children: [
      { id: 'loyalty' },
      { id: 'offers' },
      { id: 'omnichannel' },
      { id: 'scheduler' },
      { id: 'consent-management' },
      { id: 'users' },
      { id: 'company' },
      { id: 'system' },
      { id: 'payments' }
    ]
});


export const SIDENAV: NavItem[] = [
    LOYALTY_ANALYTICS,
    RETAIL_ANALYTICS,
    SUPPLIER_ANALYTICS,
    RETAIL_GROUP_ANALYTICS,
    BASIC_ANALYTICS,
    LOYALTY,
    OFFERS_PERSONALIZATION,
    OMNICHANNEL,
    AUDIENCES,
    CUSTOMER_DATA_PLATFORM,
    GEN_AI_ANALYTICS,
    CONFIGURATION
];
