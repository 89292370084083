<ng-container *ngIf="inputs">
  <div class="row">
    <div class="col-md-12">
      <div class="alert alert-warning d-flex align-items-center">
        <em class="fa-regular fa-triangle-exclamation mrg-right-10 font-size-20"></em>
        <span class="font-size-14" [innerHTML]="'resources.segment_conditions.messages.margin_condition_alert' | translate"></span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-lg-12 col-xl-4 pdd-top-25">
      <app-question [question]="inputs[0]" [form]="form"/>
    </div>

    <div class="col-md-6 col-lg-6 col-xl-3">
      <app-question [class.invalid-selector]="form.get('operator')?.errors?.required" [question]="getInputConfig('operator')" [form]="form"/>
    </div>

    <div class="col-md-6 col-lg-6 col-xl-5" *ngIf="scopeValue === 'feature'">
      <app-question [class.invalid-selector]="form.get('feature_ids')?.errors?.required" [question]="getInputConfig('feature_ids')" [form]="form"/>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 col-lg-6 col-xl-3">
      <app-question [question]="getInputConfig('date_from')" [form]="form"/>
    </div>

    <div class="col-md-6 col-lg-6 col-xl-3">
      <app-question [question]="getInputConfig('date_to')" [form]="form"/>
    </div>

    <div class="col-md-6 col-lg-6 col-xl-3">
      <app-question [question]="getInputConfig('location_taxonomy_term_ids')"
                    [form]="form"
                    (multiselectChanged)="handleSelectedLocationTaxonomyTermIds($event)">
      </app-question>
    </div>

    <div class="col-md-6 col-lg-6 col-xl-3">
      <app-question [question]="getInputConfig('location_ids')" [form]="form"/>
    </div>
  </div>

  <div class="row mrg-btm-10">
    <div class="col-md-6 col-lg-6 col-xl-3">
      <app-question [class.invalid-selector]="form.get('margin_amount_operator')?.errors?.required"
                    [question]="getInputConfig('margin_amount_operator')"
                    [form]="form"
                    (multiselectChanged)="handleMarginAmountOperatorChanges($event)"/>
    </div>

    <div class="col-md-6 col-lg-6 col-xl-2" *ngIf="!hasFormKeyWithValue('margin_amount_operator', 'bt')">
      <app-question [question]="getInputConfig('margin_amount_value')" [form]="form"/>
    </div>

    <ng-container *ngIf="hasFormKeyWithValue('margin_amount_operator', 'bt')">
      <div class="col-md-3 col-lg-3 col-xl-1">
        <app-question [question]="getInputConfig('margin_amount_value_1')" [form]="form"/>
      </div>
      <div class="col-md-3 col-lg-3 col-xl-1">
        <app-question [question]="getInputConfig('margin_amount_value_2')" [form]="form"/>
      </div>
    </ng-container>
  </div>
</ng-container>
