<div class="modal fade modal-fs show" id="modal-fs" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header background-white">
        <div class="row flex-grow-1">
          <div [ngClass]="flags.showPointsPolicyPreview ? 'col-md-10' : 'col-md-8'" class="ml-auto mr-auto">
            <h1 *ngIf="!id" [translate]="'resources.scores.loyalty_scores.form.title'"></h1>
            <h1 *ngIf="id" [translate]="'resources.scores.loyalty_scores.form.edit_title'"></h1>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div *ngIf="loyaltyScoresForm && loyaltyScoresForm.errors">
              <div class="alert alert-danger alert-dismissible fade show mrg-top-5"
                *ngFor="let error of getErrors()" role="alert">
                <b>{{error.field}}:</b> {{error.message}}.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                  (click)="removeError(error)">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="pdd-vertical-20-but-bottom">
          <button class="modal-close" (click)="returnToList()">
            <i class="ti-close"></i>
          </button>
        </div>
      </div>

      <div class="modal-body back-color">
        <div class="card-block no-pdd">
          <div class="row">
            <div [ngClass]="{'mr-auto': !flags.showPointsPolicyPreview}" class="col-md-8 ml-auto">
              <form [formGroup]="loyaltyScoresForm" *ngIf="loyaltyScoresForm">

                <!-- DETAILS -->
                 <app-rules-details-block [inputs]="inputs"
                                          [loyaltyScoresForm]="loyaltyScoresForm"
                                          [mode]="mode"
                                          [filteredAvailableLanguages]="filteredAvailableLanguages"
                                          [flags]="flags"/>
                <!--  -->

                <!-- AWARD POINTS/REWARD -->
                <app-award-value-block [inputs]="inputs"
                                       [loyaltyScoresForm]="loyaltyScoresForm"
                                       [isUsed]="isUsed"
                                       [mode]="mode"
                                       [loyaltyScoresData]="loyaltyScoresData"
                                       (hideValidityPointsBlockChange)="onHideValidityPointsBlockChange($event)"
                                       (customEntitySelected)="manageCustomEntityFields($event)"/>
                <!--  -->

                <!-- VICTORY CONDITIONS -->
                <div class="card">
                  <div class="card-heading border bottom">
                    <h4 class="text-bold card-title" [translate]="'resources.scores.loyalty_scores.fields.victory'"></h4>
                  </div>
                  <div class="card-block">
                    <div class="alert alert-info" role="alert">
                      <span class="alert-text">{{'resources.scores.loyalty_scores.descriptions.victory_conditions' | translate}}</span>
                    </div>

                    <!-- CUSTOM ENTITY DEFINITION FORM -->
                    <ng-container *ngIf="customEntity" #customEntityForm>
                      <h5 class="text-bold card-title">{{'resources.scores.loyalty_scores.fields.custom_entity_title' | translate}}</h5>
                      <app-form-loyalty-scores-custom-entity [entity]="customEntity"
                                                             [definedEntityAttrs]="loyaltyScoresData ? loyaltyScoresData.customEntityAttributes : []"/>
                    </ng-container>
                    <!-- END - CUSTOM ENTITY DEFINITION FORM -->

                    <!-- CHALLENGES -->
                    <div class="row" *ngIf="hasFormKeyWithValue('loyalty_score_moment', 'after_winning_challenge')">
                      <div class="col-md-12">
                        <div class="form-group">
                          <h5 class="text-bold card-title">{{'resources.scores.loyalty_scores.fields.challenges' | translate}}</h5>
                          <label class="text-bold" for="achievement_definition_challenge_ids">
                            {{'resources.scores.loyalty_scores.fields.achievement_definition_challenge_ids_input' | translate}}
                            <span class="text-danger">&nbsp;*</span>
                          </label>
                          <app-question [question]="getInputConfig('achievement_definition_challenge_ids')" [form]="loyaltyScoresForm"/>
                        </div>
                      </div>
                    </div>
                    <!--  -->

                    <!-- COUNTRIES -->
                    <h5 class="text-bold card-title">{{'resources.scores.loyalty_scores.fields.country_title' | translate}}</h5>
                    <!-- WARNING MESSAGE -->
                    <div class="alert alert-danger" role="alert" *ngIf="hasFormKeyWithValue('loyalty_score_moment', 'after_winning_challenge')">
                      <span class="alert-text">{{'resources.scores.loyalty_scores.warnings.registration_countries' | translate}}</span>
                    </div>
                    <!--  -->
                    <div class="row">
                      <div class="col-md-6" [hidden]="hasFormKeyWithValue('loyalty_score_moment', 'after_winning_challenge') || isCustomEntity">
                        <div class="form-group">
                          <label class="text-bold" for="countries">
                            {{'resources.scores.loyalty_scores.fields.countries' | translate}}
                            <span class="text-danger">*</span>
                            <em class="ti-info-alt pull-right mrg-top-3 mrg-left-5"
                                tooltipPosition="top"
                                pTooltip="{{'resources.scores.loyalty_scores.form.tooltip.countries_tooltip' | translate}}">
                            </em>
                          </label>
                          <app-question [question]="getInputConfig('countries')"
                                        [form]="loyaltyScoresForm"
                                        (multiselectChanged)="countrySelector()"/>
                        </div>
                      </div>

                      <div class="col-md-6" *ngIf="hasFormKeyWithValue('loyalty_score_moment', 'after_create_activity') ||
                                                   hasFormKeyWithValue('loyalty_score_moment', 'after_winning_challenge') ||
                                                   isCustomEntity">
                        <div class="form-group">
                          <label class="text-bold" for="customer_registration_countries">
                            {{'resources.scores.loyalty_scores.fields.customer_registration_countries' | translate}}
                            <em class="ti-info-alt pull-right mrg-top-3 mrg-left-5"
                                tooltipPosition="top"
                                pTooltip="{{'resources.scores.loyalty_scores.form.tooltip.customer_registration_tooltip' | translate}}">
                            </em>
                          </label>
                          <app-question [question]="getInputConfig('customer_registration_countries')" [form]="loyaltyScoresForm"/>
                        </div>
                      </div>
                    </div>

                    <!-- TIERS -->
                    <div class="row" *ngIf="hasFormKeyWithValue('loyalty_score_moment', 'after_create_activity') ||
                                            hasFormKeyWithValue('loyalty_score_moment', 'after_winning_challenge')">
                      <div class="col-md-12">
                        <div class="form-group">
                          <h5 class="text-bold card-title">{{'resources.scores.loyalty_scores.fields.tiers' | translate}}</h5>
                          <label class="text-bold" for="achievement_definition_ids">
                            {{'resources.scores.loyalty_scores.fields.achievement_definition_ids_input' | translate}}
                          </label>
                          <app-question [question]="getInputConfig('achievement_definition_ids')" [form]="loyaltyScoresForm"/>
                        </div>
                      </div>
                    </div>
                    <!--  -->

                    <!-- LOCATIONS -->
                    <div *ngIf="!hasFormKeyWithValue('loyalty_score_moment', 'birthday') &&
                                !hasFormKeyWithValue('loyalty_score_moment', 'after_winning_challenge') &&
                                !isCustomEntity">
                      <h5 class="text-bold card-title">{{'resources.scores.loyalty_scores.fields.locations_title' | translate}}</h5>

                      <div class="row">
                        <div class="col-md-6">
                          <label for="locations" class="text-bold" [translate]="'resources.scores.loyalty_scores.fields.locations'"></label>
                          <div class="form-group">
                            <app-question [question]="getInputConfig('locationsOptions')"
                                          [form]="loyaltyScoresForm"
                                          (multiselectChanged)="resetOptionsValues('location_ids')"/>
                          </div>
                        </div>
                        <div class="col-md-6 top-28" *ngIf="hasFormKeyWithValue('locationsOptions', 'include') || hasFormKeyWithValue('locationsOptions', 'exclude')">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('location_ids')"
                                          [form]="loyaltyScoresForm"
                                          (blur)="handleLocationChange($event)"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--  -->

                    <!-- LOCATIONS FEATURES -->
                    <ng-container *ngIf="!hasFormKeyWithValue('loyalty_score_moment', 'birthday') && !isCustomEntity && !hasFormKeyWithValue('loyalty_score_moment', 'after_winning_challenge')">
                      <div class="row">
                        <label for="feature_location_ids" class="mrg-left-15 text-bold" [translate]="'resources.scores.loyalty_scores.fields.location_features'"></label>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('locationFeaturesOptions')"
                                          [form]="loyaltyScoresForm"
                                          (multiselectChanged)="resetOptionsValues('feature_location_ids')"/>
                          </div>
                        </div>
                        <div class="col-md-6" *ngIf="hasFormKeyWithValue('locationFeaturesOptions', 'include') ||
                                                     hasFormKeyWithValue('locationFeaturesOptions', 'include_all') ||
                                                     hasFormKeyWithValue('locationFeaturesOptions', 'exclude')">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('feature_location_ids')"
                                          [form]="loyaltyScoresForm"/>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <!--  -->

                    <!-- PRODUCTS -->
                    <div *ngIf="hasFormKeyWithValue('loyalty_score_moment', 'after_create_activity')">
                      <h5 class="text-bold card-title">{{'resources.scores.loyalty_scores.fields.products_title' | translate}}</h5>

                      <div class="row">
                        <div class="col-md-6">
                          <label for="features" class="text-bold" [translate]="'resources.scores.loyalty_scores.fields.products'"></label>
                          <div class="form-group">
                            <app-question [question]="getInputConfig('productsOptions')"
                                          [form]="loyaltyScoresForm"
                                          (multiselectChanged)="resetOptionsValues('product_ids')"/>
                          </div>
                        </div>

                        <!-- SEARCH BY FIELD -->
                        <div class="col-md-6" *ngIf="hasFormKeyWithValue('productsOptions', 'include') || hasFormKeyWithValue('productsOptions', 'exclude')">
                          <label for="search_by" class="text-bold"
                                  [translate]="'resources.scores.loyalty_scores.fields.search_by'">
                          </label>
                          <div class="form-group">
                            <app-question [question]="getInputConfig('search_by')"
                                          [form]="loyaltyScoresForm"
                                          (multiselectChanged)="searchByResource($event)"/>
                          </div>
                        </div>
                        <!--  -->

                        <div class="col-md-12" *ngIf="hasFormKeyWithValue('productsOptions', 'include') ||
                                                      hasFormKeyWithValue('productsOptions', 'exclude')">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('product_ids')"
                                          [form]="loyaltyScoresForm"
                                          [useToken]="true"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--  -->

                    <!-- PRODUCTS FEATURES -->
                    <div *ngIf="hasFormKeyWithValue('loyalty_score_moment', 'after_create_activity')">
                      <div class="row">
                        <label for="features" class="text-bold mrg-left-15"
                               [translate]="'resources.scores.loyalty_scores.fields.product_features'">
                        </label>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('productsFeaturesOptions')"
                                          [form]="loyaltyScoresForm"
                                          (multiselectChanged)="resetOptionsValues('feature_ids')"/>
                          </div>
                        </div>
                        <div class="col-md-6"
                          *ngIf="hasFormKeyWithValue('productsFeaturesOptions', 'include') || hasFormKeyWithValue('productsFeaturesOptions', 'include_all') || hasFormKeyWithValue('productsFeaturesOptions', 'exclude') ">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('feature_ids')" [form]="loyaltyScoresForm"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--  -->

                    <!-- PAYMENTS RESTRICTIONS -->
                    <div *ngIf="hasFormKeyWithValue('loyalty_score_moment', 'after_create_activity')">
                      <h5 class="text-bold" [translate]="'resources.scores.loyalty_scores.fields.payment_title'"></h5>
                      <div class="row">
                        <label for="payment_method_ids" class="text-bold mrg-left-15"
                                   [translate]="'resources.scores.loyalty_scores.fields.payment_options'">
                        </label>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('paymentsOptions')"
                                          [form]="loyaltyScoresForm"
                                          (multiselectChanged)="resetOptionsValues('payment_method_ids')"/>
                          </div>
                        </div>
                        <div class="col-md-6" *ngIf="hasFormKeyWithValue('paymentsOptions', 'include') ||
                                                     hasFormKeyWithValue('paymentsOptions', 'include_all') ||
                                                     hasFormKeyWithValue('paymentsOptions', 'exclude')">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('payment_method_ids')" [form]="loyaltyScoresForm"/>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="row" *ngIf="flags?.showDemoWallmart">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="payment_method_ids" class="text-bold">
                              <span>{{'resources.scores.loyalty_scores.fields.include_taxes_title' | translate}}</span>
                              <em class="ti-info-alt pull-right mrg-top-3 mrg-left-5"
                                  tooltipPosition="top"
                                  pTooltip="{{'resources.scores.loyalty_scores.form.tooltip.include_taxes_tooltip' | translate}}">
                              </em>
                            </label>
                            <app-question [question]="getInputConfig('include_taxes')" [form]="loyaltyScoresForm"/>
                          </div>
                        </div>
                      </div> -->

                    </div>
                    <!--  -->

                    <!-- LINE PROPERTIES -->
                    <div *ngIf="hasFormKeyWithValue('loyalty_score_moment', 'after_create_activity')">
                      <h5 class="text-bold card-title">{{'resources.scores.loyalty_scores.fields.custom_properties' | translate}}</h5>
                      <div class="row">
                        <label for="lineConditions" class="text-bold mrg-left-15"
                               [translate]="'resources.scores.loyalty_scores.fields.line_conditions'">
                        </label>
                      </div>
                      <table class="table no-mrg-bottom" aria-hidden="true">
                        <tr *ngFor="let item of motivator_line_attributes; let i = index" [attr.hidden]="item._destroy">
                          <td class="col-xs-2">{{item.field_name}}</td>
                          <td class="col-xs-1 text-center">{{item.condition_name}}</td>
                          <td class="col-xs-8 text-right">
                            <span class="label">{{item.value}}</span>
                          </td>
                          <td class="col-xs-1 text-right">
                            <em class="fa fa-times pointer" (click)="deleteMotivatorLine(i)">
                            </em>
                          </td>
                        </tr>
                      </table>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('lineConditions')"
                                          (multiselectChanged)="customerLineConditionChange()"
                                          [form]="loyaltyScoresForm"/>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('lineOptions')" (multiselectChanged)="lineOptionsChange($event)" [form]="loyaltyScoresForm"/>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('lineInput')" [disabled]="lineInputDisabled" [form]="loyaltyScoresForm"/>
                          </div>
                        </div>
                        <div class="col-md-1">
                          <button class="btn btn-success btn-icon no-margin-right pull-right"
                            (click)="addMotivatorLine()"><i class="fa fa-plus"></i></button>
                        </div>
                      </div>
                      <!-- ################################################################## -->
                      <!-- ############ DEMO LOYALTY FEATURES - PRODUCT CONDITION ########### -->
                      <!-- ################################################################## -->
                       <!-- <ng-container *ngIf="flags.showDemoWallmart">
                         <app-product-condition-attributes [inputs]="inputs"
                                                           [loyaltyScoresForm]="loyaltyScoresForm"
                                                           [flags]="flags"/>

                         <app-feature-condition-attributes [inputs]="inputs"
                                                           [loyaltyScoresForm]="loyaltyScoresForm"
                                                           [flags]="flags"/>
                       </ng-container> -->
                    </div>
                    <!-- #################################################################### -->
                    <!-- #################### END DEMO WALLMART - PRODUCT CONDITION ######### -->
                    <!-- #################################################################### -->

                    <!-- CUSTOMER CONDITIONS TO WIN -->
                    <div *ngIf="!hasFormKeyWithValue('loyalty_score_moment', 'manual')
                                && !hasFormKeyWithValue('loyalty_score_moment', 'birthday')
                                && !hasFormKeyWithValue('loyalty_score_moment', 'after_winning_challenge')
                                && !isCustomEntity">
                    <h5 class="text-bold card-title"></h5>
                      <div class="row">
                        <label for="customerConditions" class=" text-bold mrg-left-15"
                               [translate]="'resources.scores.loyalty_scores.fields.customer_conditions'">
                        </label>
                      </div>
                      <table class="table no-mrg-bottom" aria-hidden="true">
                        <tr *ngFor="let item of customer_attributes; let i = index" [attr.hidden]="item._destroy">
                          <td class="col-xs-2">{{item.field_name}}</td>
                          <td class="col-xs-1 text-center">
                            {{ 'resources.scores.loyalty_scores.conditions.' + item.condition | translate }}
                          </td>
                          <td class="col-xs-8 text-right">
                            <span class="label">{{item.value_content}}</span>
                          </td>
                          <td class="col-xs-1 text-right">
                            <em class="fa fa-times pointer" (click)="deleteCustomerAttribute(i)">
                            </em>
                          </td>
                        </tr>
                      </table>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('customerConditions')"
                                          (multiselectChanged)="customerConditionChange($event)"
                                          [form]="loyaltyScoresForm"/>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('customerOptions')" [form]="loyaltyScoresForm"/>
                          </div>
                        </div>
                        <div class="col-md-4" *ngIf="items && items['multiple']">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('customerSelectMulti')" [form]="loyaltyScoresForm"/>
                          </div>
                        </div>
                        <div class="col-md-4" *ngIf="items && !items['multiple']">
                          <div class="form-group">
                            <app-question [question]="getInputConfig('customerInput')" [form]="loyaltyScoresForm"/>
                          </div>
                        </div>
                        <div class="col-md-4" *ngIf="!items">
                        </div>
                        <div class="col-md-1">
                          <button class="btn btn-success btn-icon no-margin-right pull-right"
                            (click)="addCustomerAttributeLine()">
                            <i class="fa fa-plus"></i>
                          </button>
                        </div>
                      </div>

                      <ng-container *ngIf="flags.showDemoWallmart">
                        <app-customer-purchase-condition [inputs]="inputs"
                                                         [loyaltyScoresForm]="loyaltyScoresForm"
                                                         [flags]="flags"/>
                      </ng-container>

                      <app-total-amount-conditions [inputs]="inputs" [loyaltyScoresForm]="loyaltyScoresForm"/>
                    </div>

                  </div>
                </div>
                <!-- END VICTORY CONDITIONS -->

                <!-- DEMO WALLMART -->
                <!-- CUSTOMER SEGMENTATION HIDDEN -->
                 <!-- <ng-container *ngIf="flags.showDemoWallmart">
                    <app-customer-segmentation-block [inputs]="inputs"
                                                     [loyaltyScoresForm]="loyaltyScoresForm"
                                                     [flags]="flags"/>
                 </ng-container> -->
                <!--  -->

                <!-- LIMITS -->
                 <app-limits-block [inputs]="inputs"
                                   [loyaltyScoresForm]="loyaltyScoresForm"
                                   [isUsed]="isUsed"
                                   [flags]="flags"/>
                <!-- END LIMITS -->

                <!-- VALIDITY OF POINTS AWARDED -->
                 <app-validity-points-block *ngIf="shouldHideValidityPointsBlock"
                                            [inputs]="inputs"
                                            [loyaltyScoresForm]="loyaltyScoresForm"
                                            [isUsed]="isUsed"
                                            [flags]="flags"/>
                <!--  -->

                <!-- VALIDITY OF LOYALTY RULE -->
                 <app-validity-loyalty-rule-block [inputs]="inputs" [loyaltyScoresForm]="loyaltyScoresForm"/>
                <!--  -->

                <!-- Partners -->
                 <app-partners-block [inputs]="inputs" [loyaltyScoresForm]="loyaltyScoresForm"/>
                 <!--  -->
              </form>
            </div>
            <div *ngIf="flags.showPointsPolicyPreview" class="col-md-2 mr-auto">
              <div class="card sticky-div">
                <div class="card-heading border bottom">
                  <h4 class="text-bold card-title">
                    {{'common.preview' | translate}}
                  </h4>
                </div>
                <app-resource-preview
                  *ngIf="loyaltyScoresForm"
                  [formValues]="loyaltyScoresForm.value"
                  [type]="'points_policy'"
                  [availabilityType]="'range'"></app-resource-preview>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- FOOTER -->
      <div class="row modal-footer background-white">
        <div [ngClass]="flags.showPointsPolicyPreview ? 'col-md-10' : 'col-md-8'" class="ml-auto mr-auto text-right">
          <span>
            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          </span>
          <button *ngIf="!flags.showDemoWallmart" class="btn btn-info no-mrg-right"
                  (click)="save()"
                  [translate]="'components.create-update.fields.save'"
                  [disabled]="loading">
          </button>
          <!-- DEMO BUTTONS -->
          <ng-container *ngIf="flags.showDemoWallmart">

            <button class="btn btn-info no-mrg-right"
                    [translate]="'common.buttons.submit_for_approval'"
                    [disabled]="loading"
                    (click)="save()">
            </button>
            <button *ngIf="roleSlug === 'admin' || roleSlug === 'owner'" class="btn btn-info no-mrg-right"
                    [translate]="'common.buttons.approve_and_save'"
                    [disabled]="loading"
                    (click)="save()">
            </button>

          </ng-container>
          <!--  -->
        </div>
      </div>
      <!--  -->

    </div>
  </div>
</div>
