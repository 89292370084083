import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourcesService } from '../../../../shared/services/resources.service';

@Injectable({
  providedIn: 'root'
})

export class MassiveActionsService extends ResourcesService {

  constructor(http: HttpClient) {
    super(http);
  }

  giveScore(loyaltyScoreId: number, segmentId: number, comment: string) {
    return this.postResource({loyalty_score_id: loyaltyScoreId, segment_id: segmentId, comment: comment}, `massive_actions/give_score`);
  }

  giveScoreCsv(loyaltyScoreId: number, file_url:string) {
    return this.postResource({loyalty_score_id: loyaltyScoreId, file_url}, `massive_actions/give_score_csv`);
  }

  getActionById(actionId: number | string): Observable<object> {
    return this.getData({ apiEndPoint: `massive_actions/${actionId}` });
  }

  getAllMassiveActions(): Observable<object> {
    return this.getData({ apiEndPoint: `massive_actions` });
  }

  run( loyalty_score_id: number, actionId: number) {
    return this.patchResource({loyalty_score_id}, `massive_actions/${actionId}/run`);
  }

  rejectAction(actionId: number) {
    return this.patchResource({}, `massive_actions/${actionId}/reject`);
  }

  fetchMultiselect( searchValues?: string, page?: number ): Observable<object> {
    let requestOptions = {
      apiEndPoint: 'massive_actions',
      numberPerPage: 10,
      pageNumber: 1
    };
    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;
    if (searchValues) {
      const filterObj = { filtering: { search_text: searchValues, active: true}};
      requestOptions = {...requestOptions, ...filterObj};
    }
    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getActionById(id);
  }
}
