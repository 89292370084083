<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card-block no-pdd">
        <div class="mrg-top-10">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <form [formGroup]="partnerForm" *ngIf="partnerForm">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name" [translate]="'resources.scores.partners.columns.name'"></label>
                      <span *ngIf="getInputConfig('name').required" class="text-danger">*</span>
                      <app-question [question]="getInputConfig('name')" [form]="partnerForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name" [translate]="'resources.scores.partners.columns.description'"></label>
                      <app-question [question]="getInputConfig('description')" [form]="partnerForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="type" [translate]="'resources.scores.partners.columns.type'"></label>
                      <app-question [question]="getInputConfig('type')" [form]="partnerForm" (multiselectChanged)="onTypeSelectorChange()"></app-question>
                    </div>
                  </div>
                </div>
                <div *ngIf="hasFormKeyWithValue('type', 'endemic')" class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="supplier_id" [translate]="'resources.scores.partners.columns.supplier_id'"></label>
                      <app-question [question]="getInputConfig('supplier_id')" [form]="partnerForm" [filters]="getInputConfig('supplier_id').filters"></app-question>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name" [translate]="'resources.scores.partners.columns.thumbnail'"></label>
                      <app-question [question]="getInputConfig('image')" [form]="partnerForm" (change)="onFileChange($event)"></app-question>
                    </div>
                    <img class="mrg-btm-20 img-container" *ngIf="partner && !missingImg" src="{{partner.thumbnail_medium}}" alt="thumbnail">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name" [translate]="'resources.scores.partners.columns.active'"></label>
                      <app-question [question]="getInputConfig('active')" [form]="partnerForm"></app-question>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
