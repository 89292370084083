import { Injectable } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { MultiselectDataSourceable, MultiselectDataSource } from '../../shared/components/multiselect/multiselect';
import { Observable } from 'rxjs';
import { QuestionBase } from '../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../shared/models/forms/question-textbox';
import { MultiSelectQuestion } from '../../shared/models/forms/question-multiselect';
import { SegmentCategoriesService } from './segment-categories.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { CheckboxQuestion } from '../../shared/models/forms/question-checkbox';
import {TextareaQuestion} from '../../shared/models/forms/question-textarea';

@Injectable()
export class SegmentsService extends ResourcesService implements MultiselectDataSourceable {

  categoryId: string = null;
  optsSource = new BehaviorSubject<string>(null);
  optsSource$ = this.optsSource.asObservable();
  selectedOptsSource = new BehaviorSubject<{selectedIds: string[], key: string}>(null);
  selectedOptsSource$ = this.selectedOptsSource.asObservable();

  constructor(
    http: HttpClient,
    private categoriesService: SegmentCategoriesService,
    private translate: TranslateService
  ) {
    super(http);
  }

  setSegment() {
    this.optsSource.next('');
  }

  setCategory(categoryId) {
    this.categoryId = categoryId;
    this.optsSource.next(this.categoryId);
  }

  getSegmentById(id: number) {
    return this.getData({ apiEndPoint: `segments/${id}` });
  }

  getCustomersById(id: number) {
    return this.getData({ apiEndPoint: `segments/${id}/customers` });
  }

  getSegments(options?: object) {
    let requestOptions: object = {
      apiEndPoint: 'segments',
      sorting: {
        byProp: 'created_at',
        direction: 'desc'
      },
      filtering: {},
      numberPerPage: 300
    };

    if (options) {
      requestOptions = Object.assign(requestOptions, options);
    }
    return this.getData(requestOptions);
  }

  getCategories() {
    return this.getData({ apiEndPoint: `segment_categories` });
  }

  fetchMultiselect( searchValues?: string, page?: number, filters?: object ) {
    let requestOptions = {
      apiEndPoint: 'segments',
      numberPerPage: 50,
      pageNumber: 1,
      filtering: {},
      sorting: {
        byProp: 'created_at',
        direction: 'desc'
      }
    };

    requestOptions.pageNumber = page ? page : requestOptions.pageNumber;
    /* Handling user search input: Merge object with user search term string */
    if ( searchValues ) {
      const filterObj = { filtering: {name: searchValues, id: searchValues, filter_logic: 'or'}};
      requestOptions = {...requestOptions, ...filterObj};
    }

    if (filters) {
      const filtering = Object.assign({}, requestOptions.filtering, filters);
      const filterObj = { filtering: filtering};
      requestOptions = {...requestOptions, ...filterObj};
    }

    return this.getData( requestOptions );
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getSegmentById(id);
  }

  getNameWithTemplate(element: any): MultiselectDataSource {
    return new MultiselectDataSource(element.id, `(${element.id}) ${element.name}`, element);
  }

  tagSegments(ids: number[]) {
    return this.postResource({ ids: ids}, 'segments/tag');
  }

  resetTags(ids: number[]) {
    return this.postResource({ ids: ids}, 'segments/reset_tags');
  }

  cloneSegment(params: any) {
    return this.postResource(params, 'segments/clone');
  }

  customersToExcel(id: number, params: any): Observable<any> {
    return this.postResource(params, `segments/${id}/export`);
  }

  archiveSegments(ids: number[]) {
    return this.postResource({ ids: ids}, 'segments/archive');
  }

  unarchiveSegment(id: number) {
    return this.postResource({}, `segments/${id}/unarchive`);
  }

  deleteSegment(id: number) {
    return this.deleteResource({}, `segments/${id}`);
  }

  deleteSegments(selectedIds) {
    return this.deleteResourceWithParams(`segments`, {ids: selectedIds.join(',')});
  }

  saveSegment(payload: object): Observable<object>  {
    return this.postResource(payload, 'segments');
  }

  patchSegmentById(id: number, params: any): Observable<object>  {
    return this.patchResource(params, `segments/${id}`);
  }

  exportSalesToCsv(id: any, params): Observable<object> {
    return this.getData({
      apiEndPoint: `segments/${id}/customers_sales`,
      filtering: params
    });
  }

  enableExport(ids: number[]) {
    return this.postResource({ ids: ids}, 'segments/enable_export');
  }

  disableExport(ids: number[]) {
    return this.postResource({ ids: ids}, `/segments/disable_export`);
  }

  getInputs(segmentData: object): QuestionBase<unknown>[] {
    const inputs = [
      new TextboxQuestion({
        key: 'name',
        label: this.translate.instant('resources.segments.fields.segment_name'),
        type: 'text',
        cssClasses: 'form-control input-md',
        required: true,
        getValue: (segmentName: string) => segmentName
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'segment_category_id',
        label: this.translate.instant('resources.segments.fields.segment_category'),
        dataSource: this.categoriesService,
        settings: {
          singleSelection: true,
          enableCheckAll: false,
          showCheckbox: false,
          enableSearchFilter: true,
          text: this.translate.instant('resources.segments.fields.category') + '...'
        },
        getValue: (segmentCategory: { id: number }) => segmentCategory ? ({selectedIds: [segmentCategory.id]}) : ({selectedIds: []})
      }),
      new TextboxQuestion({
        key: 'description',
        type: 'text',
        label: this.translate.instant('resources.segments.fields.description'),
        cssClasses: 'form-control input-md',
        getValue: (segmentDesc: string) => segmentDesc
      }),
      new CheckboxQuestion({
        key: 'apply_communications_consent',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value: boolean) => !!value
      }),
      new CheckboxQuestion({
        key: 'send_email',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value: boolean) => !!value
      }),
      new CheckboxQuestion({
        key: 'send_sms',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value: boolean) => !!value
      }),
      new CheckboxQuestion({
        key: 'include_phone_duplicated',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value: boolean) => !!value
      }),
      new CheckboxQuestion({
        key: 'send_push',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value: boolean) => !!value
      }),
      new CheckboxQuestion({
        key: 'active_customers',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        value: true,
        getValue: (value: boolean) => !!value
      }),
      new CheckboxQuestion({
        key: 'exclude_fraudulent_customers',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value: boolean) => !!value
      }),
      new CheckboxQuestion({
        key: 'profiled_customers',
        type: 'checkbox',
        cssClasses: 'form-control input-default',
        getValue: (value: boolean) => !!value
      }),
      new TextareaQuestion({
        key: 'create_from_csv_content',
        label: this.translate.instant('resources.segments.fields.create_from_csv_content'),
        type: 'textarea',
        cssClasses: 'form-control input-default',
        placeholder: '1\n2\n3',
        getValue: (value: string) => value ?? null
      })
    ];

    const segmentKeys = ['name', 'segment_category_id', 'description', 'apply_communications_consent', 'send_email', 'send_push', 'send_sms', 'active_customers', 'exclude_fraudulent_customers', 'profiled_customers', 'include_phone_duplicated'];
    if (Object.keys(segmentData).length) {
      segmentData['segment_category_id'] = segmentData['segmentCategory'];
      segmentKeys.forEach( key => {
        const input = inputs.find( _input => _input.key === key );
        const _key = key === 'segment_category_id' ? 'segment_category' : key;
        const value = input.getValue(segmentData[_key]);
        if (value?.hasOwnProperty('selectedIds')) {
          input.selectedIds = value.selectedIds;
        } else {
          input.value = value;
        }
      });
    }

    return inputs;
  }

  refreshSelectedOptsSource(selectedIds: string[], key: string) {
    this.selectedOptsSource.next({selectedIds, key});
  }
}
