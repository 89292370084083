<ng-container *ngIf="inputs">
  <div class="row">
    <div [class]="wideLayout">
      <app-question [question]="getInputConfig('date_from')" [form]="form"></app-question>
    </div>

    <div [class]="wideLayout">
      <app-question [question]="getInputConfig('date_to')" [form]="form"></app-question>
    </div>

    <div *ngIf="flags.showLocationTaxonomyTermIds" [class]="wideLayout">
      <app-question [question]="getInputConfig('location_taxonomy_term_ids')"
                    [form]="form"
                    (multiselectChanged)="handleSelectedLocationTaxonomyTermIds($event)">
      </app-question>
    </div>

    <div [class]="wideLayout">
      <app-question [question]="getInputConfig('location_ids')" [form]="form"></app-question>
    </div>
  </div>

  <div class="row mrg-btm-15">
    <div [class]="wideLayout">
      <app-question [question]="getInputConfig('operator')"
                    [form]="form"
                    (multiselectChanged)="handleOperatorChanges($event)">
      </app-question>
    </div>

    <div [ngClass]="hasFormKeyWithValue('operator', 'bt') ? narrowLayout : wideBtLayout">
      <app-question [question]="getInputConfig('value')" [form]="form"></app-question>
    </div>

    <ng-container *ngIf="hasFormKeyWithValue('operator', 'bt')">
      <div [class]="narrowLayout">
        <app-question [question]="getInputConfig('value_bt')" [form]="form"></app-question>
      </div>
    </ng-container>

    <div class="col d-flex align-items-center">
      <div class="checkbox-wrapper-center">
        <ng-container *ngIf="flags.showTicketsSegmentNetAmount; else includeZeroNegativeAverageActivity">
          <app-question [question]="inputs[8]" [form]="form"></app-question>
          <span>{{ 'resources.segment_conditions.fields.net_amount' | translate }}</span>
        </ng-container>
        <ng-template #includeZeroNegativeAverageActivity>
          <app-question [question]="inputs[7]" [form]="form"></app-question>
          <span>{{ 'resources.segment_conditions.fields.include_non_positive_average' | translate }}</span>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
