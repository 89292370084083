import { ActivatedRoute, Router } from '@angular/router';
import { CompanyPromotionsService } from '../../../services/promotional-discounts/promotional-discounts.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfirmationService } from '../../../../../../shared/services/confirmation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-show-promotional-discounts',
  templateUrl: './show-promotional-discounts.component.html',
  styleUrls: ['./show-promotional-discounts.component.scss'],
  providers: [CompanyPromotionsService]
})
export class ShowCompanyPromotionsComponent implements OnInit, OnDestroy {

  element: any;
  apiEndPoint: string;
  id: number;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly companypromotionsService: CompanyPromotionsService,
    private readonly confirmationService: ConfirmationService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    registerLocaleData(es);
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeModal(): void {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.apiEndPoint = 'promotional_discounts';
        this.getPromotionalDiscountById();
      }
    });
  }

  private getPromotionalDiscountById(): void {
    this.companypromotionsService.fetchSelectedById(this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (res) => this.element = res,
      error: (error: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(error)
    });
  }
}
