import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AbstractControl,
  ReactiveFormsModule,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Subject, Subscription } from 'rxjs';

import { PlanAlertsStatus, Plan } from '../../plan';

import { SharedModule } from '../../../../shared/shared.module';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { DateService } from '../../../../shared/services/date.service';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { PlansService } from '../../plans.service';

import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { TextboxQuestion } from '../../../../shared/models/forms/question-textbox';
import { RadioQuestion } from '../../../../shared/models/forms/question-radio';
import { FeatureFlagsService } from '../../../../shared/services/feature-flags.service';

import { pollUntil } from '../../../../shared/utils/common.utils';

@Component({
  selector: 'app-schedule-plan-form',
  standalone: true,
  imports: [CommonModule, SharedModule, ReactiveFormsModule, RouterLink],
  templateUrl: './schedule-plan-form.component.html',
  styleUrls: ['./schedule-plan-form.component.scss'],
})
export class SchedulePlanFormComponent implements OnInit, OnDestroy {
  PlanAlertsStatus = PlanAlertsStatus; // This exposes the PlanAlertsStatus enum to the template

  inputs: QuestionBase<any>[];
  schedulePlanForm: UntypedFormGroup;
  id = this.route.snapshot.params.id;
  plan: Plan;
  loading: boolean;
  flags = this.featureFlagsService.flags;
  alertsUnacknowledged = false;

  subs$: Subscription[] = [];
  poll$: Subscription;
  pollKill$: Subject<void>;

  constructor(
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
    private qcs: QuestionControlService,
    private translate: TranslateService,
    private planService: PlansService,
    private modalStatusService: ModalStatusService,
    private dateService: DateService,
    private modalStatus: ModalStatusService,
    private featureFlagsService: FeatureFlagsService
  ) { }

  ngOnInit() {
    this.setInputs();
    this.getPlanById();
  }

  ngOnDestroy() {
    this.subs$.forEach((s$) => s$.unsubscribe());
    this.pollKill$.next();
    this.poll$.unsubscribe();
  }

  getPlanById(): void {
    const [poll$, pollKill$] = pollUntil(
      this.planService.getPlanById,
      [this.id],
      this.planService,
      2000,
      this.handleExecutionError,
      (response) => response.alerts_status === PlanAlertsStatus.Generating // Stop when status is not generating
    );
    this.pollKill$ = pollKill$;
    this.poll$ = poll$.subscribe({
      next: (response) => {
        this.plan = new Plan(response);
        this.loading = response.alerts_status === PlanAlertsStatus.Generating;
        this.checkUnacknowledgedAlerts();
      },
      error: this.handleExecutionError
    });
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.schedulePlanForm, formKey, value);
  }

  openValidationModal() {
    this.router.navigate([{ outlets: { modal: `validate_plan/${this.id}` } }]).catch(() => { });
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => { });
  }

  isPlanAlertStatus(status: PlanAlertsStatus | PlanAlertsStatus[]): boolean {
    if (!this.plan) return false;
    if (Array.isArray(status)) { return status.includes(this.plan.alerts_status); }
    return this.plan.alerts_status === status;
  }

  handleFormSelection() {
    if (this.schedulePlanForm.value.schedule === 'send_now') {
      this.executePlan();
    } else {
      this.schedulePlan();
    }
  }

  handleSchedule(_value: Event) {
    this.schedulePlanForm.patchValue(
      { schedule_date: null },
      { emitEvent: true },
    );
  }

  disableConfirmButton() {
    if (this.schedulePlanForm.invalid) { return true }
    if (!this.flags.showPlansAlertsConfiguration) { return false } // If the feature is not yet enabled, do not disable the button
    // If the alerts_status is not Success or there are unacknowledged alerts, disable the button
    return this.plan.alerts_status !== PlanAlertsStatus.Success || this.alertsUnacknowledged;
  }

  private getValidators(): ValidatorFn[] {
    const scheduleDate = this.schedulePlanForm.get('schedule_date');
    const scheduleInput = this.schedulePlanForm.get('schedule');
    scheduleDate.setValidators([]);

    return [
      (_form: UntypedFormGroup) => {
        if (scheduleInput.value === 'send_now') {
          return null;
        }
      },
      (form: UntypedFormGroup) => {
        if (scheduleInput.value === 'schedule') {
          scheduleDate.setValidators([Validators.required]);
        } else {
          scheduleDate.setValidators([]);
        }

        if (
          scheduleInput.value === 'schedule' &&
          !this.dateService.isValidDate(scheduleDate.value)
        ) {
          return this.setError(scheduleDate, { invalidDate: true });
        } else {
          form.setErrors(null);
          return null;
        }
      },
    ];
  }

  private checkUnacknowledgedAlerts() {
    if (this.flags.showPlansAlertsConfiguration && this.plan?.alerts_status === PlanAlertsStatus.Success) {
      this.subs$.push(this.planService.getPlanAlertsById(this.id).subscribe({
        next: (response) => {
          this.alertsUnacknowledged = response['_embedded']['list']
            .some((alert: Record<string, (number | string | boolean)>) => alert.acknowledged === false);
        },
        error: (error) => {
          console.error('Error fetching plan alerts:', error);
          this.alertsUnacknowledged = false; // Assume no unacknowledged alerts on error
        }
      }));
    } else {
      this.alertsUnacknowledged = false; //If flag is turned off, do not disable
    }
  }

  private setError(control: AbstractControl, errorHash: object): object {
    control.setErrors(errorHash);
    return errorHash;
  }

  private schedulePlan() {
    const scheduled_at = this.schedulePlanForm.get('schedule_date').value
      ? this.schedulePlanForm.get('schedule_date').value
      : null;
    this.plan['scheduled_at'] = scheduled_at;
    const messageConfirmation = this.translate.instant(
      'resources.plans.messages.schedule',
    );
    this.confirmationService
      .displayConfirmationAlert(this.plan.name, messageConfirmation, 'question')
      .then((data) => {
        if (data.hasOwnProperty('value') && data.value) {
          this.loading = true;
          const schedule$ = this.planService
            .updatePlan(`${this.id}`, this.plan)
            .subscribe({
              next: () => {
                this.planService.executePlan(this.id).subscribe({
                  next: () => {
                    this.loading = false;
                    const successTitle = this.translate.instant(
                      'resources.plans.messages.schedule_success_title',
                    );
                    const successDesc = this.translate.instant(
                      'resources.plans.messages.schedule_success_desc',
                    );
                    this.confirmationService
                      .displaySuccessAlert(successTitle, successDesc)
                      .catch(() => { });
                    this.modalStatusService.modalStatus.emit();
                    this.loading = false;
                    this.closeModal();
                  },
                  error: (errorData: HttpErrorResponse) => {
                    this.loading = false;
                    this.confirmationService.displayErrorAlert(
                      'Error',
                      errorData.error.error,
                    );
                    this.closeModal();
                  },
                });
              },
              error: (errorData: HttpErrorResponse) => {
                this.loading = false;
                this.confirmationService.displayErrorAlert(
                  'Error',
                  errorData.error.error,
                );
              },
            });
          this.subs$.push(schedule$);
        }
      })
      .catch(() => { });
  }

  private executePlan() {
    const scheduled_at = this.schedulePlanForm.get('schedule_date').value
      ? this.schedulePlanForm.get('schedule_date').value
      : null;
    this.plan['scheduled_at'] = scheduled_at;
    const messageConfirmation = this.translate.instant(
      'resources.plans.messages.execute_sure',
    );
    this.confirmationService
      .displayConfirmationAlert(this.plan.name, messageConfirmation, 'question')
      .then((data) => {
        if (data.hasOwnProperty('value') && data.value) {
          this.loading = true;
          this.planService.updatePlan(`${this.id}`, this.plan).subscribe(() => {
            const execute$ = this.planService
              .executePlan(`${this.id}`)
              .subscribe({
                next: () => {
                  this.loading = false;
                  const successTitle = this.translate.instant(
                    'resources.plans.messages.execute_success_title',
                  );
                  const successDesc = this.translate.instant(
                    'resources.plans.messages.execute_success_desc',
                  );
                  this.confirmationService
                    .displaySuccessAlert(successTitle, successDesc)
                    .catch(() => { });
                  this.modalStatusService.modalStatus.emit();
                  this.loading = false;
                  this.closeModal();
                },
                error: (errorData: HttpErrorResponse) => {
                  this.handleExecutionError(errorData);
                },
              });
            this.subs$.push(execute$);
          });
        }
      })
      .catch(() => { });
  }

  private handleExecutionError(err: HttpErrorResponse) {
    this.loading = false;
    if (
      err.error.hasOwnProperty('extra_info') &&
      err.error.extra_info.length > 0
    ) {
      this.modalStatus.resourceStatus.emit({ plan: this.plan, err: err });
    } else {
      this.confirmationService.displayErrorAlert(
        this.translate.instant('common.error'),
        err.error.error,
      );
    }
    this.closeModal();
  }

  private scheduleOptions() {
    return [
      {
        id: 'send_now',
        name: this.translate.instant('resources.plans.fields.send_now'),
      },
      {
        id: 'schedule',
        name: this.translate.instant('resources.plans.fields.schedule'),
      },
    ];
  }

  private setInputs() {
    const inputs = [
      new RadioQuestion({
        key: 'schedule',
        type: 'radio',
        cssClasses: 'radio-inline radio-info',
        options: this.scheduleOptions(),
        value: 'send_now',
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'schedule_date',
        type: 'datetime-local',
        step: 60,
        required: true,
      }),
    ];

    this.inputs = inputs;
    this.schedulePlanForm = this.qcs.toFormGroup(this.inputs);
    this.schedulePlanForm.setValidators(this.getValidators());
    return this.inputs;
  }
}
