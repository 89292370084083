import { AbstractControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { CurrentCompany } from '../../../shared/models/current-user';
import { CustomerAttribute, TierDefinition } from './tier';
import { CustomerAttributesService } from '../../customer-data-platform/customer-attributes/services/customer-attributes/customer-attributes.service';
import { FeatureFlagsService } from '../../../shared/services/feature-flags.service';
import { getTranslationInputs } from '../../data-warehouse/data-warehouse-coupons/form-coupons/coupon-utils/coupon-utils';
import { isNullOrUndefined } from '../../../shared/utils/common.utils';
import { MultiselectService } from '../../../shared/components/multiselect/multiselect.service';
import { ProfileService } from '../../../profiles/profile.service';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../shared/services/question-control.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { TiersManagementService } from './tiers-management.service';
import { DateTimeZoneService } from '../../../shared/services/date-time-zone.service';

@Component({
  selector: 'app-tiers',
  templateUrl: './tiers-management.component.html',
  styleUrls: ['./tiers-management.component.scss']
})

export class TiersComponent implements OnInit, OnDestroy {

  @Output() responseEmitter: EventEmitter<TierDefinition> = new EventEmitter<TierDefinition>();

  companyData: CurrentCompany;
  companyId: number = this.profileService.getStoredUser().company.id;
  customerAttributeInfo: CustomerAttribute;
  customerAttributesFilters: { attribute_type: string; only_numbers: boolean; loyalty_values: boolean; };
  filteredAvailableLanguages: string[];
  flags = this.featureFlags.flags;
  hasNullName: boolean;
  id: number;
  image: { filename: string; filesize: number; filetype: unknown; base64: string; };
  imageFile: string | ArrayBuffer;
  inputs: QuestionBase<any>[];
  loading: boolean;
  MAX_FILE_SIZE_LIMIT = 500 * 1024;
  mode: string = this.route.snapshot.data.mode;
  selectedFile: {
    name: string;
    size: number;
    type: string;
  };
  subs$: Subscription[] = [];
  tierDefinition: TierDefinition;
  tiersDefinitionForm: UntypedFormGroup;
  type: string = this.route.snapshot.data.type;

  private company = new CurrentCompany(this.profileService.getProfileCompany());
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private confirmationService: ConfirmationService,
    private customerAttributesService: CustomerAttributesService,
    private dateService: DateTimeZoneService,
    private featureFlags: FeatureFlagsService,
    private multiselectService: MultiselectService,
    private profileService: ProfileService,
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private tiersManagementService: TiersManagementService
  ) {
    this.customerAttributesFilters = {attribute_type: 'calculation', only_numbers: true, loyalty_values: true};
  }

  ngOnInit(): void {
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.tiersDefinitionForm, formKey, value);
  }

  displaySelectedAttribute(event) {
    if(event?.id) {
      this.customerAttributesService.fetchSelectedById(event.id).pipe(takeUntil(this.destroy$)).subscribe( {
        next: (response: CustomerAttribute) => {
          this.customerAttributeInfo = response;
          const data_period_type = this.customerAttributeInfo['data_period_type'];
          const exp = this.inputs.find(e => e.key === 'expiration');
          const filer = ["relative_months", "relative_days"].includes(data_period_type);
          exp.options = this.tiersManagementService.getExpirationOptions(filer);
          this.multiselectService.updateDropdownList.next('expiration');
        },
        error: (errorData) => this.confirmationService.displayHttpErrorAlert(errorData)
      });
    }
  }

  onFileChange(event): void {
    const reader = new FileReader();
    if (event?.target?.files?.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        this.image = {
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
          base64: reader.result.toString().split(',')[1]
        };
        this.imageFile = e.target?.result; // Base64 URL
      };
    }
  }

  validateFileSize(event, fileUpload) {
    const file = event.files[0];
    this.selectedFile = file;
    const imageInput= this.tiersDefinitionForm.get('image');

    if (file.size > this.MAX_FILE_SIZE_LIMIT) {
      this.image = null;
      fileUpload.clear();
      return imageInput.setErrors({ 'invalidFileSize': true });
    } else {
      fileUpload.clear();
      imageInput.setErrors(null);
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      this.image = {
        filename: file.name,
        filesize: file.size,
        filetype: file.type,
        base64: reader.result.toString().split(',')[1]
      };
      this.imageFile = e.target?.result; // Base64 URL
    };
  }

  private getTierDefinitionById() {
    this.tiersManagementService.getTierDefinitionById(this.id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: TierDefinition) => {
        this.tierDefinition = response;
        this.responseEmitter.emit(this.tierDefinition);
        this.tierDefinition['customer_attribute'] = response['achievement_definitions'][0].customer_attribute_definition_id;
        this.getAndFilterRequiredInput();
        this.tiersDefinitionForm = this.qcs.toFormGroup(this.inputs);
        this.tiersDefinitionForm.setValidators(this.getValidators());
        this.enrollmentsValidation();
        getTranslationInputs(this.tierDefinition, this.qcs, this.tiersDefinitionForm, this.inputs, 'form');
        this.filteredAvailableLanguages = response.available_languages;
        if(this.mode === 'show'){
          this.inputs.forEach(input => input.disabled = true);
        }
      },
      error: (errorData) => this.confirmationService.displayHttpErrorAlert(errorData)
    });
  }

  private getParams() {
    this.route.params.subscribe(params => {
      this.id = params.id;
      if(this.id) {
        this.getTierDefinitionById();
      } else {
        this.getAndFilterRequiredInput();
        this.tiersDefinitionForm = this.qcs.toFormGroup(this.inputs);
        this.getCompanyData();
        this.enrollmentsValidation();
        this.tiersDefinitionForm.setValidators(this.getValidators());
      }
    });
  }

  private getAndFilterRequiredInput(): void {
    const defaultFilterKeys = [];
    let desiredInputs;

    if (this.type === 'challenge') {
      defaultFilterKeys.push('name', 'threshold_tier');
    } else if (this.type === 'tier') {
      defaultFilterKeys.push('level_name', 'threshold_challenge');
    }

    const filteredInputs = (input) => defaultFilterKeys.every(key => input.key !== key);

    if (this.mode === 'create') {
      desiredInputs = this.tiersManagementService.getInputs({}).filter(filteredInputs);
    } else {
      desiredInputs = this.tiersManagementService.getInputs(this.tierDefinition, this.type).filter(filteredInputs);
    }

    this.inputs = desiredInputs;
  }

  private enrollmentsValidation() {
    const maxEnrollmentsControl = this.tiersDefinitionForm.get('max_enrollments');
    const openToAllControl = this.tiersDefinitionForm.get('open_to_all');

    const openToAllSubscription = openToAllControl.valueChanges.subscribe(checked => {
      const enrollmentsControl = this.tiersDefinitionForm.get('enrollments');

      if (checked) {
        enrollmentsControl.setValidators(Validators.required);
        this.inputs.find(input => input.key === 'enrollments').required = true;
      } else {
        enrollmentsControl.clearValidators();
        enrollmentsControl.reset();
        this.inputs.find(input => input.key === 'enrollments').required = false;
      }
      enrollmentsControl.updateValueAndValidity({ onlySelf: true });
    });

    this.subs$.push(openToAllSubscription);
    this.updateEnrollmentsValidations(maxEnrollmentsControl);

    const allowJoining$ = this.tiersDefinitionForm.get('open_to_all').valueChanges.subscribe(
      (checked) => {
        if (checked) {
          const fieldsToReset = ['max_enrollments', 'enrollments'];
          fieldsToReset.forEach(field => this.tiersDefinitionForm.get(field).reset());
        } else {
          this.updateEnrollmentsValidations(maxEnrollmentsControl);
        }
      }
    );

    const enrollments$ = this.tiersDefinitionForm.get('enrollments').valueChanges.subscribe(
      () => {
        this.updateEnrollmentsValidations(maxEnrollmentsControl);
      }
    );

    this.subs$.push(allowJoining$);
    this.subs$.push(enrollments$);
  }

  private updateEnrollmentsValidations(maxEnrollmentsControl: AbstractControl) {
    const enrollmentsValue = this.tiersDefinitionForm.get('enrollments')?.value;
    const isOpenToAll = this.tiersDefinitionForm.get('open_to_all')?.value;
    const maxEnrollmentsInput = this.inputs.find(input => input.key === 'max_enrollments');
    const enrollmentsInput = this.inputs.find(input => input.key === 'enrollments');

    if (enrollmentsValue?.[0]?.id === 'max_enrollments') {
      maxEnrollmentsInput.required = true;
      maxEnrollmentsInput.disabled = false;
      maxEnrollmentsControl.setValidators([Validators.required, Validators.min(1)]);
    } else if (enrollmentsValue?.[0]?.id === 'unlimited') {
      maxEnrollmentsInput.disabled = true;
      maxEnrollmentsInput.required = false;
      this.tiersDefinitionForm.get('max_enrollments').reset();
      maxEnrollmentsControl.clearValidators();
    } else if (isOpenToAll && !enrollmentsValue) {
      this.tiersDefinitionForm.get('max_enrollments').reset();
      enrollmentsInput.required = true;
      maxEnrollmentsInput.required = false;
      this.tiersDefinitionForm.get('enrollments').setValidators(Validators.required);
      maxEnrollmentsControl.clearValidators();
    } else {
      maxEnrollmentsControl.clearValidators();
      maxEnrollmentsInput.required = false;
      this.tiersDefinitionForm.get('max_enrollments').reset();
    }
    maxEnrollmentsControl?.updateValueAndValidity({ onlySelf: true });
  }

  private getValidators(): ValidatorFn[] {
    return [
      (form: UntypedFormGroup) => {
        const customerAttr = form.get('customer_attribute');
        if (isNullOrUndefined(customerAttr.value) || customerAttr.value.length === 0){
          return this.setError(customerAttr, {invalid__: true});
        } else {
          form.setErrors(null);
          customerAttr.setErrors(null)
          this.tiersDefinitionForm.get('customer_attribute').clearValidators();
          this.tiersDefinitionForm.get('customer_attribute').updateValueAndValidity({ onlySelf: true });
        }
        return null;
      },
      (form: UntypedFormGroup) => {
        const availableFrom = form.get('available_from');
        const availableTo = form.get('available_to');
        if (this.dateService.isDate2BeforeDate1(availableFrom.value, availableTo.value)) {
          return this.setError(availableTo, { invalidDateRange: true });
        } else {
          availableTo.setErrors(null);
        }
        form.controls['available_from'].setErrors(null);
        form.controls['available_to'].setErrors(null);
        return null;
      },
      (form: UntypedFormGroup) => {
        const displayFrom = form.get('display_from');
        const displayTo = form.get('display_to');
        if (this.dateService.isDate2BeforeDate1(displayFrom.value, displayTo.value)) {
          return this.setError(displayTo, { invalidDateRange: true });
        } else {
          displayTo.setErrors(null);
        }
        form.controls['display_from'].setErrors(null);
        form.controls['display_to'].setErrors(null);
        return null;
      },
      (form: UntypedFormGroup) => {
        const expiration = form.get('expiration');
        const expirationRelativeValue = form.get('expiration_relative_value');

        if (expiration?.value && expiration.value.length > 0) {
          if (expiration.value[0].id === 'relative_months' || expiration.value[0].id === 'relative_days') {
            expirationRelativeValue?.setValidators(Validators.required);
          } else {
            expirationRelativeValue?.clearValidators();
            if(!isNullOrUndefined(expirationRelativeValue.value)) {
              expirationRelativeValue?.reset();
          }
          expirationRelativeValue?.updateValueAndValidity({ onlySelf: true });
        }

        if (expirationRelativeValue?.hasError('required')) {
          return this.setError(expirationRelativeValue, { required: true });
        } else {
          expirationRelativeValue?.setErrors(null);
        }

        return null;
      }}
    ];
  }

  private setError(control: AbstractControl, errorHash: object): object {
    control.setErrors(errorHash);
    return errorHash;
  }

  private getCompanyData() {
    if (this.company.availableLanguages) {
      const availableLanguagesOptions = this.inputs.find(input => input.key === 'available_languages').options;
      const filtered = availableLanguagesOptions.filter(item => this.company.availableLanguages.map(_item => _item.slice(0, 2)).includes(item.id));
      this.tiersDefinitionForm.get('available_languages').patchValue(filtered);
      this.filteredAvailableLanguages = filtered;
    }
  }

}
