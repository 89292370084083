<ng-container *ngIf="inputs">
  <form [formGroup]="form" *ngIf="form">
    <div class="row">
      <div class="col-md-6 col-lg-6 col-xl-3">
        <app-question [question]="getInputConfig('date_type')" [form]="form" (multiselectChanged)="handleDateChanges($event)"/>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-lg-6 col-xl-3">
        <app-question [question]="getInputConfig('date_from')" [form]="form"/>
      </div>

      <div class="col-md-6 col-lg-6 col-xl-3">
        <app-question [question]="getInputConfig('date_to')" [form]="form"/>
      </div>

      <div class="col-md-6 col-lg-6 col-xl-3">
        <app-question [question]="getInputConfig('location_taxonomy_term_ids')"
                      [form]="form"
                      (multiselectChanged)="handleSelectedLocationTaxonomyTermIds($event)"/>
      </div>

      <div class="col-md-6 col-lg-6 col-xl-3">
        <app-question [question]="getInputConfig('location_ids')" [form]="form"/>
      </div>
    </div>

    <div class="row mrg-btm-10">
      <div class="col-md-6 col-lg-6 col-xl-3">
        <app-question [ngClass]="handleOperatorClass('total_sum_operator')"
                      [question]="getInputConfig('total_sum_operator')"
                      [form]="form"
                      (multiselectChanged)="handleSelectorOperatorChanges($event, 'total_sum')"/>
      </div>

      <div class="col-md-6 col-lg-6 col-xl-2" *ngIf="!hasFormKeyWithValue('total_sum_operator', 'bt')">
        <app-question [question]="getInputConfig('total_sum_value')" [form]="form"/>
      </div>

      <ng-container *ngIf="hasFormKeyWithValue('total_sum_operator', 'bt')">
        <div class="col-md-3 col-lg-3 col-xl-1">
          <app-question [question]="getInputConfig('total_sum_value_1')" [form]="form"/>
        </div>
        <div class="col-md-3 col-lg-3 col-xl-1">
          <app-question [question]="getInputConfig('total_sum_value_2')" [form]="form"/>
        </div>
      </ng-container>

      <div class="col-md-6 col-lg-6 col-xl-3">
        <app-question [ngClass]="handleOperatorClass('payment_cost_operator')"
                      [question]="getInputConfig('payment_cost_operator')"
                      [form]="form"
                      (multiselectChanged)="handleSelectorOperatorChanges($event, 'payment_cost')"/>
      </div>

      <div class="col-md-6 col-lg-6 col-xl-2" *ngIf="!hasFormKeyWithValue('payment_cost_operator', 'bt')">
        <app-question [question]="getInputConfig('payment_cost_value')" [form]="form"/>
      </div>

      <ng-container *ngIf="hasFormKeyWithValue('payment_cost_operator', 'bt')">
        <div class="col-md-3 col-lg-3 col-xl-1">
          <app-question [question]="getInputConfig('payment_cost_value_1')" [form]="form"/>
        </div>
        <div class="col-md-3 col-lg-3 col-xl-1">
          <app-question [question]="getInputConfig('payment_cost_value_2')" [form]="form"/>
        </div>
      </ng-container>
    </div>

    <div class="row mrg-btm-10">
      <div class="col-md-6 col-lg-6 col-xl-3">
        <app-question [ngClass]="handleOperatorClass('shipping_cost_operator')"
                      [question]="getInputConfig('shipping_cost_operator')"
                      [form]="form"
                      (multiselectChanged)="handleSelectorOperatorChanges($event, 'shipping_cost')"/>
      </div>

      <div class="col-md-6 col-lg-6 col-xl-2" *ngIf="!hasFormKeyWithValue('shipping_cost_operator', 'bt')">
        <app-question [question]="getInputConfig('shipping_cost_value')" [form]="form"/>
      </div>

      <ng-container *ngIf="hasFormKeyWithValue('shipping_cost_operator', 'bt')">
        <div class="col-md-3 col-lg-3 col-xl-1">
          <app-question [question]="getInputConfig('shipping_cost_value_1')" [form]="form"/>
        </div>
        <div class="col-md-3 col-lg-3 col-xl-1">
          <app-question [question]="getInputConfig('shipping_cost_value_2')" [form]="form"/>
        </div>
      </ng-container>
    </div>
  </form>
</ng-container>
