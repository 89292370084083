import { Component, Input } from "@angular/core";
import { SummaryPointsTicketDetail, TicketDetail } from "../../../../../shared/models/purchases/ticket-detail";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-points-block',
  templateUrl: './points-block.component.html',
  styleUrls: ['./points-block.component.scss']
})

export class PointsBlockComponent {

  @Input() ticketData: TicketDetail;
  @Input() currencySymbol: string;
  @Input() pointsSummary: SummaryPointsTicketDetail[];

  currentLang: string;

  constructor(public translate: TranslateService) {
    this.currentLang = this.translate.currentLang;
  }

  scroll(lineOrder: number) {
    document.getElementById("line" + lineOrder).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
}
