import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { DynamicDate } from '../../../../models/segments/conditions/dynamic-date';
import { handleSelectedLocationTaxonomyTermIdsField, resetInputsForm } from '../../utils/common.utils';
import { LocationsService } from '../../../../../resources/data-warehouse/locations/locations.service';
import { MultiselectDataSource } from '../../../multiselect/multiselect';
import { Operator } from '../../../../models/segments/conditions/operator';
import { QuestionBase } from '../../../../models/forms/question-base';
import { QuestionControlService } from '../../../../services/question-control.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-ecommerce-condition',
  templateUrl: './ecommerce-condition.component.html',
  styleUrl: './ecommerce-condition.component.scss'
})

export class EcommerceConditionComponent {

  @Input() item: Condition;
  @Input() inputs: QuestionBase<unknown>[];
  @Input() form: UntypedFormGroup;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly locationsService: LocationsService,
    private readonly qcs: QuestionControlService
  ) {}

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.form, formKey, value);
  }

  handleOperatorClass(operator: string): string {
    return this.form?.get(operator)?.errors?.valueRequired ? 'invalid-selector' : 'valid-selector';
  }

  handleDateChanges(selectedDate: DynamicDate): void {
    const dateKeys = ['date_from', 'date_to'];
    dateKeys.forEach(key => {
      const formField = this.form.get(key);
      const inputField = this.getInputConfig(key);
      if (selectedDate) {
        formField.enable();
      } else {
        formField.patchValue(null);
        inputField.value = {id: null, name: null};
        formField.disable();
      }
      inputField.disabled = !selectedDate;
    });
  }

  handleSelectorOperatorChanges(operator: Operator, keyRoot: string): void {
    this.handleOperatorChanges(operator, `${keyRoot}_operator`, `${keyRoot}_value`, `${keyRoot}_value_1`, `${keyRoot}_value_2`);
  }

  handleSelectedLocationTaxonomyTermIds(locationTaxonomyTermIdsSelected: MultiselectDataSource[]): void {
    handleSelectedLocationTaxonomyTermIdsField(
      locationTaxonomyTermIdsSelected,
      'location_ids',
      this.getInputConfig('location_ids'),
      this.form,
      this.changeDetector,
      this.locationsService
    );
  }

  private handleOperatorChanges(operator: Operator, keyOperator: string, keyOperatorValue: string, keyOperatorValue1: string, keyOperatorValue2: string) {
    if (operator) {
      const op = operator.hasOwnProperty('id') ? operator.id : null;
      const keyFieldsToReset = op === 'bt' ? [keyOperatorValue] : [keyOperatorValue1, keyOperatorValue2];
      resetInputsForm(this.form, this.inputs, keyFieldsToReset);
    } else {
      resetInputsForm(this.form, this.inputs, [keyOperator, keyOperatorValue, keyOperatorValue1, keyOperatorValue2]);
    }
  }
}
