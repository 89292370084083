import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CreateUpdateLandingsService } from '../service/create-update-landings.service';
import { ConfirmationService } from '../../../services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../../../profiles/profile.service';
import { ModalStatusService } from '../../../services/modal-status.service';
import { Subscription } from 'rxjs';
import { DictionaryService } from '../../../services/dictionary.service';
import { FeatureFlagsService } from '../../../services/feature-flags.service';
import { Modules } from '../../../enums/modules.enums';

@Component({
  selector: 'app-update-landing',
  templateUrl: './update-landing.component.html',
  styleUrls: ['./update-landing.component.css'],
  providers: [DictionaryService]
})
export class UpdateLandingComponent implements OnInit, OnDestroy{

  schema: any;
  beeCustomConfig = {onSave: (json: JSON, html: string) => this.onSave(json, html)};
  userData;
  id: number;
  campaignId: number;
  campaignName: string;
  subs$: Subscription[];
  mergeTags: {name: string, value: string}[];
  isPersonalizationCampaignsModule = this.route.snapshot?.['_routerState']?.url?.split('/')?.[1] === Modules.PersonalizationCampaigns;

  private flags = this.featureFlags.flags;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private currentUserProfile: ProfileService,
    private translate: TranslateService,
    private createUpdateLandingsService: CreateUpdateLandingsService,
    private modalStatusService: ModalStatusService,
    private dictionary: DictionaryService,
    private featureFlags: FeatureFlagsService
  ) {
    this.subs$ = [];
  }

  ngOnInit() {
    this.mergeTags = this.dictionary.getValuesByKey('campaign_templates_custom_variables');
    if(!this.flags.showCustom1DynamicVariable){
      this.mergeTags = this.mergeTags.filter(tag => tag.value !== '{custom1}');
    }
    this.getParams();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getParams() {
    const route$ = this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.getUserData();
      }
    });
    this.subs$.push(route$);
  }

  getById() {
    const landing$ = this.createUpdateLandingsService.getById(this.id).subscribe(
      response => {
        this.campaignName = response['name'];
        this.campaignId = response['template'].id;
        if (response['template']['schema']) {
          this.createUpdateLandingsService.initBee(this.userData, this.beeCustomConfig, response['template']['schema'], this.mergeTags);
        } else {
          this.getTemplateById();
        }
      }
    );
    this.subs$.push(landing$);
  }

  private getTemplateById() {
    const landing$ = this.createUpdateLandingsService.getTemplateById(1).subscribe(
      response => {
        this.createUpdateLandingsService.initBee(this.userData, this.beeCustomConfig, response['schema'], this.mergeTags);
      },
      errorData => {
        this.confirmationService.displayErrorAlert(
          this.translate.instant('common.error'),
          errorData.error.error
        )
      }
    );
    this.subs$.push(landing$);
  }

  private getUserData() {
    const user$ = this.currentUserProfile.getRawUserProfile().subscribe((reqResponse) => {
      this.userData = reqResponse;
      this.getById();
    });
    this.subs$.push(user$);
  }

  onSave(json, html) {
    const save$ = this.createUpdateLandingsService.updateTemplateById(this.id, json, html, this.campaignName).subscribe(
      () => {
        const updateTitle = this.translate.instant('resources.campaigns_landings.modal.warnings.success_title');
        const updateText = this.translate.instant('resources.campaigns_landings.modal.warnings.success_text');
        this.confirmationService.displaySuccessAlert(updateTitle, updateText).catch(() => {});
        this.modalStatusService.modalStatus.emit();
        const routerLink = this.isPersonalizationCampaignsModule ? `${Modules.PersonalizationCampaigns}/templates/landings` : 'omnichannel-manager/landings';
        this.router.navigate([routerLink]).then(() => {
          this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
        }).catch(() => {});
      },
      errorData => {
        this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error)
      }
    );
    this.subs$.push(save$);
  }

  confirmClose() {

    const confirmationTitle = this.translate.instant('resources.campaigns_landings.modal.warnings.continue');
    const confirmationMessage = this.translate.instant('resources.campaigns_landings.modal.warnings.cancel_message');

    this.confirmationService.displayConfirmationAlert(confirmationTitle, confirmationMessage).then(
      data => {
        if (data.hasOwnProperty('value') && data.value) {
          this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
        }
      }
    ).catch(() => {});
  }
}
