<!-- BLOQUE2 -->
<div *ngIf="couponDetail" class="card height-100">
  <div class="card-heading border bottom">
    <h4 class="card-title" [translate]="'resources.coupons.modal.show.details.fields.info'"></h4>
  </div>
  <div class="card-block">
    <div class="table-overflow">
      <table class="table table-hover" aria-hidden="true">
        <tbody>
          <tr *ngIf="couponDetail.available_days">
            <td><b [translate]="'resources.coupons.modal.show.details.fields.range'"></b></td>
            <td>{{couponDetail.available_days}}</td>
          </tr>
          <tr *ngIf="couponDetail.available_from">
            <td><b [translate]="'resources.coupons.modal.show.details.fields.available_from'"></b></td>
            <td>{{couponDetail.available_from | date: 'dd/MM/yyyy'}}</td>
          </tr>
          <tr *ngIf="couponDetail.available_to">
            <td><b [translate]="'resources.coupons.modal.show.details.fields.available_to'"></b></td>
            <td>{{couponDetail.available_to | date: 'dd/MM/yyyy'}}</td>
          </tr>
          <tr *ngIf="couponDetail.print_from">
            <td><b [translate]="'resources.coupons.modal.show.details.fields.print_from'"></b></td>
            <td>{{couponDetail.print_from | date: 'dd/MM/yyyy'}}</td>
          </tr>
          <tr *ngIf="couponDetail.print_to">
            <td><b [translate]="'resources.coupons.modal.show.details.fields.print_to'"></b></td>
            <td>{{couponDetail.print_to | date: 'dd/MM/yyyy'}}</td>
          </tr>
          <tr *ngIf="couponDetail.priority">
            <td><b [translate]="'resources.coupons.modal.show.details.fields.priority'"></b></td>
            <td>{{couponDetail.priority}}</td>
          </tr>
          <tr *ngIf="couponDetail.static_code">
            <td><b [translate]="'resources.coupons.modal.show.details.fields.static_code'"></b></td>
            <td>{{couponDetail.static_code}}</td>
          </tr>
          <tr *ngIf="couponDetail.key">
            <td><b [translate]="'resources.coupons.modal.show.details.fields.coupon_key'"></b></td>
            <td>{{couponDetail.key}}</td>
          </tr>
          <tr *ngIf="couponDetail.coupon_type">
            <td><b [translate]="'resources.coupons.modal.show.details.fields.coupon_type'"></b></td>
            <td>{{couponDetail.coupon_type}}</td>
          </tr>
          <tr *ngIf="couponDetail.discount_type !== '[]'">
            <td><b [translate]="'resources.coupons.modal.show.details.fields.discount_type'"></b></td>
            <td *ngIf="couponDetail.discount_type === 'percent'"
              [translate]="'resources.coupons.modal.show.details.fields.percent'"></td>
            <td *ngIf="couponDetail.discount_type === 'cash'"
              [translate]="'resources.coupons.modal.show.details.fields.cash'"></td>
          </tr>
          <ng-container *ngIf="couponDetail.payment_methods?.length">
            <tr>
              <td><b [translate]="'resources.coupons.modal.show.details.fields.payment_method_discount_type'"></b></td>
              <td>{{ couponDetail.payment_method_discount_type }}</td>
            </tr>
            <tr>
              <td><b [translate]="'resources.coupons.modal.show.details.fields.payment_method_discount'"></b></td>
              <td>{{ couponDetail.payment_method_discount | number:'':'es' }}</td>
            </tr>
            <tr>
              <td><b [translate]="'resources.coupons.modal.show.details.fields.payment_methods'"></b></td>
              <td *ngIf="!showPaymentMethods"><b (click)="seePaymentMethods()"
                  [translate]="'resources.coupons.modal.show.details.fields.see_payment_methods'"></b></td>
              <td *ngIf="showPaymentMethods">
                <ul class="ul-style">
                  <li *ngFor="let item of couponDetail.payment_methods | slice: 0:4">
                    {{ item.slug }}
                  </li>
                </ul>
              </td>
            </tr>
          </ng-container>
          <tr>
            <td><b [translate]="'resources.coupons.modal.show.details.fields.printable'"></b></td>
            <td *ngIf="couponDetail.printable"><span><i class="fa fa-check text-success"></i></span></td>
            <td *ngIf="!couponDetail.printable"><span><i class="fa fa-times text-danger"></i></span></td>
          </tr>
          <tr *ngIf="flags.displayCumulativeFieldCoupons">
            <td><b [translate]="'resources.coupons.modal.show.details.fields.cumulative'"></b></td>
            <td *ngIf="couponDetail.cumulative"><span><i class="fa fa-check text-success"></i></span></td>
            <td *ngIf="!couponDetail.cumulative"><span><i class="fa fa-times text-danger"></i></span></td>
          </tr>
          <tr *ngIf="flags.ShowCouponPoints && couponDetail.points">
            <td><b [translate]="'resources.coupons.modal.show.details.fields.points'"></b></td>
            <td>{{couponDetail.points}}</td>
          </tr>
          <tr *ngIf="flags.ShowCouponPoints && couponDetail.points_money">
            <td><b [translate]="'resources.coupons.modal.show.details.fields.points_money'"></b></td>
            <td>{{couponDetail.points_money}}</td>
          </tr>
          <tr>
            <ng-container
              *ngIf="couponDetail.location_ids && couponDetail.location_ids.length >= 0 && (couponDetail.feature_location_ids && couponDetail.feature_location_ids.length === 0 || !couponDetail.feature_location_ids)">
              <td><b [translate]="'resources.coupons.modal.show.details.fields.locations'"></b>
                <b *ngIf="couponDetail.location_ids.length > 0"> ({{couponDetail.locations.length}})</b>
              </td>
              <td *ngIf="couponDetail.location_ids.length === 0">
                <span [translate]="'resources.coupons.modal.show.details.fields.all_locations'"></span>
              </td>
              <td>
                <ul class="ul-style">
                  <li *ngFor="let item of couponDetail.locations | slice: 0:4">
                    {{item.name }}
                  </li>
                </ul>
              </td>
            </ng-container>
            <ng-container
              *ngIf="couponDetail.location_ids && couponDetail.location_ids.length === 0 && (couponDetail.feature_location_ids && couponDetail.feature_location_ids.length > 0 || !couponDetail.location_ids)">
              <td><b [translate]="'resources.coupons.modal.show.details.fields.feature_locations'"></b>
                <b *ngIf="couponDetail.feature_location_ids.length > 0">
                  ({{couponDetail.feature_location_ids.length}})</b>
              </td>
              <td>
                <ul class="ul-style">
                  <li *ngFor="let item of couponDetail._embedded.location_taxonomy_terms| slice: 0:4">
                    {{item.name }}
                  </li>
                </ul>
              </td>
            </ng-container>
          </tr>
          <tr>
            <td><b [translate]="'resources.coupons.modal.show.details.fields.funded'"></b></td>
            <td *ngIf="couponDetail.funded"><span><i class="fa fa-check text-success"></i></span></td>
            <td *ngIf="!couponDetail.funded"><span><i class="fa fa-times text-danger"></i></span></td>
          </tr>
          <tr>
            <td><b [translate]="'resources.coupons.modal.show.details.fields.auto_select'"></b></td>
            <td *ngIf="couponDetail.auto_select"><span><i class="fa fa-check text-success"></i></span></td>
            <td *ngIf="!couponDetail.auto_select"><span><i class="fa fa-times text-danger"></i></span></td>
          </tr>
          <tr *ngIf="couponDetail.provider_id">
            <td><b [translate]="'resources.coupons.modal.show.details.fields.provider_id'"></b></td>
            <td>{{couponDetail.provider_id}}</td>
          </tr>
          <tr *ngIf="couponDetail.max_redemptions">
            <td><b [translate]="'resources.coupons.modal.show.details.fields.max_redemptions'"></b></td>
            <td>{{couponDetail.max_redemptions}}</td>
          </tr>
          <tr>
            <td><b [translate]="'resources.coupons.modal.show.details.fields.coupon_estimations'"></b></td>
            <td *ngIf="!showEstimations"><b (click)="seeEstimations()"
                [translate]="'resources.coupons.modal.show.details.fields.see_estimations'"></b></td>
            <td *ngIf="showEstimations">
              <ul class="ul-style estimations-style">
                <li><b [translate]="'resources.coupons.modal.show.details.fields.estimated_customer_redemption'"></b>:
                  {{ couponDetail.estimated_customer_redemption }}</li>
                <li><b [translate]="'resources.coupons.modal.show.details.fields.estimated_redemption'"></b>: {{
                  couponDetail.estimated_redemption }}</li>
                <li><b [translate]="'resources.coupons.modal.show.details.fields.estimated_delivery'"></b>: {{
                  couponDetail.estimated_delivery }}</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="border top" *ngIf="couponDetail.locations && couponDetail.locations.length > 4">
        <div class="pdd-vertical-15 pdd-horizon-20 text-center">
          <a data-toggle="modal" data-target="#modal-locations" class="text-semibold text-dark pointer">
            <span class="btn btn-light" [translate]="'resources.coupons.modal.show.buttons.show_all_locations'"></span>
          </a>
        </div>
      </div>

      <div class="border top" *ngIf="couponDetail.feature_location_ids && couponDetail.feature_location_ids.length > 4">
        <div class="pdd-vertical-15 pdd-horizon-20 text-center">
          <a data-toggle="modal" data-target="#modal-featureLocations" class="text-semibold text-dark pointer">
            <span class="btn btn-light"
              [translate]="'resources.coupons.modal.show.buttons.show_all_features_location'"></span>
          </a>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- END BLOQUE2 -->
