import { Injectable } from '@angular/core';
import { SegmentConditionProvider } from '../../condition.service';
import { TranslateService } from '@ngx-translate/core';
import { QuestionBase } from '../../../../models/forms/question-base';
import { OperatorsService } from '../../common/operators.service';
import { Option } from '../../../../models/common/option';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { DateService } from '../../../date.service';
import { multiselectPresenceValidator } from '../../../validations.service';
import { MultiSelectQuestion } from '../../../../models/forms/question-multiselect';
import { CustomEntitiesService } from '../../../../../resources/customer-data-platform/custom-entities/services/custom-entities.service';
import { CustomEntityKey } from './enums/custom-entity-key.enums';
import { TextboxQuestion } from '../../../../models/forms/question-textbox';
import { FloatQuestion } from '../../../../models/forms/question-float';
import { DynamicDateQuestion } from '../../../../models/forms/question-dynamic-date';
import { POSITIVE_INTEGER_REGEX } from '../../constants/condition.constants';
import { CustomEntityCondition, CustomEntityConditionForm } from '../../../../models/segments/conditions/custom-entity-condition';
import { parseCustomEntityFormValuesFromAPI } from './parsers/form-from-api.parser';
import { parseCustomEntityFormValuesToAPI } from './parsers/form-to-api.parser';
import { getCustomEntitiesConditionFormGroupValidations } from './validations/form.validations';

@Injectable()
export class CustomEntityConditionService implements SegmentConditionProvider {

  inputs: QuestionBase<unknown>[];
  customValidators = [(control: UntypedFormGroup) => getCustomEntitiesConditionFormGroupValidations(control)];

  constructor(
    private translate: TranslateService,
    private operatorsService: OperatorsService,
    private customEntitiesService: CustomEntitiesService,
    private dateService: DateService
  ) {}

  getInputs(params?: CustomEntityConditionForm): QuestionBase<unknown>[] {
    const definitionId = params?.loaded_definition_id || params?.definition_id?.[0]?.rawElement?.['id'];
    const isQuantityBtOperator = this.isOperator('bt', params, CustomEntityKey.QuantityOperator);

    const inputs = [
      new MultiSelectQuestion({
        key: CustomEntityKey.DefinitionId,
        label: this.translate.instant('resources.segment_conditions.fields.custom_entity'),
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        dataSource: this.customEntitiesService,
        selectedIds: definitionId ? [definitionId] : [],
        required: true,
        customValidators: [multiselectPresenceValidator]
      }),
      new MultiSelectQuestion({
        key: CustomEntityKey.Attribute,
        label: this.translate.instant('resources.segment_conditions.fields.custom_entity_attribute'),
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        options: [], // set on component as it is parsed from custom entities datasource
        customValidators: [multiselectPresenceValidator]
      }),
      new TextboxQuestion({
        key: CustomEntityKey.AttributeType,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.type'),
        type: 'text',
        value: params?.entity_attribute_type ?? null
      }),
      new MultiSelectQuestion({
        key: CustomEntityKey.StringOperator,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorsService.getStringOperators(),
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false }
      }),
      new MultiSelectQuestion({
        key: CustomEntityKey.StringValueMultiselect,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        options: [],
        settings: { singleSelection: true, showCheckbox: true, enableSearchFilter: false },
        customValidators: [multiselectPresenceValidator]
      }),
      new TextboxQuestion({
        key: CustomEntityKey.StringValue,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        type: 'text'
      }),
      new MultiSelectQuestion({
        key: CustomEntityKey.IntegerOperator,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorsService.getNumericOperators(),
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false }
      }),
      new FloatQuestion({
        key: CustomEntityKey.IntegerValue,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        type: 'number',
        step: 1
      }),
      new FloatQuestion({
        key: CustomEntityKey.IntegerValue2,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.value2'),
        type: 'number',
        step: 1
      }),
      new MultiSelectQuestion({
        key: CustomEntityKey.FloatOperator,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorsService.getNumericOperators(),
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false }
      }),
      new FloatQuestion({
        key: CustomEntityKey.FloatValue,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        type: 'number',
        step: 0.01
      }),
      new FloatQuestion({
        key: CustomEntityKey.FloatValue2,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.value2'),
        type: 'number',
        step: 0.01
      }),
      new MultiSelectQuestion({
        key: CustomEntityKey.BooleanOperator,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorsService.getEqualityOperators(),
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false }
      }),
      new MultiSelectQuestion({
        key: CustomEntityKey.BooleanValue,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        options: this.getBooleanValueOptions(),
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false }
      }),
      new MultiSelectQuestion({
        key: CustomEntityKey.DateOperator,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorsService.getNumericOperators(),
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false }
      }),
      new DynamicDateQuestion({
        key: CustomEntityKey.DateValue,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.date')
      }),
      new DynamicDateQuestion({
        key: CustomEntityKey.DateValue2,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.date2')
      }),
      new DynamicDateQuestion({
        key: CustomEntityKey.DateFrom,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.date_from'),
        value: params?.date_from ?? null
      }),
      new DynamicDateQuestion({
        key: CustomEntityKey.DateTo,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.date_to'),
        value: params?.date_to ?? null
      }),
      new MultiSelectQuestion({
        key: CustomEntityKey.QuantityOperator,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorsService.getNumericOperators(),
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        required: true,
        value: params ? params.quantity_operator ?? null : [this.operatorsService.getNumericOperators().find(item => item.id === 'gteq')],
        customValidators: [multiselectPresenceValidator]
      }),
      new FloatQuestion({
        key: CustomEntityKey.QuantityValue,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        type: 'number',
        step: 1,
        min: 0,
        helpText: this.translate.instant('resources.segment_conditions.hints.minimum_value_must_be', { number: '0' }),
        required: true,
        value: params ? params.quantity_value ?? null : 1,
        customValidators: [Validators.pattern(POSITIVE_INTEGER_REGEX), ]
      }),
      new FloatQuestion({
        key: CustomEntityKey.QuantityValue2,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.value2'),
        type: 'number',
        step: 1,
        min: 1,
        required: isQuantityBtOperator,
        value: params?.quantity_value2 ?? null,
        customValidators: [Validators.pattern(POSITIVE_INTEGER_REGEX)]
      })
    ];

     this.inputs = inputs;
     return this.inputs;
  }

  prepareFormValuesToAPI(params: CustomEntityConditionForm): CustomEntityCondition {
    return parseCustomEntityFormValuesToAPI(params);
  }

  prepareFormValuesFromAPI(params: CustomEntityCondition): CustomEntityConditionForm {
    return parseCustomEntityFormValuesFromAPI(params, this.operatorsService, this.dateService);
  }

  private isOperator(operator: string, params: CustomEntityConditionForm, operatorType: string): boolean {
    return !!(params?.[operatorType]?.length && params?.[operatorType]?.id === operator);
  }

  private getBooleanValueOptions(): Option[] {
    return [
      { id: false, name: this.translate.instant('resources.segment_conditions.options.false')},
      { id: true, name: this.translate.instant('resources.segment_conditions.options.true')}
    ];
  }
}
