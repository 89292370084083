<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="table-overflow">
            <table class="table table-hover" aria-hidden="true" *ngIf="element">
              <tbody>
                <tr *ngIf="element.fullname">
                  <td class="no-border-top"><strong [translate]="'resources.users.fields.name'"></strong></td>
                  <td class="no-border-top">{{element.fullname}}</td>
                </tr>
                <tr *ngIf="element.email">
                  <td><strong [translate]="'resources.users.fields.email'"></strong></td>
                  <td>{{element.email}}</td>
                </tr>
                <tr>
                  <td><strong [translate]="'resources.users.fields.country'"></strong></td>
                  <td>{{countryName | emptyData}}</td>
                </tr>
                <tr *ngIf="element.role">
                  <td><strong [translate]="'resources.users.fields.role_name'"></strong></td>
                  <td>{{element.role.name}}</td>
                </tr>
                <tr>
                  <td><strong [translate]="'resources.users.fields.preferred_language'"></strong></td>
                  <td>{{languageName | emptyData}}</td>
                </tr>
                <tr *ngIf="element.status">
                  <td><strong [translate]="'resources.users.fields.status'"></strong></td>
                  <td>
                    <span class="label font-size-12"
                          [ngClass]="{'label-info': element.status === 'active',
                                      'label-danger': element.status === 'blocked' || element.status === 'inactive'
                                     }">
                      {{'resources.users.status.' + element.status | translate}}
                    </span>
                    <button *ngIf="isAuthorized('admin, user_manager') && element.status === 'blocked'"
                            class="pull-right btn btn-info"
                            (click)="unblockUser()"
                            [translate]="'resources.users.buttons.unblock'">
                    </button>
                  </td>
                </tr>
                <tr *ngIf="flags.showWalmartPOC && element?.partner" class="tr-border-top">
                  <td><strong [translate]="'resources.users.fields.partner_id'"></strong></td>
                  <td>{{ element?.partner?.name | emptyData }}</td>
                </tr>
                <tr *ngIf="!flags.showWalmartPOC && element?.suppliers?.length" class="tr-border-top">
                  <td><strong [translate]="'resources.users.fields.supplier_ids'"></strong></td>
                  <td>
                    <ul class="list arrow bullet-success">
                      <li *ngFor="let supplier of element.suppliers">
                        {{ supplier.name }}
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr *ngIf="element.authtoken">
                  <td><strong [translate]="'resources.users.fields.authtoken'"></strong></td>
                  <td class="word-break">{{element.authtoken}}</td>
                </tr>
                <tr *ngIf="(element.role.slug !== 'franchise' && element.role.slug !== 'manager')">
                  <td><strong [translate]="'resources.users.fields.location_ids'"></strong></td>
                  <td>{{'common.all_feminine' | translate}}</td>
                </tr>
                <tr *ngIf="(element.role.slug === 'franchise' || element.role.slug === 'manager') && element?.locations?.length" class="tr-border-top">
                  <td><strong [translate]="'resources.users.fields.location_ids'"></strong></td>
                  <td>
                    <ul class="list arrow bullet-success">
                      <li *ngFor="let location of element.locations">
                        {{ location }}
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
