<a #uploadCSV data-toggle="modal" data-target="#grantPoints-modal" hidden></a>
  <div class="modal fade show" id="grantPoints-modal">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4> Upload Translations </h4>
        </div>
        <div class="modal-body background-gray">
          <div class="col-md-12 ml-auto mr-auto">

            <div class="row mrg-top-10">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="csv" class="font-size-14 text-bold" [translate]="'resources.coupons.form.csv'"></label>
                  <app-question [question]="getInputConfig('csv_file')" [form]="uploadCsvForm"></app-question>
                </div>
                <div class="alert alert-info d-flex align-items-center">
                  <em class="text-bold fa-light fa-exclamation-circle warning_icon_position mrg-right-10 font-size-16"></em>
                  <p class="font-size-14">{{'demo.configure_translations.upload_instructions' | translate}}<br>
                    {{ 'resources.coupons.modal.import.download_template'| translate }}
                    <a href="https://loyalguru-imports.s3.eu-west-1.amazonaws.com/csv_example.csv" target="_blank"><span class="color pointer text-bold">{{ 'resources.coupons.modal.import.here_link'| translate }}</span></a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- FOOTER -->
        <div class="modal-footer background-white align-content-center">
          <div class="pull-right d-flex align-items-center">
            <span class="mrg-right-10">
            </span>
            <button class="btn btn-default" data-dismiss="modal"
                    [translate]="'common.buttons.cancel'">
            </button>
            <button class="btn btn-info mrg-right-10"
                    [translate]="'common.buttons.save'">
            </button>
          </div>
        </div>
        <!-- END FOOTER -->
      </div>
    </div>
  </div>
