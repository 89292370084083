import { Injectable } from '@angular/core';
import { DataTableConfiguration } from '../components/data-table/data-table-cfg';
import { TranslateService } from '@ngx-translate/core';
import { isNullOrUndefined } from '../utils/common.utils';
import { CouponsAppAnalysisGroupedResults, ReportCouponsAppAnalysisCalculatedData, ReportCouponsAppAnalysisHeader } from '../../resources/analytics/reports/shows/show-report-coupons-in-app-analysis/model/report-coupons-in-app-analysis.model';

@Injectable()
export class CsvExportService {

  constructor(
    private translate: TranslateService
  ) { }

  getCsvBlob(csvString: string): Blob {
    return new Blob(['\ufeff', csvString], { type: 'text/csv' });
  }

  prepareAriAsStringForCsv(tableCfg: DataTableConfiguration, objArray: Object[]): string {
    let stringCsv = '';
    const headers = Object.keys(objArray[0]);

    headers.forEach(headerKey => {
      let translatedKey;
      if (tableCfg.requestData.translationResource) {
        translatedKey = this.translate.instant(tableCfg.requestData.translationResource + headerKey);
      } else {
        translatedKey = this.translate.instant('resources.' + tableCfg.requestData.apiEndPoint + '.columns.' + headerKey);
      }
      stringCsv += translatedKey + ';';
    });
    stringCsv += '\n';

    objArray.forEach(csvRow => {
      const item = this.mapExportItem(tableCfg, csvRow);
      headers.forEach(headerKey => {
        stringCsv += '"' + item[headerKey] + '";';
      });
      stringCsv += '\n';
    });

    return stringCsv;
  }

  prepareCsvStringByColumnHeaderTypes(
    headers: ReportCouponsAppAnalysisHeader[],
    objArray: CouponsAppAnalysisGroupedResults[],
    parseValueCallback: Function,
    currencySymbol: string = '€'
  ): string {
    let stringCsv = '';
    const baseHeaders = headers.filter(header => header.csv !== false);
    baseHeaders.forEach(header => {
      stringCsv += this.translate.instant(header.translateKey, {currencySymbol: currencySymbol}) + ';'
    });
    stringCsv += '\n';
    objArray.forEach(rowObj => {
      rowObj.results.forEach(csvRow => {
        baseHeaders.forEach(header => {
          stringCsv += parseValueCallback(csvRow, header) + ';';
        });
        stringCsv += '\n';
      });
      baseHeaders.forEach(header => {
        stringCsv += parseValueCallback(rowObj.total_result, header, rowObj.results) + ';';
      });
      stringCsv += '\n';
    });
    return stringCsv;
  }

  replaceDotWithChar(string: string, newChar: string, rexp = /\./g) {
    return string.replace(rexp, newChar);
  }

  parseNumericValue(value, type: 'float' | 'integer') {
    if (isNullOrUndefined(value) || value === 0) return '0';
    if (type === 'float') {
      return value ? this.replaceDotWithChar(parseFloat(value).toFixed(2), ',') : '';
    } else if (type === 'integer') {
      return value ? this.replaceDotWithChar(parseInt(value, 10).toString(), ',') : '';
    }
    return value.toString();
  }

  private mapExportItem(tableCfg: DataTableConfiguration, item): Object {
    const mappedItem = {};
    tableCfg.tableMapping.map(itemMapping => {
        mappedItem[itemMapping['prop']] = this.getExportValue(item, itemMapping);
      }
    );
    return mappedItem;
  }

  private getExportValue(item, mapping) {
    const property = mapping['prop'];
    let parsedValue = item[property];

    if (mapping['type'] === 'image') {parsedValue = item[property]?.src ? item[property].src : ''};
    if (mapping.hasOwnProperty('link')) {parsedValue = item[property].parsedValue};
    if (mapping.hasOwnProperty('icon')) {parsedValue = item[property].parsedValue};
    if (mapping.hasOwnProperty('badge')) {parsedValue = item[property].badge};

    if (parsedValue?.hasOwnProperty('innerHTML')) {
      let htmlContent = parsedValue['innerHTML'];
      htmlContent = htmlContent.replace(/<strong>/g, '')
      htmlContent = htmlContent.replace(/<\/strong>/g, '')
      htmlContent = htmlContent.replace(/<br>/g, ', ')
      parsedValue = htmlContent;
    }

    if (typeof parsedValue === 'object' && parsedValue !== null && parsedValue.hasOwnProperty('parsedValue')) {
      parsedValue = parsedValue.parsedValue;
    }

    if (typeof parsedValue === 'object' && parsedValue !== null && parsedValue.hasOwnProperty('icons')) {
      parsedValue = parsedValue.icons.map(el => el.alt).join(', ');
    }

    return parsedValue ?? '';
  }
}
