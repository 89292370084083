import { ActivatedRoute, Router } from '@angular/router';
import { Cms } from '../../../../shared/models/cms/cms';
import { CmsService } from '../cms.service';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { DateService } from '../../../../shared/services/date.service';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { SharedModule } from '../../../../shared/shared.module';
import { TranslateService } from '@ngx-translate/core';
import { Modules } from '../../../../shared/enums/modules.enums';

@Component({
  selector: 'app-form-cms',
  templateUrl: './form-cms.component.html',
  styleUrls: ['./form-cms.component.css'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, SharedModule],
  providers: [CmsService]
})
export class FormCmsComponent implements OnInit {

  cmsData: Cms;
  cmsForm: UntypedFormGroup;
  id: number;
  imageData: { filename: unknown; filesize: unknown; filetype: unknown; base64: string; };
  inputs: QuestionBase<unknown>[];
  isMissingImg: string;
  isPersonalizationCampaignsModule = this.route.snapshot?.['_routerState']?.url?.split('/')?.[1] === Modules.PersonalizationCampaigns;
  loading: boolean;
  thumbnail: string;

  constructor(
    private cmsService: CmsService,
    private confirmationService: ConfirmationService,
    private dateService: DateService,
    private modalStatusService: ModalStatusService,
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
  ) {
    this.cmsData = {
      availableTo: '',
      body:  '',
      createdAt:  '',
      publishAt:  '',
      thumbnail:  '',
      title:  ''
    };
  }

  ngOnInit() {
    this.getParams();
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.imageData = {
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
          base64: reader.result.toString().split(',')[1]
        };
      };
    }
  }

  sendData() {
    const cmsOptions = {
      publish_at: this.cmsForm.value.publish_at ? this.cmsForm.value.publish_at : null,
      available_to: this.cmsForm.value.available_to ? new Date(this.cmsForm.value.available_to) : null,
      news_taxonomy_terms: this.cmsForm.value.news_taxonomy_terms ? this.cmsForm.value.news_taxonomy_terms.map(el => el.rawElement) : [],
      id: this.id,
      title: this.cmsData.title,
      body: this.cmsData.body,
    };

    if (this.imageData) { cmsOptions['image'] = this.imageData; }

    if (this.cmsForm.value.publish_at && this.cmsForm.value.available_to) {
      cmsOptions['available_period'] = 'Del ' + this.dateService.momentFormat(this.cmsForm.value.publish_at, 'DD/MM/YYYY' ) + ' al ' + this.dateService.momentFormat(this.cmsForm.value.available_to, 'DD/MM/YYYY');
    } else if (this.cmsForm.value.publish_at) {
      cmsOptions['available_period'] = 'A partir del ' + this.dateService.momentFormat(this.cmsForm.value.publish_at, 'DD/MM/YYYY');
    } else if (this.cmsForm.value.available_to) {
      cmsOptions['available_period'] = 'Hasta el ' + this.dateService.momentFormat(this.cmsForm.value.available_to, 'DD/MM/YYYY');
    } else {
      cmsOptions['available_period'] = 'Siempre';
    }

    if (this.id) {
      this.loading = true;
      this.thumbnail = cmsOptions['thumbnail'] = this.thumbnail;

      this.cmsService.updateData(cmsOptions, this.id).subscribe(response => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.cms.form.warnings.update_title'),
          this.translate.instant('resources.cms.form.warnings.update_text')
        ).catch(() => {});
        this.loading = false;
        this.modalStatusService.modalStatus.emit();
        this.returnToList();
      },
        errorData => {
          this.loading = false;
          this.confirmationService.displayErrorAlert('Error', errorData.error.error);
        });
    } else {
      this.loading = true;
      this.cmsService.sendData(cmsOptions).subscribe(response => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.cms.form.warnings.success_title'),
          this.translate.instant('resources.cms.form.warnings.success_text')
        ).catch(() => {});
        this.loading = false;
        this.modalStatusService.modalStatus.emit();
        this.returnToList();
      },
        errorData => {
          this.loading = false;
          this.confirmationService.displayErrorAlert('Error', errorData.error.error);
        });
    }
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getCmsById(this.id);
      } else {
        this.inputs = this.cmsService.setInputs({});
        this.cmsForm = this.qcs.toFormGroup(this.inputs);
      }
    });
  }

  private getCmsById(id: number) {
    this.cmsService.getById(this.id).subscribe(
      data => {
        this.cmsData = data;
        this.thumbnail = data['thumbnail'];
        if (this.thumbnail === 'images/thumbnail/missing.png') {
          this.isMissingImg = this.thumbnail;
        }
        this.inputs = this.cmsService.setInputs(data);
        this.cmsForm = this.qcs.toFormGroup(this.inputs);
      }
    );
  }

  private returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else if (this.isPersonalizationCampaignsModule) {
      this.router.navigate([`/${Modules.PersonalizationCampaigns}/templates/cms`]).catch(() => {});
    } else {
      this.router.navigate(['/omnichannel-manager/cms']).catch(() => {});
    }
  }

}
