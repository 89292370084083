import { AttributesConditionsService } from '../services/attributes-conditions.service';
import { AverageActivityConditionService } from '../services/conditions/average-activity-condition.service';
import { CampaignConditionService } from '../services/conditions/campaign-condition.service';
import { CampaignHistoriesService } from '../../resources/campaigns/campaign-histories.service';
import { CampaignsService } from '../../resources/campaigns/campaigns.service';
import { CampaignTemplatesService } from '../components/create-update-templates/service/campaign-templates-service.service';
import { CityConditionService } from '../services/conditions/services/city-condition/city-condition.service';
import { ClientBirthdayConditionService } from '../services/conditions/client-birthday-condition.service';
import { ClientEmailConditionService } from '../services/conditions/client-email-condition.service';
import { ClientIdConditionService } from '../services/conditions/client-id-condition.service';
import { ClientRegistrationDateConditionService } from '../services/conditions/services/client-registration-date-condition/client-registration-date-condition.service';
import { ClientUsualLocationConditionService } from '../services/conditions/client-usual-location-condition.service';
import { CmsService } from '../../resources/content-designer/campaign-news-list/cms.service';
import { CollectiveConditionService } from '../services/conditions/collective-condition.service';
import { CollectivesService } from '../../resources/data-warehouse/profiles/collectives.service';
import { CommonModule } from '@angular/common';
import { ConditionService } from '../services/conditions/condition.service';
import { ConfirmationService } from '../services/confirmation.service';
import { CouponConditionService } from '../services/conditions/coupon-condition.service';
import { CouponHistoriesService } from '../../resources/coupons/coupon-histories.service';
import { CouponsService } from '../../resources/coupons/coupons.service';
import { CreateUpdateSegmentsCSVComponent } from '../components/create-update-segments-csv/create-update-segments-csv.component';
import { CreateUpdateSegmentsV2Component } from '../components/create-update-segments-v2/create-update-segments-v2.component';
import { CurrencyConversionsService } from '../../resources/config/currencies/currency-conversions.service';
import { CustomerJourneyShowComponent } from '../../resources/customer-journeys/customer-journey-show/customer-journey-show.component';
import { CustomerJourneysService } from '../../resources/customer-journeys/customer-journeys.service';
import { DashCategoriesService } from '../../resources/analytics/categories/dash-categories.service';
import { DashPromotionsService } from '../../resources/analytics/promotions/dashPromotions.service';
import { DateService } from '../services/date.service';
import { DndModule } from 'ngx-drag-drop';
import { EmailTemplatesService } from '../services/email-templates.service';
import { FeatureInternalsService } from '../../resources/data-warehouse/products/services/products-internal-features/feature-internals.service';
import { FeaturesService } from '../../resources/data-warehouse/products/services/products-features/features.service';
import { FeatureTaxonomiesService } from '../../resources/data-warehouse/products/services/products-feature-taxonomies/feature-taxonomies.service';
import { FidelizationService } from '../../resources/analytics/fidelization/fidelization.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenderConditionService } from '../services/conditions/services/gender-condition/gender-condition.service';
import { GroupConditionService } from '../services/conditions/group-condition.service';
import { HighchartsConfigService } from '../services/highcharts-config.service';
import { ImportRewardCodesComponent } from '../../resources/scores/rewards/show-rewards/import-reward-codes/import-reward-codes.component';
import { LastPurchaseConditionService } from '../services/conditions/services/last-purchase-condition/last-purchase-condition.service';
import { LoyaltyScoreMomentsService } from '../../resources/scores/loyalty_scores/loyalty_scores_moments/loyalty-score-moments.service';
import { LoyaltyScoresService } from '../../resources/scores/loyalty_scores/loyalty-scores.service';
import { MailConditionService } from '../services/conditions/mail-condition.service';
import { MatrixService } from '../services/matrix.service';
import { MaxSalesConditionService } from '../services/conditions/max-sales-condition.service';
import { ModalsRoutingModule } from './modals-routing.module';
import { MulticouponJourneyService } from '../services/multicoupon-journey.service';
import { NameConditionService } from '../services/conditions/services/name-condition/name-condition.service';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { OperatorsService } from '../services/conditions/common/operators.service';
import { PlanAnalysisComponent } from '../../resources/plans/campaigns/plan-analysis/plan-analysis.component';
import { PlanCampaignActionService } from '../../resources/plans/campaigns/custom-campaign-plan-audience/plan-campaign-action.service';
import { PlanDetailComponent } from '../../resources/plans/plan-detail/plan-detail.component';
import { PlanRoiDetailComponent } from '../../resources/plans/plan-roi-detail/plan-roi-detail.component';
import { PostalCodeConditionService } from '../services/conditions/postal-code-condition.service';
import { PotentialJourneyService } from '../services/potential-journey.service';
import { ProductsService } from '../../resources/data-warehouse/products/services/products/products.service';
import { PushTemplatesService } from '../services/push-templates.service';
import { QuestionControlService } from '../services/question-control.service';
import { RecuperationJourneyService } from '../services/recuperation-journey.service';
import { RedirectionService } from '../services/redirection.service';
import { RefreshCacheService } from '../services/refresh-cache.service';
import { ReturnsConditionService } from '../services/conditions/services/returns-condition/returns-condition.service';
import { RewardCategoriesService } from '../../resources/scores/rewards/reward-categories/reward-categories.service';
import { RewardCodesBatchDetailComponent } from '../../resources/scores/rewards/show-rewards/reward-codes-batch-detail/reward-codes-batch-detail.component';
import { RewardCodesComponent } from '../../resources/scores/rewards/show-rewards/reward-codes/reward-codes.component';
import { RewardDetailsComponent } from '../../resources/scores/rewards/show-rewards/reward-details/reward-details.component';
import { RewardLocalizedContentComponent } from '../../resources/scores/rewards/show-rewards/reward-localized-content/reward-localized-content.component';
import { RewardsService } from '../../resources/scores/rewards/rewards.service';
import { RewardStockDetailsComponent } from '../../resources/scores/rewards/show-rewards/reward-stock/reward-stock-details.component';
import { RolesService } from '../../resources/users/roles.service';
import { RouterModule } from '@angular/router';
import { SegmentCategoriesService } from '../../resources/segments/segment-categories.service';
import { SegmentsService } from '../../resources/segments/segments.service';
import { SharedModule } from '../shared.module';
import { ShowCampaignsStatisticsComponent } from '../../resources/campaigns/campaign-manual/show-campaigns-statistics/show-campaigns-statistics.component';
import { ShowTicketsComponent } from '../../resources/data-warehouse/tickets/show/show-tickets.component';
import { SmsTemplatesService } from '../services/sms-templates.service';
import { SqlConditionService } from '../services/conditions/sql-condition.service';
import { StateConditionService } from '../services/conditions/services/state-condition/state-condition.service';
import { TagActivitiesService } from '../services/tag-activities.service';
import { TagHistoriesService } from '../services/tag-histories.service';
import { TagSalesService } from '../services/tag-sales.service';
import { TaxonomiesCountConditionService } from '../services/conditions/services/taxonomies-count-condition/taxonomies-count-condition.service';
import { TemplatesService } from '../services/templates.service';
import { TicketsConditionService } from '../services/conditions/services/tickets-condition/tickets-condition.service';
import { TiersService } from '../../resources/scores/rewards/tiers.service';
import { UsersService } from '../../resources/users/users.service';
import { V1SegmentsService } from '../../resources/segments/v1-segments.service';
import { VoucherCountConditionService } from '../services/conditions/services/voucher-count-condition/voucher-count-condition.service';
import { WalletsService } from '../../resources/scores/wallets.service';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    DndModule,
    ModalsRoutingModule,
    NgSelectModule,
    FormsModule,
    NgxDatatableModule,
    ReactiveFormsModule,
  ],
  declarations: [
    CreateUpdateSegmentsV2Component,
    CreateUpdateSegmentsCSVComponent,
    CustomerJourneyShowComponent,
    ShowTicketsComponent,
    ShowCampaignsStatisticsComponent,
    PlanDetailComponent,
    PlanRoiDetailComponent,
    RewardDetailsComponent,
    RewardLocalizedContentComponent,
    RewardStockDetailsComponent,
    RewardCodesComponent,
    ImportRewardCodesComponent,
    RewardCodesBatchDetailComponent,
    PlanAnalysisComponent
  ],
  providers: [
    AttributesConditionsService,
    AverageActivityConditionService,
    CampaignConditionService,
    CampaignHistoriesService,
    CampaignsService,
    CampaignTemplatesService,
    CityConditionService,
    ClientBirthdayConditionService,
    ClientEmailConditionService,
    ClientIdConditionService,
    ClientRegistrationDateConditionService,
    ClientUsualLocationConditionService,
    CmsService,
    CollectiveConditionService,
    CollectivesService,
    ConditionService,
    ConfirmationService,
    CouponConditionService,
    CouponHistoriesService,
    CouponsService,
    CurrencyConversionsService,
    CustomerJourneysService,
    DashCategoriesService,
    DashPromotionsService,
    DashPromotionsService,
    DateService,
    EmailTemplatesService,
    FeatureInternalsService,
    FeaturesService,
    FeatureTaxonomiesService,
    FidelizationService,
    GenderConditionService,
    GroupConditionService,
    HighchartsConfigService,
    LastPurchaseConditionService,
    LoyaltyScoreMomentsService,
    LoyaltyScoresService,
    MailConditionService,
    MatrixService,
    MatrixService,
    MaxSalesConditionService,
    MulticouponJourneyService,
    MulticouponJourneyService,
    NameConditionService,
    OperatorsService,
    PlanCampaignActionService,
    PostalCodeConditionService,
    PostalCodeConditionService,
    PotentialJourneyService,
    ProductsService,
    PushTemplatesService,
    QuestionControlService,
    RecuperationJourneyService,
    RedirectionService,
    RefreshCacheService,
    ReturnsConditionService,
    RewardCategoriesService,
    RewardsService,
    RolesService,
    SegmentCategoriesService,
    SegmentsService,
    SmsTemplatesService,
    SqlConditionService,
    StateConditionService,
    TagActivitiesService,
    TagHistoriesService,
    TagSalesService,
    TaxonomiesCountConditionService,
    TemplatesService,
    TicketsConditionService,
    TiersService,
    TiersService,
    UsersService,
    V1SegmentsService,
    VoucherCountConditionService,
    WalletsService
  ],
  exports: [
    RouterModule,
    CreateUpdateSegmentsV2Component,
    CreateUpdateSegmentsCSVComponent,
    CustomerJourneyShowComponent
  ]
})


export class ModalsModule { }
