<ng-container [ngSwitch]="header.key">
  <ng-container *ngSwitchCase="'auto_redeem'">
    <ng-container *ngIf="item[header.key]; else notAutoRedemable">
      <span class="text-success"><i class="fa-solid fa-check"></i></span>
    </ng-container>
    <ng-template #notAutoRedemable>
      <span class="text-danger"><i class="fa-solid fa-x"></i></span>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'campaign_category'">
    <ng-container *ngIf="containsPlansPrefix(item[header.key]); else notTranslatable">
      {{ 'resources.campaign_plans.types.dictionary.' + item[header.key] | translate }}
    </ng-container>
    <ng-template #notTranslatable>
      {{item[header.key]}}
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'campaign_id'">
    <a (click)="goToCampaign(item.campaign_id)">
      {{item.campaign_id}}
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'campaign_history_id'">
    <a (click)="goToCampaignHistory(item.campaign_id, item.campaign_history_id)">
      {{item.campaign_history_id}}
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'plan_id'">
    <ng-container *ngIf="item.plan_id; else noPlan">
      <a (click)="goToPlan(item.plan_id)">
        {{item.plan_id}}
      </a>
    </ng-container>
    <ng-template #noPlan>-</ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'coupon_id'">
    <a (click)="goToCoupon(item.coupon_id)">
      {{item.coupon_id}}
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'coupon_discount'">
    <ng-container *ngIf="item.coupon_discount_type === 'cash'">
      {{ item.coupon_discount | currency: currencySymbol }}
    </ng-container>
    <ng-container *ngIf="item.coupon_discount_type === 'percent'">
      {{ item.coupon_discount | number:'1.2-2'}} %
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'coupon_name'">
    {{ item.campaign_name | lgMultilanguageTranslate}}
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngIf="isString(item[header.key]); else noString">
      {{item[header.key] | translate}}
    </ng-container>
    <ng-template #noString>
      <ng-container *ngIf="isType(header.key, 'percentage')">
        {{item[header.key] | percent: '1.2-2': 'es'}}
      </ng-container>
      <ng-container *ngIf="isType(header.key, 'integer')">
        {{item[header.key] | number: '1.0-0': 'es'}}
      </ng-container>
      <ng-container *ngIf="isType(header.key, 'float')">
        {{item[header.key] | number: '1.2-2': 'es'}}
      </ng-container>
      <ng-container *ngIf="isType(header.key, 'currency_float')">
        {{item[header.key] | number: '1.2-2' }} {{currencySymbol}}
      </ng-container>
    </ng-template>
  </ng-container>
</ng-container>