<div class="modal fade modal-fs show" id="modal-fs" style="display: block">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <!-- MODAL HEADER -->
      <app-coupon-header-component
        [showCouponPreview]="flags.showCouponPreview"
        [mode]="{ mode, formMode }"
        (closeCoupon)="closeCoupon.emit()"
      />

      <!-- MODAL BODY -->
      <div class="modal-body height-100 background-gray overflow-auto">
        <!-- MODAL WARNINGS -->
        <app-coupon-form-warnings
          *ngIf="couponForm"
          [isUpdateHistory]="formMode === 'update_history_id'"
        >
        </app-coupon-form-warnings>

        <!-- MODAL FORM -->
        <div class="row mrg-top-10">
          <div [ngClass]="{'mr-auto': !flags.showCouponPreview}" class="col-md-8 ml-auto">
            <form [formGroup]="couponForm" *ngIf="couponForm">
              <!-- DESCRIPTION / BASIC INFO -->
              <app-coupon-form-section key="basic_info">
                <app-coupon-form-section-rows
                  [inputConfig]="getInputConfig"
                  [form]="couponForm"
                  [section]="couponSections.basic_info_1"
                />

                <div
                  class="row"
                  *ngIf="flags.couponsAvailableLanguagesVisibility"
                >
                  <div class="col-md-12">
                    <div class="form-group">
                      <app-language-accordions
                        [inputs]="inputs"
                        [form]="couponForm"
                        [mode]="mode"
                        [filteredAvailableLanguages]="
                          filteredAvailableLanguages
                        "
                      >
                      </app-language-accordions>
                    </div>
                  </div>
                </div>

                <ng-container
                  *ngIf="!flags.couponsAvailableLanguagesVisibility"
                >
                  <app-coupon-form-section-rows
                    [inputConfig]="getInputConfig"
                    [form]="couponForm"
                    [section]="couponSections.basic_info_2"
                  />
                </ng-container>

                <app-coupon-form-section-rows
                  [inputConfig]="getInputConfig"
                  [form]="couponForm"
                  [section]="couponSections.basic_info_3"
                  (onFileChange)="onFileChange($event)"
                >
                </app-coupon-form-section-rows>
              </app-coupon-form-section>

              <!-- DISCOUNT -->
              <app-coupon-form-section key="discount">
                <app-coupon-form-section-rows
                  [inputConfig]="getInputConfig"
                  [form]="couponForm"
                  [section]="couponSections.discount_1"
                  (onMultiselectChange)="handleMultiselectChange($event)"
                >
                  <ng-container
                    *ngIf="
                      flags.showCouponsCustomType &&
                      hasFormKeyWithValue('coupon_type', 'custom')
                    "
                  >
                    <div class="col-md-12">
                      <div class="form-group">
                        <ace
                          class="height-300"
                          [mode]="'json'"
                          theme="monokai"
                          [(value)]="entity_attributes"
                        >
                        </ace>
                      </div>
                      <div class="mrg-btm-10 helper-text-mrg">
                        <span
                          class="text-left font-size-12"
                          [innerHTML]="
                            'resources.custom_entities.messages.helper_json'
                              | translate
                          "
                        ></span>
                        <button
                          class="btn btn-xs pull-right btn-default"
                          (click)="presentTemplate('multi')"
                        >
                          Example: <strong>Multi-buy coupon</strong>
                        </button>
                        <button
                          class="btn btn-xs pull-right btn-default"
                          (click)="presentTemplate('percentage_based')"
                        >
                          Example: <strong>Percentage based</strong>
                        </button>
                        <button
                          class="btn btn-xs pull-right btn-default"
                          (click)="presentTemplate('buy_5_get_1')"
                        >
                          Example: <strong>Buy 5 get 1</strong>
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </app-coupon-form-section-rows>

                <!-- Affectation variants -->
                <ng-container
                  *ngIf="
                    !couponTypeSelected &&
                    hasFormKeyWithValue('affectation', 'attribute')
                  "
                >
                  <app-coupon-form-section-rows
                    [inputConfig]="getInputConfig"
                    [form]="couponForm"
                    [section]="couponSections.discount_2"
                    (onMultiselectChange)="handleMultiselectChange($event)"
                  >
                  </app-coupon-form-section-rows>
                </ng-container>

                <ng-container
                  *ngIf="
                    couponTypeSelected &&
                    hasFormKeyWithValue('affectation', 'attribute')
                  "
                >
                  <app-coupon-form-section-rows
                    [inputConfig]="getInputConfig"
                    [form]="couponForm"
                    [section]="couponSections.discount_3"
                  />
                </ng-container>

                <ng-container
                  *ngIf="
                    hasFormKeyWithValue('affectation', 'product') &&
                    flags.showCouponProductAffectation
                  "
                >
                  <app-coupon-form-section-rows
                    [inputConfig]="getInputConfig"
                    [form]="couponForm"
                    [section]="couponSections.discount_4"
                    (onMultiselectChange)="handleMultiselectChange($event)"
                  >
                    <div class="col-md-1">
                      <button
                        class="btn btn-default btn-icon import-product-btn"
                        type="button"
                        tooltipPosition="top"
                        pTooltip="{{
                          'resources.coupons.form.import_product_ids'
                            | translate
                        }}"
                        (click)="importProductsModal.openModal()"
                      >
                        <i class="ei-import"></i>
                      </button>
                    </div>
                  </app-coupon-form-section-rows>
                </ng-container>

                <!-- Feature / Products Exclusion (Demo) -->
                <ng-container
                  *ngIf="
                    flags.showCouponsCumulativeDiscount &&
                    hasFormKeyWithValue('affectation', 'attribute')
                  "
                >
                  <app-coupon-form-section-rows
                    [inputConfig]="getInputConfig"
                    [form]="couponForm"
                    [section]="couponSections.discount_6"
                    (onMultiselectChange)="handleMultiselectChange($event)"
                  >
                    <div class="col-md-1">
                      <button
                        class="btn btn-default btn-icon import-product-btn"
                        type="button"
                        tooltipPosition="top"
                        pTooltip="{{
                          'resources.coupons.form.exclude_product_ids'
                            | translate
                        }}"
                        (click)="importProductsModal.openModal()"
                      >
                        <i class="ei-import"></i>
                      </button>
                    </div>
                  </app-coupon-form-section-rows>
                </ng-container>
                <!-- END - Feature / Products Exclusion (Demo) - END -->

                <!-- End affectation variants -->
                <ng-container *ngIf="formMode !== 'update_history_id'">
                  <app-coupon-form-section-rows
                    [inputConfig]="getInputConfig"
                    [form]="couponForm"
                    [section]="couponSections.discount_5"
                  />
                </ng-container>

                <ng-container>
                  <app-coupon-form-section-rows
                    [inputConfig]="getInputConfig"
                    [form]="couponForm"
                    [section]="couponSections.discount_7"
                  />
                </ng-container>
              </app-coupon-form-section>

              <!-- POINTS -->
              <app-coupon-form-section
                key="points"
                *ngIf="flags.showPointsOptionDemoSelex"
              >
                <app-coupon-form-section-rows
                  [inputConfig]="getInputConfig"
                  [form]="couponForm"
                  [section]="couponSections.points"
                />
              </app-coupon-form-section>

              <!-- AVAILABILITY -->
              <app-coupon-form-section key="availability">
                <app-coupon-form-section-rows
                  [inputConfig]="getInputConfig"
                  [form]="couponForm"
                  [section]="couponSections.availability"
                />
              </app-coupon-form-section>

              <!-- PRINT CFG -->
              <app-coupon-form-section key="print_cfg">
                <app-coupon-form-section-rows
                  [inputConfig]="getInputConfig"
                  [form]="couponForm"
                  [section]="couponSections.print_cfg"
                />
              </app-coupon-form-section>

              <!-- REDEMPTION -->
              <app-coupon-form-section key="redemption">
                <app-coupon-form-section-rows
                  [inputConfig]="getInputConfig"
                  [form]="couponForm"
                  [section]="couponSections.redemption"
                  [currency]="currencySymbol"
                />
              </app-coupon-form-section>

              <!-- PREDICTION -->
              <app-coupon-form-section
                key="prediction"
                *ngIf="formMode !== 'update_history_id'"
              >
                <app-coupon-form-section-rows
                  [inputConfig]="getInputConfig"
                  [form]="couponForm"
                  [section]="couponSections.prediction"
                />
              </app-coupon-form-section>

              <!-- 3RD PARTY -->
              <app-coupon-form-section key="third_party">
                <app-coupon-form-section-rows
                  [inputConfig]="getInputConfig"
                  [form]="couponForm"
                  [section]="couponSections.third_party"
                />
              </app-coupon-form-section>

              <!-- INTEGRATION PARTY -->
              <app-coupon-form-section key="integration">
                <app-coupon-form-section-rows
                  [inputConfig]="getInputConfig"
                  [form]="couponForm"
                  [section]="couponSections.integration"
                />
              </app-coupon-form-section>
            </form>
          </div>
          <div *ngIf="flags.showCouponPreview" class="col-md-2 mr-auto">
            <div class="card sticky-div">
              <div class="card-heading border bottom">
                <h4 class="card-title">
                  {{'common.preview' | translate}}
                </h4>
              </div>
              <app-resource-preview
                *ngIf="couponForm"
                [formValues]="couponForm.value"
                [imageFile]="imageFile"
                [thumbnail]="couponData?.thumbnail_medium"
                [type]="'coupon'"
                [availabilityType]="'humanized'"></app-resource-preview>
            </div>
          </div>
        </div>
      </div>

      <!-- MODAL FOOTER -->
      <div class="row modal-footer background-white" *ngIf="couponForm">
        <div [ngClass]="{'col-md-10': flags.showCouponPreview}" class="col-md-8 ml-auto mr-auto text-right">
					<button class="btn btn-info"
            (click)="sendData()"
            [disabled]="!couponForm.valid || loading">
            <span *ngIf="loading">
              <em class="fa fa-spinner fa-spin"></em>
            </span>
            {{ 'common.buttons.save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-import-products
  #importProductsModal
  (productIdsEmitter)="handleImportProductList($event)"
></app-import-products>
