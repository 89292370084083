import { Component, OnInit, OnDestroy } from '@angular/core';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { LocationsService } from '../../../../data-warehouse/locations/locations.service';
import { ProductsService } from '../../../../data-warehouse/products/services/products/products.service';
import { TextboxQuestion } from '../../../../../shared/models/forms/question-textbox';
import { ProductSalesService } from './product-sales.service';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { MatrixService } from '../../../../../shared/services/matrix.service';
import { Subscription } from 'rxjs';
import {getCurrencySymbol, registerLocaleData} from '@angular/common';
import es from '@angular/common/locales/es';
import * as moment from 'moment';
import { DataTableFilterService } from '../../../../../shared/components/data-table-filter/data-table-filter.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import {ProfileService} from '../../../../../profiles/profile.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-sales-tab',
  templateUrl: './product-sales-tab.component.html',
  styleUrls: ['./product-sales-tab.component.css'],
  providers: [ProductSalesService]
})
export class ProductSalesTabComponent implements OnInit, OnDestroy {

  filters: QuestionBase<any>[];
  defaultFilters: QuestionBase<any>[];
  loading: boolean;
  productData: Object;
  productSalesData: any;
  chartOptions: any;
  currencySymbol: string;
  subs$: Subscription[] = [];
  filterSlug: string;
  filterConfig: { disableOnInit: boolean, disableOnSubmit: boolean };

  public charts = {
    productSales: {
      axisLabels: {
        x: [],
        y: []
      }
    }
  };

  public requestData: {
    filtering: {
      location_ids: number;
      product_ids: number;
      date_from: any;
      date_to: any;
    };
  };

  constructor(
    private route: ActivatedRoute,
    private locations: LocationsService,
    private products: ProductsService,
    private refreshCacheService: RefreshCacheService,
    private filterService: DataTableFilterService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private profileService: ProfileService,
    private matrixService: MatrixService
  ) {}

  ngOnInit() {
    registerLocaleData(es);
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');
    this.filterSlug = this.route.snapshot.data.slug;
    this.filterConfig = { disableOnInit: true, disableOnSubmit: true };
    this.filters = this.setFilters();
    this.defaultFilters = this.setFilters();
  }

  ngOnDestroy() {
    if ( this.subs$ ) { this.subs$.forEach(s$ => s$.unsubscribe()); }
  }

  onFilterHandler(filterFormValue) {
    if (filterFormValue.date_from && filterFormValue.date_to) {
      if (!this.compareDates(filterFormValue)) {
        return;
      }
    }
    this.getProductSalesData(filterFormValue);
  }

  getProductSalesData( filterFormValue? ) {
    let filtering;
    if (filterFormValue) {
      filtering = {
        date_from: filterFormValue.date_from,
        date_to: filterFormValue.date_to,
        location_ids: filterFormValue.location_ids,
        product_ids: filterFormValue.product_ids
      };
    } else {
      filtering = {};
    }
    const params = {
      apiEndPoint: 'analytics/product_sales',
      filtering: filtering
    };

    this.loading = true;
    const productData$ = this.refreshCacheService.getExpensiveData(params).subscribe((reqResponse) => {
      if (!this.refreshCacheService.isRequestPending(reqResponse)) {
        this.productSalesData = reqResponse;
        productData$.unsubscribe();
        this.checkIfFinished();
        this.loading = false;
        this.filterService.loaderStatus.next(true);
      }
    });
    this.subs$.push(productData$);
  }

  isAxisGroupInformed(axis) {
    return (this.chartOptions && this.chartOptions.hasOwnProperty('axisGroups') && this.chartOptions.axisGroups.hasOwnProperty(axis));
  }

  processValue(row, col, value) {
    if (this.chartOptions.hasOwnProperty('formatter')) {
      return this.chartOptions.formatter(row, col, value);
    }
    return value;
  }

  hasTextDanger(cell) {
    if (cell[2] < 0 ) {
      return true;
    } else {
      return false;
    }
  }

  private checkIfFinished() {
    if ( this.productSalesData !== undefined ) {
      this.generateChart(this.productSalesData);
    }
  }

  private alertDateMessage() {
    this.confirmationService.displayAlert(
      this.translate.instant('components.data-table.errors.title_error_date'),
      this.translate.instant('components.data-table.errors.date_bigger_than'),
      'warning').catch(() => {});
  }

  private compareDates(filterFormValue): boolean {
    if (moment(filterFormValue.date_from).isAfter(filterFormValue.date_to)) {
      this.loading = false;
      this.alertDateMessage();
      setTimeout(() => { this.filterService.loaderStatus.next(true); });
      return false;
    }
    return true;
  }


  private generateChart(data) {

    const yLabels = data.names.products;
    const xLabels = [
      this.translate.instant('dashboards.results.product_sales.table.tickets'),
      this.translate.instant('dashboards.results.product_sales.table.sales_tickets'), 
      this.translate.instant('dashboards.results.product_sales.table.sales_product'), 
      this.translate.instant('dashboards.results.product_sales.table.average_ticket')
    ];

    let table = this.matrixService.createFromTable(data.values, [yLabels.length, xLabels.length]);

    if (table.length) {
      table = this.matrixService.subset(table, 'all', [1, 2, 3, 4]);
      table = this.matrixService.process(table, { addDivisionColumn: [1, 0] });
    }

    this.chartOptions = {
      columnMenuEnabled: false,
      table: table,
      axisLabels: {
        x: xLabels,
        y: yLabels
      },
    };

    this.charts['productSales'] = this.chartOptions;
  }

  private setFilters(): QuestionBase<any>[] {
    return [
      new MultiSelectQuestion({
        key: 'location_ids',
        label: 'dashboards.results.product_sales.filters.location_ids',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableSearchFilter: true},
        dataSource: this.locations,
      }),
      new MultiSelectQuestion({
        key: 'product_ids',
        label: 'dashboards.results.product_sales.filters.product_ids',
        cssClasses: 'form-control input-default',
        required: true,
        settings: { singleSelection: false, enableSearchFilter: true},
        dataSource: this.products,
        useToken: true
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_from',
        label: 'dashboards.results.product_sales.filters.date_from',
        type: 'date'
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'date_to',
        label: 'dashboards.results.product_sales.filters.date_to',
        type: 'date'
      })
    ];
  }

}
