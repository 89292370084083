import { Component, Input, OnInit, SecurityContext } from "@angular/core";
import { DecimalPipe, getCurrencySymbol, registerLocaleData } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import es from '@angular/common/locales/es';
import { TranslateService } from "@ngx-translate/core";
import { isNullOrUndefined } from "is-what";

import { ReportRoiByStoreBaseHeaders, ReportRoiByStoreTableHeaders } from './utils/table-headers.utils';
import { ReportRoiByStoreByCampaign, ReportRoiByStoreHeader, ReportRoiByStoreCalculatedData } from "./model/report-roi-by-stores.model";

import { CsvExportService } from "../../../../../shared/services/csv-export.service";
import { RoiByStoreReportData } from "../../report.model";
import { ProfileService } from "../../../../../profiles/profile.service";

type ReportRoiContent = {id: number, value: ReportRoiByStoreByCampaign[]};

@Component({
  selector: 'app-show-report-roi-by-store',
  templateUrl: './show-report-roi-by-store.component.html',
  styleUrls: ['./show-report-roi-by-store.component.scss']
})
export class ShowReportRoiByStore implements OnInit {
  private currency = this.profileService.getProfileCompany().currency;

  baseHeaders = ReportRoiByStoreBaseHeaders.filter((header) => header.display);
  tableHeaders = ReportRoiByStoreTableHeaders;
  content: ReportRoiContent;
  currencySymbol = getCurrencySymbol(this.currency, 'wide');

  @Input() element: RoiByStoreReportData;
  @Input() id: number;
  @Input() lastGenerationAt: string;

  constructor(
    private csvExportService: CsvExportService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private profileService: ProfileService,
    private decimalPipe : DecimalPipe
  ){}

  ngOnInit() {
    registerLocaleData(es);
    this.groupByCampaignId();
  }

  goToCoupon(couponId: number) {
    window.open(`#/offers-personalization/dashboard/reports(modal:show/coupons/${couponId})`);
  }

  goToOrigin(type: 'plan' | 'campaign_block', originId: number) {
    if (type === 'plan') {
      window.open(`#/offers-personalization/dashboard/reports(modal:show/plans/${originId}/roi)`);
    } else {
      window.open(`#/offers-personalization/dashboard/reports(modal:show/customer_journeys/${originId}/summary)`);
    }
  }

  downloadCsv() {
    const details = Object.values(this.content).map(element => element['rawResults']).flat();
    const csvData = this.prepareAriAsStringForCsv(details);
    const csvBlob = this.csvExportService.getCsvBlob(csvData);
    const url = window.URL.createObjectURL(csvBlob);
    const nav = (window.navigator as any);

    if (nav.msSaveBlob) {
      nav.msSaveBlob(csvBlob, 'filename.csv');
    } else {
      const a = document.createElement('a');
      a.href = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(url));
      const fileName = `${this.element.type}.csv`;
      a.download = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(fileName));
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  private groupByCampaignId() {
    const groupedCampaigns = this.element.results.reduce((acc, item) => {
      const campaignId = item.campaign_id;
      // Create a new group for the campaign_id if it doesn't exist
      if (!acc[campaignId]) {
        acc[campaignId] = {rawResults: [], groupedCalculations: {}}
      }
      // Push the item into the corresponding group
      acc[campaignId].rawResults.push(item);
      return acc;
    }, {});
    this.content = this.calculatedGroupedValues(groupedCampaigns);
  }

  private calculatedGroupedValues(groupedCampaigns): ReportRoiContent {
    Object.values(groupedCampaigns).forEach((el) => {
      this.baseHeaders.filter(header => header.summable).forEach((header) => {
        el['groupedCalculations'][header.key] = el['rawResults'].reduce((acc: number, obj: ReportRoiByStoreHeader) => acc + (obj[header.key] || 0), 0)
      });
      this.baseHeaders.filter(header => header.calculateValueFn).forEach((header) => {
        el['groupedCalculations'][header.key] = header.calculateValueFn(el);
      });
      this.translateCampaignCategory(el['groupedCalculations']);
    });
    return groupedCampaigns;
  }

  private translateCampaignCategory(groupedCalculations) {
    const key = groupedCalculations['campaign_category']
    if(key.indexOf('Plans::') === 0) {
      groupedCalculations['campaign_category'] = this.translate.instant('resources.campaign_plans.types.dictionary.' + key);
    } else if(key.indexOf('CampaignBlock::') === 0) {
      groupedCalculations['campaign_category'] = this.translate.instant('resources.journeys.type.dictionary.' + key);
    }
  }

  private prepareAriAsStringForCsv(objArray: ReportRoiByStoreCalculatedData[]): string {
    let stringCsv = '';
    const baseHeaders = ReportRoiByStoreBaseHeaders.filter(header => header.csv !== false);
    baseHeaders.forEach(header => {
      stringCsv += this.translate.instant(header.translateKey, {currencySymbol: this.currencySymbol}) + ';'
    });
    stringCsv += '\n';
    objArray.forEach(csvRow => {
      baseHeaders.forEach(header => {
        stringCsv += this.parseValue(csvRow, header.key) + ';';
      });
      stringCsv += '\n';
    });
    return stringCsv;
  }

  private parseValue(csvRow: unknown, key: string) {
    let value = csvRow[key];

    switch (key) {
      case 'discount':
        return this.parseDiscount(csvRow, value);
      case 'min_purchase':
        return this.parseMinPurchase(csvRow);
      case 'origin_id':
        return this.parseOriginId(csvRow);
      case 'campaign_category':
        return this.parseCampaignCategory(csvRow);
      case 'discount_cost':
      case 'unique_voucher_redemptions_ratio':
      case 'estimated_incremental_sales':
      case 'incremental_ratio':
      case 'printed_vouchers_ratio':
        return this.parseNumericValue(value);
      default:
        return this.parseDefaultValue(value);
    }
  }

  private parseDiscount(csvRow: unknown, value: unknown) {
    const unit = csvRow['discount_type'] === 'percent' ? '%' : this.currencySymbol;
    return value ? this.replaceDotWithChar(`${value}${unit}`, ',') : '';
  }

  private parseMinPurchase(csvRow: unknown) {
    if (csvRow['min_units'] && csvRow['min_units'] !== 0) {
      const units = this.translate.instant('resources.reports.roi_by_store.fields.units');
      return csvRow['min_units'] ? this.replaceDotWithChar(`${csvRow['min_units']} ${units}`, ',') : '';
    } else {
      return csvRow['min_purchase'] ? this.replaceDotWithChar(`${csvRow['min_purchase']}${this.currencySymbol}`, ',')  : '';
    }
  }

  private parseOriginId(csvRow: unknown) {
    return csvRow['campaign_block_id'] ? csvRow['campaign_block_id'] : csvRow['plan_id'];
  }

  private parseCampaignCategory(csvRow: unknown) {
    if(csvRow['campaign_category'].indexOf('Plans::') === 0) {
      return this.translate.instant('resources.campaign_plans.types.dictionary.' + csvRow['campaign_category']);
    } else if(csvRow['campaign_category'].indexOf('CampaignBlock::') === 0) {
      return this.translate.instant('resources.journeys.type.dictionary.' + csvRow['campaign_category']);
    }
  }

  private parseNumericValue(value) {
    return value ? this.replaceDotWithChar(parseFloat(value).toFixed(2), ',') : '';
  }

  private parseDefaultValue(value) {
    if (isNullOrUndefined(value)) {
      return '';
    } else if (value && !isNaN(value)) {
      const decimalPart = value?.toString().split('.')[1];
      if (decimalPart && decimalPart.length > 2) {
        let decimalPart = this.decimalPipe.transform(value, '1.2-2', 'es');
        decimalPart = this.replaceDotWithChar(decimalPart, ',');
        return decimalPart;
      }    }
    return value ? value : '';
  }

  private replaceDotWithChar(string: string, newChar: string, re = /\./g) {
    return string.replace(re, newChar)
  }
}
