import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { CountriesService } from '../../../../shared/services/countries.service';
import { getTranslationInputs } from '../../../data-warehouse/data-warehouse-coupons/form-coupons/coupon-utils/coupon-utils';
import { LoyaltyScore } from '../../../../shared/models/loyalty-scores/loyalty-scores';
import { LoyaltyScoresService } from '../loyalty-scores.service';
import { ProfileService } from '../../../../profiles/profile.service';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { registerLocaleData } from '@angular/common';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';
import es from '@angular/common/locales/es';
import { LoyaltyScoresMock } from '../../../../shared/mocks/loyalty-scores-mock';

@Component({
  selector: 'app-show-loyalty-scores',
  templateUrl: './show-loyalty-scores.component.html',
  styleUrls: ['./show-loyalty-scores.component.scss'],
  providers: [LoyaltyScoresService],
  encapsulation: ViewEncapsulation.None,
})

export class ShowLoyaltyScoresComponent implements OnInit, OnDestroy {

  apiEndPoint: string;
  id: number;
  isModal: boolean;
  loyaltyScoreId: number;
  loyaltyScoresData: LoyaltyScore;
  loyaltyScoresForm: UntypedFormGroup;
  loyaltyScoresInputs: QuestionBase<any>[];
  minHeight: string = 'calc(100vh - 128px)';
  mode: string;
  roleSlug: string;
  selectedTab: string;
  subs$: Subscription[];

  constructor(
    public loyaltyScoresService: LoyaltyScoresService,
    private confirmationService: ConfirmationService,
    private countriesService: CountriesService,
    private profileService: ProfileService,
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {
    this.loyaltyScoreId = this.route.snapshot.params.id
    this.subs$ = [];
  }

  ngOnInit() {
    registerLocaleData(es);
    this.roleSlug = this.profileService.getStoredUserRole();
    this.isModal = this.router.url.indexOf('(modal:show/loyalty_rules/') >= 0;
    this.getParams();
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  goToMoment() {
    window.open(`#/loyalty/triggers(modal:show/loyalty_rule_moments/${this.loyaltyScoresData.embeddedLoyaltyScoreMoment.id})`);
  }

  goToReward() {
    window.open(`#/loyalty/rewards/list/active(modal:show/rewards/${this.loyaltyScoresData.rewardId})`);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private getParams() {
    const route$ = this.route.params.subscribe(params => {
      if (params.id) {
        if (this.router.url.indexOf(`(modal:`) >= 0) {
          this.mode = 'show';
        }
        this.id = params.id;
        this.getLoyaltyScore(params.id);
      }
    });
    this.subs$.push(route$);
  }

  private getLoyaltyScore(loyaltyScoreId: number) {
    const loyaltyScore$ = this.loyaltyScoresService.getLoyaltyScoresById(loyaltyScoreId).subscribe(
      loyaltyScoreData => {
        loyaltyScore$.unsubscribe();
        this.loyaltyScoresData = new LoyaltyScore(loyaltyScoreData);
        this.loyaltyScoresData.countries = this.parseCountries(this.loyaltyScoresData.countries);
        this.loyaltyScoresInputs = this.loyaltyScoresService.getInputs({});
        this.loyaltyScoresForm = this.qcs.toFormGroup(this.loyaltyScoresInputs);
        getTranslationInputs(this.loyaltyScoresData, this.qcs, this.loyaltyScoresForm, [], this.mode);
      },
      errorData => {
        loyaltyScore$.unsubscribe();
        this.handleErrorOnRequest(errorData);
      }
    );
    this.subs$.push(loyaltyScore$);
  }

  private handleErrorOnRequest(errorData) {
    this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorData.error.error);
    this.closeModal();
  }

  private parseCountries(countriesList) {
    this.countriesService.getCountries('default');
    return countriesList?.map(item => {
      let country = this.countriesService.getCountriesById(item);
      if (!country) {
        country = {id: item, name: item};
      }
      return country;
    });
  }

}
