<div class="app" [ngClass]="{'is-collapsed': app.layout.isMenuCollapsed,
                             'is-opened': app.layout.isMenuOpened,
                             'rtl' : app.layout.rtlActived}">
  <div class="layout" [ngClass]='headerSelected'>

    <div [ngClass]="sidenavSelected">

      <!-- Side Nav START -->
      <ng-container *ngIf="featureFlags.showNewMenuHome; else currentMenu">
        <app-new-side-nav-revamp (onEvent)="handleHeaderEvent($event)"></app-new-side-nav-revamp>
      </ng-container>
      <ng-template #currentMenu>
        <app-new-side-nav (onEvent)="handleHeaderEvent($event)"></app-new-side-nav>
      </ng-template>
      <!-- Side Nav END -->

      <!-- Page Container START -->
      <div class="page-container">

        <!-- Top Nav START -->
        <app-content-header (onEvent)="handleHeaderEvent($event)" [isCollapsed]="app.layout.isMenuCollapsed"></app-content-header>
        <!-- Top Nav END -->

        <!-- Side Panel START -->
        <app-side-panel></app-side-panel>
        <!-- Side Panel END -->

        <!-- Content Wrapper START -->
        <div class="main-content">
          <!-- Alerts START -->
           <div id="layout-header-alerts">
            <div class="alert alert-danger alert-dismissible fade show mrg-btm-0" *ngIf="systemWarning === 'offline'">
              {{'common.system_warning' | translate}}
              <button type="button" class="close no-pdd-top pdd-top-10" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="alert alert-warning alert-dismissible fade show mrg-btm-0" *ngIf="periodPending">
              {{'common.period_warning' | translate}}
              <button type="button" class="close no-pdd-top pdd-top-10" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="alert alert-warning alert-dismissible fade show mrg-btm-0" *ngIf="activeSync">
              {{'common.synchro_active' | translate}}
              <button type="button" class="close no-pdd-top pdd-top-10" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
           </div>
          <!-- Alerts END -->
          <router-outlet></router-outlet>
        </div>
        <!-- Content Wrapper END -->
      </div>
      <!-- Page Container END -->
    </div>
  </div>
</div>
