import { CommonModule, DecimalPipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { isNullOrUndefined } from "../../../../../shared/utils/common.utils";
import { LgCurrencyPipe } from "../../../../../shared/pipes/lg-currency.pipe";
import { PrimeNgModule } from "../../../../../shared/primeNG/primeng/primeng.module";
import { TicketDetail, VoucherTicketDetail } from "../../../../../shared/models/purchases/ticket-detail";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

export interface DetailsBlockItem {
  blockTitleKey: string;
  children: {
    titleKey: string;
    value: any;
    link?: string;
    tooltip?: string;
    visibility?: boolean;
  }[];
}

@Component({
  selector: 'app-details-block',
  templateUrl: './details-block.component.html',
  styleUrls: ['./details-block.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, PrimeNgModule]
})

export class DetailsBlockComponent implements OnInit {
  currentLang = this.translate.currentLang;
  details: DetailsBlockItem[];

  @Input() ticketData: TicketDetail;
  @Input() currencySymbol: string;
  @Input() vouchersData: VoucherTicketDetail[];
  @Input() totalDiscount: number;

  constructor(
    private readonly lgCurrency: LgCurrencyPipe,
    private readonly decimalPipe: DecimalPipe,
    private readonly translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getDetails();
  }

  openUrlInNewTab(url: string): void {
    window.open(url, '_blank');
  }

  scroll(elementId: string): void {
    document.getElementById(elementId).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  private getDetails(): void {
    const hasPaymentCost = !isNullOrUndefined(this.ticketData.payment_cost);
    const hasShippingCost = !isNullOrUndefined(this.ticketData.shipping_cost);
    this.details = [
      {
        blockTitleKey: 'resources.tickets.modal.cards.details.title',
        children: [
          {
            titleKey: 'common.id',
            value: this.ticketData.id
          },
          {
            titleKey: 'resources.tickets.modal.cards.details.fields.total',
            value: `${this.lgCurrency.transform(this.ticketData.total, '1.2-2', this.ticketData.currency)}`
          },
          {
            titleKey: 'resources.tickets.modal.cards.details.fields.payment_cost',
            value: hasPaymentCost ? `${this.lgCurrency.transform(this.ticketData.payment_cost, '1.2-2', this.ticketData.currency)}` : null,
            visibility: hasPaymentCost
          },
          {
            titleKey: 'resources.tickets.modal.cards.details.fields.shipping_cost',
            value: hasShippingCost ? `${this.lgCurrency.transform(this.ticketData.shipping_cost, '1.2-2', this.ticketData.currency)}` : null,
            visibility: hasShippingCost
          },
          {
            titleKey: 'resources.tickets.modal.cards.details.fields.order_id',
            value: this.ticketData.order_id,
            visibility: !!this.ticketData.order_id
          },
          {
            titleKey: 'resources.tickets.modal.cards.details.fields.code',
            value: this.ticketData.code ? this.ticketData.code : '-'
          },
          {
            titleKey: 'resources.tickets.modal.cards.details.fields.customer',
            value: this.handleCustomerFullName(this.ticketData?._embedded?.customer),
            link: this.getCustomerLink(this.ticketData?._embedded?.customer)
          },
          {
            titleKey: this.ticketData.checkout_date ? 'resources.tickets.modal.cards.details.fields.delivery_date' : 'resources.tickets.modal.cards.details.fields.date',
            value: this.ticketData.created_at ? new Date(this.ticketData.created_at).toLocaleDateString(this.currentLang, { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : '-'
          },
          {
            titleKey: 'resources.tickets.modal.cards.details.fields.checkout_date',
            value: this.ticketData.checkout_date ? new Date(this.ticketData.checkout_date).toLocaleDateString(this.currentLang, { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : null,
            visibility: !!this.ticketData.checkout_date
          },
          {
            titleKey: 'resources.tickets.modal.cards.details.fields.location',
            value: this.ticketData._embedded.location ? this.ticketData._embedded.location?.name : ''
          }
        ]
      },
      {
        blockTitleKey: 'resources.tickets.modal.cards.points.title',
        children: [
          {
            titleKey: 'resources.tickets.modal.cards.points.fields.points_won',
            value: this.decimalPipe.transform(this.ticketData.points_won, '1.0-0', 'es')
          },
          {
            titleKey: 'resources.tickets.modal.cards.points.fields.scores_used',
            value:  this.decimalPipe.transform(this.ticketData.score_used, '1.0-0', 'es')
          },
          {
            titleKey: 'resources.tickets.modal.cards.points.fields.from_customer',
            value: this.decimalPipe.transform(this.ticketData.score_returned_from_customer, '1.0-0', 'es'),
            tooltip: 'resources.tickets.modal.cards.points.tooltips.from_customer'
          },
          {
            titleKey: 'resources.tickets.modal.cards.points.fields.to_customer',
            value:  this.decimalPipe.transform(this.ticketData.score_returned_to_customer, '1.0-0', 'es'),
            tooltip: 'resources.tickets.modal.cards.points.tooltips.to_customer'
          },
          {
            titleKey: 'resources.tickets.modal.cards.points.fields.score_payment',
            value: `${this.decimalPipe.transform(this.ticketData.cashbackTotalPoints, '1.0-0', 'es')} (${this.lgCurrency.transform(this.ticketData.cashbackTotalAmount, '1.2-2', this.ticketData.currency)})`
          }
        ]
      },
      {
        blockTitleKey: 'resources.tickets.modal.cards.vouchers_redeemed.title',
        children: [
          {
            titleKey: 'resources.tickets.modal.cards.vouchers_redeemed.fields.used',
            value: this.vouchersData.length
          },
          {
            titleKey: 'resources.tickets.modal.cards.vouchers_redeemed.fields.total_discount',
            value: this.lgCurrency.transform(this.totalDiscount / 100, '1.2-2', this.ticketData.currency)
          }
        ]
      }
    ];
  }

  private handleCustomerFullName(customer: object): string {
    const customerId = customer?.['id'] ? customer['id'] : '-';
    let customerFullName = customer?.['name'] ?? '';
    if (customer?.['surname_1']) { customerFullName += ` ${customer['surname_1']}`};
    return customerFullName?.trim() ? customerFullName : customerId;
  }

  private getCustomerLink(customer: object): string {
    return customer?.['id'] ? `/#/data-warehouse/profiles/${customer['id']}/summary` : null;
  }
}
