export const TAB_SLUGS_WITH_CURRENCY_AND_CURRENCY_COMPANY_FILTERS = [
  'summary',
  'loyalty_summary',
  'loyalty_points_accrued',
  'loyalty_points_redeemed',
  'loyalty_rewards',
  'sales_by_rule',
  'loyalty_partners',
  'segment_drill_down',
  'overlap_customers_segment'
];