import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Condition } from '../../../../models/segments/condition';
import { QuestionBase } from '../../../../models/forms/question-base';
import { UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { QuestionControlService } from '../../../../services/question-control.service';
import { Operator } from '../../../../models/segments/conditions/operator';
import { resetInputsForm } from '../../utils/common.utils';
import { CustomEntityKey } from '../../../../services/conditions/services/custom-entity-condition/enums/custom-entity-key.enums';
import { CustomEntityAttributeType } from '../../../../services/conditions/services/custom-entity-condition/enums/custom-entity-attribute-type.enums';
import { MultiselectDataSource } from '../../../multiselect/multiselect';
import { MultiselectService } from '../../../multiselect/multiselect.service';
import { Subject, distinctUntilChanged, take, takeUntil } from 'rxjs';
import { ATTRIBUTES_CONFIGURATION_FIELDS, CONDITION_DATES_FIELDS, NARROW_LAYOUT_CLASS, WIDE_LAYOUT_CLASS } from './constants/custom-entity-condition.constants';
import { CustomEntitiesService } from '../../../../../resources/customer-data-platform/custom-entities/services/custom-entities.service';
import { CustomEntityCondition, CustomEntityConditionForm } from '../../../../models/segments/conditions/custom-entity-condition';
import { parseItemCondition } from './parsers/condition-item.parser';
import { getAttributesConfigurationFormGroup } from './utils/attributes-form.utils';
import { OperatorsService } from '../../../../services/conditions/common/operators.service';
import { DateService } from '../../../../services/date.service';
import { setAttributeConfigCtrlsValidations } from '../../../../services/conditions/services/custom-entity-condition/validations/form.validations';
import { TranslateService } from '@ngx-translate/core';
import { parseAttributeOptions } from './parsers/attributes.parser';

@Component({
  selector: 'app-custom-entity-condition',
  templateUrl: './custom-entity-condition.component.html',
  styleUrls: ['./custom-entity-condition.component.scss']
})
export class CustomEntityConditionComponent implements OnInit, OnDestroy {

  wideLayout = WIDE_LAYOUT_CLASS;
  narrowLayout = NARROW_LAYOUT_CLASS;
  customEntityKey = CustomEntityKey;
  customEntityAttributeType = CustomEntityAttributeType;
  isCustomEntityReady = false;
  newAttributeForm: UntypedFormGroup;

  get attributesConfigArray(): UntypedFormArray {
    return this.form?.get(this.customEntityKey.AttributesConfiguration) as UntypedFormArray;
  }

  get isQuantityBtOperator(): boolean {
    return this.isBtOperator(this.customEntityKey.QuantityOperator);
  }

  private readonly destroy$ = new Subject<void>();

  @Input() conditionFormValues: object; // These are the params/values that will be used when condition is dragged inside the segment
  @Input() item: Condition;
  @Input() inputs: QuestionBase<unknown>[];
  @Input() form: UntypedFormGroup;
  @Input() rawConditionValues: object;

  constructor(
    private qcs: QuestionControlService,
    private multiselectService: MultiselectService,
    private customEntitiesService: CustomEntitiesService,
    private operatorsService: OperatorsService,
    private dateService: DateService,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.setupInitialCondition();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  handleOperatorChanges(operator: Operator, keyValue2: string) {
    const op = operator?.hasOwnProperty('id') ? operator.id : null;
    if (!op || op !== 'bt') {
      resetInputsForm(this.form, this.inputs, [keyValue2]);
    }
  }

  handleSelectedEntity(selectedEntity: MultiselectDataSource): void {
    let attributeOptions = [];
    if (selectedEntity) {
      const entityAttributes = selectedEntity?.rawElement?.entity_attributes;
      attributeOptions = parseAttributeOptions(entityAttributes, attributeOptions);
    }
    const attributeInput = this.getInputConfig(this.customEntityKey.Attribute);
    attributeInput.options = attributeOptions;
    attributeInput.value = null;
    this.multiselectService.updateDropdownList.next(this.customEntityKey.Attribute);
    this.attributesConfigArray?.clear();
    this.newAttributeForm.reset();
  }

  private isBtOperator(operatorKey: string, attributeConfigCtrl?: UntypedFormGroup): boolean {
    return this.qcs.hasFormKeyWithValue(attributeConfigCtrl ?? this.form, operatorKey, 'bt');
  }

  private setupInitialCondition(): void {
    this.setInitialFormValues();
    this.form.addControl(this.customEntityKey.AttributesConfiguration, new UntypedFormArray([], [Validators.required]));
    this.setNewAttributeInputsForm();
    const definitionIdInput = this.getInputConfig(this.customEntityKey.DefinitionId);
    if (definitionIdInput?.selectedIds?.length) {
      this.customEntitiesService.getById(definitionIdInput.selectedIds[0]).pipe(take(1)).subscribe((customEntity: object) => {
        this.setInitialAttributeOptions(customEntity);
        this.setInitialAttributesConfig();
        this.setupDefaultRequirements();
      });
    } else {
      this.setupDefaultRequirements();
    }
  }

  // Set only for dragging purposes (second dragging loses selected ATTRIBUTES_CONFIGURATION_FIELDS related to newAttrubyteForm)
  private setInitialFormValues(): void {
    if (this.conditionFormValues) {
      this.setAttributesConfigFormFields(this.conditionFormValues);
    }
  }

  private setNewAttributeInputsForm(): void {
    this.newAttributeForm = getAttributesConfigurationFormGroup(this.conditionFormValues);
    this.newAttributeForm.setValidators([(control: UntypedFormGroup) => setAttributeConfigCtrlsValidations(control)]);
    this.setNewAttributeFormSubscription();
  }

  private setNewAttributeFormSubscription(): void {
    this.newAttributeForm.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(
      newValue => this.setAttributesConfigFormFields(newValue)
    );
  }

  private setAttributesConfigFormFields(formValue: object): void {
    ATTRIBUTES_CONFIGURATION_FIELDS.forEach(field => this.form.get(field).patchValue(formValue?.[field] ?? null));
  }

  private setInitialAttributeOptions(customEntity: object): void {
    let attributeOptions = [];
    const entityAttributes = customEntity?.['entity_attributes'];
    attributeOptions = parseAttributeOptions(entityAttributes, attributeOptions);
    const attributeInput = this.getInputConfig(this.customEntityKey.Attribute);
    attributeInput.options = attributeOptions;
    this.multiselectService.updateDropdownList.next(this.customEntityKey.Attribute);
  }

  private setInitialAttributesConfig(): void {
    const attributesConfig = this.rawConditionValues?.['ce_conditions'];
    if (this.conditionFormValues?.hasOwnProperty(this.customEntityKey.AttributesConfiguration)) {
      const attributesConfigFormValues = this.conditionFormValues[this.customEntityKey.AttributesConfiguration];
      attributesConfigFormValues.forEach((formItemCondition: CustomEntityConditionForm) => this.attributesConfigArray.push(getAttributesConfigurationFormGroup(formItemCondition)));
    } else if (attributesConfig?.length) {
      attributesConfig.forEach((itemCondition: CustomEntityCondition) => {
        const parsedItemCondition = parseItemCondition(itemCondition, this.operatorsService, this.dateService, this.translate);
        this.attributesConfigArray.push(getAttributesConfigurationFormGroup(parsedItemCondition));
      });
    }
  }

  private setupDefaultRequirements(): void {
    this.setInitialRequiredInputs();
    this.isCustomEntityReady = true;
    this.changeDetector.detectChanges();
  }

  // Visual purposes to show asterisk (validation controlled by customValidators)
  private setInitialRequiredInputs(): void {
    this.inputs.forEach(input => {
      const staticFields = [...CONDITION_DATES_FIELDS, this.customEntityKey.Attribute, this.customEntityKey.AttributeType];
      if (!staticFields.includes(input.key)) {
        input.required = true;
      }
    });
  }
}
