import { Injectable } from "@angular/core";
import { MultiSelectQuestion } from "../../../../../shared/models/forms/question-multiselect";
import { Option } from "../../../../../shared/models/common/option";
import { QuestionBase } from "../../../../../shared/models/forms/question-base";
import { TextareaQuestion } from "../../../../../shared/models/forms/question-textarea";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class FormReservedIdsInputsService {

  constructor(
    private readonly translate: TranslateService
  ) {}

  getInputs(): QuestionBase<unknown>[] {
    return [
      new MultiSelectQuestion({
        key: 'codes_type',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableSearchFilter: false },
        options: this.getCodesTypeOptions(),
        required: true
      }),
      new TextareaQuestion({
        key: 'codes',
        cssClasses: 'form-control input-md',
        placeholder: this.translate.instant('resources.reserved_ids.fields.codes_placeholder'),
        required: true
      })
    ];
  }

  private getCodesTypeOptions(): Option[] {
    return [
      { id: 'id', name: this.translate.instant('resources.reserved_ids.fields.codes_type_options.id') },
      { id: 'code', name: this.translate.instant('resources.reserved_ids.fields.codes_type_options.code') },
      { id: 'id_code', name: this.translate.instant('resources.reserved_ids.fields.codes_type_options.id_code') }
    ];
  }
}