import { getCurrencySymbol } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ProfileService } from '../../../profiles/profile.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-resource-preview',
  templateUrl: './resource-preview.component.html',
  styleUrls: ['./resource-preview.component.scss']
})
export class ResourcePreviewComponent implements OnInit, OnChanges {

  @Input() imageFile: string | ArrayBuffer;
  @Input() formValues: {
    discount: number;
    name: string;
    [key: string]: any;
  }
  @Input() thumbnail: string | ArrayBuffer;
  @Input() type: 'coupon' | 'challenge' | 'points_policy' | 'reward';
  @Input() availabilityType: 'humanized' | 'range';

  currencySymbol: string;

  constructor(
    private profileService: ProfileService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    const currency = this.profileService.getProfileCompany().currency;
    this.currencySymbol = getCurrencySymbol(currency, 'wide');
    this.setLongestValue('name', 'name');
    this.setLongestValue('description', 'description');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formValues) {
      this.setLongestValue('name', 'name');
      this.setLongestValue('description', 'description');
    }
  }

  getSpecificLocationData(): string {
    if (this.formValues?.location_ids?.length) {
      return this.formValues?.location_ids.map(l => l.name).join(', ');
    } else if (this.formValues?.feature_location_ids?.length) {
      return this.formValues?.feature_location_ids.map(fl => fl.name).join(', ');
    } else {
      return this.translate.instant('resources.messages.fields.all_stores');
    }
  }

  isDiscountType(discountTypeId: string): boolean {
    return this.formValues.discount_type?.[0]?.id === discountTypeId;
  }

  getCountrySymbols(): string {
    if (!this.formValues || !this.formValues?.['countries']?.length) {
      return this.translate.instant('common.all').toLowerCase();
    } else {
      return this.formValues['countries'].map(el => el.id).join(', ');
    }
  }

  private setLongestValue(prefix: string, targetProperty: string): void {
    const keys = Object.keys(this.formValues).filter(key => key.startsWith(prefix));
    let longestValue = '';

    keys.forEach(key => {
      const value = this.formValues[key];
      if (value?.length > longestValue.length) {
        longestValue = value;
      }
    });

    this.formValues[targetProperty] = longestValue;
  }
}