import { Injectable } from '@angular/core';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TextboxQuestion } from '../../../../../shared/models/forms/question-textbox';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from '../../../../../shared/services/resources.service';
import { FeaturesService } from '../../../../data-warehouse/products/services/products-features/features.service';
import { Option } from '../../../../../shared/models/common/option';

@Injectable()
export class ReportIncrementalService extends ResourcesService {

  inputs: QuestionBase<any>[];
  formValues = {};

  constructor(
    http: HttpClient,
    private translate: TranslateService,
    private features: FeaturesService
  ) {
    super(http);
  }

  fetchSelectedById(id: number): Observable<object> {
    return this.getData({ apiEndPoint: `reports/${id}/report` });
  }

  fetchReportDetailById(id: number, history_id: number): Observable<object> {
    return this.getData({ apiEndPoint: `reports/${id}/details/${history_id}` });
  }

  getIncrementalOptions(): Option[] {
    return [
      { id: 'impacted', name: this.translate.instant('resources.reports.form.fields.impacted') },
      { id: 'redemptions', name: this.translate.instant('resources.reports.form.fields.redemptions') }
    ];
  }

  getAffectationOptions(): Option[] {
    return [
      { id: 'activity', name: this.translate.instant('resources.reports.form.fields.ticket') },
      { id: 'attribute', name: this.translate.instant('resources.reports.form.fields.features') }
    ];
  }

  getDeliveryOptions(): Option[] {
    return [
      { id: 'next', name: this.translate.instant('resources.reports.form.fields.next') }
    ];
  }

  setInputs(formValues: any) {
    let config = null;
    if ( formValues ) {
      config = formValues.configuration;
    }

    const inputsArray = [
      new TextboxQuestion({
        key: 'name',
        type: 'text',
        cssClasses: 'form-control input-default',
        value: formValues && formValues.name ? formValues.name : []
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'before_date_from',
        label: 'resources.reports.form.fields.before_date_from',
        type: 'date',
        required: true,
        value: (config) ? config.before_date_from : null
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'before_date_to',
        label: 'resources.reports.form.fields.before_date_to',
        type: 'date',
        required: true,
        value: (config) ? config.before_date_to : null
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'after_date_from',
        label: 'resources.reports.form.fields.before_date_from',
        type: 'date',
        required: true,
        value: (config) ? config.after_date_from : null
      }),
      new TextboxQuestion({
        cssClasses: 'form-control input-md',
        key: 'after_date_to',
        label: 'resources.reports.form.fields.before_date_to',
        type: 'date',
        required: true,
        value: (config) ? config.after_date_to : null
      }),
      new TextboxQuestion({
        key: 'prediction_percentage_redemption',
        type: 'number',
        cssClasses: 'form-control input-default',
        value: (config) ? config.prediction_percentage_redemption : null
      }),
      new TextboxQuestion({
        key: 'prediction_coupon_discount',
        type: 'number',
        cssClasses: 'form-control input-default',
        value: (config) ? config.prediction_coupon_discount : null
      }),
      new MultiSelectQuestion({
        key: 'analysis_type',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        options: this.getIncrementalOptions(),
        value: (config) ? [this.getIncrementalOptions().find( item => item.id === config.analysis_type )] : null
      }),
      new MultiSelectQuestion({
        key: 'affectation',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        options: this.getAffectationOptions(),
        required: true,
        value: (config) ? [this.getAffectationOptions().find( item => item.id === config.affectation)] : null
      }),
      new MultiSelectQuestion({
        key: 'features',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, enableSearchFilter: true},
        dataSource: this.features,
        selectedIds: (config && config.feature && config.feature.length > 0) ? config.feature : []
      })
    ];

    this.inputs = inputsArray;
    return this.inputs;
  }

  sendData(data, id) {
    return this.postResource(data, `reports/${id}/details`);
  }

  updateData(data: any, id: number, history_id) {
    return this.patchResource(data, `reports/${id}/details/${history_id}`);
  }
}
