<ng-container *ngIf="hasFormKeyWithValue('loyalty_score_moment', 'after_create_activity')">
  <div class="row">
    <h5 class="text-bold card-title mrg-left-15">{{'resources.scores.loyalty_scores.fields.total_ticket' | translate}}</h5>
    <em class="ti-info-alt pull-right mrg-left-5" style="top: 6px; position: relative;"
        tooltipPosition="top"
        pTooltip="{{'resources.scores.loyalty_scores.form.tooltip.total_ticket_tooltip' | translate}}">
    </em>
  </div>

  <div class="row">

    <div class="col-md-2">
      <label style="top:30px; margin:0;" class="text-bold font-size-13">{{'resources.scores.loyalty_scores.fields.total_ticket_amount' | translate}}</label>
    </div>

    <label for="ticket_total_min" class="text-bold font-size-13">{{'resources.scores.loyalty_scores.conditions.gteq' | translate}}</label>

    <div class="col-md-2" style="bottom: 10px;">
      <app-question [question]="getInputConfig('ticket_total_min')" [form]="loyaltyScoresForm"/>
    </div>

    <label for="ticket_total_max" class="text-bold font-size-13">{{'resources.scores.loyalty_scores.conditions.lteq' | translate}}</label>

    <div class="col-md-2" style="bottom: 10px;">
      <app-question [question]="getInputConfig('ticket_total_max')" [form]="loyaltyScoresForm"/>
    </div>

  </div>
</ng-container>
