import { DateService } from '../../../date.service';
import { defaultParseFormValuesToAPI, getParseDynamicDateValue, getParseFloatValue, getParseMultiselectIdValues, getParseObjectKeysIdValue, getParseOperatorValue, getParseSelectedIdsValue, getParseSingleSelectOperatorIdValue, getParseValue, parseParamsValues, setLocationsFiltering } from '../../utils/common.utils';
import { DynamicDateQuestion } from '../../../../models/forms/question-dynamic-date';
import { EcommerceCondition } from '../../../../models/segments/conditions/ecommerce-condition';
import { FloatQuestion } from '../../../../models/forms/question-float';
import { getFormGroupValidations, MIN_OPERATOR_VALUE, MIN_OPERATOR_VALUE_2 } from './validations/form.validations';
import { Injectable } from '@angular/core';
import { LocationsService } from '../../../../../resources/data-warehouse/locations/locations.service';
import { LocationsTaxonomyTermsService } from '../../../../../resources/data-warehouse/locations/location-taxonomy-terms.service';
import { multiselectPresenceValidator } from '../../../validations.service';
import { MultiSelectQuestion } from '../../../../models/forms/question-multiselect';
import { Operator } from '../../../../models/segments/conditions/operator';
import { OperatorsService } from '../../common/operators.service';
import { QuestionBase } from '../../../../models/forms/question-base';
import { SegmentConditionProvider } from '../../condition.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable()
export class EcommerceConditionService implements SegmentConditionProvider {

  inputs: QuestionBase<unknown>[];
  customValidators = [(control: UntypedFormGroup) => getFormGroupValidations(control)];

  private readonly numericOperatorOpts = this.operatorsService.getNumericOperators();

  constructor(
    private readonly dateService: DateService,
    private readonly locationsService: LocationsService,
    private readonly locationsTaxonomyTermsService: LocationsTaxonomyTermsService,
    private readonly operatorsService: OperatorsService,
    private readonly translate: TranslateService
  ) {}

  getInputs(params?: EcommerceCondition): QuestionBase<unknown>[] {
    setLocationsFiltering(params, this.locationsService);

    const inputs = [
      new MultiSelectQuestion({
        key: 'date_type',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.date'),
        options: this.dateTypeOpts(),
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        getValue: getParseValue(),
        parseValue: getParseSingleSelectOperatorIdValue(this.dateTypeOpts()),
        customValidators: [multiselectPresenceValidator]
      }),
      new DynamicDateQuestion({
        key: 'date_from',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.date_from'),
        getValue: getParseDynamicDateValue(this.dateService),
        parseValue: getParseObjectKeysIdValue(),
        disabled: !params?.date_type
      }),
      new DynamicDateQuestion({
        key: 'date_to',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.date_to'),
        getValue: getParseDynamicDateValue(this.dateService),
        parseValue: getParseObjectKeysIdValue(),
        disabled: !params?.date_type
      }),
      new MultiSelectQuestion({
        key: 'location_taxonomy_term_ids',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.location_taxonomy_term_ids'),
        dataSource: this.locationsTaxonomyTermsService,
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        getValue: getParseSelectedIdsValue(),
        parseValue: getParseMultiselectIdValues()
      }),
      new MultiSelectQuestion({
        key: 'location_ids',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.location_ids'),
        dataSource: this.locationsService,
        settings: { singleSelection: false, enableCheckAll: true, showCheckbox: true, enableSearchFilter: true },
        getValue: getParseSelectedIdsValue(),
        parseValue: getParseMultiselectIdValues()
      }),
      new MultiSelectQuestion({
        key: 'total_sum_operator',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.total_sum_operator'),
        options: this.numericOperatorOpts,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        getValue: getParseValue(),
        parseValue: getParseSingleSelectOperatorIdValue(this.numericOperatorOpts)
      }),
      new FloatQuestion({
        key: 'total_sum_value',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.blank'),
        type: 'number',
        step: 0.1,
        getValue: getParseValue(false),
        parseValue: getParseFloatValue()
      }),
      new FloatQuestion({
        key: 'total_sum_value_1',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.minimum'),
        type: 'number',
        step: 0.1,
        getValue: getParseValue(false),
        parseValue: getParseFloatValue()
      }),
      new FloatQuestion({
        key: 'total_sum_value_2',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.maximum'),
        type: 'number',
        step: 0.1,
        getValue: getParseValue(false),
        parseValue: getParseFloatValue()
      }),
      new MultiSelectQuestion({
        cssClasses: 'form-control input-md',
        key: 'payment_cost_operator',
        label: this.translate.instant('resources.segment_conditions.fields.payment_cost'),
        options: this.numericOperatorOpts,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        getValue: getParseValue(),
        parseValue: getParseSingleSelectOperatorIdValue(this.numericOperatorOpts)
      }),
      new FloatQuestion({
        key: 'payment_cost_value',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.blank'),
        type: 'number',
        min: MIN_OPERATOR_VALUE,
        step: 0.1,
        getValue: getParseValue(false),
        parseValue: getParseFloatValue()
      }),
      new FloatQuestion({
        key: 'payment_cost_value_1',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.minimum'),
        type: 'number',
        min: MIN_OPERATOR_VALUE,
        step: 0.1,
        getValue: getParseValue(false),
        parseValue: getParseFloatValue()
      }),
      new FloatQuestion({
        key: 'payment_cost_value_2',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.maximum'),
        type: 'number',
        min: MIN_OPERATOR_VALUE_2,
        step: 0.1,
        getValue: getParseValue(false),
        parseValue: getParseFloatValue()
      }),
      new MultiSelectQuestion({
        key: 'shipping_cost_operator',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.shipping_cost'),
        options: this.numericOperatorOpts,
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        getValue: getParseValue(),
        parseValue: getParseSingleSelectOperatorIdValue(this.numericOperatorOpts)
      }),
      new FloatQuestion({
        key: 'shipping_cost_value',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.blank'),
        type: 'number',
        min: MIN_OPERATOR_VALUE,
        step: 0.1,
        getValue: getParseValue(false),
        parseValue: getParseFloatValue()
      }),
      new FloatQuestion({
        key: 'shipping_cost_value_1',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.minimum'),
        type: 'number',
        min: MIN_OPERATOR_VALUE,
        step: 0.1,
        getValue: getParseValue(false),
        parseValue: getParseFloatValue()
      }),
      new FloatQuestion({
        key: 'shipping_cost_value_2',
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.maximum'),
        type: 'number',
        min: MIN_OPERATOR_VALUE_2,
        step: 0.1,
        getValue: getParseValue(false),
        parseValue: getParseFloatValue()
      })
    ];

    parseParamsValues(params, inputs);
    this.inputs = inputs;
    return this.inputs;
  }

  prepareFormValuesToAPI(params: EcommerceCondition): EcommerceCondition {
    const parsedValuesObj =  defaultParseFormValuesToAPI(params, this.inputs);
    const keysRoot = ['total_sum', 'payment_cost', 'shipping_cost'];
    this.handleValuesToAPI(parsedValuesObj, keysRoot);
    return parsedValuesObj;
  }

  prepareFormValuesFromAPI(params: EcommerceCondition): EcommerceCondition {
    const parseValues = {
      date_type: getParseOperatorValue(this.dateTypeOpts()),
      date_from: getParseValue(),
      date_to: getParseValue(),
      location_taxonomy_term_ids: getParseValue(),
      location_ids: getParseValue(),
      total_sum_operator: getParseOperatorValue(this.numericOperatorOpts),
      total_sum_value: getParseValue(false),
      total_sum_value_1: getParseValue(false),
      total_sum_value_2: getParseValue(false),
      shipping_cost_operator: getParseOperatorValue(this.numericOperatorOpts),
      shipping_cost_value: getParseValue(false),
      shipping_cost_value_1: getParseValue(false),
      shipping_cost_value_2: getParseValue(false),
      payment_cost_operator: getParseOperatorValue(this.numericOperatorOpts),
      payment_cost_value: getParseValue(false),
      payment_cost_value_1: getParseValue(false),
      payment_cost_value_2: getParseValue(false)
    };

    const parsedValuesObj = {};
    Object.keys(params).forEach( key => { parsedValuesObj[key] = parseValues[key](params[key]); });
    return parsedValuesObj;
  }

  private handleValuesToAPI(parsedValuesObj: object, keysRoot: string[]): void {
    const dateKeys = ['date_from', 'date_to'];
    dateKeys.forEach(key => {
      if (!parsedValuesObj.hasOwnProperty(key)) {
        parsedValuesObj[key] = null;
      }
    });

    keysRoot.forEach(key => {
      if (parsedValuesObj[`${key}_operator`] !== 'bt') {
        delete parsedValuesObj[`${key}_value_1`];
        delete parsedValuesObj[`${key}_value_2`];
      } else {
        delete parsedValuesObj[`${key}_value`];
      }
    });
  }

  private dateTypeOpts(): Operator[] {
    return [
      { id: 'checkout_date', name: this.translate.instant('resources.segment_conditions.options.checkout_date')},
      { id: 'delivery_date', name: this.translate.instant('resources.segment_conditions.options.delivery_date')}
    ];
  }
}