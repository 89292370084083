import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PlanCampaign } from '../../plan-campaign';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { TranslateService } from '@ngx-translate/core';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { CheckboxQuestion } from '../../../../../shared/models/forms/question-checkbox';
import { isNullOrUndefined } from 'util';
import { distinctUntilChanged } from 'rxjs/operators';
import { CampaignCategoriesService } from '../../../../campaigns/campaign-categories/campaign-categories.service';
import { FeatureFlagsService } from '../../../../../shared/services/feature-flags.service';

@Component({
  selector: 'app-recovery-campaign-plan-definition',
  templateUrl: './recovery-campaign-plan-definition.component.html'
})
export class RecoveryCampaignPlanDefinitionComponent implements OnInit, OnDestroy {

  inputs: QuestionBase<any>[];
  form: UntypedFormGroup;
  subs$: Subscription[];
  flags = this.featureFlags.flags;

  @Input('planCampaign') planCampaign: PlanCampaign;
  @Output('formValidity') formValidity: EventEmitter<any>;

  constructor(
    private qcs: QuestionControlService,
    private translate: TranslateService,
    private campaignCategoriesSrv: CampaignCategoriesService,
    private featureFlags: FeatureFlagsService
  ) {
    this.formValidity = new EventEmitter();
    this.subs$ = [];
  }

  ngOnInit() {
    this.setInputs(this.planCampaign);
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  getPayload(): Object {
    const payload = Object.assign({}, this.form.value);
    const segment = this.form.value['segment_id'];

    payload['type'] = 'RecoveryCampaign';
    payload['config'] = {
      segment_id: segment && segment.length > 0 ? segment[0].id : null,
      periods: this.form.value['periods'] ? this.form.value['periods'].map(el => el.id) : null,
      has_bought: this.form.value['has_bought'] ? this.form.value['has_bought'] : false,
      campaign_plan_category_id: this.parseCampaignCategoryPayload()
    };

    Object.keys(this.form.value).filter(key => key.indexOf('segment_') >= 0 || key.indexOf('campaign_plan_category_id') >= 0)
    .forEach(key => {
      delete payload[key];
    });
    return payload;
  }

  private parseCampaignCategoryPayload() {
    const campaignValue = this.form.value['campaign_plan_category_id'];
    if (campaignValue && campaignValue[0] && campaignValue[0].id) {
      return campaignValue[0].id;
    } else {
      return null;
    }
  }

  private dateOptions() {
    return [
      { id: '%30_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_1')},
      { id: '%90_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_3')},
      { id: '%180_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_6')},
      { id: '%365_days_ago%', name: this.translate.instant('components.dynamic-date-input.dynamic-dates.months_12')}
    ];
  }

  private getSelectedPeriods(planCampaign: PlanCampaign): {id: number, name: string}[] {
    const campaignPeriods = planCampaign.config['periods'];
    if (isNullOrUndefined(campaignPeriods)) {return []; }
    return planCampaign.config['periods'].map(period => this.dateOptions().find(opt => opt.id === period));
  }

  private formStatusChanges(): void {
    setTimeout(() => this.formValidity.emit(this.form.status));
    const formStatus$ = this.form.statusChanges.pipe(distinctUntilChanged()).subscribe((formStatus) => {
      this.formValidity.emit(formStatus);
    });
    this.subs$.push(formStatus$);
  }

  private setInputs(planCampaign?: PlanCampaign) {
    let campaignCategories;
    if (planCampaign) {
      campaignCategories = this.getCampaignCategoryValue(planCampaign);
    }
    const inputs = [
      new MultiSelectQuestion({
        key: 'campaign_plan_category_id',
        label: 'resources.plans.filters.campaign_category',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: true, enableSearchFilter: true },
        dataSource: this.campaignCategoriesSrv,
        selectedIds: (campaignCategories && campaignCategories.length > 0 && campaignCategories[0] !== 0) ? campaignCategories : null,
        value: [{id: -1, name: this.translate.instant('resources.campaign_categories.messages.no_category')}],
        required: true
      }),
      new MultiSelectQuestion({
        key: 'periods',
        label: 'resources.campaign_plans.types.recovery.fields.period',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: false, showCheckbox: true, enableSearchFilter: true },
        options: this.dateOptions(),
        value: planCampaign ? this.getSelectedPeriods(planCampaign) : [],
        required: true
      }),
      new CheckboxQuestion({
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.campaign_plans.types.recovery.fields.bought'),
        key: 'has_bought',
        value: planCampaign ? planCampaign.config['has_bought'] : false
      }),
      new MultiSelectQuestion({
        key: 'assignment_types',
        label: 'resources.campaign_plans.fields.assignment_types',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: true, enableSearchFilter: false },
        options: this.assignmentTypesOpts(),
        value: [this.assignmentTypesOpts()[0]]
      }),
    ];

    this.inputs = inputs;
    this.form = this.qcs.toFormGroup(this.inputs);
    this.formStatusChanges();
  }

  private getCampaignCategoryValue(planCampaign: PlanCampaign) {
    const campaignPlanCategoryId = planCampaign.config['campaign_plan_category_id'];
    if (isNullOrUndefined(campaignPlanCategoryId)) {return []; }
    return [campaignPlanCategoryId];
  }

  private assignmentTypesOpts() {
    return [
      { id: 'coupon', name: this.translate.instant('resources.vouchers.columns.coupon')},
      { id: 'points_rule', name: this.translate.instant('resources.campaigns.fields.loyalty_score_id')},
      { id: 'challenge', name: this.translate.instant('resources.scores.loyalty_scores.filters.challenge')}
    ];
  }

}
