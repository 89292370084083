<div class="modal-backdrop fade in" [id]="'reservedIDs'" tabindex="-1" aria-hidden="false">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">

			<div class="modal-header">
				<h1 class="mrg-left-15 mrg-btm-5 first-letter-upper">{{ 'resources.reserved_ids.form_title' | translate }}</h1>
				<button class="modal-close" (click)="closeModal()">
					<em class="ti-close"></em>
				</button>
			</div>

			<div class="modal-body background-gray">
				<div class="row">
					<div class="col-md-12">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="card-block no-pdd">
                    <div class="mrg-top-10">
                      <div class="card padding-20">
                        <form  [formGroup]="reservedIdsForm" *ngIf="reservedIdsForm">
                          <div class="row">
                            <div class="col-md-12">
                              <label for="codes_type" class="text-bold">
                                {{ 'resources.reserved_ids.fields.codes_type' | translate }}
                                <span class="text-danger">*</span>
                              </label>
                              <div class="alert alert-info d-flex align-items-center" role="alert">
                                <span class="alert-text">{{ 'resources.reserved_ids.fields.codes_type_description' | translate }}</span>
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="form-group">
                                <app-question [question]="getInputConfig('codes_type')" [form]="reservedIdsForm"></app-question>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="codes" class="text-bold">
                                  {{ 'resources.reserved_ids.fields.codes' | translate }}
                                  <span class="text-danger">*</span>
                                </label>
                                <div class="alert alert-info codes-values" role="alert">
                                  <span class="alert-text">{{ 'resources.reserved_ids.fields.codes_description' | translate }}</span>
                                  <ul>
                                    <li><b>{{ 'resources.reserved_ids.fields.codes_description_id' | translate}}</b></li>
                                    <p>{{ 'resources.reserved_ids.fields.codes_description_id_example_1' | translate}}</p>
                                    <p>{{ 'resources.reserved_ids.fields.codes_description_id_example_2' | translate}}</p>
                                    <li><b>{{ 'resources.reserved_ids.fields.codes_description_code' | translate}}</b></li>
                                    <p>{{ 'resources.reserved_ids.fields.codes_description_code_example_1' | translate}}</p>
                                    <p>{{ 'resources.reserved_ids.fields.codes_description_code_example_2' | translate}}</p>
                                    <li><b>{{ 'resources.reserved_ids.fields.codes_description_id_code' | translate}}</b></li>
                                    <p>{{ 'resources.reserved_ids.fields.codes_description_id_code_example_1' | translate}}</p>
                                    <p>{{ 'resources.reserved_ids.fields.codes_description_id_code_example_2' | translate}}</p>
                                  </ul>
                                </div>
                                <app-question [question]="getInputConfig('codes')" [form]="reservedIdsForm"></app-question>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
			</div>

			<div class="modal-footer background-white align-content-center">
				<div class="pull-right d-flex align-items-center">
					<button class="pull-right btn btn-info"
									(click)="save()"
									[disabled]="loading || reservedIdsForm.invalid">
            <span *ngIf="loading">
              <em class="fa fa-spinner fa-spin"></em>
            </span>
            {{ 'common.save' | translate }}
          </button>
				</div>
			</div>
		</div>
	</div>
</div>
