import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CurrentUser } from '../shared/models/current-user';
import { DictionaryService } from '../shared/services/dictionary.service';
import { LaunchDarklyService } from '../shared/services/launch-darkly/launchdarkly.service';
import { getCurrentScope } from '@sentry/browser';
import { map } from 'rxjs/operators';
import { LG_EMAIL_DOMAINS_LIST } from '../shared/constants/lg-company.constants';
import { CURRENT_COMPANY_STORED_CONSTANTS, CURRENT_USER_STORED_CONSTANTS } from './current-user-stored-constants';

@Injectable()
export class ProfileService {

  public currentUser: CurrentUser;

  constructor(
    private http: HttpClient,
    private dictionaryService: DictionaryService,
    private launchDarklyService: LaunchDarklyService
  ) {}

  getCurrentUserProfile() {
    return this.http.get('users/me').pipe(map(
      response => {
        const currentUser = new CurrentUser(response);
        this.setLaunchDarklyUser(currentUser);
        this.setSentryUser(currentUser);
        sessionStorage.setItem('user_data', this.summarizeStoredCurrentUser(response));
        this.currentUser = currentUser;
        return this.currentUser;
      }
    ));
  }

  getRawUserProfile() {
    return this.http.get('users/me').pipe(map(response => response));
  }

  getStoredUserRole() {
    const storedUser = this.getStoredUser();
    if (!storedUser) { return null; }
    return this.getStoredUser().role.slug;
  }

  getStoredUser() {
    const user = sessionStorage.getItem('user_data');
    return user ? JSON.parse(user) : null;
  }

  getTransactionTypeObject(element: { id: number, type: string }) {
    const iconObj = this.dictionaryService.getObjectByKeyAndId('scores_transactions_type', element.type);
    return {
      'plainIcon': iconObj['icon'],
      'parsedValue': element.type,
      'name': iconObj['name'],
    };
  }

  getProfileCompany() {
    return this.getStoredUser().company;
  }

  isLGUser(): boolean {
    const userEmailDomain = this.getStoredUser().email.split('@')[1];
    const isLGUserEmail = LG_EMAIL_DOMAINS_LIST.includes(userEmailDomain);
    return isLGUserEmail;
  }

  getStoredUserFullname(userData: any) {
    let fullName = '';
    if (userData.name) {
      fullName = fullName.concat(userData.name)
    };

    if (userData.surname_1) {
      fullName = userData.name ? fullName.concat(' ', userData.surname_1) : fullName.concat(userData.surname_1)
    };

    if (userData.surname_2) {
      fullName = userData.name || userData.surname_1 ? fullName.concat(' ', userData.surname_2) : fullName.concat(userData.surname_2)
    };
    return fullName;
  }

  // private
  private summarizeStoredCurrentUser(rawUserResponse: object): string {
    const rawUserObject = { company: {} };
    CURRENT_USER_STORED_CONSTANTS.forEach(key => rawUserObject[key] = rawUserResponse[key]);
    CURRENT_COMPANY_STORED_CONSTANTS.forEach(key => rawUserObject['company'][key] = rawUserResponse['company'][key]);
    this.cleanStoredCurrentUser(rawUserObject);
    return JSON.stringify(rawUserObject);
  }

  private cleanStoredCurrentUser(rawUserObject: object): void {
    delete rawUserObject?.['company']?.['company_configurations']?.sso;
  }

  private cleanAuthData() {
    sessionStorage.removeItem('user_data');
    localStorage.removeItem('is_authenticated');
  }

  private setLaunchDarklyUser(user: CurrentUser) {
    if (!this.launchDarklyService.isInitiated()) {
      this.launchDarklyService.initClient(user);
      return;
    }
    this.launchDarklyService.changeUser({
      key: user.email,
      name: user.fullName,
      email: user.email,
      company: user.company.slug,
      role: user.role.slug
    });
  }

  private setSentryUser(user: CurrentUser) {
    getCurrentScope().setUser({
      username: user.fullName,
      email: user.email
    });
  }
}
