import { Injectable } from '@angular/core';
import BeePlugin from '@mailupinc/bee-plugin';
import { ResourcesService } from '../../../services/resources.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { FeatureFlagsService } from './../../../services/feature-flags.service';
import { IBeeConfig } from '@mailupinc/bee-plugin/dist/types/bee';

@Injectable()
export class CreateUpdateLandingsService extends ResourcesService {

  specialLinks = [
    {
    type: this.translate.instant('resources.campaign_templates.modal.special_links.cancel_subscription'),
    label: this.translate.instant('resources.campaign_templates.modal.special_links.cancel_subscription'),
    link: '{unsubscribe}'
    },
    {
      type: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr'),
      label: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr_es'),
      link: 'https://gdpr-landing.web.app/#/gdpr/{gdpr_token}?locale=es'
    },
    {
      type: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr'),
      label: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr_cat'),
      link: 'https://gdpr-landing.web.app/#/gdpr/{gdpr_token}?locale=ca'
    },
    {
      type: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr'),
      label: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr_en'),
      link: 'https://gdpr-landing.web.app/#/gdpr/{gdpr_token}?locale=en'
    },
    {
      type: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr'),
      label: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr_it'),
      link: 'https://gdpr-landing.web.app/#/gdpr/{gdpr_token}?locale=it'
    },
    {
      type: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr'),
      label: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr_fr'),
      link: 'https://gdpr-landing.web.app/#/gdpr/{gdpr_token}?locale=fr'
    },
    {
      type: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr'),
      label: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr_de'),
      link: 'https://gdpr-landing.web.app/#/gdpr/{gdpr_token}?locale=de'
    },
    {
      type: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr'),
      label: this.translate.instant('resources.campaign_templates.modal.special_links.link_gdpr_hr'),
      link: 'https://gdpr-landing.web.app/#/gdpr/{gdpr_token}?locale=hr'
    }
  ];

  mergeContents = [
    {name: this.translate.instant('resources.campaigns.content_variables.coupon_barcode'), value: '{coupon_barcode}'}
  ];

  campaignId: number;
  flags = this.featureFlags.flags;

  constructor(
    http: HttpClient,
    private translate: TranslateService,
    private featureFlags: FeatureFlagsService,
  ) {
    super(http);
  }

  initBee(userData, cfg, schema, mergeTags: {name: string, value: string}[]) {
    const lang = this.flags.newLanguageMenuVisibility ? userData.preferred_language : userData.company.locale.substr(0, 2);
    const beeConfig: IBeeConfig = {
      uid: userData.company.slug, // needed for identify resources of the that user and billing stuff
      container: 'bee-plugin-container', // Identifies the id of div element that contains BEE Plugin
      language: lang.concat('-', lang.toUpperCase()),
      mergeTags: mergeTags,
      mergeContents: this.getDynamicContent(),
      specialLinks: this.specialLinks,
      editorFonts: {
        showDefaultFonts: true,
        customFonts: [
          {
            name: 'Roboto Mono',
            fontFamily: '\'Roboto Mono\', monospace',
            url: 'https://fonts.googleapis.com/css2?family=Oswald'
          },
          {
            name: 'Recoleta',
            fontFamily: '\'Recoleta\', sans-serif',
            url: 'https://fonts.cdnfonts.com/css/recoleta'
          },
          {
            name: 'Work Sans',
            fontFamily: '\'Work Sans\', sans-serif',
            url: 'https://fonts.googleapis.com/css2?family=Work+Sans'
          },
          {
            name: 'Neue Machina',
            fontFamily: '\'Neue Machina\', sans-serif',
            url: 'https://lg-custom-fonts.s3.eu-west-1.amazonaws.com/neue_machina/neue_machina.css'
          },
          {
            name: 'Ginto Nord',
            fontFamily: '\'Ginto Nord\', sans-serif',
            url: 'https://lg-custom-fonts.s3.eu-west-1.amazonaws.com/ginto_nord/ginto_nord_v2.css'
          }
        ]
      },
      onSave: (_schema, _html) => {
        cfg.onSave(_schema, _html);
      }
    };

    const bee = new BeePlugin();

    bee.getToken(environment.beePluginClientId, environment.beePluginKey).then(
      () => bee.start(beeConfig, schema).then(
        () => bee.load(schema)
      )
    ).catch(() => {});
  }

  getTemplatesData() {
    return this.getData({ apiEndPoint: `campaign_templates?via=email` });
  }

  getTemplateById(id: number) {
    return this.getData({ apiEndPoint: `campaign_templates/${id}` });
  }

  getById(id: number) {
    return this.getData({ apiEndPoint: `campaigns/${id}` });
  }

  sendCampaignData(campaignData, id: number) {
    return this.patchResource(campaignData, `campaigns/${id}`);
  }

  updateTemplateById(id: number, json: Object, html: Object, name?: string) {
    return this.patchResource(
      {
        name: name,
        template: {
          id: this.campaignId,
          message: html,
          schema: json,
          subject: 'aux',
          via: 'email'
        }
      },
      `campaigns/${id}`
    );
  }

  saveLanding(name: string, html: Object, json: Object) {
    return this.postResource(
      {
        name: name,
        distribution: 'landing',
        motive: 'informative',
        via: 'email',
        template: {
          message: html,
          schema: json,
          subject: 'aux'
        }
      },
      `campaigns`
    );
  }

  duplicateLanding(data) {
    return this.postResource(data, 'campaigns');
  }

  private getDynamicContent() {
    if (this.flags.showCustom1DynamicContentEmail) {
      this.mergeContents.push({
        name: this.translate.instant('resources.campaigns.content_variables.recommended_products'),
        value: '{custom1}'
      })
    }
    return this.mergeContents;
  }
}
