import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../../../shared/services/question-control.service';
import { QuestionBase } from '../../../../../../shared/models/forms/question-base';

@Component({
  selector: 'app-customer-purchase-condition',
  templateUrl: './customer-purchase-condition.component.html',
  styleUrl: './customer-purchase-condition.component.scss'
})
export class CustomerPurchaseConditionComponent {

  @Input('loyaltyScoresForm') loyaltyScoresForm: UntypedFormGroup;
  @Input('inputs') inputs;
  @Input('flags') flags;

  constructor(
      private qcs: QuestionControlService
  ) { }

  getInputConfig(inputKey: string): QuestionBase<unknown> {
      return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

}
