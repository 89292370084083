<div class="row" *ngIf="element">
  <div class="col-md-12">
    <div class="card mrg-left-15 mrg-right-15">
      <div class="card-block">
        <div class="pull-left">
          <h4 class="card-title">
            <i class="ei-timer"></i>
            {{ 'resources.reports.fields.last_generated' | translate }}
            {{ lastGenerationAt | dateTimeZone: 'DD/MM/YYYY HH:mm' }}
          </h4>
        </div>
        <div class="btn-group pull-right">
          <button class="btn btn-default" (click)="downloadCsv()">
            {{ 'resources.reports.coupons_in_app_analysis.buttons.download_csv' | translate }}
          </button>
        </div>
        <div class="pull-left switch-container">
          <div class="toggle-checkbox checkbox-inline toggle-sm mrg-top-10">
            <input #toggleBtn
              (click)="handleShowSubtotal()"
              type="checkbox"
              [id]="'toggle-coupons-in-app-analysis-subtotals-visibility-'+ !showSubtotal"
              [name]="'toggle-coupons-in-app-analysis-subtotals-visibility-'+ !showSubtotal">
            <label [for]="'toggle-coupons-in-app-analysis-subtotals-visibility-'+ !showSubtotal"></label>
            <span class="toggle-label-align card-title text-dark mrg-left-5">{{'resources.plans.buttons.show_subtotal' | translate}}</span>
          </div>
        </div>
        <table class="table table-responsive table-hover table-sm table-bordered no-mrg-btm" aria-hidden="true">
          <thead>
            <tr class="highlight-header">
              <th class="text-center" *ngFor="let header of tableHeaders">
                {{header.translateKey | translate: {currencySymbol: currencySymbol} }}
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let groupedResult of groupedResults">
              <tr [hidden]="showSubtotal" *ngFor="let item of groupedResult.results | keyvalue">
                <td *ngFor="let header of tableHeaders; let i = index;">
                  <app-analysis-table-cell [item]="item.value" [header]="header" [currencySymbol]="currencySymbol"></app-analysis-table-cell>
                </td>
              </tr>
              <tr class="highlight-header">
                <td *ngFor="let header of tableHeaders; let i = index;">
                  <app-analysis-table-cell [item]="groupedResult.total_result" [header]="header" [currencySymbol]="currencySymbol"></app-analysis-table-cell>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
