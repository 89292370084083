import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourcesService } from '../../../../../shared/services/resources.service';

@Injectable()

export class ApiService extends ResourcesService {

  constructor(http: HttpClient) {
    super(http);
  }

  getStreamingDataById(id: number) {
    return this.getData({ apiEndPoint: `streamings/${id}` });
  }

  getFile(resourceId: number, streamingElementId: number, fileId: number) {
    return this.getData({ apiEndPoint: `streamings/${resourceId}/elements/${streamingElementId}/download/${fileId}`});
  }

}
