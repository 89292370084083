<div class="container-fluid clearfix">
  <div class="row">
    <div class="col">
      <div class="card height-100">
        <div class="card-block">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="csv" class="font-size-14 text-bold">{{ 'resources.product_features.form.csv' | translate }}</label>
                <app-question [question]="getInputConfig('csv')" [form]="importProductFeaturesForm"></app-question>
              </div>
              <p>
                {{ 'resources.product_features.modal.import.download_template'| translate }}
                <a href="https://loyalguru-imports.s3.eu-west-1.amazonaws.com/import_product_categories_template.csv" target="_blank" rel="noopener">
                  {{ 'resources.product_features.modal.import.here_link'| translate }}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
