<div *ngIf="entityAttrsForm">
  <ng-container *ngIf="definedEntityAttrs.length > 0">
    <label class="text-bold">{{'resources.custom_entities.fields.entity_attributes' | translate}}</label>
    <table class="table table-bordered table-striped" aria-describedby="Custom entities attributes definition list">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of definedEntityAttrs; let i = index" [attr.hidden]="item._destroy">
          <td class="col-md-3">{{decorateAttrName(item.field)}}</td>
          <td class="col-md-3 text-center">{{decorateAttrOperator(item.condition)}}</td>
          <td class="col-md-4 text-right">
            <span class="label">{{item.value}}</span>
          </td>
          <td class="col-md-1 text-right">
            <em class="fa fa-times pointer" (click)="deleteLine(i)">
            </em>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <div class="row" [ngClass]="{'mrg-top-30': definedEntityAttrs.length > 0}">
    <div class="col-md-4">
      <div class="form-group">
        <label class="text-bold" for="custom_entity_definition_id" [translate]="'resources.scores.loyalty_scores.fields.custom_entity'"></label>
        <span *ngIf="getInputConfig('custom_entity_definition_id').required" class="text-danger">*</span>
        <app-question [question]="getInputConfig('custom_entity_definition_id')"
                      [form]="entityAttrsForm"
                      (multiselectChanged)="handleEntitySelected($event)"/>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <app-question [question]="getInputConfig('attribute')" (multiselectChanged)="entityAttributeChange($event)" [form]="entityAttrsForm"/>
      </div>
    </div>
    <div class="col" *ngIf="!currentSelectedType"></div>
    <ng-container *ngIf="currentSelectedType === 'number'">
      <div class="col-md-4">
        <div class="form-group">
          <app-question [question]="getInputConfig('number_operator')" [form]="entityAttrsForm"/>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <app-question [question]="getInputConfig('number_value')" [form]="entityAttrsForm"/>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentSelectedType === 'string'">
      <div class="col-md-4">
        <div class="form-group">
          <app-question [question]="getInputConfig('string_operator')" [form]="entityAttrsForm"/>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <app-question [question]="getInputConfig('string_value')" [form]="entityAttrsForm"/>
        </div>
      </div>
    </ng-container>
    <div class="col-md-1">
      <button class="btn btn-success btn-icon no-margin-right pull-right"
        (click)="addEntityAttribute()"><span class="fa fa-plus"></span></button>
    </div>
  </div>
</div>
