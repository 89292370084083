<h4 class="card-title mrg-top-15">
  {{ titleKey | translate }}
  <em class="ti-info-alt pull-right mrg-top-3 mrg-left-5" tooltipPosition="top"
    [pTooltip]="('resources.plans.alerts.budget.tooltip' | translate)">
  </em>
</h4>

<h1 class="font-size-20 display-flex align-items-center text-semibold" [ngClass]="colorClass" #budgetContentContainer>
  <i *ngIf="budgetAlertEnabled && maxBudgetAmount" class="mrg-right-5 flex-shrink-0" [ngClass]="icon"></i>

  <span class="budget-values-wrapper">
    <span class="budget-part budget-amount">
      {{ currentBudgetAmount | lgcurrency:'1.2-2' }}
    </span>
    <ng-container *ngIf="budgetAlertEnabled && maxBudgetAmount">
      <span class="budget-part budget-separator">
        {{ separator }}
      </span>
      <span class="budget-part budget-amount">
        {{ maxBudgetAmount | lgcurrency:'1.2-2' }}
      </span>
    </ng-container>
  </span>
</h1>
