import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { HTML_BASIC_TAGS } from '../constants/html-tags.constants';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'lg-language-tag' })
export class LgLanguageTagPipe implements PipeTransform {

  contentByLang: Record<string, string> = {};

  constructor(
    private readonly sanitizer: DomSanitizer
  ) {}

  transform(value: string) {
    const htmlDesc = document.createElement('html');
    htmlDesc.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML, value);
    const divs = Array.from(htmlDesc.getElementsByTagName('div'));

    if (!divs.length) {
      const spans = Array.from(htmlDesc.getElementsByTagName('span'));
      const paragraphs = Array.from(htmlDesc.getElementsByTagName('p'));

      let response = '';

      if (spans.length > 0) {
        response = spans.map(span => span.innerHTML).join('');
      } else if (paragraphs.length > 0) {
        response = paragraphs.map(paragraph => paragraph.innerHTML).join('');
      } else {
        response = this.removeExtraTags(value);
      }

      return this.removeQuotes(JSON.stringify(this.removeExtraTags(response, ' ')));
    } else {
      for (const div of divs) {
        this.assignSubstringPerLanguage(div.innerHTML, div.getAttribute('lang'));
      }
      return this.decorateContentByLang(this.contentByLang);
    }
  }

  private assignSubstringPerLanguage(content: string, lang: string) {
    if (lang) {
      this.contentByLang[lang] = content;
    }
  }

  private decorateContentByLang(o: object): string {
    return Object.entries(o)
      .map(([key, value]) => `${key.split('-')[1]}: ${this.removeQuotes(JSON.stringify(this.removeExtraTags(value)))}`)
      .join(', ');
  }

  private removeExtraTags(str: string, replaceChar: string = ''): string {
    if (str) {
      HTML_BASIC_TAGS.forEach(tag => {
        str = str.replace(new RegExp(tag, 'g'), replaceChar);
      });
      return str;
    }
    return '';
  }

  private removeQuotes(str: string): string {
    // Remove double-quotes when found after running JSON.stringify method
    let cleansedString = str.replace(/^"(.*)"$/g, "$1");
    // Ensure new line characters (\n) are replaced with empty strings to avoid uncleased strings
    cleansedString = cleansedString.replace(/\\n/g, ' ');
    return cleansedString;
  }
}
