<div class="card-header bg-light no-pdd">
  <div class="rounded-top voucher-details-img flex flex-column">
    <div class="text-center">
      <img
        *ngIf="!imageFile && thumbnail"
        [alt]="formValues.name"
        class="image-detail"
        [src]="thumbnail"/>
      <img
        *ngIf="imageFile"
        [alt]="formValues.name"
        class="image-detail"
        [src]="imageFile"/>
      <img
        *ngIf="!imageFile && !thumbnail && type === 'coupon'"
        [alt]="formValues.name"
        class="image-detail padding-20"
        src="assets/images/others/coupon_empty.png" />
      <img
        *ngIf="!imageFile && !thumbnail && type === 'challenge'"
        [alt]="formValues.name"
        class="image-detail padding-20"
        src="assets/images/others/challenge_empty.png" />
      <img
        *ngIf="!imageFile && !thumbnail && type === 'points_policy'"
        [alt]="formValues.name"
        class="image-detail padding-20"
        src="assets/images/others/points_policy_empty.png" />
      <img
        *ngIf="!imageFile && !thumbnail && type === 'reward'"
        [alt]="formValues.name"
        class="image-detail padding-20"
        src="assets/images/others/reward_empty.png" />
    </div>
    <div class="voucher-details-discount text-center pdd-top-10">
      <!-- COUPON - DISCOUNT INFO -->
      <h1 class="pdd-horizon-20 no-pdd-top overflow-x-auto" *ngIf="type === 'coupon'">
        <span *ngIf="!formValues.discount && !formValues.discount_type?.[0]?.id" class="transparent-white">
          {{ 10.00 | number : '1.0-0'}}%
        </span>
        <span *ngIf="isDiscountType('Punti'); else noPoints">
          {{ formValues.discount | number : '1.0-0'}}
        </span>
        <ng-template #noPoints>
          {{ formValues.discount | number : '1.2-2'}}
        </ng-template>
        <span *ngIf="isDiscountType('cash')">
          {{currencySymbol}}
        </span>
        <span *ngIf="isDiscountType('percent')">
          %
        </span>
        <span *ngIf="isDiscountType('Punti')">
          Pts.
        </span>
      </h1>
      <!-- END COUPON -->
      <!-- CHALLENGE - GOAL INFORMATION -->
      <div *ngIf="type === 'challenge'" class="voucher-details-card-redemptions">
        <ng-container *ngIf="!formValues.level_name && !formValues.threshold_challenge; else progressData">
          <div class="level-name mrg-btm-10">
            {{'resources.scores.challenges.fields.level' | translate | truncate: 21}}
          </div>
        </ng-container>
        <ng-template #progressData>
          <div class="progress-wrapper">
            <div class="progress">
              <span>{{ 0 }} / {{ formValues?.threshold_challenge | number: '1.0-0' | truncate: 10 }}</span>
            </div>
          </div>
          <div class="level-name mrg-btm-5 pdd-horizon-20" *ngIf="formValues?.level_name">
            {{ formValues.level_name | truncate: 50 }}
          </div>
        </ng-template>
      </div>
      <!-- END CHALLENGE -->
      <!-- POINTS POLICY -->
      <div *ngIf="type === 'points_policy'" class="voucher-details-card-redemptions">
        <ng-container [ngSwitch]="formValues.loyalty_score_moment?.[0]?.id">
          <ng-container *ngSwitchCase="'after_create_activity'">
            <div class="policy-hint-text mrg-btm-10" *ngIf="formValues.strategy_type?.[0]?.id == 'multiplicator'">
              {{'components.resource-preview.block_a.per_amount_spent' | translate: {currencySymbol: currencySymbol, points: formValues.points_price_multiplicator} }}
            </div>
            <div class="policy-hint-text mrg-btm-10" *ngIf="formValues.strategy_type?.[0]?.id == 'fixed_points'">
              {{'components.resource-preview.block_a.fixed_amount' | translate: {currencySymbol: currencySymbol, points: formValues.points} }}
            </div>
            <div class="policy-hint-text mrg-btm-10" *ngIf="formValues.strategy_type?.[0]?.id == 'chunk'">
              {{'components.resource-preview.block_a.per_chunk' | translate: {currencySymbol: currencySymbol, points: formValues.points_price_multiplicator, spend: formValues.points} }}
            </div>
            <div class="policy-hint-text mrg-btm-10" *ngIf="formValues.strategy_type?.[0]?.id == 'percentage'">
              {{'components.resource-preview.block_a.percent_amount_line_total' | translate: {currencySymbol: currencySymbol, percentage: formValues.points_price_percentage} }}
            </div>
            <div class="policy-hint-text mrg-btm-10" *ngIf="formValues.strategy_type?.[0]?.id == 'cashback_percentage'">
              {{'components.resource-preview.block_a.percent_amount' | translate: {currencySymbol: currencySymbol, percentage: formValues.points_price_percentage} }}
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'after_filling_location'">
            <div class="policy-hint-text mrg-btm-10">
              {{'components.resource-preview.block_a.after_filling_location' | translate: {currencySymbol: currencySymbol, points: formValues.points} }}
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'after_create_profile'">
            <div class="policy-hint-text mrg-btm-10">
              {{'components.resource-preview.block_a.after_create_profile' | translate: {currencySymbol: currencySymbol, points: formValues.points} }}
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'refer_a_friend'">
            <div class="policy-hint-text mrg-btm-10">
              {{'components.resource-preview.block_a.refer_a_friend' | translate: {currencySymbol: currencySymbol, points: formValues.points} }}
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'after_winning_challenge'">
            <div class="policy-hint-text mrg-btm-10">
              {{'components.resource-preview.block_a.after_winning_challenge' | translate: {currencySymbol: currencySymbol, points: formValues.points} }}
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'birthday'">
            <div class="policy-hint-text mrg-btm-10">
              {{'components.resource-preview.block_a.birthday' | translate: {currencySymbol: currencySymbol, points: formValues.points} }}
            </div>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <div class="policy-hint-text mrg-btm-10" *ngIf="formValues.points && formValues.loyalty_score_moment?.[0]?.name; else awardNotConfigured">
              {{'components.resource-preview.block_a.other' | translate: {currencySymbol: currencySymbol, points: formValues.points, trigger: formValues.loyalty_score_moment?.[0]?.name} }}
            </div>
            <ng-template #awardNotConfigured>
              <div class="policy-hint-text mrg-btm-10 transparent-white">
                {{'components.resource-preview.block_a.empty' | translate}}
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
      </div>
      <!-- END POINTS POLICY -->
      <!-- REWARD CODE -->
      <h1 class="pdd-horizon-20 no-pdd-top overflow-x-auto" *ngIf="type === 'reward'">
        <div class="transparent-white font-size-13">
          {{'components.resource-preview.fields.points' | translate }}
        </div>
        <div *ngIf="formValues?.points; else noPoints">
          <span>{{formValues?.points | truncate: 36}}</span>
        </div>
      </h1>
      <!-- END REWARD -->
    </div>
  </div>
</div>
<div class="card-body">
  <div *ngIf="formValues.available_to && availabilityType === 'humanized'" class="mrg-top-10 mrg-btm-20">
    <span class="label label-info font-size-12">
      {{'components.dynamic-date-input.dynamic-dates.days_remaining' | translate: { time: formValues.available_to | timeRemaining } }}
    </span>
  </div>
  <ng-container *ngIf="availabilityType === 'range'">
    <span class="label label-success font-size-12" *ngIf="formValues.available_from">
      {{formValues.available_from | date: 'dd/MM/yyyy'}}
    </span>
    <span class="label label-success font-size-12 mrg-left-5" *ngIf="formValues.available_to">
      {{formValues.available_to | date: 'dd/MM/yyyy'}}
    </span>
  </ng-container>
  <ng-container *ngIf="formValues.name">
    <h5 class="mrg-top-10 no-pdd-btm">{{formValues.name | emptyData}}</h5>
  </ng-container>
  <ng-container *ngIf="!formValues.name">
    <h5 class="mrg-top-10 no-pdd-btm transparent">
      {{'resources.synchros.show.resource' | translate}}
      {{'common.name' | translate | lowercase}}
    </h5>
  </ng-container>
  <ng-container *ngIf="formValues.description">
    <small class="mrg-top-10 no-pdd-btm">{{formValues.description | truncate: 60}}</small>
  </ng-container>
  <ng-container *ngIf="!formValues.description">
    <small class="mrg-top-10 no-pdd-btm transparent">{{'resources.coupons.modal.show.details.fields.description' | translate}}</small>
  </ng-container>
  <ul class="list-group no-pdd mrg-top-20" *ngIf="type === 'coupon'">
    <li class="list-group-item no-pdd no-border border-bottom">
      <label class="no-mrg text-secondary">
        {{'resources.coupons.modal.show.details.fields.locations' | translate }}
      </label>
      <p class="resource-value">
        {{getSpecificLocationData() | truncate: 25 }}
      </p>
    </li>
  </ul>
  <ul class="list-group no-pdd mrg-top-20" *ngIf="type === 'points_policy'">
    <li class="list-group-item no-pdd no-border border-bottom">
      <label class="no-mrg text-secondary">
        {{'components.resource-preview.fields.availability' | translate}}
      </label>
      <p class="resource-value">
        {{'components.resource-preview.block_b.countries' | translate: {countries: getCountrySymbols() | truncate: 10} }}
      </p>
    </li>

    <li class="list-group-item no-pdd no-border border-bottom">
      <label class="no-mrg text-secondary">
        {{'components.resource-preview.fields.limits' | translate}}
      </label>
      <p class="resource-value" *ngIf="formValues.once?.[0]?.id !== 'allways' && formValues.once_limit">
        {{'components.resource-preview.block_c.limits' | translate: {name: formValues.once?.[0]?.name, amount: formValues.once_limit } }}
      </p>
      <p class="resource-value" *ngIf="formValues.once?.[0]?.id == 'allways'">
        {{ formValues.once?.[0]?.name }}
      </p>
      <p class="resource-value" *ngIf="!formValues.once?.[0]?.id && !formValues.once_limit">
        -
      </p>
    </li>

    <li class="list-group-item no-pdd no-border border-bottom">
      <label class="no-mrg text-secondary">
        {{'components.resource-preview.fields.activation' | translate}}
      </label>
      <p class="resource-value" *ngIf="formValues.activation_delay && formValues.activation_delay_unit?.[0]?.name; else noActivation">
        {{'components.resource-preview.block_d.limits' | translate: {num: formValues.activation_delay, timeframe: formValues.activation_delay_unit?.[0]?.name | lowercase } }}
      </p>
      <ng-template #noActivation>
        <p class="resource-value">
          -
        </p>
      </ng-template>
    </li>

    <li class="list-group-item no-pdd no-border border-bottom" *ngIf="formValues.expiration_period_type == 'relative'">
      <label class="no-mrg text-secondary">
        {{'components.resource-preview.fields.expiration' | translate}}
      </label>
      <p class="resource-value" *ngIf="formValues.availability_duration && formValues.availability_duration_unit?.[0]?.name; else noExpiration">
        {{'components.resource-preview.block_e.limits' | translate: {num: formValues.availability_duration, timeframe: formValues.availability_duration_unit?.[0]?.name | lowercase } }}
      </p>
      <ng-template #noExpiration>
        <p class="resource-value">
          -
        </p>
      </ng-template>
    </li>

    <li class="list-group-item no-pdd no-border border-bottom" *ngIf="formValues.expiration_period_type == 'fixed'">
      <label class="no-mrg text-secondary">
        {{'components.resource-preview.fields.expiration' | translate}}
      </label>
      <p class="resource-value">
        {{formValues.fixed_option_selected?.[0]?.name}}
      </p>
    </li>

    <li class="list-group-item no-pdd no-border border-bottom">
      <label class="no-mrg text-secondary">
        {{'components.resource-preview.fields.transferable' | translate}}
      </label>
      <p class="resource-value mrg-top-5" *ngIf="formValues.transferable; else notTransferable">
        <em class="text-success fa-sharp-duotone fa-solid fa-circle-check font-size-20"></em>
      </p>
      <ng-template #notTransferable>
        <p class="resource-value mrg-top-5">
          <em class="text-danger fa-sharp-duotone fa-solid fa-circle-xmark font-size-20"></em>
        </p>
      </ng-template>
    </li>
  </ul>

  <ul class="list-group no-pdd mrg-top-20" *ngIf="type === 'reward'">
    <li class="list-group-item no-pdd no-border border-bottom">
      <label class="no-mrg text-secondary">
        {{'resources.scores.rewards.form.redemption_type' | translate}}
      </label>
      <p class="resource-value">
        {{'resources.scores.rewards.form.options.' + formValues.redemption_type | translate}}
      </p>
    </li>

    <li class="list-group-item no-pdd no-border border-bottom">
      <label class="no-mrg text-secondary">
        {{'resources.scores.rewards.form.type' | translate}}
      </label>
      <p class="resource-value" *ngIf="formValues.type?.[0]?.name; else noType">
        {{formValues.type?.[0]?.name}}
      </p>
      <ng-template #noType>
        <p class="resource-value">-</p>
      </ng-template>
    </li>

    <li class="list-group-item no-pdd no-border border-bottom">
      <label class="no-mrg text-secondary">
        {{'components.resource-preview.fields.expiration' | translate}}
      </label>
      <ng-container *ngIf="!formValues.activate_reward_validity">
        <p class="resource-value">-</p>
      </ng-container>
      <ng-container *ngIf="formValues.validity_duration_type == 'relative'; else noRewardExpiration">
        <p class="resource-value" *ngIf="formValues.validity_duration_number && formValues.validity_duration_unit_option?.[0]?.name; else noRewardExpiration">
          {{'components.resource-preview.block_e.limits' | translate: {num: formValues.validity_duration_number, timeframe: formValues.validity_duration_unit_option?.[0]?.name | lowercase } }}
        </p>
        <ng-template #noRewardExpiration>
          <p class="resource-value">-</p>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="formValues.validity_duration_type == 'fixed'">
        <p class="resource-value" *ngIf="formValues.validity_duration_date; else noRewardFixedExpiration">
          {{formValues.validity_duration_date | date: 'dd/MM/yyyy'}}
        </p>
        <ng-template #noRewardFixedExpiration>
          <p class="resource-value">-</p>
        </ng-template>
      </ng-container>
    </li>
  </ul>
</div>
