import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { BigqueryJobsStepsBase } from '../../../../../shared/models/bigquery-steps/bigquerystep-base';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { CustomStep } from '../../../../../shared/models/bigquery-steps/bigquerystep-custom';
import { DataStoreSynchroStep } from '../../../../../shared/models/bigquery-steps/bigquerystep-data-store-synchro';
import { DateTimeZoneService } from '../../../../../shared/services/date-time-zone.service';
import { DictionaryService } from '../../../../../shared/services/dictionary.service';
import { DumpStep } from '../../../../../shared/models/bigquery-steps/bigquerystep-dump';
import { DumpTablesService } from '../../../../../shared/services/dump-tables.service';
import { FeatureFlagsService } from './../../../../../shared/services/feature-flags.service';
import { GrantScoresStep } from '../../../../../shared/models/bigquery-steps/bigquerystep-grant-scores';
import { HttpErrorResponse } from '@angular/common/http';
import { LoyaltyScoresService } from '../../../../scores/loyalty_scores/loyalty-scores.service';
import { MultiSelectQuestion } from '../../../../../shared/models/forms/question-multiselect';
import { PeriodStep } from '../../../../../shared/models/bigquery-steps/bigquerystep-period';
import { PumpStep } from '../../../../../shared/models/bigquery-steps/bigquerystep-pump';
import { PumpTablesService } from '../../../../../shared/services/pump-tables.service';
import { QuestionBase } from '../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../shared/services/question-control.service';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { SegmentCategoriesService } from '../../../../segments/segment-categories.service';
import { SegmentCategoryStep } from '../../../../../shared/models/bigquery-steps/bigquerystep-segmentcategory';
import { SegmentsService } from '../../../../segments/segments.service';
import { SegmentStep } from '../../../../../shared/models/bigquery-steps/bigquerystep-segment';
import { Subject, takeUntil } from 'rxjs';
import { SynchroStep } from '../../../../../shared/models/bigquery-steps/bigquerystep-synchro';
import { TextboxQuestion } from '../../../../../shared/models/forms/question-textbox';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
  providers: [SegmentsService, SegmentCategoriesService, DictionaryService, LoyaltyScoresService]
})

export class StepsComponent implements OnInit {

  currentStep: {index: number, step: BigqueryJobsStepsBase};
  disableAddStepBtn: boolean;
  flags = this.featureFlags.flags;
  loadingSegment: boolean;
  loyaltyRuleInfo: {
    rawElement: {
      available_from: string;
      available_to: string;
    }
  };
  mode: 'new' | 'edit';
  segmentReport: unknown;
  segmentSelected: unknown;
  stepsForm: UntypedFormGroup;
  stepsInputs: QuestionBase<unknown>[];
  todayDate: string;

  @Output('saveEmitter') saveEmitter: EventEmitter<{ step: BigqueryJobsStepsBase, currentStep?: BigqueryJobsStepsBase }>;
  @Output('removeEmitter') removeEmitter: EventEmitter<number>;

  private destroy$ = new Subject<void>();

  constructor(
    private confirmationService: ConfirmationService,
    private dateService: DateTimeZoneService,
    private dumpTablesSrv: DumpTablesService,
    private featureFlags: FeatureFlagsService,
    private loyaltyScoresSrv: LoyaltyScoresService,
    private pumpTablesSrv: PumpTablesService,
    private qcs: QuestionControlService,
    private refreshCacheService: RefreshCacheService,
    private segmentsCategoriesSrv: SegmentCategoriesService,
    private segmentsSrv: SegmentsService,
    private translate: TranslateService
  ) {
    this.mode = 'new';
    this.removeEmitter = new EventEmitter();
    this.saveEmitter = new EventEmitter();
  }

  ngOnInit() {
    const today = new Date();
    this.todayDate = this.dateService.momentFormat(today, 'YYYY-MM-DD');
    this.stepsInputs = this.getInputs();
    this.stepsForm = this.qcs.toFormGroup(this.stepsInputs);
    this.stepsForm.setValidators(this.getValidations());
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.stepsInputs, inputKey);
  }

  hasFormKeyWithValue(formKey: string, value: any): boolean {
    return this.qcs.hasFormKeyWithValue(this.stepsForm, formKey, value);
  }

  openSegment() {
    if ( this.stepsForm.get('segment').value && this.stepsForm.get('segment').value.length ) {
      const segmentId = this.stepsForm.get('segment').value[0].id;
      window.open(`#/segments/segments/${segmentId}`, '_blank');
    }
  }

  getTranslationParams() {
    const params: any = {
      numberOfCustomers: this.segmentReport?.['count'],
      policyId: this.stepsForm?.value?.loyalty_score?.[0]?.id,
      policyName: this.stepsForm?.value?.loyalty_score?.[0]?.name,
      availableDates: '',
    };

    const availableFrom = this.loyaltyRuleInfo?.rawElement?.available_from;
    const availableTo = this.loyaltyRuleInfo?.rawElement?.available_to;
    const userLocale = this.translate.store.currentLang;

    const formattedFrom = availableFrom
      ? new Date(availableFrom).toLocaleDateString(userLocale)
      : null;
    const formattedTo = availableTo
      ? new Date(availableTo).toLocaleDateString(userLocale)
      : null;

    if (formattedFrom && formattedTo) {
      params.availableDates = this.translate.instant('resources.scores.loyalty_scores.warnings.available_dates_from_to', {
        policyAvailableFrom: formattedFrom,
        policyAvailableTo: formattedTo,
      });
    } else if (formattedFrom) {
      params.availableDates = this.translate.instant('resources.scores.loyalty_scores.warnings.available_dates_from', {
        policyAvailableFrom: formattedFrom,
      });
    } else if (formattedTo) {
      params.availableDates = this.translate.instant('resources.scores.loyalty_scores.warnings.available_dates_to', {
        policyAvailableTo: formattedTo,
      });
    }

    return params;
  }

  pointsRuleSelectorChange(selectedRule) {
    this.loyaltyRuleInfo = selectedRule;
  }

  segmentSelectorChange(event) {
    this.segmentSelected = null;
    this.segmentReport = null;
    if(event?.id) {
      this.segmentSelected = event;

      const params = { apiEndPoint: `segments/${event.id}/report?current=true` };
      this.disableAddStepBtn = true;

      return new Promise<void>((resolve, reject) => {
        this.refreshCacheService.getExpensiveData(params).pipe(takeUntil(this.destroy$)).subscribe({
          next: (response) => {
            if (!this.refreshCacheService.isRequestPending(response)) {
              this.loadingSegment = false;
              this.segmentReport = response;
              this.disableAddStepBtn = false;
              resolve();
            }
          },
          error: (error: HttpErrorResponse) => {
            this.loadingSegment = false;
            this.disableAddStepBtn = false;
            this.segmentSelected = null;
            this.confirmationService.displayHttpErrorAlert(error);
            reject(error);
          }
        });
      });
    }
  }

  editStep(step: BigqueryJobsStepsBase, index: number) {
    this.mode = 'edit';
    this.currentStep = {index: index, step: step};

    let segmentInput: QuestionBase<any>;
    const newValues = {category: [this.getStepTypes().find( item => item.id === step.category)]};

    switch (step.category) {

      case 'custom':
        const typeInput = this.stepsInputs.find(input => input.key === 'custom');
        if ((step as CustomStep).custom) {
          typeInput.value = typeInput.getValue((step as CustomStep).custom);
        }
        break;

      case 'segment':
        segmentInput = this.stepsInputs.find(input => input.key === 'segment');
        segmentInput.value = [];
        segmentInput.selectedIds = [];
        segmentInput.selectedIds = [(step as SegmentStep).segment_id];
        break;

      case 'segment_category':
        const segmentCategoryInput = this.stepsInputs.find(input => input.key === 'segment_category');
        segmentCategoryInput.value = [];
        segmentCategoryInput.selectedIds = [];
        segmentCategoryInput.selectedIds = [(step as SegmentCategoryStep).segment_category_id];
        break;

      case 'pump':
        const pumpInput = this.stepsInputs.find(input => input.key === 'pump');
        const pumpType = this.stepsInputs.find(input => input.key === 'pump_cut_condition');

        if ((step as PumpStep).tableName) {
          pumpInput.value = [];
          pumpInput.selectedIds = [(step as PumpStep).tableName];
        }

        if ((step as PumpStep).pumpCutCondition) {
          const selectedOption = pumpType.options.find(option => option.id === (step as PumpStep).pumpCutCondition);
          pumpType.value = [selectedOption];
          this.stepsForm.get('pump_cut_condition').patchValue([selectedOption]);
        }
        break;

      case 'dump':
        const dumpInput = this.stepsInputs.find(input => input.key === 'dump');
        if ((step as DumpStep).tableName) {
          dumpInput.value = [];
          dumpInput.selectedIds = [];
          dumpInput.selectedIds = [(step as DumpStep).tableName];
        }
        break;

      case 'grant_scores':
        const lScoresInput = this.stepsInputs.find(input => input.key === 'loyalty_score');
        lScoresInput.value = [];
        lScoresInput.selectedIds = [];
        lScoresInput.selectedIds = [(step as GrantScoresStep).config.loyalty_score_id];

        segmentInput = this.stepsInputs.find(input => input.key === 'segment');
        segmentInput.value = [];
        segmentInput.selectedIds = [];
        segmentInput.selectedIds = [(step as GrantScoresStep).config.segment_id];

        const commentsInput = this.stepsInputs.find(input => input.key === 'comments');

        if ((step as GrantScoresStep).config.comments) {
          commentsInput.value = commentsInput.getValue((step as GrantScoresStep).config.comments);
        }

        break;
    }
    this.stepsForm.patchValue(newValues, {emitEvent: false});
  }

  resetForm() {
    this.mode = 'new';
    this.currentStep = null;
    this.stepsForm.reset();
  }

  saveStep() {
    const categorySelected = this.stepsForm.get('category').value ? this.stepsForm.get('category').value[0].id : null;
    let step: BigqueryJobsStepsBase;
    let segmentId: number;

    switch (categorySelected) {
      case 'custom':
        const typeSelected = this.stepsForm.get('custom').value ? this.stepsForm.get('custom').value[0].id : null;
        step = new CustomStep({custom: typeSelected});
        break;
      case 'segment':
        const segmentName = this.stepsForm.get('segment').value ? this.stepsForm.get('segment').value[0].name : null;
        segmentId = this.stepsForm.get('segment').value ? this.stepsForm.get('segment').value[0].id : null;
        step = new SegmentStep({
          name: segmentName,
          segment_id: segmentId
        });
        break;
      case 'segment_category':
        const segmentCategoryName = this.stepsForm.get('segment_category').value ? this.stepsForm.get('segment_category').value[0].name : null;
        const segmentCategoryId = this.stepsForm.get('segment_category').value ? this.stepsForm.get('segment_category').value[0].id : null;
        step = new SegmentCategoryStep({
          name: segmentCategoryName,
          segment_category_id: segmentCategoryId
        });
        break;
      case 'synchro':
        const synchro = this.stepsForm.get('category').value ? this.stepsForm.get('category').value[0].id : null;
        step = new SynchroStep({category: synchro});
        break;
      case 'datastore_synchro':
        const datastore_synchro = this.stepsForm.get('category').value ? this.stepsForm.get('category').value[0].id : null;
        step = new DataStoreSynchroStep({category: datastore_synchro});
        break;
      case 'pump':
        const tableName = this.stepsForm.get('pump').value ? this.stepsForm.get('pump').value[0].id : null;
        const pumpType = this.stepsForm.get('pump_cut_condition').value ? this.stepsForm.get('pump_cut_condition').value[0].id : null;
        step = new PumpStep({
          table_name: tableName,
          file_name: `_${tableName}`,
          pump_cut_condition: pumpType
        });
        break;
      case 'dump':
        const dumpTableName = this.stepsForm.get('dump').value ? this.stepsForm.get('dump').value[0].id : null;
        step = new DumpStep({table_name: dumpTableName});
        break;
      case 'period':
        const period = this.stepsForm.get('category').value ? this.stepsForm.get('category').value[0].id : null;
        step = new PeriodStep({category: period});
        break;
      case 'grant_scores':
        const loyaltyScoreId = this.stepsForm.get('loyalty_score').value ? this.stepsForm.get('loyalty_score').value[0].id : null;
        segmentId = this.stepsForm.get('segment').value ? this.stepsForm.get('segment').value[0].id : null;
        const comments = this.stepsForm.get('comments').value;
        step = new GrantScoresStep({
          name: this.translate.instant('resources.jobs.modal.fields.step_categories.grant_scores'),
          category: 'grant_scores',
          config: {
            loyalty_score_id: loyaltyScoreId,
            segment_id: segmentId,
            comments: comments
          }
        });
        break;
      default: break;
    }

    if (this.currentStep) {
      this.saveEmitter.emit({step: step, currentStep: this.currentStep.step});
    } else {
      this.saveEmitter.emit({step: step});
    }

    this.resetForm();
  }

  private getStepTypes(): {id: string, name: string}[] {
    const stepTypes = [
      { id: 'custom', name: this.translate.instant('resources.jobs.modal.fields.step_categories.custom') },
      { id: 'segment', name: this.translate.instant('resources.jobs.modal.fields.step_categories.segment') },
      { id: 'segment_category', name: this.translate.instant('resources.jobs.modal.fields.step_categories.segment_category') },
      { id: 'synchro', name: this.translate.instant('resources.jobs.modal.fields.step_categories.synchro') },
      { id: 'datastore_synchro', name: this.translate.instant('resources.jobs.modal.fields.step_categories.datastore_synchro') },
      { id: 'pump', name: this.translate.instant('resources.jobs.modal.fields.step_categories.pump') },
      { id: 'period', name: this.translate.instant('resources.jobs.modal.fields.step_categories.period') },
      { id: 'grant_scores', name: this.translate.instant('resources.jobs.modal.fields.step_categories.grant_scores') }
    ];
    if(this.flags.showDataUploadOption){
      stepTypes.push({id: 'dump', name: this.translate.instant('resources.jobs.modal.fields.step_categories.dump')})
    };
    return stepTypes;
  }

  private getCustomTypes() {
    return [
      { id: 'DashboardsClearCache', name: this.translate.instant('resources.jobs.modal.fields.step_categories.custom_type.clear_cache') },
      { id: 'ExpensiveQueryClearCache', name: this.translate.instant('resources.jobs.modal.fields.step_categories.custom_type.expensive_query_clear_cache') }
    ];
  }

  private getPumpTypes() {
    return [
      { id: 'full', name: this.translate.instant('resources.jobs.pump_cut_conditions.full') },
      { id: 'by_id', name: this.translate.instant('resources.jobs.pump_cut_conditions.by_id') },
      { id: 'daily', name: this.translate.instant('resources.jobs.pump_cut_conditions.daily') },
      { id: 'daily_hard', name: this.translate.instant('resources.jobs.pump_cut_conditions.daily_hard') },
      { id: 'fortnightly', name: this.translate.instant('resources.jobs.pump_cut_conditions.fortnightly') }
    ];
  }

  private getInputs(): QuestionBase<any>[] {

    const inputs = [
      new MultiSelectQuestion({
        key: 'category',
        label: 'resources.jobs.modal.fields.step_type',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        options: this.getStepTypes(),
        required: true,
        getValue: (value) => ([this.getStepTypes().find( item => item.id === value)])
      }),
      new MultiSelectQuestion({
        key: 'custom',
        label: 'resources.jobs.modal.fields.type',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        options: this.getCustomTypes(),
        required: true,
        getValue: (value) => ([this.getCustomTypes().find( item => item.id === value)])
      }),
      new MultiSelectQuestion({
        key: 'segment',
        label: 'resources.jobs.modal.fields.segment',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.segmentsSrv,
        required: true
      }),
      new MultiSelectQuestion({
        key: 'segment_category',
        label: 'resources.jobs.modal.fields.segment_category',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.segmentsCategoriesSrv,
        required: true
      }),
      new MultiSelectQuestion({
        key: 'pump',
        label: 'resources.jobs.modal.fields.pump_table',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.pumpTablesSrv,
        required: true,
      }),
      new MultiSelectQuestion({
        key: 'pump_cut_condition',
        label: 'resources.jobs.modal.fields.pump_cut_condition',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        options: this.getPumpTypes(),
        required: true,
        getValue: (value) => ([this.getPumpTypes().find( item => item.id === value)])
      }),
      new MultiSelectQuestion({
        key: 'dump',
        label: 'resources.jobs.modal.fields.pump_table',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: false },
        dataSource: this.dumpTablesSrv,
        required: true,
      }),
      new MultiSelectQuestion({
        key: 'loyalty_score',
        label: 'resources.jobs.modal.fields.loyalty_score',
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, enableCheckAll: false, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.loyaltyScoresSrv,
        required: true
      }),
      new TextboxQuestion({
        key: 'comments',
        cssClasses: 'form-control input-default',
        label: 'resources.jobs.modal.fields.comments',
        type: 'text',
        getValue: (value) => value ? value : null
      }),
    ];
    return inputs;
  }

  private getValidations(): ValidatorFn[] {
    const ensureCategory = ((_ctrl: AbstractControl) => {
      const categoryCtrl = _ctrl.get('category');
      const isCategoryPresent = categoryCtrl.value && categoryCtrl.value.length > 0 && categoryCtrl.value[0].id;
      if (!isCategoryPresent) {
        return {categoryRequired: true};
      }
      return null;
    });

    const ensureTypeIfCustom = ((_ctrl: AbstractControl) => {
      const categoryCtrl = _ctrl.get('category');
      const customCtrl = _ctrl.get('custom');
      const categoryValue = categoryCtrl.value && categoryCtrl.value.length > 0 && categoryCtrl.value[0].id;
      const isTypePresent = customCtrl.value && customCtrl.value.length > 0 && customCtrl.value[0].id;
      if (categoryValue === 'custom' && !isTypePresent) {
        return {typeRequiredIfCustomCategory: true};
      }
      return null;
    });

    const ensureSegmentIdIfSegment = ((_ctrl: AbstractControl) => {
      const categoryCtrl = _ctrl.get('category');
      const segmentCtrl = _ctrl.get('segment');
      const categoryValue = categoryCtrl.value && categoryCtrl.value.length > 0 && categoryCtrl.value[0].id;
      const isSegmentIdPresent = segmentCtrl.value && segmentCtrl.value.length > 0 && segmentCtrl.value[0].id;
      if (categoryValue === 'segment' && !isSegmentIdPresent) {
        return {segmentIdRequiredIfSegment: true};
      }
      return null;
    });

    const ensureSegmentCategoryIdIfSegmentCategory = ((_ctrl: AbstractControl) => {
      const categoryCtrl = _ctrl.get('category');
      const segmentCategoryCtrl = _ctrl.get('segment_category');
      const categoryValue = categoryCtrl.value && categoryCtrl.value.length > 0 && categoryCtrl.value[0].id;
      const isSegmentCategoryIdPresent = segmentCategoryCtrl.value && segmentCategoryCtrl.value.length > 0 && segmentCategoryCtrl.value[0].id;
      if (categoryValue === 'segment_category' && !isSegmentCategoryIdPresent) {
        return {segmentCategoryIdRequiredIfSegmentCategory: true};
      }
      return null;
    });

    const ensurePumpCfgIfPump = ((_ctrl: AbstractControl) => {
      const categoryCtrl = _ctrl.get('category');
      const pumpCtrl = _ctrl.get('pump');
      const cutCtrl = _ctrl.get('pump_cut_condition');

      const categoryValue = categoryCtrl.value && categoryCtrl.value.length > 0 && categoryCtrl.value[0].id;
      const isPumpPresent = pumpCtrl.value && pumpCtrl.value.length > 0 && pumpCtrl.value[0].id;
      const isCutCfgPresent = cutCtrl.value && cutCtrl.value.length > 0 && cutCtrl.value[0].id;

      if (categoryValue === 'pump' && !isPumpPresent) {
        return {pumpValueRequiredIfPump: true};
      }
      if (categoryValue === 'pump' && !isCutCfgPresent) {
        return {cutConditionRequiredIfPump: true};
      }
      return null;
    });

    const ensureDumpCfgIfDump = ((_ctrl: AbstractControl) => {
      const categoryCtrl = _ctrl.get('category');
      const dumpCtrl = _ctrl.get('dump');

      const categoryValue = categoryCtrl.value && categoryCtrl.value.length > 0 && categoryCtrl.value[0].id;
      const isDumpPresent = dumpCtrl.value && dumpCtrl.value.length > 0 && dumpCtrl.value[0].id;

      if (categoryValue === 'dump' && !isDumpPresent) {
        return {dumpValueRequiredIfDump: true};
      }

      return null;
    })

    const ensureGrantScoresIfGrantScore = ((_ctrl: AbstractControl) => {
      const categoryCtrl = _ctrl.get('category');
      const loyaltyScoresCtrl = _ctrl.get('loyalty_score');
      const segmentCtrl = _ctrl.get('segment');

      const categoryValue = categoryCtrl.value && categoryCtrl.value.length > 0 && categoryCtrl.value[0].id;
      const isLoyaltyScorePresent = loyaltyScoresCtrl.value && loyaltyScoresCtrl.value.length > 0 && loyaltyScoresCtrl.value[0].id;
      const isSegmentIdPresent = segmentCtrl.value && segmentCtrl.value.length > 0 && segmentCtrl.value[0].id;

      if (categoryValue === 'grant_scores' && !isLoyaltyScorePresent) {
        return {loyaltyScoreRequiredIfGrantScores: true};
      }

      if (categoryValue === 'grant_scores' && !isSegmentIdPresent) {
        return {segmentRequiredIfGrantScores: true};
      }

      return null;
    });

    Object.keys(this.stepsForm.controls).forEach((key) => this.stepsForm.get(key).setValidators([]));

    return [
      ensureCategory,
      ensureTypeIfCustom,
      ensureSegmentIdIfSegment,
      ensureSegmentCategoryIdIfSegmentCategory,
      ensurePumpCfgIfPump,
      ensureDumpCfgIfDump,
      ensureGrantScoresIfGrantScore
    ];
  }

}
