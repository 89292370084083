<ng-container *ngIf="onlyPreview; else previewAndProduction">
  <div class="alert alert-warning d-flex align-items-center space-between" role="alert">
    <span>
      {{ 'components.looker-iframe.alerts.preview' | translate }}
    </span>
  </div>
</ng-container>

<ng-template #previewAndProduction>
  <div class="alert alert-info d-flex align-items-center space-between pdd-left-20 pdd-right-0 pdd-vertical-5" role="alert" *ngIf="mode === 'production'">
    <span>
      {{'components.preview-warning.messages.prod_dashboard' | translate }}
    </span>
    <button
      class="btn btn-icon btn-default btn-sm pdd-vertical-10 no-mrg-vertical pdd-vertical-10"
      (click)="setMode('preview')"
    >
      <i class="fa-duotone fa-solid fa-magnifying-glass-play font-size-18 text-info"></i>
      {{'components.preview-warning.buttons.see_preview' | translate}}
    </button>
  </div>

  <div class="alert alert-warning d-flex align-items-center space-between pdd-left-20 pdd-right-0 pdd-vertical-5" role="alert" *ngIf="mode === 'preview'">
    <span>
      {{ 'components.preview-warning.messages.preview_dashboard' | translate }}
    </span>
    <button
      class="btn btn-icon btn-default btn-sm pdd-vertical-10 no-mrg-vertical pdd-vertical-10"
      (click)="setMode('production')"
    >
      <i class="fa-duotone fa-solid fa-magnifying-glass-waveform font-size-18 text-warning"></i>
      {{'components.preview-warning.buttons.see_production' | translate}}
    </button>
  </div>
</ng-template>

