import {Component, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import { QuestionBase } from '../../../../shared/models/forms/question-base';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../../../shared/services/question-control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalStatusService } from '../../../../shared/services/modal-status.service';
import { Subscription } from 'rxjs';
import { WalletsService } from '../../wallets.service';
import {RewardsService} from '../../rewards/rewards.service';
import {LoyaltyScoresService} from '../../loyalty_scores/loyalty-scores.service';

@Component({
  selector: 'app-form-wallets',
  templateUrl: './form-wallets.component.html',
  styleUrls: ['./form-wallets.component.css'],
  providers: [WalletsService, RewardsService, LoyaltyScoresService]
})
export class FormWalletsComponent implements OnInit, OnDestroy {

  @Output() fileChanged: EventEmitter<any> = new EventEmitter<any>();

  public walletsForm: UntypedFormGroup;
  public inputs: QuestionBase<any>[];
  public id: number;
  public showButton: boolean;
  public loading: boolean;
  public walletsData: any;
  private subs$: Subscription[];

  constructor(private qcs: QuestionControlService,
              private route: ActivatedRoute,
              private router: Router,
              private walletsService: WalletsService,
              private rewardsService: RewardsService,
              private confirmationService: ConfirmationService,
              private modalStatusService: ModalStatusService,
              private translate: TranslateService) {
    this.subs$ = [];
  }

  ngOnInit() {
    this.getParams();
    this.showBackButton();
  }

  ngOnDestroy() {
    this.subs$.forEach(sub$ => sub$.unsubscribe());
  }

  showBackButton() {
    this.showButton = !(this.router.url.indexOf('(modal:') >= 0);
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  private getParams() {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.id = params.id;
        this.getWalletsById(this.id);
      } else {
        this.inputs = this.walletsService.getInputs({});
        this.walletsForm = this.qcs.toFormGroup(this.inputs);
      }
    });
  }

  private getWalletsById(id: number) {
    const walletSubscription = this.walletsService.getWalletById(id).subscribe(
      data => {
        walletSubscription.unsubscribe();
        this.walletsData = data;
        this.inputs = this.walletsService.getInputs(data);
        this.walletsForm = this.qcs.toFormGroup(this.inputs);
      },
      error => {
        walletSubscription.unsubscribe();
        this.confirmationService.displayErrorAlert('Error', error.error.error);
      }
    );
    this.subs$.push(walletSubscription);
  }

  public async sendData() {
    const points = Number(this.walletsForm.value.points);
    const reward_id = this.walletsForm.value.reward_id && this.walletsForm.value.reward_id.length > 0 ? this.walletsForm.value.reward_id[0].id : null;

    if (reward_id) {

      const reward = await this.rewardsService.getRewardsById(reward_id).toPromise();

      if (reward && reward.points < points) {
        this.confirmationService.displayErrorAlert('Error', this.translate.instant('resources.scores.wallets.form.warnings.reward_warning_text'));
        return false;
      }

      if (reward && reward.points > points) {
        this.confirmationService.displayErrorAlert('Error', this.translate.instant('resources.scores.wallets.form.warnings.reward_error_text'));
        return false;
      }
    }

    const walletsOptions = {
      name: this.walletsForm.value.name,
      reward_id: reward_id,
      points: points,
    };

    this.modalStatusService.formLoaderStatus.emit('loading');
    this.walletsService.createWallet(walletsOptions).subscribe(
      response => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.scores.wallets.form.warnings.success_title'),
          this.translate.instant('resources.scores.wallets.form.warnings.success_text')
        ).catch(() => {});
        this.modalStatusService.formLoaderStatus.emit('loading_finished');
        this.modalStatusService.modalStatus.emit();
        this.returnToList();
      },
      errorData => {
        this.modalStatusService.formLoaderStatus.emit('loading_finished');
        if (errorData.error.errors && errorData.error.errors.length > 0) {
          this.qcs.paintErrorsInForm(this.inputs, this.walletsForm, errorData.error.errors);
        } else if (errorData.error && errorData.error.error) {
          this.confirmationService.displayErrorAlert('Error', errorData.error.error);
        }
      }
    );
  }

  public returnToList() {
    if (this.router.url.indexOf('(modal:') >= 0 ) {
      this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
    } else {
      this.router.navigate(['/scores/wallets']).catch(() => {});
    }
  }

}
