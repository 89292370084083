import { Component, Input, OnInit, EventEmitter, Output, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../models/forms/question-base';
import { QuestionControlService } from '../../services/question-control.service';
import { DataTableFilterService } from './data-table-filter.service';
import { Subscription } from 'rxjs';
import { FeaturesService } from '../../../resources/data-warehouse/products/services/products-features/features.service';
import { FeatureTaxonomiesService } from '../../../resources/data-warehouse/products/services/products-feature-taxonomies/feature-taxonomies.service';
import { ProductsService } from '../../../resources/data-warehouse/products/services/products/products.service';
import { FilterConfiguration } from './data-table-filter-cfg';
import { DateService } from '../../services/date.service';
import { LocationsTaxonomyTermsService } from '../../../resources/data-warehouse/locations/location-taxonomy-terms.service';
import { LocationsService } from '../../../resources/data-warehouse/locations/locations.service';
import { isNullOrUndefined } from '../../utils/common.utils';

@Component({
  selector: 'app-data-table-filter',
  templateUrl: './data-table-filter.component.html',
  styleUrls: ['./data-table-filter.component.scss'],
  providers: [ QuestionControlService ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTableFilterComponent implements OnInit, OnDestroy {

  @Input() slug: string;
  @Input() questions: QuestionBase<any>[];
  @Input() cfg: FilterConfiguration;
  @Input() isDynamicTabData: boolean;
  @Input() flags = null;

  @Output() onSubmitEmitter: EventEmitter<any>;
  @Output() onClearEmitter: EventEmitter<any>;
  @Output() onFiltersChangeValue: EventEmitter<any>;

  form: UntypedFormGroup;
  payLoad: Object;
  disableBtns: boolean;
  standardFilters: QuestionBase<any>[];
  advancedFilters: QuestionBase<any>[];
  preStandardFilters: QuestionBase<any>[];
  checkboxRowFilters: QuestionBase<any>[];
  subs$: Subscription[];

  requestInterval;
  requestIntervalDelay = 2000;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly dateService: DateService,
    private readonly featuresService: FeaturesService,
    private readonly filterService: DataTableFilterService,
    private readonly locationsService: LocationsService,
    private readonly productsService: ProductsService,
    private readonly qcs: QuestionControlService
  ) {
    this.cfg = {
      disableOnInit: false,
      disableOnSubmit: false,
      disableSubmitBtn: false,
      dashboardPerUnitMeasure: false,
      ensureButtonDisabling: false
    };
    this.standardFilters = [];
    this.advancedFilters = [];
    this.preStandardFilters = [];
    this.checkboxRowFilters = [];
    this.payLoad = {};
    this.questions = [];
    this.subs$ = [];
    this.isDynamicTabData = false;
    this.onSubmitEmitter = new EventEmitter();
    this.onClearEmitter = new EventEmitter();
    this.onFiltersChangeValue = new EventEmitter();
  }

  ngOnInit() {
    this.divideFilterInputs();
    this.sortFilterInputs();
    this.form = this.qcs.toFormGroup(this.questions);
    this.setFormSubscriptions();
    this.handleBtnDisabling();
    setTimeout(() => this.filterService.filtersLoaderStatus.next(true));
  }

  ngOnDestroy() {
    this.subs$.forEach(s$ => s$.unsubscribe());
    if ( this.requestInterval ) { clearTimeout(this.requestInterval); }
  }

  onSubmit() {
    this.payLoad = this.cleanUndefinedFormValues(this.form.value);
    this.payLoad = this.transformObjectsIntoStrings(this.payLoad);
    this.onSubmitEmitter.emit(this.payLoad);
    this.handleBtnDisabling();
  }

  clearAllFilters() {
    const inputKeys = this.questions.map(question => question.key);
    inputKeys.forEach(key => this.form.get(key).reset());
    this.onClearEmitter.emit(this.form.value);
  }

  // TODO: Abstract methods to be injected in filter configuration (from tab component)
  handleFilterChanges(value, filter: QuestionBase<any>) {
    if (filter.dataSource && filter.dataSource instanceof FeatureTaxonomiesService) {
      this.handleFeaturesFilterByTaxonomies(value);
    }

    if (filter.dataSource && filter.dataSource instanceof FeaturesService && (filter.key !== 'supplier' && filter.key !== 'brand_pk')) {
      this.handleProductsFilterByFeaturesPks();
    }

    if (this.isDynamicTabData && filter.dataSource && filter.dataSource instanceof LocationsTaxonomyTermsService  && filter.key === 'location_taxonomy_term_ids') {
      this.handleLocationsFilterByLocationTaxonomy();
    }

    if (this.cfg.customCallbackOnFilterChanged){
      this.cfg.customCallbackOnFilterChanged(value, filter, this.questions, this.form);
    }
  }
  
  private handleFeaturesFilterByTaxonomies(value) {
    if (this.questions.find(item => item.dataSource instanceof FeaturesService)) {
      if (value) {
        this.featuresService.setTaxonomies(value.id);
        this.questions.find(item => item.key === 'feature_ids').value = [];
        this.form.patchValue({feature_ids: []});
        this.resetFilter('product_ids');
      } else {
        this.featuresService.setTaxonomies(null);
      }

      const featuresFilter = this.questions.find(item => item.key === 'feature_ids');
      const productsFilter = this.questions.find(item => item.key === 'product_ids');
      if (productsFilter) {
          if (featuresFilter.value && featuresFilter.value.length > 0) {
            const featuresValues = featuresFilter.value.map(feature => feature.rawElement.pk).join(',');
            this.productsService.setFeatures(featuresValues);
          } else {
            this.productsService.setFeatures(null);
          }
      }
    }
  }

  private handleProductsFilterByFeaturesPks() {
    let featuresValues;
    const featureFilter = this.questions.find(item => item.dataSource instanceof FeaturesService);
    const features = this.form.get(featureFilter.key).value;
    if (features && features.length > 0  && !isNullOrUndefined(features[0])) {
      featuresValues = features.map(feature => feature.rawElement.pk).join(',');
      this.resetFilter('product_ids');
    }
    if ( featuresValues ) {
      this.productsService.setFeatures(featuresValues);
    } else {
      this.productsService.setFeatures(null);
    }
  }
  
  private handleLocationsFilterByLocationTaxonomy() {
    const locationCategoriesFilter = this.questions.find(item => item.key === 'location_taxonomy_term_ids');
    const locationsFilter = this.questions.find(item => item.key === 'location_ids');
    if (locationsFilter) {
      if (locationCategoriesFilter.value && locationCategoriesFilter.value.length > 0) {
        this.resetFilter('location_ids')
        const locationCategoriesValues = locationCategoriesFilter.value.map(locationCategory => locationCategory.id).join(',');
        this.locationsService.setLocationTaxonomyTermIds(locationCategoriesValues);
      } else {
        this.locationsService.setLocationTaxonomyTermIds(null);
      }
    }
  }
  
  private resetFilter(filterKey: string) {
    const filter = this.questions.find(item => item.key === filterKey);
    if (filter) {
      filter.value = null;
      this.form.get(filterKey).patchValue(null);
    }
  }

  private handleBtnDisabling() {
    if ( !this.cfg ) { return; }

    if ( this.cfg.disableOnInit ) {
      this.disableBtns = true;
      this.questions.forEach( question => { question.disabled = true; });
    }

    if ( this.cfg.disableOnSubmit ) {  this.disableBtns = true; }

    const filterService$ = this.filterService.loaderStatus$.subscribe(
      loadersCompleted => {
        // if at least 1 xtra configs is true, means inputs are disabled
        const disableInputs = this.xtraFilterCfg() ? !loadersCompleted : (!loadersCompleted || !this.form.valid);
        this.disableBtns = !loadersCompleted || !this.form.valid;
        this.questions.forEach(question => question.disabled = disableInputs);
        this.changeDetector.detectChanges();
      }
    );
    this.subs$.push(filterService$);
    this.changeDetector.detectChanges();
  }

  private xtraFilterCfg() {
    return this.cfg.dashboardPerUnitMeasure || this.cfg.ensureButtonDisabling;
  }

  private divideFilterInputs() {
    const vm = this;
    this.questions.forEach(function(question){
      if (question.preStandard) {
        vm.preStandardFilters.push(question);
      } else if (question.advanced) {
        vm.advancedFilters.push(question);
      } else if (question.checkboxRow) {
        vm.checkboxRowFilters.push(question);
      } else {
        vm.standardFilters.push(question);
      }
    });
  }

  private sortFilterInputs() {
    this.preStandardFilters.sort((a, b) => a.order - b.order);
    this.standardFilters.sort((a, b) => a.order - b.order);
    this.advancedFilters.sort((a, b) => a.order - b.order);
  }

  private cleanUndefinedFormValues(formValues): Object {
    Object.keys(formValues).forEach(function (key) {
      if (formValues[key] === 'null' ||
          formValues[key] === null ||
          formValues[key] === undefined ||
          formValues[key] === '' ||
          (formValues[key] instanceof Array && formValues[key].length === 0)) {
        delete formValues[key];
      }
    });
    return formValues;
  }

  // Prepare multiselect values
  private transformObjectsIntoStrings(formValues): Object {
    Object.keys(formValues).forEach(
      key => {
        if (Array.isArray(formValues[key])) {
          const preparedValues: string[] = [];
          formValues[key].forEach(
            multiselectOption => {
              if (multiselectOption.id) {
                preparedValues.push(multiselectOption.id);
              }
            }
          );
          formValues[key] = preparedValues.join();
        }
      }
    );

    return formValues;
  }

  private setFormSubscriptions() {
    const formChanges$ = this.form.valueChanges.subscribe(data => {
      this.onFiltersChangeValue.emit(data)
    });

    if (this.cfg && this.cfg.comparableDatesFilters) {
      const compareWithValue = this.form.get('compare_with').value;
      this.questions.find(question => question.key === 'date_from_compared').hidden = !compareWithValue;
      this.questions.find(question => question.key === 'date_to_compared').hidden = !compareWithValue;
      
      const formDateFrom$ = this.form.get('date_from').valueChanges.subscribe((dateFrom) => {
        if (!this.form.get('compare_with').value) {
          this.form.get('date_from_compared').patchValue(this.dateService.calculateDate('substract', 1, 'year', dateFrom));
        }
      });

      const formDateTo$ = this.form.get('date_to').valueChanges.subscribe((dateTo) => {
        if (!this.form.get('compare_with').value) {
          this.form.get('date_to_compared').patchValue(this.dateService.calculateDate('substract', 1, 'year', dateTo));
        }
      });
      
      const formCompareWith$ = this.form.get('compare_with').valueChanges.subscribe((compareWith) => {
        this.questions.find(question => question.key === 'date_from_compared').hidden = !compareWith;
        this.questions.find(question => question.key === 'date_to_compared').hidden = !compareWith;

        const dateFromValue = this.form.get('date_from').value;
        const dateToValue = this.form.get('date_to').value;
        
        this.form.get('date_from_compared').patchValue(this.dateService.calculateDate('substract', 1, 'year', dateFromValue));
        this.form.get('date_to_compared').patchValue(this.dateService.calculateDate('substract', 1, 'year', dateToValue));
      });

      this.subs$.push(formDateFrom$, formDateTo$, formCompareWith$);
    }
    
    const formStatus$ = this.form.statusChanges.subscribe( result => {
      this.disableBtns = result === 'INVALID';
    });

    this.subs$.push(formChanges$, formStatus$);
  }
}
