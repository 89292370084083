import { Component, Input } from '@angular/core';
import { QuestionBase } from '../../../../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../../../../shared/services/question-control.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-stock-management',
  templateUrl: './stock-management.component.html',
  styleUrls: ['./stock-management.component.scss']
})
export class StockManagementComponent {

  @Input() inputs: QuestionBase<any>[] = [];
  @Input() rewardsForm: UntypedFormGroup;

  constructor(
    private qcs: QuestionControlService,
  ) { }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

}
