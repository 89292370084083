<!-- WARNING MESSAGE -->
<div class="alert alert-info" role="alert">
  <span class="info-text">
    {{'resources.campaigns.warnings.external_csv_audience' | translate}}
  </span>
</div>
<div class="row">
  <div class="col-md-6">
    <div class="card pdd-horizon-20 pdd-top-20">
      <div class="form-group">
        <label>{{'resources.campaigns.fields.segment' | translate}}</label>
        <div class="segment-container">
          <app-question
            class="flex-grow-1"
            [question]="getInputConfig('segment')"
            (selectedLoadedEmitter)="handleSelectedSegment($event)"
            [form]="campaignForm"></app-question>
          <button type="button"
                  class="btn btn-default btn-icon"
                  (click)="openSegment()"
                  [disabled]="!campaignForm.value.segment?.length"
                  pTooltip="{{'resources.campaigns.buttons.see_segment' | translate}}">
            <i class="ei-show"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card pdd-horizon-20 pdd-top-20">
      <div class="form-group">
        <label>{{'resources.campaigns.fields.csv_external_data_url' | translate}}</label>
        <div>
          <button *ngIf="!savedExternalListCsv; else externalListPresent"
                  type="button"
                  class="btn btn-success btn-icon"
                  (click)="loadExternalList()">
            <i class="ei-upload"></i>
            {{'resources.campaigns.buttons.upload_external_list' | translate}}
          </button>
          <ng-template #externalListPresent>
            <div class="external-list-container">
              <a href="#" target="_blank" class="external-url">
                ...{{savedExternalListCsv | slice:0:40}}
              </a>
              <button type="button" class="btn btn-default btn-icon" (click)="loadExternalList('delete')">
                <em class="fa-duotone fa-trash font-size-14 text-danger" aria-hidden="true"></em>
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col" *ngIf="shouldShowControlGroup()">
    <app-question [question]="getInputConfig('control_group_flag')" [form]="campaignForm"></app-question>
  </div>
  <div class="col" *ngIf="mode === 'plan_manual_campaign' && hasFormKeyWithValue('via', 'sms')">
    <app-question [question]="getInputConfig('include_phone_duplicated')" [form]="campaignForm"></app-question>
  </div>
  <div class="col" *ngIf="shouldShowContactPolicies()">
    <app-question [question]="getInputConfig('contact_policy_flag')" [form]="campaignForm"></app-question>
  </div>
</div>
