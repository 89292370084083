import { Injectable } from '@angular/core';
import { SegmentConditionProvider } from '../../condition.service';
import { TranslateService } from '@ngx-translate/core';
import { QuestionBase } from '../../../../models/forms/question-base';
import { MultiSelectQuestion } from '../../../../models/forms/question-multiselect';
import { TextboxQuestion } from '../../../../models/forms/question-textbox';
import { multiselectPresenceValidator } from '../../../validations.service';
import { CustomerAttributeCondition, CustomerAttributeConditionForm } from '../../../../models/segments/conditions/customer-attribute-condition';
import { OperatorsService } from '../../common/operators.service';
import { CustomerAttributesService } from '../../../../../resources/customer-data-platform/customer-attributes/services/customer-attributes/customer-attributes.service';
import { FloatQuestion } from '../../../../models/forms/question-float';
import { DynamicDateQuestion } from '../../../../models/forms/question-dynamic-date';
import { Option } from '../../../../models/common/option';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { getFormGroupValidations } from './validations/form.validations';
import { INTEGER_REGEX } from '../../constants/condition.constants';
import { DateService } from '../../../date.service';
import { parseFormValuesToAPI } from './parsers/form-to-api.parser';
import { parseFormValuesFromAPI } from './parsers/form-from-api.parser';
import { CustomerAttributeType } from './enums/customer-attribute-type.enums';
import { CustomerAttributeKey } from './enums/customer-attribute-key.enums';

@Injectable()
export class CustomerAttributeConditionService implements SegmentConditionProvider {

  inputs: QuestionBase<unknown>[];
  customValidators = [(control: UntypedFormGroup) => getFormGroupValidations(control)];

  constructor(
    private translate: TranslateService,
    private operatorsService: OperatorsService,
    private customerAttributesService: CustomerAttributesService,
    private dateService: DateService
  ) {}

  getInputs(params?: CustomerAttributeConditionForm): QuestionBase<unknown>[] {
    const definitionId = params?.loaded_definition_id || params?.definition_id?.[0]?.id;
    const isStringAttribute = this.isAttributeType(params, CustomerAttributeType.String);
    const isIntegerAttribute = this.isAttributeType(params, CustomerAttributeType.Integer);
    const isFloatAttribute = this.isAttributeType(params, CustomerAttributeType.Float);
    const isBooleanAttribute = this.isAttributeType(params, CustomerAttributeType.Boolean);
    const isDatetimeAttribute = this.isAttributeType(params, CustomerAttributeType.Datetime);
    const isBtIntegerOperator = this.isBtOperator(params, CustomerAttributeKey.IntegerOperator);
    const isBtFloatOperator = this.isBtOperator(params, CustomerAttributeKey.FloatOperator);
    const isBtDateOperator = this.isBtOperator(params, CustomerAttributeKey.DateOperator);

    const inputs = [
      new MultiSelectQuestion({
        key: CustomerAttributeKey.DefinitionId,
        label: this.translate.instant('resources.segment_conditions.fields.customer_attribute'),
        cssClasses: 'form-control input-default',
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: true },
        dataSource: this.customerAttributesService,
        selectedIds: definitionId ? [definitionId] : [],
        required: true,
        customValidators: [multiselectPresenceValidator]
      }),
      new TextboxQuestion({
        key: CustomerAttributeKey.DefinitionType,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.type'),
        type: 'text',
        value: params?.definition_type ?? null
      }),
      new MultiSelectQuestion({
        key: CustomerAttributeKey.StringOperator,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorsService.getStringOperators(),
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        required: isStringAttribute,
        value: params?.string_operator ?? null,
        customValidators: isStringAttribute ? [multiselectPresenceValidator] : []
      }),
      new TextboxQuestion({
        key: CustomerAttributeKey.StringValue,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        type: 'text',
        required: isStringAttribute,
        value: params?.string_value ?? null
      }),
      new MultiSelectQuestion({
        key: CustomerAttributeKey.IntegerOperator,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorsService.getNumericOperators(),
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        required: isIntegerAttribute,
        value: params?.integer_operator ?? null,
        customValidators: isIntegerAttribute ? [multiselectPresenceValidator] : []
      }),
      new FloatQuestion({
        key: CustomerAttributeKey.IntegerValue,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        type: 'number',
        step: 1,
        required: isIntegerAttribute,
        value: params?.integer_value ?? null,
        customValidators: isIntegerAttribute ? [Validators.pattern(INTEGER_REGEX)] : []
      }),
      new FloatQuestion({
        key: CustomerAttributeKey.IntegerValue2,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        type: 'number',
        step: 1,
        required: isIntegerAttribute && isBtIntegerOperator,
        customValidators: isIntegerAttribute && isBtIntegerOperator ? [Validators.pattern(INTEGER_REGEX)] : [],
        value: params?.integer_value2 ?? null
      }),
      new MultiSelectQuestion({
        key: CustomerAttributeKey.FloatOperator,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorsService.getNumericOperators(),
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        required: isFloatAttribute,
        value: params?.float_operator ?? null,
        customValidators: isFloatAttribute ? [multiselectPresenceValidator] : []
      }),
      new FloatQuestion({
        key: CustomerAttributeKey.FloatValue,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        type: 'number',
        step: 0.01,
        required: isFloatAttribute,
        value: params?.float_value ?? null
      }),
      new FloatQuestion({
        key: CustomerAttributeKey.FloatValue2,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        type: 'number',
        step: 0.01,
        required: isFloatAttribute && isBtFloatOperator,
        value: params?.float_value2 ?? null
      }),
      new MultiSelectQuestion({
        key: CustomerAttributeKey.BooleanOperator,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorsService.getEqualityOperators(),
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        required: isBooleanAttribute,
        value: params?.boolean_operator ?? null,
        customValidators: isBooleanAttribute ? [multiselectPresenceValidator] : []
      }),
      new MultiSelectQuestion({
        key: CustomerAttributeKey.BooleanValue,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.value'),
        options: this.getBooleanValueOptions(),
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        required: isBooleanAttribute,
        value: params?.boolean_value ?? null,
        customValidators: isBooleanAttribute ? [multiselectPresenceValidator] : []
      }),
      new MultiSelectQuestion({
        key: CustomerAttributeKey.DateOperator,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.operator'),
        options: this.operatorsService.getNumericOperators(),
        settings: { singleSelection: true, showCheckbox: false, enableSearchFilter: false },
        required: isDatetimeAttribute,
        value: params?.date_operator ?? null,
        customValidators: isDatetimeAttribute ? [multiselectPresenceValidator] : []
      }),
      new DynamicDateQuestion({
        key: CustomerAttributeKey.DateValue,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.date'),
        required: isDatetimeAttribute,
        hideNoDateOpt: true,
        value: params?.date_value ?? null
      }),
      new DynamicDateQuestion({
        key: CustomerAttributeKey.DateValue2,
        cssClasses: 'form-control input-md',
        label: this.translate.instant('resources.segment_conditions.fields.date'),
        required: isDatetimeAttribute && isBtDateOperator,
        hideNoDateOpt: true,
        value: params?.date_value2 ?? null
      })
    ];

    this.inputs = inputs;
    return this.inputs;
  }

  prepareFormValuesToAPI(params: CustomerAttributeConditionForm): CustomerAttributeCondition {
    return parseFormValuesToAPI(params);
  }

  prepareFormValuesFromAPI(params: CustomerAttributeCondition): CustomerAttributeConditionForm {
    return parseFormValuesFromAPI(params, this.operatorsService, this.dateService, this.getBooleanValueOptions());
  }

  private isAttributeType(params: CustomerAttributeConditionForm, attributeType: string): boolean {
    return !!(params?.definition_type && params.definition_type === attributeType);
  }

  private isBtOperator(params: CustomerAttributeConditionForm, operatorType: string): boolean {
    return !!(params && params[operatorType] && params[operatorType].length && params[operatorType].id === 'bt');
  }

  private getBooleanValueOptions(): Option[] {
    return [
      { id: false, name: this.translate.instant('resources.segment_conditions.options.false')},
      { id: true, name: this.translate.instant('resources.segment_conditions.options.true')}
    ];
  }
}
