import { FeatureFlagsService } from '../shared/services/feature-flags.service';
import { FrogedService } from '../shared/services/froged/froged.service';
import { GOOGLE_ANALYTICS_TAG_MANAGER_EVENT, GoogleAnalyticsTagManagerService } from '../shared/services/google-analytics-tag-manager/google-analytics-tag-manager.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { LAST_USER_SESSION_ACTIVITY_LOCAL_STORAGE, USER_IDLE_SESSION_TIMER_LOCAL_STORAGE } from '../shared/constants/local-storage.constants';
import { Observable } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';
import { ProfileService } from '../profiles/profile.service';
import { Router } from '@angular/router';
import { translateInitializerFactory } from '../shared/config/translate-intitializer.factory';
import { TranslateService } from '@ngx-translate/core';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class AuthService {

  emitter = new EventEmitter<any>();
  company_language: string;
  flags = this.featureFlags.flags;

  constructor(
    private readonly featureFlags: FeatureFlagsService,
    private readonly frogedService: FrogedService,
    private readonly http: HttpClient,
    private readonly primeConfig: PrimeNGConfig,
    private readonly profileService: ProfileService,
    private readonly gatmService: GoogleAnalyticsTagManagerService,
    private readonly router: Router,
    private readonly translateService: TranslateService
  ) {}

  authorizeUser(codeChallenge: string): Observable<any> {
    const body = {code_challenge: codeChallenge};
    return this.http.post('users/authorization', body, httpOptions);
  }

  login(loginPayload: object): Observable<any> {
    return this.http.post('users/session', loginPayload, httpOptions);
  }

  ssoSignIn(ssoSignInPayload: object): Observable<unknown> {
    return this.http.post('sso/saml2', ssoSignInPayload, httpOptions);
  }

  logoutReq(logoutPayload: {user: string}): Observable<any> {
    return this.http.post('users/logout', logoutPayload, httpOptions);
  }

  signinUser(email: string, password: string): Observable<any> {
    const body = {email: email, password: password};
    return this.http.post('users/session', body, httpOptions);
  }

  recoverPassword(payload: { email: string, language: string }): Observable<any> {
    return this.http.post('users/send_reset_password', payload, httpOptions);
  }

  resetPassword(params): Observable<any> {
    return this.http.post('users/reset_password', params, httpOptions);
  }

  logout(): void {
    sessionStorage.removeItem('user_data');
    localStorage.removeItem('is_authenticated');
    localStorage.removeItem(LAST_USER_SESSION_ACTIVITY_LOCAL_STORAGE);
    localStorage.removeItem(USER_IDLE_SESSION_TIMER_LOCAL_STORAGE);
    this.gatmService.setGoogleAnalyticsTagManager(GOOGLE_ANALYTICS_TAG_MANAGER_EVENT.Logout);
    this.frogedService.logout();
    this.emitter.emit('logout');
  }

  isAuthenticated(): boolean {
    return localStorage.getItem('is_authenticated') === 'true';
  }

  afterLoginPath(userProfile): void {
    userProfile.subscribe(
      userData => {
        this.gatmService.setGoogleAnalyticsTagManager(GOOGLE_ANALYTICS_TAG_MANAGER_EVENT.Login, userData);
        this.frogedService.setupFroged();
        this.redirectToMainPage(userData.role.slug);
      }
    );
  }

  redirectToMainPage(userRoleSlug: string) {
    const redirect = () => {
      if (userRoleSlug === 'user_manager') {
        this.router.navigateByUrl('/config/users').catch(() => {});
      } else {
        this.router.navigateByUrl('/home').catch(() => {});
      }
    };
    // If FF not loaded yet, wait to get them before redirecting
    if (!this.featureFlags.flagsLoaded) {
      this.featureFlags.flagsLoaded$.subscribe(
        flagsLoaded => {
          if (flagsLoaded) {
            redirect();
          }
        }
      );
    } else {
      redirect();
    }
  }

  setUserAuthToken() {
    localStorage.setItem('is_authenticated', 'true');
    const initializeFactory = translateInitializerFactory(this.translateService, this.profileService, this.primeConfig );
    initializeFactory();
  }
}
