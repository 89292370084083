import { CampaignConstructorOptions } from "./campaign-constructor-opts";
import { CampaignPayload } from "./campaign-payload";
import { CampaignReady } from "./campaign-ready";
import { CampaignReport } from "./campaign-report";
import { CampaignTemplate, EmailTemplate } from "./campaign-template";
import { getIdValueFromArrayObject } from "../../utils/common.utils";

export const CONTROL_GROUP_SIGNIFICANCE = 250;
export class Campaign {
  area: string;
  campaign_category: {};
  campaign_category_id: number;
  campaign_history_coupon_id: number;
  campaign_id?: number;
  campaign_template_id?: number;
  collective_id?: number;
  contact_policy_flag = false;
  control_group_flag = false;
  coupon_distribution: string;
  coupon_ids: number[];
  coupons: any[];
  csv_external_data_url?: string;
  description: string;
  distribution: string;
  editable = true;
  execution: 'daily' | 'weekly' | 'monthly' | null;
  execution_until: string;
  hasContent: boolean;
  has_been_sent: boolean;
  id: number;
  informative: boolean;
  invalid_motives: string[];
  isEmail: boolean;
  isPush: boolean;
  isSms: boolean;
  landing_stats: 0;
  last_send_history: LastSentHistory;
  locations: any[];
  message_ids: any[];
  messages: any[];
  motive: string = 'informative';
  motiveCoupon: boolean = false;
  name: string;
  periodic = false;
  ready_report: CampaignReady;
  reminder = false;
  reminder_plan = false;
  report: CampaignReport;
  report_error: string;
  report_present: boolean;
  reward = false;
  scheduled_at: any;
  segment: {};
  segment_id: number;
  sender_id: number;
  sender: string;
  sent_at: null;
  skip_amount_error: boolean;
  status: string = 'draft';
  tagged: boolean;
  template: CampaignTemplate = { message: '' };
  valid_to_send: boolean;
  validation_status: string;
  via: string;
  voucheables: any[];
  warning_motives: string[];
  warnings: boolean;

  constructor(options: CampaignConstructorOptions = {}) {

    Object.keys(options).forEach(key => this[key] = options[key]);

    // To prevent changedAfterChecked errors
    this.hasContent = ['sms', 'email', 'push'].includes(options.via);
    this.isEmail = options.via === 'email';
    this.isSms = options.via === 'sms';
    this.isPush = options.via === 'push';

    // Campaign template
    this.template = options.template || { message: '' };
    this.template.id = (options.template && options.template.id) ? options.template.id : undefined;

    // Contact policy
    this.contact_policy_flag = options.contact_policy_flag;

    // Control groups
    this.control_group_flag = ['reminder', 'informative'].includes(this.motive) ? false : options.control_group_flag;

    // ?
    this.informative = options.motiveCoupon || false;
  }

  public hasMessages(): boolean {
    return this.message_ids && this.message_ids.length > 0;
  }

  public isCoupon(): boolean {
    return this.motive === 'coupon';
  }

  public isReminder(): boolean {
    return this.motive === 'reminder';
  }

  public isMessage(): boolean {
    if (this.via && this.via[0] && this.via[0]['id'] === 'ticket_message') {
      return true;
    } else {
      return false;
    }
  }

  public copyEmailTemplate(subject: string, templateId: number, emailTemplate: any) {
    emailTemplate.id = this.template?.id;
    this.campaign_template_id = templateId;
    this.template = new EmailTemplate(subject, emailTemplate);
  }

  public copySmsTemplate(templateId: number, smsTemplate: any) {
    smsTemplate.id = this.template?.id;
    this.campaign_template_id = templateId;
    this.template = new CampaignTemplate(smsTemplate.template);
  }

  public copyPushTemplate(templateId: number, pushTemplate: any) {
    pushTemplate.id = this.template?.id;
    this.campaign_template_id = templateId;
    this.template = new CampaignTemplate(pushTemplate.template);
  }

  public resetTemplate() {
    const templateObject = { id: this.template?.id, subject: this.name, message: '', schema: {}, values: {} };
    this.template = new CampaignTemplate(templateObject);
  }

  public prepareToBatchEdit(formValues) {

    const name = formValues.name;
    const via = formValues.via[0].id;
    const campaign_category = formValues.campaign_category;
    const coupon_ids = formValues.coupon_ids;
    const message_ids = formValues.message_ids;
    const segment = formValues.segment;
    const scheduled_at = (formValues.scheduled_at) ? formValues.scheduled_at : null;

    const rewardKey = Object.keys(formValues).find(key => key.indexOf('reward') >= 0);
    const reward = formValues[rewardKey];

    // Overwritter modified values
    this.name = name;
    this.via = via;
    this.campaign_category_id = (campaign_category && campaign_category.length > 0) ? campaign_category[0].id : null;
    this.coupon_ids = (coupon_ids && coupon_ids.length > 0) ? coupon_ids[0].id : null;
    this.message_ids = (message_ids && message_ids.length > 0) ? message_ids[0].id : null;
    this.segment_id = (segment && segment.length > 0) ? segment[0].id : null;
    this.scheduled_at = scheduled_at;
    this.reward = reward;

    switch (this.via) {
      case 'ticket_message':
        this.coupon_ids = [];
        this.motive = 'message';
        this.reward = false;
        break;
      case 'none':
        this.message_ids = [];
        this.motive = 'coupon';
        break;
      case 'push':
        this.coupon_ids = [];
        this.message_ids = [];
        this.motive = 'informative';
        break;
      case 'email':
      case 'sms':
        this.message_ids = [];
        this.motive = (this.coupon_ids && this.coupon_ids.length > 0) ? 'coupon' : 'informative';
        if (!this.template.subject) { this.template.subject = this.name; }
        break;
      default:
        break;
    }

    if (this.motive === 'coupon') { this.coupon_distribution = 'normal'; }

    this.distribution = this.periodic ? 'periodic' : this.distribution;
  }

  public redefineMotiveRegardingVia(motiveValue: string, via: string): string {
    // When motive radio input is reminder, it has to be saved as "coupon"
    let motive = ['reminder'].includes(motiveValue) ? 'coupon' : motiveValue;
    // If campaign channel is ticket message motive should always be message (there is no actual radio option for this)
    motive = via === 'ticket_message' ? 'message' : motive;
    return motive;
  }

  public getCampaignPayload(formValues, campaign?: Campaign): CampaignPayload {
    if (formValues) {
      const motive = formValues.motive;
      const via = getIdValueFromArrayObject(formValues.via);
      return {
        campaign_category_id: getIdValueFromArrayObject(formValues.campaign_category),
        campaign_history_coupon_id: getIdValueFromArrayObject(formValues.campaign_history_coupon_id),
        campaign_template_id: this.getTemplateId(formValues, via),
        collective_id: getIdValueFromArrayObject(formValues.collective_id),
        contact_policy_flag: ['email', 'sms', 'push'].includes(via) ? formValues.contact_policy_flag : false,
        control_group_flag: ['reminder', 'informative'].includes(motive) ? false : formValues.control_group_flag,
        coupon_ids: getIdValueFromArrayObject(formValues.coupon_ids, true),
        csv_external_data_url: campaign?.csv_external_data_url,
        description: formValues.description || null,
        distribution: formValues.periodic ? 'periodic' : 'multiple',
        execution: getIdValueFromArrayObject(formValues.execution),
        execution_until: formValues.execution_until,
        id: this.id,
        message_ids: getIdValueFromArrayObject(formValues.message_ids, true),
        motive: this.redefineMotiveRegardingVia(motive, via),
        name: formValues.name,
        periodic: formValues.periodic,
        reminder: motive === 'reminder',
        reward: formValues.reward,
        scheduled_at: formValues.scheduled_at,
        segment_id: getIdValueFromArrayObject(formValues.segment),
        sender_id: getIdValueFromArrayObject(formValues.sender_id),
        skip_amount_error: formValues.skip_amount_error,
        template: {
          ...this.template,
          subject: via === 'email' ? formValues.email_subject : this.template.subject
        },
        via: via
      }
    }
  }

  private getTemplateId(options, via: string): number {
    if(via === 'email') return getIdValueFromArrayObject(options.email_template);
    else if(via === 'sms') return getIdValueFromArrayObject(options.sms_template);
    else if(via === 'push') return getIdValueFromArrayObject(options.push_template);
  }
}

export interface LastSentHistory {
  sent_at: string;
}
