export const CURRENT_USER_STORED_CONSTANTS = [
  'company_id',
  'email',
  'id',
  'ip_white_list',
  'location',
  'locations',
  'name',
  'partner',
  'preferred_language',
  'role',
  'supplier_ids',
  'surname_1',
  'surname_2'
];

export const CURRENT_COMPANY_STORED_CONSTANTS = [
  'actions_running',
  'actions_waiting',
  'alerts_email',
  'app_version',
  'available_languages',
  'barcodes_configuration',
  'card_digitization',
  'company_configurations',
  'control_group_ratio',
  'coupon_template',
  'currency',
  'customs',
  'email',
  'email_credit_price',
  'email_credits',
  'evaluation_period',
  'export_configuration',
  'feature_taxonomy_main',
  'gdpr_configuration',
  'gdpr_fields',
  'id',
  'last_period_number_done',
  'locale',
  'model_configuration',
  'multicurrency',
  'name',
  'otp_configuration',
  'period_pending',
  'phone_check_credits',
  'plans_configuration',
  'purchased_modules',
  'slug',
  'sms_credit_price',
  'sms_credits',
  'sms_permitted_hour_from',
  'sms_permitted_hour_to',
  'starting_template_coupon_id',
  'streaming_api_allow_wrong_quantity_division',
  'streaming_api_allow_wrong_quantity_sign',
  'strict_profiling',
  'supplier_taxonomy',
  'system_profile_groups',
  'system_segment_categories',
  'thumbnail',
  'time_zone',
  'unified_customers',
  'voucher_intelligent_print',
  'voucher_print_size'
];
