import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { RefreshCacheService } from '../../../../../shared/services/refresh-cache.service';
import { DataTableFilterService } from '../../../../../shared/components/data-table-filter/data-table-filter.service';
import { CustomersService } from '../../customers.service';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { DictionaryService } from '../../../../../shared/services/dictionary.service';
import { ContactsRowData } from './models/contacts-row-data.model';

// Highcharts
import { chart } from 'highcharts';
import * as Highcharts from 'highcharts';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contacts-tab',
  templateUrl: './contacts-tab.component.html',
  styleUrls: ['./contacts-tab.component.css']
})

export class ContactsTabComponent implements OnInit, OnDestroy {

  activeMonths: number[];
  months: {id: number, name: string, key: string}[];
  loading: boolean;
  contactsData: ContactsRowData[] = [];
  subs$: Subscription[] = [];
  contactsChart: Highcharts.Chart;
  showGraph: boolean = false;
  translatedMonths: string[];
  rows: string[] = Object.keys(new ContactsRowData({}));

  @ViewChild('contactsChartElement') contactsChartElement: ElementRef;

  constructor(
    private customersService: CustomersService,
    private filterService: DataTableFilterService,
    private refreshCacheService: RefreshCacheService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private dictionaryService: DictionaryService
  ) {}

  ngOnInit(): void {
    this.months = this.dictionaryService.dictionaries.months;
    this.activeMonths = this.months.map((_, index) => index);
    this.getCustomersContactsData({});
    const filterService$ = this.customersService.applyFilters$.subscribe(
      (filterValues) => this.getCustomersContactsData(filterValues)
    );
    this.subs$.push(filterService$);
  }

  ngOnDestroy(): void {
    if (this.subs$) { this.subs$.forEach(s$ => s$.unsubscribe()); }
  }

  showChart(): void {
    this.showGraph = !this.showGraph;
  }

  private getCustomersContactsData(filterValues: Object): void {
    const today = new Date();
    let filters = {...this.customersService.filters, ...filterValues};

    if (filters instanceof Object && filters.hasOwnProperty('contact_totals')) {
      filters = this.customersService.filters['contact_totals'];
      filters.year = filters?.last_two_years;
      delete filters.last_two_years;
    }

    if (!filters.hasOwnProperty('year') || !filters['year']) { filters['year'] = `${today.getFullYear()}`; }
    if (parseInt(filters['year'], 10) === today.getFullYear()) {
      const activeMonthNum = (today.getMonth() > 0) ? today.getMonth() : 1;
      this.activeMonths = this.months.slice(0, activeMonthNum).map((_, index) => index);
    } else {
      this.activeMonths = this.months.slice(0, this.months.length).map((_, index) => index);
    }

    this.loading = true;
    this.filterService.loaderStatus.next(false);
    this.enqueuePollingRequest(filters);
  }

  private enqueuePollingRequest(filters: Object): void {
    const params = {apiEndPoint: 'analytics/customer_contacts', filtering: filters};
    const customerContacts$ = this.refreshCacheService.getExpensiveData(params).subscribe(
      response => {
        if (response.status === 'error') {
          const errorText = this.translate.instant('dashboards.customers.tabs.contacts.error.no_data');
          this.handleError(customerContacts$, errorText);
        } else if (!this.refreshCacheService.isRequestPending(response)) {
          this.handleLoadingSubs(customerContacts$);
          this.contactsData = response.map(element => new ContactsRowData(element));
          this.initChart();
        }
      },
      errorData => {
        this.handleError(customerContacts$, errorData.error.error);
      }
    );
    this.subs$.push(customerContacts$);
  }

  private handleLoadingSubs(customerContacts: Subscription): void {
    customerContacts.unsubscribe();
    this.loading = false;
    this.filterService.loaderStatus.next(true);
  }

  private handleError(customerContacts$: Subscription, errorText: string): void {
    this.handleLoadingSubs(customerContacts$);
    this.contactsData = [];
    this.confirmationService.displayErrorAlert(this.translate.instant('common.error'), errorText);
  }

  private initChart(): void {
    this.translatedMonths = this.months.map(element => this.translate.instant(element.name));

    const contactsChartOptions: Highcharts.Options = {
      chart: {
        type: 'line',
        plotBorderWidth: 1,
        zooming: {
          type: 'xy'
        },
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom'
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: this.translatedMonths,
          gridLineWidth: 1,
          plotLines: [{
            color: 'black',
            dashStyle: 'Dash',
            width: 1,
            value: 0,
            zIndex: 3
          }],
          min: 0.5,
          max: 12 - 1.5,
          startOnTick: false,
          endOnTick: false,
          minPadding: 0,
          maxPadding: 0
        },
        yAxis: {
          startOnTick: false,
          endOnTick: false,
          title: {
            text: this.translate.instant('dashboards.customers.tabs.contacts.table.values')
          },
          maxPadding: 0.2,
          plotLines: [{
              color: 'black',
              dashStyle: 'Dash',
              value: 0,
              width: 1,
              zIndex: 3
          }]
        },
        responsive: {
          rules: [
            {
              condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
              }
            }
          }
        ]
      },
      accessibility: {
        enabled: false
      },
    };

    this.contactsChart = chart(this.contactsChartElement.nativeElement, contactsChartOptions);

    if (this.contactsData) {
      Object.keys(this.contactsData[0]).forEach(keyValue => {
      const parsedValues: any = {
        name: this.translate.instant('dashboards.customers.tabs.contacts.table.' + keyValue),
        data: this.contactsData.map(element => {
          return element[keyValue];
        }),
        tooltip: {
          shared: false,
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '{point.y}'
        },
        type: 'line',
        lineWidth : 1,
        shared: false,
        marker: {
          radius: 4,
          symbol: 'diamond'
        }
      };
      this.contactsChart.addSeries(parsedValues);
      });
    }

    this.contactsChart.redraw();
  }
}
