import { Component, ElementRef, Input, OnInit, SecurityContext, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { CsvExportService } from "../../../../../shared/services/csv-export.service";
import { TranslateService } from "@ngx-translate/core";
import { ProfileService } from "../../../../../profiles/profile.service";
import { getCurrencySymbol, PercentPipe, registerLocaleData } from "@angular/common";
import es from '@angular/common/locales/es';
import { ReportCouponsAppAnalysisBaseHeaders, ReportCouponsAppAnalysisTableHeaders } from "./utils/table-header.utils";
import { CouponsAppAnalysisDetailResult, CouponsAppAnalysisGroupedResults, CouponsAppAnalysisReportDetailData, ReportCouponsAppAnalysisCalculatedData, ReportCouponsAppAnalysisHeader } from "./model/report-coupons-in-app-analysis.model";
import { DateService } from '../../../../../shared/services/date.service';
import { isNullOrUndefined } from "../../../../../shared/utils/common.utils";
import { LgMultilanguageTranslatePipe } from '../../../../../shared/pipes/lg-multilanguage-translate.pipe';
import { groupRawResultsByCampaignId } from "./utils/campaign-analysis-utils";

@Component({
  selector: 'app-show-report-coupons-in-app-analysis',
  templateUrl: './show-report-coupons-in-app-analysis.component.html',
  styleUrls: ['./show-report-coupons-in-app-analysis.component.scss'],
  providers: [LgMultilanguageTranslatePipe, PercentPipe]
})

export class ShowCouponsInAppAnalysis implements OnInit {

  private currency = this.profileService.getProfileCompany().currency;

  baseHeaders = ReportCouponsAppAnalysisBaseHeaders.filter((header) => header.display);
  tableHeaders = ReportCouponsAppAnalysisTableHeaders;
  content: {id: number, value: ReportCouponsAppAnalysisCalculatedData[]};
  currencySymbol = getCurrencySymbol(this.currency, 'wide');
  groupedResults: CouponsAppAnalysisGroupedResults[];
  showSubtotal = false;

  @Input() element: CouponsAppAnalysisReportDetailData;
  @Input() id: number;
  @Input() lastGenerationAt: string;

  @ViewChild('toggleBtn') toggleBtn: ElementRef;

  constructor(
    private csvExportService: CsvExportService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private profileService: ProfileService,
    private dateService: DateService,
    private lgTranslatePipe: LgMultilanguageTranslatePipe,
    private percentPipe: PercentPipe
  ){}

  ngOnInit() {
    this.element.results = this.element.results.map(result => {
      const detailResultInstance = new CouponsAppAnalysisDetailResult(
        result,
        this.dateService
      );
      return detailResultInstance;
    });
    this.groupedResults = groupRawResultsByCampaignId(this.element.results, this.translate);
    registerLocaleData(es);
  }

  downloadCsv() {
    const csvData = this.csvExportService.prepareCsvStringByColumnHeaderTypes(
      ReportCouponsAppAnalysisBaseHeaders,
      this.groupedResults,
      this.parseValue.bind(this)
    );
    const csvBlob = this.csvExportService.getCsvBlob(csvData);const url = window.URL.createObjectURL(csvBlob);
    const nav = (window.navigator as any);

    if (nav.msSaveBlob) {
      nav.msSaveBlob(csvBlob, 'filename.csv');
    } else {
      const a = document.createElement('a');
      a.href = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(url));
      const fileName = `${this.element.type}.csv`;
      a.download = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(fileName));
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  handleShowSubtotal() {
    this.showSubtotal = this.toggleBtn.nativeElement.checked;
  }

  private parseValue(csvRow: unknown, header: ReportCouponsAppAnalysisHeader, results: ReportCouponsAppAnalysisCalculatedData[]): string {
    let key = header.key;
    let value = csvRow[key];

    if (key === 'coupon_discount') {
      return this.parseDiscount(csvRow, value);
    } else if (key === 'coupon_name'){
      return this.lgTranslatePipe.transform(value);
    } else if(['origin_type', 'channel'].includes(key)) {
      return this.translate.instant(value);
    } else if (key === 'date_from') {
      if (results?.length > 1) {
        const minDate = results.reduce((min, result) => result.date_from < min ? result.date_from : min, results[0].date_from);
        const maxDate = results.reduce((max, result) => result.date_to > max ? result.date_to : max, results[0].date_to);
        return `${minDate} - ${maxDate}`;
      } else {
        return value + (csvRow['date_to'] ? ' - ' + csvRow['date_to'] : '');
      }
    } else if (key === 'campaign_category') {
      return this.translateCampaignCategory(value);
    }

    switch (header.type) {
      case 'boolean':
        return value ? this.translate.instant('yes') : this.translate.instant('no');
      case 'float':
      case 'integer':
        return this.csvExportService.parseNumericValue(value, header.type);
      case 'string':
        return isNullOrUndefined(value) ? '' : value;
      case 'percentage':
        return this.parsePercentageValue(value);
      case 'currency_float':
        return this.csvExportService.parseNumericValue(value, 'float') + this.currencySymbol;
    }
  }

  private parsePercentageValue(value) {
    return this.percentPipe.transform(value, '1.2-2', 'es');
  }

  private parseDiscount(csvRow: unknown, value: unknown) {
    const unit = csvRow['coupon_discount_type'] === 'percent' ? '%' : this.currencySymbol;
    return value ? this.csvExportService.replaceDotWithChar(`${value}${unit}`, ',') : '';
  }

  private translateCampaignCategory(value: string): string{
    return value?.indexOf('Plans::') === 0 ? this.translate.instant('resources.campaign_plans.types.dictionary.' + value) : value;
  }
}
