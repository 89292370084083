import { DictionaryService } from './dictionary.service';
import { Injectable } from '@angular/core';
import { MultiselectDataSource } from '../components/multiselect/multiselect';
import { ProfileService } from '../../profiles/profile.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import { FeatureFlagsService } from './feature-flags.service';

@Injectable()
export class DateTimeZoneService {

  flags = this.featureFlags.flags;
  private locale: string;
  private timezone: string;

  constructor(
    private translate: TranslateService,
    private dictionary: DictionaryService,
    private currentUserProfile: ProfileService,
    private readonly featureFlags: FeatureFlagsService
  ) {
    this.loadLocalization();
  }

  parseDateWithFormat(dateStringValue, format: string, isUnix?: boolean) {
    if (isUnix) {
      return moment.unix(dateStringValue / 1000).format(format);
    }
    return moment(new Date(dateStringValue)).format(format);
  }

  parseNumericDateWithFormatFromNow(dateStringValue: number) {
    return moment(dateStringValue).fromNow();
  }

  dateISOStringToDateTime(dateIsoString: string): string {
    return moment(new Date(dateIsoString)).format('YYYY-MM-DDTHH:mm:ss');
  }

  /**
   * dateStringValue: Must be a string of the type: dd/mm/yyyy
   */
  isFutureDate(dateStringValue: string): boolean {
    const today = moment();
    const splittedDate = dateStringValue.split('/');
    const momentDate = moment(new Date(`${splittedDate[1]}-${splittedDate[0]}-${splittedDate[2]}`));
    return momentDate.isValid() && momentDate.isAfter(today);
  }

  momentFormat(dateStringValue: any, format: string) {
    return moment(dateStringValue).format(format);
  }

  momentStartOfTypeAndFormat(startOf: any, format: string): any {
    return moment().startOf(startOf).format(format);
  }

  momentEndOfTypeAndFormat(endOf: any, format: string): any {
    return moment().endOf(endOf).format(format);
  }

  nPeriodsAgo(date, n, period): any {
    const result = moment(date);
    if (['year', 'semester', 'quarter', 'month'].indexOf( period ) >= 0) {
      result.add(-n, 'years');
    } else {
      result.add(-n * 364, 'days');
    }
    return result.format('YYYY-MM-DD');
  }

  getYearOptions(threshold: number) {
    const years: MultiselectDataSource[] = [];
    const currentYear = moment().year();
    for (let index = 0; index <= threshold; index++) {
      const year = `${currentYear - index}`;
      years.push( new MultiselectDataSource(year, year) );
    }
    return years;
  }

  assignHourAndMinuteToDefaultDate(inputTimeValue: string): moment.Moment {
    const date = moment().format('YYYY-MM-DD');
    return moment(date + ' ' + inputTimeValue);
  }

  samePeriodLastYear(dateString: string, format: string = 'YYYY-MM-DD'): string {
    return moment(dateString).subtract(1, 'years').format(format);
  }

  dynamicDateToDateOption(value) {
    if (!(value && value.length)) { return {id: null, name: null}; }
    if ( value.indexOf('%') >= 0 ) {
      let dynamicDateValues = this.dictionary.getValuesByKey('dynamic_dates');
      if (this.flags.showMinutesInDynamicDates) {
        dynamicDateValues = [...this.dictionary.getValuesByKey('dynamic_dates_minutes'), ...dynamicDateValues];
      }
      const item = dynamicDateValues.find( element => element.id === value);
      return { id: item.id, name: this.translate.instant(item.name) };
    } else {
      const date = moment(value);
      return {
        id: date.format('YYYY-MM-DD'),
        name: date.format('DD/MM/YYYY')
      };
    }
  }

  isDate2BeforeDate1(date1: string, date2: string) {
    const _date1 = moment(date1);
    const _date2 = moment(date2);
    return _date2.isBefore(_date1);
  }

  isValidDate(date: string): boolean {
    return moment(date).isValid();
  }

  isSameOrBefore(momentDate): boolean {
    return moment(moment()).isSameOrBefore(momentDate, 'd');
  }

  getDatesDiffByTimeUnit(date1: Date, date2: Date, timeUnit: moment.unitOfTime.DurationConstructor, includeBothDates?: boolean): number {
    const mDate1 = moment(date1);
    const mDate2 = moment(date2);
    const result = mDate2.diff(mDate1, timeUnit);
    return includeBothDates ? result + 1 : result;
  }

  calculateDate(operation: string, quantity: number, timeUnit: moment.unitOfTime.DurationConstructor, _fromDate?: Date | string, _format?: string) {
    const fromDate = _fromDate ? moment(_fromDate) : moment();
    const format = _format ? _format : 'YYYY-MM-DD';
    if (operation === 'substract') {
      return fromDate.subtract(quantity, timeUnit).format(format);
    } else if (operation === 'add') {
      return fromDate.add(quantity, timeUnit).format(format);
    }
  }

  getLastDayOfCalculatedPeriod(operation: string, quantity: number, timeUnit: moment.unitOfTime.DurationConstructor, date: Date, endOfTimeFrame: moment.unitOfTime.DurationConstructor, format: string) {
    const dateTo = this.calculateDate(operation, quantity, timeUnit, date);
    return moment(dateTo).endOf(endOfTimeFrame).format(format);
  }

  private loadLocalization() {
    const user = this.currentUserProfile.getStoredUser();
    this.locale = user.preferred_language ? user.preferred_language : user.company['locale'].replace('_', '-');
    this.timezone = user.company['time_zone'] ? user.company['time_zone'] : 'UTC';
    moment.tz.setDefault(this.timezone);
    moment.locale(this.locale);
  }

}
