import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonModule, DecimalPipe, getCurrencySymbol } from '@angular/common';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription, zip, takeUntil, finalize } from 'rxjs';
import * as moment from 'moment';

import { BudgetInfoComponent } from '../alerts/budget-info/budget-info.component';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { CsvExportService } from '../../../shared/services/csv-export.service';
import { DataTableFilterService } from '../../../shared/components/data-table-filter/data-table-filter.service';
import { DictionaryService } from '../../../shared/services/dictionary.service';
import { EmbeddedCampaign, getPlanDaysCount, PlanForecastCampaignGroup } from './plan-forecast-campaign-group';
import { FeatureFlagsService } from '../../../shared/services/feature-flags.service';
import { FilterConfiguration } from '../../../shared/components/data-table-filter/data-table-filter-cfg';
import { FloatQuestion } from '../../../shared/models/forms/question-float';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { isNullOrUndefined } from '../../../shared/utils/common.utils';
import { ModalStatusService } from '../../../shared/services/modal-status.service';
import { MultiselectDataSource } from '../../../shared/components/multiselect/multiselect';
import { MultiSelectQuestion } from '../../../shared/models/forms/question-multiselect';
import { Plan } from '../plan';
import { PlanCampaignAction } from '../campaigns/custom-campaign-plan-audience/plan-campaign-action';
import { PlanCampaignActionService } from '../campaigns/custom-campaign-plan-audience/plan-campaign-action.service';
import { PlanCampaignService } from '../campaigns/plan-campaign.service';
import { PlanForecastDetailActionsMock } from './plan-forecast-campaign-group-mock';
import { PlansService } from '../plans.service';
import { ProfileService } from '../../../profiles/profile.service';
import { QuestionBase } from '../../../shared/models/forms/question-base';
import { QuestionControlService } from '../../../shared/services/question-control.service';
import { RefreshCacheService } from '../../../shared/services/refresh-cache.service';
import { SwitchCouponsComponent } from './switch-coupons-modal/switch-coupons.component';
import {CouponsService} from '../../coupons/coupons.service';
import { ActionPayload, PlanForecastDetailTotals, TableColumnList } from './plan-forecast-detail.models';
import { SharedModule } from '../../../shared/shared.module';
import { ConfigurationEntryResponse, ConfigurationService } from '../../../shared/services/configuration/configuration.service';

@Component({
  selector: 'app-plan-forecast-detail',
  templateUrl: './plan-forecast-detail.component.html',
  styleUrls: ['./plan-forecast-detail.component.scss'],
  standalone: true,
  imports: [BudgetInfoComponent, CommonModule, SharedModule],
  providers: [DecimalPipe, DictionaryService, PlanCampaignActionService, ConfigurationService]
})

export class PlanForecastDetailComponent implements OnInit, OnDestroy {

  LOCAL_STORAGE_VISIBILITY_KEY = 'plan_forecast_detail_visibility_channels'
  INTERVAL_DELAY = 30000;

  ableToApprove: boolean = true;
  currencySymbol = getCurrencySymbol(this.profileService.getProfileCompany().currency, 'wide');
  currentFilterValues: object = {};
  debug = false;
  displayFilter = false;
  filterCfg: FilterConfiguration = {disableOnSubmit: false, disableOnInit: false, disableSubmitBtn: false};
  filters: QuestionBase<any>[];
  flags = this.featureFlags.flags;
  forecastDate: string;
  forecastForm: UntypedFormGroup = new UntypedFormGroup({});
  hasSupplierCampaign: boolean;
  inputs: QuestionBase<any>[];
  inputsVisibility = {estimatedDelivery: false, redemptionsPercentage: false};
  intervalInstance: any = null;
  loading = true;
  plan: Plan;
  planForecastData: PlanForecastCampaignGroup[];
  planId: string;
  subs$: Subscription[] = [];
  totalBudget = 0;
  budgetAlertEnabled = false;
  tableColumnsList: TableColumnList[] = this.getColumns();
  totals: PlanForecastDetailTotals;

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild('switchCoupons') switchCoupons: SwitchCouponsComponent;

  constructor(
    private filterService: DataTableFilterService,
    private translate: TranslateService,
    private plansService: PlansService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private planCampaignsService: PlanCampaignService,
    private couponsService: CouponsService,
    private modalStatusService: ModalStatusService,
    private csvExportService: CsvExportService,
    private decimalPipe: DecimalPipe,
    private dictionaryService: DictionaryService,
    private profileService: ProfileService,
    private qcs: QuestionControlService,
    private refreshCacheService: RefreshCacheService,
    private planCampaignActionService: PlanCampaignActionService,
    private featureFlags: FeatureFlagsService,
    private configurationService: ConfigurationService,
  ) {}

  ngOnInit() {
    this.getParams();
    this.handleStoredVisibility();
    if(this.flags.managePlanOrCampaignApprovers) {
      this.forbbidenRoles();
    }
    this.configurationService.getConfiguration('alerts', 'total_budget')
      .subscribe((config: ConfigurationEntryResponse) => this.budgetAlertEnabled = config.active);
  }

  ngOnDestroy() {
    if (this.intervalInstance) { clearInterval(this.intervalInstance); }
    this.subs$.forEach(s$ => s$.unsubscribe());
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFilterHandler($event) {
    this.currentFilterValues = $event;
    this.getPlanForecastData($event);
  }

  getInputs(): QuestionBase<any>[] {

    let campaignStatusListPlanForecast = this.dictionaryService.getValuesByKey('campaign_plan_status');
    campaignStatusListPlanForecast = campaignStatusListPlanForecast.filter(el => ['draft', 'completed', 'delivered', 'rejected'].includes(el['id']));

    return [
      new MultiSelectQuestion({
        key: 'campaign_plan_type',
        label: this.translate.instant('resources.plans.filters.campaign_type'),
        cssClasses: 'form-control input-default',
        settings: {singleSelection: false, enableSearchFilter: false, enableCheckAll: true, showCheckbox: true},
        options: this.getPlanCampaignCategoryOptions()
      }),
      new MultiSelectQuestion({
        key: 'campaign_plan_ids',
        label: this.translate.instant('resources.plans.filters.campaign'),
        cssClasses: 'form-control input-default',
        settings: {singleSelection: false, enableSearchFilter: true, enableCheckAll: true, showCheckbox: true},
        dataSource: this.planCampaignsService
      }),
      new MultiSelectQuestion({
        key: 'campaign_plan_status',
        label: this.translate.instant('resources.plans.filters.status'),
        cssClasses: 'form-control input-default',
        settings: {singleSelection: true, enableSearchFilter: false, enableCheckAll: false, showCheckbox: false},
        options: campaignStatusListPlanForecast
      })
    ];
  }

  /**
   * Calculates the forecast for plan actions that have edited fields.
   * It processes action requests in parallel, waiting for all of them to complete,
   * and then triggers a plan recalculation.
  */
  calculateForecast() {
    this.filters = null;
    this.loading = true;

    const actionRequests$ = this.planForecastData
      .map(pfd => pfd.actions.filter(action => action.hasEditedFields))
      .flat()
      .map(action => this.recalculateRow(action, false));

    zip(...actionRequests$).pipe(takeUntil(this.destroy$)).subscribe({
      error: (err) => {
        this.loading = false,
        this.confirmationService.displayHttpErrorAlert(err)
      },
      complete: () => this.planRecalculateRequest()
    });
  }

  exportCoupons() {
    const params = {...this.currentFilterValues, ...{plan_id: this.plan.id}};
    this.couponsService.exportData(params).pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.plans.messages.export_coupons_success_title'),
          this.translate.instant('resources.plans.messages.export_coupons_success_desc')
        ).catch(() => {});
      },
      error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
    });
  }

  exportForecast() {
    const csvData = this.dataToCSV();
    const blob = this.csvExportService.getCsvBlob(csvData);
    const url = window.URL.createObjectURL(blob);
    const filename = `plan_${this.plan.id}_forecast_${moment().format('DDMMYYYY')}.csv`;

    if (navigator['msSaveOrOpenBlob']) {
      navigator['msSaveBlob'](blob, filename);
    } else {
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  approveCampaign(campaign: EmbeddedCampaign) {
    if (this.plan.isDelivered()) { return; }
    if (['rejected', 'draft'].includes(campaign.status)) {
      this.planCampaignsService.submit(`${campaign.id}`).pipe(takeUntil(this.destroy$)).subscribe({
        next: (response: {warning_messages: string[]}) => {
          if (response.warning_messages?.length > 0) {
            const successTitle = this.translate.instant('resources.campaign_plans.messages.submit_success_title');
            const textWarnings = this.translate.instant('resources.campaign_plans.messages.submit_success_warnings_desc');
            const warnings = `${textWarnings}: <strong>${response.warning_messages.join(', ')}</strong>`;
            this.confirmationService.displayAlertWithHtml(successTitle, warnings, 'warning').catch(() => {});
          }
          this.getPlanActions(null, true);
        },
        error: (err: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(err)
      });
    }
  }

  parseCampaignsToApprove() {
    return this.planForecastData
      .flatMap(group => group.campaigns)
      .filter(campaign => campaign.status === 'draft')
      .map(campaign => campaign.id);
  }

  approveAllCampaigns() {
    const title = this.translate.instant('common.messages.are_you_sure');
    const text = this.translate.instant('resources.campaign_plans.messages.approve_all_campaigns');

    this.confirmationService.displayConfirmationAlert(title, text).then(data => {
      if ( data.hasOwnProperty('value') && data.value) {
        this.planCampaignsService.completeAll(this.parseCampaignsToApprove()).pipe(takeUntil(this.destroy$)).subscribe({
          next: (response: {warning_messages: string[]}) => {
            const successTitle = this.translate.instant('resources.campaign_plans.messages.approve_all_campaigns_success_title');
            if (response.warning_messages?.length > 0) {
              this.translate.instant('resources.campaign_plans.messages.approve_all_campaigns_success_title');
              const textWarnings = this.translate.instant('resources.campaign_plans.messages.submit_success_warnings_desc_plural');
              const warnings = `${textWarnings}: <strong>${response.warning_messages.join(', ')}</strong>`;
              this.confirmationService.displayAlertWithHtml(successTitle, warnings, 'warning').catch(() => {});
            } else {
              this.confirmationService.displaySuccessAlert(
                successTitle,
                this.translate.instant('resources.campaign_plans.messages.approve_all_campaigns_success_desc')
              ).catch(() => {});
            }
            this.getPlanActions(null, true);
          },
          error: (err: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(err)
       });
      }
    }).catch(() => {});
  }

  rejectCampaign(campaign: EmbeddedCampaign) {
    if (this.plan.isDelivered()) { return; }
    if (['completed', 'draft'].includes(campaign.status)) {
      this.planCampaignsService.reject(`${campaign.id}`).pipe(takeUntil(this.destroy$)).subscribe({
        next: () => this.getPlanActions(null, true),
        error: (err: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(err)
      });
    }
  }

  recalculateRow(action: PlanCampaignAction, autoRecalculateRequest = true): Observable<any> {
    this.loading = true;
    const req$ = this.planCampaignActionService.patchCampaign(this.getActionPayload(action), action.id);
    if(autoRecalculateRequest){
      req$.pipe(takeUntil(this.destroy$)).subscribe(
        () => {
          this.loading = false;
          this.recalc(action);
        }
      )
    } else {
      return req$;
    }
  }

  openSwitchCouponsModal() {
    this.switchCoupons.open();
  }

  /*
  * Prevents dropdown from collapsing so the end-user can keep (de)selecting columns
  */
  tableCfgClickHandler(event: MouseEvent, colId: string) {
    event.stopPropagation();
    const cbValue = (<HTMLInputElement>event.target).checked;
    if (!isNullOrUndefined(cbValue)){
      this.tableColumnsList.find(el => el.id === colId).visible = (<HTMLInputElement>event.target).checked;
    }
    localStorage.setItem(this.LOCAL_STORAGE_VISIBILITY_KEY, JSON.stringify(this.tableColumnsList.map(el => el.visible)));
  }

  isForecastInProgress() {
    return this.plan && this.plan?.isForecastInProgress();
  }

  isPlanDelivered() {
    return this.plan && this.plan.isDelivered();
  }

  isForecastCalculable() {
    return !this.isPlanDelivered() && !this.isForecastInProgress();
  }

  toggleInputDisplay(action, string) {
    action[string] = !action[string]
  }

  getInputConfig(inputKey: string): QuestionBase<any> {
    return this.qcs.getInputCfgByKey(this.inputs, inputKey);
  }

  handleFormChanges(action: PlanCampaignAction) {
    action.hasEditedFields = true;
  }

  // PRIVATE METHODS

  private getActionPayload(action: PlanCampaignAction): ActionPayload {
    const estimatedDelivery = parseFloat(this.forecastForm.get(`estimated_delivery_${action.campaignId}`).value);
    const estimatedRedemptions = parseFloat(this.forecastForm.get(`estimated_redemption_${action.campaignId}`).value)
    return {
      forecast_config: {
        delivered_percentage: estimatedDelivery,
        total_redemptions_percentage: estimatedRedemptions
      },
      audience_config: {
        estimated_delivery: estimatedDelivery,
        estimated_redemption: estimatedRedemptions,
        estimated_customer_redemption: action.estimatedCustomerRedemption
      }
    }
  }

  private planRecalculateRequest() {
    this.plansService.calculateForecast(`${this.plan.id}`).pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.plans.messages.calc_forecast_success_title'),
          this.translate.instant('resources.plans.messages.calc_forecast_success_desc')
        ).catch(() => {});
        this.getPlan(`${this.plan.id}`);
      },
      error: (errorData: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(errorData)
    });
  }

  private forbbidenRoles() {
    const userRole = this.profileService.getStoredUserRole();
    this.ableToApprove = ['admin', 'owner', 'manager', 'user_manager'].includes(userRole);
  }

  private recalc(action: PlanCampaignAction) {
    const endpoint = `actions/${action.id}/calculate_data`;
    const body = { operation: 'forecast' };
    this.loading = true;
    this.refreshCacheService.postExpensiveData(endpoint, body).pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: object) => {
        if (!this.refreshCacheService.isRequestPending(response)) {
          this.loading = false;
          this.getPlanActions(null, true);
        }
      },
      error: (err: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(err)
    });
  }

  private setInputs(action?: PlanCampaignAction) {
    const inputs = [
      new FloatQuestion({
        cssClasses: 'form-control input-default',
        key: `estimated_delivery_${action.campaignId}`,
        type: 'number',
        step: 0.01,
        value: action.forecastConfig && action.forecastConfig.delivered_percentage ? action.forecastConfig.delivered_percentage : action.forecast.estimatedDelivery
      }),
      new FloatQuestion({
        cssClasses: 'form-control input-default',
        key: `estimated_redemption_${action.campaignId}`,
        type: 'number',
        step: 0.01,
        value: action.forecastConfig && action.forecastConfig.total_redemptions_percentage ? action.forecastConfig.total_redemptions_percentage : action.forecast.totalRedemptionsPercentage
      }),
    ];

    inputs.forEach(input => {
      this.qcs.addToFormGroup(this.forecastForm, input);
      this.inputs.push(input);
    });
  }

  private getParams() {
    this.route.parent.params.pipe(takeUntil(this.destroy$)).subscribe(parentParams => {
      this.planId = parentParams.id;
      this.planCampaignsService.setPlan(parseInt(parentParams.id, 10));
      this.getPlan(this.planId);
    });
  }

  private getPlan(planId: string) {
    this.plansService.getPlanById(planId).pipe(takeUntil(this.destroy$)).subscribe({
      next: (planData: HttpResponse<Object>) => {
        this.plan = new Plan(planData);
        this.forecastDate = this.plan.planForecastDate;
        this.modalStatusService.resourceStatus.emit(this.plan);
        this.filters = this.getInputs();
        this.displayFilter = !this.plan.isForecastInProgress() && !this.plan.isRoiInProgress();
        if (this.plan.isForecastInProgress()) {
          this.startIterativeCheckRequest();
        } else {
          if (this.intervalInstance) { clearInterval(this.intervalInstance); }
          this.getPlanForecastData();
        }
      },
      error: (err: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(err)
    });
  }

  private startIterativeCheckRequest() {
    if (isNullOrUndefined(this.intervalInstance)) {
      this.intervalInstance = setInterval(() => this.getPlan(`${this.plan.id}`), this.INTERVAL_DELAY);
    }
  }

  private getPlanCampaignCategoryOptions(): MultiselectDataSource[] {
    return this.plansService.getStaticTypes().slice(0, 4).map(el => new MultiselectDataSource(el.type, el.name));
  }

  private getPlanForecastData(filters?: Object) {
    if (this.debug) {
      this.enqueueDebugRequest(filters);
    } else {
      this.getPlanActions(filters);
    }
  }

  private enqueueDebugRequest(filters?: Object) {
    this.loading = true;
    this.filterService.loaderStatus.next(false);
    setTimeout(() => {
      this.loading = false;
      this.filterService.loaderStatus.next(true);
      this.planForecastData = this.parseActions(PlanForecastDetailActionsMock);
      this.calculateTotalValues();
    }, 500);
  }

  private handleEditableInputs() {
    this.inputs = [];
    this.planForecastData.forEach(element => {
      element.actions.forEach(action => {
        action['deliveryVisibility'] = false;
        action['redemptionsVisibility'] = false;
        this.setInputs(action)
      });
    });
  }

  private getPlanActions(filters?: Object, overrideLoader?: boolean) {
    this.loading = overrideLoader ? false : true;
    this.plansService.getActions(`${this.plan.id}`, filters)
      .pipe(takeUntil(this.destroy$), finalize(() => this.loading = false))
      .subscribe({
        next: (reqResponse) => {
          if (isNullOrUndefined(filters)) {
            this.totalBudget = this.calculateTotalBudget(reqResponse['_embedded']['list']);
          }
          this.planForecastData = this.parseActions(reqResponse);
          this.handleEditableInputs();
          this.calculateTotalValues();
        },
        error: (err: HttpErrorResponse) => this.confirmationService.displayHttpErrorAlert(err)
      });
  }

  private calculateTotalValues() {

    const incSales = this.planForecastData.reduce((acc, obj) => acc + (obj.subtotal.incSales || 0), 0);
    const budgetValue = this.planForecastData.reduce((acc, obj) => acc + (obj.subtotal.budgetExpense || 0), 0);
    const impactedCustomers = this.planForecastData.reduce((acc, obj) => acc + (obj.subtotal.impactedCustomers || 0), 0);
    const couponsDelivered = this.planForecastData.reduce((acc, obj) => acc + (obj.subtotal.couponsDelivered || 0), 0);
    const redemptions = this.planForecastData.reduce((acc, obj) => acc + (obj.subtotal.totalRedemptions || 0), 0);
    const customers = this.planForecastData.reduce((acc, obj) => acc + (obj.subtotal.totalCustomers || 0), 0);
    const affectationSales = this.planForecastData.reduce((acc, obj) => acc + (obj.subtotal.affectationSales || 0), 0);
    const sumSalesMpaa = this.planForecastData.reduce((acc, obj) => acc + (obj.subtotal.sumSalesMpaa || 0), 0);
    const sumSalesPeriod5Mpaa = this.planForecastData.reduce((acc, obj) => acc + (obj.subtotal.sumSalesPeriod5Mpaa || 0), 0);
    const seasonalityAvg = sumSalesPeriod5Mpaa !== 0 ? (sumSalesMpaa / sumSalesPeriod5Mpaa) : 0;
    const maxRedemptionsPerCoupon = this.planForecastData.reduce((acc, obj) => acc + (obj.subtotal.maxRedemptionsPerCoupon || 0), 0);
    const estimatedRedemptionPerc = couponsDelivered !== 0 ? ((redemptions / couponsDelivered) * 100) : 0;
    const uniqueRedemptions = this.planForecastData.reduce((acc, obj) => acc + (Math.round(obj.subtotal.uniqueRedemptions) || 0), 0);
    const discountCost = this.planForecastData.reduce((acc, obj) => acc + (obj.subtotal.budgetExpense || 0), 0);
    const lgCost = this.planForecastData.reduce((acc, obj) => acc + (obj.subtotal.lgCost || 0), 0);
    const totalAffectationSalesByPlanDaysCount = affectationSales * getPlanDaysCount(this.plan);
    const incrementalOverSales = totalAffectationSalesByPlanDaysCount !== 0 ? (incSales / totalAffectationSalesByPlanDaysCount) * 100 : 0;
    const deliveryCost = this.planForecastData.reduce((acc, obj) => acc + (obj.subtotal.deliveryCost || 0), 0);

    this.totals = {
      impactedCustomers: impactedCustomers,
      couponsDelivered: couponsDelivered,
      budgetValue: budgetValue,
      budgetRatioValue: this.planForecastData.reduce((acc, obj) => acc + (obj.subtotal.budgetRatio || 0), 0),
      incSales: incSales,
      redemptions: redemptions,
      incSalesRatio: budgetValue === 0 ? 0 : incSales / budgetValue,
      estimatedDelivery: impactedCustomers === 0 ? 0 : couponsDelivered / impactedCustomers,
      calcTotalRedemptions: couponsDelivered === 0 ? 0 : redemptions / couponsDelivered,
      customers: customers,
      affectationSales: affectationSales,
      seasonality: seasonalityAvg,
      maxRedemptionsPerCoupon: maxRedemptionsPerCoupon,
      estimatedRedemptionPerc: estimatedRedemptionPerc,
      uniqueRedemptions: uniqueRedemptions,
      discountCost: discountCost,
      lgCost: lgCost,
      incrementalOverSales: incrementalOverSales,
      deliveryCost: deliveryCost
    };

    this.hasSupplierCampaign = this.planForecastData.some(campaign => campaign.type === 'Plans::SupplierCampaign');
  }

  private calculateTotalBudget(actionList: object[]): number {
    const actionsWithCoupon = this.plan.isDelivered() ? actionList.filter(this.onlyDelivered) : actionList.filter(this.onlyCompleted);
    return actionsWithCoupon.reduce((acc, obj) => acc + (this.retrieveDiscountCost(obj) || 0), 0);
  }

  private retrieveDiscountCost(action: Object): number {
    if (action && action['forecast'] && action['forecast'].hasOwnProperty('data') && action['forecast']['data'].hasOwnProperty('discount_cost')) {
      return action['forecast']['data']['discount_cost'];
    } else {
      return 0;
    }
  }

  private parseActions(response: Object): PlanForecastCampaignGroup[] {
    const actionList = response['_embedded']['list'];
    const actionsWithCoupon = actionList.filter(this.onlyDisplayable);
    const typesList = actionsWithCoupon.map((el: Object) => el['_embedded']['campaign_plan']['type']).filter(this.onlyUnique);
    let planForecastCampaignGroups: PlanForecastCampaignGroup[] = typesList.map((type: string) => {
      const actions = actionsWithCoupon.filter((action: Object) => action['_embedded']['campaign_plan']['type'] === type);
      return new PlanForecastCampaignGroup({
        type: type,
        category: this.translate.instant(`resources.campaign_plans.types.dictionary.${type}`),
        actions: actions,
        plan: this.plan
      });
    });
    planForecastCampaignGroups = this.calculateActionsPercentageBudget(planForecastCampaignGroups);
    return planForecastCampaignGroups;
  }

  private calculateActionsPercentageBudget(planForecastCampaignGroups: PlanForecastCampaignGroup[]): PlanForecastCampaignGroup[] {
    planForecastCampaignGroups.forEach(group => {
      group.campaigns.forEach(campaign => {
        if (['completed', 'delivered'].includes(campaign.status)) {
          campaign.budgetExpenseRatio = this.totalBudget === 0 ? 0 : (campaign.budgetExpense / this.totalBudget );
        } else {
          campaign.budgetExpenseRatio = 0;
        }
        campaign.actions.forEach(action => {
          if (['completed', 'delivered'].includes(action.embeddedCampaign.status)) {
            action.forecast.budgetRatio = this.totalBudget === 0 ? 0 : (action.forecast.budgetExpense / this.totalBudget );
          } else {
            action.forecast.budgetRatio = 0;
          }
        });
        group.subtotal.budgetRatio = this.totalBudget === 0 ? 0 : (group.subtotal.budgetExpense / this.totalBudget );
      });
    });
    return planForecastCampaignGroups;
  }

  private onlyDisplayable(action: Object, index: number, self: Object[]) {
    const couponId = action['_embedded'] && action['_embedded']['coupon'] && action['_embedded']['coupon']['id'] ? action['_embedded']['coupon']['id'] : null;
    return couponId && ['draft', 'completed', 'delivered', 'rejected'].includes(action['_embedded']['campaign_plan']['status']);
  }

  private onlyCompleted(action: Object, index: number, self: Object[]) {
    const couponId = action['_embedded'] && action['_embedded']['coupon'] && action['_embedded']['coupon']['id'] ? action['_embedded']['coupon']['id'] : null;
    return couponId && action['_embedded']['campaign_plan']['status'] === 'completed';
  }

  private onlyDelivered(action: Object, index: number, self: Object[]) {
    const couponId = action['_embedded'] && action['_embedded']['coupon'] && action['_embedded']['coupon']['id'] ? action['_embedded']['coupon']['id'] : null;
    return couponId && action['_embedded']['campaign_plan']['status'] === 'delivered';
  }

  private onlyUnique(value: any, index: number, self: any[]) {
    return self.indexOf(value) === index;
  }

  private dataToCSV(): string {
    const location = 'es';
    let stringCsv = '';
    this.tableColumnsList.forEach(col => {
      if (col.visible) { stringCsv += col.plainTitle + ';'; }
    });
    stringCsv += this.translate.instant('resources.plans.columns.status') + ';';
    stringCsv += '\n';

    this.planForecastData.forEach((detail) => {
      detail.campaigns.forEach((campaign) => {
        campaign.actions.forEach((action) => {
          if (this.tableColumnsList[0].visible) { stringCsv += detail.category + ';'; }
          if (this.tableColumnsList[1].visible) { stringCsv += campaign.name + ';'; }
          if (this.tableColumnsList[2].visible) { stringCsv += action.campaignId + ';'; }
          if (this.tableColumnsList[3].visible) { stringCsv += action.name + ';'; }
          if (this.tableColumnsList[4].visible) { stringCsv += this.decimalPipe.transform(action.forecast.customers, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[5].visible) { stringCsv += this.decimalPipe.transform(action.forecast.estimatedDelivery, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[6].visible) {
            const estimatedRedemption = action.forecastConfig?.total_redemptions_percentage || action.forecast.redemptionsPercentage;
            stringCsv +=  this.decimalPipe.transform(estimatedRedemption, '1.2-2', location) + ';';
          }
          if (this.tableColumnsList[7].visible) { stringCsv += this.decimalPipe.transform(action.forecast.budgetExpense, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[8].visible) { stringCsv += this.decimalPipe.transform(action.forecast.budgetRatio * 100, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[9].visible) { stringCsv += this.decimalPipe.transform(action.forecast.incSales, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[10].visible) { stringCsv += this.decimalPipe.transform(action.forecast.incRatio, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[11].visible) { stringCsv += this.decimalPipe.transform(action.forecast.netIncrementalRatio, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[12].visible) { stringCsv += action.coupon_id + ';'; }
          if (this.tableColumnsList[13].visible) { stringCsv += action.couponName + ';'; }
          if (this.tableColumnsList[14].visible) {
            const couponAffectation = ['purchase', 'product_category'].includes(action.couponAffectation) ? this.translate.instant('resources.coupons.fields.' + action.couponAffectation) : '';
            stringCsv += couponAffectation + ';';
          }
          if (this.tableColumnsList[15].visible) { stringCsv += action.couponPriority + ';'; }
          if (this.tableColumnsList[16].visible) { stringCsv += this.decimalPipe.transform(action.audience.averageExpenditure, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[17].visible) { stringCsv += this.decimalPipe.transform(action.audience.averageTicket, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[18].visible) { stringCsv += this.decimalPipe.transform(action.audience.units, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[19].visible) { stringCsv += this.decimalPipe.transform(action.audience.frequency, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[20].visible) { stringCsv += this.decimalPipe.transform(action.audience.pvp, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[21].visible) { stringCsv += this.decimalPipe.transform(action.audience.affectationSales, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[22].visible) { stringCsv += this.decimalPipe.transform(action.audience.seasonality, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[23].visible) { stringCsv += this.decimalPipe.transform(action.audience.controlGroup, '1.0-0', location) + ';'; }
          if (this.tableColumnsList[24].visible) { stringCsv += this.decimalPipe.transform(action.audience.minMoney, '1.0-0', location) + ';'; }
          if (this.tableColumnsList[25].visible) { stringCsv += this.decimalPipe.transform(action.audience.minUnits, '1.0-0', location) + ';'; }
          if (this.tableColumnsList[26].visible) { stringCsv += this.decimalPipe.transform(action.forecast.maxRedemptionsPerCoupon, '1.0-0', location) + ';'; }
          if (this.tableColumnsList[27].visible) { stringCsv += this.decimalPipe.transform(action.forecast.totalRedemptions, '1.0-0', location) + ';'; }
          if (this.tableColumnsList[28].visible) { stringCsv += this.decimalPipe.transform(action.forecast.estimatedRedemption, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[29].visible) { stringCsv += this.decimalPipe.transform(action.forecast.redemptionsPerCustomer, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[30].visible) { stringCsv += this.decimalPipe.transform(action.forecast.uniqueRedemptions, '1.0-0', location) + ';'; }
          if (this.tableColumnsList[31].visible) { stringCsv += this.decimalPipe.transform(action.forecast.discount, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[32].visible) { stringCsv += this.decimalPipe.transform(action.forecast.minPurchase, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[33].visible && action.forecast.discountType === 'cash') {
            stringCsv += this.decimalPipe.transform(action.forecast.discountOverPurchase, '1.2-2', location) + ';';
          } else if (this.tableColumnsList[33].visible && action.forecast.discountType !== 'cash') {
            stringCsv += this.decimalPipe.transform(action.forecast.discount, '1.2-2', location) + ';';
          }
          if (this.tableColumnsList[34].visible) { stringCsv += this.decimalPipe.transform(action.forecast.budgetExpense, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[35].visible) { stringCsv += this.decimalPipe.transform(action.forecast.deliveryCost, '1.2-2', location) + ';'; }
          if (this.tableColumnsList[36].visible) { stringCsv += detail.type === 'Plans::SupplierCampaign' ? this.decimalPipe.transform(action.forecast.lgCost, '1.2-2', location) + ';' : this.translate.instant('common.fields.na') + ';'; }
          if (this.tableColumnsList[37].visible) { stringCsv += this.decimalPipe.transform(action.forecast.incrementalOverSales, '1.2-2', location) + ';'; }
          // Status
          stringCsv += this.translate.instant(`resources.campaign_plans.status.${action.embeddedCampaign.status}`) + ';';
          stringCsv += '\n';
        });

        // CAMPAIGN TOTAL ROW
        if (this.tableColumnsList[0].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[1].visible) { stringCsv += this.translate.instant('resources.campaign_plans.types.custom.forecast.table.campaign_total') + ';'; }
        if (this.tableColumnsList[2].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[3].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[4].visible) { stringCsv += this.decimalPipe.transform(campaign.impactedCustomers, '1.2-2', location) + ';'; }
        if (this.tableColumnsList[5].visible) { stringCsv += this.decimalPipe.transform(campaign.estimatedDelivery * 100, '1.2-2', location) + ';'; }
        if (this.tableColumnsList[6].visible) { stringCsv += this.decimalPipe.transform(campaign.redemptions * 100, '1.2-2', location) + ';'; }
        if (this.tableColumnsList[7].visible) { stringCsv += this.decimalPipe.transform(campaign.budgetExpense, '1.2-2', location) + ';'; }
        if (this.tableColumnsList[8].visible) { stringCsv += this.decimalPipe.transform(campaign.budgetExpenseRatio * 100, '1.2-2', location) + ';'; }
        if (this.tableColumnsList[9].visible) { stringCsv += this.decimalPipe.transform(campaign.incSales, '1.2-2', location) + ';'; }
        if (this.tableColumnsList[10].visible) { stringCsv += this.decimalPipe.transform(campaign.incSalesRatio, '1.2-2', location) + ';'; }
        if (this.tableColumnsList[11].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[12].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[13].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[14].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[15].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[16].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[17].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[18].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[19].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[20].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[21].visible) { stringCsv += this.decimalPipe.transform(campaign.affectationSales, '1.2-2', location) + ';'; }
        if (this.tableColumnsList[22].visible) { stringCsv += this.decimalPipe.transform(campaign.seasonality, '1.2-2', location) + ';'; }
        if (this.tableColumnsList[23].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[24].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[25].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[26].visible) { stringCsv += this.decimalPipe.transform(campaign.maxRedemptionsPerCoupon, '1.0-0', location) + ';'; }
        if (this.tableColumnsList[27].visible) { stringCsv += this.decimalPipe.transform(campaign.totalRedemptions, '1.0-0', location) + ';'; }
        if (this.tableColumnsList[28].visible) { stringCsv += this.decimalPipe.transform(campaign.estimatedRedemptionPerc, '1.2-2', location) + ';'; }
        if (this.tableColumnsList[29].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[30].visible) { stringCsv += this.decimalPipe.transform(campaign.uniqueRedemptions, '1.0-0', location) + ';'; }
        if (this.tableColumnsList[31].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[32].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[33].visible) { stringCsv += '"";'; }
        if (this.tableColumnsList[34].visible) { stringCsv += this.decimalPipe.transform(campaign.discountCost, '1.2-2', location) + ';'; }
        if (this.tableColumnsList[35].visible) { stringCsv += this.decimalPipe.transform(campaign.deliveryCost, '1.2-2', location) + ';'; }
        if (this.tableColumnsList[36].visible) { stringCsv += detail.type === 'Plans::SupplierCampaign' ? this.decimalPipe.transform(campaign.lgCost, '1.2-2', location) + ';' : this.translate.instant('common.fields.na') + ';'; }
        if (this.tableColumnsList[37].visible) { stringCsv += this.decimalPipe.transform(campaign.incrementalOverSales, '1.2-2', location) + ';'; }
        stringCsv += '"";';
        stringCsv += '\n';
      });

      // SUBTOTAL ROW
      if (this.tableColumnsList[0].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[1].visible) { stringCsv += this.translate.instant('common.subtotal') + ';'; }
      if (this.tableColumnsList[2].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[3].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[4].visible) { stringCsv += this.decimalPipe.transform(detail.subtotal.impactedCustomers, '1.2-2', location) + ';'; }
      if (this.tableColumnsList[5].visible) { stringCsv += this.decimalPipe.transform(detail.subtotal.estimatedDelivery * 100, '1.2-2', location) + ';'; }
      if (this.tableColumnsList[6].visible) { stringCsv += this.decimalPipe.transform(detail.subtotal.totalRedemptionsPercentage * 100, '1.2-2', location) + ';'; }
      if (this.tableColumnsList[7].visible) { stringCsv += this.decimalPipe.transform(detail.subtotal.budgetExpense, '1.2-2', location) + ';'; }
      if (this.tableColumnsList[8].visible) { stringCsv += this.decimalPipe.transform(detail.subtotal.budgetRatio * 100, '1.2-2', location) + ';'; }
      if (this.tableColumnsList[9].visible) { stringCsv += this.decimalPipe.transform(detail.subtotal.incSales, '1.2-2', location) + ';'; }
      if (this.tableColumnsList[10].visible) { stringCsv += this.decimalPipe.transform(detail.subtotal.incRatio, '1.2-2', location) + ';'; }
      if (this.tableColumnsList[11].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[12].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[13].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[14].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[15].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[16].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[17].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[18].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[19].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[20].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[21].visible) { stringCsv += this.decimalPipe.transform(detail.subtotal.affectationSales, '1.2-2', location) + ';'; }
      if (this.tableColumnsList[22].visible) { stringCsv += this.decimalPipe.transform(detail.subtotal.seasonality, '1.2-2', location) + ';'; }
      if (this.tableColumnsList[23].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[24].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[25].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[26].visible) { stringCsv += this.decimalPipe.transform(detail.subtotal.maxRedemptionsPerCoupon, '1.0-0', location) + ';'; }
      if (this.tableColumnsList[27].visible) { stringCsv += this.decimalPipe.transform(detail.subtotal.totalRedemptions, '1.0-0', location) + ';'; }
      if (this.tableColumnsList[28].visible) { stringCsv += this.decimalPipe.transform(detail.subtotal.estimatedRedemptionPerc, '1.2-2', location) + ';'; }
      if (this.tableColumnsList[29].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[30].visible) { stringCsv += this.decimalPipe.transform(detail.subtotal.uniqueRedemptions, '1.0-0', location) + ';'; }
      if (this.tableColumnsList[31].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[32].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[33].visible) { stringCsv += '"";'; }
      if (this.tableColumnsList[34].visible) { stringCsv += this.decimalPipe.transform(detail.subtotal.discountCost, '1.2-2', location) + ';'; }
      if (this.tableColumnsList[35].visible) { stringCsv += this.decimalPipe.transform(detail.subtotal.deliveryCost, '1.2-2', location) + ';'; }
      // NEED DATA FOR LG COST
      if (this.tableColumnsList[36].visible) { stringCsv += detail.type === 'Plans::SupplierCampaign' ? this.decimalPipe.transform(detail.subtotal.lgCost, '1.2-2', location) + ';' : this.translate.instant('common.fields.na') + ';'; }

      if (this.tableColumnsList[37].visible) { stringCsv += this.decimalPipe.transform(detail.subtotal.incrementalOverSales, '1.2-2', location) + ';'; }
      stringCsv += '"";';
      stringCsv += '\n';
    });

    if (this.tableColumnsList[0].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[1].visible) { stringCsv += this.translate.instant('common.total') + ';'; }
    if (this.tableColumnsList[2].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[3].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[4].visible) { stringCsv += this.decimalPipe.transform(this.totals.impactedCustomers, '1.2-2', location) + ';'; }
    if (this.tableColumnsList[5].visible) { stringCsv += this.decimalPipe.transform(this.totals.estimatedDelivery * 100, '1.2-2', location) + ';'; }
    if (this.tableColumnsList[6].visible) { stringCsv += this.decimalPipe.transform(this.totals.calcTotalRedemptions * 100, '1.2-2', location) + ';'; }
    if (this.tableColumnsList[7].visible) { stringCsv += this.decimalPipe.transform(this.totals.budgetValue, '1.2-2', location) + ';'; }
    if (this.tableColumnsList[8].visible) { stringCsv += this.decimalPipe.transform(this.totals.budgetRatioValue * 100, '1.2-2', location) + ';'; }
    if (this.tableColumnsList[9].visible) { stringCsv += this.decimalPipe.transform(this.totals.incSales, '1.2-2', location) + ';'; }
    if (this.tableColumnsList[10].visible) { stringCsv += this.decimalPipe.transform(this.totals.incSalesRatio, '1.2-2', location) + ';'; }
    if (this.tableColumnsList[11].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[12].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[13].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[14].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[15].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[16].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[17].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[18].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[19].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[20].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[21].visible) { stringCsv += this.decimalPipe.transform(this.totals.affectationSales, '1.2-2', location) + ';'; }
    if (this.tableColumnsList[22].visible) { stringCsv += this.decimalPipe.transform(this.totals.seasonality, '1.2-2', location) + ';'; }
    if (this.tableColumnsList[23].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[24].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[25].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[26].visible) { stringCsv += this.decimalPipe.transform(this.totals.maxRedemptionsPerCoupon, '1.0-0', location) + ';'; }
    if (this.tableColumnsList[27].visible) { stringCsv += this.decimalPipe.transform(this.totals.redemptions, '1.0-0', location) + ';'; }
    if (this.tableColumnsList[28].visible) { stringCsv += this.decimalPipe.transform(this.totals.estimatedRedemptionPerc, '1.2-2', location) + ';'; }
    if (this.tableColumnsList[29].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[30].visible) { stringCsv += this.decimalPipe.transform(this.totals.uniqueRedemptions, '1.0-0', location) + ';'; }
    if (this.tableColumnsList[31].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[32].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[33].visible) { stringCsv += '"";'; }
    if (this.tableColumnsList[34].visible) { stringCsv += this.decimalPipe.transform(this.totals.discountCost, '1.2-2', location) + ';'; }
    if (this.tableColumnsList[35].visible) { stringCsv += this.decimalPipe.transform(this.totals.deliveryCost, '1.2-2', location) + ';'; }
    if (this.tableColumnsList[36].visible) { stringCsv += this.planForecastData['type'] === 'Plans::SupplierCampaign' ? this.decimalPipe.transform(this.totals.lgCost, '1.2-2', location) + ';' : this.translate.instant('common.fields.na') + ';'; }
    if (this.tableColumnsList[37].visible) { stringCsv += this.decimalPipe.transform(this.totals.incrementalOverSales, '1.2-2', location) + ';'; }
    stringCsv += '"";';
    stringCsv += '\n';
    return stringCsv;
  }

  private getColumns(): {id: string, headerTitle: string, plainTitle: string, visible: boolean, disabled?: boolean}[] {
    return [
      // Default columns
      {
        id: 'campaign_type',
        headerTitle: this.translate.instant('resources.plans.columns.campaign_type'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.campaign_type')),
        visible: true,
        disabled: true
      },
      {
        id: 'campaign_name',
        headerTitle: this.translate.instant('resources.plans.columns.campaign_name'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.campaign_name')),
        visible: true,
        disabled: true
      },
      {
        id: 'campaign_id',
        headerTitle: this.translate.instant('resources.plans.columns.campaign_id'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.campaign_id')),
        visible: true,
        disabled: true
      },
      {
        id: 'segment_name',
        headerTitle: this.translate.instant('resources.plans.columns.segment_name'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.segment_name')),
        visible: true,
        disabled: true
      },
      {
        id: 'delivered_coupons',
        headerTitle: this.translate.instant('resources.plans.columns.delivered_coupons'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.delivered_coupons')),
        visible: true
      },
      {
        id: 'delivered_percentage',
        headerTitle: this.translate.instant('resources.plans.columns.delivered_percentage'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.delivered_percentage')),
        visible: true
      },
      {
        id: 'redemptions_percentage',
        headerTitle: this.translate.instant('resources.plans.columns.redemptions_percentage'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.redemptions_percentage')),
        visible: true
      },
      {
        id: 'budget',
        headerTitle: this.translate.instant('resources.plans.columns.budget', {currencySymbol: this.currencySymbol}),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.budget', {currencySymbol: this.currencySymbol})),
        visible: true
      },
      {
        id: 'budget_percentage',
        headerTitle: this.translate.instant('resources.plans.columns.budget_percentage'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.budget_percentage')),
        visible: true
      },
      {
        id: 'inc_sales',
        headerTitle: this.translate.instant('resources.plans.columns.inc_sales'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.inc_sales')),
        visible: true
      },
      {
        id: 'inc_sales_ratio',
        headerTitle: this.translate.instant('resources.plans.columns.inc_sales_ratio'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.inc_sales_ratio')),
        visible: true
      },
      {
        id: 'net_incremental_ratio',
        headerTitle: this.translate.instant('resources.plans.columns.net_incremental_ratio'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.net_incremental_ratio')),
        visible: true
      },
      {
        id: 'coupon_id',
        headerTitle: this.translate.instant('resources.plans.columns.coupon_id'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.coupon_id')),
        visible: true
      },
      {
        id: 'coupon_name',
        headerTitle: this.translate.instant('resources.plans.columns.coupon_name'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.coupon_name')),
        visible: true
      },
      {
        id: 'coupon_affectation',
        headerTitle: this.translate.instant('resources.plans.columns.coupon_affectation'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.coupon_affectation')),
        visible: true
      },
      {
        id: 'priority',
        headerTitle: this.translate.instant('resources.plans.columns.priority'),
        plainTitle: this.normalizeString(this.translate.instant('resources.plans.columns.priority')),
        visible: true
      },
      // Columns retrieved from audience
      {
        id: 'average_expenditure',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.audience.table.average_expenditure', {currencySymbol: this.currencySymbol}),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.audience.table.average_expenditure', {currencySymbol: this.currencySymbol})),
        visible: false
      },
      {
        id: 'average_ticket',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.audience.table.average_ticket', {currencySymbol: this.currencySymbol}),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.audience.table.average_ticket', {currencySymbol: this.currencySymbol})),
        visible: false
      },
      {
        id: 'units',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.audience.table.units'),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.audience.table.units')),
        visible: false
      },
      {
        id: 'frequency',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.audience.table.frequency'),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.audience.table.frequency')),
        visible: false
      },
      {
        id: 'pvp',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.audience.table.pvp'),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.audience.table.pvp')),
        visible: false
      },
      {
        id: 'affectation_sales',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.audience.table.affectation_sales'),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.audience.table.affectation_sales')),
        visible: false
      },
      {
        id: 'seasonality',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.audience.table.seasonality'),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.audience.table.seasonality')),
        visible: false
      },
      {
        id: 'control_group',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.audience.table.control_group'),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.audience.table.control_group')),
        visible: false
      },
      {
        id: 'minimum_money',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.audience.table.minimum_money'),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.audience.table.minimum_money')),
        visible: false
      },
      {
        id: 'minimum_units',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.audience.table.minimum_units'),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.audience.table.minimum_units')),
        visible: false
      },
      // Columns retrieved from forecast
      {
        id: 'max_coupon_redemptions',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.max_coupon_redemptions'),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.forecast.table.max_coupon_redemptions')),
        visible: false
      },
      {
        id: 'total_redemptions',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.total_redemptions'),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.forecast.table.total_redemptions')),
        visible: false
      },
      {
        id: 'estimated_redemption',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.estimated_redemption'),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.forecast.table.estimated_redemption')),
        visible: false
      },
      {
        id: 'redemption_per_customer',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.redemption_per_customer'),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.forecast.table.redemption_per_customer')),
        visible: false
      },
      {
        id: 'unique_redemptions',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.unique_redemptions'),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.forecast.table.unique_redemptions')),
        visible: false
      },
      {
        id: 'discount',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.discount'),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.forecast.table.discount')),
        visible: false
      },
      {
        id: 'min_purchase',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.min_purchase', {currencySymbol: this.currencySymbol}),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.forecast.table.min_purchase', {currencySymbol: this.currencySymbol})),
        visible: false
      },
      {
        id: 'percent_discount',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.percent_discount'),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.forecast.table.percent_discount')),
        visible: false
      },
      {
        id: 'discount_cost',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.discount_cost', {currencySymbol: this.currencySymbol}),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.forecast.table.discount_cost', {currencySymbol: this.currencySymbol})),
        visible: false
      },
      {
        id: 'delivery_cost',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.delivery_cost', {currencySymbol: this.currencySymbol}),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.forecast.table.delivery_cost', {currencySymbol: this.currencySymbol})),
        visible: true
      },
      {
        id: 'lg_cost',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.lg_cost'),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.forecast.table.lg_cost')),
        visible: true
      },
      {
        id: 'increase_total_sales',
        headerTitle: this.translate.instant('resources.campaign_plans.types.custom.forecast.table.increase_total_sales'),
        plainTitle: this.normalizeString(this.translate.instant('resources.campaign_plans.types.custom.forecast.table.increase_total_sales')),
        visible: false
      }
    ];
  }

  private handleStoredVisibility() {
    const visibilityCfg = JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_VISIBILITY_KEY));
    if (visibilityCfg) { this.tableColumnsList.forEach((el, i) => el.visible = visibilityCfg[i]); }
  }

  private normalizeString(str: string) {
    return str.replace(/<br>/g, '');
  }

}
