import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { Subject, Subscription, timer } from 'rxjs';

import { SharedModule } from '../../../shared/shared.module';

import { QuestionControlService } from '../../../shared/services/question-control.service';
import { AlertsService } from '../../../shared/services/alerts/alerts.service';
import { PlansService } from '../plans.service';
import { PlanAlertsStatus } from '../plan';

import { CheckboxQuestion } from '../../../shared/models/forms/question-checkbox';

import { pollUntil } from '../../../shared/utils/common.utils';

@Component({
  selector: 'app-plans-alerts-detail',
  standalone: true,
  imports: [CommonModule, SharedModule, ReactiveFormsModule, TableModule],
  providers: [AlertsService],
  templateUrl: './plans-alerts-detail.component.html',
  styleUrl: './plans-alerts-detail.component.scss'
})
export class PlansAlertsDetailComponent {
  static readonly baseAlertsPath = 'plans';

  PlanAlertsStatus = PlanAlertsStatus;

  id: number;
  alertInputs: { key: string, id: number, message: string, input: CheckboxQuestion }[];
  form: UntypedFormGroup;
  alertsStatus: PlanAlertsStatus = PlanAlertsStatus.Generating;

  public subs$: Subscription[] = [];
  poll$: Subscription;
  pollKill$: Subject<void>;

  constructor(
    private route: ActivatedRoute,
    private alertsService: AlertsService,
    private planService: PlansService,
    private translate: TranslateService,
    private qcs: QuestionControlService
  ) {
    this.id = this.route.parent?.snapshot.params['id'] as number;
  }

  ngOnInit(): void {
    this.getPlanAlertsStatusById();
  }

  ngOnDestroy(): void {
    this.subs$.forEach(sub => sub.unsubscribe());
    this.pollKill$.next();
    this.poll$.unsubscribe();
  }

  acknowledge($event: Event) {
    const element = $event.target as HTMLInputElement;
    switch (element.type) {
      case 'checkbox':
        const alertId = this.alertInputs.find(input => input.key === element.id)?.id;
        // Depending on checked state, it will either acknowledge or unacknowledge the alert
        if (element.checked) {
          this.subs$.push(this.alertsService.acknowledgeAlert(PlansAlertsDetailComponent.baseAlertsPath, this.id, alertId).subscribe({
            error: (error: HttpErrorResponse) => { console.error('Error acknowledging alert:', error); }
          }));
        } else {
          this.subs$.push(this.alertsService.unacknowledgeAlert(PlansAlertsDetailComponent.baseAlertsPath, this.id, alertId).subscribe({
            error: (error: HttpErrorResponse) => { console.error('Error unacknowledging alert:', error); }
          }));
        }
        break;
      case 'button':
        // Mark all checks in the form
        Object.keys(this.form.controls).forEach(key => {
          const control = this.form.controls[key];
          if (control instanceof FormControl && typeof control.value === 'boolean') {
            control.setValue(true);
          }
        });
        // Acknowledge all alerts
        this.subs$.push(this.alertsService.acknowledgeAlert(PlansAlertsDetailComponent.baseAlertsPath, this.id).subscribe({
          error: (error: HttpErrorResponse) => { console.error('Error acknowledging alerts:', error); }
        }));
        break;
    }
  }

  recalculate(_$event: Event) {
    this.subs$.push(this.planService.getPlanAlertsById(this.id, true).subscribe({
      complete: () => {
        // When the server responds, reset the polling; this should start it even if it was stopped
        // We also do this to ensure that it polls *right now*, without waiting for the next interval
        this.pollKill$.next();
        this.poll$.unsubscribe();
        this.alertsStatus = PlanAlertsStatus.Generating; // So that the spinner is shown while we wait for the delay and the server's response
        timer(2000).subscribe(() => this.getPlanAlertsStatusById()); // Start polling again after a delay
      },
      error: (error: HttpErrorResponse) => console.error('Error getting plan alerts:', error)
    }));
  }

  getPlanAlertsStatusById(): void {
    const [poll$, pollKill$] = pollUntil(
      this.planService.getPlanById,
      [this.id],
      this.planService,
      10000,
      this.handleExecutionError,
      (response) => {
        this.alertsStatus = response.alerts_status; // Get the alerts status from the response to update UI
        return true; // Polling should continue indefinitely
      }
    );
    this.pollKill$ = pollKill$;
    this.poll$ = poll$.subscribe({
      next: (_response) => {
        this.subs$.push(this.alertsService.getAlertsByEntity(PlansAlertsDetailComponent.baseAlertsPath, this.id).subscribe({
          next: response => {
            const inputs = [];
            this.alertInputs = [];
            // forEach instead of map so we build both arrays in one pass
            response['_embedded']['list'].forEach((alert: Record<string, (number | string)>) => {
              const input = new CheckboxQuestion({
                key: alert.alert_key,
                label: this.translate.instant('resources.plans.alerts.report.acknowledge'),
                value: alert.acknowledged,
                cssClasses: 'form-control input-default',
              });
              this.alertInputs.push({
                key: alert.alert_key as string,
                id: alert.id as number,
                message: alert.message as string,
                input
              });
              inputs.push(input);
            });
            this.form = this.qcs.toFormGroup(inputs);
          }
        }));
      },
      error: this.handleExecutionError
    });
  }

  isPlanAlertStatus(status: PlanAlertsStatus | PlanAlertsStatus[]): boolean {
    if (!this.alertsStatus) return false;
    if (Array.isArray(status)) { return status.includes(this.alertsStatus); }
    return this.alertsStatus === status;
  }

  private handleExecutionError(err: HttpErrorResponse) {
    console.error('Error handling execution:', err);
    if (err.status === 404) {
      console.warn('Plan not found');
    } else if (err.status === 500) {
      console.error('Server error');
    } else {
      console.error('Unknown error');
    }
    console.log('Error details:', err);
  }
}
