import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { CountriesService } from '../../../shared/services/countries.service';
import { CurrentUser } from '../../../shared/models/current-user';
import { FeatureFlagsService } from '../../../shared/services/feature-flags.service';
import { LanguagesService } from '../../../shared/services/languages.service';
import { ProfileService } from '../../../profiles/profile.service';
import { registerLocaleData } from '@angular/common';
import { RolesService } from '../../../profiles/roles.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../shared/models/users/user';
import { UsersService } from '../users.service';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-show-users',
  templateUrl: './show-users.component.html',
  styleUrls: ['./show-users.component.css'],
  providers: [ UsersService ]
})

export class ShowUsersComponent implements OnInit, OnDestroy {

  countryName: string;
  currentUser: CurrentUser;
  element: User;
  flags = this.featureFlags.flags;
  id: number;
  languageName: string;

  private subs$: Subscription[] = [];

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly countriesService: CountriesService,
    private readonly featureFlags: FeatureFlagsService,
    private readonly languagesService: LanguagesService,
    private readonly profileService: ProfileService,
    private readonly rolesService: RolesService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly userService: UsersService
  ) { }

  ngOnInit(): void {
    registerLocaleData(es);
    this.getParams();
    this.currentUser = new CurrentUser(this.profileService.getStoredUser());
  }

  ngOnDestroy(): void {
    this.subs$.forEach(sub$ => sub$.unsubscribe());
  }

  isAuthorized(roleSlugs: string[]): boolean {
    return this.rolesService.isAuthorized(roleSlugs, this.currentUser.role);
  }

  unblockUser(): void {
    this.confirmationService.displayConfirmationAlert(
      this.translate.instant('common.messages.are_you_sure'),
      this.translate.instant('resources.users.messages.unblock_confirmation_text'),
      'question',
    ).then(data => {
        if (data.hasOwnProperty('value') && data.value) { this.doUnblock(); }
      }
    ).catch(() => {});
  }

  private doUnblock(): void {
    const unblock$ = this.userService.updateUser({status: 'active'}, this.id).subscribe(
      () => {
        this.confirmationService.displaySuccessAlert(
          this.translate.instant('resources.users.messages.updated_success_title'),
          this.translate.instant('resources.users.messages.updated_success_text')
        ).catch(() => {});
        this.closeModal();
      },
      errData => this.confirmationService.displayErrorAlert('Error', errData.error.error),
    );
    this.subs$.push(unblock$);
  }

  private getParams(): void {
    const router$ = this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.getUser();
      }
    });
    this.subs$.push(router$);
  }

  private getUser(): void {
    const element$ = this.userService.getUserById(this.id).subscribe(
      userData => {
        this.element = new User(userData);
        if (this.element) {
          this.countryName = this.element.country ? this.countriesService.getCountryName(this.element.country) : this.translate.instant('common.all');
          this.getLanguageName(this.element);
        }
      },
      errData => this.confirmationService.displayErrorAlert('Error', errData.error.error)
    );
    this.subs$.push(element$);
  }

  private closeModal(): void {
    this.router.navigate([{ outlets: { modal: null } }]).catch(() => {});
  }

  private getLanguageName(params): void {
    const languages = this.languagesService.getPlatformLanguages();
    const language = params?.preferredLanguage;
    if (language) {
      this.languageName = languages.find(item => item.id === language).name
    }
  }

}
